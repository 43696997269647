import { useTranslation } from '@getpopsure/i18n-react';
import Card from 'components/Card';
import InformationCard, {
  InformationSection,
} from 'components/InformationCard';
import ChecklistInformation from 'components/InformationCard/ChecklistInformation';
import EditableInformationCard from 'components/InformationCard/EditableInformationCard';
import TextInformation from 'components/InformationCard/TextInformation';
import { UPDATE_STUDENT_CHECKLIST_IBAN } from 'graphql/publicHealth/internal/mutations';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';

import MultipleLineInformation from '../../../../../../components/InformationCard/MultipleLineInformation';
import IbanConfirmationModal from '../IbanConfirmationModal';

interface StudentChecklistCardProps {
  policy: PublicHealthPolicy;
  editable: boolean;
}

const StudentChecklistCard = ({
  policy,
  editable,
}: StudentChecklistCardProps) => {
  const { t } = useTranslation();
  const studentChecklist: InformationSection[] = [
    {
      title: t(
        'admin.policy.public.overview.studentchecklist.title',
        'Student checklist'
      ),
      rows: [
        {
          id: 'iban',
          title: t(
            'admin.policy.public.overview.studentchecklist.iban.title',
            'IBAN'
          ),
          data: policy.studentChecklist?.iban ?? '',
          type: 'TEXT',
          component: TextInformation,
          editable:
            editable &&
            !(
              policy.publicStatus === 'CANCELED' ||
              policy.publicStatus === 'CANT_BE_COVERED' ||
              policy.publicStatus === 'DROPPED_OUT' ||
              policy.publicStatus === 'COVERED'
            ),
        },
        {
          id: 'address',
          title: t(
            'admin.policy.public.overview.studentChecklist.address.title',
            'Address'
          ),
          type: 'MULTIPLELINE',
          component: MultipleLineInformation,
          editable: false,
          multipleLinesData: [
            {
              label: t(
                'admin.policy.public.overview.studentChecklist.address.housenumber.label',
                'House number'
              ),
              data: policy.studentChecklist?.address?.houseNumber ?? '',
            },
            {
              label: t(
                'admin.policy.public.overview.studentChecklist.address.street.label',
                'Street'
              ),
              data: policy.studentChecklist?.address?.street ?? '',
            },
            {
              label: t(
                'admin.policy.public.overview.studentChecklist.address.city.label',
                'City'
              ),
              data: policy.studentChecklist?.address?.city ?? '',
            },
            {
              label: t(
                'admin.policy.public.overview.studentChecklist.address.additionalinformation.label',
                'Additional information'
              ),
              data:
                policy.studentChecklist?.address?.additionalInformation ?? '',
            },
            {
              label: t(
                'admin.policy.public.overview.studentChecklist.address.postcode.label',
                'Postal code'
              ),
              data: policy.studentChecklist?.address?.postcode ?? '',
            },
            {
              label: t(
                'admin.policy.public.overview.studentChecklist.address.country.label',
                'Country'
              ),
              data: policy.studentChecklist?.address?.country ?? '',
            },
          ],
        },
        {
          id: 'documents',
          title: t(
            'admin.policy.public.overview.studentchecklist.documents.title',
            'Documents'
          ),
          type: 'CHECKLIST',
          editable: false,
          component: ChecklistInformation,
          checklist: [
            {
              title: t(
                'admin.documents.type.prelimconfirmation',
                'Prelim. confirmation of coverage'
              ),
              check: Boolean(
                policy.studentChecklist?.proofOfConfirmationOfCoverage
              ),
            },
            {
              title: t(
                'admin.documents.type.enrollmentcertificate',
                'Enrollment certificate'
              ),
              check: Boolean(
                policy.studentChecklist?.uniEnrollmentCertificateUrl
              ),
            },
            {
              title: t(
                'admin.documents.type.acceptanceLetter',
                'Acceptance letter'
              ),
              check: Boolean(policy.studentChecklist?.acceptanceLetterUrl),
            },
          ],
        },
      ],
    },
  ];

  const studentChecklistEditableData = {
    iban: policy.studentChecklist?.iban ?? '',
  };
  return policy.studentChecklist ? (
    editable ? (
      <EditableInformationCard
        sections={studentChecklist}
        editableData={studentChecklistEditableData}
        resourceId={policy.studentChecklist.id}
        mutation={UPDATE_STUDENT_CHECKLIST_IBAN}
        ConfirmationModal={IbanConfirmationModal}
        successMessage="Your changes have been successfully saved"
      />
    ) : (
      <InformationCard sections={studentChecklist} />
    )
  ) : (
    <Card
      title={t(
        'admin.policy.public.overview.studentchecklist.title',
        'Student checklist'
      )}
      boldTitle={true}
    >
      <div className="border px-[24px] py-[20px]">
        <span className="text-sm text-gray-600">
          {t(
            'admin.policy.public.overview.studentchecklist.notstarted.label',
            'The student checklist has not been started for this customer.'
          )}
        </span>
      </div>
    </Card>
  );
};

export default StudentChecklistCard;
