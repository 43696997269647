import { ACTION_TYPES } from 'components/TaskEngine/TaskEngine.types';
import { insuranceTypes } from 'shared/insurances/types';
import { z } from 'zod';

const tags = ['CLAIMS', 'POLICIES'] as const;
export const TagSchema = z.enum(tags);

export const InsuranceTypeSchema = z.enum(insuranceTypes);

export const ActionTypeSchema = z.enum(ACTION_TYPES);

export const BaseActionSchema = z.object({
  type: ActionTypeSchema.exclude(['SUBMIT']),
});

const InputActionSchema = BaseActionSchema.extend({
  type: z.literal('INPUT'),
  attributes: z.object({ placeholder: z.string() }),
});

const TextareaActionSchema = BaseActionSchema.extend({
  type: z.literal('TEXTAREA'),
  attributes: z.object({ placeholder: z.string() }),
});

const LinkActionSchema = BaseActionSchema.extend({
  type: z.literal('LINK'),
  attributes: z.object({ href: z.string().url() }),
});

export const TaskTemplateDataSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
  actions: z.array(
    z.union([
      InputActionSchema,
      TextareaActionSchema,
      LinkActionSchema,
      BaseActionSchema,
    ])
  ),
});

export const TaskTemplateSchema = z.object({
  id: z.string().nullish(),
  name: z.string(),
  tag: TagSchema,
  insuranceType: InsuranceTypeSchema,
  data: TaskTemplateDataSchema,
});

export const CreateTaskTemplateSchema = TaskTemplateSchema.omit({
  id: true,
}).partial({ name: true });

export const UpdateTaskTemplateSchema = TaskTemplateSchema.omit({ name: true });

export type InsuranceType = z.infer<typeof InsuranceTypeSchema>;
export type Tag = z.infer<typeof TagSchema>;
export type TaskTemplateData = z.infer<typeof TaskTemplateDataSchema>;
export type TaskTemplate = z.infer<typeof TaskTemplateSchema>;
export type CreateTaskTemplate = z.infer<typeof CreateTaskTemplateSchema>;

export type AdditionalFieldsAttributes = {
  type: string;
  label: string;
  required: boolean;
  name: string;
  id: string;
};
