import { ZodError } from 'zod';

import { SubmittableCustomerValidationError } from '../models';

// TODO: [KONG] Write tests
export const retrieveErrorMessages = (
  error: ZodError
): Partial<SubmittableCustomerValidationError> => {
  let errorMessages: Partial<SubmittableCustomerValidationError> = {};
  error.issues.forEach(({ message, path }) => {
    if (path.includes('firstName')) {
      errorMessages = { ...errorMessages, firstName: message };
    }
    if (path.includes('lastName')) {
      errorMessages = { ...errorMessages, lastName: message };
    }
    if (path.includes('email')) {
      errorMessages = { ...errorMessages, email: message };
    }
  });
  return errorMessages;
};
