import { generatePath } from 'react-router-dom';
import { paths } from 'routes/definedPaths';
import { Country } from 'shared/countries/models';

import { PolicyTemplateInsurancePath } from '../template/models/InsuranceTypes';

export const genericPolicyDetailsRoute = (
  policyId: string,
  insuranceType: PolicyTemplateInsurancePath,
  country: Country = 'de'
): string =>
  generatePath(paths.policies.internal.details.path, {
    id: policyId,
    insuranceType,
    country,
  });
