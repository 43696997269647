import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { InformationSection } from 'components/InformationCard';
import ComboBoxInformation from 'components/InformationCard/ComboBoxInformation/ComboBoxInformation';
import { CurrencyInformation } from 'components/InformationCard/CurrencyInformation';
import DateInformation from 'components/InformationCard/DateInformation';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { GenericClaim } from 'pages/claims/generic/models';
import { GenericClaimType } from 'shared/claims/generic/models';
import { policyMappers } from 'shared/insurances/';

export const retrieveClaimInfoRows =
  (genericClaimTypes: GenericClaimType[]) =>
  (claimInfo: GenericClaim): InformationSection[] =>
    [
      {
        id: 'claimInfo',
        title: 'Claim information',
        boldTitle: false,
        rows: [
          {
            id: 'claimNumber',
            title: 'Claim number',
            data: claimInfo?.claimNumber ?? '',
            type: 'TEXT',
            component: TextInformation,
            editable: false,
          },
          {
            id: 'status',
            title: 'Status',
            data: claimInfo?.status ?? 'RECEIVED',
            statusMapping: (status) => {
              return {
                color:
                  policyMappers.GENERIC?.claim?.badgeColorMapper[status] ??
                  'blue',
                text: {
                  text:
                    policyMappers.GENERIC?.claim?.claimStatusMapper[status] ??
                    '',
                  id: status,
                },
              };
            },
            type: 'STATUS',
            component: StatusInformation,
            editable: false,
          },
          {
            id: 'createdOn',
            title: 'Created on',
            data: claimInfo?.createdAt ?? '',
            type: 'DATE',
            component: DateInformation,
            editable: false,
          },
          {
            id: 'claimType',
            title: 'Claim type',
            data: claimInfo.claimType?.map(({ type }) => type).join(',') ?? '',
            type: 'DROPDOWN',
            component: ComboBoxInformation,

            dropdownOptions: genericClaimTypes.map((type) => ({
              id: type,
              label: policyMappers.GENERIC?.claim?.typeNameMapper
                ? policyMappers.GENERIC.claim.typeNameMapper[
                    type as GenericClaimType
                  ]
                : '',
            })),
            optionMapping: (option: string) =>
              policyMappers.GENERIC?.claim?.typeNameMapper
                ? policyMappers.GENERIC.claim.typeNameMapper[
                    option as GenericClaimType
                  ]
                : '',
            placeholder: 'Choose a claim type',
            editable: true,
            multipleOptions: true,
            required: true,
          },
          {
            id: 'dateOfEvent',
            title: 'Event date',
            data: claimInfo?.dateOfEvent ?? '',
            type: 'DATE',
            component: DateInformation,
            editable: true,
          },
          {
            id: 'eventDescription',
            title: 'Event description',
            data: claimInfo?.eventDescription ?? '',
            type: 'TEXT',
            component: TextInformation,
            editable: true,
          },
          {
            id: 'iban',
            title: 'IBAN',
            data: claimInfo?.iban ?? '',
            type: 'TEXT',
            component: TextInformation,
            editable: true,
          },
          {
            id: 'amount',
            title: 'Claimed amount',
            data: claimInfo?.amount ?? 0,
            type: 'CURRENCY',
            component: CurrencyInformation,
            editable: true,
            placeholder: englishFormattedEuroCurrency(0),
          },
          {
            id: 'refundAmount',
            title: 'Refund amount',
            data: claimInfo?.refundAmount ?? 0,
            type: 'CURRENCY',
            component: CurrencyInformation,
            editable: true,
            placeholder: englishFormattedEuroCurrency(0),
          },
          {
            id: 'featherPaidOn',
            title: 'Feather paid on',
            data: claimInfo?.featherPaidOn ?? '',
            type: 'DATE',
            component: DateInformation,
            editable: true,
          },
          {
            id: 'customerPaidOn',
            title: 'Customer paid on',
            data: claimInfo?.customerPaidOn ?? '',
            type: 'DATE',
            component: DateInformation,
            editable: true,
          },
          {
            id: 'thirdPartyPaidOn',
            title: '3rd party paid on',
            data: claimInfo?.thirdPartyPaidOn ?? '',
            type: 'DATE',
            component: DateInformation,
            editable: true,
          },
        ],
      },
    ];
