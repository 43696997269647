import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { CREATE_TASK_TEMPLATE } from 'pages/admin/taskTemplates/graphql/mutations';
import { GET_TASK_TEMPLATES } from 'pages/admin/taskTemplates/graphql/queries';
import { useState } from 'react';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import { TaskTemplate } from '../taskTemplate.types';
import TaskTemplateForm from '../TaskTemplateForm/TaskTemplateForm';

const CreateTaskTemplateModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const alertBannersState = useReactiveVar(alertBanners);

  const [createTaskTemplate, { error, loading, reset }] = useMutation(
    CREATE_TASK_TEMPLATE,
    {
      refetchQueries: [GET_TASK_TEMPLATES],
      onCompleted: () => {
        openCreateTaskForm(false);

        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'SUCCESS',
          message: t(
            'taskTemplates.createTaskTemplate.alert.success',
            'New template created'
          ),
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
      onError: () => {
        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'WARNING',
          message: t(
            'taskTemplates.createTaskTemplate.alert.error',
            'Something went wrong. Please try again.'
          ),
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
    }
  );

  const handleButtonClick = () => openCreateTaskForm(true);

  const openCreateTaskForm = (open: boolean) => {
    setOpen(open);
    if (open) {
      reset();
    }
  };

  const handleSubmit = async (input: TaskTemplate) => {
    await createTaskTemplate({
      variables: { input },
    });
  };

  return (
    <>
      <Button onClick={handleButtonClick} buttonType="primary">
        New template
      </Button>
      {open && (
        <Modal
          open={open}
          setOpen={setOpen}
          title={t(
            'taskTemplates.createTaskTemplate.modal.title',
            'Create new template'
          )}
          loading={loading}
          textLoading="Creating..."
          hideActions
          scrollable
        >
          <TaskTemplateForm
            setOpen={setOpen}
            onSubmit={handleSubmit}
            loading={loading}
            error={error}
          />
        </Modal>
      )}
    </>
  );
};

export default CreateTaskTemplateModal;
