import { gql } from '@apollo/client';

import { LIFE_POLICY_ACTIVITY } from './activity';

export const GET_LIFE_POLICY = gql`
  ${LIFE_POLICY_ACTIVITY}
  query GetLifePolicy($id: String!) {
    lifePolicy(id: $id) {
      id
      policyNumber
      createdAt
      startDate
      archivedAt
      price
      paymentReceivedAt
      source
      campaign
      medium
      content
      term
      numberOfClaims
      publicStatus
      activeUntil
      providerId
      endDate

      # Coverage info
      lifeCoverageInfo {
        coverage
        duration
      }

      # insured person
      insuredPerson {
        firstName
        lastName
        dateOfBirth
        email
        gender
      }

      # user
      user {
        id
        firstName
        lastName
        stripeToken
        intercomEUId
        email
        isDelinquent
        isFraudulent
        fraudulentAdditionalInfo
      }

      # documents
      policyDocuments {
        url
        id
        name
        resizedUrl
        isFromProvider
        category
        archivedAt
        isVisibleToCustomer
        isVisibleToHr
        isVisibleToProvider
        internalNotes
      }
      questionnaireDownloadDocuments {
        id
        url
        blobName
      }
      customerUploads {
        id
        url
        blobName
      }

      # policy cancelation request
      policyCancelationRequest {
        id
        cancelationConfirmedByProviderAt
        createdAt
        canceledOnStripeAt
        requestSentToProviderAt
      }

      # questionnaire
      questionnaire {
        id
        answers
      }

      pinnedNotes {
        id
        body
        createdAt
        adminUser {
          firstName
          lastName
        }
        updatedAt
      }

      # policy activity
      ...LifePolicyActivity

      referral {
        referralCode
        status
      }
    }
  }
`;
