export type AuthenticationType = 'PASSWORD' | 'OAUTH';

export const mapAuthenticationType = (type: AuthenticationType) => {
  switch (type) {
    case 'PASSWORD':
      return 'Password';
    case 'OAUTH':
      return 'OAuth';
  }
};
