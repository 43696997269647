import { gql } from '@apollo/client';

export const GET_TASK_TEMPLATES = gql`
  query GetTaskTemplates($filterInsuranceType: [String], $limit: Int) {
    taskTemplates(filterInsuranceType: $filterInsuranceType, limit: $limit) {
      id
      name
      data
    }
  }
`;
