import { TFunction } from '@getpopsure/i18n-react';
import DateInformation from 'components/InformationCard/DateInformation';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { InformationCardDetails } from 'pages/policies/template/models/PolicyTemplateData';

import { CompanyPensionPolicy } from '../models/CompanyPensionPolicy';
import { companyPensionStatusMapper } from '../utils';

export const getOverviewTabCards = (t: TFunction) => {
  return (data?: {
    hrCompanyPensionPolicy: CompanyPensionPolicy;
  }): InformationCardDetails[] => [
    {
      editable: false,
      cards: [
        {
          title: t(
            'admin.hr.companyPension.overview.employeeInformation.title',
            'Employee information'
          ),
          rows: [
            {
              id: 'firstName',
              title: t(
                'admin.hr.companyPension.overview.firstName.label',
                'First name'
              ),
              data: data?.hrCompanyPensionPolicy?.user?.firstName ?? '',
              component: TextInformation,
              type: 'TEXT',
            },
            {
              id: 'lastName',
              title: t(
                'admin.hr.companyPension.overview.lastName.label',
                'Last name'
              ),
              data: data?.hrCompanyPensionPolicy?.user?.lastName ?? '',
              component: TextInformation,
              type: 'TEXT',
            },
            {
              id: 'dateOfBirth',
              title: t(
                'admin.hr.companyPension.overview.dateOfBirth.label',
                'Date of birth'
              ),
              data: data?.hrCompanyPensionPolicy?.user?.dateOfBirth ?? '',
              component: DateInformation,
              type: 'DATE',
            },
          ],
        },
      ],
    },
    {
      editable: false,
      cards: [
        {
          title: t(
            'admin.hr.companyPension.overview.policyInformation.title',
            'Policy information'
          ),
          rows: [
            {
              id: 'provider',
              title: t(
                'admin.hr.companyPension.overview.provider.label',
                'Provider'
              ),
              data: data?.hrCompanyPensionPolicy?.providerId ?? '',
              component: TextInformation,
              type: 'TEXT',
            },
            {
              id: 'status',
              title: t(
                'admin.hr.companyPension.overview.status.label',
                'Status'
              ),
              data: data?.hrCompanyPensionPolicy?.publicStatus ?? '',
              statusMapping: companyPensionStatusMapper(t),
              component: StatusInformation,
              type: 'STATUS',
            },
            {
              id: 'startDate',
              title: t(
                'admin.hr.companyPension.overview.startDate.label',
                'Start date'
              ),
              data: data?.hrCompanyPensionPolicy?.startDate ?? '',
              component: DateInformation,
              type: 'DATE',
            },
            {
              id: 'policyNumber',
              title: t(
                'admin.hr.companyPension.overview.policyNumber.label',
                'Policy number'
              ),
              data: data?.hrCompanyPensionPolicy?.policyNumber ?? '',
              component: TextInformation,
              type: 'TEXT',
            },
          ],
        },
      ],
    },
    {
      editable: false,
      cards: [
        {
          title: t(
            'admin.hr.companyPension.overview.contributionDetails.title',
            'Contribution details'
          ),
          rows: [
            {
              id: 'financingType',
              title: t(
                'admin.hr.companyPension.overview.financingType.label',
                'Financing type'
              ),
              data:
                data?.hrCompanyPensionPolicy?.pensionDetails?.financingType ??
                '',
              component: TextInformation,
              type: 'TEXT',
            },
            {
              id: 'paymentMethod',
              title: t(
                'admin.hr.companyPension.overview.paymentMethod.label',
                'Payment method'
              ),
              data:
                data?.hrCompanyPensionPolicy?.pensionDetails?.paymentMethod ??
                '',
              component: TextInformation,
              type: 'TEXT',
            },
            {
              id: 'employeeContribution',
              title: t(
                'admin.hr.companyPension.overview.employeeContribution.label',
                'Employee contribution'
              ),
              data:
                data?.hrCompanyPensionPolicy?.pensionDetails?.ownContribution ??
                '',
              component: TextInformation,
              type: 'TEXT',
            },
            {
              id: 'employerContribution',
              title: t(
                'admin.hr.companyPension.overview.employerContribution.label',
                'Employer contribution'
              ),
              data:
                data?.hrCompanyPensionPolicy?.pensionDetails
                  ?.employerContribution ?? '',
              component: TextInformation,
              type: 'TEXT',
            },
            {
              id: 'totalContribution',
              title: t(
                'admin.hr.companyPension.overview.totalContribution.label',
                'Total monthly contribution'
              ),
              data:
                data?.hrCompanyPensionPolicy?.pensionDetails
                  ?.totalContribution ?? '',
              component: TextInformation,
              type: 'TEXT',
            },
            {
              id: 'yearlyContribution',
              title: t(
                'admin.hr.companyPension.overview.yearlyContribution.label',
                'Total yearly contribution'
              ),
              data:
                data?.hrCompanyPensionPolicy?.pensionDetails
                  ?.yearlyContribution ?? '',
              component: TextInformation,
              type: 'TEXT',
            },
          ],
        },
      ],
    },
  ];
};
