import { useMutation, useReactiveVar } from '@apollo/client';
import { RecentActivity } from 'components/ActivityLog/RecentActivity';
import { CustomerDelinquencyBanner } from 'components/CustomerDelinquencyBanner';
import { CustomerFraudulentBanner } from 'components/CustomerFraudulentBanner';
import InformationCard, {
  InformationSection,
} from 'components/InformationCard';
import { ConfirmationModalProps } from 'components/InformationCard/EditableInformationCard';
import { PinnedNotes } from 'components/Notes/PinnedNotes';
import { DocumentNode } from 'graphql';
import { Claim } from 'models/Claim';
import { GET_CLAIM_ACTIVITY } from 'shared/activityLog/queries';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import { GENERATE_CLAIM_NUMBER } from '../../../ClaimsOverview/graphql/mutations';
import { ClaimInfo } from '../ClaimInfo/ClaimInfo';
import { Documents } from '../Documents';
import * as styles from './styles';

interface Props<Policy, Status> {
  claimInfo: Claim<Policy, Status>;
  policyInfoRows: InformationSection[];
  refetchQueries: DocumentNode;
  updateClaimMutation: DocumentNode;
  retrieveClaimInfoRows: (
    claimInfo: Claim<Policy, Status>,
    { generateClaimNumber }: { generateClaimNumber?: (claimId: string) => void }
  ) => InformationSection[];
  ClaimInfoConfirmationModal?: React.ElementType<ConfirmationModalProps>;
  retrieveClaimInfoOldConfirmationValue?: (
    claimInfo: Claim<Policy, Status>
  ) => { id: string; value: string | number }[];
  retrieveAdditionalClaimInfoRows?: (
    claimInfo: Claim<Policy, Status>
  ) => InformationSection[];
}

export const OverviewTab = <Policy, Status>({
  claimInfo,
  policyInfoRows,
  refetchQueries,
  updateClaimMutation,
  retrieveClaimInfoRows,
  retrieveAdditionalClaimInfoRows,
  ClaimInfoConfirmationModal,
  retrieveClaimInfoOldConfirmationValue,
}: Props<Policy, Status>) => {
  const alertBannersState = useReactiveVar(alertBanners);

  const [generateClaimNumber] = useMutation(GENERATE_CLAIM_NUMBER, {
    onCompleted: () => {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message: 'Claim number successfully generated.',
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
    onError: () => {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: 'Failed to generate claim number. Please try again.',
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
    refetchQueries: [refetchQueries],
  });

  const generateClaimNumberWrapperFn = (claimId: string) =>
    generateClaimNumber({ variables: { claimId } });

  const claimInfoData = retrieveClaimInfoRows(claimInfo, {
    generateClaimNumber: generateClaimNumberWrapperFn,
  });

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <CustomerDelinquencyBanner customer={claimInfo.user} linkToCustomer />
        <CustomerFraudulentBanner customer={claimInfo.user} />
        <ClaimInfo
          refetchQueries={[refetchQueries]}
          updateClaimMutation={updateClaimMutation}
          claimId={claimInfo.id}
          claimInfoData={claimInfoData}
          ConfirmationModal={ClaimInfoConfirmationModal}
          oldConfirmationValue={retrieveClaimInfoOldConfirmationValue?.(
            claimInfo
          )}
        />
        {retrieveAdditionalClaimInfoRows && (
          <ClaimInfo
            refetchQueries={[refetchQueries]}
            updateClaimMutation={updateClaimMutation}
            claimId={claimInfo.id}
            claimInfoData={retrieveAdditionalClaimInfoRows(claimInfo)}
          />
        )}
        <InformationCard sections={policyInfoRows} />
      </div>
      <div className={styles.rightContainer}>
        <PinnedNotes
          notes={claimInfo.pinnedNotes ?? []}
          refetchQueries={[refetchQueries]}
        />
        <RecentActivity
          query={GET_CLAIM_ACTIVITY}
          variables={{
            claimId: claimInfo.id,
            userId: claimInfo.user.id,
            pageSize: 3,
          }}
        />
        <Documents
          documents={claimInfo.documents}
          claimId={claimInfo.id}
          userId={claimInfo.user.id}
          refetchQuery={refetchQueries}
        />
      </div>
    </div>
  );
};
