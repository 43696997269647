import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import {
  DogLiabilityClaimStatus,
  dogLiabilityClaimStatuses,
  DogLiabilityClaimType,
  dogLiabilityClaimTypes,
} from 'shared/claims/dogLiability/models';
import { policyMappers } from 'shared/insurances';

const statusOptions: SelectButtonOption<DogLiabilityClaimStatus>[] =
  dogLiabilityClaimStatuses.map((status) => ({
    id: status,
    label: policyMappers.DOG_LIABILITY?.claim?.claimStatusMapper[status] ?? '',
    title: policyMappers.DOG_LIABILITY?.claim?.claimStatusMapper[status] ?? '',
  }));

const claimTypeOptions: SelectButtonOption<DogLiabilityClaimType>[] =
  dogLiabilityClaimTypes.map((type) => ({
    id: type,
    label: policyMappers.DOG_LIABILITY?.claim?.typeNameMapper[type] ?? '',
    title: policyMappers.DOG_LIABILITY?.claim?.typeNameMapper[type] ?? '',
  }));
export const filters: FilterConfig[] = [
  {
    options: statusOptions,
    filterType: 'MULTIPLE',
    label: 'Status',
    id: 'filterStatus',
    placeholder: 'Status',
  },
  {
    options: claimTypeOptions,
    filterType: 'MULTIPLE',
    label: 'Claim type',
    id: 'filterClaimType',
    placeholder: 'Claim type',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Created on',
    id: 'createdAt',
  },
];
