import { BadgeColor } from 'components/Badge/Badge';

import { LiabilityStatus } from '../types';

export const liabilityStatusMapper: Record<LiabilityStatus, string> = {
  PENDING: 'Pending',
  ACTIVE: 'Covered',
  CANCELED: 'Canceled',
  DROPPED_OUT: 'Canceled',
};

export const liabilityBadgeColorMapper: Record<LiabilityStatus, BadgeColor> = {
  PENDING: 'purple',
  ACTIVE: 'green',
  CANCELED: 'gray',
  DROPPED_OUT: 'gray',
};
