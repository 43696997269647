import { useTranslation } from '@getpopsure/i18n-react';
import EditQuestionnaireButton from 'components/EditQuestionnaireButton/EditQuestionnaireButton';
import InformationCard, {
  InformationSection,
} from 'components/InformationCard';
import ChecklistInformation from 'components/InformationCard/ChecklistInformation';
import DateInformation from 'components/InformationCard/DateInformation';
import MultipleLineInformation from 'components/InformationCard/MultipleLineInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { PrivateHealthPolicy } from 'models/privateHealthPolicy';
import {
  CREATE_PRIVATE_HEALTH_QUESTIONNAIRE,
  UPDATE_PRIVATE_QUESTIONNAIRE,
} from 'pages/policies/privateHealth/graphql/mutations';
import { getEditablePrivateQuestionnaireAnswers } from 'pages/policies/privateHealth/policy/getEditableQuestionnaireAnswers';
import { mapGender } from 'shared/mapGender';

import { GET_PRIVATE_POLICY } from '../../graphql/queries';
import SideSection from '../SideSection/SideSection';

interface QuestionnaireTabProps {
  policy: PrivateHealthPolicy;
}

const QuestionnaireTab = ({ policy }: QuestionnaireTabProps) => {
  const { t } = useTranslation();

  const {
    personalInfo,
    medicalHistory,
    specify,
    financialHistory,
    insuranceHistory,
  } = policy.questionnaire?.answers || {};

  const isMissingQuestionnaire = !policy.questionnaire;

  const noQuestionnaireSection: InformationSection = {
    title: 'No questionnaire',
    rows: [],
  };

  const personalInformationSection: InformationSection = {
    title: 'Personal information',
    rows: [
      {
        id: 'firstName',
        title: 'First name',
        data: personalInfo?.name.firstName ?? '',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'lastName',
        title: 'Last name',
        data: personalInfo?.name.lastName ?? '',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'gender',
        title: 'Gender',
        data: mapGender(t)[personalInfo?.gender ?? 'OTHER'],
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'dateOfBirth',
        title: 'Date of birth',
        data: personalInfo?.dateOfBirth ?? '',
        type: 'DATE',
        component: DateInformation,
      },
      {
        id: 'maritalStatus',
        title: 'Marital status',
        data: personalInfo?.maritalStatus ?? '',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'nationality',
        title: 'Nationality',
        data: Array.isArray(personalInfo?.nationality)
          ? personalInfo?.nationality
              ?.map((country) => country.name)
              .join(',') ?? ''
          : personalInfo?.nationality?.name ?? '',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'residencePermitStatus',
        title: 'Status of residence permit',
        data: personalInfo?.residencePermitStatus ?? '',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'typeOfResidencePermit',
        title: 'Type of residence permit',
        data: personalInfo?.whatTypeOfResidencePermit ?? '',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'passportNumber',
        title: 'Passport number',
        data: personalInfo?.passportNumber ?? '',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'passportExpiration',
        title: 'Passport expiration date',
        data: personalInfo?.passportExpiry ?? '',
        type: 'DATE',
        component: DateInformation,
      },
      {
        id: 'email',
        title: 'Email',
        data: personalInfo?.email ?? '',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'coverageStartDate',
        title: 'Coverage start date',
        data: personalInfo?.coverageStartDate ?? '',
        type: 'DATE',
        component: DateInformation,
      },
      {
        id: 'relocationDate',
        title: 'Relocation date',
        data: personalInfo?.relocationDate ?? '',
        type: 'DATE',
        component: DateInformation,
      },
      {
        id: 'livingInGermany',
        title: 'Currently living in Germany?',
        data: personalInfo?.currentlyLivingInGermany ? 'Yes' : 'No',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'livedInGermanyForLast36Months',
        title: 'Lived in Germany for last 36 months?',
        data:
          personalInfo?.livedInGermanyForLast36Months === undefined ||
          personalInfo?.livedInGermanyForLast36Months === null
            ? ''
            : personalInfo?.livedInGermanyForLast36Months
            ? 'Yes'
            : 'No',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'address',
        title: 'Address',
        multipleLinesData: [
          {
            label: 'House number',
            data: personalInfo?.address.houseNumber ?? '',
          },
          {
            label: 'Street',
            data: personalInfo?.address.street ?? '',
          },
          {
            label: 'City',
            data: personalInfo?.address.city ?? '',
          },
          {
            label: 'Additional information',
            data: personalInfo?.address.additionalInformation ?? '',
          },
          {
            label: 'Postal code',
            data: personalInfo?.address.postcode ?? '',
          },
          {
            label: 'Country',
            data: personalInfo?.address.country ?? '',
          },
        ],
        type: 'MULTIPLELINE',
        component: MultipleLineInformation,
      },
    ],
  };

  const occupation = financialHistory?.employmentStatus;

  const financialHistorySection: InformationSection = {
    title: 'Financial history',
    rows: [
      {
        id: 'employmentStatus',
        title: 'Employment status',
        data: financialHistory?.employmentStatus ?? '',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'jobTitle',
        title: 'Job title',
        data: financialHistory?.whatIsYourJobTitle ?? '',
        type: 'TEXT',
        component: TextInformation,
      },
      ...(occupation === 'EMPLOYED'
        ? [
            {
              id: 'employerName',
              title: 'Employer name',
              data: financialHistory?.employerName ?? '',
              type: 'TEXT' as const,
              component: TextInformation,
            },
          ]
        : []),
      ...(occupation === 'EMPLOYED'
        ? [
            {
              id: 'annualIncome',
              title: 'Annual income',
              data: financialHistory?.income?.toString() ?? '',
              type: 'TEXT' as const,
              component: TextInformation,
            },
          ]
        : []),
      ...(occupation === 'SELF_EMPLOYED'
        ? [
            {
              id: 'incomeEstime',
              title: 'Income estimate',
              data: financialHistory?.incomeEstimate?.toString() ?? '',
              type: 'TEXT' as const,
              component: TextInformation,
            },
          ]
        : []),
      {
        id: 'hasTaxId',
        title: 'Have german tax ID?',
        data: financialHistory?.hasGermanTaxId ? 'Yes' : 'No',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'taxId',
        title: 'German tax ID',
        data: financialHistory?.germanTaxId ?? '',
        type: 'TEXT',
        component: TextInformation,
      },
      ...(occupation === 'EMPLOYED'
        ? [
            {
              id: 'documents',
              title: 'Documents',
              type: 'CHECKLIST' as const,
              editable: false,
              component: ChecklistInformation,
              checklist: [
                {
                  title: 'Employment contract',
                  check: !!financialHistory?.workContract,
                },
              ],
            },
          ]
        : []),
      ...(occupation === 'SELF_EMPLOYED'
        ? [
            {
              id: 'documents',
              title: 'Documents',
              type: 'CHECKLIST' as const,
              editable: false,
              component: ChecklistInformation,
              checklist: [
                {
                  title: 'Bank statement',
                  check: !!financialHistory?.bankStatement,
                },
                {
                  title: 'Additional proof of income',
                  check: !!financialHistory?.incomeStatement,
                },
                {
                  title: 'Business plan',
                  check: false,
                },
                {
                  title: 'Contract with clients',
                  check: !!financialHistory?.futureIncome,
                },
              ],
            },
          ]
        : []),
      ...(occupation === 'SELF_EMPLOYED'
        ? [
            {
              id: 'workDescription',
              title: 'Description of the work',
              data: financialHistory?.jobDescription ?? '',
              type: 'TEXT' as const,
              component: TextInformation,
            },
            {
              id: 'linkedIn',
              title: 'LinkedIn Profile',
              data: financialHistory?.linkedInProfileUrl ?? '',
              type: 'TEXT' as const,
              component: TextInformation,
            },
          ]
        : []),
    ],
  };

  const insuranceHistorySection: InformationSection = {
    title: 'Insurance history',
    rows: [
      {
        id: 'insuredAnywhereWorld',
        title: 'Currently insured anywhere in the world?',
        data: insuranceHistory?.hasHealthInsurance ? 'Yes' : 'No',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'currentInsurance',
        title: 'Current health insurance',
        data: insuranceHistory?.currentHealthInsurance ?? '',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'consistentInsurance',
        title: 'Insured at all times in the last 12 months',
        data: insuranceHistory?.hadConsistentHealthInsurance ? 'Yes' : 'No',
        type: 'TEXT',
        component: TextInformation,
      },
    ],
  };

  const specifyDiagnosedTreatedOrGivenMedicalAdvice =
    specify?.diagnosedTreatedOrGivenMedicalAdvice;

  const specifyAdmittedToHospitalPast10years =
    specify?.admittedToHospitalPast10years;

  const medicalHistorySection: InformationSection = {
    title: 'Medical history',
    rows: [
      {
        id: 'height',
        title: 'Height (cm)',
        data: String(medicalHistory?.heightInCm ?? ''),
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'weight',
        title: 'Weight (kg)',
        data: String(medicalHistory?.weightInKg ?? ''),
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'diagnosedTreatedOrGivenMedicalAdvice',
        title: 'Diagnosed in the last 3 years?',
        data: medicalHistory?.diagnosedTreatedOrGivenMedicalAdvice
          ? JSON.stringify(
              specifyDiagnosedTreatedOrGivenMedicalAdvice,
              null,
              ' '
            )
          : 'No',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'admittedToHospitalPast10years',
        title: 'Admitted to hospital in last 10 years?',
        data: medicalHistory?.admittedToHospitalPast10years
          ? JSON.stringify(specifyAdmittedToHospitalPast10years, null, ' ')
          : 'No',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'hivPositive',
        title: 'Diagnosed with HIV/AIDS?',
        data: medicalHistory?.hivPositive ? 'Yes' : 'No',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'psychotherapy',
        title: 'Received psychotherapy?',
        data: medicalHistory?.hasReceivedPsychotherapy ? 'Yes' : 'No',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'produceChildren',
        title: 'Unable to produce children?',
        data: medicalHistory?.unableToProduceChildren ? 'Yes' : 'No',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'otherConditions',
        title: 'Other conditions?',
        data: Array.isArray(medicalHistory?.sufferedFromCondition)
          ? medicalHistory?.sufferedFromCondition.join(', ') ?? 'No'
          : medicalHistory?.sufferedFromCondition.toString() ?? 'No',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'currentMedicalTreatment',
        title: 'Current treatments',
        data: Array.isArray(medicalHistory?.undergoingTreatment)
          ? medicalHistory?.undergoingTreatment.join(', ') ?? ''
          : 'No',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'futureMedicalTreatments',
        title: 'Future medical treatments?',
        data: medicalHistory?.planToUndergoMedicalTreatment ? 'Yes' : 'No',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'hasMissingBodyPart',
        title: 'Missing body part or artificial implants?',
        data: medicalHistory?.hasMissingBodyPart ? 'Yes' : 'No',
        type: 'TEXT',
        component: TextInformation,
      },
      ...(medicalHistory?.hasMissingBodyPart
        ? [
            {
              id: 'missingBodyParts',
              title: 'Missing body parts',
              data: Array.isArray(medicalHistory?.missingBodyPart)
                ? medicalHistory.missingBodyPart.join(', ') ?? ''
                : medicalHistory?.missingBodyPart.toString(),
              type: 'TEXT' as const,
              component: TextInformation,
            },
            {
              id: 'missingBodyPartsExplanation',
              title: 'Missing body parts explanation',
              data:
                medicalHistory?.missingBodyPartExplanation?.toString() ?? '',
              type: 'TEXT' as const,
              component: TextInformation,
            },
          ]
        : []),
      {
        id: 'chronicProblems',
        title: 'Chronic health problems?',
        data: Array.isArray(medicalHistory?.sufferingFromCondition)
          ? medicalHistory?.sufferingFromCondition.join(', ') ?? 'No'
          : medicalHistory?.sufferingFromCondition.toString() ?? 'No',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'chronicProblemsExplanation',
        title: 'Chronic health problems explanation',
        data:
          medicalHistory?.sufferingFromConditionExplanation?.toString() ?? '',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'regularMedication',
        title: 'Take regular medication?',
        data: medicalHistory?.hasTakenMedication ? 'Yes' : 'No',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'glasses',
        title: 'Do you wear glasses or contact lenses?',
        data: medicalHistory?.assistingVisionDevice ? 'Yes' : 'No',
        type: 'TEXT',
        component: TextInformation,
      },
      ...(medicalHistory?.assistingVisionDevice
        ? [
            {
              id: 'maximumEyeDiopters',
              title: 'Maximum eye diopters',
              data: medicalHistory?.maximumEyeDiopters?.toString() ?? '',
              type: 'TEXT' as const,
              component: TextInformation,
            },
            {
              id: 'visionOperation',
              title: 'Operation because of vision',
              data: medicalHistory?.hadOperationBecauseOfVision ? 'Yes' : 'No',
              type: 'TEXT' as const,
              component: TextInformation,
            },
            {
              id: 'visionHospital',
              title: 'Have you been at the hospital because of vision?',
              data: medicalHistory?.hasBeenAtHospitalBecauseOfVision
                ? 'Yes'
                : 'No',
              type: 'TEXT' as const,
              component: TextInformation,
            },
          ]
        : []),
      {
        id: 'dentistTreatment',
        title: 'Dentist or orthodontic treatment?',
        data: Array.isArray(medicalHistory?.dentalCondition)
          ? medicalHistory?.dentalCondition.join(', ') ?? 'No'
          : medicalHistory?.dentalCondition?.toString() ?? 'No',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'howManyTeeth',
        title: 'How many teeth affected by condition?',
        data: medicalHistory?.howManyTeethAffectedByCondition?.toString() ?? '',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'missingTeeth',
        title: 'Teeth missing?',
        data: medicalHistory?.hasMissingTeeth ? 'Yes' : 'No',
        type: 'TEXT',
        component: TextInformation,
      },
      ...(medicalHistory?.hasMissingTeeth
        ? [
            {
              id: 'missingTeethUpperOrLowerJaw',
              title: 'Teeth missing in upper or lower jaw?',
              data:
                medicalHistory?.missingTeethUpperOrLowerJaw?.toString() ?? '',
              type: 'TEXT' as const,
              component: TextInformation,
            },
            {
              id: 'lowerJawMissingTeeth',
              title: 'Teeth missing in lower jaw?',
              data: medicalHistory?.lowerJawMissingTeeth?.toString() ?? '',
              type: 'TEXT' as const,
              component: TextInformation,
            },
            {
              id: 'upperJawMissingTeeth',
              title: 'Teeth missing in upper jaw?',
              data: medicalHistory?.upperJawMissingTeeth?.toString() ?? '',
              type: 'TEXT' as const,
              component: TextInformation,
            },
          ]
        : []),
      {
        id: 'jawCondition',
        title: 'Any types of jaw condition?',
        data: Array.isArray(medicalHistory?.jawCondition)
          ? medicalHistory?.jawCondition.join(', ') ?? 'No'
          : medicalHistory?.jawCondition?.toString() ?? 'No',
        type: 'TEXT',
        component: TextInformation,
      },
    ],
  };

  const sampleAnswers = {
    personalInfo: {
      name: {
        firstName: 'John',
        lastName: 'Doe',
      },
      maritalStatus: 'SINGLE',
      dateOfBirth: '2000-1-1',
      email: '',
      gender: 'MALE',
      coverageStartDate: '2000-1-1',
      phoneNumber: '',
      address: {
        city: '',
        street: '',
        country: '',
        postcode: '',
        houseNumber: '',
      },
    },
  };

  return (
    <>
      <div className="relative flex w-full flex-col">
        <InformationCard
          sections={
            isMissingQuestionnaire
              ? [noQuestionnaireSection]
              : [
                  personalInformationSection,
                  financialHistorySection,
                  insuranceHistorySection,
                  medicalHistorySection,
                ]
          }
          additionalActions={
            <EditQuestionnaireButton
              answers={policy.questionnaire?.answers}
              questionnaireId={policy.questionnaire?.id ?? ''}
              policyId={policy.id}
              getEditableAnswers={getEditablePrivateQuestionnaireAnswers}
              mutation={UPDATE_PRIVATE_QUESTIONNAIRE}
              createMutationProps={{
                mutation: CREATE_PRIVATE_HEALTH_QUESTIONNAIRE,
                variables: {
                  answersAsStr: JSON.stringify(sampleAnswers),
                  policyId: policy.id,
                },
                refetchQueries: [GET_PRIVATE_POLICY],
              }}
              description={
                policy.publicStatus !== 'ACTIVE'
                  ? 'After saving, the application document will be regenerated. Please apply the same changes to the overview page to keep everything up-to-date.'
                  : ''
              }
              allowResubmitToProvider={!!policy.applicationSentAt}
            />
          }
        />
      </div>
      <div className="w-full lg:ml-[20px] lg:w-auto">
        <SideSection policy={policy} />
      </div>
    </>
  );
};

export default QuestionnaireTab;
