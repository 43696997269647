import { gql } from '@apollo/client';

export const PRIVATE_DOCUMENT_FIELDS = gql`
  fragment PrivateDocumentFields on PrivateHealthPolicy {
    policyDocuments {
      id
      name
      url
      resizedUrl
      category
      archivedAt
      isFromProvider
      isVisibleToCustomer
      isVisibleToHr
      isVisibleToProvider
      internalNotes
    }
    questionnaireDownloadDocuments {
      id
      url
      blobName
    }
    customerUploads {
      id
      url
      blobName
      name
    }
  }
`;
