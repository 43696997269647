import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import Modal from 'components/Modal';
import { UPDATE_TASK_TEMPLATE } from 'pages/admin/taskTemplates/graphql/mutations';
import { GET_TASK_TEMPLATES } from 'pages/admin/taskTemplates/graphql/queries';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import { TaskTemplate } from '../taskTemplate.types';
import TaskTemplateForm from '../TaskTemplateForm/TaskTemplateForm';

interface UpdateTaskTemplateModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  taskTemplate: TaskTemplate;
}

const UpdateTaskTemplateModal = ({
  open,
  setOpen,
  taskTemplate,
}: UpdateTaskTemplateModalProps) => {
  const { t } = useTranslation();

  const alertBannersState = useReactiveVar(alertBanners);

  const [updateTaskTemplate, { loading, error }] = useMutation(
    UPDATE_TASK_TEMPLATE,
    {
      refetchQueries: [GET_TASK_TEMPLATES],
      onCompleted: () => {
        setOpen(false);

        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'SUCCESS',
          message: t(
            'taskTemplates.updateTaskTemplate.alert.success',
            'Task template updated.'
          ),
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
      onError: () => {
        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'WARNING',
          message: t(
            'taskTemplates.updateTaskTemplate.alert.error',
            'Something went wrong. Please try again.'
          ),
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
    }
  );

  const handleSubmit = async (input: TaskTemplate) => {
    await updateTaskTemplate({
      variables: {
        id: taskTemplate.id,
        input,
      },
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={t('taskTemplates.updateTaskTemplate.modal.title', 'Edit template')}
      loading={loading}
      textLoading="Saving..."
      hideActions
      scrollable
    >
      <TaskTemplateForm
        formData={taskTemplate}
        setOpen={setOpen}
        onSubmit={handleSubmit}
        loading={loading}
        error={error}
      />
    </Modal>
  );
};

export default UpdateTaskTemplateModal;
