import { ClaimFileType, claimFileTypes } from 'models/Claim';
import { claimDocumentCategories } from 'pages/claims/template/models';
import { z, ZodObject, ZodRawShape } from 'zod';

const schema = z.object({
  claimId: z.string().uuid(),
  file: z.custom<File>(),
  friendlyName: z.string().optional(),
  type: z.enum(claimFileTypes),
});

export const getUploadDocumentSchema = (
  type?: ClaimFileType
): ZodObject<ZodRawShape> => {
  if (type === 'CUSTOMER_UPLOADS') {
    return schema.extend({
      userId: z.string().uuid(),
    });
  }

  if (type === 'FEATHER_UPLOADS' || type === 'PROVIDER_UPLOADS') {
    return schema.extend({
      category: z.enum(claimDocumentCategories),
      isVisibleToCustomer: z.boolean(),
      internalNote: z.string().optional(),
    });
  }

  return schema;
};
