export const truncatedString = ({
  target,
  maxLength,
}: {
  target: string;
  maxLength: number;
}): string => {
  if (target.length > maxLength) {
    return `${target.slice(0, maxLength)}...`;
  }

  return target;
};
