import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import Button from 'components/Button';
import { CheckBox } from 'components/CheckBox';
import ComboBox from 'components/ComboBox';
import { ComboBoxOption } from 'components/ComboBox/ComboBox';
import { FormInputLabel } from 'components/FormInputLabel';
import Input from 'components/Input';
import Modal from 'components/Modal';
import { SelectMenuOption } from 'components/SelectMenu';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';

import { InsuranceType } from '../../../shared/insurances/types';
import { RichTextEditor } from '../../RichTextEditor';
import TaskActionsBuilder from '../TaskActionsBuilder/TaskActionsBuilder';
import { TaskActionsFormInput, TaskTemplate } from '../TaskEngine.types';
import { GET_TASK_TEMPLATES } from '../TaskList/TaskList.queries';
import {
  getTaskPayload,
  getTaskTemplatePayload,
} from '../utils/getTaskPayload';
import * as styles from './CreateTaskForm.styles';

type Payload = {
  task: ReturnType<typeof getTaskPayload>;
  template: ReturnType<typeof getTaskTemplatePayload>;
};

interface CreateTaskFormProps {
  setOpen: (open: boolean) => void;
  onSubmit: (payload: Payload) => void;
  loading: boolean;
  error?: ApolloError;
  insuranceType: InsuranceType;
}

const customTaskOption = {
  id: 'custom',
  label: 'Custom task',
};

export const CreateTaskForm = (props: CreateTaskFormProps) => {
  const location = useLocation();
  const { setOpen, onSubmit, loading, error, insuranceType } = props;
  const { register, control, handleSubmit, setValue, resetField } =
    useForm<TaskActionsFormInput>();

  const [taskTemplateOptions, setTaskTemplateComboboxOptions] = useState<
    ComboBoxOption[]
  >([customTaskOption]);
  const [selectedTaskTemplate, setSelectedTaskTemplate] =
    useState<SelectMenuOption>();

  const { t } = useTranslation();
  const [createTaskTemplateChecked, setCreateTaskTemplateChecked] =
    useState(false);
  const [updateTaskTemplateChecked, setUpdateTaskTemplateChecked] =
    useState(false);

  const [richTextDescription, setRichTextDescription] = useState<string>('');

  const { data, error: taskTemplatesError } = useQuery<{
    taskTemplates: Pick<TaskTemplate, 'id' | 'name' | 'data'>[];
  }>(GET_TASK_TEMPLATES, {
    variables: {
      filterInsuranceType: [insuranceType],
      /**
       * We have to find a better way to paginate this.
       * for now, we'll just fetch the first 200 task templates of any given insurance type
       */
      limit: 200,
    },
    onCompleted: (data) => {
      setTaskTemplateComboboxOptions((prev) => {
        return [
          ...prev,
          ...data.taskTemplates.map((taskTemplate) => ({
            id: taskTemplate.id,
            label: taskTemplate.name,
          })),
        ];
      });
    },
  });

  const setSelectedTemplateOption = (newOption: ComboBoxOption) => {
    const template = taskTemplateOptions.find(
      (option) => option.id === newOption.id
    );

    setSelectedTaskTemplate(template);

    if (template?.id === 'custom') {
      resetField('name');
      resetField('title');
      resetField('description');
      setValue('actions', [{ type: 'INPUT' }]);
      setRichTextDescription('');
      setUpdateTaskTemplateChecked(false);
    }

    if (template?.id !== 'custom') {
      const filteredTaskTemplate = data?.taskTemplates.find(
        (taskTemplate) => taskTemplate.id === template?.id
      );

      if (filteredTaskTemplate) {
        const { name, data } = filteredTaskTemplate;
        const { title, description, actions } = data;
        setValue('name', name);
        setValue('title', title);
        setValue('actions', actions);
        setUpdateTaskTemplateChecked(false);
        setRichTextDescription(description);
      }
    }
  };

  const toggleCreateTaskTemplateCheckbox = () => {
    setCreateTaskTemplateChecked((prev) => !prev);
    if (!createTaskTemplateChecked) {
      resetField('name');
    }
  };

  const submit: SubmitHandler<TaskActionsFormInput> = (formEntries) => {
    const task = getTaskPayload({
      formEntries: {
        ...formEntries,
        description: richTextDescription,
      },
    });

    const templatePayload = getTaskTemplatePayload({
      insuranceType,
      tag: location.pathname.includes('policies') ? 'POLICIES' : 'CLAIMS',
      formEntries: {
        id:
          selectedTaskTemplate?.id === 'custom'
            ? undefined
            : selectedTaskTemplate?.id,
        ...formEntries,
        description: richTextDescription,
      },
      createTaskTemplate: createTaskTemplateChecked,
      updateTaskTemplate: updateTaskTemplateChecked,
    });

    onSubmit({ task, template: templatePayload });
  };

  return (
    <Modal
      title="Create task"
      open
      setOpen={setOpen}
      confirmButtonLabel="Create"
      hideActions
      scrollable={!!selectedTaskTemplate}
    >
      <form className={styles.form} onSubmit={handleSubmit(submit)}>
        <div className={styles.inputWrapper}>
          <FormInputLabel
            title={t('taskEngine.createTask.modal.template', 'Template')}
          />
          <ComboBox
            options={taskTemplateOptions}
            placeholder="Select a template"
            multiple={false}
            selectedOption={selectedTaskTemplate}
            setSelectedOption={setSelectedTemplateOption}
            color="gray"
            error={taskTemplatesError?.message}
            useExternalQuery={true}
          />
        </div>
        {selectedTaskTemplate && (
          <>
            <div className={styles.inputWrapper}>
              <FormInputLabel
                htmlFor="title"
                title={t('taskEngine.createTask.modal.title', 'Title')}
              />
              <Input
                id="title"
                color="gray"
                required
                placeholder={t(
                  'taskEngine.createTask.modal.title.placeholder',
                  'Task title for customer'
                )}
                {...register('title')}
              />
            </div>
            <div className={styles.inputWrapper}>
              <FormInputLabel
                htmlFor="description"
                title={t(
                  'taskEngine.createTask.modal.description',
                  'Description'
                )}
              />
              <RichTextEditor
                value={richTextDescription}
                setValue={setRichTextDescription}
                placeholder="Enter a description"
              />
            </div>

            <TaskActionsBuilder
              register={register}
              control={control}
              setValue={setValue}
              resetField={resetField}
            />

            <div
              className={classNames(
                'mt-6',
                'border-t',
                'border-solid',
                'border-gray-300',
                'pt-8'
              )}
            >
              {selectedTaskTemplate?.id === 'custom' ? (
                <CheckBox
                  small
                  className={classNames('text-sm', 'text-gray-900')}
                  label={t(
                    'taskEngine.createTask.modal.saveTemplateCheckbox',
                    'Save as template'
                  )}
                  checked={createTaskTemplateChecked}
                  setChecked={toggleCreateTaskTemplateCheckbox}
                />
              ) : (
                <CheckBox
                  small
                  className={classNames('text-sm', 'text-gray-900')}
                  label={t(
                    'taskEngine.createTask.modal.updateTemplateCheckbox',
                    'Update template'
                  )}
                  checked={updateTaskTemplateChecked}
                  setChecked={setUpdateTaskTemplateChecked}
                />
              )}
            </div>
            {createTaskTemplateChecked && selectedTaskTemplate.id === 'custom' && (
              <div className={classNames('mt-2')}>
                <FormInputLabel
                  hidden
                  htmlFor="name"
                  title={t('taskEngine.createTask.modal.name', 'Template name')}
                />
                <Input
                  id="name"
                  color="gray"
                  placeholder={t(
                    'taskEngine.createTask.modal.name',
                    'Template name'
                  )}
                  required
                  {...register('name')}
                />
              </div>
            )}
            <p className={classNames('text-sm', 'text-gray-600', 'mt-6')}>
              {t(
                'taskEngine.createTask.modal.userNotificationHint',
                'The customer will be notified by email.'
              )}
            </p>
          </>
        )}
        <div className={classNames('flex', 'justify-end', 'mt-4')}>
          <Button
            type="button"
            buttonType="secondary"
            onClick={() => setOpen(false)}
          >
            {t('admin.modal.cancel.action', 'Cancel')}
          </Button>
          <Button
            loading={loading}
            type="submit"
            buttonType="primary"
            className={styles.submit}
          >
            Confirm
          </Button>
        </div>
        {error && <p className={styles.errorMessage}>{error.message}</p>}
      </form>
    </Modal>
  );
};
