import { useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import Badge from 'components/Badge';
import { BadgeColor } from 'components/Badge/Badge';
import CalendarDatePicker from 'components/CalendarDatePicker';
import FileDropzone from 'components/FileDropzone';
import Input, { InputColor } from 'components/Input/Input';
import Modal from 'components/Modal';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import TextArea from 'components/TextArea/TextArea';
import { Issue } from 'models/issue';
import UnresolvedIssues from 'pages/policies/publicHealth/components/UnresolvedIssues';
import { useState } from 'react';

import * as styles from './styles';

export interface AdditionalDataType {
  [key: string]: string | undefined | File;
}

interface ErrorsType {
  [key: string]: boolean;
}

export interface ChangeStatusFormFields {
  id: string;
  field:
    | { type: 'date'; placeholder: string }
    | { type: 'file' }
    | { type: 'textarea'; placeholder: string }
    | { type: 'text'; placeholder: string }
    | { type: 'select'; placeholder: string; options: SelectMenuOption[] };
  color: InputColor;
  required?: boolean;
  label: string;
  description?: string;
  errorMessage?: string;
}

interface ChangeStatusFormProps {
  formId: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  updateStatus: (
    variables: { status: string; issueIds?: string[]; category?: string },
    formId: string
  ) => void;
  newStatus: { text: string; id: string } | null;
  openIssues?: Issue[];
  loading: boolean;
  showIssuesSection: boolean;
  newStatusColor: BadgeColor;
  formFields: ChangeStatusFormFields[];
  additionalData?: AdditionalDataType;
  bottomDescription?: string;
}

/**
 * @deprecated
 * Use StatusUpdateModal instead
 */
const ChangeStatusForm = ({
  formId,
  open,
  setOpen,
  updateStatus,
  newStatus,
  openIssues,
  loading,
  showIssuesSection,
  newStatusColor = 'gray',
  formFields,
  additionalData,
  bottomDescription,
}: ChangeStatusFormProps) => {
  const { t } = useTranslation();

  const [additionalInfo, setAdditionalInfo] = useState<
    undefined | AdditionalDataType
  >(additionalData);

  const [errors, setErrors] = useState<ErrorsType>({});

  const onSubmit = () => {
    let missingRequired = false;
    formFields.forEach((field) => {
      if (field.required && !additionalInfo?.[field.id]) {
        missingRequired = true;
        setErrors((oldErrors) => ({ ...oldErrors, [field.id]: true }));
      } else {
        setErrors((oldErrors) => ({ ...oldErrors, [field.id]: false }));
      }
    });

    if (missingRequired) {
      return;
    }

    let issueIds;
    if (showIssuesSection && openIssues && openIssues.length !== 0) {
      issueIds = openIssues.map((issue) => issue.id);
    }
    updateStatus(
      {
        status: newStatus?.id ?? '',
        issueIds,
        category: newStatus?.id,
        ...additionalInfo,
      },
      formId
    );
  };

  const handleDateChange = (date: Date | [Date, Date] | null, id: string) => {
    if (!date || Array.isArray(date)) return;

    setAdditionalInfo({
      ...additionalInfo,
      [id]: date.toISOString(),
    });
  };

  const handleFileChange = (file: File | undefined, id: string) => {
    setAdditionalInfo({
      ...additionalInfo,
      [id]: file,
    });
  };

  const handleTextChange = (text: string, id: string) => {
    setAdditionalInfo({
      ...additionalInfo,
      [id]: text,
    });
  };

  const handleOptionChange = (newOption: SelectMenuOption, fieldId: string) => {
    setAdditionalInfo({
      ...additionalInfo,
      [fieldId]: newOption.id,
    });
  };

  const getSelectedOption = (
    fieldId: string,
    dropdownOptions: SelectMenuOption[]
  ): SelectMenuOption | undefined => {
    const optionId = additionalInfo?.[fieldId];
    if (!optionId || typeof optionId !== 'string') return undefined;

    return dropdownOptions.find((o) => o.id === optionId);
  };

  const getSelectedDate = (id: string): Date | undefined => {
    const date = additionalInfo?.[id];
    if (!date || typeof date !== 'string') return undefined;
    return new Date(date);
  };

  const getSelectedFile = (id: string): File | undefined => {
    const file = additionalInfo?.[id];
    if (!file || typeof file === 'string') return undefined;
    return file;
  };

  const getSelectedText = (id: string): string | undefined => {
    const text = additionalInfo?.[id];
    if (!text || typeof text !== 'string') return undefined;
    return text;
  };

  return (
    <Modal
      title={t(
        'admin.provider.policy.public.modal.changestatus.title',
        'Change status'
      )}
      open={open}
      setOpen={setOpen}
      handleConfirm={onSubmit}
      loading={loading}
      scrollable={!!openIssues}
      textLoading={t(
        'admin.provider.policy.public.modal.changestatus.confirming.action',
        'Confirming...'
      )}
    >
      <>
        <div
          className={classNames(styles.newStatusSection, {
            [styles.newStatusSectionCol]:
              showIssuesSection && openIssues && openIssues.length !== 0,
          })}
        >
          <h1 className={styles.sectionTitle}>
            {t(
              'admin.provider.policy.public.modal.changestatus.newstatus.title',
              'New status'
            )}
          </h1>
          <Badge
            color={newStatusColor}
            badgeType="full"
            className={classNames({
              [styles.status]:
                newStatus?.id !== 'new' ||
                !openIssues ||
                openIssues?.length === 0,
            })}
          >
            {newStatus?.text}
          </Badge>
        </div>

        {formFields.map((field) => {
          return (
            <div className={styles.section} key={field.id}>
              <label className={styles.sectionTitle} htmlFor={field.id}>
                {field.label}
                {!field.required && (
                  <span className={styles.grayText}>(optional)</span>
                )}
              </label>

              <p className={styles.description}>{field.description}</p>

              {field.field.type === 'date' && (
                <CalendarDatePicker
                  onChange={(date) => handleDateChange(date, field.id)}
                  selectedDate={getSelectedDate(field.id)}
                  className={styles.calendarWrapper}
                />
              )}

              {field.field.type === 'file' && (
                <FileDropzone
                  uploadedFile={getSelectedFile(field.id)}
                  onFileSelect={(file) => handleFileChange(file, field.id)}
                />
              )}

              {field.field.type === 'textarea' && (
                <TextArea
                  color={field.color}
                  placeholder={field.field.placeholder}
                  className="mt-2"
                  value={getSelectedText(field.id)}
                  onChange={(event) =>
                    handleTextChange(event.target.value, field.id)
                  }
                  error={errors[field.id]}
                  errorMessage={field.errorMessage ?? 'This field is required'}
                />
              )}

              {field.field.type === 'text' && (
                <Input
                  color={field.color}
                  placeholder={field.field.placeholder}
                  className="mt-2"
                  value={getSelectedText(field.id)}
                  onChange={(event) =>
                    handleTextChange(event.target.value, field.id)
                  }
                  error={errors[field.id]}
                  errorMessage={field.errorMessage ?? 'This field is required'}
                />
              )}

              {field.field.type === 'select' && (
                <div className="mt-2">
                  <SelectMenu
                    color="gray"
                    placeholder={field.field.placeholder}
                    options={field.field.options}
                    selected={getSelectedOption(field.id, field.field.options)}
                    setSelected={(selected: SelectMenuOption<any>) =>
                      handleOptionChange(selected, field.id)
                    }
                    disabled={false}
                    error={errors[field.id]}
                    errorMessage={
                      field.errorMessage ?? 'This field is required'
                    }
                  />
                </div>
              )}
            </div>
          );
        })}

        {showIssuesSection && openIssues && openIssues.length !== 0 && (
          <div className={styles.section}>
            <h1 className={styles.sectionTitle}>
              {t(
                'admin.provider.policy.public.modal.changestatus.issues.title',
                'These issues will be resolved automatically'
              )}
            </h1>
            <UnresolvedIssues issues={openIssues} setOpenModal={setOpen} />
          </div>
        )}
        {bottomDescription && (
          <div className={styles.section}>
            <p className={styles.description}>{bottomDescription}</p>
          </div>
        )}
      </>
    </Modal>
  );
};

export default ChangeStatusForm;
