import { useTranslation } from '@getpopsure/i18n-react';

const EmptyQuestionnaireState = () => {
  const { t } = useTranslation();

  return (
    <div className="flex h-[calc(100vh-300px)]">
      <div className="flex flex-1 flex-col items-center justify-center">
        <img
          src="/static/error-states/feathers.svg"
          alt="feathers"
          className="h-[80px]"
        />
        <h1 className="text-900 mt-[16px] text-xl">
          {t(
            'admin.policy.tabs.questionnaire.emptyState.title',
            'Not available yet'
          )}
        </h1>
        <p className="mt-[4px] text-sm text-gray-600">
          {t(
            'admin.policy.tabs.questionnaire.emptyState.subtitle',
            'Policy questionnaire will be available here soon'
          )}
        </p>
      </div>
    </div>
  );
};

export default EmptyQuestionnaireState;
