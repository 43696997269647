import { gql } from '@apollo/client';

import { PRIVATE_QUESTIONNAIRE_FIELDS } from './questionnaire';
import { TARIFF_INFO } from './tariffInfo';

export const UPDATE_PRIVATE_POLICY_STATUS = gql`
  mutation UpdatePrivateHealthPolicyStatus(
    $policyId: String!
    $newStatus: String!
    # Application sent
    $messageToProvider: String
    $isUrgentApplication: Boolean
    # Active
    $startDate: String
    $policyNumber: String
    # Missing info + issue creation
    $issueTitle: String
    $issueDescription: String
    # Cant be covered
    $reasonForDenial: String
    $messageToCustomer: String
    # Cancelled
    $endDate: String
    # Resolve issues
    $issueIdsToResolve: [String]
    $taskIdsToCancel: [String]
  ) {
    updatePrivateHealthPolicyStatus(
      policyId: $policyId
      newStatus: $newStatus
      messageToProvider: $messageToProvider
      isUrgentApplication: $isUrgentApplication
      startDate: $startDate
      policyNumber: $policyNumber
      issueTitle: $issueTitle
      issueDescription: $issueDescription
      reasonForDenial: $reasonForDenial
      messageToCustomer: $messageToCustomer
      endDate: $endDate
      issueIdsToResolve: $issueIdsToResolve
      taskIdsToCancel: $taskIdsToCancel
    ) {
      id
      publicStatus
      startDate
      policyNumber
      endDate
      issues {
        id
        status
        category
        tasks {
          status
        }
      }
    }
  }
`;

export const UPDATE_PRIVATE_POLICY = gql`
  ${TARIFF_INFO}
  mutation UpdatePrivatePolicyInformation(
    $source: String!
    $id: String!
    $startDate: String!
    $policyNumber: String!
    $addOns: [String!]!
    $deductible: String!
    $tariff: String!
    $sickDayPayout: Float
    $tariffStartDate: String
    $endDate: String
    $campaign: String
    $medium: String
    $term: String
    $content: String
    $companyId: String
  ) {
    updatePrivatePolicyInformation(
      source: $source
      id: $id
      startDate: $startDate
      policyNumber: $policyNumber
      endDate: $endDate
      campaign: $campaign
      medium: $medium
      content: $content
      term: $term
      companyId: $companyId
    ) {
      id
      startDate
      source
      policyNumber
      endDate
      campaign
      medium
      content
      term
      company {
        id
        name
      }
    }
    updateTariffInfo(
      sickDayPayout: $sickDayPayout
      addOns: $addOns
      deductible: $deductible
      tariff: $tariff
      policyId: $id
      tariffStartDate: $tariffStartDate
    ) {
      ...TariffInfoFields
    }
  }
`;

export const GENERATE_DEPENDENTS_CONFIRMATION = gql`
  mutation GenerateDependentConfirmationOfCoverage(
    $dateOfBirth: String!
    $startDate: String!
    $lastName: String!
    $firstName: String!
    $id: String!
  ) {
    generateDependentConfirmationOfCoverage(
      dateOfBirth: $dateOfBirth
      startDate: $startDate
      lastName: $lastName
      firstName: $firstName
      id: $id
    )
  }
`;

export const CREATE_PRIVATE_HEALTH_QUESTIONNAIRE = gql`
  mutation CreatePrivateHealthQuestionnaire(
    $policyId: String!
    $answersAsStr: String!
  ) {
    createQuestionnaire(
      policyId: $policyId
      answersAsStr: $answersAsStr
      questionnaireType: "PRIVATE_HEALTH"
    ) {
      id
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
export const _DUMMY_CREATE_QUESTIONNAIRE = gql`
  mutation _DummyCreateQuestionnaire($policyId: String!) {
    createQuestionnaire(
      policyId: $policyId
      answersAsStr: "{}"
      questionnaireType: "GENERIC"
    ) {
      id
    }
  }
`;

export const UPDATE_PRIVATE_QUESTIONNAIRE = gql`
  ${PRIVATE_QUESTIONNAIRE_FIELDS}
  mutation UpdatePrivateHealthQuestionnaire(
    $questionnaireId: String!
    $policyId: String!
    $answers: String!
    $submitApp: Boolean
  ) {
    updatePrivateHealthQuestionnaire(
      questionnaireId: $questionnaireId
      policyId: $policyId
      answers: $answers
      submitApp: $submitApp
    ) {
      id
      answers {
        ...PrivateQuestionnaireAnswerFields
      }
    }
  }
`;

export const DELETE_TARIFF_INFO = gql`
  mutation DeleteTariffInfo($tariffInfoId: String!, $policyId: String!) {
    deleteTariffInfo(tariffInfoId: $tariffInfoId, policyId: $policyId)
  }
`;

export const UPDATE_IBAN = gql`
  mutation UpdateIBAN($iban: String!, $id: String!, $paymentMethod: String) {
    updateIban(iban: $iban, id: $id, paymentMethod: $paymentMethod) {
      id
      iban
      privateHealthPaymentMethod
    }
  }
`;

export const UPDATE_PRICE_INFORMATION = gql`
  mutation UpdateTariffInfoPrice(
    $addOnsPrice: [JSONObject!]!
    $noClaimsBonus: Float!
    $yearlyCashback: Float!
    $taxDeductibleAmount: Float!
    $longTermCare: Float!
    $statutorySurcharge: Float!
    $riskSurcharge: Float!
    $tariffPrice: Float!
    $policyId: String!
    $ownContributions: Float!
    $employerContributions: Float!
  ) {
    updateTariffInfoPrice(
      addOnsPrice: $addOnsPrice
      noClaimsBonus: $noClaimsBonus
      yearlyCashback: $yearlyCashback
      taxDeductibleAmount: $taxDeductibleAmount
      longTermCare: $longTermCare
      statutorySurcharge: $statutorySurcharge
      riskSurcharge: $riskSurcharge
      tariffPrice: $tariffPrice
      policyId: $policyId
      ownContributions: $ownContributions
      employerContributions: $employerContributions
    ) {
      id
      createdAt
      updatedAt
      startDate
      endDate
      tariff
      deductible
      riskLevel
      addOns
      longTermCare
      sickDayPayout
      totalMonthlyPrice
      statutorySurcharge
      tariffMonthlyPrice
      riskFactorSurcharge
      employerContributions
      ownContributions
      noClaimsBonus
      guaranteedYearlyBonus
      taxDeductibleAmount
    }
  }
`;

export const CREATE_MANY_TARIFF_INFOS = gql`
  mutation createManyTariffInfos($tariffInfoData: [TariffInfoInput]!) {
    createManyTariffInfos(tariffInfoData: $tariffInfoData) {
      id
      createdAt
      updatedAt
      startDate
      endDate
      tariff
      deductible
      riskLevel
      addOns
      longTermCare
      sickDayPayout
      totalMonthlyPrice
      statutorySurcharge
      tariffMonthlyPrice
      riskFactorSurcharge
      employerContributions
      ownContributions
      noClaimsBonus
      guaranteedYearlyBonus
      taxDeductibleAmount
    }
  }
`;

export const DELETE_PRIVATE_POLICY_FILE = gql`
  mutation DeletePrivateHealthFile(
    $id: String!
    $blobName: String!
    $documentType: String!
  ) {
    deletePrivateHealthPolicyFile(
      id: $id
      blobName: $blobName
      documentType: $documentType
    ) {
      id
      name
      type
      blobName
    }
  }
`;

export const CREATE_PRIVATE_HEALTH_POLICY = gql`
  mutation CreatePrivateHealthPolicy(
    $startDate: String!
    $status: String!
    $provider: String!
    $file: Upload
    $price: Float
    $deductible: String!
    $tariff: String!
    $email: String
    $addOns: [String]
    $dateOfBirth: String
    $lastName: String
    $firstName: String
    $policyNumber: String
    $customerId: String
  ) {
    createPrivateHealthPolicy(
      customerId: $customerId
      policyNumber: $policyNumber
      startDate: $startDate
      status: $status
      provider: $provider
      file: $file
      price: $price
      addOns: $addOns
      deductible: $deductible
      tariff: $tariff
      email: $email
      dateOfBirth: $dateOfBirth
      lastName: $lastName
      firstName: $firstName
    ) {
      id
    }
  }
`;

export const REGENERATE_PRIVATE_HEALTH_FILE = gql`
  mutation RegeneratePrivateHealthFile($category: String!, $id: String!) {
    regeneratePrivateHealthFile(type: $category, policyId: $id)
  }
`;
