import { gql } from '@apollo/client';

export const GET_HR_COMPANY_PENSION_POLICIES = gql`
  query HrCompanyPensionPolicies(
    $company: String!
    $filterStatus: [String!]!
    $sortOrder: String!
    $sortColumn: String!
    $searchString: String!
    $offset: Float!
    $limit: Float!
  ) {
    hrCompanyPensionPolicies(
      company: $company
      filterStatus: $filterStatus
      sortOrder: $sortOrder
      sortColumn: $sortColumn
      searchString: $searchString
      offset: $offset
      limit: $limit
    ) {
      id
      startDate
      pensionDetails {
        ownContribution
        employerContribution
        totalContribution
        yearlyContribution
        financingType
        paymentMethod
      }
      policyNumber
      publicStatus
      user {
        firstName
        lastName
      }
    }
    totalHrCompanyPensionPolicies(
      company: $company
      filterStatus: $filterStatus
      searchString: $searchString
    )
  }
`;
