import { useMutation } from '@apollo/client';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import classnames from 'classnames';
import Button from 'components/Button';
import FileErrorCard from 'components/FileErrorCard';
import Modal from 'components/Modal';
import { PolicyCard } from 'components/PolicyCard';
import { UPDATE_SCANNED_DOCUMENT } from 'pages/scannedDocuments/scannedDocuments.mutations';
import { GET_SCANNED_DOCUMENT_BY_ID } from 'pages/scannedDocuments/scannedDocuments.queries';
import { ScannedDocument } from 'pages/scannedDocuments/scannedDocuments.types';
import { getPolicyDetailsBaseURLFromInsuranceType } from 'pages/scannedDocuments/scannedDocuments.utils';
import { useState } from 'react';
import { insuranceIconMapper } from 'shared/insurances/insuranceIconMapper';
import { insuranceNameMapper } from 'shared/insurances/insuranceNameMapper';

import * as styles from './ScannedDocument.ShowInAccount.styles';

export const ShowInAccount = ({
  scannedDocument,
}: {
  scannedDocument: ScannedDocument;
}) => {
  const {
    id,
    visibleInUserAccount: documentShouldBeVisibleInUserAccount,
    userPolicyId,
  } = scannedDocument;

  const matchingPolicy =
    scannedDocument.userPolicy ?? scannedDocument.relatedUserPolicy;

  const [showModal, setShowModal] = useState(false);

  // Take into account if userPolicyId is missing for some reason
  const visibleInUserAccount =
    documentShouldBeVisibleInUserAccount && !!userPolicyId;

  const [setVisibleInUserAccount, { loading }] = useMutation(
    UPDATE_SCANNED_DOCUMENT,
    {
      variables: {
        id,
        visibleInUserAccount: !visibleInUserAccount,
        userPolicyId: matchingPolicy?.id,
      },
      refetchQueries: [GET_SCANNED_DOCUMENT_BY_ID],
    }
  );

  const handleConfirm = async () => {
    await setVisibleInUserAccount();
    setShowModal(false);
  };

  if (!matchingPolicy) {
    return null;
  }

  const confirmationRequired = !scannedDocument.userPolicyId;

  return (
    <>
      {showModal && (
        <Modal
          title={
            visibleInUserAccount
              ? 'Hide document from user account?'
              : 'Show document in user account?'
          }
          open={showModal}
          setOpen={setShowModal}
          confirmButtonLabel={visibleInUserAccount ? 'Hide' : 'Show'}
          handleConfirm={handleConfirm}
        >
          <div className="flex flex-col">
            {confirmationRequired && (
              <FileErrorCard
                open={true}
                className="mb-5 mt-5"
                title="Unconfirmed relation"
                hideCloseButton={true}
                handleClose={() => {}}
                errorType="WARNING"
                description="Accepting this action will also confirm that below policy belongs to this document."
              />
            )}
            <p className={classnames(styles.label, 'mt-5')}>Matching policy</p>
            <PolicyCard
              title={
                (matchingPolicy?.insuranceType &&
                  insuranceNameMapper[matchingPolicy?.insuranceType]) ??
                ''
              }
              icon={
                (matchingPolicy?.insuranceType &&
                  insuranceIconMapper[matchingPolicy.insuranceType]) ??
                ''
              }
              link={
                getPolicyDetailsBaseURLFromInsuranceType(
                  matchingPolicy?.insuranceType,
                  matchingPolicy?.id
                ) ?? ''
              }
              insuredPerson={`${matchingPolicy?.user.firstName} ${matchingPolicy?.user.lastName}`}
              policyNumber={matchingPolicy?.policyNumber}
              regionOfPurchase={matchingPolicy.regionOfPurchase}
              smallVersion
              openInNewTab
            />
            {visibleInUserAccount ? (
              <p className={styles.bottomText}>
                Once confirmed, this document will no longer be shown on the
                policy&apos;s respective details page in the user account.
              </p>
            ) : (
              <>
                <p className={styles.bottomTextBold}>
                  Once confirmed, this document will show on the related
                  policy&apos;s respective details page in the user account.
                </p>
              </>
            )}
          </div>
        </Modal>
      )}
      {visibleInUserAccount ? (
        <Button
          loading={loading}
          buttonType="primary"
          className="flex gap-2"
          onClick={() => setShowModal(true)}
        >
          <span className="flex gap-2">
            <div className="w-5">
              <EyeOffIcon />
            </div>
            <span>Hide from account</span>
          </span>
        </Button>
      ) : (
        <Button
          loading={loading}
          buttonType="white"
          className="flex gap-2"
          onClick={() => setShowModal(true)}
        >
          <span className="flex gap-2">
            <div className="w-5">
              <EyeIcon />
            </div>
            <span>Show in account</span>
          </span>
        </Button>
      )}
    </>
  );
};
