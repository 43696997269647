import { useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import ActivityCircle from 'components/ActivityCircle';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { DATE_FORMAT_TIME } from 'models/date';
import { Status } from 'models/statusColor';
import { getAuthor } from 'shared/getAuthor';

import { Activity } from '../ActivityCards';
import * as styles from './styles';

dayjs.extend(relativeTime);

interface CommissionChangeActivityCardProps {
  activityCard: Activity;
  smallVersion: boolean;
  hasActivityLine: boolean;
  policyId?: string;
  statusMapping?: (status: string) => Status;
  showCustomerInformed: boolean;
  providerView?: boolean;
}

const CommissionChangeActivityCard = ({
  activityCard,
  smallVersion,
  hasActivityLine,
}: CommissionChangeActivityCardProps) => {
  const { t } = useTranslation();
  const activityAuthor = getAuthor(activityCard.author);
  return (
    <div className={styles.cardWrapper}>
      <ActivityCircle
        activityType={activityCard.type}
        smallVersion={smallVersion}
        hasActivityLine={hasActivityLine}
      />
      <div className={smallVersion ? styles.cardSmall : styles.cardFull}>
        <p className={styles.lightText}>
          <>
            {activityAuthor && (
              <p
                className={classNames(
                  styles.darkText,
                  styles.inlineText,
                  'mr-1'
                )}
              >
                {activityAuthor}
              </p>
            )}
            {activityCard.newCommissionStatus === 'ACCEPTED' &&
              t(
                'admin.provider.policy.public.activity.changecommission.approve.subtitle',
                'confirmed the commision entitlement'
              )}
            {activityCard.newCommissionStatus === 'DECLINED' &&
              t(
                'admin.provider.policy.public.activity.changecommission.decline.subtitle',
                'declined the commision entitlement'
              )}
          </>
          <p
            className={classNames(
              styles.veryLightText,
              styles.inlineText,
              'ml-1'
            )}
          >
            {dayjs(activityCard.createdAt).format(DATE_FORMAT_TIME)}
          </p>
        </p>
      </div>
    </div>
  );
};

export default CommissionChangeActivityCard;
