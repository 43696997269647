import classNames from 'classnames';

export const text = classNames('text-sm', 'text-gray-600');

export const newStatusSection = classNames('mt-[16px]');

export const section = classNames('mt-[24px]');

export const sectionTitle = classNames(
  'mb-[8px]',
  'text-sm',
  'text-gray-900',
  'font-bold'
);

export const textarea = classNames('mt-[10px]');

export const radioButtons = classNames(
  'flex',
  'items-center',
  'space-x-[32px]'
);

export const divider = classNames(
  'w-full',
  'border-t',
  'border-gray-300',
  'mt-[20px]'
);

export const createIssueSectionWrapper = classNames(
  'transition-max-height',
  'ease-out',
  'duration-500'
);
