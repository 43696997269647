import { useTranslation } from '@getpopsure/i18n-react';
import LoadingState from 'components/LoadingState';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { paths } from 'routes/definedPaths';

const GoogleLoginFailure = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    history.replace({
      pathname: paths.auth.path,
      state: { googleLoginFailure: true },
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Redirect - Feather Admin Panel</title>
      </Helmet>
      <LoadingState
        message={t('admin.signin.redirect.title', 'Redirecting...')}
      />
    </>
  );
};

export default GoogleLoginFailure;
