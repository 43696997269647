import { BadgeColor } from 'components/Badge/Badge';

import { LifeStatus } from '../types';

export const lifeStatusMapper: Record<LifeStatus, string> = {
  PENDING: 'Pending',
  APPLICATION_SENT: 'Sent',
  ACTIVE: 'Covered',
  CANCELED: 'Canceled',
  DROPPED_OUT: 'Canceled',
};

export const lifeBadgeColorMapper: Record<LifeStatus, BadgeColor> = {
  PENDING: 'purple',
  APPLICATION_SENT: 'blue',
  ACTIVE: 'green',
  CANCELED: 'gray',
  DROPPED_OUT: 'gray',
};
