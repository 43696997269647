import CheckBadge from 'components/CheckBadge';
import { CheckBadgeType } from 'components/CheckBadge/CheckBadge';

interface TableCellCheckProps {
  data: unknown;
  getCheck: (data: any) => {
    check: CheckBadgeType;
    badgeCaption?: string;
  };
}

export const TableCellCheck = ({ data, getCheck }: TableCellCheckProps) => {
  const cellData = getCheck(data);

  return (
    <div className="relative z-10">
      <CheckBadge
        checkType={cellData.check}
        badgeCaption={cellData.badgeCaption}
      />
    </div>
  );
};
