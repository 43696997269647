import { ApolloError } from '@apollo/client';
import OverviewPage from 'components/OverviewPage';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import Table from 'components/TableRefactor/Table';
import { HeaderConfig, RowConfig } from 'components/TableRefactor/types';
import { TkApiRequest } from 'models/tkApiRequest';
import { useState } from 'react';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';
import { getInternalPublicHealthStatus } from 'shared/mapInternalPublicHealthStatus';

import { GET_TK_API_REQUESTS } from './graphql/requests';

const TkApiRequestsPage = () => {
  const [error, setError] = useState<ApolloError | undefined>();
  const rowConfig: RowConfig = {
    getResourceLink: (data: TkApiRequest) =>
      generatePath(paths.tkApiRequests.details.path, {
        id: data.id,
      }),
    cells: [
      {
        type: 'TEXT',
        textColor: 'dark',
        props: {
          getText: (data: TkApiRequest) => data.userPolicy.user.firstName ?? '',
        },
      },
      {
        type: 'TEXT',
        textColor: 'dark',
        props: {
          getText: (data: TkApiRequest) => data.userPolicy.user.lastName ?? '',
        },
      },
      {
        type: 'STATUS',
        textColor: 'light',
        props: {
          getStatus: (data: TkApiRequest) =>
            getInternalPublicHealthStatus(data.userPolicy.publicStatus ?? ''),
        },
      },
      {
        type: 'STATUS',
        textColor: 'light',
        props: {
          getStatus: (data: TkApiRequest) => {
            if (data.status === 'SUCCESS') {
              return {
                color: 'green',
                text: { text: 'Success', id: 'SUCCESS' },
              };
            }
            if (data.status === 'FAIL') {
              return {
                color: 'red',
                text: { text: 'Fail', id: 'FAIL' },
              };
            }
            if (data.status === 'PENDING') {
              return {
                color: 'yellow',
                text: { text: 'Pending', id: 'PENDING' },
              };
            }
          },
        },
      },
      {
        type: 'TEXT',
        textColor: 'light',
        props: {
          getText: (data: TkApiRequest) => data.externalId ?? '',
        },
      },
      {
        type: 'DATE',
        textColor: 'light',
        props: {
          getDate: (data: TkApiRequest) => data.createdAt ?? '',
        },
      },
      {
        type: 'LINK',
        textColor: 'light',
        props: {
          getLink: (data: TkApiRequest) => ({
            label: 'Policy',
            href: generatePath(
              paths.policies.publicHealth.internal.details.path,
              {
                id: data.userPolicy.id,
              }
            ),
          }),
        },
      },
    ],
  };

  const headers: HeaderConfig[] = [
    {
      id: 'firstName',
      label: 'First name',
      component: ColumnHeader,
    },
    {
      id: 'lastName',
      label: 'Last name',
      component: ColumnHeader,
    },
    {
      id: 'policyStatus',
      label: 'Policy status',
      component: ColumnHeader,
    },
    {
      id: 'status',
      label: 'Status',
      component: ColumnHeader,
    },
    {
      id: 'externalId',
      label: 'External ID',
      component: ColumnHeader,
    },
    {
      id: 'createdAt',
      label: 'Created at',
      component: ColumnHeader,
    },
    {
      id: 'policy',
      label: '',
      component: ColumnHeader,
    },
  ];

  return (
    <OverviewPage
      title="TK API Requests - Feather Admin Panel"
      current="tkApiRequests"
      error={error}
    >
      <Table
        title="TK API Requests"
        query={GET_TK_API_REQUESTS}
        itemsPerPage={24}
        searchPlaceholder="Search by customer name or email"
        headers={headers}
        rowConfig={rowConfig}
        emptyStateLabel={{
          title: 'There are no requests.',
          description: 'It looks like there are no TK API requests yet.',
        }}
        onError={setError}
      />
    </OverviewPage>
  );
};

export default TkApiRequestsPage;
