import { capitalizeName } from '@getpopsure/public-utility';
import { PolicyTemplateData } from 'pages/policies/template/models/PolicyTemplateData';
import { insuranceNameMapper } from 'shared/insurances/insuranceNameMapper';
import { policyDocumentCategories } from 'shared/policyDocumentCategories';

import { NUMBER_OF_POLICIES_PER_PAGE } from '../template/constants';
import { getDocumentSections } from '../template/defaultPolicyDocumentsConfig';
import { TemplateInsuranceType } from '../template/models/InsuranceTypes';
import { getDbInsuranceType } from '../template/utils';
import { getOverviewTabCards } from './data/overviewTabData';
import { getQuestionnaireData } from './data/questionnaireData';
import {
  extendedValidatorObject,
  getLegalModals,
  legalNextStatuses,
  legalStatusOptions,
  retrieveModalId,
} from './data/statusData';
import { rowConfig, tableHeaders } from './data/tableData';
import { tableFilters } from './data/tableFilters';
import { GET_LEGAL_POLICIES } from './graphql/policies';
import { GET_LEGAL_POLICY } from './graphql/singlePolicy';
import { UPDATE_LEGAL_QUESTIONNAIRE } from './graphql/updateQuestionnaire';
import { UPDATE_LEGAL_POLICY_STATUS } from './graphql/updateStatus';
import { LegalPoliciesData } from './models/LegalPolicies';
import { LegalPolicyData } from './models/LegalPolicy';

const insurancePath = 'legal';
const insuranceType = getDbInsuranceType(
  insurancePath
) as TemplateInsuranceType;
const insuranceName = insuranceNameMapper[insuranceType];

export const getLegalPoliciesTemplateData = (): PolicyTemplateData<
  LegalPoliciesData,
  LegalPolicyData,
  string
> => {
  return {
    insuranceType,
    navigation: {
      current: `policies-${insurancePath}`,
    },
    queries: {
      allPolicies: GET_LEGAL_POLICIES,
      policy: GET_LEGAL_POLICY,
    },
    mutations: {
      statusChange: UPDATE_LEGAL_POLICY_STATUS,
      editQuestionnaire: UPDATE_LEGAL_QUESTIONNAIRE,
    },
    copy: {
      table: {
        title: `${insuranceName} policies`,
        searchPlaceholder:
          'Search by name, email, policy number, policy id or utm source',
      },
      details: {
        pageTitle: `${insuranceName} policy - Feather Admin Panel`,
        title: insuranceName,
      },
    },
    features: {
      overviewTable: {
        numberOfRowsPerPage: NUMBER_OF_POLICIES_PER_PAGE,
        headersConfig: tableHeaders,
        filtersConfig: tableFilters,
        rowConfig,
      },
      details: {
        queryVariables: {
          insuranceType,
        },
        insuredPersonName: (data?: LegalPolicyData) =>
          capitalizeName({
            firstName: data?.legalPolicy.insuredPerson?.firstName ?? '',
            lastName: data?.legalPolicy.insuredPerson?.lastName ?? '',
          }) ?? '',
        overviewTab: {
          getCards: getOverviewTabCards,
        },
      },
      statusChange: {
        statusOptions: legalStatusOptions,
        statusChangeOptions: legalNextStatuses,
        getModals: getLegalModals,
        retrieveModalId,
        extendedValidatorObject,
        additionalQueryVariables: { insuranceType },
      },
      documents: {
        uploadOptions: policyDocumentCategories,
        getDocumentSections: (data?: LegalPolicyData) =>
          getDocumentSections(data?.legalPolicy),
      },
      questionnaire: {
        cardTitle: 'Personal information',
        editable: true,
        getQuestionnaireData,
      },
    },
  };
};
