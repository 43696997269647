import { gql } from '@apollo/client';

export const GET_DENTAL_CLAIM = gql`
  query GetDentalClaim($claimId: String!) {
    dentalClaim(claimId: $claimId) {
      id
      user {
        id
        firstName
        lastName
        email
        isDelinquent
        isFraudulent
        fraudulentAdditionalInfo
      }
      intercomEuUserId
      stripeCustomerToken
      status
      createdAt
      amount
      iban
      refundAmount
      featherPaidOn
      customerPaidOn
      claimNumber
      claimType {
        id
        type
      }
      submissions {
        id
        createdAt
        submissionDetails
        errorDetails
      }
      policy {
        id
        publicStatus
        startDate
        plan
        insuredPerson {
          firstName
          lastName
        }
        policyNumber
      }
      documents {
        userUploads {
          id
          url
          blobName
          resizedUrl
        }
        scannedDocuments {
          id
          url
          blobName
        }
        featherUploads {
          id
          url
          blobName
          resizedUrl
          isVisibleToCustomer
          internalNotes
          friendlyName
          category
        }
        providerUploads {
          id
          url
          blobName
          resizedUrl
          isVisibleToCustomer
          internalNotes
          friendlyName
          category
        }
        archivedDocuments {
          id
          url
          blobName
          resizedUrl
          isVisibleToCustomer
          archivedAt
          internalNotes
          friendlyName
          category
        }
      }

      issues {
        id
        category
        description
        status
        title
        createdAt
        updatedAt
        author {
          firstName
          lastName
        }

        tasks {
          id
          description
          status
          data
          createdAt
          finishedAt
        }
      }

      pinnedNotes {
        id
        body
        createdAt
        adminUser {
          firstName
          lastName
        }
        updatedAt
      }
    }
  }
`;
