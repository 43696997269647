import classNames from 'classnames';

export const feathers = classNames('h-[96px]', 'mb-[32px]');

export const contentWrapper = classNames(
  'absolute',
  'flex',
  'flex-col',
  'justify-center',
  'items-center',
  'mx-auto',
  'right-0',
  'left-0',
  'my-auto',
  'top-0',
  'bottom-0'
);

export const title = classNames('text-2xl', 'font-medium', 'text-gray-900');

export const text = classNames(
  'text-base',
  'font-normal',
  'text-gray-600',
  'mt-[8px]',
  'text-center',
  'w-[400px]'
);
