import classNames from 'classnames';

export const tableWrapper = classNames('rounded-[4px]', 'bg-gray-200');

export const tableContent = classNames(
  'mt-2',
  'align-middle',
  'rounded-[4px]',
  'overflow-x-scroll',
  'p-1'
);

export const table = classNames('w-full');

export const tableHeader = classNames(
  'bg-white',
  'border-b',
  'border-gray-300'
);

export const tableCell = classNames(
  'bg-white',
  'border-t',
  'border-t-gray-200',
  'p-3',
  'text-left'
);

export const link = classNames(
  'text-sm',
  'row-cell',
  'text-indigo-500',
  'hover:text-indigo-900',
  'flex',
  'items-center',
  'gap-2'
);

export const icon = classNames('w-[16px]');

export const bottomText = classNames(
  'text-sm',
  'font-bold',
  'text-gray-600',
  'mt-[24px]'
);

export const textUnchanged = '';
export const textNew = classNames('font-bold', 'text-green-500');
export const textUpdated = classNames('font-bold', 'text-blue-500');
