import './index.css';
import 'i18n';

import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import FlagProvider from '@unleash/proxy-client-react';
import App from 'App';
import ErrorBoundary from 'components/ErrorBoundary';
import { client } from 'graphql/client';
import ReactDOM from 'react-dom';
import { unleashConfig } from 'unleash.config';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://61f2eccb4ee74763a5289ad7d1f340a8@o163991.ingest.sentry.io/6150048',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_DEPLOY_ENV,
    tracesSampleRate: 1.0,
    maxValueLength: 250,
    allowUrls: [
      'admin.staging.feather-insurance.com',
      'admin.feather-insurance.com',
    ],
  });
}

ReactDOM.render(
  <FlagProvider config={unleashConfig}>
    <ApolloProvider client={client}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </ApolloProvider>
  </FlagProvider>,
  document.getElementById('root')
);
