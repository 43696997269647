export const getAOKProviderBranches = () => {
  return [
    {
      id: 'AOK',
      label: 'AOK',
    },
    {
      id: 'AOK_BW',
      label: 'AOK Baden-Württemberg',
    },
    {
      id: 'AOK_BY',
      label: 'AOK Bayern',
    },
    {
      id: 'AOK_HB',
      label: 'AOK Bremen/Bremerhaven',
    },
    {
      id: 'AOK_HE',
      label: 'AOK Hessen',
    },
    {
      id: 'AOK_NI',
      label: 'AOK Niedersachsen',
    },
    {
      id: 'AOK_NE',
      label: 'AOK Nordost',
    },
    {
      id: 'AOK_NW',
      label: 'AOK NordWest',
    },
    {
      id: 'AOK_RL_HH',
      label: 'AOK Rheinland/Hamburg',
    },
    {
      id: 'AOK_RP_SL',
      label: 'AOK Rheinland-Pfalz/Saarland',
    },
    {
      id: 'AOK_SN_TH',
      label: 'AOK Sachsen und Thüringen',
    },
    {
      id: 'AOK_ST',
      label: 'AOK Sachsen-Anhalt',
    },
  ];
};

export const mapProviderBranch = (providerBranch: string) => {
  return (
    getAOKProviderBranches().find((branch) => branch.id === providerBranch)
      ?.label ?? providerBranch
  );
};
