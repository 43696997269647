import { useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import ActivityCircle from 'components/ActivityCircle';
import IssueCard from 'components/IssueCard';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { DocumentNode } from 'graphql';
import { Status } from 'models/statusColor';
import { getAuthor } from 'shared/getAuthor';

import { Activity } from '../ActivityCards';
import * as styles from './styles';

dayjs.extend(relativeTime);

interface IssueCreationActivityCardProps {
  activityCard: Activity;
  smallVersion: boolean;
  hasActivityLine: boolean;
  policyId?: string;
  statusMapping?: (status: string) => Status;
  showCustomerInformed: boolean;
  providerView?: boolean;
  issueStatusChangeMutation?: DocumentNode;
  policyQuery?: DocumentNode;
  uploadFileMutation?: DocumentNode;
  issueCategory?: string;
}

const IssueCreationActivityCard = ({
  activityCard,
  smallVersion,
  hasActivityLine,
  policyId,
  showCustomerInformed,
  providerView = true,
  issueCategory,
  issueStatusChangeMutation,
  policyQuery,
  uploadFileMutation,
}: IssueCreationActivityCardProps) => {
  const { t } = useTranslation();
  const activityAuthor = getAuthor(activityCard.author);

  return (
    <div className={styles.cardWrapper}>
      <ActivityCircle
        activityType={activityCard.type}
        smallVersion={smallVersion}
        hasActivityLine={hasActivityLine}
      />
      <div className={styles.card}>
        <p className={classNames(styles.textWrapper, styles.lightText)}>
          {activityAuthor && (
            <p className={classNames(styles.darkText, styles.inlineText)}>
              {activityAuthor}
            </p>
          )}
          {activityAuthor
            ? t(
                'admin.provider.policy.public.activity.createdissue.subtitle',
                'created a new issue'
              )
            : t(
                'admin.provider.policy.public.activity.system.createdissue.subtitle',
                'New issue automatically created'
              )}
        </p>
        {activityCard.issue && (
          <IssueCard
            policyId={policyId ?? ''}
            showAuthor={false}
            issue={activityCard.issue}
            smallVersion={smallVersion}
            showStatus={!smallVersion}
            showCustomerInformed={showCustomerInformed}
            cardColor={smallVersion ? 'gray' : 'white'}
            providerView={providerView}
            issueCategory={issueCategory}
            issueStatusChangeMutation={issueStatusChangeMutation}
            policyQuery={policyQuery}
            uploadFileMutation={uploadFileMutation}
          />
        )}
      </div>
    </div>
  );
};

export default IssueCreationActivityCard;
