import classNames from 'classnames';

export const dropzone = classNames(
  'flex',
  'mb-[8px]',
  'items-center',
  'justify-center',
  'min-h-[155px]',
  'w-full',
  'border',
  'border-indigo-500',
  'p-[26px]',
  'rounded-[6px]',
  'transition-all',
  'duration-50',
  'ease-linear'
);

export const dragActive = classNames('border-solid', 'bg-indigo-200');

export const dragInactive = classNames('border-dashed', 'bg-white');

export const dropzoneContent = classNames(
  'flex',
  'flex-col',
  'items-center',
  'justify-center',
  'h-full'
);

export const uploadIcon = classNames(
  'text-indigo-500',
  'w-[50px]',
  '!stroke-1'
);

export const uploadText = classNames('text-sm', 'text-gray-600', 'mt-[4px]');

export const textHighlight = classNames('text-indigo-500');

export const uploadRequirementsText = classNames(
  'text-sm',
  'text-gray-500',
  'mt-[4px]'
);
