import { DentalClaimType } from './models';

export const claimTypeNameMapper: Record<DentalClaimType, string> = {
  COST_PLAN: 'Cost plan',
  DENTAL_CLEANING: 'Dental cleaning',
  HIGH_QUALITY_FILLING: 'High-quality filling',
  ROOT_CANAL_OR_PERIO_TREATMENT: 'Root canal or perio treatment',
  MOUTHGUARD: 'Mouthguard',
  EXTRACTION: 'Extraction',
  REPLACEMENT: 'Replacement',
  WHITENING: 'Whitening',
  OTHER: 'Other',
};
