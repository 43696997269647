import {
  DocumentSection,
  DocumentsSection as DocumentsCard,
} from 'components/DocumentsSection/DocumentsSection';
import { PrivateHealthPolicy } from 'models/privateHealthPolicy';
import { REGENERATE_PRIVATE_HEALTH_FILE } from 'pages/policies/privateHealth/graphql/mutations';
import { GET_PRIVATE_POLICY } from 'pages/policies/privateHealth/graphql/queries';
import { EditButton } from 'pages/policies/template/EditButton/EditButton';
import {
  ARCHIVE_POLICY_DOCUMENT,
  DELETE_POLICY_DOCUMENT,
  EDIT_POLICY_DOCUMENT,
  GENERATE_POLICY_DOCUMENTS_ARCHIVE,
  GET_POLICY_DOCUMENTS_ARCHIVE,
  RESTORE_POLICY_DOCUMENT,
  UPLOAD_POLICY_DOCUMENT,
} from 'pages/policies/template/mutations';
import { UploadButton } from 'pages/policies/template/UploadButton/UploadButton';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';
import { policyDocumentCategories } from 'shared/policyDocumentCategories';
import { retrieveFileFriendlyName } from 'shared/utils/retrieveFileFriendlyName';

interface DocumentsSectionProps {
  policy: PrivateHealthPolicy;
}

const DocumentsSection = ({ policy }: DocumentsSectionProps) => {
  const featherUploads: DocumentSection = {
    title: 'Feather uploads',
    documents: [],
  };

  const customerUploads: DocumentSection = {
    title: 'Customer uploads',
    documents: [],
  };

  const checkoutDownloads: DocumentSection = {
    title: 'Checkout downloads',
    documents: [],
  };

  const providerUploads: DocumentSection = {
    title: 'Provider uploads',
    documents: [],
  };

  const archivedDocuments: DocumentSection = {
    title: 'Archived documents',
    documents: [],
    defaultOpen: false,
  };

  const hasConfirmationOfCoverage = policy.policyDocuments?.find(
    (document) => document.category === 'CONFIRMATION_OF_COVERAGE'
  );

  const hasLetterForEmployer = policy.policyDocuments?.find(
    (document) => document.category === 'LETTER_FOR_EMPLOYER'
  );

  if (!hasConfirmationOfCoverage && policy.publicStatus === 'ACTIVE') {
    featherUploads.documents.push({
      id: '',
      fileName: 'Confirmation of coverage',
      category: 'CONFIRMATION_OF_COVERAGE',
      canRegenerate: true,
    });
  }

  if (
    !hasLetterForEmployer &&
    policy.insuredPerson?.occupation === 'EMPLOYED' &&
    policy.publicStatus === 'ACTIVE'
  ) {
    featherUploads.documents.push({
      id: '',
      fileName: 'Letter for employer',
      category: 'LETTER_FOR_EMPLOYER',
      canRegenerate: true,
    });
  }

  policy?.policyDocuments
    ?.filter((document) => !document.archivedAt && !document.isFromProvider)
    .forEach((document) => {
      featherUploads.documents.push({
        ...document,
        friendlyName: document.name,
        fileName: document.blobName
          ? retrieveFileFriendlyName(document.blobName)
          : document.name,
        canArchive: true,
        canDelete: true,
        canEdit: true,
        canRegenerate:
          document.category === 'CONFIRMATION_OF_COVERAGE' ||
          document.category === 'LETTER_FOR_EMPLOYER',
      });
    });

  policy?.customerUploads?.forEach((document) => {
    customerUploads.documents.push({
      ...document,
      friendlyName: document.name,
      fileName: retrieveFileFriendlyName(document.blobName),
    });
  });

  policy?.questionnaireDownloadDocuments?.forEach((document) => {
    checkoutDownloads.documents.push({
      ...document,
      fileName: retrieveFileFriendlyName(document.blobName),
    });
  });

  policy?.policyDocuments
    ?.filter((document) => document.isFromProvider && !document.archivedAt)
    .forEach((document) => {
      providerUploads.documents.push({
        ...document,
        friendlyName: document.name,
        fileName: document.blobName
          ? retrieveFileFriendlyName(document.blobName)
          : document.name,
        canArchive: true,
        canDelete: true,
        canEdit: true,
      });
    });

  policy?.policyDocuments
    ?.filter((document) => document.archivedAt)
    .forEach((document) => {
      archivedDocuments.documents.push({
        ...document,
        friendlyName: document.name,
        fileName: document.blobName
          ? retrieveFileFriendlyName(document.blobName)
          : document.name,
        canArchive: true,
        canDelete: true,
      });
    });

  const scannedDocuments: DocumentSection = {
    title: 'Scanned documents',
    documents:
      policy?.scannedDocuments?.flatMap(
        ({ url, blobName, id, visibleInUserAccount, friendlyName }) => [
          {
            id,
            url,
            fileName: retrieveFileFriendlyName(blobName),
            friendlyName,
            resizedUrl: url,
            blobName,
            isVisibleToCustomer: visibleInUserAccount,
            detailsUrl: generatePath(paths.scannedDocuments.details.path, {
              id,
            }),
          },
        ]
      ) ?? [],
  };

  return (
    <DocumentsCard
      sections={[
        featherUploads,
        customerUploads,
        providerUploads,
        checkoutDownloads,
        scannedDocuments,
        archivedDocuments,
      ]}
      documentCategories={policyDocumentCategories}
      getResourceQuery={GET_PRIVATE_POLICY}
      resourceId={policy.id}
      deleteMutation={DELETE_POLICY_DOCUMENT}
      regenerateMutation={REGENERATE_PRIVATE_HEALTH_FILE}
      editMutation={EDIT_POLICY_DOCUMENT}
      archiveMutation={ARCHIVE_POLICY_DOCUMENT}
      restoreMutation={RESTORE_POLICY_DOCUMENT}
      downloadAllMutation={GENERATE_POLICY_DOCUMENTS_ARCHIVE}
      getDocumentArchiveQuery={GET_POLICY_DOCUMENTS_ARCHIVE}
      documentVisibilityOptions={[
        { id: 'CUSTOMER', label: 'Customer' },
        { id: 'PROVIDER', label: 'Provider' },
        { id: 'HR', label: 'HR' },
      ]}
      uploadButton={
        <UploadButton
          mutation={UPLOAD_POLICY_DOCUMENT}
          documentCategories={policyDocumentCategories}
          resourceId={policy.id}
          documentVisibilityOptions={[
            { id: 'CUSTOMER', label: 'Customer' },
            { id: 'PROVIDER', label: 'Provider' },
            { id: 'HR', label: 'HR' },
          ]}
          getResourceQuery={GET_PRIVATE_POLICY}
        />
      }
      editButton={EditButton}
    />
  );
};

export default DocumentsSection;
