import { BadgeColor } from 'components/Badge/Badge';

import { HouseholdStatus } from '../types';

export const householdStatusMapper: Record<HouseholdStatus, string> = {
  PENDING: 'Pending',
  ACTIVE: 'Covered',
  CANCELED: 'Canceled',
  DROPPED_OUT: 'Canceled',
};

export const householdBadgeColorMapper: Record<HouseholdStatus, BadgeColor> = {
  PENDING: 'purple',
  ACTIVE: 'green',
  CANCELED: 'gray',
  DROPPED_OUT: 'gray',
};
