import Loader from 'components/Loader';
import { useEffect, useState } from 'react';

import * as styles from './styles';

const TIMEOUT_LENGTH = 1_500;

export const TimedLoader = () => {
  const [displayLoader, setDisplayLoader] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayLoader(true);
    }, TIMEOUT_LENGTH);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={styles.container} data-testid="timed-loader">
      {displayLoader && (
        <div className={styles.loaderWrapper}>
          <Loader className={styles.loader} />
        </div>
      )}
    </div>
  );
};
