import classNames from 'classnames';

export const button = classNames('flex', 'items-center');

export const options = classNames('flex', 'items-center');

export const option = classNames('cursor-pointer', 'text-sm', 'text-gray-500');
export const optionSelected = classNames('text-indigo-500', 'font-bold');

export const separator = classNames('mx-[2px]', 'text-gray-400', 'leading-5');
