/**
 * NOTE: This type is not being shared with BE.
 * When updating, also update BE type
 *
 * Notion page for all vertical claim statuses:
 * https://www.notion.so/featherinsurance/Claim-statuses-2-0-22713a3fe92c49969b2e403f655678b2
 */
export const dentalClaimStatuses = [
  'SUBMITTED_TO_PROVIDER',
  'RECEIVED',
  'INCOMPLETE',
  'MISSING_INFO_RECEIVED',
  'FEATHER_PAID_OUT',
  'CUSTOMER_PAID_OUT',
  'APPROVED',
  'DENIED',
  'DUPLICATE',
  'DROPPED_OUT',
  'CLOSED_WITHOUT_PAYMENT',
  'CLOSED',
  'OTHER_PAID_OUT',
] as const;
export type DentalClaimStatus = typeof dentalClaimStatuses[number];

export const deprecatedDentalClaimStatuses: DentalClaimStatus[] = [
  'CLOSED_WITHOUT_PAYMENT',
  'OTHER_PAID_OUT',
];

export const dentalClaimTypes = [
  'COST_PLAN',
  'DENTAL_CLEANING',
  'HIGH_QUALITY_FILLING',
  'ROOT_CANAL_OR_PERIO_TREATMENT',
  'MOUTHGUARD',
  'EXTRACTION',
  'REPLACEMENT',
  'WHITENING',
  'OTHER',
] as const;
export type DentalClaimType = typeof dentalClaimTypes[number];
