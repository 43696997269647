import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import { HeaderConfig, RowConfig } from 'components/TableRefactor/types';
import { Status } from 'models/statusColor';
import { policyRouteMapper } from 'shared/insurances/policyRouteMapper';
import {
  dogLiabilityBadgeColorMapper,
  dogLiabilityStatusMapper,
} from 'shared/insurances/statusMappers/dogLiability';
import { DogLiabilityStatus } from 'shared/insurances/types';

import { DogLiabilityPolicy } from '../models/DogLiabilityPolicy';

export const tableHeaders: HeaderConfig[] = [
  {
    id: 'firstName',
    label: 'First name',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    sticky: true,
    threshold: 'left-0',
    border: 'none',
    component: SortableColumnHeader,
  },
  {
    id: 'lastName',
    label: 'Last name',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    sticky: true,
    threshold: 'left-[140px]',
    border: 'none',
    component: SortableColumnHeader,
  },
  {
    id: 'status',
    label: 'Status',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    sticky: true,
    threshold: 'left-[280px]',
    border: 'right',
    component: SortableColumnHeader,
  },
  {
    id: 'startDate',
    label: 'Start date',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: SortableColumnHeader,
  },
  {
    id: 'policyNumber',
    label: 'Policy number',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    component: SortableColumnHeader,
  },
  {
    id: 'deductible',
    label: 'Deductible',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    component: ColumnHeader,
  },
  {
    id: 'createdAt',
    label: 'Created on',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: SortableColumnHeader,
  },
  {
    id: 'source',
    label: 'Source',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: ColumnHeader,
  },
];

const displayArchivedStatus = () =>
  ({
    text: {
      id: 'ARCHIVED',
      text: 'Archived',
    },
    color: 'gray',
  } as Status);

const statusMapping = (status: string) => ({
  text: {
    id: status,
    text: dogLiabilityStatusMapper[status as DogLiabilityStatus],
  },
  color: dogLiabilityBadgeColorMapper[status as DogLiabilityStatus] ?? 'gray',
});

export const rowConfig: RowConfig = {
  getResourceLink: (data: DogLiabilityPolicy) =>
    policyRouteMapper.DOG_LIABILITY(data.id),
  cells: [
    {
      type: 'TEXT',
      props: {
        getText: (data: DogLiabilityPolicy) =>
          data.insuredPerson?.firstName ?? data.user?.firstName ?? '',
      },
      textColor: 'dark',
    },
    {
      type: 'TEXT',
      props: {
        getText: (data: DogLiabilityPolicy) =>
          data.insuredPerson?.lastName ?? data.user?.lastName ?? '',
      },
      textColor: 'dark',
    },
    {
      type: 'STATUS',
      textColor: 'dark',
      props: {
        getStatus: (data: DogLiabilityPolicy) => {
          if (data.archivedAt) return displayArchivedStatus();
          return statusMapping(data.publicStatus);
        },
      },
    },
    {
      type: 'DATE',
      props: { getDate: (data: DogLiabilityPolicy) => data.startDate ?? '' },
      textColor: 'light',
    },
    {
      type: 'TEXT',
      props: { getText: (data: DogLiabilityPolicy) => data.policyNumber ?? '' },
      textColor: 'light',
    },
    {
      type: 'TEXT',
      props: {
        getText: (data: DogLiabilityPolicy) =>
          typeof data.deductibleAmount === 'number'
            ? englishFormattedEuroCurrency(data.deductibleAmount)
            : '',
      },
      textColor: 'light',
    },
    {
      type: 'DATE',
      props: { getDate: (data: DogLiabilityPolicy) => data.createdAt ?? '' },
      textColor: 'light',
    },
    {
      type: 'TEXT',
      props: { getText: (data: DogLiabilityPolicy) => data.source ?? '' },
      textColor: 'light',
    },
  ],
};
