import classNames from 'classnames';

export const pagination = classNames(
  'mt-[8px]',
  'flex',
  'items-center',
  'justify-between',
  'border-t',
  'border-gray-200',
  'flex-grow',
  'flex-col',
  'md:flex-row',
  'space-y-2'
);

export const paginationText = classNames('text-sm', 'text-gray-500');

export const paginationButtons = classNames(
  'relative',
  'z-0',
  'inline-flex',
  'rounded-[8px]'
);
