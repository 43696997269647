import { TFunction } from '@getpopsure/i18n-react';
import { DocumentSection } from 'components/DocumentsSection/DocumentsSection';

import { CompanyLifePolicy } from '../models/CompanyLifePolicy';

export const getDocumentSections =
  (t: TFunction) =>
  (data?: { hrCompanyLifePolicy: CompanyLifePolicy }): DocumentSection[] => {
    const featherUploads: DocumentSection = {
      title: t('admin.hr.companyLife.featherUploads.label', 'Feather uploads'),
      documents: [],
    };

    data?.hrCompanyLifePolicy.hrVisiblePolicyDocuments?.forEach((document) => {
      featherUploads.documents.push({
        id: document.id,
        url: document.url,
        fileName: document.name,
        resizedUrl: document.resizedUrl,
      });
    });

    return [featherUploads];
  };
