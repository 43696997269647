import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import Modal from 'components/Modal';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import { UPDATE_REFERRAL_CODE } from '../../graphql/mutations';
import { GET_REFERRAL_CODES } from '../../graphql/queries';
import { ReferralCode, ReferralCodeInput } from '../../types';
import ReferralCodeForm from '../ReferralCodeForm';

interface UpdateReferralCodeModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  referralCode: ReferralCode;
}

const UpdateReferralCodeModal = ({
  open,
  setOpen,
  referralCode,
}: UpdateReferralCodeModalProps) => {
  const { t } = useTranslation();

  const alertBannersState = useReactiveVar(alertBanners);

  const [updateReferralCode, { loading, error }] = useMutation(
    UPDATE_REFERRAL_CODE,
    {
      refetchQueries: [GET_REFERRAL_CODES],
      onCompleted: () => {
        setOpen(false);

        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'SUCCESS',
          message: t(
            'referralCodes.updateReferralCode.alert.success',
            'Referral code updated.'
          ),
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
      onError: () => {
        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'WARNING',
          message: 'Something went wrong. Please try again.',
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
    }
  );

  const handleSubmit = async (input: ReferralCodeInput) => {
    updateReferralCode({
      variables: {
        id: referralCode.id,
        input,
      },
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={t(
        'referralCodes.updateReferralCode.modal.title',
        'Edit referral code'
      )}
      loading={loading}
      textLoading={t(
        'referralCode.updateReferralCode.modal.textLoading',
        'Saving...'
      )}
      hideActions
      scrollable
    >
      <ReferralCodeForm
        formData={referralCode}
        setOpen={setOpen}
        onSubmit={handleSubmit}
        loading={loading}
        error={error}
      />
    </Modal>
  );
};

export default UpdateReferralCodeModal;
