import { useTranslation } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import { FormEvent } from 'react';

import * as styles from './styles';

interface EditButtonsProps {
  editMode: boolean;
  onEdit: () => void;
  onDiscard: () => void;
  onSave?: (e: FormEvent) => void;
  saveButtonLabel?: string;
  discardButtonLabel?: string;
}

const EditButtons = ({
  editMode,
  onEdit,
  onDiscard,
  onSave,
  saveButtonLabel,
  discardButtonLabel,
}: EditButtonsProps) => {
  const { t } = useTranslation();
  if (!editMode) {
    return (
      <Button
        className={styles.secondaryButton}
        buttonType="secondary"
        onClick={onEdit}
      >
        {t('admin.form.edit.action', 'Edit')}
      </Button>
    );
  }
  return (
    <div className={styles.buttonsWrapper}>
      <Button
        className={styles.secondaryButton}
        buttonType="secondary"
        onClick={onDiscard}
      >
        {discardButtonLabel ?? t('admin.form.discard.action', 'Discard')}
      </Button>
      <Button
        className={styles.primaryButton}
        buttonType="primary"
        onClick={onSave}
      >
        {saveButtonLabel ?? t('admin.form.save.action', 'Save')}
      </Button>
    </div>
  );
};

export default EditButtons;
