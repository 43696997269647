import { useReactiveVar } from '@apollo/client';
import { alertBanners } from 'shared/reactiveVariables';

import { AlertBanner } from './components/AlertBanner';

export const AlertBanners = () => {
  const alertBannersState = useReactiveVar(alertBanners);

  return (
    <>
      {alertBannersState?.map((state) => (
        <AlertBanner key={state.id} state={state} />
      ))}
    </>
  );
};
