import { InformationRow, InformationSection } from 'components/InformationCard';
import DateInformation from 'components/InformationCard/DateInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { ExpatHealthPolicy } from 'pages/policies/expatHealth/models/ExpatHealthPolicy';
import { policyMappers } from 'shared/insurances';
import { mapExpatPlan } from 'shared/insurances/planMappers/expat';

export const retrieveExpatPolicyInfoRows = (
  policyInfo: ExpatHealthPolicy
): InformationSection[] => [
  {
    title: 'Policy information',
    boldTitle: false,
    rows: [
      {
        id: 'status',
        title: 'Status',
        data:
          policyMappers.INCOMING?.statusMapper[policyInfo?.publicStatus] ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
      {
        id: 'startDate',
        title: 'Start date',
        data: policyInfo?.startDate ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: false,
      },
      ...(policyInfo?.publicStatus === 'CANCELED'
        ? [
            {
              id: 'activeUntil',
              title: 'Active until',
              data: policyInfo?.activeUntilDate ?? '',
              type: 'DATE',
              component: DateInformation,
              editable: false,
            } as InformationRow,
          ]
        : []),
      {
        id: 'plan',
        title: 'Plan',
        data: policyInfo?.plan ? mapExpatPlan(policyInfo.plan) : '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
    ],
  },
];
