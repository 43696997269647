import FileErrorCard from 'components/FileErrorCard';

export type PremoderationIssueCategory =
  | 'UNKNOWN_UNI'
  | 'RECEIVES_PENSION'
  | 'INVALID_MONTHLY_INCOME'
  | 'AGE_UNDER_16'
  | 'MISSING_BROKER_MANDATE'
  | 'AGE_OVER_30'
  | 'WORKS_MORE_THAN_TWENTY_HOURS';

interface PremoderationIssuesProps {
  issues: { active: boolean; category: PremoderationIssueCategory }[];
}

const categoryMapper: Record<PremoderationIssueCategory, string> = {
  AGE_OVER_30: 'Age should be under 30.',
  UNKNOWN_UNI: 'University is not in the list of approved universities.',
  RECEIVES_PENSION: 'Answer to receives pension should be false.',
  INVALID_MONTHLY_INCOME:
    'Answer to monthly income should be "UNDER_PRIVATE_THRESHOLD" or "EXCEED_PRIVATE_THRESHOLD"',
  AGE_UNDER_16: 'Age should be equal or over 16.',
  MISSING_BROKER_MANDATE: 'Missing signed broken mandate document.',
  WORKS_MORE_THAN_TWENTY_HOURS:
    'Answer to works more than twenty hours should be false.',
};

const PremoderationIssues = ({ issues }: PremoderationIssuesProps) => {
  const activeIssues = issues.filter(({ active }) => active);
  return (
    <>
      {activeIssues.length !== 0 && (
        <FileErrorCard
          className="!mt-0 mb-4"
          open={true}
          title="Premoderation isues"
          errorType="ERROR"
          handleClose={() => {}}
          description={activeIssues.map((issue) => (
            <li className="mt-2 list-disc space-y-2">
              {categoryMapper[issue.category]}
            </li>
          ))}
          hideCloseButton={true}
        />
      )}
    </>
  );
};

export default PremoderationIssues;
