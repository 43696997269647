import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import { HeaderConfig } from 'components/TableRefactor/types';

export const tableHeaders: HeaderConfig[] = [
  {
    id: 'first-name',
    label: 'First name',
    width: 'w-[180px]',
    minWidth: 'min-w-[180px]',
    sticky: true,
    threshold: 'left-0',
    border: 'none',
    component: SortableColumnHeader,
  },
  {
    id: 'last-name',
    label: 'Last name',
    width: 'w-[180px]',
    minWidth: 'min-w-[180px]',
    sticky: true,
    threshold: 'left-[180px]',
    border: 'none',
    component: SortableColumnHeader,
  },
  {
    id: 'status',
    label: 'Status',
    width: 'w-[180px]',
    minWidth: 'min-w-[180px]',
    sticky: true,
    threshold: 'left-[360px]',
    border: 'right',
    component: SortableColumnHeader,
  },
  {
    id: 'claimed',
    label: 'Claimed',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: ColumnHeader,
  },
  {
    id: 'payout',
    label: 'Payout',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: ColumnHeader,
  },
  {
    id: 'claim-type',
    label: 'Claim type',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: ColumnHeader,
  },
  {
    id: 'created-at',
    label: 'Created on',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: SortableColumnHeader,
  },
  {
    id: 'feather-paid-on',
    label: 'Feather paid on',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    component: ColumnHeader,
  },
  {
    id: 'customer-paid-on',
    label: 'Customer paid on',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    component: ColumnHeader,
  },
];
