import classNames from 'classnames';

export const activityCircleWrapper = classNames(
  'flex',
  'flex-col',
  'mr-[16px]',
  'items-center'
);

export const activityCircle = classNames(
  'rounded-full',
  'w-[32px]',
  'h-[32px]',
  'flex',
  'justify-center'
);

export const activityCircleFull = classNames('bg-white');
export const activityCircleSmall = classNames('bg-gray-200');

export const icon = classNames('text-gray-500', 'w-[20px]');

export const activityLineFull = classNames(
  'w-[2px]',
  'bg-gray-300',
  'h-[calc(100%-38px)]'
);

export const activityLineSmall = classNames(
  'w-[2px]',
  'bg-gray-200',
  'h-[calc(100%-38px)]'
);
