import { ExternalLinkIcon } from '@heroicons/react/outline';
import Link from 'components/Link';

import { TextInformationProps } from '../TextInformation';

const LinkInformation = ({
  cell,
  href,
  externalLink,
}: TextInformationProps) => {
  return (
    <Link
      color="primary"
      text={cell.toString()}
      href={href}
      IconComponent={externalLink ? ExternalLinkIcon : undefined}
      iconLeft={false}
      target={externalLink ? '_blank' : '_self'}
    />
  );
};

export default LinkInformation;
