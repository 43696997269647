import { useTranslation } from '@getpopsure/i18n-react';
import Page404 from 'pages/errors/404';
import { useParams } from 'react-router';
import { Country } from 'shared/countries/models';

import { getTemplateHRDataByInsuranceType } from '../../data';
import { getTemplateInsuranceTypeCountry } from '../../models/InsuranceTypes';
import PoliciesOverviewTemplate from '../../PoliciesOverviewTemplate';
import {
  getDbHRInsuranceType,
  isValidHRTemplateInsurancePath,
} from '../../utils';

const PoliciesOverviewTemplateGuard = () => {
  const { insuranceType, country, company } = useParams<{
    insuranceType: string;
    company: string;
    country: Country;
  }>();

  const { t } = useTranslation();

  if (!isValidHRTemplateInsurancePath(insuranceType)) {
    return <Page404 />;
  }

  const dbInsuranceType = getDbHRInsuranceType(insuranceType);

  if (!getTemplateInsuranceTypeCountry()[dbInsuranceType].includes(country)) {
    return <Page404 />;
  }

  const templateData = getTemplateHRDataByInsuranceType(t, company)[
    dbInsuranceType
  ];

  return (
    <PoliciesOverviewTemplate
      templateData={templateData}
      insuranceType={dbInsuranceType}
    />
  );
};

export default PoliciesOverviewTemplateGuard;
