import { BadgeColor } from 'components/Badge/Badge';

export type PrivateHealthStatusId =
  | 'received'
  | 'missinginfo'
  | 'noteligible'
  | 'droppedout'
  | 'covered'
  | 'canceled'
  | 'sent'
  | 'contactfeather';

export type PrivateHealthStatus = {
  id: PrivateHealthStatusId;
  text: string;
};

export const mapPrivateHealthStatus = (status: string): PrivateHealthStatus => {
  switch (status) {
    case 'APPLICATION_RECEIVED':
      return { id: 'received', text: 'Received' };
    case 'APPLICATION_SENT':
      return { id: 'sent', text: 'Sent' };
    case 'PENDING':
    case 'ERR_MISSING_INFO':
      return { id: 'missinginfo', text: 'Missing info' };
    case 'CANT_BE_COVERED':
      return { id: 'noteligible', text: 'Not eligible' };
    case 'DROPPED_OUT':
      return { id: 'droppedout', text: 'Dropped out' };
    case 'ACTIVE':
      return { id: 'covered', text: 'Covered' };
    case 'CANCELED':
      return { id: 'canceled', text: 'Canceled' };
    default:
      return {
        id: 'contactfeather',
        text: 'Contact Feather',
      };
  }
};

export const getPrivateHealthStatusColor = (
  status: PrivateHealthStatus
): BadgeColor => {
  const colors: {
    [K in PrivateHealthStatusId]: BadgeColor;
  } = {
    received: 'purple',
    sent: 'blue',
    covered: 'green',
    missinginfo: 'pink',
    canceled: 'gray',
    noteligible: 'yellow',
    contactfeather: 'gray',
    droppedout: 'gray',
  };
  return colors[status.id];
};

export const getPrivateHealthStatus = (status: string) => {
  const text = mapPrivateHealthStatus(status);
  const color = getPrivateHealthStatusColor(text);
  return { text, color };
};
