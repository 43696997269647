import { DocumentNode, useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import { QuestionnaireAnswers } from 'models/questionnaire';
import { _DUMMY_CREATE_QUESTIONNAIRE } from 'pages/policies/privateHealth/graphql/mutations';
import { useState } from 'react';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import EditQuestionnaireForm from './EditQuestionnaireForm';

interface EditQuestionnaireButtonProps {
  answers?: QuestionnaireAnswers;
  questionnaireId: string;
  policyId: string;
  getEditableAnswers?: (answers: QuestionnaireAnswers) => object;
  mutation: DocumentNode;
  createMutationProps?: {
    mutation?: DocumentNode;
    variables?: Record<string, unknown>;
    refetchQueries?: any[];
  };
  description?: string;
  allowResubmitToProvider?: boolean;
  refetchQueries?: DocumentNode[];
}

const EditQuestionnaireButton = ({
  answers,
  questionnaireId,
  policyId,
  getEditableAnswers,
  mutation,
  createMutationProps,
  description,
  allowResubmitToProvider = false,
  refetchQueries,
}: EditQuestionnaireButtonProps) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const createMode = !questionnaireId;

  const alertBannersState = useReactiveVar(alertBanners);

  const onEditComplete = () => {
    setOpenModal(false);

    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'SUCCESS',
      message: t(
        'component.editQuestionnaireButton.saveSuccess',
        'Your changes have been saved successfully.'
      ),
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };
  const onEditError = () => {
    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'WARNING',
      message: t(
        'component.editQuestionnaireButton.error',
        'Something went wrong. Please try again.'
      ),
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };
  const [editQuestionnaire, { loading }] = useMutation(mutation, {
    onCompleted: onEditComplete,
    onError: onEditError,
    ...(refetchQueries?.length && { refetchQueries: [...refetchQueries] }),
  });

  const [createQuestionnaire, { loading: busyCreatingQuestionnaire }] =
    useMutation(createMutationProps?.mutation ?? _DUMMY_CREATE_QUESTIONNAIRE, {
      variables: {
        ...createMutationProps?.variables,
      },
      ...(createMutationProps?.refetchQueries?.length && {
        refetchQueries: [...createMutationProps.refetchQueries],
      }),
    });

  const handleSaveQuestionnaire = (variables: {
    questionnaireId: string;
    answers: string;
    policyId: string;
    submitApp: boolean;
  }) => {
    editQuestionnaire({ variables });
  };

  return (
    <div>
      <Button
        buttonType="secondary"
        className="w-[88px]"
        onClick={async () => {
          if (createMode && createMutationProps?.mutation) {
            await createQuestionnaire();
          } else {
            setOpenModal(true);
          }
        }}
        loading={busyCreatingQuestionnaire}
        disabled={createMode && !createMutationProps}
      >
        {createMode ? 'Create' : 'Edit'}
      </Button>
      <EditQuestionnaireForm
        open={openModal}
        setOpen={setOpenModal}
        answers={answers}
        questionnaireId={questionnaireId}
        policyId={policyId}
        editQuestionnaire={handleSaveQuestionnaire}
        loading={loading}
        getEditableAnswers={getEditableAnswers}
        description={description}
        allowResubmitToProvider={allowResubmitToProvider}
      />
    </div>
  );
};

export default EditQuestionnaireButton;
