import classNames from 'classnames';

export const listOptionContentWrapper = classNames('flex', 'flex-col');

export const listOptionContent = classNames('flex', 'justify-between');

export const optionTitle = classNames('flex', 'items-center');

export const listOptionText = classNames('text-gray-900');
export const listOptionTextWithDot = classNames('text-gray-900', 'ml-[10px]');

export const listOptionTextSelected = classNames(
  'text-indigo-500',
  'font-bold'
);
export const listOptionTextSelectedWithDot = classNames(
  'text-gray-900',
  'font-bold',
  'ml-[10px]'
);

export const listOptionTextHover = classNames('!text-white');

export const hidden = classNames('hidden');

export const listOptionIconSelected = classNames('text-indigo-500');

export const icon = classNames('h-5', 'w-5');
