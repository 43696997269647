import classNames from 'classnames';

import * as styles from './styles';

interface CardSectionProps {
  title?: string;
  children: React.ReactNode;
  className?: string;
}

const CardSection = ({ title, children, className }: CardSectionProps) => {
  return (
    <div className={classNames(className, styles.section)}>
      {title && <h1 className={styles.sectionTitle}>{title}</h1>}
      {children}
    </div>
  );
};

export default CardSection;
