import classNames from 'classnames';

export const section = classNames(
  'mt-[16px]',
  'flex',
  'flex-col',
  'space-y-2',
  'm-[5px]'
);

export const sectionTitle = classNames('text-sm', 'text-gray-900', 'font-bold');

export const text = classNames('text-sm', 'text-gray-600');
