import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import {
  getTemplateInsuranceTypeCountry,
  TemplateInsuranceType,
} from 'pages/policies/template/models/InsuranceTypes';
import { Country, countryNameMapper } from 'shared/countries/models';
import { GenericStatus } from 'shared/insurances/types';

const statusFilterOptions: SelectButtonOption<GenericStatus>[] = [
  {
    id: 'PENDING',
    label: 'Pending',
    title: 'Pending',
  },
  {
    id: 'ACTIVE',
    label: 'Covered',
    title: 'Covered',
  },
  {
    id: 'CANCELED',
    label: 'Canceled',
    title: 'Canceled',
  },
  {
    id: 'DROPPED_OUT',
    label: 'Dropped out',
    title: 'Dropped out',
  },
];

const getRegionFilterOptions = (insuranceType: TemplateInsuranceType) =>
  getTemplateInsuranceTypeCountry()[insuranceType].reduce(
    (options, country) => {
      if (country === 'eu') return options;
      options.push({
        id: country,
        label: countryNameMapper[country],
        title: countryNameMapper[country],
      });
      return options;
    },
    <SelectButtonOption<string>[]>[]
  );

export const getFilterConfigByRegion = (
  insuranceType: TemplateInsuranceType,
  currentRegion?: Country
): FilterConfig[] => [
  {
    options: statusFilterOptions,
    filterType: 'MULTIPLE',
    label: 'Status',
    id: 'filterStatus',
    placeholder: 'Select status(es)',
  },
  ...(currentRegion === 'eu'
    ? [
        {
          options: getRegionFilterOptions(insuranceType),
          filterType: 'MULTIPLE' as const,
          label: 'Region',
          id: 'region',
          placeholder: 'Select region',
        },
      ]
    : []),
];
