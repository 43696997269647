import { ApolloError } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import Button from 'components/Button';
import CalendarDatePicker from 'components/CalendarDatePicker';
import { FormInputLabel } from 'components/FormInputLabel';
import Input from 'components/Input';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import TextArea from 'components/TextArea';
import dayjs from 'dayjs';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InsuranceType } from 'shared/insurances/types';

import { ReferralCampaign, ReferralCampaignSchema } from '../../types';
import { insuranceTypeDropdownOptions } from '../../utils';
import * as styles from './styles';

interface ReferralCampaignFormProps {
  setOpen: (open: boolean) => void;
  onSubmit: (payload: ReferralCampaign) => void;
  loading: boolean;
  error?: ApolloError;
  formData?: ReferralCampaign;
}

const ReferralCampaignForm = ({
  setOpen,
  onSubmit,
  loading,
  error,
  formData,
}: ReferralCampaignFormProps) => {
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm<ReferralCampaign>({
    defaultValues: formData,
  });

  const defaultSourceInsuranceType =
    formData &&
    insuranceTypeDropdownOptions.find(
      (option) => option.id === formData.sourceInsuranceType
    );

  const defaultTargetInsuranceType =
    formData &&
    insuranceTypeDropdownOptions.find(
      (option) => option.id === formData.targetInsuranceType
    );

  const [startDate, setStartDate] = useState(formData?.startDate);
  const [endDate, setEndDate] = useState(formData?.endDate);
  const [selectedSourceInsuranceType, setSelectedSourceInsuranceType] =
    useState<SelectMenuOption<InsuranceType>>(
      defaultSourceInsuranceType || insuranceTypeDropdownOptions[0]
    );
  const [selectedTargetInsuranceType, setSelectedTargetInsuranceType] =
    useState<SelectMenuOption<InsuranceType>>(
      defaultTargetInsuranceType || insuranceTypeDropdownOptions[0]
    );

  const handleStartDateChange = (date: Date | [Date, Date] | null) => {
    if (!date || Array.isArray(date)) return;
    setStartDate(dayjs(date).utc(true).format());
  };

  const handleEndDateChange = (date: Date | [Date, Date] | null) => {
    if (!date || Array.isArray(date)) return;
    setEndDate(dayjs(date).utc(true).format());
  };

  const submit: SubmitHandler<ReferralCampaign> = (data) => {
    const { title, description, amount } = data;
    const referralCampaign = {
      title,
      description,
      amount: Number(amount),
      sourceInsuranceType: selectedSourceInsuranceType.id,
      targetInsuranceType: selectedTargetInsuranceType.id,
      startDate: dayjs(startDate).startOf('day').toISOString(),
      endDate: dayjs(endDate).endOf('day').toISOString(),
    };
    const parsedReferralCampaign =
      ReferralCampaignSchema.parse(referralCampaign);
    onSubmit(parsedReferralCampaign);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className={styles.section}>
        <FormInputLabel
          htmlFor="name"
          title={t('referralCampaign.referralCampaignForm.name.label', 'Name')}
        />
        <Input
          id="name"
          color="gray"
          placeholder={t(
            'referralCampaign.referralCampaignForm.name.placeholder',
            'Campaign name'
          )}
          required
          {...register('title')}
        />
      </div>
      <div className={styles.section}>
        <FormInputLabel
          htmlFor="description"
          title={t(
            'referralCampaign.referralCampaignForm.description.label',
            'Description'
          )}
        />
        <TextArea
          id="description"
          color="gray"
          placeholder={t(
            'referralCampaign.referralCampaignForm.description.placeholder',
            'Add a description for the campaign'
          )}
          required
          {...register('description')}
        />
      </div>
      <div className={styles.section}>
        <FormInputLabel
          htmlFor="name"
          title={t(
            'referralCampaign.referralCampaignForm.amount.label',
            'Amount'
          )}
        />
        <Input
          id="amount"
          type="number"
          color="gray"
          placeholder={t(
            'referralCampaign.referralCampaignForm.amount.placeholder',
            'Amount'
          )}
          required
          {...register('amount')}
        />
      </div>
      <div className={styles.section}>
        <FormInputLabel
          htmlFor="sourceInsuranceType"
          title={t(
            'referralCampaign.referralCampaignForm.sourceInsuranceType.label',
            'Source insurance type'
          )}
        />
        <SelectMenu
          color="gray"
          options={insuranceTypeDropdownOptions}
          selected={selectedSourceInsuranceType}
          setSelected={setSelectedSourceInsuranceType}
          placeholder={t(
            'referralCampaign.referralCampaignForm.sourceInsuranceType.placeholder',
            'Select the source insurance'
          )}
        />
      </div>
      <div className={styles.section}>
        <FormInputLabel
          htmlFor="tag"
          title={t(
            'referralCampaign.referralCampaignForm.targetInsuranceType.label',
            'Target insurance type'
          )}
        />
        <SelectMenu
          color="gray"
          options={insuranceTypeDropdownOptions}
          selected={selectedTargetInsuranceType}
          setSelected={setSelectedTargetInsuranceType}
          placeholder={t(
            'referralCampaign.referralCampaignForm.targetInsuranceType.placeholder',
            'Select the target insurance type'
          )}
        />
      </div>
      <div className={styles.section}>
        <FormInputLabel
          htmlFor="name"
          title={t(
            'referralCampaign.referralCampaignForm.startDate.label',
            'Start date'
          )}
        />
        <CalendarDatePicker
          selectedDate={startDate ? new Date(startDate) : undefined}
          onChange={handleStartDateChange}
          className={styles.dateInput}
        />
      </div>
      <div className={styles.section}>
        <FormInputLabel
          htmlFor="name"
          title={t(
            'referralCampaign.referralCampaignForm.endDate.label',
            'End date'
          )}
        />
        <CalendarDatePicker
          selectedDate={endDate ? new Date(endDate) : undefined}
          onChange={handleEndDateChange}
          className={styles.dateInput}
        />
      </div>
      <div className={classNames('flex', 'justify-end', 'mt-[24px]')}>
        <Button
          type="button"
          buttonType="secondary"
          onClick={() => setOpen(false)}
        >
          {t('admin.modal.cancel.action', 'Cancel')}
        </Button>
        <Button
          loading={loading}
          type="submit"
          buttonType="primary"
          className={classNames('ml-2', 'min-w-[140px]')}
        >
          {formData
            ? t('referralCampaign.referralCampaignForm.save.action', 'Update')
            : t(
                'referralCampaign.referralCampaignForm.create.action',
                'Create'
              )}
        </Button>
      </div>
      {error && <p className={styles.errorMessage}>{error.message}</p>}
    </form>
  );
};

export default ReferralCampaignForm;
