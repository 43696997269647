import { ComboBoxOption } from 'components/ComboBox/ComboBox';
import { InformationSection } from 'components/InformationCard';
import ComboBoxInformation from 'components/InformationCard/ComboBoxInformation';
import DateInformation from 'components/InformationCard/DateInformation';
import DropdownInformation from 'components/InformationCard/DropdownInformation';
import EditableInformationCard from 'components/InformationCard/EditableInformationCard';
import TextInformation from 'components/InformationCard/TextInformation';
import ToggleInformation from 'components/InformationCard/ToggleInformation';
import { SelectMenuOption } from 'components/SelectMenu';
import { EDIT_ADMIN_USER } from 'graphql/admin/users/mutations';
import { AdminUser } from 'models/AdminUser';
import {
  AuthenticationType,
  mapAuthenticationType,
} from 'shared/mapAuthenticationType';
import { Language, mapLanguage } from 'shared/mapLanguage';
import { mapUserRole, UserRole } from 'shared/mapUserRoles';

import * as styles from './styles';

interface OverviewTabProps {
  adminUser: AdminUser;
  companies: ComboBoxOption[];
  setCompaniesQuery: (newQuery: string) => void;
}

const roleOptions: ComboBoxOption[] = [
  {
    id: 'HR_PARTNER',
    label: 'HR Partner',
  },
  {
    id: 'FEATHER_ADMIN',
    label: 'Feather Admin',
  },
  {
    id: 'PUBLIC_HEALTH_PROVIDER_ADMIN',
    label: 'Public health provider admin',
  },
  {
    id: 'PUBLIC_HEALTH_PROVIDER_AGENT',
    label: 'Public health provider agent',
  },
  {
    id: 'PUBLIC_HEALTH_PROVIDER_AGENT_PLUS',
    label: 'Public health provider agent plus',
  },
];

const languageOptions: SelectMenuOption[] = [
  {
    id: 'en',
    label: 'English',
  },
  {
    id: 'de',
    label: 'German',
  },
];

const OverviewTab = ({
  adminUser,
  companies,
  setCompaniesQuery,
}: OverviewTabProps) => {
  if (!adminUser) {
    return <></>;
  }

  const companyMapping = (companyId: string) => {
    return companies.find((company) => company.id === companyId)?.label;
  };

  const mapLanguageData = (language: string) => {
    return mapLanguage(language as Language);
  };

  const mapUserRoleData = (role: string) => {
    return mapUserRole(role as UserRole);
  };

  const userInformation: InformationSection[] = [
    {
      title: 'User Information',
      rows: [
        {
          id: 'firstName',
          title: 'First name',
          data: adminUser.firstName,
          type: 'TEXT',
          component: TextInformation,
          editable: true,
          required: true,
          errorMessage: 'This field is required.',
        },
        {
          id: 'lastName',
          title: 'Last name',
          data: adminUser.lastName,
          type: 'TEXT',
          component: TextInformation,
          editable: true,
          required: true,
          errorMessage: 'This field is required.',
        },
        {
          id: 'email',
          title: 'Email',
          data: adminUser.email,
          type: 'TEXT',
          component: TextInformation,
          editable: true,
          required: true,
          errorMessage: 'This field is required.',
        },
        {
          id: 'createdAt',
          title: 'Created on',
          data: adminUser.createdAt,
          type: 'DATE',
          component: DateInformation,
          editable: false,
        },
        {
          id: 'isFeather',
          title: 'Feather Employee',
          data: adminUser.isFeather ? 'Yes' : 'No',
          type: 'TOGGLE',
          component: ToggleInformation,
          editable: false,
        },
        {
          id: 'roles',
          title: 'Role',
          data: adminUser.roles.join(', '),
          type: 'DROPDOWN',
          component: ComboBoxInformation,
          editable: !adminUser.isFeather,
          optionMapping: mapUserRoleData,
          dropdownOptions: roleOptions,
          multipleOptions: false,
          placeholder: 'Select role',
        },
        {
          id: 'companyId',
          title: 'Company',
          data:
            companies.find((company) => company.label === adminUser.company)
              ?.id ?? '',
          type: 'DROPDOWN',
          component: ComboBoxInformation,
          dropdownOptions: companies,
          multipleOptions: false,
          placeholder: 'Select company',
          optionMapping: companyMapping,
          editable: !adminUser.isFeather,
          useExternalQuery: true,
          setExternalQuery: setCompaniesQuery,
        },
        {
          id: 'active',
          title: 'Active User',
          data: adminUser.active ? 'Yes' : 'No',
          type: 'TOGGLE',
          component: ToggleInformation,
          editable: true,
        },
      ],
    },
    {
      title: 'Preferences',
      rows: [
        {
          id: 'hasAccessToOldAdminPanel',
          title: 'Access to old admin panel',
          data: adminUser.hasAccessToOldAdminPanel ? 'Yes' : 'No',
          type: 'TOGGLE',
          component: ToggleInformation,
          editable: true,
        },
        {
          id: 'hasAccessToNewAdminPanel',
          title: 'Access to new admin panel',
          data: adminUser.hasAccessToNewAdminPanel ? 'Yes' : 'No',
          type: 'TOGGLE',
          component: ToggleInformation,
          editable: true,
        },
        {
          id: 'language',
          title: 'Language',
          data: adminUser.language ?? 'English (Default)',
          type: 'DROPDOWN',
          component: DropdownInformation,
          optionMapping: mapLanguageData,
          editable: true,
          multipleOptions: false,
          dropdownOptions: languageOptions,
          placeholder: 'Select language',
        },
        {
          id: 'authType',
          title: 'Authentication Type',
          data:
            mapAuthenticationType(
              adminUser.authenticationType as AuthenticationType
            ) ?? '',
          type: 'TEXT',
          component: TextInformation,
        },
      ],
    },
  ];

  const adminUserEditableData = {
    firstName: adminUser.firstName,
    lastName: adminUser.lastName,
    email: adminUser.email,
    isFeather: adminUser.isFeather ? 'Yes' : 'No',
    active: adminUser.active ? 'Yes' : 'No',
    hasAccessToNewAdminPanel: adminUser.hasAccessToNewAdminPanel ? 'Yes' : 'No',
    hasAccessToOldAdminPanel: adminUser.hasAccessToOldAdminPanel ? 'Yes' : 'No',
    roles: adminUser.roles ? adminUser.roles[0] : undefined,
    language: adminUser.language,
    companyId:
      companies.find((company) => company.label === adminUser.company)?.id ??
      '',
  };

  return (
    <div className={styles.tabWrapper}>
      <EditableInformationCard
        sections={userInformation}
        resourceId={adminUser.id}
        mutation={EDIT_ADMIN_USER}
        editableData={adminUserEditableData}
        successMessage="User information successfully updated"
      />
    </div>
  );
};

export default OverviewTab;
