import { useTranslation } from '@getpopsure/i18n-react';
import { downloadBlob } from 'shared/utils/downloadBlob';
import { getMimeType } from 'shared/utils/getMimeType';

import * as styles from '../styles';

export const DownloadButton = ({
  url,
  fileName,
}: {
  url: string;
  fileName: string;
}) => {
  const { t } = useTranslation();

  const fetchBlob = async () => {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': getMimeType(fileName),
      },
    });
    let blob = await response.blob();
    if (!blob.type) {
      blob = new Blob([blob], {
        type: getMimeType(fileName),
      });
    }

    return blob;
  };

  return (
    <button
      type="button"
      className={styles.dropdownOption}
      onClick={async () => {
        const blob = await fetchBlob();
        downloadBlob(blob, fileName);
      }}
    >
      {t('admin.documents.action.download.label', 'Download')}
    </button>
  );
};
