import classNames from 'classnames';

export const eyeIcon = classNames('text-gray-500', 'w-[18px]', 'peer');
export const checkIcon = classNames(
  'text-green-500',
  'fill-green',
  'w-[18px]',
  'peer'
);
export const crossIcon = classNames(
  'text-gray-500',
  'fill-gray',
  'w-[18px]',
  'peer'
);
export const exclamationIcon = classNames(
  'text-yellow-400',
  'fill-yellow',
  'w-[18px]',
  'peer'
);
export const errorIcon = classNames(
  'text-red-400',
  'fill-red',
  'w-[18px]',
  'peer'
);

export const badgeCaption = classNames(
  'hidden',
  'peer-hover:block',
  'absolute',
  'bottom-7',
  'z-10',
  'bg-white',
  'shadow-3xl',
  'px-4',
  'py-2',
  'text-xs',
  'text-gray-600',
  'rounded-md',
  'w-fit',
  'whitespace-nowrap'
);
