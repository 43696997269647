import {
  FetchResult,
  OperationVariables,
  useReactiveVar,
} from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import { SelectMenuOption } from 'components/SelectMenu';
import { useEffect, useState } from 'react';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import CreateIssueForm from './CreateIssueForm/CreateIssueForm';

interface CreateIssueButtonProps {
  issueTypeOptions: SelectMenuOption[];
  policyId: string;
  providerView: boolean;
  loading: boolean;
  error: boolean;
  data: boolean;
  createIssue: (variables: OperationVariables) => Promise<FetchResult<any>>;
}

const CreateIssueButton = ({
  issueTypeOptions,
  policyId,
  providerView,
  loading,
  error,
  data,
  createIssue,
}: CreateIssueButtonProps) => {
  const { t } = useTranslation();
  const [form, setForm] = useState(false);

  const alertBannersState = useReactiveVar(alertBanners);

  // TODO: [KONG] Refactor: Should this component rely on data and error boolean?
  useEffect(() => {
    if (data) {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message: t(
          'admin.alert.issuecreation.success.header',
          'Issue successfully created.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    }

    if (error) {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: t(
          'admin.alert.issuecreation.warning.header',
          'Something went wrong. Please try again.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    }
  }, [data, error]);

  const handleCreateIssue = (variables: {
    category: string;
    description: string;
  }) => {
    createIssue({ userPolicyId: policyId, ...variables })
      .catch(() => {})
      .then(() => {
        // TODO: [KONG] Refactor: Call this on completed mutation
        setForm(false);
      });
  };

  const handleButtonClick = () => {
    setForm(true);
  };

  return (
    <>
      <Button
        buttonType="white"
        className="!px-[17px]"
        onClick={handleButtonClick}
      >
        {t('admin.provider.policy.public.createissue.action', 'Create issue')}
      </Button>
      {form && (
        <CreateIssueForm
          issueTypeOptions={issueTypeOptions}
          open={form}
          setOpen={setForm}
          createIssue={handleCreateIssue}
          loading={loading}
          providerView={providerView}
        />
      )}
    </>
  );
};

export default CreateIssueButton;
