import { RowConfig } from 'components/TableRefactor/types';
import { Status } from 'models/statusColor';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';
import { Country } from 'shared/countries/models';
import { getLocalStorageCountry } from 'shared/countries/utils';
import { policyMappers } from 'shared/insurances';

import { GenericPolicy } from '../models/GenericPolicy';

const statusMapper = (status: string): Status => {
  const policyMapper = policyMappers.GENERIC;
  return {
    text: {
      id: status,
      text: policyMapper?.statusMapper[status] ?? '',
    },
    color: policyMapper?.badgeColorMapper[status] ?? 'gray',
  };
};

export const getRowConfig = (
  insurancePath: string,
  currentRegion?: Country
): RowConfig => ({
  getResourceLink: (data: GenericPolicy) => {
    const country = getLocalStorageCountry();
    return generatePath(paths.policies.internal.details.path, {
      insuranceType: insurancePath,
      id: data.id,
      country,
    });
  },
  cells: [
    {
      type: 'TEXT',
      textColor: 'dark',
      props: {
        getText: (data: GenericPolicy) =>
          data.insuredPerson?.firstName ?? data.user?.firstName ?? '',
      },
    },
    {
      type: 'TEXT',
      textColor: 'dark',
      props: {
        getText: (data: GenericPolicy) =>
          data.insuredPerson?.lastName ?? data.user?.lastName ?? '',
      },
    },
    {
      type: 'STATUS',
      textColor: 'light',
      props: {
        getStatus: (data: GenericPolicy) => {
          return data.archivedAt
            ? {
                text: {
                  id: 'ARCHIVED',
                  text: 'Archived',
                },
                color: 'gray',
              }
            : statusMapper(data.publicStatus);
        },
      },
    },
    {
      type: 'DATE',
      textColor: 'light',
      props: {
        getDate: (data: GenericPolicy) => data.startDate ?? '',
      },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: {
        getText: (data: GenericPolicy) => data.policyNumber ?? '',
      },
    },
    {
      type: 'DATE',
      textColor: 'light',
      props: {
        getDate: (data: GenericPolicy) => data.createdAt ?? '',
      },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: {
        getText: (data: GenericPolicy) => data.source ?? '',
      },
    },
    ...(currentRegion === 'eu'
      ? [
          {
            type: 'TEXT' as const,
            textColor: 'light' as const,
            props: {
              getText: (data: GenericPolicy) =>
                data.regionOfPurchase?.toUpperCase() ?? '',
            },
          },
        ]
      : []),
  ],
});
