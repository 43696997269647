import { Gender } from '@popsure/public-models';
import { InputColor } from 'components/Input/Input';
import { SelectMenuOption } from 'components/SelectMenu';
import { z } from 'zod';

export interface SubmittableCustomer {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth?: string;
  gender?: Gender;
}

export interface CustomerFormData {
  id: keyof SubmittableCustomer;
  renderDetails:
    | { type: 'email'; placeholder: string }
    | { type: 'text'; placeholder: string }
    | { type: 'select'; options: SelectMenuOption[]; placeholder: string }
    | { type: 'date' };
  color: InputColor;
  required?: boolean;
  label: string;
  errorId?: keyof SubmittableCustomerValidationError;
}

// TODO: [KONG] Allow zod to accept custom type values
const genderArray = ['FEMALE', 'MALE', 'OTHER'] as const;

export const ZSubmittableCustomerSchema = z.object({
  firstName: z.string().min(1, { message: 'This field is required.' }),
  lastName: z.string().min(1, { message: 'This field is required.' }),
  email: z
    .string()
    .min(1, { message: 'This field is required.' })
    .email(
      "This email is invalid. Make sure it's written like example@email.com."
    ),
  gender: z.enum(genderArray).optional(),
  dateOfBirth: z.string().optional(),
});

export interface SubmittableCustomerValidationError {
  firstName: string;
  lastName: string;
  email: string;
}
