import { SelectButtonOption } from 'components/SelectButton';
import { StatusUpdateFormQuestion } from 'components/StatusUpdateModal/models';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';
import { GenericStatus } from 'shared/insurances/types';

import { GenericPolicyData } from '../models/GenericPolicyData';

export const statusChanges: { [key in GenericStatus]: GenericStatus[] } = {
  PENDING: ['PENDING', 'ACTIVE', 'CANCELED', 'DROPPED_OUT'],
  ACTIVE: ['PENDING', 'ACTIVE', 'CANCELED', 'DROPPED_OUT'],
  CANCELED: ['PENDING', 'ACTIVE', 'CANCELED', 'DROPPED_OUT'],
  DROPPED_OUT: ['PENDING', 'ACTIVE', 'CANCELED', 'DROPPED_OUT'],
};

export const statusOptions: SelectButtonOption<GenericStatus>[] = [
  {
    title: 'Pending',
    label: 'Pending',
    id: 'PENDING',
    color: 'blue',
  },
  {
    title: 'Covered',
    label: 'Covered',
    id: 'ACTIVE',
    color: 'green',
  },
  {
    title: 'Canceled',
    label: 'Canceled',
    id: 'CANCELED',
    color: 'gray',
  },
  {
    title: 'Dropped out',
    label: 'Dropped out',
    id: 'DROPPED_OUT',
    color: 'gray',
  },
];

const activeUntilForm: StatusUpdateFormQuestion = {
  id: 'activeUntil',
  label: 'Active until',
  data: {
    type: 'DATE',
    defaultValue: dayjs().format(DATE_FORMAT),
  },
  required: true,
  errorMessage: 'An active until date is required.',
};

export const getModals = (data?: GenericPolicyData) => {
  return {
    PENDING: { showOpenIssues: false, formFields: [] },
    ACTIVE: { showOpenIssues: false, formFields: [] },
    CANCELED: {
      showOpenIssues: false,
      formFields: [activeUntilForm],
      bottomDescription:
        "Stripe subscription will be automatically canceled if the policy hasn't started yet upon confirmation.",
    },
    DROPPED_OUT: { showOpenIssues: false, formFields: [] },
  };
};
