import { useTranslation } from '@getpopsure/i18n-react';
import Button from 'components/Button';

import * as styles from './styles';

interface EditButtonsProps {
  isInEditMode: boolean;
  onEdit: () => void;
  onDiscard: () => void;
  canSave?: boolean;
}

export const ActionButtons = ({
  isInEditMode,
  onEdit,
  onDiscard,
  canSave = false,
}: EditButtonsProps) => {
  const { t } = useTranslation();

  if (!isInEditMode) {
    return (
      <Button
        className={styles.secondaryButton}
        buttonType="secondary"
        onClick={onEdit}
      >
        {t('informationCard.editButton.edit', 'Edit')}
      </Button>
    );
  }

  return (
    <div className={styles.buttonsWrapper}>
      <Button
        className={styles.secondaryButton}
        buttonType="secondary"
        onClick={onDiscard}
      >
        {t('informationCard.editButton.dicard', 'Discard')}
      </Button>
      <Button
        className={styles.primaryButton}
        buttonType="primary"
        type="submit"
        disabled={!canSave}
      >
        {t('informationCard.editButton.save', 'Save')}
      </Button>
    </div>
  );
};
