import { useTranslation } from '@getpopsure/i18n-react';
import { AdjustmentsIcon } from '@heroicons/react/outline';
import Button from 'components/Button';
import SelectButton, { SelectButtonOption } from 'components/SelectButton';
import useQueryParams from 'hooks/useQueryParams';
import { useState } from 'react';
import { useHistory } from 'react-router';

import MoreFiltersForm from './MoreFiltersForm';
import { ActiveDateFilters, ActiveFilters, FilterConfig } from './types';

interface FiltersProps {
  fetchFiltered: (
    newFilterOption: SelectButtonOption | SelectButtonOption[] | undefined,
    filterId: string
  ) => void;
  fetchFilteredAll: (options: {
    [key: string]: SelectButtonOption | SelectButtonOption[] | undefined;
  }) => void;
  fetchFilteredDates: (options: {
    [key: string]: [Date | undefined, Date | undefined] | undefined;
  }) => void;
  loading: boolean;
  filterConfig: FilterConfig[];
  filters: ActiveFilters;
  dateFilters: ActiveDateFilters;
  viewConfigId?: string;
  nVisibleFilters?: number;
}

const Filters = ({
  fetchFiltered,
  loading,
  filterConfig,
  dateFilters,
  filters,
  fetchFilteredAll,
  fetchFilteredDates,
  viewConfigId,
  // By default, only the first 2 filters should be visible, the rest is accessible via the "Filters" button
  nVisibleFilters = 2,
}: FiltersProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const queryParams = useQueryParams();
  const [moreFiltersForm, setMoreFiltersForm] = useState(false);

  const handleSubmitMultipleFilter = (
    filterOptions: SelectButtonOption[] | SelectButtonOption,
    id: string
  ) => {
    if (Array.isArray(filterOptions)) {
      /**
       * Below is a temporary solution removing duplicating options for dropdown.
       * Clicking on selected item does not remove the item from selected options
       * eg. Generic claims claim type
       */
      const duplicateRemovedOptionIds: string[] = [];

      filterOptions.forEach(({ id }) => {
        if (!duplicateRemovedOptionIds.includes(id)) {
          duplicateRemovedOptionIds.push(id);
        }
      });

      const duplicateRemovedOptions = duplicateRemovedOptionIds.map((option) =>
        filterOptions.find(({ id }) => id === option)
      ) as SelectButtonOption<void>[];

      if (!duplicateRemovedOptions || duplicateRemovedOptions.length === 0) {
        queryParams.delete(id);
      } else {
        queryParams.set(
          id,
          duplicateRemovedOptions.map((option) => option.id).join(',')
        );
      }
      fetchFiltered(duplicateRemovedOptions, id);

      queryParams.set('page', '1');

      history.replace(`?${queryParams.toString()}`);
    } else {
      if (filterOptions.id === 'any') {
        queryParams.delete(id);
      } else {
        queryParams.set(id, filterOptions.id);
      }
      fetchFiltered(filterOptions, id);

      queryParams.set('page', '1');

      history.replace(`?${queryParams.toString()}`);
    }
  };

  const numberOfActiveFilters = (
    activeFilters: ActiveFilters,
    activeDateFilters: ActiveDateFilters
  ) => {
    return Object.entries({ ...activeFilters, ...activeDateFilters }).reduce(
      (total, [k, v]) => {
        if (k === viewConfigId) return total;
        if (!v) return total;
        if ('id' in v && v.id === 'any') return total;
        if (Array.isArray(v) && v.length === 0) return total;
        return total + 1;
      },
      0
    );
  };

  const clearAllFilters = () => {
    const empty: {
      [key: string]: SelectButtonOption | SelectButtonOption[] | undefined;
    } = {};
    const emptyDates: {
      [key: string]: [Date, Date] | undefined;
    } = {};

    const emptyFilters = filterConfig?.reduce((prev, cur) => {
      if (cur.filterType === 'DATE_RANGE') {
        return prev;
      }
      prev[cur.id] =
        cur.filterType === 'MULTIPLE' ? [] : { label: 'Any', id: 'any' };
      queryParams.delete(cur.id);
      return prev;
    }, empty);
    const emptyDateFilters = filterConfig?.reduce((prev, cur) => {
      if (cur.filterType === 'DATE_RANGE') {
        queryParams.delete(cur.id);
        prev[cur.id] = undefined;
      }
      return prev;
    }, emptyDates);

    queryParams.set('page', '1');

    history.replace(`?${queryParams.toString()}`);

    fetchFilteredAll(emptyFilters);
    fetchFilteredDates(emptyDateFilters);
  };

  return (
    <>
      {filterConfig.slice(0, nVisibleFilters).map((filter) => {
        if (filter.filterType === 'MULTIPLE' || filter.filterType === 'TEXT') {
          return (
            <SelectButton
              key={filter.id}
              withDot={filter.options.some(({ color }) => color)}
              disabled={loading}
              selected={filters[filter.id]}
              handleOnChange={(
                options: SelectButtonOption[] | SelectButtonOption
              ) => handleSubmitMultipleFilter(options, filter.id)}
              options={filter.options}
              label={filter.label}
              multiple={filter.filterType === 'MULTIPLE'}
              dataTestId={`${filter.id}-select-button`}
            />
          );
        }
        return null;
      })}
      {filterConfig.length > nVisibleFilters && (
        <>
          <Button
            buttonType="white"
            Icon={AdjustmentsIcon}
            onClick={() => setMoreFiltersForm(true)}
            disabled={loading}
            className={
              numberOfActiveFilters(filters, dateFilters)
                ? '!text-indigo-500'
                : ''
            }
            data-testid="filters-button"
          >
            Filters{' '}
            {numberOfActiveFilters(filters, dateFilters)
              ? `(${numberOfActiveFilters(filters, dateFilters)})`
              : ''}
          </Button>
          {moreFiltersForm && (
            <MoreFiltersForm
              open={moreFiltersForm}
              setOpen={setMoreFiltersForm}
              filterDetails={filterConfig}
              fetchFilteredAll={fetchFilteredAll}
              fetchFilteredDates={fetchFilteredDates}
              activeDateFilters={dateFilters}
              activeFilters={filters}
            />
          )}
        </>
      )}
      {!!numberOfActiveFilters(filters, dateFilters) &&
        filterConfig.length > 1 && (
          <Button
            buttonType="transparent"
            disabled={loading}
            onClick={clearAllFilters}
          >
            {t('components.table.filter.reset', 'Reset')}
          </Button>
        )}
    </>
  );
};

export default Filters;
