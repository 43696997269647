import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import {
  ExpatClaimStatus,
  expatClaimStatuses,
} from 'shared/claims/expatHealth/models';
import { statusNameMapper as expatHealthStatusNameMapper } from 'shared/claims/expatHealth/statusNameMapper';

const statusOptions: SelectButtonOption<ExpatClaimStatus>[] =
  expatClaimStatuses.map((status) => ({
    id: status,
    label: expatHealthStatusNameMapper[status],
    title: expatHealthStatusNameMapper[status],
  }));

export const filters: FilterConfig[] = [
  {
    options: statusOptions,
    filterType: 'MULTIPLE',
    label: 'Status',
    id: 'filterStatus',
    placeholder: 'Status',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Created on',
    id: 'createdAt',
  },
];
