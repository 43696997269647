import { TransactionInsuranceType } from '../models/TransactionInsuranceType';

export const getInsuranceTypePathName = (
  insuranceType: TransactionInsuranceType
) => {
  switch (insuranceType) {
    case 'DENTAL':
      return 'dental';
    case 'INCOMING':
      return 'expat-health';
    case 'INCOMING_ES':
      return 'expat-health-es';
    case 'PET_HEALTH':
      return 'pet-health';
  }
};
