import classNames from 'classnames';

export const rowData = classNames('text-sm', 'text-gray-900');

export const input = classNames(
  'w-[264px]',
  '!px-[12px]',
  '!py-[9px]',
  'min-h-[60px]',
  'max-h-[120px]'
);
