import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import { HeaderConfig } from 'components/TableRefactor/types';

export const tableHeaders: HeaderConfig[] = [
  {
    id: 'error',
    label: '',
    width: 'w-[40px]',
    minWidth: 'min-w-[40px]',
    sticky: true,
    threshold: 'left-0',
    border: 'none',
    component: ColumnHeader,
  },
  {
    id: 'customer-name',
    label: 'Customer name',
    width: 'w-none',
    minWidth: 'min-w-[205px]',
    sticky: true,
    threshold: 'left-[40px]',
    border: 'none',
    component: ColumnHeader,
  },
  {
    id: 'computed-sort-order',
    label: 'Status',
    width: 'w-none',
    minWidth: 'min-w-[116px]',
    sticky: true,
    threshold: 'left-[245px]',
    border: 'right',
    component: SortableColumnHeader,
  },
  {
    id: 'emitted-at',
    label: 'Payment date',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    component: SortableColumnHeader,
  },
  {
    id: 'received',
    label: 'Received',
    width: 'w-[100px]',
    minWidth: 'min-w-[100px]',
    component: ColumnHeader,
  },
  {
    id: 'claim-number',
    label: 'Claim number',
    width: 'w-[205px]',
    minWidth: 'min-w-[205px]',
    component: ColumnHeader,
  },
  {
    id: 'claimed',
    label: 'Claimed',
    width: 'w-[100px]',
    minWidth: 'min-w-[100px]',
    component: ColumnHeader,
  },
  {
    id: 'refunded',
    label: 'Refunded',
    width: 'w-[100px]',
    minWidth: 'min-w-[100px]',
    component: ColumnHeader,
  },
  {
    id: 'links',
    label: 'Links',
    width: 'w-[80px]',
    minWidth: 'min-w-[80px]',
    component: ColumnHeader,
  },
  {
    id: 'links2',
    label: '',
    width: 'w-[70px]',
    minWidth: 'min-w-[70px]',
    component: ColumnHeader,
  },
];
