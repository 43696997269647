import classNames from 'classnames';

export const checkbox = classNames(
  'inline-flex',
  'h-6 w-6',
  'rounded',
  'border',
  'bg-white',
  'items-center'
);

export const selected = classNames(
  'bg-indigo-500',
  'hover:border-indigo-500',
  'border-indigo-500'
);

export const mutedBorder = classNames(
  'border-gray-200',
  'hover:border-gray-400'
);
export const normalBorder = classNames('border-gray-400');
