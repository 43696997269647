import classNames from 'classnames';

import * as styles from './styles';

interface PageButtonSeparatorProps {
  isVisible: boolean;
}

const PageButtonSeparator = ({ isVisible }: PageButtonSeparatorProps) => {
  return (
    <span
      className={classNames(styles.pageButtonSepartor, {
        [styles.flex]: isVisible,
        [styles.hidden]: !isVisible,
      })}
    >
      ...
    </span>
  );
};

export default PageButtonSeparator;
