import classNames from 'classnames';
import { BadgeColor } from 'components/Badge/Badge';
import Card from 'components/Card';
import { CheckBadgeType } from 'components/CheckBadge/CheckBadge';
import Loader from 'components/Loader';
import { SelectMenuOption } from 'components/SelectMenu';
import { PolicyStatus } from 'models/publicHealthStatus';
import { ChangeEvent, FormEvent } from 'react';
import { ZodObject, ZodRawShape } from 'zod';

import { CurrencyInformationProps } from './CurrencyInformation';
import { DataErrors, DataForm } from './EditableInformationCard';
import EditButtons from './EditButtons';
import MultipleLineInformation from './MultipleLineInformation';
import * as styles from './styles';
import { TextInformationProps } from './TextInformation';

interface InformationCardProps {
  sections: InformationSection[];
  editable?: boolean;
  editMode?: boolean;
  handleClickOnSave?: (e: FormEvent) => void;
  handleClickOnEdit?: () => void;
  handleClickOnDiscard?: () => void;
  handleChangeOnInput?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleChangeOnTextbox?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  handleNumberChangeOnInput?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleDateChange?: (date: Date, id: string) => void;
  handleDropdownChange?: (option: SelectMenuOption, id: string) => void;
  handleMultipleDropdownChange?: (
    options: SelectMenuOption[],
    id: string
  ) => void;
  handleArrayChange?: (value: string[], id: string) => void;
  handleToggleChange?: (value: string, id: string) => void;
  handleMultipleLineChangeOnInput?: (
    e: ChangeEvent<HTMLInputElement>,
    id: string
  ) => void;
  form?: DataForm;
  errorMessage?: string;
  errors?: DataErrors;
  additionalActions?: React.ReactNode;
  loading?: boolean;
  saveButtonLabel?: string;
  discardButtonLabel?: string;
}

export interface InformationSection {
  title: string;
  boldTitle?: boolean;
  zodValidationSchema?: ZodObject<ZodRawShape>;
  rows: InformationRow[];
  // TODO: [KONG] Convert to mandatory field
  id?: string;
}

export type MultipleLineRow = {
  label: string | JSX.Element;
  data?: string;
  id?: string;
};

export type InformationRowType =
  | 'TEXT'
  | 'DATE'
  | 'STATUS'
  | 'MULTIPLELINE'
  | 'DROPDOWN'
  | 'TOGGLE'
  | 'LINK'
  | 'DOCUMENTS'
  | 'TEXT_WITH_CHECK'
  | 'TEXT_WITH_DETAILS'
  | 'CURRENCY'
  | 'TEXTBOX';

// TODO: [KONG] Needs refactor for stricter type definitions
export type InformationRow =
  | {
      id: string;
      type: Exclude<
        InformationRowType,
        | 'STATUS'
        | 'MULTIPLELINE'
        | 'DROPDOWN'
        | 'LINK'
        | 'DOCUMENTS'
        | 'TEXT_WITH_CHECK'
        | 'CURRENCY'
        | 'BUTTON'
      >;
      component: React.ComponentType<TextInformationProps>;
      data: string;
      multipleLinesData?: undefined;
      statusMapping?: undefined;
      title: string;
      editable?: boolean;
      dropdownOptions?: undefined;
      optionMapping?: undefined;
      multipleOptions?: undefined;
      placeholder?: string;
      href?: undefined;
      useExternalQuery?: undefined;
      setExternalQuery?: undefined;
      externalLink?: undefined;
      checklist?: undefined;
      required?: boolean;
      errorMessage?: string;
      check?: undefined;
      zodValidationSchema?: ZodObject<ZodRawShape>;
      detailsLabel?: string;
      detailsTitle?: string;
      renderModalDetails?: (
        setOpen: (prevValue: boolean) => void,
        modalOpen: boolean
      ) => JSX.Element;
      isModalScrollable?: boolean;
      onClick?: undefined;
    }
  | {
      id: string;
      type: 'STATUS';
      component: React.ComponentType<TextInformationProps>;
      data: PolicyStatus | string;
      multipleLinesData?: undefined;
      statusMapping: (status: PolicyStatus | string) => {
        color: BadgeColor;
        text: { text: string; id: string };
      };
      title: string;
      editable?: boolean;
      dropdownOptions?: undefined;
      optionMapping?: undefined;
      multipleOptions?: undefined;
      placeholder?: undefined;
      href?: undefined;
      useExternalQuery?: undefined;
      setExternalQuery?: undefined;
      externalLink?: undefined;
      checklist?: undefined;
      required?: boolean;
      errorMessage?: string;
      check?: undefined;
      zodValidationSchema?: ZodObject<ZodRawShape>;
      detailsTitle?: undefined;
      detailsLabel?: undefined;
      isModalScrollable?: boolean;
      onClick?: undefined;
      renderModalDetails?: undefined;
    }
  | {
      id: string;
      type: 'MULTIPLELINE';
      component: React.ComponentType<TextInformationProps>;
      data?: Record<string, unknown>;
      multipleLinesData: MultipleLineRow[];
      statusMapping?: undefined;
      title: string;
      editable?: boolean;
      dropdownOptions?: undefined;
      optionMapping?: undefined;
      multipleOptions?: undefined;
      placeholder?: undefined;
      href?: undefined;
      useExternalQuery?: undefined;
      setExternalQuery?: undefined;
      externalLink?: undefined;
      checklist?: undefined;
      required?: boolean;
      errorMessage?: string;
      check?: undefined;
      zodValidationSchema?: ZodObject<ZodRawShape>;
      detailsTitle?: undefined;
      detailsLabel?: undefined;
      isModalScrollable?: boolean;
      onClick?: undefined;
      renderModalDetails?: undefined;
    }
  | {
      id: string;
      type: 'DROPDOWN';
      component: React.ComponentType<TextInformationProps>;
      data: string;
      multipleLinesData?: undefined;
      statusMapping?: undefined;
      title: string;
      editable?: boolean;
      dropdownOptions: SelectMenuOption[];
      optionMapping: (option: string) => string | undefined;
      multipleOptions: boolean;
      placeholder: string;
      href?: undefined;
      useExternalQuery?: boolean;
      setExternalQuery?: (query: string) => void;
      externalLink?: undefined;
      checklist?: undefined;
      required?: boolean;
      errorMessage?: string;
      check?: undefined;
      zodValidationSchema?: ZodObject<ZodRawShape>;
      detailsTitle?: string;
      detailsLabel?: string;
      renderModalDetails?: (
        setOpen: (prevValue: boolean) => void,
        modalOpen: boolean
      ) => JSX.Element;
      isModalScrollable?: boolean;
      onClick?: undefined;
    }
  | {
      id: string;
      type: 'LINK';
      component: React.ComponentType<TextInformationProps>;
      data: string;
      multipleLinesData?: undefined;
      statusMapping?: undefined;
      title: string;
      editable?: boolean;
      dropdownOptions?: undefined;
      optionMapping?: undefined;
      multipleOptions?: undefined;
      placeholder?: undefined;
      href: string;
      useExternalQuery?: undefined;
      setExternalQuery?: undefined;
      externalLink: boolean;
      checklist?: undefined;
      required?: boolean;
      errorMessage?: string;
      check?: undefined;
      zodValidationSchema?: ZodObject<ZodRawShape>;
      detailsTitle?: undefined;
      detailsLabel?: undefined;
      isModalScrollable?: boolean;
      onClick?: undefined;
      renderModalDetails?: undefined;
    }
  | {
      id: string;
      type: 'CHECKLIST';
      component: React.ComponentType<TextInformationProps>;
      data?: undefined;
      multipleLinesData?: undefined;
      statusMapping?: undefined;
      title: string;
      editable?: boolean;
      dropdownOptions?: undefined;
      optionMapping?: undefined;
      multipleOptions?: undefined;
      placeholder?: undefined;
      href?: undefined;
      useExternalQuery?: undefined;
      setExternalQuery?: undefined;
      externalLink?: undefined;
      checklist: {
        id?: string;
        title: string;
        check: boolean;
      }[];
      required?: boolean;
      errorMessage?: string;
      check?: undefined;
      zodValidationSchema?: ZodObject<ZodRawShape>;
      detailsTitle?: string;
      detailsLabel?: string;
      renderModalDetails?: (
        setOpen: (prevValue: boolean) => void,
        modalOpen: boolean
      ) => JSX.Element;
      isModalScrollable?: boolean;
      onClick?: undefined;
    }
  | {
      id: string;
      type: 'TEXT_WITH_CHECK';
      component: React.ComponentType<TextInformationProps>;
      data: string;
      multipleLinesData?: undefined;
      statusMapping?: undefined;
      title: string;
      editable?: boolean;
      dropdownOptions?: undefined;
      optionMapping?: undefined;
      multipleOptions?: undefined;
      placeholder?: undefined;
      href?: undefined;
      useExternalQuery?: undefined;
      setExternalQuery?: undefined;
      externalLink?: undefined;
      checklist?: undefined;
      required?: boolean;
      errorMessage?: string;
      check: CheckBadgeType;
      zodValidationSchema?: ZodObject<ZodRawShape>;
      detailsTitle?: undefined;
      detailsLabel?: undefined;
      isModalScrollable?: boolean;
      onClick?: undefined;
      renderModalDetails?: undefined;
    }
  | {
      id: string;
      type: 'TEXT_WITH_DETAILS';
      component: React.ComponentType<TextInformationProps>;
      data: string;
      multipleLinesData?: undefined;
      statusMapping?: undefined;
      title: string;
      editable?: boolean;
      dropdownOptions?: undefined;
      optionMapping?: undefined;
      multipleOptions?: undefined;
      placeholder?: string;
      href?: undefined;
      useExternalQuery?: undefined;
      setExternalQuery?: undefined;
      externalLink?: undefined;
      checklist?: undefined;
      required?: boolean;
      errorMessage?: string;
      check?: undefined;
      zodValidationSchema?: ZodObject<ZodRawShape>;
      detailsTitle?: string;
      detailsLabel?: string;
      isModalScrollable?: boolean;
      onClick?: undefined;
      renderModalDetails?: (
        setOpen: (prevValue: boolean) => void,
        modalOpen: boolean
      ) => JSX.Element;
    }
  | {
      id: string;
      type: 'CURRENCY';
      component: React.ComponentType<CurrencyInformationProps>;
      data?: number;
      statusMapping?: undefined;
      multipleLinesData?: undefined;
      title: string;
      editable?: boolean;
      dropdownOptions?: undefined;
      optionMapping?: undefined;
      multipleOptions?: undefined;
      placeholder?: string;
      href?: undefined;
      useExternalQuery?: undefined;
      setExternalQuery?: undefined;
      externalLink?: undefined;
      checklist?: undefined;
      required?: boolean;
      errorMessage?: string;
      check?: undefined;
      zodValidationSchema?: ZodObject<ZodRawShape>;
      detailsTitle?: string;
      detailsLabel?: string;
      isModalScrollable?: boolean;
      onClick?: undefined;
      renderModalDetails?: undefined;
    }
  | {
      id: string;
      type: 'TEXTBOX';
      component: React.ComponentType<TextInformationProps>;
      data: string;
      multipleLinesData?: undefined;
      statusMapping?: undefined;
      title: string;
      editable?: boolean;
      dropdownOptions?: undefined;
      optionMapping?: undefined;
      multipleOptions?: undefined;
      placeholder?: string;
      href?: undefined;
      useExternalQuery?: undefined;
      setExternalQuery?: undefined;
      externalLink?: undefined;
      checklist?: undefined;
      required?: boolean;
      errorMessage?: string;
      check?: undefined;
      zodValidationSchema?: ZodObject<ZodRawShape>;
      detailsTitle?: string;
      detailsLabel?: string;
      renderModalDetails?: (
        setOpen: (prevValue: boolean) => void,
        modalOpen: boolean
      ) => JSX.Element;
      isModalScrollable?: boolean;
      onClick?: undefined;
    }
  | {
      id: string;
      type: 'BUTTON';
      component: React.ComponentType<TextInformationProps>;
      data: string;
      onClick: (metaData: Record<string, any>) => void;
      multipleLinesData?: undefined;
      statusMapping?: undefined;
      title: string;
      editable?: undefined;
      dropdownOptions?: undefined;
      optionMapping?: undefined;
      multipleOptions?: undefined;
      placeholder?: undefined;
      href?: undefined;
      useExternalQuery?: undefined;
      setExternalQuery?: undefined;
      externalLink?: undefined;
      checklist?: undefined;
      required?: boolean;
      errorMessage?: string;
      check?: undefined;
      zodValidationSchema?: ZodObject<ZodRawShape>;
      detailsTitle?: undefined;
      detailsLabel?: undefined;
      isModalScrollable?: boolean;
      renderModalDetails?: undefined;
    };

const InformationCard = ({
  sections,
  editable,
  handleClickOnSave,
  handleClickOnEdit,
  handleClickOnDiscard,
  handleChangeOnInput,
  handleChangeOnTextbox,
  handleNumberChangeOnInput,
  handleDateChange,
  handleDropdownChange,
  handleMultipleDropdownChange,
  handleToggleChange,
  handleArrayChange,
  handleMultipleLineChangeOnInput,
  editMode,
  form,
  errorMessage,
  errors,
  additionalActions,
  loading,
  saveButtonLabel,
  discardButtonLabel,
}: InformationCardProps) => {
  const editButton = editable && handleClickOnEdit && handleClickOnDiscard && (
    <EditButtons
      editMode={editMode ?? false}
      onEdit={handleClickOnEdit}
      onDiscard={handleClickOnDiscard}
      onSave={handleClickOnSave}
      saveButtonLabel={saveButtonLabel}
      discardButtonLabel={discardButtonLabel}
    />
  );

  return (
    <Card
      title={sections[0]?.title}
      boldTitle={sections[0]?.boldTitle ?? true}
      actionButton={editButton ?? additionalActions}
    >
      {loading && (
        <div className={styles.noDataContainer}>
          <Loader className={styles.loader} />
        </div>
      )}

      <form>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        {sections.map((section, sectionIndex) => {
          return (
            <div key={sectionIndex}>
              {sectionIndex !== 0 && (
                <div className={styles.sectionRowTitle}>
                  <h1 className={styles.title}>{section.title}</h1>
                </div>
              )}
              {section.rows.map((row, index) => {
                const data = row.editable
                  ? form
                    ? form[row.id]
                    : ''
                  : row.data;

                const isMultiline = row.type === 'MULTIPLELINE';
                const isExpandable = row.type === 'TEXTBOX';

                return (
                  <div
                    key={index}
                    className={classNames(styles.cardListRow, {
                      [styles.cardListRowEdit]: editMode && row.editable,
                      [styles.cardListMultipleRows]:
                        row.type === 'MULTIPLELINE',
                      [styles.cardListChecklistRows]: row.type === 'CHECKLIST',
                    })}
                  >
                    <div
                      className={classNames({
                        [styles.rowLabel]: !isMultiline,
                        [styles.rowLabelMultipleLine]: isMultiline,
                        [styles.rowLabelExpandable]: isExpandable,
                      })}
                    >
                      <span>{row.title}</span>
                    </div>
                    {row.type === 'MULTIPLELINE' ? (
                      <MultipleLineInformation
                        cell={data ?? {}}
                        multipleCells={row.multipleLinesData}
                        editable={row.editable}
                        editMode={editMode}
                        id={row.id}
                        handleChange={handleMultipleLineChangeOnInput}
                      />
                    ) : (
                      <row.component
                        cell={
                          data !== undefined && data !== null && data !== ''
                            ? data
                            : ''
                        }
                        multipleCells={row.multipleLinesData}
                        statusMapping={row.statusMapping}
                        editable={row.editable ?? false}
                        editMode={editMode}
                        value={data?.toString() ?? ''}
                        handleChange={handleChangeOnInput}
                        handleChangeOnTextbox={handleChangeOnTextbox}
                        handleNumberChangeOnInput={handleNumberChangeOnInput}
                        handleDateChange={handleDateChange}
                        handleDropdownChange={handleDropdownChange}
                        handleMultipleDropdownChange={
                          handleMultipleDropdownChange
                        }
                        handleToggleChange={handleToggleChange}
                        handleArrayChange={handleArrayChange}
                        dropdownOptions={row.dropdownOptions}
                        optionMapping={row.optionMapping}
                        multipleOptions={row.multipleOptions}
                        id={row.id}
                        placeholder={row.placeholder}
                        href={row.href}
                        useExternalQuery={row.useExternalQuery}
                        setExternalQuery={row.setExternalQuery}
                        externalLink={row.externalLink}
                        checklist={row.checklist}
                        errorMessage={errors?.[row.id] ? row.errorMessage : ''}
                        check={row.check}
                        detailsLabel={row.detailsLabel}
                        detailsTitle={row.detailsTitle}
                        renderModalDetails={row.renderModalDetails}
                        isModalScrollable={row.isModalScrollable}
                        onClick={row.onClick}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </form>
    </Card>
  );
};

export default InformationCard;
