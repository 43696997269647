import { DocumentNode } from 'graphql';
import { TemplateInsuranceType } from 'pages/policies/template/models/InsuranceTypes';

import { SUBMIT_PET_HEALTH_CLAIM } from './graphql/mutations';

export const claimSubmissionMutationByInsuranceType: Partial<
  Record<TemplateInsuranceType, DocumentNode>
> = {
  PET_HEALTH: SUBMIT_PET_HEALTH_CLAIM,
};
