import { gql } from '@apollo/client';

export const UPDATE_EXPAT_CANCELATION_INFORMATION = gql`
  mutation UpdateExpatCancelationInformation(
    $id: String!
    $reason: String
    $createdAt: String
    $canceledOnStripeAt: String
    $cancelationConfirmedByProviderAt: String
    $activeUntil: String
  ) {
    updateExpatPolicyCancelationInformation(
      policyId: $id
      reason: $reason
      createdAt: $createdAt
      canceledOnStripeAt: $canceledOnStripeAt
      cancelationConfirmedByProviderAt: $cancelationConfirmedByProviderAt
      activeUntil: $activeUntil
    ) {
      id
      cancelationRequest {
        id
        cancelationConfirmedByProviderAt
        canceledOnStripeAt
        createdAt
        reason
      }
      activeUntilDate
    }
  }
`;
