import classNames from 'classnames';

export const navbar = classNames('w-full', 'bg-gray-200', 'z-10', 'relative');

export const navbarContent = classNames(
  'mx-[40px]',
  'flex',
  'items-center',
  'justify-end'
);
