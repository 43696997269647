import { BadgeColor } from 'components/Badge/Badge';

import { badgeColorMapper as bikeBadgeColorMapper } from './bike/badgeColorMapper';
import { claimTypeNameMapper as bikeClaimTypeMapper } from './bike/claimTypeNameMapper';
import { statusNameMapper as bikeStatusNameMapper } from './bike/statusNameMapper';
import { badgeColorMapper as dentalBadgeColorMapper } from './dental/badgeColorMapper';
import { claimTypeNameMapper as dentalClaimTypeMapper } from './dental/claimTypeNameMapper';
import { statusNameMapper as dentalStatusNameMapper } from './dental/statusNameMapper';
import { badgeColorMapper as dogLiabilityBadgeColorMapper } from './dogLiability/badgeColorMapper';
import { claimTypeNameMapper as dogLiabilityClaimTypeMapper } from './dogLiability/claimTypeNameMapper';
import { statusNameMapper as dogLiabilityStatusNameMapper } from './dogLiability/statusNameMapper';
import { badgeColorMapper as expatHealthBadgeColorMapper } from './expatHealth/badgeColorMapper';
import { claimTypeNameMapper as expatHealthClaimTypeMapper } from './expatHealth/claimTypeNameMapper';
import { statusNameMapper as expatHealthStatusNameMapper } from './expatHealth/statusNameMapper';
import { badgeColorMapper as genericBadgeColorMapper } from './generic/badgeColorMapper';
import { claimTypeNameMapper as genericClaimTypeMapper } from './generic/claimTypeNameMapper';
import { statusNameMapper as genericStatusNameMapper } from './generic/statusNameMapper';
import { badgeColorMapper as householdBadgeColorMapper } from './household/badgeColorMapper';
import { claimTypeNameMapper as householdClaimTypeMapper } from './household/claimTypeNameMapper';
import { statusNameMapper as householdStatusNameMapper } from './household/statusNameMapper';
import { badgeColorMapper as legalBadgeColorMapper } from './legal/badgeColorMapper';
import { claimTypeNameMapper as legalClaimTypeMapper } from './legal/claimTypeNameMapper';
import { statusNameMapper as legalStatusNameMapper } from './legal/statusNameMapper';
import { badgeColorMapper as liabilityBadgeColorMapper } from './liability/badgeColorMapper';
import { claimTypeNameMapper as liabilityClaimTypeMapper } from './liability/claimTypeNameMapper';
import { statusNameMapper as liabilityStatusNameMapper } from './liability/statusNameMapper';
import { CombinedClaimStatus, CombinedClaimType } from './models';
import { badgeColorMapper as privateHealthBadgeColorMapper } from './privateHealth/badgeColorMapper';
import { claimTypeNameMapper as privateHealthClaimTypeMapper } from './privateHealth/claimTypeNameMapper';
import { statusNameMapper as privateHealthStatusNameMapper } from './privateHealth/statusNameMapper';

export const combinedClaimTypeMapper: Record<CombinedClaimType, string> = {
  ...dentalClaimTypeMapper,
  ...privateHealthClaimTypeMapper,
  ...liabilityClaimTypeMapper,
  ...householdClaimTypeMapper,
  ...expatHealthClaimTypeMapper,
  ...genericClaimTypeMapper,
  ...bikeClaimTypeMapper,
  ...legalClaimTypeMapper,
  ...dogLiabilityClaimTypeMapper,
};

export const combinedClaimStatusMapper: Record<CombinedClaimStatus, string> = {
  ...dentalStatusNameMapper,
  ...privateHealthStatusNameMapper,
  ...liabilityStatusNameMapper,
  ...expatHealthStatusNameMapper,
  ...householdStatusNameMapper,
  ...genericStatusNameMapper,
  ...bikeStatusNameMapper,
  ...legalStatusNameMapper,
  ...dogLiabilityStatusNameMapper,
};

export const combinedStatusColorMapper: Record<
  CombinedClaimStatus,
  BadgeColor
> = {
  ...dentalBadgeColorMapper,
  ...privateHealthBadgeColorMapper,
  ...liabilityBadgeColorMapper,
  ...expatHealthBadgeColorMapper,
  ...householdBadgeColorMapper,
  ...genericBadgeColorMapper,
  ...bikeBadgeColorMapper,
  ...legalBadgeColorMapper,
  ...dogLiabilityBadgeColorMapper,
};
