import classNames from 'classnames';

export const label = classNames(
  'text-sm',
  'font-bold',
  'text-gray-900',
  'mb-2'
);

export const policyCard = classNames(
  'flex',
  'items-center',
  'gap-3',
  'p-2',
  'bg-white',
  'hover:bg-indigo-100',
  'hover:text-indigo-500',
  'w-full',
  'border',
  'border',
  'border-gray-100',
  'rounded-md',
  'text-sm'
);
export const policyType = classNames('font-bold');
export const policyNumber = classNames('text-gray-500');

export const policyIcon = classNames('w-12');

export const bottomText = classNames('text-sm', 'text-gray-600', 'mt-[24px]');
export const bottomTextBold = classNames(
  'font-bold',
  'text-sm',
  'text-gray-600',
  'mt-[24px]'
);
