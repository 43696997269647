import { InformationSection } from 'components/InformationCard';
import DateInformation from 'components/InformationCard/DateInformation';
import EditableInformationCard from 'components/InformationCard/EditableInformationCard';
import TextInformation from 'components/InformationCard/TextInformation';
import { PrivateHealthPolicy } from 'models/privateHealthPolicy';
import { GENERATE_DEPENDENTS_CONFIRMATION } from 'pages/policies/privateHealth/graphql/mutations';
import { useState } from 'react';

interface DependentsTabProps {
  policy: PrivateHealthPolicy;
}

const DependentsTab = ({ policy }: DependentsTabProps) => {
  const dependentsInformation: InformationSection = {
    title: 'Dependent information',
    rows: [
      {
        id: 'firstName',
        title: 'First name',
        data: '',
        type: 'TEXT',
        component: TextInformation,
        editable: true,
        required: true,
        errorMessage: 'This field is required.',
        placeholder: 'Enter first name',
      },
      {
        id: 'lastName',
        title: 'Last name',
        data: '',
        type: 'TEXT',
        component: TextInformation,
        editable: true,
        required: true,
        errorMessage: 'This field is required.',
        placeholder: 'Enter last name',
      },
      {
        id: 'dateOfBirth',
        title: 'Date of birth',
        data: '',
        type: 'DATE',
        component: DateInformation,
        editable: true,
        required: true,
        errorMessage: 'This field is required.',
      },
      {
        id: 'startDate',
        title: 'Start date',
        data: '',
        type: 'DATE',
        component: DateInformation,
        editable: true,
        required: true,
        errorMessage: 'This field is required.',
      },
    ],
  };

  const editableData = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    startDate: '',
  };

  const [data, setData] = useState(editableData);

  const onCompleted = () => {
    setData(editableData);
  };

  return (
    <div className="w-[750px]">
      <EditableInformationCard
        sections={[dependentsInformation]}
        editableData={data}
        mutation={GENERATE_DEPENDENTS_CONFIRMATION}
        resourceId={policy.id}
        onCompleted={onCompleted}
        keepOpen={true}
        saveButtonLabel="Generate confirmation"
        discardButtonLabel="Clear"
        successMessage="Generating confirmation of coverage in the background"
      />
    </div>
  );
};

export default DependentsTab;
