import { ApolloError } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import Button from 'components/Button';
import { FormInputLabel } from 'components/FormInputLabel';
import Input from 'components/Input';
import RadioButton from 'components/RadioButton';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { ReferralCode } from '../../types';
import * as styles from '../styles';

type ReferralCodeFormPayload = {
  referralCode: string;
  isDiscountCode: boolean;
};

interface ReferralCodeFormProps {
  setOpen: (open: boolean) => void;
  onSubmit: (payload: ReferralCodeFormPayload) => void;
  loading: boolean;
  error?: ApolloError;
  formData?: ReferralCode;
}

const ReferralCodeForm = ({
  setOpen,
  onSubmit,
  loading,
  error,
  formData,
}: ReferralCodeFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ReferralCode>({
    defaultValues: formData,
  });
  const [isDiscountCode, setIsDiscountCode] = useState<'YES' | 'NO'>(
    formData?.isDiscountCode ? 'YES' : 'NO'
  );

  const { t } = useTranslation();

  const submit: SubmitHandler<ReferralCodeFormPayload> = (data) => {
    const payload = {
      referralCode: data.referralCode,
      isDiscountCode: isDiscountCode === 'YES',
    };

    onSubmit(payload);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className={styles.section}>
        <FormInputLabel
          htmlFor="referralCode"
          title={t('referralCode.form.code.label', 'Referral code')}
        />
        <Input
          id="title"
          color="gray"
          className="mt-2"
          placeholder={t(
            'referralCode.form.code.placeholder',
            'Referral code (8 characters long)'
          )}
          required
          {...register('referralCode', {
            minLength: {
              value: 8,
              message: t(
                'referralCode.form.code.error',
                'Referral code must be 8 characters long'
              ),
            },
            maxLength: {
              value: 8,
              message: t(
                'referralCode.form.code.error',
                'Referral code must be 8 characters long'
              ),
            },
          })}
        />
        {errors.referralCode && (
          <p className={styles.errorMessage}>{errors.referralCode.message}</p>
        )}
      </div>
      <div className={styles.section}>
        <FormInputLabel
          htmlFor="isDiscountCode"
          title={t(
            'referralCode.form.isDiscountCode.label',
            'Is this a discount code?'
          )}
        />
        <div className="mt-[8px] flex items-center space-x-[32px]">
          <RadioButton
            value="Yes"
            id="YES"
            name="YES"
            onChange={() => {
              setIsDiscountCode('YES');
            }}
            checked={isDiscountCode === 'YES'}
            error={false}
          >
            Yes
          </RadioButton>
          <RadioButton
            value="No"
            id="NO"
            name="NO"
            onChange={() => {
              setIsDiscountCode('NO');
            }}
            checked={isDiscountCode === 'NO'}
            error={false}
          >
            No
          </RadioButton>
        </div>
      </div>
      <div className={classNames('flex', 'justify-end', 'mt-[24px]')}>
        <Button
          type="button"
          buttonType="secondary"
          onClick={() => setOpen(false)}
        >
          {t('admin.modal.cancel.action', 'Cancel')}
        </Button>
        <Button
          loading={loading}
          type="submit"
          buttonType="primary"
          className={classNames('ml-2', 'min-w-[140px]')}
        >
          {formData
            ? t('referralCode.form.save.action', 'Save')
            : t('referralCode.form.create.action', 'Create')}
        </Button>
      </div>
      {error && <p className={styles.errorMessage}>{error.message}</p>}
    </form>
  );
};

export default ReferralCodeForm;
