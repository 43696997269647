import { IssueCategoryCombined } from './models';

export const combinedIssueCategoryMapper: Record<
  IssueCategoryCombined,
  string
> = {
  UNI_CHECK: 'Unicheck',
  MISSING_INFO: 'Missing info',
  CANCELED: 'Canceled',
  NOT_ELIGIBLE: 'Not eligible',
  OTHER: 'Other',
};
