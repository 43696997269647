import { gql } from '@apollo/client';

export const ADD_ADMIN_USER = gql`
  mutation addAdminUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $isFeather: Boolean!
    $language: String!
    $companyId: String
    $roles: [String!]
    $hasAccessToNewAdminPanel: Boolean!
    $hasAccessToOldAdminPanel: Boolean!
  ) {
    addAdminUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      isFeather: $isFeather
      language: $language
      companyId: $companyId
      roles: $roles
      hasAccessToNewAdminPanel: $hasAccessToNewAdminPanel
      hasAccessToOldAdminPanel: $hasAccessToOldAdminPanel
    ) {
      id
      firstName
      lastName
      company
      email
      authenticationType
      createdAt
      active
      roles
      hasAccessToNewAdminPanel
      hasAccessToOldAdminPanel
    }
  }
`;

export const UPDATE_ADMIN_USER_LANGUAGE = gql`
  mutation UpdateAdminUserLanguage($language: String!) {
    updateAdminUserLanguage(language: $language) {
      id
      language
    }
  }
`;

export const EDIT_ADMIN_USER = gql`
  mutation editAdminUser(
    $id: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $isFeather: Boolean!
    $language: String
    $companyId: String
    $roles: [String!]
    $active: Boolean
    $hasAccessToNewAdminPanel: Boolean!
    $hasAccessToOldAdminPanel: Boolean!
  ) {
    editAdminUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      isFeather: $isFeather
      language: $language
      companyId: $companyId
      roles: $roles
      active: $active
      hasAccessToNewAdminPanel: $hasAccessToNewAdminPanel
      hasAccessToOldAdminPanel: $hasAccessToOldAdminPanel
    ) {
      id
      firstName
      lastName
      company
      email
      authenticationType
      createdAt
      active
      roles
      isFeather
      language
      hasAccessToNewAdminPanel
      hasAccessToOldAdminPanel
    }
  }
`;

export const RESET_ADMIN_USER_PASSWORD = gql`
  mutation resetPassword($id: String!) {
    resetPassword(id: $id) {
      id
    }
  }
`;
