/**
 * NOTE: This type is not being shared with BE.
 * When updating, also update BE type
 *
 * Notion page for all vertical claim statuses:
 * https://www.notion.so/featherinsurance/Claim-statuses-2-0-22713a3fe92c49969b2e403f655678b2
 */
export const householdClaimStatuses = [
  'SUBMITTED_TO_PROVIDER',
  'RECEIVED',
  'INCOMPLETE',
  'MISSING_INFO_RECEIVED',
  'DUPLICATE',
  'CUSTOMER_PAID_OUT',
  'FEATHER_PAID_OUT',
  'OTHER_PAID_OUT',
  'DENIED',
  'DROPPED_OUT',
  'ON_HOLD',
  'IN_REVIEW',
  'CLOSED',
  'APPROVED_WITH_OTHER_CLAIMS',
] as const;
export type HouseholdClaimStatus = typeof householdClaimStatuses[number];

export const deprecatedHouseholdClaimStatuses: HouseholdClaimStatus[] = [
  'APPROVED_WITH_OTHER_CLAIMS',
];

export const householdClaimTypes = [
  'FIRE_DAMAGE',
  'WATER_DAMAGE',
  'BURGLARY',
  'BIKE_THEFT',
  'GLASS_BREAKAGE',
  'OTHER',
] as const;
export type HouseholdClaimType = typeof householdClaimTypes[number];
