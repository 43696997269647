import { Tariff } from '@getpopsure/private-health-insurance-pricing-engine';
import { SelectMenuOption } from 'components/SelectMenu';

export const primoTariffNames = [
  'Primo',
  'PRIMO',
  'PRIMO.Bonus Z',
  'PRIMO.SB 1 Z',
  'PRIMO.SB 2 Z',
  'PRIMO.SB 3 Z',
];
export const primoPlusTariffNames = [
  'PRIMO_PLUS',
  'PrimoPlus',
  'PRIMO.Bonus Z plus',
  'PRIMO.SB 1 Z plus',
  'PRIMO.SB 2 Z plus',
  'PRIMO.SB 3 Z plus',
];

export const ksTariffNames = ['KS', 'KS.2', 'KS.1', 'KS.Bonus'];

export const nkTariffNames = ['NK', 'NK.1', 'NK.2', 'NK.3', 'NK.4', 'NK.Bonus'];

export const nkSelectXLTariffNames = [
  'NK_SELECT_XL',
  'NKSelectXL',
  'NK.select XL Bonus',
];
export const nkSelectLTariffNames = [
  'NK_SELECT_L',
  'NKSelectL',
  'NK.select L Bonus',
  'NK.select L 600',
  'NK.select L 1200',
  'NK.select L 3000',
];
export const nkSelectSTariffNames = [
  'NK_SELECT_S',
  'NKSelectS',
  'NK.select S Bonus',
  'NK.select S 600',
  'NK.select S 1200',
  'NK.select S 3000',
];
export const hiMedicalTariffNames = [
  'HI_MEDICAL',
  'HiMedical',
  'Hi.Medical S P500',
  'Hi.Medical S 1200',
];
export const hiMedicalPlusTariffNames = [
  'HI_MEDICAL_PLUS',
  'HiMedicalPlus',
  'Hi.Medical L P500',
  'Hi.Medical L 1200',
];
export const emergencyTariffNames = ['NLTN.'];

export const getPrivateTariffName = (tariff: string) => {
  if (primoTariffNames.includes(tariff)) return 'Primo';
  if (primoPlusTariffNames.includes(tariff)) return 'Primo plus';
  if (ksTariffNames.includes(tariff)) return 'KS';
  if (nkTariffNames.includes(tariff)) return 'NK';
  if (nkSelectXLTariffNames.includes(tariff)) return 'NK select XL';
  if (nkSelectLTariffNames.includes(tariff)) return 'NK select L';
  if (nkSelectSTariffNames.includes(tariff)) return 'NK select S';
  if (hiMedicalTariffNames.includes(tariff)) return 'Hi medical S';
  if (hiMedicalPlusTariffNames.includes(tariff)) return 'Hi medical L';
  if (emergencyTariffNames.includes(tariff)) return 'Emergency';

  return tariff;
};

export const tariffs: SelectMenuOption<Tariff | 'NLTN.'>[] = [
  {
    id: 'Primo',
    label: 'Primo',
  },
  {
    id: 'PrimoPlus',
    label: 'Primo plus',
  },
  {
    id: 'KS',
    label: 'KS',
  },
  {
    id: 'NK',
    label: 'NK',
  },
  {
    id: 'NKSelectXL',
    label: 'NK select XL',
  },
  {
    id: 'NKSelectS',
    label: 'NK select S',
  },
  {
    id: 'NKSelectL',
    label: 'NK select L',
  },
  {
    id: 'HiMedical',
    label: 'Hi medical S',
  },
  {
    id: 'HiMedicalPlus',
    label: 'Hi medical L',
  },
  {
    id: 'NLTN.',
    label: 'Emergency',
  },
];
