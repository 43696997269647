import { InclusiveMember } from 'shared/insurances/planMappers/liability';

export const getLiabilityReadableTariff = (
  inclusiveMembers?: InclusiveMember[]
): string => {
  if (
    inclusiveMembers?.includes('PARENTS') ||
    inclusiveMembers?.includes('KIDS')
  ) {
    return 'Partner, parent(s), kid(s)';
  }

  if (inclusiveMembers?.includes('PARTNER')) {
    return 'Partner';
  }

  return 'Single';
};
