export const questionnaireTypes = [
  'DENTAL_PRE_QUOTE',
  'DENTAL_POST_QUOTE',
  'HOUSEHOLD',
  'LIABILITY',
  'LEGAL',
  'LEGALV2',
  'LIFE_PRE_QUOTE',
  'LIFE_POST_QUOTE',
  'LIFE_SCREENED_OUT',
  'BIKE_PRE_QUOTE',
  'BIKE_POST_QUOTE',
  'BIKE_SCREENED_OUT',
  'DOG_LIABILITY',
  'PUBLIC_HEALTH',
  'PRIVATE_HEALTH',
  'PRIVATE_HEALTH_INFO_COLLECTION',
  'PRIVATE_HEALTH_PRE_SIGNUP',
  'EXPAT_PRE_QUOTE',
  'EXPAT_POST_QUOTE',
  'EXPAT_DEPENDENT',
  'EXPAT_V2',
  'EXPAT_V2_DEPENDENT',
  'INCOMING',
  'B2B_LANDING_PAGE',
  'DISABILITY',
  'SIGNUPTK',
  'INCOMING_ES',
  'INCOMING_ES_DEPENDENT',
] as const;
export type QuestionnaireType = typeof questionnaireTypes[number];
