import { DocumentSection } from 'components/DocumentsSection/DocumentsSection';

import { retrieveFileFriendlyName } from '../../../../shared/utils/retrieveFileFriendlyName';
import { GenericPolicyData } from '../models/GenericPolicyData';

export const getDocumentSections = (
  data?: GenericPolicyData
): DocumentSection[] => {
  const featherUploads: DocumentSection = {
    title: 'Feather uploads',
    documents: [],
  };

  const customerUploads: DocumentSection = {
    title: 'Customer uploads',
    documents: [],
  };

  const checkoutDownloads: DocumentSection = {
    title: 'Checkout downloads',
    documents: [],
  };

  const providerUploads: DocumentSection = {
    title: 'Provider uploads',
    documents: [],
  };

  const archivedDocuments: DocumentSection = {
    title: 'Archived documents',
    documents: [],
    defaultOpen: false,
  };

  data?.genericPolicy?.policyDocuments
    .filter((document) => !document.archivedAt && !document.isFromProvider)
    .forEach((document) => {
      featherUploads.documents.push({
        ...document,
        friendlyName: document.name,
        fileName: document.blobName
          ? retrieveFileFriendlyName(document.blobName)
          : document.name,
        canArchive: true,
        canDelete: true,
        canEdit: true,
      });
    });

  data?.genericPolicy?.customerUploads?.forEach((document) => {
    customerUploads.documents.push({
      ...document,
      fileName: retrieveFileFriendlyName(document.blobName),
    });
  });

  data?.genericPolicy?.questionnaireDownloadDocuments.forEach((document) => {
    checkoutDownloads.documents.push({
      ...document,
      fileName: retrieveFileFriendlyName(document.blobName),
    });
  });

  data?.genericPolicy?.policyDocuments
    .filter((document) => document.isFromProvider)
    .forEach((document) => {
      providerUploads.documents.push({
        ...document,
        friendlyName: document.name,
        fileName: document.blobName
          ? retrieveFileFriendlyName(document.blobName)
          : document.name,
        canArchive: true,
        canDelete: true,
        canEdit: true,
      });
    });

  data?.genericPolicy?.policyDocuments
    .filter((document) => document.archivedAt)
    .forEach((document) => {
      archivedDocuments.documents.push({
        ...document,
        friendlyName: document.name,
        fileName: document.blobName
          ? retrieveFileFriendlyName(document.blobName)
          : document.name,
        canArchive: true,
        canDelete: true,
      });
    });

  return [
    featherUploads,
    customerUploads,
    providerUploads,
    checkoutDownloads,
    archivedDocuments,
  ];
};
