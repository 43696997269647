import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import { HeaderConfig } from 'components/TableRefactor/types';

export const tableHeaders: HeaderConfig[] = [
  {
    id: 'firstName',
    label: 'First name',
    width: 'w-[180px]',
    minWidth: 'min-w-[180px]',
    sticky: true,
    threshold: 'left-0',
    border: 'none',
    component: SortableColumnHeader,
  },
  {
    id: 'lastName',
    label: 'Last name',
    width: 'w-[180px]',
    minWidth: 'min-w-[180px]',
    sticky: true,
    threshold: 'left-[180px]',
    border: 'none',
    component: SortableColumnHeader,
  },
  {
    id: 'status',
    label: 'Status',
    width: 'w-[180px]',
    minWidth: 'min-w-[180px]',
    sticky: true,
    threshold: 'left-[360px]',
    border: 'right',
    component: SortableColumnHeader,
  },
  {
    id: 'claimType',
    label: 'Claim type',
    width: 'w-[180px]',
    minWidth: 'min-w-[180px]',
    component: ColumnHeader,
  },
  {
    id: 'createdAt',
    label: 'Created on',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: SortableColumnHeader,
  },
  {
    id: 'claimNumber',
    label: 'Claim number',
    width: 'w-[180px]',
    minWidth: 'min-w-[180px]',
    component: ColumnHeader,
  },
  {
    id: 'provision',
    label: 'Claim provision',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    component: ColumnHeader,
  },
];
