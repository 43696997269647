import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import { BikePlan, bikePlanMapper } from 'shared/insurances/planMappers/bike';
import { bikeStatusMapper } from 'shared/insurances/statusMappers/bike';
import { BikeStatus } from 'shared/insurances/types';

const statusFilterOptions: SelectButtonOption<BikeStatus>[] = [
  {
    id: 'PENDING',
    label: bikeStatusMapper.PENDING,
    title: bikeStatusMapper.PENDING,
  },
  {
    id: 'APPLICATION_SENT',
    label: bikeStatusMapper.APPLICATION_SENT,
    title: bikeStatusMapper.APPLICATION_SENT,
  },
  {
    id: 'ACTIVE',
    label: bikeStatusMapper.ACTIVE,
    title: bikeStatusMapper.ACTIVE,
  },
  {
    id: 'CANCELED',
    label: bikeStatusMapper.CANCELED,
    title: bikeStatusMapper.CANCELED,
  },
];

const planFilterOptions: SelectButtonOption<BikePlan>[] = [
  {
    id: 'BASIC',
    label: bikePlanMapper.BASIC,
    title: bikePlanMapper.BASIC,
  },
  {
    id: 'COMFORT',
    label: bikePlanMapper.COMFORT,
    title: bikePlanMapper.COMFORT,
  },
];

const providerFilterOptions: SelectButtonOption<string>[] = [
  {
    id: 'BERLIN_DIREKT',
    label: 'Berlin Direkt',
    title: 'Berlin Direkt',
  },
  {
    id: 'BARMENIA',
    label: 'Barmenia',
    title: 'Barmenia',
  },
];

export const filters: FilterConfig[] = [
  {
    options: statusFilterOptions,
    filterType: 'MULTIPLE',
    label: 'Status',
    id: 'filterStatus',
    placeholder: 'Select status(es)',
  },
  {
    options: planFilterOptions,
    filterType: 'MULTIPLE',
    label: 'Plan',
    id: 'filterPlan',
    placeholder: 'Select plan(s)',
  },
  {
    options: providerFilterOptions,
    filterType: 'MULTIPLE',
    label: 'Provider',
    id: 'filterProvider',
    placeholder: 'Select provider(s)',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Start date',
    id: 'filterStartDate',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Created on',
    id: 'filterCreatedAt',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Archived on',
    id: 'filterArchivedAt',
  },
];
