export const fetchBlob = async (url: string, mimeType: string) => {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': mimeType,
    },
  });
  let blob = await response.blob();
  if (!blob.type) {
    blob = new Blob([blob], {
      type: mimeType,
    });
  }

  return blob;
};
