import { getFixedT } from '@getpopsure/i18n-react';

export const getFilterViewOptions = (providerId: string) => {
  const t = getFixedT();
  return [
    {
      id: 'all',
      label: t('admin.filters.allpolicies', 'All policies'),
      title: t('admin.filters.allpolicies', 'All policies'),
      show: true,
      dot: false,
    },
    {
      id: 'action-pending',
      label: t('admin.filters.actionneeded', 'Action needed'),
      title: t('admin.filters.actionneeded', 'Action needed'),
      show: true,
      dot: false,
    },
    ...(providerId === 'barmer'
      ? [
          {
            id: 'commission-check',
            label: t('admin.filters.commissioncheck', 'Commission check'),
            title: t('admin.filters.commissioncheck', 'Commission check'),
            show: true,
            dot: false,
          },
        ]
      : []),
  ];
};
