import { ZodError } from 'zod';

export const retrieveZodErrorMessages = <ValidationError>(
  error: ZodError
): Partial<ValidationError> => {
  let errorMessages: Partial<ValidationError> = {};

  error.issues.forEach(({ message, path }) => {
    errorMessages = { ...errorMessages, [path[0]]: message };
  });

  return errorMessages;
};
