import { InformationSection } from 'components/InformationCard';
import DateInformation from 'components/InformationCard/DateInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { DentalPolicy } from 'pages/policies/dental/models/DentalPolicy';
import { policyMappers } from 'shared/insurances';
import { dentalPlanMapper } from 'shared/insurances/planMappers/dental';

export const retrieveDentalPolicyInfoRows = (
  policyInfo: DentalPolicy
): InformationSection[] => [
  {
    title: 'Policy information',
    boldTitle: false,
    rows: [
      {
        id: 'status',
        title: 'Status',
        data:
          policyMappers.DENTAL?.statusMapper[policyInfo?.publicStatus] ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
      {
        id: 'policyNumber',
        title: 'Policy number',
        data: policyInfo?.policyNumber ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
      {
        id: 'startDate',
        title: 'Start date',
        data: policyInfo?.startDate ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: false,
      },
      {
        id: 'plan',
        title: 'Plan',
        data: policyInfo?.plan ? dentalPlanMapper[policyInfo.plan] : '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
    ],
  },
];
