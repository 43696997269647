import Loader from 'components/Loader';

import * as styles from './styles';

const LoadingState = ({ message }: { message: string }) => {
  return (
    <div className={styles.loadingState}>
      <div className={styles.contentWrapper}>
        <Loader className={styles.loader} />
        <p className={styles.text}>{message}</p>
      </div>
    </div>
  );
};

export default LoadingState;
