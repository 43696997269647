import classNames from 'classnames';
import ComboBox from 'components/ComboBox';
import { ComboBoxOption } from 'components/ComboBox/ComboBox';
import Input from 'components/Input';
import Modal from 'components/Modal';
import RadioButton from 'components/RadioButton';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import { ChangeEvent, useState } from 'react';

import * as styles from './styles';

interface CreateAdminUserFormProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  createAdminUser: (variables: {
    firstName: string;
    lastName: string;
    email: string;
    isFeather: boolean;
    companyId?: string;
    roles?: string[];
    language: string;
    hasAccessToNewAdminPanel: boolean;
    hasAccessToOldAdminPanel: boolean;
  }) => void;
  loading: boolean;
  errorMessage?: string;
  companyOptions: ComboBoxOption[];
  setCompaniesQuery: (query: string) => void;
}

const languageOptions: SelectMenuOption[] = [
  {
    id: 'en',
    label: 'English',
  },
  {
    id: 'de',
    label: 'German',
  },
];

const roleOptions: ComboBoxOption[] = [
  {
    id: 'HR_PARTNER',
    label: 'HR Partner',
  },
  {
    id: 'PUBLIC_HEALTH_PROVIDER_ADMIN',
    label: 'Public health provider admin',
  },
  {
    id: 'PUBLIC_HEALTH_PROVIDER_AGENT',
    label: 'Public health provider agent',
  },
  {
    id: 'PUBLIC_HEALTH_PROVIDER_AGENT_PLUS',
    label: 'Public health provider agent plus',
  },
];

const CreateAdminUserForm = ({
  open,
  setOpen,
  createAdminUser,
  loading,
  errorMessage,
  companyOptions,
  setCompaniesQuery,
}: CreateAdminUserFormProps) => {
  const [formData, setFormData] = useState<{
    firstName: string;
    lastName: string;
    email: string;
    isFeather: boolean;
    company?: ComboBoxOption;
    role?: ComboBoxOption;
    language: SelectMenuOption | undefined;
    hasAccessToNewAdminPanel: boolean;
    hasAccessToOldAdminPanel: boolean;
  }>({
    firstName: '',
    lastName: '',
    email: '',
    isFeather: false,
    company: undefined,
    role: undefined,
    language: undefined,
    hasAccessToNewAdminPanel: false,
    hasAccessToOldAdminPanel: false,
  });

  const handleConfirm = () => {
    if (
      formData.firstName &&
      formData.lastName &&
      formData.email &&
      formData.language
    ) {
      if (
        (!formData.isFeather && formData.company && formData.role) ||
        formData.isFeather
      )
        createAdminUser({
          ...formData,
          companyId: formData.company?.id ?? undefined,
          language: formData.language?.id ?? 'en',
          roles: [formData.role?.id ?? ''],
        });
    }
  };

  const handleChangeOnInput = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleRadioButtonChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value === 'yes',
    });
  };

  const handleLanguageChange = (option: SelectMenuOption) => {
    setFormData({ ...formData, language: option });
  };

  const handleCompanyChange = (option: ComboBoxOption) => {
    setFormData({ ...formData, company: option });
  };
  const handleRolesChange = (option: ComboBoxOption) => {
    setFormData({ ...formData, role: option });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Create new admin"
      handleConfirm={handleConfirm}
      confirmButtonLabel="Create"
      loading={loading}
      scrollable={true}
      textLoading="Creating..."
    >
      <>
        <div className={styles.section}>
          <label htmlFor="firstName" className={styles.label}>
            First name
          </label>
          <Input
            id="firstName"
            type="text"
            required={true}
            placeholder="Enter first name"
            onChange={handleChangeOnInput}
            color="gray"
          />
        </div>
        <div className={styles.section}>
          <label htmlFor="lastName" className={styles.label}>
            Last name
          </label>
          <Input
            id="lastName"
            type="text"
            required={true}
            placeholder="Enter last name"
            onChange={handleChangeOnInput}
            color="gray"
          />
        </div>
        <div className={styles.section}>
          <label htmlFor="email" className={styles.label}>
            Email
          </label>
          <Input
            id="email"
            type="text"
            required={true}
            placeholder="Enter email address"
            onChange={handleChangeOnInput}
            color="gray"
          />
        </div>
        <div className="mt-[24px] w-full border-t border-gray-300" />
        <div className={styles.section}>
          <h1 className={styles.label}>Is this a Feather employee?</h1>
          <div className={styles.radioButtons}>
            <RadioButton
              id="isFeather"
              name="uploadDocument"
              value="yes"
              checked={!!formData.isFeather}
              onChange={handleRadioButtonChange}
            >
              Yes
            </RadioButton>
            <RadioButton
              id="isFeather"
              name="uploadDocument"
              value="no"
              checked={!formData.isFeather}
              onChange={handleRadioButtonChange}
            >
              No
            </RadioButton>
          </div>
        </div>
        <div
          className={classNames(styles.rolesWrapper, {
            'max-h-[0px]': formData.isFeather,
            'max-h-[200px]': !formData.isFeather,
            'overflow-visible': !formData.isFeather,
            'overflow-hidden': formData.isFeather,
          })}
        >
          <div className={styles.section}>
            <ComboBox
              placeholder="Select company"
              label="Company"
              selectedOption={formData.company}
              setSelectedOption={handleCompanyChange}
              options={companyOptions}
              useExternalQuery={true}
              setExternalQuery={setCompaniesQuery}
              color="gray"
            />
          </div>
          <div className={styles.section}>
            <ComboBox
              placeholder="Select role"
              label="Role"
              selectedOption={formData.role}
              setSelectedOption={handleRolesChange}
              options={roleOptions}
              multiple={false}
              color="gray"
            />
          </div>
        </div>
        <div className="mt-[24px] w-full border-t border-gray-300" />
        <div className={styles.section}>
          <h1 className={styles.label}>Has access to old admin panel?</h1>
          <div className={styles.radioButtons}>
            <RadioButton
              id="hasAccessToOldAdminPanel"
              name="accessOldAdminPanel"
              value="yes"
              checked={formData.hasAccessToOldAdminPanel}
              onChange={handleRadioButtonChange}
            >
              Yes
            </RadioButton>
            <RadioButton
              id="hasAccessToOldAdminPanel"
              name="accessOldAdminPanel"
              value="no"
              checked={!formData.hasAccessToOldAdminPanel}
              onChange={handleRadioButtonChange}
            >
              No
            </RadioButton>
          </div>
        </div>
        <div className={styles.section}>
          <h1 className={styles.label}>Has access to new admin panel?</h1>
          <div className={styles.radioButtons}>
            <RadioButton
              id="hasAccessToNewAdminPanel"
              name="accessNewAdminPanel"
              value="yes"
              checked={formData.hasAccessToNewAdminPanel}
              onChange={handleRadioButtonChange}
            >
              Yes
            </RadioButton>
            <RadioButton
              id="hasAccessToNewAdminPanel"
              name="accessNewAdminPanel"
              value="no"
              checked={!formData.hasAccessToNewAdminPanel}
              onChange={handleRadioButtonChange}
            >
              No
            </RadioButton>
          </div>
        </div>
        <div className="mt-[24px] w-full border-t border-gray-300" />
        <div className={styles.section}>
          <SelectMenu
            selected={formData.language}
            setSelected={handleLanguageChange}
            placeholder="Select language"
            options={languageOptions}
            label="Default language"
            color="gray"
          />
        </div>
        <div className={styles.section}>
          <p className={styles.errorText}>{errorMessage}</p>
        </div>
      </>
    </Modal>
  );
};

export default CreateAdminUserForm;
