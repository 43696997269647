import { useTranslation } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import QuestionnaireCard from 'components/QuestionnaireCard';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import { useRef } from 'react';
import { useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';

import SideSection from '../SideSection';

const QuestionnaireTabPublicProvider = ({
  policy,
}: {
  policy: PublicHealthPolicy;
}) => {
  const questionnaireTabRef = useRef(null);
  const { t } = useTranslation();
  const handlePrint = useReactToPrint({
    content: () => questionnaireTabRef.current,
  });
  const { provider } = useParams<{ provider: string }>();

  const printButton = (
    <Button
      buttonType="secondary"
      onClick={handlePrint}
      className="w-[88px] print:hidden"
    >
      {t('admin.provider.policy.public.questionnaire.print.label', 'Print')}
    </Button>
  );

  const answersWithOptionalBarmerDetails =
    provider === 'barmer'
      ? {
          partnernummer: '131308217',
        }
      : {};

  return (
    <>
      <div ref={questionnaireTabRef} className="w-full">
        <QuestionnaireCard
          questionnaireId={policy.questionnaire?.id ?? ''}
          policyId={policy.id}
          answers={
            policy.questionnaire?.answers
              ? {
                  ...policy.questionnaire?.answers,
                  ...answersWithOptionalBarmerDetails,
                }
              : {}
          }
          cardTitle="Personal information"
          additionalAction={printButton}
        />
      </div>
      <div className="w-full lg:ml-[20px] lg:w-auto">
        <SideSection policy={policy} />
      </div>
    </>
  );
};

export default QuestionnaireTabPublicProvider;
