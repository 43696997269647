import { CustomerFormData } from './models';

export const customerFormData: CustomerFormData[] = [
  {
    id: 'firstName',
    label: 'First name',
    renderDetails: { type: 'text', placeholder: 'Enter first name' },
    required: true,
    color: 'gray',
    errorId: 'firstName',
  },
  {
    id: 'lastName',
    label: 'Last name',
    renderDetails: { type: 'text', placeholder: 'Enter last name' },
    required: true,
    color: 'gray',
    errorId: 'lastName',
  },
  {
    id: 'email',
    label: 'Email',
    renderDetails: { type: 'email', placeholder: 'Enter email address' },
    required: true,
    color: 'gray',
    errorId: 'email',
  },
  {
    id: 'gender',
    label: 'Gender',
    renderDetails: {
      type: 'select',
      options: [
        { id: 'MALE', label: 'Male' },
        { id: 'FEMALE', label: 'Female' },
        { id: 'OTHER', label: 'Other' },
      ],
      placeholder: 'Select gender',
    },
    color: 'gray',
  },
  {
    id: 'dateOfBirth',
    label: 'Date of birth',
    renderDetails: { type: 'date' },
    color: 'gray',
  },
];
