import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import { DocumentNode } from 'graphql';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import * as styles from '../styles';

export const ArchiveButton = ({
  mutation,
  documentId,
  getResourceQuery,
}: {
  mutation: DocumentNode;
  documentId: string;
  getResourceQuery?: DocumentNode;
}) => {
  const { t } = useTranslation();
  const alertBannersState = useReactiveVar(alertBanners);
  const [archiveDocument] = useMutation(mutation, {
    variables: {
      id: documentId,
    },
    refetchQueries: getResourceQuery ? [getResourceQuery] : [],
    onCompleted: () => {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message: t(
          'admin.documents.archive.success.title',
          'Document archived.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
    onError: () => {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: t(
          'admin.documents.archive.error.title',
          'Something went wrong. Please try again.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
  });

  return (
    <button
      type="button"
      className={styles.dropdownOption}
      onClick={() => archiveDocument()}
    >
      {t('admin.documents.action.archive.label', 'Archive')}
    </button>
  );
};
