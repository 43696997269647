import Input from 'components/Input';
import Modal from 'components/Modal';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import { ChangeEvent, useState } from 'react';
import { companyTypeOptions } from 'shared/mapCompanyType';

import * as styles from './styles';

interface CreateCompanyFormProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  createCompany: (variables: {
    name: string;
    type: string;
    sources: string;
  }) => void;
  loading: boolean;
  errorMessage?: string;
}

const CreateCompanyForm = ({
  open,
  setOpen,
  createCompany,
  loading,
  errorMessage,
}: CreateCompanyFormProps) => {
  const [name, setName] = useState('');
  const [sources, setSources] = useState('');
  const [type, setType] = useState<SelectMenuOption | undefined>(undefined);

  const handleConfirm = () => {
    if (name && sources && type) {
      createCompany({ name, sources, type: type.id });
    }
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleSourcesChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSources(e.target.value);
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add Company"
      handleConfirm={handleConfirm}
      confirmButtonLabel="Add"
      loading={loading}
      textLoading="Adding..."
    >
      <>
        <div className={styles.section}>
          <label htmlFor="name" className={styles.label}>
            Company name
          </label>
          <Input
            id="name"
            type="text"
            required={true}
            placeholder="Enter company name"
            onChange={handleNameChange}
            color="gray"
          />
        </div>
        <div className={styles.section}>
          <label htmlFor="sources" className={styles.label}>
            Sources
          </label>
          <Input
            id="sources"
            type="text"
            required={true}
            placeholder="Enter sources (eg. source 1, source 2...)"
            onChange={handleSourcesChange}
            color="gray"
          />
        </div>
        <div className={styles.section}>
          <SelectMenu
            selected={type}
            setSelected={setType}
            placeholder="Select company type"
            options={companyTypeOptions}
            label="Company type"
            color="gray"
          />
        </div>
        <div className={styles.section}>
          <p className={styles.errorText}>{errorMessage}</p>
        </div>
      </>
    </Modal>
  );
};

export default CreateCompanyForm;
