import { BadgeColor } from 'components/Badge/Badge';

import { HouseholdClaimStatus } from './models';

export const badgeColorMapper: Record<HouseholdClaimStatus, BadgeColor> = {
  RECEIVED: 'purple',
  INCOMPLETE: 'red',
  MISSING_INFO_RECEIVED: 'purple',
  SUBMITTED_TO_PROVIDER: 'blue',
  OTHER_PAID_OUT: 'green',
  FEATHER_PAID_OUT: 'gray',
  CUSTOMER_PAID_OUT: 'green',
  DENIED: 'yellow',
  DUPLICATE: 'gray',
  IN_REVIEW: 'blue',
  DROPPED_OUT: 'gray',
  ON_HOLD: 'gray',
  CLOSED: 'gray',
  APPROVED_WITH_OTHER_CLAIMS: 'green',
};
