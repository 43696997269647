import {
  PrivateHealthStatus,
  privateHealthStatuses,
} from 'shared/insurances/types';
import { z, ZodObject, ZodRawShape } from 'zod';

export const privateHealthStatusesWithoutDeprecatedStatus: PrivateHealthStatus[] =
  privateHealthStatuses.filter((status) => !['PENDING'].includes(status));

const zodSchema = z.object({
  newStatus: z.enum(
    // TODO: [KONG] Remove type inferencing
    privateHealthStatusesWithoutDeprecatedStatus as [string, ...string[]]
  ),
  issueIdsToResolve: z.array(z.string().uuid()).optional(),
  taskIdsToCancel: z.array(z.string().uuid()).optional(),
});

export const getZodSchema = (
  status: PrivateHealthStatus,
  shouldCreateAnIssue: boolean
): ZodObject<ZodRawShape> => {
  switch (status) {
    case 'APPLICATION_SENT':
      return zodSchema.extend({
        messageToProvider: z.string().optional(),
        isUrgentApplication: z.boolean().optional(),
      });

    case 'ACTIVE':
      return zodSchema.extend({
        startDate: z.string(),
        policyNumber: z.string(),
      });

    case 'ERR_MISSING_INFO': {
      if (shouldCreateAnIssue) {
        return zodSchema.extend({
          issueTitle: z.string().optional(),
          issueDescription: z.string(),
        });
      }

      return zodSchema;
    }

    case 'CANT_BE_COVERED':
      return zodSchema.extend({
        reasonForDenial: z.string(),
        messageToCustomer: z.string(),
      });

    case 'CANCELED':
      return zodSchema.extend({
        endDate: z.string(),
      });

    default:
      return zodSchema;
  }
};
