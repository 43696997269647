import { SelectMenuOption } from 'components/SelectMenu';

export const policyDocumentCategories: SelectMenuOption[] = [
  {
    id: 'APPLICATION',
    label: 'Application',
  },
  {
    id: 'CONFIRMATION_OF_COVERAGE',
    label: 'Confirmation of coverage',
  },
  {
    id: 'DEPENDENT_CONFIRMATION_OF_COVERAGE',
    label: 'Dependent confirmation of coverage',
  },
  {
    id: 'MERGED_CONFIRMATION_OF_COVERAGE',
    label: 'Merged confirmation of coverage',
  },
  {
    id: 'CONFIRMATION_OF_COVERAGE_FROM_PROVIDER',
    label: 'Confirmation of coverage from provider',
  },
  {
    id: 'PRELIMINARY_CONFIRMATION_OF_COVERAGE_FROM_PROVIDER',
    label: 'Preliminary confirmation of coverage from provider',
  },
  {
    id: 'LETTER_FOR_EMPLOYER',
    label: 'Letter for employer',
  },
  {
    id: 'SIGNED_TK_APPLICATION',
    label: 'Signed TK Application',
  },
  {
    id: 'LETTER_FOR_VISA',
    label: 'Letter for visa',
  },
  {
    id: 'LETTER_FOR_UNIVERSITY',
    label: 'Letter for university',
  },
  {
    id: 'TAX_STATEMENT',
    label: 'Tax statement',
  },
  {
    id: 'RISK_SURCHARGE',
    label: 'Risk surcharge',
  },
  {
    id: 'EXCLUSIONS',
    label: 'Exclusions',
  },
  {
    id: 'INSURANCE_POLICY',
    label: 'Insurance policy',
  },
  {
    id: 'INSURANCE_COVERAGE',
    label: 'Insurance coverage',
  },
  {
    id: 'ADVICE_RECORD',
    label: 'Advice record',
  },
  {
    id: 'SIMPLE_OVERVIEW',
    label: 'Simple overview',
  },
  {
    id: 'CUSTOMER_PROTECTION',
    label: 'Customer protection',
  },
  {
    id: 'CLAIMS_PAYOUT',
    label: 'Claims payout',
  },
  {
    id: 'CERTIFICATE_OF_AUTHORITY',
    label: 'Certificate of authority',
  },
  {
    id: 'SEPA_MANDATE',
    label: 'Sepa mandate',
  },
  {
    id: 'CONFIRMATION_OF_PERMANENT_RESIDENCE',
    label: 'Confirmation of permanent residence',
  },
  {
    id: 'PRODUCT_INFO',
    label: 'Product info',
  },
  {
    id: 'PREMIUM_INVOICE',
    label: 'Premium invoice',
  },
  {
    id: 'BENEFICIARY_FORM',
    label: 'Beneficiary form',
  },
  {
    id: 'CONSENT_FORM',
    label: 'Consent form',
  },
  {
    id: 'TERMS_AND_CONDITIONS',
    label: 'Terms and conditions',
  },
  {
    id: 'IPID',
    label: 'IPID',
  },
  {
    id: 'DISCLOSURE',
    label: 'Disclosure',
  },
  {
    id: 'ANLAGE_6',
    label: 'Anlage 6',
  },
  {
    id: 'LETTER_OF_INTENTION',
    label: 'Letter of intention',
  },
  {
    id: 'INVOICE',
    label: 'Invoice',
  },
  {
    id: 'CONSENT_TO_DATA_TRANSFER',
    label: 'Consent to data transfer',
  },
  {
    id: 'RETURN_TO_GERMANY',
    label: 'Return to Germany',
  },
  {
    id: 'OTHER',
    label: 'Other',
  },
].sort((a, b) => a.id.localeCompare(b.id));
