import { useQuery } from '@apollo/client';
import FileErrorCard from 'components/FileErrorCard';
import Loader from 'components/Loader';
import Modal from 'components/Modal';

import { GET_READY_TRANSACTIONS_DETAILS } from '../../graphql/readyTransactionDetails';
import { useClaimAmountsValidation } from '../../hooks/useClaimAmountsValidation';
import {
  expatTransactionInsuranceTypes,
  TransactionInsuranceType,
} from '../../models/TransactionInsuranceType';

interface ExportConfirmationModalProps {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  loadingExport: boolean;
  handleClickOnExport: () => void;
  insuranceType: TransactionInsuranceType;
}

const ExportConfirmationModal = ({
  openModal,
  setOpenModal,
  loadingExport,
  handleClickOnExport,
  insuranceType,
}: ExportConfirmationModalProps) => {
  const { data, loading } = useQuery<{
    readyTransactionDetails: {
      readyCount: number;
      invalidIBANCount: number;
      mismatchedAmountsCount: number;
      missingClaimNumberCount: number;
      delinquentCustomersCount: number;
    };
  }>(GET_READY_TRANSACTIONS_DETAILS, {
    variables: {
      insuranceType,
    },
    fetchPolicy: 'network-only',
  });

  const { exportWarning } = useClaimAmountsValidation(insuranceType);

  return (
    <Modal
      open={openModal}
      setOpen={setOpenModal}
      title="Export as CSV"
      confirmButtonLabel="Export"
      textLoading="Exporting..."
      handleConfirm={handleClickOnExport}
      loading={loadingExport}
      disabled={
        (data?.readyTransactionDetails?.readyCount ?? 0) < 1 ||
        (expatTransactionInsuranceTypes.includes(insuranceType) &&
          (data?.readyTransactionDetails.mismatchedAmountsCount ?? 0) > 0)
      }
    >
      {loading ? (
        <div className="flex justify-center">
          <Loader className="h-7 w-7 animate-spin" />
        </div>
      ) : (
        <>
          <div className="text-sm text-gray-600">
            {(data?.readyTransactionDetails?.readyCount ?? 0) < 1 ? (
              'No transactions in ready status. No transactions can be exported right now.'
            ) : (
              <span>
                <b>{data?.readyTransactionDetails.readyCount} transactions</b>{' '}
                with ready status have been selected. These transactions will be
                included in the CSV file.{' '}
              </span>
            )}
          </div>
          {(!!data?.readyTransactionDetails.invalidIBANCount ||
            !!data?.readyTransactionDetails.mismatchedAmountsCount ||
            !!data?.readyTransactionDetails.missingClaimNumberCount ||
            !!data?.readyTransactionDetails.delinquentCustomersCount) && (
            <div className="mt-6">
              {!!data.readyTransactionDetails.invalidIBANCount && (
                <FileErrorCard
                  open={true}
                  title={`${data?.readyTransactionDetails.invalidIBANCount} transaction(s) have invalid IBAN.`}
                  handleClose={() => {}}
                  errorType="ERROR"
                  hideCloseButton={true}
                />
              )}
              {!!data.readyTransactionDetails.missingClaimNumberCount && (
                <FileErrorCard
                  open={true}
                  title={`${data?.readyTransactionDetails.missingClaimNumberCount} transaction(s) have missing claim numbers.`}
                  handleClose={() => {}}
                  errorType="ERROR"
                  hideCloseButton={true}
                />
              )}
              {!!data.readyTransactionDetails.mismatchedAmountsCount && (
                <FileErrorCard
                  open={true}
                  title={`${data?.readyTransactionDetails.mismatchedAmountsCount} ${exportWarning}`}
                  handleClose={() => {}}
                  errorType="WARNING"
                  hideCloseButton={true}
                />
              )}
              {!!data.readyTransactionDetails.delinquentCustomersCount && (
                <FileErrorCard
                  open={true}
                  title={`${data?.readyTransactionDetails.delinquentCustomersCount} transaction(s) belong to delinquent customers.`}
                  handleClose={() => {}}
                  errorType="ERROR"
                  hideCloseButton={true}
                />
              )}
              <div className="mt-6 text-sm text-gray-600">
                {expatTransactionInsuranceTypes.includes(insuranceType) &&
                (data?.readyTransactionDetails.mismatchedAmountsCount ?? 0) >
                  0 ? (
                  <span>
                    Please fix the mismatched amounts before exporting.
                  </span>
                ) : (
                  <span>Do you still want to export?</span>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default ExportConfirmationModal;
