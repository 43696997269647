import { ModalFormData } from 'components/ModalForm/models';
import dayjs from 'dayjs';
import { retrievePolicySuggestion } from 'pages/claims/template/components/CreateClaimModal/utils';
import { BikePolicy } from 'pages/policies/bike/models/BikePolicy';
import { claimTypeNameMapper as bikeClaimTypeNameMapper } from 'shared/claims/bike/claimTypeNameMapper';
import {
  BikeClaimStatus,
  bikeClaimStatuses,
  BikeClaimType,
  bikeClaimTypes,
} from 'shared/claims/bike/models';
import { statusNameMapper as bikeStatusNameMapper } from 'shared/claims/bike/statusNameMapper';

import { SubmittableClaim } from '../../../template/components/CreateClaimModal/models';

export const retrieveCreateClaimFormData = (
  policies: BikePolicy[],
  setPolicySearchQuery: (query: string) => void,
  policiesLoading: boolean
): ModalFormData<
  SubmittableClaim<BikeClaimStatus, BikeClaimType>,
  BikeClaimStatus | BikeClaimType | string
>[] => [
  {
    id: 'policyId',
    label: 'User policy',
    renderDetails: {
      type: 'autoSuggest',
      options: policies.map(({ id, user, policyNumber }) => ({
        id,
        label: retrievePolicySuggestion({
          firstName: user?.firstName,
          lastName: user?.lastName,
          policyNumber,
        }),
      })),
      placeholder: 'Enter name, email or policy number',
      setExternalQuery: (query: string) => {
        setPolicySearchQuery(query);
      },
      useUnfilteredOptions: true,
    },
    color: 'gray',
    required: true,
    loading: policiesLoading,
  },
  {
    id: 'status',
    label: 'Status',
    renderDetails: {
      type: 'select',
      options: bikeClaimStatuses.flatMap((status) => ({
        id: status,
        label: bikeStatusNameMapper[status],
      })),
      placeholder: 'Select a status',
    },
    color: 'gray',
    required: true,
  },
  {
    id: 'claimType',
    label: 'Claim type',
    renderDetails: {
      type: 'multiSelect',
      options: bikeClaimTypes.map((type) => ({
        id: type,
        label: bikeClaimTypeNameMapper[type],
      })),
      placeholder: 'Select type',
    },
    color: 'gray',
    required: true,
  },

  {
    id: 'dateOfEvent',
    label: 'Event date',
    renderDetails: { type: 'date', maxDate: dayjs().toISOString() },
    color: 'gray',
    required: true,
  },
  {
    id: 'eventDescription',
    label: 'Event description',
    renderDetails: { type: 'textarea', placeholder: 'Start typing...' },
    color: 'gray',
  },
  {
    id: 'amount',
    label: 'Claim amount',
    renderDetails: { type: 'currency', placeholder: '0.00' },
    color: 'gray',
  },
  {
    id: 'iban',
    label: 'IBAN',
    renderDetails: { type: 'text', placeholder: 'Enter IBAN' },
    color: 'gray',
    required: true,
  },
  {
    id: 'uploadDocument',
    label: 'Upload a document',
    renderDetails: { type: 'upload' },
  },
];
