import classNames from 'classnames';

export const row = classNames(
  'px-[24px]',
  'py-[20px]',
  'border-t',
  'border-gray-200',
  'flex',
  'items-start'
);

export const rowEditMode = classNames(
  'px-[24px]',
  'py-[11px]',
  'border-t',
  'border-gray-200',
  'flex',
  'items-center'
);

export const rowLabel = classNames(
  'text-sm',
  'text-gray-600',
  'min-w-[180px]',
  'w-[180px]',
  'mr-[16px]'
);
