import { useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import CheckBadge from 'components/CheckBadge';
import DocumentCard from 'components/DocumentsCard/DocumentCard';
import dayjs from 'dayjs';
import { DocumentNode } from 'graphql';
import { DATE_FORMAT_TIME } from 'models/date';
import { Issue } from 'models/issue';
import ChangeIssueStatus from 'pages/policies/publicHealth/components/ChangeIssueStatus/ChangeIssueStatus';
import { getAuthor } from 'shared/getAuthor';
import { IssueCategory, mapIssueCategories } from 'shared/mapIssueCategories';
import { IssueStatusType, mapIssueStatus } from 'shared/mapIssueStatus';

import * as styles from './styles';

interface IssueCardProps {
  issue: Issue;
  showAuthor: boolean;
  smallVersion?: boolean;
  className?: string;
  showStatus: boolean;
  policyId?: string;
  showCustomerInformed: boolean;
  cardColor?: 'white' | 'gray';
  providerView?: boolean;
  issueStatusChangeMutation?: DocumentNode;
  policyQuery?: DocumentNode;
  uploadFileMutation?: DocumentNode;
  issueCategory?: string;
}

const IssueCard = ({
  issue,
  showAuthor,
  smallVersion = false,
  className,
  showStatus,
  policyId,
  showCustomerInformed,
  cardColor = 'gray',
  providerView = true,
  issueStatusChangeMutation,
  policyQuery,
  uploadFileMutation,
  issueCategory,
}: IssueCardProps) => {
  const { t } = useTranslation();
  const author = getAuthor(issue.author);

  return (
    <div
      className={classNames(
        className,
        smallVersion
          ? styles.issueCardWrapperSmall
          : styles.issueCardWrapperFull,
        cardColor === 'white' ? styles.whiteCard : styles.grayCard
      )}
    >
      <div className={styles.issueCardHeader}>
        <div className={styles.issueCategory}>
          <p className={classNames(styles.darkText, styles.issueCategoryText)}>
            {mapIssueCategories(issue.category as IssueCategory)}
          </p>
          <p className={styles.veryLightText}>
            {dayjs(issue.createdAt).format(DATE_FORMAT_TIME)}
          </p>
          {showAuthor && (
            <>
              <p className={styles.veryLightText}>
                {t('admin.activity.issue.author.by.label', 'by')}
              </p>
              <p className={styles.veryLightText}>{author}</p>
            </>
          )}
        </div>
        {showStatus && (
          <ChangeIssueStatus
            id={issue?.id}
            status={mapIssueStatus(issue.status as IssueStatusType).id}
            policyId={policyId ?? ''}
            providerView={providerView}
            disabled={!author}
            issueStatusChangeMutation={issueStatusChangeMutation}
            policyQuery={policyQuery}
            uploadFileMutation={uploadFileMutation}
            issueCategory={issueCategory}
          />
        )}
      </div>
      {!smallVersion &&
        showCustomerInformed &&
        issue.isCustomerInformed !== null && (
          <div className={styles.customerInformedSection}>
            <CheckBadge
              checkType={issue.isCustomerInformed ? 'check' : 'cross'}
            />
            <p className={styles.customerInformedText}>
              {`Customer ${
                issue.isCustomerInformed ? '' : 'not'
              } informed by provider`}
            </p>
          </div>
        )}
      <p className={classNames(styles.lightText, styles.description)}>
        {issue?.description}
      </p>
      {issue.document && !smallVersion && (
        <div className="mt-[12px] w-[300px]">
          <DocumentCard
            fileLink={issue.document?.url}
            fileName={issue.document.name ?? ''}
          />
        </div>
      )}
    </div>
  );
};

export default IssueCard;
