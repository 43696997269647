import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';

import TextInformation, { TextInformationProps } from '../TextInformation';
import TextWithDetailsInformation from '../TextWithDetailsInformation';
import * as styles from './styles';

const DropdownInformation = ({
  cell,
  id,
  editMode,
  editable,
  handleChange,
  handleDropdownChange,
  dropdownOptions,
  optionMapping,
  value,
  placeholder,
  renderModalDetails,
  detailsLabel,
  detailsTitle,
}: TextInformationProps) => {
  if (!dropdownOptions) {
    throw new Error('Dropdown options missing');
  }

  if (!optionMapping) {
    throw new Error('Option mapping function missing');
  }

  const handleOptionChange = (option: SelectMenuOption) => {
    if (handleDropdownChange) {
      handleDropdownChange(option, id);
    }
  };

  if (editMode && editable) {
    return (
      <SelectMenu
        className={styles.dropdown}
        selected={dropdownOptions.find((option) => option.id === value)}
        options={dropdownOptions}
        setSelected={handleOptionChange}
        placeholder={placeholder ?? ''}
      />
    );
  }

  if (renderModalDetails) {
    return (
      <TextWithDetailsInformation
        editMode={editMode}
        editable={editable}
        cell={optionMapping(cell.toString()) ?? ''}
        handleChange={handleChange}
        id={id}
        renderModalDetails={renderModalDetails}
        detailsLabel={detailsLabel}
        detailsTitle={detailsTitle}
      />
    );
  }

  return (
    <TextInformation
      editMode={editMode}
      editable={editable}
      cell={optionMapping(cell.toString()) ?? ''}
      handleChange={handleChange}
      id={id}
    />
  );
};

export default DropdownInformation;
