import classNames from 'classnames';

export const login = classNames(
  'min-h-screen',
  'flex',
  'flex-col',
  'justify-center'
);

export const header = classNames('mx-auto', 'w-full', 'max-w-md');

export const logo = classNames('w-[170px]', 'mx-auto');

export const title = classNames(
  'text-center',
  'text-2xl',
  'font-bold',
  'text-gray-900'
);

export const loginCard = classNames(
  'mt-[22px]',
  'mx-auto',
  'w-full',
  'max-w-md',
  'bg-white',
  'p-[32px]',
  'rounded-[8px]'
);

export const emailInput = classNames('mb-[22px]');

export const inputLabel = classNames('text-sm', 'font-medium', 'text-gray-900');

export const passwordInput = classNames('mb-[30px]');

export const submitButton = classNames('w-full');

export const errorMessage = classNames(
  'mt-4',
  'text-red-400',
  'text-sm',
  'text-center'
);

export const divider = classNames('relative', 'my-[24px]');

export const dividerLineWrapper = classNames(
  'absolute',
  'inset-0',
  'flex',
  'items-center'
);
export const dividerLine = classNames('w-full', 'border-t', 'border-gray-300');

export const dividerTextWrapper = classNames(
  'relative',
  'flex',
  'justify-center'
);
export const dividerText = classNames(
  'px-2',
  'bg-white',
  'text-sm',
  'text-gray-500'
);
