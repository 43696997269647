import classNames from 'classnames';

export const noDocumentsContainer = classNames(
  'px-[24px]',
  'py-[32px]',
  'border-t',
  'border-gray-200',
  'flex',
  'justify-center'
);

export const noDocuments = classNames('text-sm', 'text-gray-600');

export const deleteModalDescription = classNames('text-sm', 'text-gray-600');

export const claimIcon = classNames('w-12');

export const cardContent = classNames(
  'flex',
  '!flex-row',
  'items-center',
  'justify-between',
  'w-full',
  'text-sm',
  'text-gray-600',
  'hover:text-indigo-500'
);

export const iconAndTextWrapper = classNames(
  'flex',
  'items-center',
  'justify-between',
  'gap-3'
);

export const amountText = classNames('font-bold');
