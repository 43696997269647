import ActivitySection from 'components/ActivitySection/ActivitySection';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import { getProviderPublicHealthStatus } from 'shared/mapProviderPublicHealthStatus';

import DocumentsSection from '../DocumentsSection';

interface SideSectionProps {
  policy: PublicHealthPolicy;
}

const SideSection = ({ policy }: SideSectionProps) => {
  return (
    <>
      <ActivitySection
        activity={policy.activity}
        policyId={policy.id}
        statusMapping={getProviderPublicHealthStatus}
      />
      <DocumentsSection data={policy} />
    </>
  );
};

export default SideSection;
