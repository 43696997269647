import { useTranslation } from '@getpopsure/i18n-react';
import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { FormInputLabel } from 'components/FormInputLabel';
import Input from 'components/Input';
import { Fragment } from 'react';
import {
  Control,
  useFieldArray,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

import FileDropzone from '../../FileDropzone';
import { TaskActionsFormInput } from '../TaskEngine.types';
import {
  additionalUserActionFields,
  userActionsDropdownOptions,
} from '../TaskEngine.utils';

type TaskActionsBuilderProps = {
  control: Control<TaskActionsFormInput>;
  register: UseFormRegister<TaskActionsFormInput>;
  setValue: UseFormSetValue<TaskActionsFormInput>;
  resetField: UseFormResetField<TaskActionsFormInput>;
};

type FileInputProps = {
  name: `actions.${number}.attributes`;
  setValue: UseFormSetValue<TaskActionsFormInput>;
  resetField: UseFormResetField<TaskActionsFormInput>;
  action: TaskActionsFormInput['actions'][number];
};

const FileInput = ({ name, setValue, action, resetField }: FileInputProps) => {
  const handleSelectFile = (file: File | undefined) => {
    setValue(name, { file });
  };

  const handleDeleteFile = () => resetField(name);

  const uploadedFile =
    typeof action.attributes?.file === 'string'
      ? undefined
      : action.attributes?.file;

  return (
    <div className="mt-3">
      <FileDropzone
        uploadedFile={uploadedFile}
        onFileSelect={handleSelectFile}
        onFileDelete={handleDeleteFile}
      />
    </div>
  );
};

const TaskActionsBuilder = ({
  control,
  register,
  setValue,
  resetField,
}: TaskActionsBuilderProps) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'actions',
  });
  const actions = useWatch({
    control,
    name: 'actions',
  });

  return (
    <div
      className={classNames(
        'mt-8',
        'border-t',
        'border-solid',
        'border-gray-300'
      )}
    >
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <div
            className={classNames('mb-2 mt-10 flex justify-between first:mt-8')}
          >
            <FormInputLabel
              id={`actions.${index}`}
              title={t('taskEngine.createTask.modal.type', 'User action')}
            />
            <button
              type="button"
              onClick={() => remove(index)}
              className={classNames(
                'bg-transparent',
                'pl-4',
                'text-gray-500',
                'focus:outline-indigo-500'
              )}
            >
              <XCircleIcon className="h-5 w-5" />
            </button>
          </div>

          <select
            {...register(`actions.${index}.type`)}
            className={classNames(
              'w-full',
              'border',
              'border-r-8',
              'rounded-md',
              'py-2',
              'px-2',
              'text-sm',
              'text-gray-700',
              'bg-gray-200',
              'focus:outline-indigo-500'
            )}
            id={`actions.${index}.type`}
          >
            {userActionsDropdownOptions.map((option) => (
              <option key={option.id} value={option.id}>
                {option.label}
              </option>
            ))}
          </select>
          {actions &&
            actions[index] &&
            additionalUserActionFields[actions[index].type].map(
              (additionalField) => (
                <div className="pt-4" key={additionalField.id}>
                  <FormInputLabel
                    htmlFor={`actions.${index}.attributes.${additionalField.name}`}
                    title={additionalField.label}
                  />
                  {additionalField.type === 'file' ? (
                    <FileInput
                      key={additionalField.id}
                      name={`actions.${index}.attributes`}
                      action={actions[index]}
                      setValue={setValue}
                      resetField={resetField}
                    />
                  ) : (
                    <Input
                      id={`actions.${index}.attributes.${additionalField.name}`}
                      color="gray"
                      className="mt-2"
                      type={additionalField.type}
                      required={additionalField.required}
                      {...register(
                        `actions.${index}.attributes.${additionalField.name}`
                      )}
                    />
                  )}
                </div>
              )
            )}
        </Fragment>
      ))}

      <div className="mt-6">
        <button
          type="button"
          className={classNames(
            'focus:outline-indigo-500',
            'inline-flex',
            'items-center',
            'rounded-md',
            'bg-transparent',
            'py-2',
            'text-sm',
            'text-gray-600',
            'hover:bg-indigo-100'
          )}
          onClick={() => append({ type: 'INPUT' })}
        >
          <PlusCircleIcon className="mr-2 h-5 w-5 text-indigo-500" />
          {t('taskEngine.createTask.modal.addUserAction', 'Add  action')}
        </button>
      </div>
    </div>
  );
};

export default TaskActionsBuilder;
