import { gql } from '@apollo/client';

export const GET_TASK_TEMPLATES = gql`
  query GetTaskTemplates(
    $searchString: String
    $filterTag: String
    $filterInsuranceType: [String]
    $offset: Int
    $limit: Int
    $sortColumn: String
    $sortOrder: String
  ) {
    taskTemplates(
      searchString: $searchString
      filterTag: $filterTag
      filterInsuranceType: $filterInsuranceType
      offset: $offset
      limit: $limit
      sortColumn: $sortColumn
      sortOrder: $sortOrder
    ) {
      id
      name
      tag
      insuranceType
      data
      createdAt
      updatedAt
    }
    taskTemplatesCount(
      searchString: $searchString
      filterTag: $filterTag
      filterInsuranceType: $filterInsuranceType
      sortColumn: $sortColumn
      sortOrder: $sortOrder
    )
  }
`;

export const GET_TASK_TEMPLATE = gql`
  query GetTaskTemplate($id: ID!) {
    taskTemplate(id: $id) {
      id
      name
      tag
      insuranceType
      data
      createdAt
      updatedAt
    }
  }
`;
