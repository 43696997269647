import classNames from 'classnames';
import Badge from 'components/Badge';

import { SelectButtonOption } from '..';
import * as styles from './styles';

interface SelectedOptionProps {
  withDot: boolean;
  selected: SelectButtonOption | SelectButtonOption[] | undefined;
}

const SelectedOption = ({ withDot, selected }: SelectedOptionProps) => {
  const getLabel = (
    selectedOption: SelectButtonOption | SelectButtonOption[] | undefined
  ): string => {
    if (!selectedOption) return '';
    if (Array.isArray(selectedOption)) {
      if (selectedOption.length === 0) return '';
      if (selectedOption.length > 1) {
        return `${selectedOption[0].title}, +${selectedOption.length - 1}`;
      }
      return selectedOption[0].title ?? '';
    }
    return selectedOption.title ?? '';
  };
  const selectedColor = !Array.isArray(selected)
    ? selected?.color
    : selected[0]?.color;

  return (
    <div className={styles.optionTitle}>
      {withDot && selectedColor && (
        <Badge badgeType="dot" color={selectedColor} />
      )}
      <p
        className={classNames({
          [styles.textSelectStatus]: !withDot,
          [styles.textSelectStatusWithDot]: withDot,
          [styles.textSelectAnyStatus]:
            !Array.isArray(selected) && selected?.id === 'any',
        })}
      >
        {getLabel(selected)}
      </p>
    </div>
  );
};

export default SelectedOption;
