import { BadgeColor } from 'components/Badge/Badge';

import { PrivateHealthStatus } from '../types';

export const privateHealthStatusMapper: Record<PrivateHealthStatus, string> = {
  APPLICATION_RECEIVED: 'Received',
  PENDING: 'Missing info',
  APPLICATION_SENT: 'Sent',
  ACTIVE: 'Covered',
  ERR_MISSING_INFO: 'Missing info',
  CANCELED: 'Canceled',
  DROPPED_OUT: 'Dropped out',
  CANT_BE_COVERED: 'Not eligible',
};

export const privateHealthBadgeColorMapper: Record<
  PrivateHealthStatus,
  BadgeColor
> = {
  APPLICATION_RECEIVED: 'purple',
  PENDING: 'red',
  APPLICATION_SENT: 'blue',
  ACTIVE: 'green',
  ERR_MISSING_INFO: 'red',
  CANCELED: 'gray',
  DROPPED_OUT: 'gray',
  CANT_BE_COVERED: 'yellow',
};
