import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import classNames from 'classnames';
import Badge from 'components/Badge';
import { BadgeColor } from 'components/Badge/Badge';
import dayjs from 'dayjs';
import { Country, countryIconMapper } from 'shared/countries/models';

import * as styles from './styles';

interface Props {
  title: string;
  insuredPerson?: string;
  policyNumber?: string;
  startDate?: string;
  price?: number;
  status?: string;
  badgeColor?: BadgeColor;
  icon: string;
  link: string;
  openInNewTab?: boolean;
  smallVersion?: boolean;
  provider?: string;
  regionOfPurchase?: Country;
}

export const PolicyCard = ({
  title,
  insuredPerson,
  policyNumber,
  startDate,
  price,
  status,
  icon,
  link,
  badgeColor,
  regionOfPurchase,
  openInNewTab = false,
  smallVersion = false,
  provider,
}: Props) => {
  const hasStartDateOrProvider = startDate || provider;

  return (
    <a
      className={styles.policyCard}
      href={link}
      target={openInNewTab ? '_blank' : ''}
      rel="noopener noreferrer"
    >
      <div className={styles.leftContainer}>
        <img className={styles.icon} src={icon} alt="insurance icon" />

        <div className={styles.titleAndDateContainer}>
          <div
            className={classNames(styles.titleContainer, {
              [styles.titleContainerSmall]: smallVersion,
            })}
          >
            <h5 className={styles.title}>{title}</h5>
            {insuredPerson && (
              <div
                className={classNames({
                  [styles.insuredPerson]: !smallVersion,
                  [styles.insuredPersonSmall]: smallVersion,
                })}
              >
                {insuredPerson}
              </div>
            )}
            {policyNumber && (
              <div
                className={classNames({
                  [styles.insuredPerson]: !smallVersion,
                  [styles.insuredPersonSmall]: smallVersion,
                })}
              >
                {policyNumber}
              </div>
            )}
          </div>
          {hasStartDateOrProvider && (
            <div className="flex">
              {startDate && (
                <div className={styles.startDate}>
                  {dayjs(startDate).format('DD MMM YYYY')}
                </div>
              )}
              {provider && <div className={styles.provider}>{provider}</div>}
            </div>
          )}
        </div>
      </div>

      <div className={styles.rightContainer}>
        {regionOfPurchase && (
          <div className="mr-4 flex items-center space-x-1">
            <img
              src={countryIconMapper[regionOfPurchase]}
              alt="countryFlag"
              className="w-3 rounded-sm"
            />
            <span className="text-sm text-gray-600">
              {regionOfPurchase?.toUpperCase()}
            </span>
          </div>
        )}
        {price && (
          <div className={styles.price}>
            {englishFormattedEuroCurrency(price)}
          </div>
        )}
        {status && badgeColor && (
          <div className={styles.status}>
            <Badge color={badgeColor} badgeType="full">
              {status}
            </Badge>
          </div>
        )}
      </div>
    </a>
  );
};
