import QuestionnaireCard from 'components/QuestionnaireCard/QuestionnaireCard';
import { DocumentNode } from 'graphql';

interface QuestionnaireTabProps {
  questionnaireId: string;
  answers: object;
  cardTitle: string;
  policyId: string;
  editable: boolean;
  mutation?: DocumentNode;
  editDescription?: string;
  sideSection?: React.ReactNode;
  refetchQueries?: DocumentNode[];
}

const QuestionnaireTab = ({
  sideSection,
  questionnaireId,
  answers,
  cardTitle,
  policyId,
  editable,
  mutation,
  editDescription,
  refetchQueries,
}: QuestionnaireTabProps) => {
  return (
    <>
      <div className="relative flex w-full flex-col">
        <QuestionnaireCard
          questionnaireId={questionnaireId}
          answers={answers ?? {}}
          cardTitle={cardTitle}
          policyId={policyId}
          mutation={mutation}
          editDescription={editDescription}
          editable={editable}
          refetchQueries={refetchQueries}
        />
      </div>
      {questionnaireId || answers ? sideSection : <></>}
    </>
  );
};

export default QuestionnaireTab;
