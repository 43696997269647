import { useTranslation } from '@getpopsure/i18n-react';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { TariffInfo } from 'models/tariffInfo';

interface PriceDetailsProps {
  tariffInfo?: TariffInfo;
  occupation: string;
}

export const PriceDetails = ({ tariffInfo, occupation }: PriceDetailsProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="mt-2 flex-col space-y-4 text-sm text-gray-900">
        <div className="flex items-center justify-between">
          <span>
            {t(
              'admin.hr.privateHealth.overview.priceDtails.modal.monthlyPrice',
              'Tariff monthly price'
            )}
          </span>
          <span>
            {englishFormattedEuroCurrency(tariffInfo?.tariffMonthlyPrice ?? 0)}
          </span>
        </div>
        <div className="flex items-center justify-between">
          <span>
            {t(
              'admin.hr.privateHealth.overview.priceDtails.modal.longTermCare',
              'Long term care insurance'
            )}
          </span>
          <span>
            {englishFormattedEuroCurrency(tariffInfo?.longTermCare ?? 0)}
          </span>
        </div>
        <div className="w-full border-b border-solid border-gray-300" />
        <div className="flex items-center justify-between font-bold">
          <span>
            {t(
              'admin.hr.privateHealth.overview.priceDtails.modal.totalMonthlyPrice',
              'Total monthly price'
            )}
          </span>
          <span>
            {englishFormattedEuroCurrency(tariffInfo?.totalMonthlyPrice ?? 0)}
          </span>
        </div>
        {occupation === 'EMPLOYED' && (
          <>
            <div className="flex items-center justify-between">
              <span>
                {t(
                  'admin.hr.privateHealth.overview.priceDtails.modal.employerContribution',
                  'Employer contribution'
                )}
              </span>
              <span>
                {englishFormattedEuroCurrency(
                  tariffInfo?.employerContributions ?? 0
                )}
              </span>
            </div>
            <div className="flex items-center justify-between">
              <span>
                {t(
                  'admin.hr.privateHealth.overview.priceDtails.modal.individualContribution',
                  'Individual contribution'
                )}
              </span>
              <span>
                {englishFormattedEuroCurrency(
                  tariffInfo?.ownContributions ?? 0
                )}
              </span>
            </div>
          </>
        )}
        <div className="w-full border-b border-solid border-gray-300" />
        <div className="flex items-center justify-between text-gray-600">
          <span>
            {t(
              'admin.hr.privateHealth.overview.priceDtails.modal.taxDeductibleAmount',
              'Tax deductible amount'
            )}
          </span>
          <span>
            {englishFormattedEuroCurrency(tariffInfo?.taxDeductibleAmount ?? 0)}
          </span>
        </div>
      </div>
    </>
  );
};
