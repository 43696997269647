import {
  expatClaimStatuses,
  expatClaimTypes,
} from 'shared/claims/expatHealth/models';
import { z } from 'zod';

const requiredError = 'This field is required.';

export const ZSubmittableExpatClaimSchema = z.object({
  policyId: z.string().min(1, { message: requiredError }),
  status: z.enum(expatClaimStatuses),
  claimType: z.array(z.enum(expatClaimTypes)),
  amount: z.string().min(1, { message: requiredError }),
  iban: z.string().min(1, { message: requiredError }),
  uploadDocument: z
    .custom<File>()
    .refine((file) => file instanceof File, 'Please upload a document'),
});
