import { countries, Country } from '../models';

export const getLocalStorageCountry = (): Country => {
  const country: any = localStorage.getItem('country');
  return country && isValidCountry(country) ? country : 'de';
};

export const isValidCountry = (country: any): boolean => {
  return Boolean(countries.includes(country));
};
