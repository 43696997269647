import { capitalizeName } from '@getpopsure/public-utility';
import Card from 'components/Card';
import Loader from 'components/Loader';
import { PolicyCard } from 'components/PolicyCard';
import { User } from 'models/User';
import { policyMappers } from 'shared/insurances';
import { InsuranceType } from 'shared/insurances/types';

import { PoliciesTitle } from './components/PoliciesTitle';
import { CustomerPolicy } from './models';
import * as styles from './styles';
import { getDisplayNameForPolicy } from './utils';

interface Props {
  customer?: User;
  policies?: CustomerPolicy[];
  loading: boolean;
}

export const CustomerPoliciesView = ({
  customer,
  policies,
  loading,
}: Props) => {
  const hasPolicies = policies && policies.length > 0;
  const policiesCount = policies?.length ?? 0;

  return (
    <div className={styles.container}>
      <Card
        title={<PoliciesTitle title="Policies" count={policiesCount} />}
        boldTitle={false}
      >
        {loading && (
          <div className={styles.noDataContainer}>
            <Loader className={styles.loader} />
          </div>
        )}

        {!hasPolicies && !loading && (
          <div className={styles.noDataContainer}>
            <p className={styles.noResults}>No policies created yet.</p>
          </div>
        )}

        {hasPolicies &&
          policies.map(
            ({
              id,
              insuranceType,
              publicStatus: statusValue,
              price,
              startDate,
              insuredPerson,
              providerId,
              regionOfPurchase,
            }) => {
              const policyMapper =
                policyMappers[insuranceType as InsuranceType];

              if (!policyMapper) {
                return <></>;
              }

              const accountHolderName =
                customer?.firstName && customer.lastName
                  ? capitalizeName({
                      firstName: customer?.firstName,
                      lastName: customer?.lastName,
                    })
                  : null;

              const insuredPersonName =
                insuredPerson?.firstName && insuredPerson.lastName
                  ? capitalizeName({
                      firstName: insuredPerson?.firstName,
                      lastName: insuredPerson?.lastName,
                    })
                  : null;

              const displayNameForPolicy = getDisplayNameForPolicy({
                policyInsuranceType: insuranceType as InsuranceType,
                accountHolderName,
                insuredPersonName,
                policies,
              });

              return (
                <PolicyCard
                  key={id}
                  insuredPerson={displayNameForPolicy ?? ''}
                  status={
                    statusValue
                      ? policyMapper.statusMapper[statusValue]
                      : undefined
                  }
                  badgeColor={
                    statusValue
                      ? policyMapper.badgeColorMapper[statusValue]
                      : undefined
                  }
                  price={price ?? undefined}
                  startDate={startDate ?? undefined}
                  title={policyMapper.name}
                  icon={policyMapper.icon}
                  link={policyMapper.path(id, regionOfPurchase)}
                  openInNewTab={false}
                  provider={providerId ?? undefined}
                  regionOfPurchase={regionOfPurchase}
                />
              );
            }
          )}
      </Card>
    </div>
  );
};
