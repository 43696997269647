import { ApolloError } from '@apollo/client';
import OverviewPage from 'components/OverviewPage';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import Table from 'components/TableRefactor/Table';
import { HeaderConfig, RowConfig } from 'components/TableRefactor/types';
import { GET_ADMIN_USERS } from 'graphql/admin/users/queries';
import { AdminUser } from 'models/AdminUser';
import { useState } from 'react';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';
import {
  AuthenticationType,
  mapAuthenticationType,
} from 'shared/mapAuthenticationType';

import CreateAdminUserButton from './components/CreateAdminUserButton';

const NUMBER_OF_ADMIN_USERS_PER_PAGE = 12;

const AdminUsersPage = () => {
  const [error, setError] = useState<ApolloError | undefined>();

  const headers: HeaderConfig[] = [
    {
      id: 'first-name',
      label: 'First name',
      width: 'w-[200px]',
      minWidth: 'min-w-[200px]',
      sticky: true,
      threshold: 'left-0',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'last-name',
      label: 'Last name',
      width: 'w-[200px]',
      minWidth: 'min-w-[200px]',
      sticky: true,
      threshold: 'left-[200px]',
      border: 'right',
      component: SortableColumnHeader,
    },
    {
      id: 'email',
      label: 'Email',
      width: 'w-none',
      minWidth: 'min-w-[240px]',
      component: ColumnHeader,
    },
    {
      id: 'company',
      label: 'Company',
      width: 'w-[125px]',
      minWidth: 'min-w-[125px]',
      component: ColumnHeader,
    },
    {
      id: 'created-at',
      label: 'Created on',
      width: 'w-[125px]',
      minWidth: 'min-w-[125px]',
      component: SortableColumnHeader,
    },
    {
      id: 'authentication-type',
      label: 'Authentication Type',
      width: 'w-[165px]',
      minWidth: 'min-w-[165px]',
      component: ColumnHeader,
    },
    {
      id: 'active',
      label: 'Active',
      width: 'w-[80px]',
      minWidth: 'min-w-[80px]',
      component: ColumnHeader,
    },
  ];

  const rowConfig: RowConfig = {
    getResourceLink: (data: AdminUser) =>
      generatePath(paths.adminUsers.internal.details.path, { id: data.id }),
    cells: [
      {
        type: 'TEXT',
        textColor: 'dark',
        props: { getText: (data: AdminUser) => data.firstName },
      },
      {
        type: 'TEXT',
        textColor: 'dark',
        props: { getText: (data: AdminUser) => data.lastName },
      },
      {
        type: 'TEXT',
        textColor: 'light',
        props: { getText: (data: AdminUser) => data.email },
      },
      {
        type: 'TEXT',
        textColor: 'light',
        props: {
          getText: (data: AdminUser) =>
            data.isFeather ? 'Feather' : data.company,
        },
      },
      {
        type: 'DATE',
        textColor: 'light',
        props: { getDate: (data: AdminUser) => data.createdAt },
      },
      {
        type: 'TEXT',
        textColor: 'light',
        props: {
          getText: (data: AdminUser) =>
            mapAuthenticationType(
              data.authenticationType as AuthenticationType
            ) ?? '',
        },
      },
      {
        type: 'CHECK',
        textColor: 'light',
        props: {
          getCheck: (data: AdminUser) => ({
            check: data.active ? 'check' : 'cross',
            badgeCaption: 'Active',
          }),
        },
      },
    ],
  };

  return (
    <OverviewPage title="Admin users" current="admin-users" error={error}>
      <Table
        title="Admin users"
        emptyStateLabel={{
          title: 'There are no companies yet.',
          description: 'Looks like no companies were created.',
        }}
        searchPlaceholder="Search by name, company name or email"
        itemsPerPage={NUMBER_OF_ADMIN_USERS_PER_PAGE}
        query={GET_ADMIN_USERS}
        headers={headers}
        rowConfig={rowConfig}
        filterConfig={[]}
        onError={setError}
        actionButton={<CreateAdminUserButton />}
      />
    </OverviewPage>
  );
};

export default AdminUsersPage;
