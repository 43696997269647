import classNames from 'classnames';

export const navbarWrapper = classNames(
  'flex',
  'flex-col',
  'w-full',
  'md:min-w-[240px]',
  'md:w-[240px]',
  'bg-indigo-700',
  'overflow-y-scroll',
  'md:h-full'
);

export const logoWrapper = classNames(
  'flex',
  'items-center',
  'px-4',
  'pt-5',
  'pb-5',
  'md:pb-1',
  'justify-between',
  'sticky',
  'top-0',
  'bg-indigo-700',
  'z-20'
);

export const logo = classNames('w-[120px]', 'h-[40px]');

export const linkSectionWrapper = classNames(
  'mt-[16px]',
  'flex-grow',
  'flex',
  'flex-col'
);

export const linkSection = classNames(
  'flex-1',
  'px-2',
  'space-y-1',
  'bg-indigo-700'
);

export const linkCurrent = classNames('bg-indigo-900', 'text-white');
export const linkNotCurrent = classNames(
  'bg-indigo-700',
  'text-gray-300',
  'hover:bg-indigo-800',
  'hover:text-white',
  'focus:bg-indigo-800',
  'focus:text-gray-300'
);
export const link = classNames(
  'group',
  'w-full',
  'items-center',
  'flex',
  'pl-2',
  'py-2',
  'text-sm',
  'font-bold',
  'rounded-md'
);

export const linkIcon = classNames('mr-3', 'h-6', 'w-6');
export const linkIconCurrent = classNames('text-gray-200');
export const linkIconNotCurrent = classNames(
  'text-gray-400',
  'group-hover:text-gray-300'
);

export const linkGroup = classNames('space-y-1');

export const linkGroupButton = classNames(
  'bg-indigo-700',
  'text-gray-300',
  'hover:bg-indigo-800',
  'hover:text-white',
  'group',
  'w-full',
  'items-center',
  'pl-2',
  'pr-1',
  'py-2',
  'text-left',
  'text-sm',
  'font-bold',
  'rounded-md',
  'focus:outline-none',
  'flex'
);

export const linkGroupIcon = classNames(
  'mr-3',
  'h-6',
  'w-6',
  'text-gray-400',
  'group-hover:text-gray-300'
);

export const linkGroupName = classNames('flex-1');

export const linkGroupArrow = classNames(
  'ml-3',
  'h-5',
  'w-5',
  'transform',
  'group-hover:text-gray-300',
  'transition-colors',
  'ease-in-out',
  'duration-150',
  'text-gray-400'
);

export const linkGroupChildren = classNames('space-y-1');

export const linkGroupChildCurrent = classNames(
  'bg-indigo-900',
  'text-white',
  'hover:text-white',
  'hover:bg-indigo-900'
);

export const linkGroupChild = classNames(
  'group',
  'w-full',
  'flex',
  'items-center',
  'pl-11',
  'pr-2',
  'py-2',
  'text-sm',
  'font-bold',
  'text-gray-300',
  'rounded-md',
  'hover:text-white',
  'hover:bg-indigo-800'
);

export const footerWrapper = classNames(
  'flex',
  'items-center',
  'justify-between',
  'px-4',
  'bg-indigo-700',
  'pt-4',
  'pb-6',
  'w-full'
);

export const userSection = classNames('flex', 'items-center', 'space-x-3');

export const userIcon = classNames('h-6', 'w-6', 'text-gray-400');
export const userName = classNames('text-gray-400', 'text-sm');

export const logoutIcon = classNames('h-6', 'w-6', 'text-gray-400');
export const menuIcon = classNames('h-6', 'w-6', 'text-gray-400');
