import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import {
  DentalPlan,
  dentalPlanMapper,
} from 'shared/insurances/planMappers/dental';
import { dentalStatusMapper } from 'shared/insurances/statusMappers/dental';
import { DentalStatus } from 'shared/insurances/types';

const statusFilterOptions: SelectButtonOption<DentalStatus>[] = [
  {
    id: 'PENDING',
    label: dentalStatusMapper.PENDING,
    title: dentalStatusMapper.PENDING,
  },
  {
    id: 'ACTIVE',
    label: dentalStatusMapper.ACTIVE,
    title: dentalStatusMapper.ACTIVE,
  },
  {
    id: 'CANCELED',
    label: dentalStatusMapper.CANCELED,
    title: dentalStatusMapper.CANCELED,
  },
];

const planFilterOptions: SelectButtonOption<DentalPlan>[] = [
  {
    id: 'BASIC',
    label: dentalPlanMapper.BASIC,
    title: dentalPlanMapper.BASIC,
  },
  {
    id: 'ADVANCED',
    label: dentalPlanMapper.ADVANCED,
    title: dentalPlanMapper.ADVANCED,
  },
];

export const tableFilters: FilterConfig[] = [
  {
    options: statusFilterOptions,
    filterType: 'MULTIPLE',
    label: 'Status',
    id: 'filterStatus',
    placeholder: 'Select status(es)',
  },
  {
    options: planFilterOptions,
    filterType: 'MULTIPLE',
    label: 'Plan',
    id: 'filterPlan',
    placeholder: 'Select plan',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Start date',
    id: 'filterStartDate',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Created on',
    id: 'filterCreatedAt',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Archived on',
    id: 'filterArchivedAt',
  },
];
