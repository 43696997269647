import { CheckIcon, XIcon } from '@heroicons/react/outline';
import Toggle from 'components/Toggle/Toggle';

import { TextInformationProps } from '../TextInformation';
import TextWithDetailsInformation from '../TextWithDetailsInformation';
import * as styles from './styles';

const ChecklistInformation = ({
  cell,
  id,
  className,
  editable,
  editMode,
  checklist,
  handleArrayChange,
  renderModalDetails,
  detailsLabel,
  detailsTitle,
}: TextInformationProps) => {
  if (!checklist) return <></>;

  const handleToggleChange = (enabled: boolean, toggleId: string) => {
    if (enabled && Array.isArray(cell)) {
      const newAddons = [...cell, toggleId];
      handleArrayChange?.(newAddons, id);
    }

    if (!enabled && Array.isArray(cell)) {
      const newAddons = cell.filter((addon) => addon !== toggleId);
      handleArrayChange?.(newAddons, id);
    }
  };

  return (
    <div className="flex w-full items-start justify-between">
      <div className={styles.checklistWrapper}>
        {checklist.map((check) => (
          <div key={check.title} className={styles.checklistInfoWrapper}>
            {editable && editMode ? (
              <Toggle
                enabled={
                  Array.isArray(cell) ? cell.includes(check.id ?? '') : false
                }
                setEnabled={(enabled) =>
                  handleToggleChange(enabled, check.id ?? '')
                }
              />
            ) : check.check ? (
              <CheckIcon className={styles.checkIcon} />
            ) : (
              <XIcon className={styles.crossIcon} />
            )}
            <span
              className={check.check ? styles.titleCheck : styles.titleCross}
            >
              {check.title}
            </span>
          </div>
        ))}
      </div>
      {renderModalDetails && !editMode && (
        <TextWithDetailsInformation
          editMode={editMode}
          editable={editable}
          cell=""
          id={id}
          renderModalDetails={renderModalDetails}
          detailsLabel={detailsLabel}
          detailsTitle={detailsTitle}
        />
      )}
    </div>
  );
};

export default ChecklistInformation;
