import { Switch } from '@headlessui/react';
import classNames from 'classnames';

import * as styles from './styles';

interface ToggleProps {
  enabled: boolean;
  setEnabled: (enabled: true) => void;
}

const Toggle = ({ enabled, setEnabled }: ToggleProps) => {
  return (
    <Switch
      checked={enabled}
      onChange={setEnabled}
      className={styles.toggleWrapper}
    >
      <span className="sr-only">Use setting</span>
      <span aria-hidden="true" className={styles.toggle} />
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'bg-indigo-500' : 'bg-gray-400',
          styles.toggleBody
        )}
      />
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          styles.circle
        )}
      />
    </Switch>
  );
};

export default Toggle;
