import { ApolloError } from '@apollo/client';
import OverviewPage from 'components/OverviewPage';
import { SelectButtonOption } from 'components/SelectButton';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import Table from 'components/TableRefactor/Table';
import { EmptyStateMessage } from 'components/TableRefactor/TableEmptyState';
import {
  HeaderConfig,
  RowConfig,
  ViewConfig,
} from 'components/TableRefactor/types';
import { GET_PUBLIC_POLICIES } from 'graphql/publicHealth/internal/queries';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import { useState } from 'react';
import { generatePath } from 'react-router-dom';
import { paths } from 'routes/definedPaths';
import { getInternalPublicHealthStatus } from 'shared/mapInternalPublicHealthStatus';
import { mapUserOccupation } from 'shared/mapUserOccupation';

const NUMBER_OF_POLICIES_PER_PAGE = 12;

interface Data {
  publicHealthPolicies: PublicHealthPolicy[];
  totalPublicHealthPolicies: {
    totalPolicies: number;
    numberOfActivityDot: number;
  };
}

const filterStatusOptions: SelectButtonOption[] = [
  {
    title: 'Uni check',
    label: 'Uni check',
    id: 'unicheck',
  },
  {
    title: 'Received',
    label: 'Received',
    id: 'received',
  },
  {
    title: 'Sent',
    label: 'Sent',
    id: 'sent',
  },
  {
    title: 'Covered',
    label: 'Covered',
    id: 'covered',
  },
  {
    title: 'Canceled',
    label: 'Canceled',
    id: 'canceled',
  },
  {
    title: 'Processing',
    label: 'Processing',
    id: 'processing',
  },
  {
    title: 'Missing info',
    label: 'Missing info',
    id: 'missinginfo',
  },
  {
    title: 'Not eligible',
    label: 'Not eligible',
    id: 'noteligible',
  },
];

const filterProviderOptions: SelectButtonOption[] = [
  {
    title: 'AOK',
    label: 'AOK',
    id: 'aok',
  },
  {
    title: 'Barmer',
    label: 'Barmer',
    id: 'barmer',
  },
  {
    title: 'DAK',
    label: 'DAK',
    id: 'dak',
  },
  {
    title: 'TK',
    label: 'TK',
    id: 'tk',
  },
  {
    title: 'BKK',
    label: 'BKK',
    id: 'bkk_firmus',
  },
];

const filterOccupationOptions: SelectButtonOption[] = [
  {
    title: 'Employed',
    label: 'Employed',
    id: 'employed',
  },
  {
    title: 'Self-employed',
    label: 'Self-employed',
    id: 'self',
  },
  {
    title: 'Student',
    label: 'Student',
    id: 'student',
  },
  {
    title: 'Other',
    label: 'Other',
    id: 'other',
  },
];

const filterViewOptions = [
  {
    id: 'all',
    label: 'All policies',
    title: 'All policies',
    show: true,
  },
  {
    id: 'action-pending',
    label: 'Action needed',
    title: 'Action needed',
    show: true,
  },
];

const InternalPublicHealthPage = () => {
  const [error, setError] = useState<ApolloError | undefined>();
  const tableFilters: FilterConfig[] = [
    {
      options: filterStatusOptions,
      filterType: 'MULTIPLE',
      label: 'Status',
      id: 'filterStatus',
      placeholder: 'Select status(es)',
    },
    {
      options: filterProviderOptions,
      filterType: 'MULTIPLE',
      label: 'Provider',
      id: 'filterProvider',
      placeholder: 'Select provider(s)',
    },
    {
      options: filterOccupationOptions,
      filterType: 'MULTIPLE',
      label: 'Occupation',
      id: 'filterOccupation',
      placeholder: 'Select occupation(s)',
    },
    {
      filterType: 'DATE_RANGE',
      label: 'Created on',
      id: 'createdAt',
    },
    {
      filterType: 'DATE_RANGE',
      label: 'Birth date',
      id: 'birthDate',
    },
  ];

  const tableHeaders: HeaderConfig[] = [
    {
      id: 'activityDot',
      label: '',
      width: 'w-[40px]',
      minWidth: 'min-w-[40px]',
      sticky: true,
      threshold: 'left-0',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'first-name',
      label: 'First name',
      width: 'w-none',
      minWidth: 'min-w-[118px]',
      sticky: true,
      threshold: 'left-[40px]',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'last-name',
      label: 'Last name',
      width: 'w-none',
      minWidth: 'min-w-[118px]',
      sticky: true,
      threshold: 'left-[158px]',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'status',
      label: 'Status',
      width: 'w-[120px]',
      minWidth: 'min-w-[120px]',
      sticky: true,
      threshold: 'left-[276px]',
      border: 'right',
      component: SortableColumnHeader,
    },
    {
      id: 'provider',
      label: 'Provider',
      width: 'w-[116px]',
      minWidth: 'min-w-[116px]',
      component: SortableColumnHeader,
    },
    {
      id: 'occupation',
      label: 'Occupation',
      width: 'w-[140px]',
      minWidth: 'min-w-[140px]',
      component: ColumnHeader,
    },
    {
      id: 'submitted-date',
      label: 'Created on',
      width: 'w-[120px]',
      minWidth: 'min-w-[120px]',
      component: SortableColumnHeader,
    },
    {
      id: 'start-date',
      label: 'Start date',
      width: 'w-[115px]',
      minWidth: 'min-w-[115px]',
      component: SortableColumnHeader,
    },
    {
      id: 'sent',
      label: 'Sent',
      width: 'w-[56px]',
      minWidth: 'min-w-[56px]',
      component: ColumnHeader,
    },
    {
      id: 'source',
      label: 'Source',
      width: 'w-[148px]',
      minWidth: 'min-w-[148px]',
      component: ColumnHeader,
    },
  ];

  const defaultEmptyStateMessage: EmptyStateMessage = {
    title: 'There are no policies yet.',
    description: 'It looks like there are no public health policies yet.',
  };

  const viewConfig: ViewConfig = {
    id: 'filterView',
    options: filterViewOptions.map((option) =>
      option.id === 'action-pending'
        ? {
            ...option,
            displayDot: (data: Data) =>
              !!data?.totalPublicHealthPolicies.numberOfActivityDot,
          }
        : option
    ),
    getGeneralActivityDot: (data: Data) =>
      !!data?.totalPublicHealthPolicies.numberOfActivityDot,
  };

  const rowConfig: RowConfig = {
    getResourceLink: (data: PublicHealthPolicy) =>
      generatePath(paths.policies.publicHealth.internal.details.path, {
        id: data.id,
      }),
    cells: [
      {
        type: 'DOT',
        props: {
          getDot: (data: PublicHealthPolicy) => ({
            active: data.hasInternalActivityDot
              ? data.hasInternalActivityDot
              : data.hasCustomerContactedDot,
            badgeColor: data.hasInternalActivityDot ? 'yellow' : 'blue',
            badgeCaption: data.hasInternalActivityDot
              ? 'Action needed'
              : 'Customer contacted',
          }),
        },
        textColor: 'dark',
      },
      {
        type: 'TEXT',
        props: {
          getText: (data: PublicHealthPolicy) =>
            data.insuredPerson.firstName ?? data.user.firstName ?? '',
        },
        textColor: 'dark',
      },
      {
        type: 'TEXT',
        props: {
          getText: (data: PublicHealthPolicy) =>
            data.insuredPerson.lastName ?? data.user.lastName ?? '',
        },
        textColor: 'dark',
      },
      {
        type: 'STATUS',
        props: {
          getStatus: (data: PublicHealthPolicy) => {
            return data.archivedAt
              ? {
                  text: {
                    id: 'ARCHIVED',
                    text: 'Archived',
                  },
                  color: 'gray',
                }
              : getInternalPublicHealthStatus(data.publicStatus);
          },
        },
        textColor: 'light',
      },
      {
        type: 'TEXT',
        props: {
          getText: (data: PublicHealthPolicy) => data.providerId,
        },
        textColor: 'light',
      },
      {
        type: 'TEXT_WITH_CHECK',
        textColor: 'light',
        props: {
          getTextWithCheck: (data: PublicHealthPolicy) => {
            const occupationLabel = mapUserOccupation(
              data.occupation,
              data.salary,
              data.otherSituation
            );
            return {
              label: occupationLabel,
              check:
                data.occupation === 'UNIVERSITY_STUDENT'
                  ? data.studentChecklist?.completed
                    ? 'check'
                    : 'cross'
                  : 'empty',
            };
          },
        },
      },
      {
        type: 'DATE',
        props: {
          getDate: (data: PublicHealthPolicy) => data.createdAt,
        },
        textColor: 'light',
      },
      {
        type: 'DATE',
        props: {
          getDate: (data: PublicHealthPolicy) => data.startDate ?? '',
        },
        textColor: 'light',
      },
      {
        type: 'CHECK',
        props: {
          getCheck: (data: PublicHealthPolicy) => ({
            check: data.sentToProvider ? 'check' : 'cross',
          }),
        },
        textColor: 'light',
      },
      {
        type: 'TEXT',
        props: {
          getText: (data: PublicHealthPolicy) => data.source,
        },
        textColor: 'light',
      },
    ],
  };

  return (
    <OverviewPage
      title="Public health policies"
      current="policies-public"
      error={error}
    >
      <Table
        title="Public health policies"
        itemsPerPage={NUMBER_OF_POLICIES_PER_PAGE}
        searchPlaceholder="Search by name, email, TK API ID, policy id or utm source"
        emptyStateLabel={defaultEmptyStateMessage}
        filterConfig={tableFilters}
        headers={tableHeaders}
        query={GET_PUBLIC_POLICIES}
        rowConfig={rowConfig}
        onError={setError}
        viewConfig={viewConfig}
        countPath={['totalPublicHealthPolicies', 'totalPolicies']}
      />
    </OverviewPage>
  );
};

export default InternalPublicHealthPage;
