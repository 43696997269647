import { BadgeColor } from 'components/Badge/Badge';

import { DentalClaimStatus } from './models';

export const badgeColorMapper: Record<DentalClaimStatus, BadgeColor> = {
  SUBMITTED_TO_PROVIDER: 'blue',
  RECEIVED: 'purple',
  INCOMPLETE: 'red',
  MISSING_INFO_RECEIVED: 'purple',
  FEATHER_PAID_OUT: 'gray',
  CUSTOMER_PAID_OUT: 'green',
  APPROVED: 'green',
  DENIED: 'yellow',
  DUPLICATE: 'gray',
  DROPPED_OUT: 'gray',
  CLOSED: 'gray',
  CLOSED_WITHOUT_PAYMENT: 'gray',
  OTHER_PAID_OUT: 'gray',
};
