import { gql } from '@apollo/client';

export const GET_EXPAT_SPAIN_HEALTH_CLAIM = gql`
  query GetExpatSpainHealthClaim($claimId: String!) {
    expatSpainHealthClaim(claimId: $claimId) {
      id
      user {
        id
        firstName
        lastName
        email
        isDelinquent
        isFraudulent
        fraudulentAdditionalInfo
      }
      intercomEuUserId
      stripeCustomerToken
      status
      createdAt
      amount
      iban
      refundAmount
      featherPaidOn
      customerPaidOn
      thirdPartyPaidOn
      notificationSentToProviderAt
      claimNumber
      eventDescription
      claimType {
        id
        type
      }
      policy {
        id
        publicStatus
        startDate
        activeUntil
        insuredPerson {
          firstName
          lastName
        }
      }
      documents {
        userUploads {
          id
          url
          blobName
          resizedUrl
        }
        scannedDocuments {
          id
          url
          blobName
        }
        featherUploads {
          id
          url
          blobName
          resizedUrl
          isVisibleToCustomer
          internalNotes
          friendlyName
          category
        }
        providerUploads {
          id
          url
          blobName
          resizedUrl
          isVisibleToCustomer
          internalNotes
          friendlyName
          category
        }
        archivedDocuments {
          id
          url
          blobName
          resizedUrl
          isVisibleToCustomer
          archivedAt
          internalNotes
          friendlyName
          category
        }
      }

      issues {
        id
        category
        description
        status
        title
        createdAt
        updatedAt
        author {
          firstName
          lastName
        }

        tasks {
          id
          description
          status
          data
          createdAt
          finishedAt
        }
      }

      pinnedNotes {
        id
        body
        createdAt
        adminUser {
          firstName
          lastName
        }
        updatedAt
      }
    }
  }
`;
