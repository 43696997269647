import { CheckIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import Badge from 'components/Badge';

import { SelectButtonOption } from '..';
import * as styles from './styles';

interface OptionsListProps {
  withDot: boolean;
  selected: boolean;
  active: boolean;
  option: SelectButtonOption;
}

const OptionsList = ({
  withDot,
  selected,
  active,
  option,
}: OptionsListProps) => {
  return (
    <div className={styles.listOptionContentWrapper}>
      <div className={styles.listOptionContent}>
        <div className={styles.optionTitle}>
          {withDot && <Badge badgeType="dot" color={option.color ?? 'gray'} />}
          <p
            className={classNames({
              [styles.listOptionText]: !selected && !withDot,
              [styles.listOptionTextWithDot]: !selected && withDot,
              [styles.listOptionTextSelected]: selected && !withDot,
              [styles.listOptionTextSelectedWithDot]: selected && withDot,
              [styles.listOptionTextHover]: active,
            })}
          >
            {option.label}
          </p>
        </div>
        <span
          className={classNames({
            [styles.listOptionTextHover]: active,
            [styles.listOptionTextSelected]: !active && !withDot,
            [styles.listOptionIconSelected]: !active && withDot,
            [styles.hidden]: !selected,
          })}
        >
          <CheckIcon className={styles.icon} aria-hidden="true" />
        </span>
      </div>
    </div>
  );
};

export default OptionsList;
