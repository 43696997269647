import { capitalizeName } from '@getpopsure/public-utility';

import { GenericPolicyData } from '../models/GenericPolicyData';

export const getInsuredPersonName = (data?: GenericPolicyData) => {
  return (
    capitalizeName({
      firstName:
        data?.genericPolicy.insuredPerson?.firstName ??
        data?.genericPolicy.user?.firstName ??
        '',
      lastName:
        data?.genericPolicy.insuredPerson?.lastName ??
        data?.genericPolicy.user?.lastName ??
        '',
    }) ?? ''
  );
};
