import { useTranslation } from '@getpopsure/i18n-react';
import EditableInformationCard from 'components/InformationCard/EditableInformationCard';
import Loader from 'components/Loader';
import { User } from 'models/User';
import { UPDATE_CUSTOMER } from 'pages/customers/pages/CustomerDetails/components/OverviewTab/components/CustomerInfo/graphql/mutations';
import { GET_CUSTOMER } from 'pages/customers/pages/CustomerDetails/graphql/queries';

import { retrieveCustomerRows } from './data';
import * as styles from './styles';

interface Props {
  customer?: User;
  loading: boolean;
}

export const CustomerInfoView = ({ customer, loading }: Props) => {
  const { t } = useTranslation();

  const editableData = {
    firstName: customer?.firstName ?? undefined,
    lastName: customer?.lastName ?? undefined,
    email: customer?.email ?? undefined,
    dateOfBirth: customer?.dateOfBirth ?? undefined,
    gender: customer?.gender ?? undefined,
    language: customer?.language ?? undefined,
    intercomEUId: customer?.intercomEUId ?? undefined,
    stripeToken: customer?.stripeToken ?? undefined,
    isDelinquent: customer?.isDelinquent ? 'Yes' : 'No',
    isFraudulent: customer?.isFraudulent ? 'Yes' : 'No',
    fraudulentAdditionalInfo: customer?.fraudulentAdditionalInfo ?? undefined,
  };

  return (
    <div className={styles.container}>
      {loading && (
        <div className={styles.noDataContainer}>
          <Loader className={styles.loader} />
        </div>
      )}
      {customer && (
        <EditableInformationCard
          sections={retrieveCustomerRows(t, customer)}
          editableData={editableData}
          mutation={UPDATE_CUSTOMER}
          resourceId={customer.id}
          refetchQueries={[GET_CUSTOMER]}
          successMessage="You have successfully saved the changes"
        />
      )}
    </div>
  );
};
