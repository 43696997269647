import 'dayjs/locale/de';
import 'i18n//relativeTime';

import { useFlagsStatus } from '@unleash/proxy-client-react';
import AppSwitch from 'AppSwitch';
import { AlertBanners } from 'components/AlertBanners';
import { TimedLoader } from 'components/TimedLoader';
import dayjs from 'dayjs';
import useStateWithLocalStorage from 'hooks/useStateWithLocalStorage';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Router } from 'react-router-dom';
import history from 'shared/browserHistory';

dayjs.locale('en');

const App = () => {
  const { flagsReady } = useFlagsStatus();

  const { setValue } = useStateWithLocalStorage('csrfToken');
  const [token, setToken] = useState('');

  useEffect(() => {
    const fetchToken = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/csrf`,
        {
          method: 'GET',
        }
      );
      response.json().then((data) => {
        setValue(data.csrfToken);
        setToken(data.csrfToken);
      });
    };
    fetchToken();
  }, []);

  if (!flagsReady) {
    return (
      <>
        <Helmet>
          <title>Feather Admin Panel</title>
          <link
            rel="shortcut icon"
            type="image/x-icon"
            href="/static/favicon.ico"
          />
        </Helmet>
        <TimedLoader />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Feather Admin Panel</title>
        <link
          rel="shortcut icon"
          type="image/x-icon"
          href="/static/favicon.ico"
        />
      </Helmet>
      <AlertBanners />
      {token && (
        <Router history={history}>
          <AppSwitch />
        </Router>
      )}
    </>
  );
};

export default App;
