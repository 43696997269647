import { useTranslation } from '@getpopsure/i18n-react';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';
import { z } from 'zod';

import type { DataPreviewProps } from '../TaskEngine.types';
import * as styles from './TaskPreviewModal.styles';

export const DatePreview = ({ data, actionId }: DataPreviewProps) => {
  const { t } = useTranslation();

  const DataWithValidDateSchema = z
    .object({ [actionId]: z.coerce.date() })
    .transform((object) => {
      return object[actionId];
    });

  const date = DataWithValidDateSchema.safeParse(data);
  const label = t('taskEngine.taskPreviewModal.datePreview.label', 'Date');

  if (!date.success) {
    Sentry.captureMessage(
      `[TASK_ENGINE]: customer provided an invalid date. Error: ${date.error.message.toString()}`,
      'warning'
    );

    return (
      <div className={styles.row}>
        <p className={styles.rowText}>{label}</p>
        <p className={styles.invalidAnswer}>
          {t(
            'taskEngine.taskPreviewModal.datePreview.error',
            'The customer provided an invalid date'
          )}
        </p>
      </div>
    );
  }

  return (
    <div className={styles.row}>
      <p className={styles.rowText}>{label}</p>
      <p className={styles.rowText}>{dayjs(date.data).format(DATE_FORMAT)}</p>
    </div>
  );
};
