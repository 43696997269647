import { gql } from '@apollo/client';

export const UPDATE_EXPAT_STATUS = gql`
  mutation UpdateExpatStatus(
    $status: String!
    $id: String!
    $policyNumber: String
    $newFilename: String!
    $file: Upload
    $startDate: String
    $activeUntil: String
    $reinstateDate: String
    $price: Float
  ) {
    updateExpatHealthPolicyStatus(
      status: $status
      id: $id
      policyNumber: $policyNumber
      startDate: $startDate
      activeUntil: $activeUntil
      reinstateDate: $reinstateDate
      price: $price
    ) {
      id
      publicStatus
      startDate
      policyNumber
      activeUntilDate
    }
    uploadExpatHealthPolicyFile(
      newFilename: $newFilename
      policyId: $id
      file: $file
    ) {
      id
      url
      name
    }
  }
`;
