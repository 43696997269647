import { useTranslation } from '@getpopsure/i18n-react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import Button from 'components/Button';
import { Fragment } from 'react';

import * as styles from './styles';

interface ModalProps {
  title: string;
  children: JSX.Element;
  open: boolean;
  setOpen: (open: boolean) => void;
  handleConfirm?: () => void;
  confirmButtonLabel?: string;
  loading?: boolean;
  textLoading?: string;
  cancelLabel?: string;
  cancelOnClick?: () => void;
  scrollable?: boolean;
  disabled?: boolean;
  hideActions?: boolean;
  wide?: boolean;
}

// TODO: [KONG] Refactor Modal to use form element for submission
const Modal = ({
  title,
  children,
  open,
  setOpen,
  handleConfirm,
  confirmButtonLabel,
  loading = false,
  textLoading,
  cancelLabel,
  cancelOnClick,
  disabled,
  scrollable = false,
  hideActions = false,
  wide = false,
}: ModalProps) => {
  const { t } = useTranslation();
  return (
    <Transition.Root show={open} appear={true} as={Fragment}>
      <Dialog as="div" className={styles.modalWrapper} onClose={setOpen}>
        <div className={styles.modal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className={styles.modalOverlay} />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(styles.modalCard, {
                [styles.overflowScroll]: scrollable,
                [styles.overflowVisible]: !scrollable,
                [styles.wide]: wide,
              })}
            >
              <div className={styles.modalContent}>
                <div className={styles.modalHeader}>
                  <Dialog.Title as="h3" className={styles.title}>
                    {title}
                  </Dialog.Title>
                  <button
                    type="button"
                    className={styles.xButton}
                    onClick={() => setOpen(false)}
                  >
                    <XIcon className={styles.xIcon} />
                  </button>
                </div>
                <div>{children}</div>
              </div>
              {!hideActions && (
                <div className={styles.modalFooter}>
                  <Button
                    type="button"
                    buttonType="secondary"
                    className={styles.cancelButton}
                    onClick={cancelOnClick || (() => setOpen(false))}
                  >
                    {cancelLabel || t('admin.modal.cancel.action', 'Cancel')}
                  </Button>
                  <Button
                    type="button"
                    buttonType="primary"
                    onClick={handleConfirm}
                    loading={loading}
                    loadingText={textLoading}
                    className="min-w-[140px]"
                    disabled={disabled}
                    data-testid="modal-cta"
                  >
                    {confirmButtonLabel ??
                      t('admin.modal.confirm.action', 'Confirm')}
                  </Button>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
