import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import * as styles from './styles';

interface CardSectionProps {
  title?: string;
  children: React.ReactNode;
  className?: string;
  defaultOpen?: boolean;
}

const CardSectionCollapsible = ({
  title,
  children,
  className,
  defaultOpen = true,
}: CardSectionProps) => {
  return (
    <Disclosure
      as="div"
      key={title}
      className={classNames(className, styles.section)}
      defaultOpen={defaultOpen}
    >
      {({ open }) => (
        <>
          <Disclosure.Button className="mb-[8px] flex w-full items-center justify-between text-gray-600">
            {title && <h1 className={styles.sectionTitle}>{title}</h1>}
            <ChevronUpIcon
              className={classNames('h-[20px]', {
                'rotate-90': !open,
              })}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="w-full">{children}</Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default CardSectionCollapsible;
