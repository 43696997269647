import { GET_DOG_LIABILITY_POLICIES } from 'pages/policies/dogLiability/graphql/policies';
import {
  DogLiabilityClaimStatus,
  DogLiabilityClaimType,
} from 'shared/claims/dogLiability/models';
import { InsuranceType } from 'shared/insurances/types';

import { ClaimsTemplateData } from '../template/models';
import {
  retrieveAdditionalClaimInfoRows,
  retrieveClaimInfoRows,
} from './modules/claimsDetails/claimInfo/data';
import { UPDATE_DOG_LIABILITY_CLAIM } from './modules/claimsDetails/graphql/mutations';
import { GET_DOG_LIABILITY_CLAIM } from './modules/claimsDetails/graphql/queries';
import { retrievePolicyInfoRows } from './modules/claimsDetails/policyInfo/data';
import { retrieveCreateClaimFormData } from './modules/createClaim/data';
import { CREATE_DOG_LIABILITY_CLAIM } from './modules/createClaim/graphql/mutations';
import { zodSubmittableClaimSchema } from './modules/createClaim/models';
import { filters } from './modules/overview/data/filters';
import { rowConfig } from './modules/overview/data/tableData';
import { tableHeaders } from './modules/overview/data/tableHeaders';
import { GET_DOG_LIABILITY_CLAIMS } from './modules/overview/graphql/queries';
import {
  claimStatusOptions,
  retrieveFormAndFooterTextByStatus,
} from './modules/statusUpdate/data';
import { UPDATE_DOG_LIABILITY_CLAIM_STATUS } from './modules/statusUpdate/graphql/mutations';

export const dogLiabilityClaimsData = (
  insuranceType: InsuranceType
): ClaimsTemplateData<DogLiabilityClaimStatus, DogLiabilityClaimType> => ({
  insuranceType,

  // GraphQL -----------------------------------------
  queries: {
    fetchAllClaims: GET_DOG_LIABILITY_CLAIMS,
    fetchSingleClaim: GET_DOG_LIABILITY_CLAIM,
    fetchAllPolicies: GET_DOG_LIABILITY_POLICIES,
  },

  mutations: {
    createClaim: CREATE_DOG_LIABILITY_CLAIM,
    updateClaim: UPDATE_DOG_LIABILITY_CLAIM,
    updateClaimStatus: UPDATE_DOG_LIABILITY_CLAIM_STATUS,
  },

  // Features ----------------------------------------
  overview: {
    headers: tableHeaders,
    filterConfig: filters,
    rowConfig,
  },
  claimCreation: {
    createClaimSubmissionSchema: zodSubmittableClaimSchema,
    retrieveFormData: retrieveCreateClaimFormData,
  },
  policyInfo: { retrievePolicyInfoRows },
  claimInfo: {
    retrieveClaimInfoRows,
    retrieveAdditionalClaimInfoRows,
  },
  statusUpdate: {
    dataByStatus: retrieveFormAndFooterTextByStatus,
    statusOptions: claimStatusOptions(insuranceType),
  },
});
