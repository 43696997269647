import CheckBadge from 'components/CheckBadge';

import TextInformation, { TextInformationProps } from '../TextInformation';

const TextWithCheckInformation = ({
  cell,
  id,
  check,
}: TextInformationProps) => {
  const textWithBadge = (
    <span className="flex items-start space-x-1">
      <p className="mt-[1px]">
        <CheckBadge checkType={check ?? 'check'} />
      </p>
      <p className="max-w-[250px]">{String(cell)}</p>
    </span>
  );

  return <TextInformation cell={textWithBadge} id={id} />;
};

export default TextWithCheckInformation;
