import { gql } from '@apollo/client';

export const UPDATE_SD_CATEGORY = gql`
  mutation updateSDCategory(
    $id: String!
    $name: String
    $whitelist: JSON
    $blacklist: JSON
    $senderName: String
    $requiredActionCaption: String
    $requiredActionColor: String
    $customerIoEvent: String
    $specialFlags: String
  ) {
    updateSDCategory(
      sdCategoryId: $id
      name: $name
      whitelist: $whitelist
      blacklist: $blacklist
      senderName: $senderName
      requiredActionCaption: $requiredActionCaption
      requiredActionColor: $requiredActionColor
      customerIoEvent: $customerIoEvent
      specialFlags: $specialFlags
    )
  }
`;

export const CREATE_SD_CATEGORY = gql`
  mutation createSDCategory {
    createSDCategory {
      id
    }
  }
`;

export const DELETE_SD_CATEGORY = gql`
  mutation deleteSDCategory($sdCategoryId: String!) {
    deleteSDCategory(sdCategoryId: $sdCategoryId) {
      id
    }
    updateCategoryRelations(selectionType: "active")
  }
`;
