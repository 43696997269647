import { ExternalLinkIcon } from '@heroicons/react/solid';
import Badge from 'components/Badge';
import Link from 'components/Link';
import { Status } from 'models/statusColor';

interface TableCellStatusProps {
  href?: string | ((data: any) => string | undefined);
  data: unknown;
  getStatus: (data: any) => Status | undefined;
}

export const TableCellStatus = ({
  getStatus,
  data,
  href,
}: TableCellStatusProps) => {
  const cellData = getStatus(data);

  const hrefStr = href && typeof href === 'function' ? href(data) : href;

  return (
    <div className="flex gap-1">
      {cellData?.text.text && (
        <Badge color={cellData.color} badgeType="full">
          {cellData.text.text}
        </Badge>
      )}
      {hrefStr && (
        <Link
          color="primary"
          text=""
          IconComponent={ExternalLinkIcon}
          href={hrefStr}
          iconLeft={false}
          className="space-x-1"
          target="_blank"
        />
      )}
    </div>
  );
};
