import { ExpatSpainClaimType } from './models';

export const claimTypeNameMapper: Record<ExpatSpainClaimType, string> = {
  MEDICATION: 'Medication',
  DOCTORS_VISIT: 'General doctor visit',
  EMERGENCY_ROOM: 'Emergency room',
  HOSPITAL_OVERNIGHT: 'Hospital overnight',
  NON_MEDICAL_PROFESSIONAL: 'Non medical professional',
  AMBULANCE: 'Ambulance',
  HOSPITAL_TREATMENT: 'Hospital treatment',
  PHYSICAL_THERAPY: 'Physical therapy',
  DENTAL: 'Dental',
  COST_PLAN: 'Cost plan',
  OTHER: 'Other',
};
