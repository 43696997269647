import { OperationVariables, useMutation } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import CreateIssueButton from 'components/CreateIssueButton';
import { SelectMenuOption } from 'components/SelectMenu';
import { CREATE_ISSUE } from 'graphql/publicHealth/provider/mutations';
import { GET_PUBLIC_PROVIDER_POLICY } from 'graphql/publicHealth/provider/queries';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import { mapProviderPublicHealthStatus } from 'shared/mapProviderPublicHealthStatus';

import ChangeStatus from '../changePolicyStatus/ChangeStatus';
import * as styles from './styles';

interface PolicyActionsProps {
  policy?: PublicHealthPolicy;
}

const PolicyActions = ({ policy }: PolicyActionsProps) => {
  const { t } = useTranslation();
  const status = mapProviderPublicHealthStatus(policy?.publicStatus ?? '');

  const [createIssue, { loading, error, data }] = useMutation(CREATE_ISSUE, {
    refetchQueries: [GET_PUBLIC_PROVIDER_POLICY],
  });

  const handleCreateIssue = (variables: OperationVariables) => {
    return createIssue({ variables: { ...variables } });
  };

  const issueTypeOptions: SelectMenuOption[] = [
    {
      id: 'missinginfo',
      label: t('admin.issue.type.missinginfo', 'Missing info'),
    },
    {
      id: 'canceled',
      label: t('admin.issue.type.canceled', 'Canceled'),
    },
    {
      id: 'noteligible',
      label: t('admin.issue.type.noteligible', 'Not eligible'),
    },
    {
      id: 'unicheck',
      label: t('admin.issue.type.unicheck', 'Uni check'),
    },
    {
      id: 'other',
      label: t('admin.issue.type.other.label', 'Other'),
    },
  ];

  return (
    <div className="flex items-center">
      <div className={styles.status}>
        <p className={styles.statusText}>
          {t('admin.provider.policy.public.status.subtitle', 'Status')}
        </p>
        <ChangeStatus
          id={policy?.id ?? ''}
          openIssues={policy?.openIssues}
          status={status}
          defaultStartDate={policy?.startDate ?? ''}
          confirmationOfCoverageFromProvider={
            policy?.confirmationOfCoverageFromProviderUrl
          }
        />
      </div>
      <div className={styles.createIssueSection}>
        <CreateIssueButton
          policyId={policy?.id ?? ''}
          issueTypeOptions={issueTypeOptions}
          providerView={true}
          loading={loading}
          error={!!error}
          createIssue={handleCreateIssue}
          data={!!data}
        />
      </div>
    </div>
  );
};

export default PolicyActions;
