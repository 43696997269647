import { useTranslation } from '@getpopsure/i18n-react';
import SelectButton, { SelectButtonOption } from 'components/SelectButton';

import * as styles from './styles';

interface Props<StatusType> {
  options: SelectButtonOption<StatusType>[];
  status?: SelectButtonOption<StatusType>;
  onChange: (option: SelectButtonOption<StatusType>) => void;
}

export const StatusDropdown = <StatusType extends string>({
  options,
  status,
  onChange,
}: Props<StatusType>) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <h1 className={styles.label}>
        {t('component.statusDropdown.title', 'Status')}
      </h1>
      <SelectButton<StatusType>
        withDot={true}
        options={options}
        selected={status}
        disabled={false}
        label=""
        handleOnChange={onChange}
        dataTestId="status-update-button"
      />
    </div>
  );
};
