import { BikeClaim } from 'pages/claims/bike/models';
import { DentalClaim } from 'pages/claims/dental/models';
import { DogLiabilityClaim } from 'pages/claims/dogLiability/models';
import { ExpatHealthClaim } from 'pages/claims/expatHealth/models';
import { ExpatSpainClaim } from 'pages/claims/expatSpain/models';
import { GenericClaim } from 'pages/claims/generic/models';
import { HouseholdClaim } from 'pages/claims/household/models';
import { LegalClaim } from 'pages/claims/legal/models';
import { LiabilityClaim } from 'pages/claims/liability/models';
import { PrivateHealthClaim } from 'pages/claims/privateHealth/models';
import { InsuranceType } from 'shared/insurances/types';

// TODO: [KONG] Unify return query object shape and remove util
export const retrieveClaimsData = (
  insuranceType: InsuranceType,
  data: unknown
) => {
  if (insuranceType === 'INCOMING') {
    return (
      (data as { expatHealthClaim: ExpatHealthClaim | null })
        ?.expatHealthClaim ?? null
    );
  }

  if (insuranceType === 'DENTAL') {
    return (data as { dentalClaim: DentalClaim | null })?.dentalClaim ?? null;
  }

  if (insuranceType === 'LIABILITY') {
    return (
      (data as { liabilityClaim: LiabilityClaim | null })?.liabilityClaim ??
      null
    );
  }

  if (insuranceType === 'HOUSEHOLD') {
    return (
      (data as { householdClaim: HouseholdClaim | null })?.householdClaim ??
      null
    );
  }

  if (insuranceType === 'PRIVATE_HEALTH') {
    return (
      (data as { privateHealthClaim: PrivateHealthClaim | null })
        ?.privateHealthClaim ?? null
    );
  }

  if (
    [
      'TRAVEL',
      'DISABILITY',
      'PET_HEALTH',
      'COMPANY_HEALTH',
      'COMPANY_LIFE',
      'INCOMING_LT',
      'INCOMING_EU',
    ].includes(insuranceType)
  ) {
    return (
      (data as { genericClaim: GenericClaim | null })?.genericClaim ?? null
    );
  }

  if (insuranceType === 'BIKE') {
    return (data as { bikeClaim: BikeClaim | null })?.bikeClaim ?? null;
  }

  if (insuranceType === 'LEGAL') {
    return (data as { legalClaim: LegalClaim | null })?.legalClaim ?? null;
  }

  if (insuranceType === 'DOG_LIABILITY') {
    return (
      (data as { dogLiabilityClaim: DogLiabilityClaim | null })
        ?.dogLiabilityClaim ?? null
    );
  }

  if (insuranceType === 'INCOMING_ES') {
    return (
      (data as { expatSpainHealthClaim: ExpatSpainClaim | null })
        ?.expatSpainHealthClaim ?? null
    );
  }

  return null;
};

export const retrieveReadableClaimTypes = (
  numberOfTypes: number,
  readableFirstType: string
): string => {
  if (numberOfTypes > 1) {
    return `${readableFirstType} + ${numberOfTypes - 1}`;
  }

  return readableFirstType;
};

export const hasPolicyDetailsPage = (insuranceType: InsuranceType): boolean => {
  const insuranceTypesWithPolicyDetailsPage: InsuranceType[] = [
    'PRIVATE_HEALTH',
    'INCOMING',
    'TRAVEL',
    'DISABILITY',
    'PET_HEALTH',
    'COMPANY_HEALTH',
    'COMPANY_LIFE',
    'BIKE',
  ];

  return insuranceTypesWithPolicyDetailsPage.includes(insuranceType);
};
