import { gql } from '@apollo/client';

export const GET_PRIVATE_HEALTH_CLAIMS = gql`
  query GetPrivateHealthClaims(
    $offset: Float!
    $limit: Float!
    $searchString: String!
    $sortColumn: String!
    $sortOrder: String!
    $filterStatus: [String]!
    $filterClaimType: [String]
    $createdAtRangeStart: DateTime
    $createdAtRangeEnd: DateTime
  ) {
    privateHealthClaims(
      offset: $offset
      limit: $limit
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterStatus: $filterStatus
      filterClaimType: $filterClaimType
      createdAtRangeStart: $createdAtRangeStart
      createdAtRangeEnd: $createdAtRangeEnd
    ) {
      id
      status
      amount
      createdAt
      claimType {
        id
        type
      }
      user {
        firstName
        lastName
        isDelinquent
        isFraudulent
        fraudulentAdditionalInfo
      }
      policy {
        insuredPerson {
          firstName
          lastName
        }
        policyNumber
        currentTariffInfo {
          tariff
        }
      }
    }
    totalPrivateHealthClaims(
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterStatus: $filterStatus
      filterClaimType: $filterClaimType
      createdAtRangeStart: $createdAtRangeStart
      createdAtRangeEnd: $createdAtRangeEnd
    )
  }
`;
