import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import { HeaderConfig, RowConfig } from 'components/TableRefactor/types';
import { Status } from 'models/statusColor';
import { bikePlanMapper } from 'shared/insurances/planMappers/bike';
import { policyRouteMapper } from 'shared/insurances/policyRouteMapper';
import {
  bikeBadgeColorMapper,
  bikeStatusMapper,
} from 'shared/insurances/statusMappers/bike';
import { BikeStatus } from 'shared/insurances/types';

import { BikePolicy } from '../models/BikePolicy';

export const tableHeaders: HeaderConfig[] = [
  {
    id: 'firstName',
    label: 'First name',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    sticky: true,
    threshold: 'left-0',
    border: 'none',
    component: SortableColumnHeader,
  },
  {
    id: 'lastName',
    label: 'Last name',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    sticky: true,
    threshold: 'left-[140px]',
    border: 'none',
    component: SortableColumnHeader,
  },
  {
    id: 'status',
    label: 'Status',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    sticky: true,
    threshold: 'left-[280px]',
    border: 'right',
    component: SortableColumnHeader,
  },
  {
    id: 'startDate',
    label: 'Start date',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: SortableColumnHeader,
  },
  {
    id: 'policyNumber',
    label: 'Policy number',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    component: SortableColumnHeader,
  },
  {
    id: 'plan',
    label: 'Plan',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    component: SortableColumnHeader,
  },
  {
    id: 'frameNumber',
    label: 'Frame number',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    component: SortableColumnHeader,
  },
  {
    id: 'createdAt',
    label: 'Created on',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: SortableColumnHeader,
  },
  {
    id: 'source',
    label: 'Source',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: ColumnHeader,
  },
];

const displayArchivedStatus = () =>
  ({
    text: {
      id: 'ARCHIVED',
      text: 'Archived',
    },
    color: 'gray',
  } as Status);

const statusMapping = (status: string) => ({
  text: {
    id: status,
    text: bikeStatusMapper[status as BikeStatus],
  },
  color: bikeBadgeColorMapper[status as BikeStatus] ?? 'gray',
});

export const rowConfig: RowConfig = {
  getResourceLink: (data: BikePolicy) => policyRouteMapper.BIKE(data.id),
  cells: [
    {
      type: 'TEXT',
      props: {
        getText: (data: BikePolicy) => data.insuredPerson?.firstName ?? '',
      },
      textColor: 'dark',
    },
    {
      type: 'TEXT',
      props: {
        getText: (data: BikePolicy) => data.insuredPerson?.lastName ?? '',
      },
      textColor: 'dark',
    },
    {
      type: 'STATUS',
      props: {
        getStatus: (data: BikePolicy) => {
          if (data.archivedAt) return displayArchivedStatus();
          return statusMapping(data.publicStatus);
        },
      },
      textColor: 'light',
    },
    {
      type: 'DATE',
      props: {
        getDate: (data: BikePolicy) => data.startDate ?? '',
      },
      textColor: 'light',
    },
    {
      type: 'TEXT',
      props: {
        getText: (data: BikePolicy) => data.policyNumber ?? '',
      },
      textColor: 'light',
    },
    {
      type: 'TEXT',
      props: {
        getText: (data: BikePolicy) =>
          data?.bikePlanId ? bikePlanMapper[data.bikePlanId] : '',
      },
      textColor: 'light',
    },
    {
      type: 'TEXT',
      props: {
        getText: (data: BikePolicy) => data.bikeInfo?.frameNumber ?? '',
      },
      textColor: 'light',
    },
    {
      type: 'DATE',
      props: {
        getDate: (data: BikePolicy) => data.createdAt ?? '',
      },
      textColor: 'light',
    },
    {
      type: 'TEXT',
      props: { getText: (data: BikePolicy) => data.source ?? '' },
      textColor: 'light',
    },
  ],
};
