import { HouseholdClaimType } from './models';

export const claimTypeNameMapper: Record<HouseholdClaimType, string> = {
  FIRE_DAMAGE: 'Fire damage',
  WATER_DAMAGE: 'Water damage',
  BURGLARY: 'Burglary',
  BIKE_THEFT: 'Bike theft',
  GLASS_BREAKAGE: 'Glass breakage',
  OTHER: 'Other',
};
