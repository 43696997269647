import { useLazyQuery, useMutation } from '@apollo/client';
import {
  DocumentTextIcon,
  ExternalLinkIcon,
  ShieldCheckIcon,
  UserIcon,
} from '@heroicons/react/outline';
import Modal from 'components/Modal';
import {
  HIDE_SCANNED_DOCUMENTS,
  SHOW_SCANNED_DOCUMENTS,
} from 'pages/scannedDocuments/scannedDocuments.mutations';
import {
  GET_POLICIES_BY_POLICY_NUMBERS,
  GET_SCANNED_DOCUMENTS,
} from 'pages/scannedDocuments/scannedDocuments.queries';
import {
  BasePolicy,
  ScannedDocument,
} from 'pages/scannedDocuments/scannedDocuments.types';
import { getPolicyDetailsBaseURLFromInsuranceType } from 'pages/scannedDocuments/scannedDocuments.utils';
import { useEffect } from 'react';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';

import * as styles from './ScannedDocuments.styles';

type ToggleVisibilityBulkActionProps = {
  selectedDocuments: ScannedDocument[];
  modalIsShown: boolean;
  closeModal: () => void;
  hideDocumentsMode?: boolean;
};

type GetPoliciesByPolicyNumbersData = {
  policiesByPolicyNumbers: BasePolicy[] | null;
};

export const ToggleVisibility = ({
  selectedDocuments,
  modalIsShown,
  closeModal,
  hideDocumentsMode,
}: ToggleVisibilityBulkActionProps) => {
  if (hideDocumentsMode) {
    return (
      <HideDocumentsModal
        selectedDocuments={selectedDocuments}
        modalIsShown={modalIsShown}
        closeModal={closeModal}
      />
    );
  }
  return (
    <ShowDocumentsModal
      selectedDocuments={selectedDocuments}
      modalIsShown={modalIsShown}
      closeModal={closeModal}
      hideDocumentsMode={hideDocumentsMode}
    />
  );
};

const ShowDocumentsModal = ({
  selectedDocuments,
  modalIsShown,
  closeModal,
  hideDocumentsMode,
}: {
  selectedDocuments: ScannedDocument[];
  modalIsShown: boolean;
  closeModal: () => void;
  hideDocumentsMode?: boolean;
}) => {
  const selectedDocumentsCount = selectedDocuments.length;

  const policyNumbers = selectedDocuments
    .map(({ policyNumber }) => policyNumber)
    .filter(Boolean);

  const [getPoliciesByPolicyNumbers, getPoliciesByPolicyNumbersResult] =
    useLazyQuery<GetPoliciesByPolicyNumbersData>(
      GET_POLICIES_BY_POLICY_NUMBERS,
      {
        variables: {
          policyNumbers,
        },
      }
    );

  const matchedPolicies =
    getPoliciesByPolicyNumbersResult.data?.policiesByPolicyNumbers;

  const documentAndPolicyPairs = selectedDocuments.flatMap(
    (scannedDocument) => {
      const matchingPolicy = matchedPolicies?.find(
        (policy) => policy.policyNumber === scannedDocument.policyNumber
      );
      if (matchingPolicy) {
        return {
          scannedDocument,
          policy: matchingPolicy,
        };
      }
      return [];
    }
  );

  const qualifiedDocumentsCount = matchedPolicies?.length ?? 0;

  const showScannedDocumentsDataArray = documentAndPolicyPairs.map(
    ({ scannedDocument, policy }) => ({
      id: scannedDocument.id,
      userPolicyId: policy.id,
    })
  );

  const [showDocuments, { loading }] = useMutation(SHOW_SCANNED_DOCUMENTS, {
    variables: {
      showScannedDocumentsDataArray,
    },
    refetchQueries: [GET_SCANNED_DOCUMENTS],
  });

  const handleConfirm = async () => {
    const result = await showDocuments();

    if (!result.errors) {
      closeModal();
    }
  };

  useEffect(() => {
    const fetchStuff = async () => {
      await getPoliciesByPolicyNumbers();
    };
    if (modalIsShown && !hideDocumentsMode) {
      fetchStuff();
    }
  }, [modalIsShown, hideDocumentsMode]);

  return (
    <Modal
      open={modalIsShown}
      setOpen={closeModal}
      title="Show documents?"
      confirmButtonLabel="Show"
      handleConfirm={handleConfirm}
      loading={loading}
      scrollable
      wide
    >
      <div className="text-sm text-gray-600">
        <p>
          <span className="font-bold">{qualifiedDocumentsCount}</span> out of
          the <span className="font-bold">{selectedDocumentsCount}</span>{' '}
          selected documents have a matching policy and can be shown in their
          respective user account:
        </p>
        <div className={styles.tableWrapper}>
          <div className={styles.tableContent}>
            <table className={styles.table}>
              <thead>
                <tr className={styles.tableHeader}>
                  <td className="p-3">
                    <div className="flex items-center gap-2">
                      <div className={styles.icon}>
                        <DocumentTextIcon />
                      </div>
                      <span className="font-bold">Recipient name</span>
                    </div>
                  </td>
                  <td className="p-3">
                    <div className="flex items-center gap-2">
                      <div className={styles.icon}>
                        <ShieldCheckIcon />
                      </div>
                      <span className="font-bold">Insured person</span>
                    </div>
                  </td>
                  <td className="p-3">
                    <div className="flex items-center gap-2">
                      <div className={styles.icon}>
                        <UserIcon />
                      </div>
                      <span className="font-bold">Account holder</span>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                {documentAndPolicyPairs.map(({ scannedDocument, policy }) => {
                  const { recipientName } = scannedDocument;
                  const insuredPersonName =
                    policy?.questionnaires?.answers?.personalInfo?.name
                      ?.firstName &&
                    policy.questionnaires?.answers?.personalInfo?.name?.lastName
                      ? `${policy?.questionnaires?.answers?.personalInfo?.name?.firstName} ${policy.questionnaires?.answers?.personalInfo?.name?.lastName}`
                      : '';
                  const userName =
                    policy?.user?.firstName && policy?.user?.lastName
                      ? `${policy.user.firstName} ${policy.user.lastName}`
                      : '';

                  const documentLink = generatePath(
                    paths.scannedDocuments.details.path,
                    {
                      id: scannedDocument.id,
                    }
                  );

                  const policyLink = getPolicyDetailsBaseURLFromInsuranceType(
                    policy.insuranceType,
                    policy.id
                  );

                  const accountLink = generatePath(
                    paths.customers.details.path,
                    {
                      id: policy.user.id,
                    }
                  );

                  return (
                    <tr key={scannedDocument.id}>
                      <td className={styles.tableCell}>
                        <a
                          className={styles.link}
                          href={documentLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {recipientName}
                          <div className={styles.icon}>
                            <ExternalLinkIcon />
                          </div>
                        </a>
                      </td>
                      <td className={styles.tableCell}>
                        <a
                          className={styles.link}
                          href={policyLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {insuredPersonName}
                          <div className={styles.icon}>
                            <ExternalLinkIcon />
                          </div>
                        </a>
                      </td>
                      <td className={styles.tableCell}>
                        <a
                          className={styles.link}
                          href={accountLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {userName}
                          <div className={styles.icon}>
                            <ExternalLinkIcon />
                          </div>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <p className={styles.bottomText}>
          Are you sure you want to reveal these documents in their respective
          user account?
        </p>
        <p className="mt-2">
          Accepting this action will also confirm the policy relations and set
          the document statuses to <span className="font-bold">DONE</span>.
        </p>
      </div>
    </Modal>
  );
};

const HideDocumentsModal = ({
  selectedDocuments,
  modalIsShown,
  closeModal,
}: {
  selectedDocuments: ScannedDocument[];
  modalIsShown: boolean;
  closeModal: () => void;
}) => {
  const ids = selectedDocuments.map(({ id }) => id);

  const [hideDocuments, { loading }] = useMutation(HIDE_SCANNED_DOCUMENTS, {
    variables: {
      scannedDocumentIds: ids,
    },
    refetchQueries: [GET_SCANNED_DOCUMENTS],
  });

  const handleConfirm = async () => {
    const result = await hideDocuments();

    if (!result.errors) {
      closeModal();
    }
  };

  const visibleDocuments = selectedDocuments.filter(
    ({ visibleInUserAccount, userPolicyId }) =>
      visibleInUserAccount && userPolicyId
  );

  const qualifiedDocumentsCount = visibleDocuments.length;
  const selectedDocumentsCount = selectedDocuments.length;

  return (
    <Modal
      open={modalIsShown}
      setOpen={closeModal}
      title="Hide documents?"
      confirmButtonLabel="Hide"
      handleConfirm={handleConfirm}
      loading={loading}
      scrollable
    >
      <div className="text-sm text-gray-600">
        <p>
          <span className="font-bold">{qualifiedDocumentsCount}</span> out of
          the <span className="font-bold">{selectedDocumentsCount}</span>{' '}
          selected documents are currently visible in their user accounts and
          can be hidden:
        </p>
        <div className={styles.tableWrapper}>
          <div className={styles.tableContent}>
            <table className={styles.table}>
              <thead>
                <tr className={styles.tableHeader}>
                  <td className="p-3">
                    <div className="flex items-center gap-2">
                      <div className={styles.icon}>
                        <DocumentTextIcon />
                      </div>
                      <span className="font-bold">Recipient name</span>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                {visibleDocuments.map((scannedDocument) => {
                  const { recipientName } = scannedDocument;

                  const documentLink = generatePath(
                    paths.scannedDocuments.details.path,
                    {
                      id: scannedDocument.id,
                    }
                  );

                  return (
                    <tr key={scannedDocument.id}>
                      <td className={styles.tableCell}>
                        <a
                          className={styles.link}
                          href={documentLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {recipientName}
                          <div className={styles.icon}>
                            <ExternalLinkIcon />
                          </div>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <p className={styles.bottomText}>
          Are you sure you want to hide these documents from their respective
          user account(s)?
        </p>
      </div>
    </Modal>
  );
};
