import { useQuery } from '@apollo/client';
import { capitalizeName } from '@getpopsure/public-utility';
import { UserIcon } from '@heroicons/react/solid';
import { DetailsPage } from 'components/DetailsPage';
import { HeaderInfoText } from 'components/DetailsPage/models';
import { TabInformation } from 'components/Tabs';
import { GET_COMPANIES } from 'graphql/admin/companies/queries';
import { GET_ADMIN_USER } from 'graphql/admin/users/queries';
import { useDebounce } from 'hooks/useDebounce';
import { AdminUser } from 'models/AdminUser';
import { useState } from 'react';
import { RouteComponentProps } from 'react-router';

import { DataCompanies } from '../users/types';
import OverviewTab from './components/OverviewTab';
import ResetPasswordButton from './components/ResetPasswordButton';

type AdminUserPageParams = {
  id: string;
};

interface Data {
  adminUser: AdminUser;
}

const AdminUserPage = ({ match }: RouteComponentProps<AdminUserPageParams>) => {
  const {
    loading,
    error,
    data: adminUserData,
  } = useQuery<Data>(GET_ADMIN_USER, {
    variables: {
      id: match.params.id,
    },
  });

  const [companiesQuery, setCompaniesQuery] = useState(
    adminUserData?.adminUser.company ?? ''
  );
  const debouncedCompaniesQuery = useDebounce(companiesQuery, 1_000);

  const { data: fetchedCompaniesData } = useQuery<DataCompanies>(
    GET_COMPANIES,
    {
      variables: {
        limit: 25,
        offset: 0,
        searchString:
          debouncedCompaniesQuery.trim().length > 0
            ? debouncedCompaniesQuery
            : adminUserData?.adminUser.company ?? '',
        sortColumn: '',
        sortOrder: '',
        filterType: 'any',
      },
      notifyOnNetworkStatusChange: false,
    }
  );

  const tabs: TabInformation[] = [
    {
      name: 'Overview',
      href: '#overview',
      withDot: false,
    },
  ];

  const companiesList =
    fetchedCompaniesData?.companies.map((company) => {
      return {
        id: company.id,
        label: company.name,
      };
    }) ?? [];

  const tabComponents = [
    {
      id: 'overview',
      component: OverviewTab,
      props: {
        adminUser: adminUserData?.adminUser,
        companies: companiesList,
        setCompaniesQuery,
      },
    },
  ];

  const headerInfoTexts: HeaderInfoText[] = [
    {
      title: 'Active user',
      icon: <UserIcon />,
      check: adminUserData?.adminUser.active ? 'check' : 'cross',
    },
  ];

  const fullName =
    adminUserData?.adminUser.firstName && adminUserData.adminUser.lastName
      ? capitalizeName({
          firstName: adminUserData.adminUser.firstName,
          lastName: adminUserData?.adminUser.lastName,
        }) ?? ''
      : '';

  return (
    <>
      <DetailsPage
        windowTitle="Admin user - Feather Admin Panel"
        pageTitle={fullName}
        backLink={{
          text: 'Back',
        }}
        loading={loading}
        tabs={tabs}
        tabComponents={tabComponents}
        current="admin-users"
        headerInfoTexts={headerInfoTexts}
        error={error}
        headerActions={
          !adminUserData?.adminUser.isFeather && (
            <ResetPasswordButton
              adminUserId={adminUserData?.adminUser.id ?? ''}
            />
          )
        }
      />
    </>
  );
};

export default AdminUserPage;
