import { gql } from '@apollo/client';

export const GET_TK_API_REQUEST = gql`
  query Query($id: String!) {
    tkApiRequest(id: $id) {
      id
      createdAt
      status
      response
      externalId
      payload
      userPolicy {
        id
        publicStatus
        user {
          firstName
          lastName
        }
      }
    }
  }
`;
