import classNames from 'classnames';

export const tableWrapper = classNames('mx-[40px]');

export const tablePlaceholder = classNames(
  'flex',
  'justify-center',
  'items-center',
  'h-[630px]'
);

export const loader = classNames('animate-spin');

export const text = classNames('text-gray-600', 'ml-[8px]');

export const paginationPlaceholder = classNames(
  'mt-[21px]',
  'flex',
  'justify-between',
  'items-center'
);

export const paginationTextPlaceholder = classNames(
  'bg-gray-300',
  'w-[180px]',
  'h-[12px]',
  'rounded-[4px]',
  'animate-pulse'
);

export const paginationButtonsPlaceholder = classNames(
  'bg-gray-300',
  'w-[320px]',
  'h-[28px]',
  'rounded-[6px]',
  'animate-pulse'
);

export const tableDataPlaceholder = classNames(
  'bg-white',
  'w-full',
  'rounded-[8px]'
);
