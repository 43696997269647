export const arrayFromNtoM = (startIndex: number, endIndex: number) => {
  const length = Math.abs(endIndex - startIndex) + 1;

  const offset = Math.min(startIndex, endIndex);

  return Array.from({ length }, (_, index) => index + offset);
};

export const nonNullish = <T>(value: T): value is NonNullable<T> => {
  return value !== null && value !== undefined;
};
