import classNames from 'classnames';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';

import Modal from '../../Modal';
import {
  type Task,
  type TaskStatus,
  TaskAction,
  TaskFile,
} from '../TaskEngine.types';
import { AddressPreview } from './AddressPreview';
import { CurrencyPreview } from './CurrencyPreview';
import { DatePreview } from './DatePreview';
import { FilePreview } from './FilePreview';
import { LinkPreview } from './LinkPreview';
import { RichTextPreview } from './RichTextPreview';
import * as styles from './TaskPreviewModal.styles';
import { TextPreview } from './TextPreview';

interface TaskDetailsProps {
  task: Task;

  onClose(): void;
}

const taskStatusMapping: Record<TaskStatus, string> = {
  COMPLETED: 'Completed',
  OPEN: 'Not completed',
  CANCELED: 'Canceled',
};

export const TaskPreviewModal = ({ task, onClose }: TaskDetailsProps) => {
  const actions = (task.description.actions || []).filter(
    (action) => action.type !== 'SUBMIT'
  );

  const isTaskCompleted = task.status === 'COMPLETED';

  const taskAdditionalInfo = actions.reduce(
    (
      acc: { links: Array<string>; files: Array<TaskFile> },
      action: TaskAction
    ) => {
      if (action.type === 'DOWNLOAD') {
        return {
          ...acc,
          files: [...acc.files, action.attributes.file],
        };
      }

      if (action.type === 'LINK') {
        return {
          ...acc,
          links: [...acc.links, action.attributes.href],
        };
      }

      return acc;
    },
    {
      links: [],
      files: [],
    }
  );

  return (
    <Modal
      open
      title={task.description.metadata?.title || ''}
      setOpen={onClose}
      hideActions
      scrollable
    >
      <div>
        <section>
          <header className={styles.header}>
            <h2 className={styles.subtitle}>
              {taskStatusMapping[task.status]}
            </h2>
            <p className={styles.date}>
              {isTaskCompleted
                ? dayjs(task.finishedAt).format(DATE_FORMAT)
                : '—'}
            </p>
          </header>
          <div className="mb-6">
            {isTaskCompleted ? (
              actions.map((action) => {
                switch (action.type) {
                  case 'INPUT':
                  case 'TEXTAREA':
                    return (
                      <TextPreview
                        key={action.id}
                        data={task.data}
                        actionId={action.id}
                        label={action.attributes.placeholder}
                      />
                    );
                  case 'ADDRESS':
                    return <AddressPreview key={action.id} data={task.data} />;
                  case 'DATE':
                    return (
                      <DatePreview
                        key={action.id}
                        actionId={action.id}
                        data={task.data}
                      />
                    );
                  case 'CURRENCY':
                    return (
                      <CurrencyPreview
                        key={action.id}
                        actionId={action.id}
                        data={task.data}
                      />
                    );
                  case 'FILE':
                    return <FilePreview key={action.id} data={task.data} />;
                  case 'LINK':
                  case 'DOWNLOAD':
                    return <LinkPreview key={action.id} />;
                  default:
                    return null;
                }
              })
            ) : (
              <p className={styles.answersPlaceholder}>
                Details will appear here
              </p>
            )}
          </div>
        </section>
        <section className={styles.taskDetailsContainer}>
          <header className={styles.header}>
            <h2 className={styles.subtitle}>Task sent</h2>
            <p className={styles.date}>
              {dayjs(task.createdAt).format(DATE_FORMAT)}
            </p>
          </header>
          <div className={styles.rowFullWidth}>
            <p className={classNames('mb-2', styles.rowText)}>Description</p>
            {task.description.metadata?.description ? (
              <RichTextPreview
                dangerouslySetHtml={task.description.metadata.description}
              />
            ) : (
              <p className={styles.rowText}>—</p>
            )}
          </div>
          {taskAdditionalInfo.links.length > 0 &&
            taskAdditionalInfo.links.map((link) => (
              <div className={styles.row}>
                <p className={styles.rowText}>Link</p>
                <p className={styles.rowText}>{link}</p>
              </div>
            ))}
          {taskAdditionalInfo.files.length > 0 && (
            <>
              <div className={styles.row}>
                <p className={styles.rowText}>Uploaded by Feather</p>
              </div>
              <div>
                <FilePreview data={{ files: taskAdditionalInfo.files }} />
              </div>
            </>
          )}
        </section>
      </div>
    </Modal>
  );
};
