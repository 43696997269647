import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import { expatSpainStatusMapper } from 'shared/insurances/statusMappers/expatSpain';
import { ExpatSpainStatus } from 'shared/insurances/types';

const statusFilterOptions: SelectButtonOption<ExpatSpainStatus>[] = [
  {
    id: 'PENDING',
    label: expatSpainStatusMapper.PENDING,
    title: expatSpainStatusMapper.PENDING,
  },
  {
    id: 'ACTIVE',
    label: expatSpainStatusMapper.ACTIVE,
    title: expatSpainStatusMapper.ACTIVE,
  },
  {
    id: 'CANCELED',
    label: expatSpainStatusMapper.CANCELED,
    title: expatSpainStatusMapper.CANCELED,
  },
];

export const tableFilters: FilterConfig[] = [
  {
    options: statusFilterOptions,
    filterType: 'MULTIPLE',
    label: 'Status',
    id: 'filterStatus',
    placeholder: 'Select status(es)',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Start date',
    id: 'filterStartDate',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Created on',
    id: 'filterCreatedAt',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Archived on',
    id: 'filterArchivedAt',
  },
];
