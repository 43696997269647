import { InformationRow, InformationSection } from 'components/InformationCard';
import DateInformation from 'components/InformationCard/DateInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { LiabilityPolicy } from 'pages/policies/liability/models/LiabilityPolicy';
import { getLiabilityReadableTariff } from 'pages/policies/liability/utils';
import { policyMappers } from 'shared/insurances';

export const retrieveLiabilityPolicyInfoRows = (
  policyInfo: LiabilityPolicy
): InformationSection[] => [
  {
    title: 'Policy information',
    boldTitle: false,
    rows: [
      {
        id: 'status',
        title: 'Status',
        data:
          policyMappers.LIABILITY?.statusMapper[policyInfo?.publicStatus] ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
      {
        id: 'policyNumber',
        title: 'Policy number',
        data: policyInfo?.policyNumber ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
      {
        id: 'startDate',
        title: 'Start date',
        data: policyInfo?.startDate ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: false,
      },
      ...(policyInfo?.publicStatus === 'CANCELED'
        ? [
            {
              id: 'activeUntil',
              title: 'Active until',
              data: policyInfo?.activeUntil ?? '',
              type: 'DATE',
              component: DateInformation,
              editable: false,
            } as InformationRow,
          ]
        : []),
      {
        id: 'inclusiveMembers',
        title: 'Tariff',
        data: getLiabilityReadableTariff(policyInfo?.inclusiveMembers),
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
    ],
  },
];
