import { gql } from '@apollo/client';

export const GET_EXPAT_SPAIN_POLICIES = gql`
  query GetExpatSpainPolicies(
    $offset: Float!
    $limit: Float!
    $searchString: String!
    $sortColumn: String!
    $sortOrder: String!
    $filterStatus: [String]
    $filterCreatedAt: [DateTime]
    $filterStartDate: [DateTime]
    $filterArchivedAt: [DateTime]
  ) {
    expatSpainPolicies(
      offset: $offset
      limit: $limit
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterStatus: $filterStatus
      filterCreatedAt: $filterCreatedAt
      filterStartDate: $filterStartDate
      filterArchivedAt: $filterArchivedAt
    ) {
      id
      policyNumber
      publicStatus
      startDate
      createdAt
      archivedAt
      source

      # Insured person
      insuredPerson {
        firstName
        lastName
      }

      user {
        id
        firstName
        lastName
      }

      # Quote info
      quote {
        plan
      }
    }
    totalExpatSpainPolicies(
      searchString: $searchString
      filterStatus: $filterStatus
      filterCreatedAt: $filterCreatedAt
      filterStartDate: $filterStartDate
      filterArchivedAt: $filterArchivedAt
    )
  }
`;
