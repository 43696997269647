import { gql } from '@apollo/client';

export const UPDATE_LIABILITY_POLICY_STATUS = gql`
  mutation UpdateLiabilityPolicyStatus(
    $insuranceType: String!
    $status: String!
    $id: String!
    $policyNumber: String
    $activeUntil: String
    $reinstateDate: String
    $price: Float
  ) {
    updateLiabilityPolicyStatus(
      insuranceType: $insuranceType
      status: $status
      id: $id
      policyNumber: $policyNumber
      activeUntil: $activeUntil
      reinstateDate: $reinstateDate
      price: $price
    ) {
      id
      publicStatus
      policyNumber
      activeUntil
      price
    }
  }
`;
