import classNames from 'classnames';

export const policyCard = classNames(
  'border-t',
  'border-t-gray-200',
  'px-[24px]',
  'py-[20px]',
  'flex',
  'justify-between',
  'content-center',
  'hover:bg-gray-50',
  'transition',
  'duration-100',
  'ease-in'
);

export const leftContainer = classNames('flex', 'justify-between');

export const icon = classNames('w-[48px]', 'h-[48px]');

export const titleAndDateContainer = classNames(
  'ml-[12px]',
  'flex',
  'flex-col',
  'justify-center'
);

export const titleContainer = classNames('flex');
export const titleContainerSmall = classNames('flex-col');

export const title = classNames('text-sm');

export const insuredPerson = classNames('text-sm', 'text-gray-600', 'ml-[8px]');
export const insuredPersonSmall = classNames(
  'text-xs',
  'text-gray-600',
  'mt-1'
);

export const startDate = classNames('text-xs', 'text-gray-600', 'mt-[4px]');

export const provider = classNames(startDate, 'ml-[8px]');

export const rightContainer = classNames('flex', 'self-center');

export const price = classNames(
  'text-sm',
  'text-gray-700',
  'font-bold',
  'leading-7'
);

export const status = classNames('ml-[12px]');
