import Card from 'components/Card';
import dayjs from 'dayjs';
import { PaymentMethod } from 'models/PaymentMethod';
import { useState } from 'react';
import paypalIcon from 'shared/paymentMethods/icons/paypal.svg';
import sepaIcon from 'shared/paymentMethods/icons/sepa.svg';
import { paymentBrandIconMapper } from 'shared/paymentMethods/paymentBrandIconMapper';

import { PaymentMethodModal } from './components/PaymentMethodModal';
import * as styles from './styles';

interface Props {
  paymentMethods?: PaymentMethod[];
}

export const CustomerPaymentMethods = ({ paymentMethods }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<PaymentMethod | null>(
    null
  );

  const handleClick = (method: PaymentMethod) => {
    setSelectedMethod(method);
    setOpenModal(true);
  };

  return (
    <>
      <PaymentMethodModal
        paymentMethod={selectedMethod}
        isOpen={openModal}
        setIsOpen={setOpenModal}
      />
      <div className={styles.container}>
        <Card title="Payment methods" boldTitle={false}>
          {paymentMethods && paymentMethods.length > 0 ? (
            paymentMethods.map((method) => {
              const { id, type, isDefault, details } = method;

              const isCard = type === 'CARD';
              const isSEPA = type === 'SEPA';
              const isPayPal = type === 'PAYPAL';

              const cardIcon = details?.brand
                ? paymentBrandIconMapper[details.brand]
                : '';

              const expiryYear =
                details?.expMonth && details.expYear
                  ? dayjs(`${details.expYear}-${details.expMonth}`).format(
                      'MM/YY'
                    )
                  : '';

              return (
                <button
                  className={styles.paymentMethodCard}
                  key={id}
                  onClick={() => handleClick(method)}
                  type="button"
                >
                  <div className="flex max-w-[50%]">
                    <img
                      src={isSEPA ? sepaIcon : isPayPal ? paypalIcon : cardIcon}
                      alt="payment brand icon"
                    />
                    {isCard && (
                      <div className={styles.cardNumberContainer}>
                        <div className={styles.number}>
                          {details?.last4 ? `•••• ${details.last4}` : ''}
                        </div>
                        <p
                          className={styles.expireDate}
                        >{`Expires ${expiryYear}`}</p>
                      </div>
                    )}
                    {isSEPA && (
                      <div className={styles.sepaNumberContainer}>
                        <div className={styles.number}>
                          {details?.last4 ? `•••• ${details.last4}` : ''}
                        </div>
                      </div>
                    )}
                    {isPayPal && (
                      <div className={styles.paypalEmailContainer}>
                        <div className={styles.number}>
                          {details?.payerEmail ? details.payerEmail : ''}
                        </div>
                      </div>
                    )}
                  </div>
                  {isDefault && (
                    <div className={styles.defaultPayment}>Default</div>
                  )}
                </button>
              );
            })
          ) : (
            <p className={styles.noPaymentMethodsText}>
              No payment method added yet.
            </p>
          )}
        </Card>
      </div>
    </>
  );
};
