import { NUMBER_OF_POLICIES_PER_PAGE } from 'pages/policies/template/constants';
import { PolicyTemplateInsurancePath } from 'pages/policies/template/models/InsuranceTypes';
import { PolicyTemplateData } from 'pages/policies/template/models/PolicyTemplateData';
import { getDbInsuranceType } from 'pages/policies/template/utils';
import { Country, countryNameMapper } from 'shared/countries/models';
import { insuranceNameMapper } from 'shared/insurances/insuranceNameMapper';
import { policyDocumentCategories } from 'shared/policyDocumentCategories';

import { CREATE_GENERIC_POLICY } from '../graphql/createPolicy';
import { GET_GENERIC_POLICIES } from '../graphql/policies';
import { GET_GENERIC_POLICY } from '../graphql/policy';
import { UPDATE_GENERIC_POLICY_STATUS } from '../graphql/updateStatus';
import { GenericPoliciesData } from '../models/GenericPoliciesData';
import { GenericPolicyData } from '../models/GenericPolicyData';
import {
  createPolicyFormData,
  createPolicySubmissionSchema,
} from './createPolicyFormData';
import { getInsuredPersonName } from './detailsHeader';
import { getDocumentSections } from './documentsData';
import { getOverviewTabCards } from './overviewTabData';
import { getQuestionnaireData } from './questionnaireData';
import { getModals, statusChanges, statusOptions } from './statusData';
import { getFilterConfigByRegion } from './tableFilters';
import { getTableHeaders } from './tableHeaders';
import { getRowConfig } from './tableRows';

export const getGenericPoliciesTemplateData = (
  insurancePath: PolicyTemplateInsurancePath,
  currentRegion?: Country
): PolicyTemplateData<
  GenericPoliciesData,
  GenericPolicyData,
  'POLICY_DOCUMENT'
> => {
  const insuranceType = getDbInsuranceType(insurancePath);
  const insuranceName = insuranceNameMapper[insuranceType];
  return {
    insuranceType,
    navigation: {
      current: `policies-${insurancePath}${
        currentRegion ? `-${currentRegion}` : ''
      }`,
    },
    queries: {
      allPolicies: GET_GENERIC_POLICIES,
      policy: GET_GENERIC_POLICY,
    },
    mutations: {
      statusChange: UPDATE_GENERIC_POLICY_STATUS,
      createPolicy: CREATE_GENERIC_POLICY,
    },
    copy: {
      table: {
        title: `${insuranceName} policies`,
        subtitle: currentRegion ? countryNameMapper[currentRegion] : undefined,
        searchPlaceholder:
          'Search by name, email, policy number, policy id or utm source',
      },
      details: {
        pageTitle: `${insuranceName} policy - Feather Admin Panel`,
        title: insuranceName,
      },
    },
    features: {
      overviewTable: {
        numberOfRowsPerPage: NUMBER_OF_POLICIES_PER_PAGE,
        rowConfig: getRowConfig(insurancePath, currentRegion),
        filtersConfig: getFilterConfigByRegion(insuranceType, currentRegion),
        headersConfig: getTableHeaders(currentRegion),
        queryVariables: {
          ...(currentRegion &&
            currentRegion !== 'eu' && {
              region: [currentRegion],
            }),
        },
      },
      policyCreation:
        currentRegion !== 'eu'
          ? {
              createPolicySubmissionSchema,
              retrieveFormData: createPolicyFormData,
            }
          : undefined,
      details: {
        queryVariables: {
          insuranceType,
        },
        insuredPersonName: getInsuredPersonName,
        overviewTab: {
          getCards: getOverviewTabCards,
        },
      },
      statusChange: {
        statusOptions,
        statusChangeOptions: statusChanges,
        getModals,
      },
      documents: {
        uploadOptions: policyDocumentCategories,
        getDocumentSections,
      },
      questionnaire: {
        cardTitle: 'Personal information',
        editable: false,
        getQuestionnaireData,
      },
    },
  };
};
