import { useMutation, useReactiveVar } from '@apollo/client';
import Button from 'components/Button';
import { RESET_ADMIN_USER_PASSWORD } from 'graphql/admin/users/mutations';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

const ResetPasswordButton = ({ adminUserId }: { adminUserId: string }) => {
  const alertBannersState = useReactiveVar(alertBanners);

  const [resetPassword, { loading }] = useMutation(RESET_ADMIN_USER_PASSWORD, {
    variables: { id: adminUserId },
    onCompleted: () => {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message: 'The password was successfully reset.',
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
    onError: () => {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: 'Something went wrong. Please try again.',
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
  });

  const handleResetPasswordClick = () => {
    resetPassword().catch(() => {});
  };

  return (
    <Button
      buttonType="white"
      loading={loading}
      onClick={handleResetPasswordClick}
    >
      Reset password
    </Button>
  );
};

export default ResetPasswordButton;
