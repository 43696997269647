import { gql } from '@apollo/client';

export const GET_QONTO_TRANSACTIONS = gql`
  query TransactionsQonto(
    $insuranceType: String!
    $sortOrder: String!
    $sortColumn: String!
    $searchString: String!
    $filterStatus: [String!]!
    $offset: Float!
    $limit: Float!
  ) {
    claimPaymentTransactions(
      insuranceType: $insuranceType
      offset: $offset
      limit: $limit
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      searchString: $searchString
      filterStatus: $filterStatus
    ) {
      id
      status
      note
      insuranceType
      qontoTransactionId
      emittedAt
      userPolicyId
      userClaimId
      qontoTransaction {
        id
        reference
        emittedAt
        amount
      }
      userPolicy {
        id
        policyNumber
        user {
          id
          firstName
          lastName
          isDelinquent
        }
      }
      userClaim {
        id
        claimNumber
        refundAmount
        iban
        status
        customerPaidOn
        amount
      }
      claimSuggestions {
        id
        amount
        claimNumber
        customerPaidOn
        iban
        refundAmount
      }
      otherClaimSuggestions {
        id
        amount
        claimNumber
        customerPaidOn
        iban
        refundAmount
      }
    }
    claimPaymentTransactionsCount(
      insuranceType: $insuranceType
      filterStatus: $filterStatus
      searchString: $searchString
    )
  }
`;
