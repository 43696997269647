import { TFunction } from '@getpopsure/i18n-react';
import { DocumentSection } from 'components/DocumentsSection/DocumentsSection';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';

export const getDocumentSections =
  (t: TFunction) =>
  (data?: { hrPublicHealthPolicy: PublicHealthPolicy }): DocumentSection[] => {
    const policyDocuments: DocumentSection = {
      title: t(
        'admin.hr.publicHealth.policyDocuments.label',
        'Policy documents'
      ),
      documents:
        data?.hrPublicHealthPolicy.hrVisiblePolicyDocuments
          ?.filter((document) =>
            [
              'CONFIRMATION_OF_COVERAGE',
              'CONFIRMATION_OF_COVERAGE_FROM_PROVIDER',
              'MERGED_CONFIRMATION_OF_COVERAGE',
              'PRELIMINARY_CONFIRMATION_OF_COVERAGE_FROM_PROVIDER',
            ].includes(document.category)
          )
          .map((document) => {
            return {
              ...document,
              fileName: document.name,
              friendlyName: document.name,
            };
          }) ?? [],
    };

    return [policyDocuments];
  };
