export type Language = 'en' | 'de';

export const mapLanguage = (language: Language) => {
  switch (language) {
    case 'en':
      return 'English';
    case 'de':
      return 'German';
    default:
      return 'English (default)';
  }
};
