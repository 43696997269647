import dayjs from 'dayjs';
import updateLocate from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocate);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '%ds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dmo',
    y: '1yr',
    yy: '%dyr',
  },
});

dayjs.updateLocale('de', {
  relativeTime: {
    future: 'in %s',
    past: 'vor %s',
    s: '%dS',
    m: '1Min',
    mm: '%dMin',
    h: '1Std',
    hh: '%dStd',
    d: '1T',
    dd: '%dT',
    M: '1M',
    MM: '%dM',
    y: '1J',
    yy: '%dJ',
  },
});
