import { useMutation, useReactiveVar } from '@apollo/client';
import { GENERATE_CLAIM_NUMBER } from 'pages/claims/template/components/ClaimsOverview/graphql/mutations';
import { useState } from 'react';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import { TableCellButton } from './TableCellButton';

interface TableCellClaimNumberProps {
  data: unknown;
  getClaimDetails: (data: any) => {
    id: string;
    claimNumber: string | null;
  };
}

export const TableCellClaimNumber = ({
  data,
  getClaimDetails,
}: TableCellClaimNumberProps) => {
  const [newClaimNumber, setNewClaimNumber] = useState<string | undefined>();

  const alertBannersState = useReactiveVar(alertBanners);

  const [generateClaimNumber] = useMutation(GENERATE_CLAIM_NUMBER, {
    onCompleted: (res) => {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message: 'Claim number successfully generated.',
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });

      setNewClaimNumber(res.generateClaimNumber);
    },
    onError: () => {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: 'Failed to generate claim number. Please try again.',
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
  });

  const { id, claimNumber } = getClaimDetails(data);

  if (claimNumber) return <span>{claimNumber}</span>;
  if (newClaimNumber) return <span>{newClaimNumber}</span>;

  return (
    <TableCellButton
      data={{ id }}
      getButtonText={() => 'Generate claim number'}
      onClick={() => generateClaimNumber({ variables: { claimId: id } })}
    />
  );
};
