import classNames from 'classnames';

export const checklistInfoWrapper = classNames(
  'flex',
  'text-sm',
  'items-center',
  'space-x-1'
);

export const checklistWrapper = classNames(
  'flex-col',
  'flex-wrap',
  'space-y-3',
  'w-full'
);

export const titleCheck = classNames('text-gray-900');
export const titleCross = classNames('text-gray-600');

export const checkIcon = classNames('w-5', 'h-5', 'text-indigo-500');
export const crossIcon = classNames('w-5', 'h-5', 'text-gray-600');
