import { useTranslation } from '@getpopsure/i18n-react';
import { Popover } from '@headlessui/react';
import {
  DotsVerticalIcon,
  EyeOffIcon,
  PaperClipIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import Card from 'components/Card';
import CardSectionCollapsible from 'components/Card/CardSectionCollapsible/CardSectionCollapsible';
import { SelectMenuOption } from 'components/SelectMenu';
import { DocumentNode } from 'graphql';
import { createElement } from 'react';

import { ArchiveButton } from './components/ArchiveButton';
import { DeleteButton } from './components/DeleteButton';
import { DetailsButton } from './components/DetailsButton';
import { DownloadAllButton } from './components/DownloadAllButton';
import { DownloadButton } from './components/DownloadButton';
import { OpenButton } from './components/OpenButton';
import { RegenerateButton } from './components/RegenerateButton';
import { RestoreButton } from './components/RestoreButton';
import * as styles from './styles';
import { getFileNameSortedDocuments } from './utils/getFileNameSortedDocuments';

export interface MappedDocumentData {
  id: string;
  fileName: string;
  friendlyName?: string;
  blobName?: string;
  url?: string;
  resizedUrl?: string;
  category?: string;
  isVisibleToCustomer?: boolean;
  isVisibleToHr?: boolean;
  isVisibleToProvider?: boolean;
  archivedAt?: string;
  internalNotes?: string;
  detailsUrl?: string;
  canDelete?: boolean;
  canRegenerate?: boolean;
  canEdit?: boolean;
  canArchive?: boolean;
  type?: string;
}

export interface DocumentSection {
  title: string;
  defaultOpen?: boolean;
  documents: MappedDocumentData[];
}

export interface EditButtonProps {
  document: MappedDocumentData & { url: string };
  refetchQuery?: DocumentNode;
  mutation: DocumentNode;
  documentVisibilityOptions?: SelectMenuOption[];
  documentCategories: SelectMenuOption[];
  displayInternalNotes?: boolean;
}

export interface DocumentsSectionProps {
  sections: DocumentSection[];
  deleteMutation?: DocumentNode;
  archiveMutation?: DocumentNode;
  editMutation?: DocumentNode;
  restoreMutation?: DocumentNode;
  regenerateMutation?: DocumentNode;
  downloadAllMutation?: DocumentNode;
  getResourceQuery?: DocumentNode;
  resourceId?: string;
  uploadButton?: React.ReactNode;
  editButton?: React.FC<EditButtonProps>;
  documentVisibilityOptions?: SelectMenuOption[];
  documentCategories?: SelectMenuOption[];
  displayInternalNotes?: boolean;
  getDocumentArchiveQuery?: DocumentNode;
}

export const DocumentsSection = ({
  sections,
  deleteMutation,
  archiveMutation,
  restoreMutation,
  regenerateMutation,
  downloadAllMutation,
  getResourceQuery,
  resourceId,
  uploadButton,
  editButton,
  editMutation,
  documentVisibilityOptions,
  documentCategories,
  displayInternalNotes = true,
  getDocumentArchiveQuery,
}: DocumentsSectionProps) => {
  const { t } = useTranslation();
  const hasDocuments = sections.some(({ documents }) => documents.length);

  return (
    <Card
      title={t('admin.documents.title', 'Documents')}
      boldTitle={false}
      actionButton={
        <div className="flex space-x-2">
          {uploadButton}
          {downloadAllMutation &&
            getDocumentArchiveQuery &&
            resourceId &&
            hasDocuments && (
              <DownloadAllButton
                mutation={downloadAllMutation}
                resourceId={resourceId}
                query={getDocumentArchiveQuery}
              />
            )}
        </div>
      }
    >
      {hasDocuments ? (
        sections.map((section) => {
          return (
            <CardSectionCollapsible
              title={section.title}
              defaultOpen={
                !section.documents.length ? false : section.defaultOpen
              }
              key={section.title}
            >
              {!section.documents.length && (
                <p className="text-sm text-gray-600">
                  {t(
                    'component.documentsCard.noDocuments',
                    'No documents uploaded yet.'
                  )}
                </p>
              )}
              {getFileNameSortedDocuments(section.documents).map((document) => {
                const { url } = document;
                const displayName = document.friendlyName ?? document.fileName;
                const truncatedFileName =
                  displayName.length > 30
                    ? `${displayName.slice(0, 30)}...`
                    : displayName;

                return (
                  <div
                    className={classNames(styles.documentCard, {
                      '!bg-gray-200': !url,
                    })}
                  >
                    <a
                      className={classNames(styles.downloadLink, {
                        'no-pointer-events': !url,
                      })}
                      href={document.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className={styles.fileNameSection}>
                        <div className="flex items-center">
                          <div className={styles.fileIconContainer}>
                            {document.isVisibleToCustomer === false ? (
                              <EyeOffIcon
                                className={classNames(styles.fileIcon, {
                                  'group-hover:text-gray-500': !url,
                                })}
                              />
                            ) : (
                              <PaperClipIcon
                                className={classNames(styles.fileIcon, {
                                  'group-hover:text-gray-500': !url,
                                })}
                              />
                            )}
                          </div>
                          <p
                            className={classNames(styles.fileName, {
                              '!text-gray-600 group-hover:text-gray-600': !url,
                            })}
                          >
                            {truncatedFileName}
                          </p>
                        </div>
                      </div>
                      {displayInternalNotes && document.internalNotes && (
                        <p className={styles.note}>{document.internalNotes}</p>
                      )}
                    </a>
                    <Popover className="relative">
                      <Popover.Button className={styles.dotIconButton}>
                        <DotsVerticalIcon className={styles.dotIcon} />
                      </Popover.Button>
                      <Popover.Panel
                        className={styles.dropdown}
                        unmount={false}
                      >
                        {({ close }) => (
                          <>
                            {document.canEdit &&
                              editButton &&
                              url &&
                              editMutation &&
                              documentCategories &&
                              createElement(editButton, {
                                document: { ...document, url },
                                refetchQuery: getResourceQuery,
                                mutation: editMutation,
                                documentVisibilityOptions,
                                documentCategories,
                                displayInternalNotes,
                              })}
                            {document.detailsUrl && (
                              <DetailsButton detailsUrl={document.detailsUrl} />
                            )}
                            {document.url &&
                              (document.resizedUrl ? (
                                <OpenButton url={document.resizedUrl} />
                              ) : (
                                <DownloadButton
                                  url={document.resizedUrl ?? document.url}
                                  fileName={document.fileName}
                                />
                              ))}
                            {document.canRegenerate &&
                              regenerateMutation &&
                              document.category &&
                              resourceId && (
                                <RegenerateButton
                                  mutation={regenerateMutation}
                                  documentCategory={document.category}
                                  resourceId={resourceId}
                                />
                              )}
                            {document.canArchive &&
                              archiveMutation &&
                              restoreMutation &&
                              (document.archivedAt ? (
                                <RestoreButton
                                  mutation={restoreMutation}
                                  documentId={document.id}
                                  getResourceQuery={getResourceQuery}
                                />
                              ) : (
                                <ArchiveButton
                                  documentId={document.id}
                                  mutation={archiveMutation}
                                  getResourceQuery={getResourceQuery}
                                />
                              ))}
                            {document.canDelete && deleteMutation && (
                              <DeleteButton
                                mutation={deleteMutation}
                                documentId={document.id}
                                documentName={
                                  document.friendlyName ?? document.fileName
                                }
                                getResourceQuery={getResourceQuery}
                                additionalVariables={{
                                  resourceId,
                                  type: document.type,
                                  blobName: document.blobName,
                                }}
                              />
                            )}
                          </>
                        )}
                      </Popover.Panel>
                    </Popover>
                  </div>
                );
              })}
            </CardSectionCollapsible>
          );
        })
      ) : (
        <div className="flex justify-center border-t border-gray-200 px-[24px] py-[32px]">
          <p className="text-sm text-gray-600">
            {t(
              'component.documentsCard.noDocuments',
              'No documents uploaded yet.'
            )}
          </p>
        </div>
      )}
    </Card>
  );
};
