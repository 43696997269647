import { gql } from '@apollo/client';

export const UPDATE_LEGAL_CLAIM = gql`
  mutation UpdateLegalClaim(
    $claimId: String!
    $claimNumber: String
    $claimType: [String]
    $eventDescription: String
    $expectedOutcome: String
    $contacts: String
    $preferredTimeDate: String
  ) {
    updateLegalClaim(
      claimId: $claimId
      claimNumber: $claimNumber
      claimType: $claimType
      eventDescription: $eventDescription
      expectedOutcome: $expectedOutcome
      contacts: $contacts
      preferredTimeDate: $preferredTimeDate
    ) {
      id
      claimNumber
      claimType {
        id
        type
      }
      eventDescription
      expectedOutcome
      contacts
      preferredTimeDate
    }
  }
`;
