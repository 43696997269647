import { gql } from '@apollo/client';

export const GET_ACTIVITY_LOG = gql`
  query GetActivityLog(
    $where: [ActivityLogWhere!]!
    $pageSize: Float
    $beforeEvent: String
  ) {
    activityLog(where: $where, pageSize: $pageSize, beforeEvent: $beforeEvent) {
      eventId
      timestamp
      author
      resource
    }
  }
`;

export const GET_CLAIM_ACTIVITY = gql`
  query GetRecentClaimActivity(
    $claimId: String!
    $userId: String!
    $pageSize: Float
  ) {
    activityLog(
      pageSize: $pageSize
      where: [
        { table: "user_claims", field: "id", value: $claimId }
        {
          table: "user_claim_submissions"
          field: "user_claim_id"
          value: $claimId
          returnFields: ["error_details", "submission_details"]
        }
        { table: "user", field: "id", value: $userId }
      ]
    ) {
      eventId
      timestamp
      author
      resource
    }
  }
`;

export const GET_POLICY_ACTIVITY = gql`
  query GetRecentPolicyActivity(
    $policyId: String!
    $userId: String!
    # $questionnaireId: String!
    $pageSize: Float
  ) {
    activityLog(
      pageSize: $pageSize
      where: [
        { table: "user_policies", field: "id", value: $policyId }
        { table: "user", field: "id", value: $userId }
        # { table: "questionnaires", field: "id", value: $questionnaireId }
      ]
    ) {
      eventId
      timestamp
      author
      resource
    }
  }
`;
