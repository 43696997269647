import { useTranslation } from '@getpopsure/i18n-react';
import useQueryParams from 'hooks/useQueryParams';
import { useHistory } from 'react-router';

import ArrowButton from './ArrowButton';
import PageButton from './PageButton';
import PageButtonSeparator from './PageButtonSeparator';
import { pagination, paginationButtons, paginationText } from './styles';

interface PaginationProps {
  totalItems: number;
  limitItems: number;
}

const Pagination = ({ totalItems, limitItems }: PaginationProps) => {
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const history = useHistory();

  const page = queryParams.get('page') ? Number(queryParams.get('page')) : 1;

  const handlePageButtonClick = (newPage: number) => {
    queryParams.set('page', String(newPage));
    history.push(`?${queryParams.toString()}`);
  };

  const numberPages =
    Math.ceil(totalItems / limitItems) === 0
      ? 1
      : Math.ceil(totalItems / limitItems);

  const handleArrowButtonClick = (next: boolean) => {
    let newCurrentPage;
    if (next) {
      if (page + 1 >= numberPages) {
        newCurrentPage = numberPages;
      } else {
        newCurrentPage = page + 1;
      }
    } else if (page - 1 <= 1) {
      newCurrentPage = 1;
    } else {
      newCurrentPage = page - 1;
    }
    handlePageButtonClick(newCurrentPage);
  };

  const getItemsIntervalStart = (): number => {
    if (totalItems === 0) {
      return 0;
    }
    return (page - 1) * limitItems + 1;
  };

  const getItemsIntervalEnd = (): number | null => {
    if (totalItems !== 0) {
      if (page * limitItems >= totalItems) {
        return totalItems;
      }
      return page * limitItems;
    }

    return null;
  };

  const intervalStart = getItemsIntervalStart();
  const withHyphen = totalItems !== 0 ? ' - ' : ' ';
  const intervalEnd = getItemsIntervalEnd();

  return (
    <div className={pagination}>
      <div>
        <p className={paginationText}>
          {t(
            'admin.policytable.pagination.description',
            '{{intervalStart}} {{withHyphen}} {{intervalEnd}} of {{totalItems}}',
            { intervalStart, withHyphen, intervalEnd, totalItems }
          )}
        </p>
      </div>
      {totalItems !== 0 && (
        <div>
          <nav className={paginationButtons} aria-label="Pagination">
            <ArrowButton
              next={false}
              handleClick={handleArrowButtonClick}
              disabled={page === 1}
              dataTestId="previous-arrow-button"
            />
            <PageButton
              current={false}
              handleClick={handlePageButtonClick}
              pageNumber={1}
              isVisible={numberPages > 3 && page - 1 > 1}
            />
            <PageButtonSeparator isVisible={numberPages > 4 && page - 2 > 1} />
            <PageButton
              current={page === 1}
              handleClick={handlePageButtonClick}
              isVisible={true}
              pageNumber={
                page === 1 || numberPages < 4
                  ? 1
                  : page === numberPages
                  ? page - 2
                  : page - 1
              }
            />
            <PageButton
              current={
                page !== 1 && (page !== numberPages || numberPages === 2)
              }
              isVisible={numberPages > 1}
              handleClick={handlePageButtonClick}
              pageNumber={
                page === 1 || numberPages < 4
                  ? 2
                  : page === numberPages
                  ? page - 1
                  : page
              }
            />
            <PageButton
              isVisible={numberPages > 2}
              current={page === numberPages}
              handleClick={handlePageButtonClick}
              pageNumber={
                page === 1 ? 3 : page === numberPages ? page : page + 1
              }
            />
            <PageButtonSeparator
              isVisible={numberPages > 4 && page < numberPages - 2}
            />
            <PageButton
              isVisible={numberPages > 3 && page < numberPages - 1}
              current={false}
              handleClick={handlePageButtonClick}
              pageNumber={numberPages}
            />
            <ArrowButton
              next={true}
              handleClick={handleArrowButtonClick}
              disabled={page === numberPages}
              dataTestId="next-arrow-button"
            />
          </nav>
        </div>
      )}
    </div>
  );
};

export default Pagination;
