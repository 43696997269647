import { useTranslation } from '@getpopsure/i18n-react';
import Page404 from 'pages/errors/404';
import { useParams } from 'react-router';
import { Country } from 'shared/countries/models';

import { getTemplateHRDataByInsuranceType } from '../../data';
import { getTemplateInsuranceTypeCountry } from '../../models/InsuranceTypes';
import {
  getDbHRInsuranceType,
  isValidHRTemplateInsurancePath,
} from '../../utils';
import PolicyDetailsTemplate from '../PolicyDetailsHRTemplate';

const PolicyDetailsTemplateGuard = () => {
  const { insuranceType, id, country, company } = useParams<{
    insuranceType: string;
    company: string;
    id: string;
    country: Country;
  }>();

  const { t } = useTranslation();

  if (!isValidHRTemplateInsurancePath(insuranceType)) {
    return <Page404 />;
  }

  const dbInsuranceType = getDbHRInsuranceType(insuranceType);

  if (!getTemplateInsuranceTypeCountry()[dbInsuranceType].includes(country)) {
    return <Page404 />;
  }

  const templateData = getTemplateHRDataByInsuranceType(t, company)[
    dbInsuranceType
  ];

  const policyQuery = templateData.queries.policy;

  if (policyQuery) {
    return (
      <PolicyDetailsTemplate
        insuranceType={dbInsuranceType}
        templateData={{
          ...templateData,
          queries: { ...templateData.queries, policy: policyQuery },
        }}
        policyId={id}
      />
    );
  }

  return <Page404 />;
};

export default PolicyDetailsTemplateGuard;
