import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import { HeaderConfig } from 'components/TableRefactor/types';
import { Country } from 'shared/countries/models';

export const getTableHeaders = (currentRegion?: Country): HeaderConfig[] => [
  {
    id: 'first-name',
    label: 'First name',
    width: 'w-none',
    minWidth: 'min-w-[116px]',
    sticky: true,
    threshold: 'left-0',
    border: 'none',
    component: SortableColumnHeader,
  },
  {
    id: 'last-name',
    label: 'Last name',
    width: 'w-none',
    minWidth: 'min-w-[116px]',
    sticky: true,
    threshold: 'left-[116px]',
    border: 'none',
    component: SortableColumnHeader,
  },
  {
    id: 'status',
    label: 'Status',
    width: 'w-none',
    minWidth: 'min-w-[120px]',
    sticky: true,
    threshold: 'left-[232px]',
    border: 'right',
    component: SortableColumnHeader,
  },
  {
    id: 'start-date',
    label: 'Start date',
    width: 'w-[116px]',
    minWidth: 'min-w-[116px]',
    component: SortableColumnHeader,
  },
  {
    id: 'policy-number',
    label: 'Policy number',
    width: 'w-[148px]',
    minWidth: 'min-w-[148px]',
    component: SortableColumnHeader,
  },
  {
    id: 'created-at',
    label: 'Created on',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: SortableColumnHeader,
  },
  {
    id: 'source',
    label: 'Source',
    width: 'w-[148px]',
    minWidth: 'min-w-[148px]',
    component: ColumnHeader,
  },
  ...(currentRegion === 'eu'
    ? [
        {
          id: 'region',
          label: 'Region',
          width: 'w-[80px]',
          minWidth: 'min-w-[80px]',
          component: ColumnHeader,
        },
      ]
    : []),
];
