import classNames from 'classnames';

export const section = classNames(
  'mt-[16px]',
  'flex',
  'flex-col',
  'space-y-2',
  'm-[5px]'
);

export const label = classNames('text-sm', 'font-bold', 'text-gray-900');

export const errorText = classNames('text-sm', 'text-red-400');

export const radioButtons = classNames(
  'flex',
  'items-center',
  'space-x-[32px]'
);

export const rolesWrapper = classNames(
  'transition-max-height',
  'ease-out',
  'duration-500'
);
