import classNames from 'classnames';

export const textContainer = classNames(
  'flex',
  'p-6',
  'flex-col',
  'align-end',
  'text-sm',
  'text-gray-900'
);

export const textContent = classNames(
  'rounded-[8px]',
  'p-6',
  'bg-gray-50',
  'flex-col',
  'align-end',
  'text-sm',
  'text-gray-900',
  'leading-loose',
  'max-h-fit'
);

export const textContentCollapsed = classNames(
  'rounded-[8px]',
  'p-6',
  'bg-gray-50',
  'text-sm',
  'text-gray-900',
  'leading-loose',
  'max-h-[118px]',
  'overflow-hidden'
);

export const link = classNames(
  'text-indigo-500',
  'hover:text-indigo-600',
  'disabled:text-indigo-200',
  'text-sm',
  'space-x-2',
  'leading-5',
  'h-[20px]',
  'group',
  'font-bold'
);

export const input = classNames('w-[264px]', '!px-[12px]', '!py-[9px]');
