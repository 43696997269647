import { ApolloError, useMutation, useReactiveVar } from '@apollo/client';
import Button from 'components/Button';
import Input from 'components/Input';
import Modal from 'components/Modal';
import { useState } from 'react';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import { CREATE_DATA_REMOVAL_REQUEST } from '../graphql/createRequest';
import { GET_DATA_REMOVAL_REQUESTS } from '../graphql/requests';

const CreateDataRemovalRequest = () => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const alertBannersState = useReactiveVar(alertBanners);

  const [createRequest, { loading }] = useMutation(
    CREATE_DATA_REMOVAL_REQUEST,
    {
      refetchQueries: [GET_DATA_REMOVAL_REQUESTS],
      errorPolicy: 'none',
      onCompleted: () => {
        setOpen(false);

        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'SUCCESS',
          message: 'Data removal has been requested.',
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
      onError: (apolloError: ApolloError) => {
        if (
          apolloError.graphQLErrors &&
          apolloError.graphQLErrors.some(
            (graphQLError) =>
              graphQLError.extensions.code === 'RESOURCE_NOT_FOUND'
          )
        ) {
          setError(true);
          return;
        }
        setOpen(false);

        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'WARNING',
          message: 'Something went wrong. Please try again.',
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
    }
  );
  return (
    <>
      <Button buttonType="primary" onClick={() => setOpen(true)}>
        New request
      </Button>
      <Modal
        title="Request data removal"
        open={open}
        setOpen={setOpen}
        textLoading="Requesting..."
        confirmButtonLabel="Request"
        loading={loading}
        handleConfirm={() => createRequest({ variables: { email } })}
      >
        <>
          <p className="text-sm text-gray-600">
            Select the customer account you want to delete.
          </p>
          <div className="mt-4">
            <label className="text-sm font-bold text-gray-900" htmlFor="email">
              Customer
            </label>
            <Input
              id="email"
              color="gray"
              className="mt-2"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError(false);
              }}
              placeholder="Enter email of Feather account holder"
              errorMessage="There is no customer account with this email. Please use an existing account."
              error={error}
            />
          </div>
          <p className="mt-6 text-sm text-gray-600">
            Data removal request <b>cannot be withdrawn</b>.
          </p>
        </>
      </Modal>
    </>
  );
};

export default CreateDataRemovalRequest;
