import { useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import FileDropzone from 'components/FileDropzone';
import Input from 'components/Input';
import Modal from 'components/Modal';
import RadioButton from 'components/RadioButton';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import TextArea from 'components/TextArea';
import { ChangeEvent, useEffect, useState } from 'react';

import * as styles from './styles';

interface CreateIssueFormProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  issueTypeOptions: SelectMenuOption[];
  createIssue: (variables: {
    category: string;
    description: string;
    type?: string;
    file?: File;
    newFilename?: string;
  }) => void;
  loading: boolean;
  providerView: boolean;
}

const CreateIssueForm = ({
  open,
  setOpen,
  issueTypeOptions,
  createIssue,
  loading,
  providerView,
}: CreateIssueFormProps) => {
  const { t } = useTranslation();

  const [description, setDescription] = useState('');
  const [selectedType, setSelectedType] = useState<
    SelectMenuOption | undefined
  >(undefined);

  const [uploadDocument, setUploadDocument] = useState<boolean | undefined>(
    false
  );
  const [documentName, setDocumentName] = useState('');
  const [fileSelected, setFileSelected] = useState<File | undefined>(undefined);

  const [isCustomerInformed, setIsCustomerInformed] = useState<
    boolean | undefined
  >(undefined);

  const [documentNameError, setDocumentNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [selectedTypeError, setSelectedTypeError] = useState(false);
  const [isCustomerInformedError, setIsCustomerInformedError] = useState(false);

  const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const handleUploadDocumentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUploadDocument(e.target.value === 'yes');
  };

  const handleChangeDocumentName = (e: ChangeEvent<HTMLInputElement>) => {
    setDocumentName(e.target.value);
  };

  const handleIsCustomerInformedChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsCustomerInformed(e.target.value === 'yes');
  };

  useEffect(() => {
    setDocumentNameError(false);
    setDescriptionError(false);
    setSelectedTypeError(false);
  }, [documentName, selectedType, description]);

  useEffect(() => {
    if (isCustomerInformed !== undefined) {
      setIsCustomerInformedError(false);
    }
  }, [isCustomerInformed]);

  const handleConfirm = () => {
    if (description === '') {
      setDescriptionError(true);
    }

    if (!selectedType) {
      setSelectedTypeError(true);
    }

    if (providerView && isCustomerInformed === undefined) {
      setIsCustomerInformedError(true);
      return;
    }

    if (!uploadDocument && selectedType && description !== '') {
      createIssue({
        description,
        category: selectedType.id,
        type: 'OTHER',
        file: fileSelected,
        newFilename: documentName,
        ...(providerView && {
          isCustomerInformed,
        }),
      });
      return;
    }

    if (!uploadDocument) return;

    if (documentName === '') {
      setDocumentNameError(true);
    }

    if (
      uploadDocument &&
      selectedType &&
      description !== '' &&
      documentName !== '' &&
      fileSelected
    ) {
      createIssue({
        description,
        category: selectedType.id,
        type: 'OTHER',
        file: fileSelected,
        newFilename: documentName,
        ...(providerView && {
          isCustomerInformed,
        }),
      });
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={t('admin.activity.newissue.modal.header', 'Create issue')}
      handleConfirm={handleConfirm}
      confirmButtonLabel={t(
        'admin.activity.newissue.modal.create.label',
        'Create issue'
      )}
      loading={loading}
      textLoading={t(
        'admin.activity.newissue.modal.loading.label',
        'Creating issue...'
      )}
    >
      <div>
        <div className={styles.section}>
          <SelectMenu
            selected={selectedType}
            setSelected={setSelectedType}
            placeholder={t(
              'admin.activity.newissue.modal.type.placeholder',
              'Select issue type'
            )}
            options={issueTypeOptions}
            label={t('admin.activity.newissue.modal.type.header', 'Issue type')}
            color="gray"
            error={selectedTypeError}
            errorMessage="The issue type is required."
          />
        </div>
        <div className={styles.section}>
          <h1 className={styles.sectionTitle}>
            {t(
              'admin.activity.newissue.modal.description.header',
              'Issue description'
            )}
          </h1>
          <TextArea
            color="gray"
            placeholder={t(
              'admin.activity.newissue.modal.description.placeholder',
              'Start typing here...'
            )}
            required
            value={description}
            onChange={handleDescriptionChange}
            error={descriptionError}
            errorMessage="The description is required."
          />
        </div>
        {providerView && (
          <div className={styles.section}>
            <h1 className={styles.sectionTitle}>
              {t(
                'admin.activity.newissue.modal.inform.title',
                'Did you inform the customer?'
              )}
            </h1>
            <div className="flex flex-col">
              <div className={styles.radioButtons}>
                <RadioButton
                  id="yes"
                  name="isCustomerInformed"
                  value="yes"
                  required
                  checked={!!isCustomerInformed}
                  onChange={handleIsCustomerInformedChange}
                  error={isCustomerInformedError}
                >
                  {t('admin.activity.newissue.modal.inform.yes.label', 'Yes')}
                </RadioButton>
                <RadioButton
                  id="no"
                  name="isCustomerInformed"
                  value="no"
                  required
                  checked={
                    !isCustomerInformed && isCustomerInformed !== undefined
                  }
                  onChange={handleIsCustomerInformedChange}
                  error={isCustomerInformedError}
                >
                  {t('admin.activity.newissue.modal.inform.no.label', 'No')}
                </RadioButton>
              </div>
              {isCustomerInformedError && (
                <span className="mt-1 text-xs text-red-400">
                  {t(
                    'admin.activity.newissue.modal.inform.error.label',
                    'Please select an option.'
                  )}
                </span>
              )}
            </div>
          </div>
        )}
        {!providerView && (
          <div
            className={classNames(styles.formWrapper, {
              'max-h-[80px]': !uploadDocument,
              'max-h-[350px]': uploadDocument,
            })}
          >
            <div className={styles.section}>
              <h1 className={styles.sectionTitle}>
                {t(
                  'admin.activity.resolveissue.modal.uploaddoc.header',
                  'Do you want to upload a document?'
                )}
              </h1>
              <div className={styles.radioButtons}>
                <RadioButton
                  id="yes"
                  name="uploadDocument"
                  value="yes"
                  checked={!!uploadDocument}
                  onChange={handleUploadDocumentChange}
                >
                  {t('admin.activity.resolveissue.modal.uploaddoc.yes', 'Yes')}
                </RadioButton>
                <RadioButton
                  id="no"
                  name="uploadDocument"
                  value="no"
                  checked={!uploadDocument && uploadDocument !== undefined}
                  onChange={handleUploadDocumentChange}
                >
                  {t('admin.activity.resolveissue.modal.uploaddoc.no', 'No')}
                </RadioButton>
              </div>
              {isCustomerInformedError && (
                <span className="mt-1 text-xs text-red-400">
                  {t(
                    'admin.provider.policy.public.modal.changestatus.inform.error.label',
                    'Please select an option.'
                  )}
                </span>
              )}
            </div>

            <>
              <div className={styles.section}>
                <label htmlFor="documentName" className={styles.label}>
                  {t(
                    'admin.activity.resolveissue.modal.uploaddoc.name.header',
                    'Document name'
                  )}
                </label>
                <Input
                  id="documentName"
                  type="text"
                  required={true}
                  placeholder={t(
                    'admin.activity.resolveissue.modal.uploaddoc.name.placeholder',
                    'Your document name'
                  )}
                  onChange={handleChangeDocumentName}
                  className={styles.input}
                  color="gray"
                  error={documentNameError}
                  errorMessage="The document name is required."
                />
              </div>
              <div className={styles.section}>
                <FileDropzone
                  uploadedFile={fileSelected}
                  onFileSelect={setFileSelected}
                />
              </div>
            </>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CreateIssueForm;
