import classNames from 'classnames';

export const cardListRow = classNames(
  'px-[24px]',
  'py-[20px]',
  'border-t',
  'border-gray-200',
  'flex',
  'items-start'
);

export const cardListMultipleRows = classNames('flex-col', '!items-start');

export const cardListChecklistRows = classNames('!py-[20px]', '!items-start');

export const cardListRowEdit = classNames(
  'px-[24px]',
  'py-[11px]',
  'border-t',
  'border-gray-200',
  'flex',
  'items-center'
);

export const rowLabel = classNames(
  'text-sm',
  'text-gray-600',
  'min-w-[180px]',
  'w-[180px]',
  'mr-[16px]'
);
export const rowLabelMultipleLine = classNames(
  'text-sm',
  'text-gray-600',
  'w-[180px]',
  'font-bold'
);

export const rowLabelExpandable = classNames('self-start');

export const secondaryButton = classNames(
  'bg-gray-50',
  'rounded-[6px]',
  'w-[88px]',
  'py-[9px]',
  'text-indigo-500',
  'text-sm',
  'hover:bg-indigo-200',
  'focus:bg-indigo-200',
  'focus:ring-2',
  'focus:ring-offset-2',
  'focus:ring-indigo-500'
);

export const primaryButton = classNames(
  'bg-indigo-500',
  'rounded-[6px]',
  'w-[88px]',
  'py-[9px]',
  'text-white',
  'text-sm',
  'hover:bg-indigo-600',
  'focus:bg-indigo-600',
  'focus:ring-2',
  'focus:ring-offset-2',
  'focus:ring-indigo-500',
  'ml-[8px]'
);

export const errorMessage = classNames(
  'text-sm',
  'text-red-600',
  'self-end',
  'px-[24px]',
  'pb-[20px]'
);

export const sectionRowTitle = classNames(
  'px-[24px]',
  'py-[20px]',
  'pt-[60px]',
  'flex',
  'justify-between',
  'border-t',
  'border-gray-200'
);

export const title = classNames('text-lg', 'text-gray-900', 'font-bold');

export const noDataContainer = classNames(
  'flex',
  'justify-center',
  'border-t',
  'border-t-gray-200',
  'px-[24px]',
  'py-[32px]'
);

export const loader = classNames('animate-spin');
