import { ApolloError } from '@apollo/client';
import OverviewPage from 'components/OverviewPage';
import { SelectButtonOption } from 'components/SelectButton';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import Table from 'components/TableRefactor/Table';
import { HeaderConfig, RowConfig } from 'components/TableRefactor/types';
import { startCase } from 'lodash';
import { isGenericInsuranceType } from 'pages/policies/generic/models/GenericInsuranceType';
import { useState } from 'react';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';
import { policyRouteMapper } from 'shared/insurances/policyRouteMapper';

import { GET_QUESTIONNAIRES } from './graphql/questionnaires';
import { Questionnaire } from './models/questionnaire';
import { questionnaireTypes } from './models/questionnaireType';

const QuestionnairesPage = () => {
  const [error, setError] = useState<ApolloError | undefined>(undefined);

  const rowConfig: RowConfig = {
    getResourceLink: (data: Questionnaire) => {
      if (
        data.userPolicy &&
        data.userPolicy.insuranceType &&
        !isGenericInsuranceType(data.userPolicy.insuranceType)
      ) {
        return `${policyRouteMapper[data.userPolicy.insuranceType](
          data.userPolicy.id
        )}#questionnaire`;
      }
      return generatePath(paths.questionnaires.details.path, {
        id: data.id,
      });
    },
    cells: [
      {
        type: 'TEXT',
        textColor: 'dark',
        props: {
          getText: (data: Questionnaire) => data.user?.firstName ?? '',
        },
      },
      {
        type: 'TEXT',
        textColor: 'dark',
        props: {
          getText: (data: Questionnaire) => data.user?.lastName ?? '',
        },
      },
      {
        type: 'TEXT',
        textColor: 'light',
        props: {
          getText: (data: Questionnaire) => data.questionnaireType,
        },
      },
      {
        type: 'CHECK',
        textColor: 'light',
        props: {
          getCheck: (data: Questionnaire) => ({
            check: data.userPolicy ? 'check' : 'cross',
          }),
        },
      },
      {
        type: 'DATE',
        textColor: 'light',
        props: {
          getDate: (data: Questionnaire) => data.createdAt,
        },
      },
    ],
  };

  const policyExistsOptions: SelectButtonOption[] = [
    {
      title: 'Any',
      label: 'Any',
      id: 'any',
    },
    {
      title: 'Yes',
      label: 'Yes',
      id: 'yes',
    },
    // Blocking out this option because it's too heavy for db
    // Will reactivate when I find a workaround for it
    // {
    //   title: 'No',
    //   label: 'No',
    //   id: 'no',
    // },
  ];

  const typeOptions: SelectButtonOption[] = questionnaireTypes.map((type) => ({
    id: type,
    title: startCase(type),
    label: startCase(type),
  }));

  const tableFilters: FilterConfig[] = [
    {
      options: typeOptions,
      filterType: 'MULTIPLE',
      label: 'Type',
      id: 'questionnaireType',
      placeholder: 'Select type(s)',
    },
    {
      options: policyExistsOptions,
      filterType: 'TEXT',
      label: 'Policy purchased',
      id: 'userPolicyExists',
    },
    {
      filterType: 'DATE_RANGE',
      label: 'Created on',
      id: 'createdAt',
    },
  ];

  const tableHeaders: HeaderConfig[] = [
    {
      id: 'firstName',
      label: 'First name',
      component: ColumnHeader,
    },
    {
      id: 'lastName',
      label: 'Last name',
      component: ColumnHeader,
    },
    {
      id: 'type',
      label: 'Questionnaire type',
      component: ColumnHeader,
    },
    {
      id: 'policy',
      label: 'Policy purchased',
      component: ColumnHeader,
    },
    {
      id: 'createdAt',
      label: 'Created on',
      component: ColumnHeader,
    },
  ];

  return (
    <OverviewPage title="Questionnaires" current="questionnaires" error={error}>
      <Table
        title="Questionnaires"
        query={GET_QUESTIONNAIRES}
        itemsPerPage={12}
        searchPlaceholder="Search by ID, customer name or email"
        headers={tableHeaders}
        rowConfig={rowConfig}
        emptyStateLabel={{
          title: 'There are no questionnaires.',
          description: 'It looks like there are no questionnaires yet.',
        }}
        filterConfig={tableFilters}
        onError={setError}
      />
    </OverviewPage>
  );
};

export default QuestionnairesPage;
