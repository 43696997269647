import {
  liabilityClaimStatuses,
  liabilityClaimTypes,
} from 'shared/claims/liability/models';
import { z } from 'zod';

export const zodSubmittableClaimSchema = z.object({
  policyId: z.string().uuid(),
  status: z.enum(liabilityClaimStatuses),
  claimType: z.array(z.enum(liabilityClaimTypes)),
  dateOfEvent: z.string(),
  eventDescription: z.string().optional(),
  amount: z.string().optional(),
  iban: z.string().optional(),
  thirdPartyName: z.string().optional(),
  thirdPartyEmail: z.string().optional(),
  uploadDocument: z.instanceof(File),
});
