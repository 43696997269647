import { EditComponentDefaultProps } from 'components/InformationCardV2/models';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';

interface InformationCardDropdownProps extends EditComponentDefaultProps {
  options: SelectMenuOption[];
  onChange?: (value: string) => void;
  placeholder?: string;
  value?: string;
}

export const InformationCardDropdown = ({
  shouldDisplayEditField = false,
  options,
  placeholder,
  onChange,
  value,
  error,
}: InformationCardDropdownProps) => {
  const selected = options.find((option) => option.id === value);

  if (shouldDisplayEditField) {
    return (
      <SelectMenu
        className="w-[264px] bg-gray-200"
        selected={selected}
        options={options}
        setSelected={(selectedOption: SelectMenuOption) =>
          onChange?.(selectedOption.id)
        }
        placeholder={placeholder ?? ''}
        error={Boolean(error)}
        errorMessage={error}
      />
    );
  }

  return <span className="text-sm text-gray-900">{selected?.label ?? ''}</span>;
};
