import {
  DocumentSection,
  DocumentsSection,
  MappedDocumentData,
} from 'components/DocumentsSection/DocumentsSection';
import { DocumentNode } from 'graphql';
import { ClaimDocuments, ClaimFileType } from 'models/Claim';
import { Document } from 'models/Document';
import { retrieveFileFriendlyName } from 'shared/utils/retrieveFileFriendlyName';

import { EditButton } from './components/EditButton/EditButton';
import { UploadButton } from './components/UploadButton/UploadButton';
import { categoryOptions } from './data';
import {
  ARCHIVE_CLAIM_DOCUMENT,
  DELETE_CLAIM_DOCUMENT,
  UNARCHIVE_CLAIM_DOCUMENT,
  UPDATE_CLAIM_DOCUMENT,
} from './graphql/mutations';

interface DocumentsSectionProps {
  claimId: string;
  userId: string;
  documents: ClaimDocuments;
  refetchQuery: DocumentNode;
}

const mapDocuments =
  (type: ClaimFileType) =>
  ({
    url,
    blobName,
    resizedUrl,
    id,
    isVisibleToCustomer,
    internalNotes,
    friendlyName,
    category,
    archivedAt,
  }: Document): MappedDocumentData => {
    const isNotScannedDocument = type !== 'SCANNED_DOCUMENT';
    const isUserClaimDocument =
      type !== 'CUSTOMER_UPLOADS' && type !== 'SCANNED_DOCUMENT';

    return {
      id,
      fileName: retrieveFileFriendlyName(blobName),
      ...(friendlyName && { friendlyName }),
      blobName,

      url,
      resizedUrl,

      type,
      isVisibleToCustomer,
      ...(internalNotes && { internalNotes }),
      ...(category && { category }),
      ...(archivedAt && { archivedAt }),

      canDelete: isNotScannedDocument,
      canEdit: isUserClaimDocument,
      canArchive: isUserClaimDocument,
    };
  };

export const Documents = ({
  claimId,
  userId,
  documents,
  refetchQuery,
}: DocumentsSectionProps) => {
  /**
   * TODO: [KONG] Remove all document sort and mappers to backend
   *
   * This is a temporary solution as backend is missing several pieces
   * of sorting and mapping to accommodate the changes.
   * This logic should all be moved to the backend within field resolvers and refactored.
   */
  const customerUploads = documents.userUploads.map(
    mapDocuments('CUSTOMER_UPLOADS')
  );
  const scannedDocuments = documents.scannedDocuments.map(
    mapDocuments('SCANNED_DOCUMENT')
  );
  const featherUploads = documents.featherUploads.map(
    mapDocuments('FEATHER_UPLOADS')
  );
  const providerUploads = documents.providerUploads.map(
    mapDocuments('PROVIDER_UPLOADS')
  );
  const archivedDocuments = documents.archivedDocuments.map(
    mapDocuments('ARCHIVED')
  );

  const sections: DocumentSection[] = [
    {
      title: 'Feather uploads',
      documents: featherUploads,
    },
    {
      title: 'Provider uploads',
      documents: providerUploads,
    },
    {
      title: 'Customer uploads',
      documents: customerUploads,
    },
    {
      title: 'Scanned documents',
      documents: scannedDocuments,
    },
    {
      title: 'Archived',
      documents: archivedDocuments,
      defaultOpen: false,
    },
  ];

  return (
    <DocumentsSection
      sections={sections}
      deleteMutation={DELETE_CLAIM_DOCUMENT}
      resourceId={claimId}
      getResourceQuery={refetchQuery}
      uploadButton={
        <UploadButton
          claimId={claimId}
          refetchQuery={refetchQuery}
          userId={userId}
        />
      }
      editButton={EditButton}
      documentCategories={categoryOptions}
      editMutation={UPDATE_CLAIM_DOCUMENT}
      archiveMutation={ARCHIVE_CLAIM_DOCUMENT}
      restoreMutation={UNARCHIVE_CLAIM_DOCUMENT}
    />
  );
};
