import {
  legalClaimStatuses,
  legalClaimTypes,
} from 'shared/claims/legal/models';
import { z } from 'zod';

export const zodSubmittableClaimSchema = z.object({
  policyId: z.string().uuid(),
  status: z.enum(legalClaimStatuses),
  claimType: z.array(z.enum(legalClaimTypes)),
  claimNumber: z.string().optional(),
  uploadDocument: z.custom<File>().optional(),
});
