import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { RowConfig } from 'components/TableRefactor/types';
import {
  retrieveClaimsDetailsPath,
  statusMapping,
} from 'pages/claims/template/utils/utils';
import { policyMappers } from 'shared/insurances';

import { ExpatHealthClaim } from '../../../models';

export const rowConfig: RowConfig = {
  getResourceLink: (data: ExpatHealthClaim) =>
    retrieveClaimsDetailsPath('expat-health', data.id),
  cells: [
    {
      type: 'TEXT',
      textColor: 'dark',
      props: {
        getText: (data: ExpatHealthClaim) =>
          data.policy.insuredPerson?.firstName ?? data.user.firstName ?? '',
      },
    },
    {
      type: 'TEXT',
      textColor: 'dark',
      props: {
        getText: (data: ExpatHealthClaim) =>
          data.policy.insuredPerson?.lastName ?? data.user.lastName ?? '',
      },
    },
    {
      type: 'STATUS',
      textColor: 'dark',
      props: {
        getStatus: ({ status }: ExpatHealthClaim) =>
          statusMapping(
            status && policyMappers.INCOMING?.claim?.claimStatusMapper
              ? policyMappers.INCOMING.claim.claimStatusMapper[status]
              : '',
            status && policyMappers.INCOMING?.claim?.badgeColorMapper
              ? policyMappers.INCOMING.claim.badgeColorMapper[status]
              : 'blue'
          ),
      },
    },
    {
      type: 'TEXT',
      textColor: 'dark',
      props: {
        getText: (data: ExpatHealthClaim) =>
          data.amount ? englishFormattedEuroCurrency(data.amount) : '',
      },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: {
        getText: (data: ExpatHealthClaim) =>
          data.refundAmount
            ? englishFormattedEuroCurrency(data.refundAmount)
            : '',
      },
    },
    {
      type: 'DATE',
      textColor: 'light',
      props: { getDate: (data: ExpatHealthClaim) => data.createdAt },
    },
    {
      type: 'DATE',
      textColor: 'light',
      props: { getDate: (data: ExpatHealthClaim) => data.featherPaidOn ?? '' },
    },
    {
      type: 'DATE',
      textColor: 'light',
      props: { getDate: (data: ExpatHealthClaim) => data.customerPaidOn ?? '' },
    },
  ],
};
