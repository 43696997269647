import { gql } from '@apollo/client';

export const CREATE_CLAIM_ISSUE = gql`
  mutation CreateClaimIssue(
    $policyOrClaimId: String!
    $category: String!
    $title: String
    $description: String!
    $document: Upload
    $documentName: String
  ) {
    createClaimIssue(
      claimId: $policyOrClaimId
      category: $category
      title: $title
      description: $description
      document: $document
      documentName: $documentName
    ) {
      id
      category
      description
      title
    }
  }
`;

export const RESOLVE_CLAIM_ISSUE = gql`
  mutation ResolveClaimIssue(
    $policyOrClaimId: String!
    $issueId: String!
    $document: Upload
    $documentName: String
  ) {
    resolveClaimIssue(
      claimId: $policyOrClaimId
      issueId: $issueId
      document: $document
      documentName: $documentName
    ) {
      id
    }
  }
`;

export const RE_OPEN_CLAIM_ISSUE = gql`
  mutation ReOpenClaimIssue(
    $policyOrClaimId: String!
    $issueId: String!
    $comment: String!
    $document: Upload
    $documentName: String
  ) {
    reOpenClaimIssue(
      claimId: $policyOrClaimId
      issueId: $issueId
      comment: $comment
      document: $document
      documentName: $documentName
    ) {
      id
    }
  }
`;
