import classNames from 'classnames';

export const link = classNames(
  'text-sm',
  'inline-flex',
  'items-center',
  'space-x-2',
  'leading-5',
  'h-[20px]',
  'group',
  'font-bold'
);

export const icon = classNames('w-[20px]');

export const linkSecondary = classNames('hover:text-gray-800', 'text-gray-600');

export const linkPrimary = classNames(
  'text-indigo-500',
  'hover:text-indigo-600',
  'disabled:text-indigo-200',
  'break-all'
);

export const linkSecondaryDisabled = classNames(
  'text-gray-500',
  'pointer-events-none'
);
export const linkPrimaryDisabled = classNames(
  'text-indigo-200',
  'pointer-events-none'
);

export const iconPrimary = classNames(
  'text-indigo-500',
  'group-hover:text-indigo-600',
  'disabled:text-indigo-200'
);

export const iconPrimaryDisabled = classNames('text-indigo-200');

export const iconGrey = classNames(
  'text-gray-400',
  'group-hover:text-gray-500',
  'disabled:text-gray-200'
);

export const iconGreyDisabled = classNames('text-gray-200');
