import { gql } from '@apollo/client';

export const CREATE_REFERRAL_CAMPAIGN = gql`
  mutation createReferralCampaign($input: CreateReferralCampaignInput!) {
    createReferralCampaign(input: $input) {
      id
      title
      description
      amount
      sourceInsuranceType
      targetInsuranceType
      startDate
      endDate
    }
  }
`;

export const UPDATE_REFERRAL_CAMPAIGN = gql`
  mutation updateReferralCampaign(
    $id: String!
    $input: UpdateReferralCampaignInput!
  ) {
    updateReferralCampaign(id: $id, input: $input) {
      id
      title
      description
      amount
      sourceInsuranceType
      targetInsuranceType
      startDate
      endDate
    }
  }
`;

export const DELETE_REFERRAL_CAMPAIGN = gql`
  mutation deleteReferralCampaign($id: String!) {
    deleteReferralCampaign(id: $id) {
      id
    }
  }
`;
