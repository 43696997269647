export const mapPlan = (plan: string): string => {
  if (!plan) {
    return '';
  }

  switch (plan) {
    case 'BASIC':
    case 'BASIC_LEGACY':
    case 'BASIC_YOUNG_GAP':
    case 'BASIC_YOUNG_NO_GAP':
    case 'BASIC_SENIOR_NO_GAP':
    case 'BASIC_SENIOR_GAP':
      return 'Basic';
    case 'ADVANCED':
    case 'ADVANCED_LEGACY':
    case 'ADVANCED_YOUNG_NO_GAP':
    case 'ADVANCED_YOUNG_GAP':
    case 'ADVANCED_SENIOR_NO_GAP':
    case 'ADVANCED_SENIOR_GAP':
      return 'Advanced';
    case 'PREMIUM':
    case 'PREMIUM_KID':
    case 'PREMIUM_YOUNG':
    case 'PREMIUM_SENIOR':
      return 'Premium';
    default:
      return plan;
  }
};
