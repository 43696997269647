import classNames from 'classnames';

export const columnHeader = classNames(
  'p-[14px]',
  'text-left',
  'text-sm',
  'font-bold',
  'text-gray-600',
  'border-b',
  'border-gray-300',
  'hover:bg-gray-50',
  'cursor-pointer',
  'outline-indigo-transparent',
  'focus:outline-indigo-2',
  'focus:bg-gray-50',
  'focus:rounded-[4px]',
  'transition-[outline-color]',
  'ease-linear',
  'duration-100'
);

export const sticky = classNames('sticky', 'bg-white');

export const borderRight = classNames(
  'border-r',
  'border-gray-300',
  'table-breakpoint:border-r-0'
);

export const borderLeft = classNames(
  'border-l',
  'border-gray-300',
  'table-breakpoint:border-l-0'
);

export const contentWrapper = classNames(
  'flex',
  'justify-between',
  'items-center'
);

export const arrowIcon = classNames('h-[16px]', 'ml-[6px]');

export const hidden = classNames('hidden');

export const noSortArrowIcon = classNames('text-gray-400');

export const sortArrowIcon = classNames('text-indigo-500');
