import classNames from 'classnames';
import Input from 'components/Input';
import Modal from 'components/Modal';
import React, { useState } from 'react';

import { TextInformationProps } from '../TextInformation';
import * as styles from './styles';

const TextWithDetailsInformation = ({
  cell,
  editMode,
  editable,
  value,
  handleChange,
  id,
  className,
  placeholder,
  errorMessage,
  detailsLabel,
  renderModalDetails,
  detailsTitle,
  isModalScrollable,
}: TextInformationProps) => {
  const [details, setDetails] = useState(false);
  return editable && editMode ? (
    <Input
      className={styles.input}
      id={id}
      type="text"
      value={String(value)}
      onChange={handleChange}
      color="gray"
      placeholder={placeholder}
      error={!!errorMessage}
      errorMessage={errorMessage}
    />
  ) : (
    <>
      <span className={classNames(className, styles.rowData)}>
        <span>{String(cell)} </span>
        <button
          type="button"
          className={styles.detailsButton}
          onClick={() => setDetails(true)}
        >
          {detailsLabel ?? 'See details'}
        </button>
      </span>
      <Modal
        open={details}
        setOpen={setDetails}
        title={detailsTitle ?? ''}
        hideActions={true}
        scrollable={isModalScrollable}
      >
        {renderModalDetails?.(setDetails, details) ?? <></>}
      </Modal>
    </>
  );
};

export default TextWithDetailsInformation;
