import {
  dogLiabilityClaimStatuses,
  dogLiabilityClaimTypes,
} from 'shared/claims/dogLiability/models';
import { z } from 'zod';

export const zodSubmittableClaimSchema = z.object({
  policyId: z.string().uuid(),
  status: z.enum(dogLiabilityClaimStatuses),
  claimType: z.array(z.enum(dogLiabilityClaimTypes)),
  eventDescription: z.string().optional(),
  amount: z.string().optional(),
  iban: z.string().optional(),
  thirdPartyName: z.string().optional(),
  thirdPartyEmail: z.string().optional(),
  uploadDocument: z.custom<File>().optional(),
  dateOfEvent: z.string().optional(),
});
