import FileErrorCard from 'components/FileErrorCard';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import { ExpatHealthPolicy } from 'pages/policies/expatHealth/models/ExpatHealthPolicy';
import { generatePath } from 'react-router-dom';
import { paths } from 'routes/definedPaths';
import { InsuranceType } from 'shared/insurances/types';
import { getHealthStatus } from 'shared/mapHealthStatus';

dayjs.extend(relativeTime);

interface DuplicateHealthPoliciesCardProps {
  policy: PublicHealthPolicy | ExpatHealthPolicy;
  insuranceType: string;
}

const DuplicateHealthPoliciesCard = ({
  policy,
  insuranceType,
}: DuplicateHealthPoliciesCardProps) => {
  const mapHealthInsuranceType = (type: string) => {
    switch (type) {
      case 'PUBLIC_HEALTH':
        return 'Public health';
      case 'PRIVATE_HEALTH':
        return 'Private health';
      case 'INCOMING':
        return 'Expat health';
    }
  };

  const getPolicyLink = ({
    insuranceType: policyInsuranceType,
    id,
  }: {
    insuranceType: InsuranceType;
    id: string;
  }) => {
    switch (policyInsuranceType) {
      case 'PUBLIC_HEALTH':
        return generatePath(paths.policies.publicHealth.internal.details.path, {
          id,
        });
      case 'PRIVATE_HEALTH':
        return generatePath(
          paths.policies.privateHealth.internal.details.path,
          {
            id,
          }
        );
      case 'INCOMING':
        return generatePath(paths.policies.expatHealth.internal.details.path, {
          id,
        });
    }
  };

  if (!policy?.user?.duplicateHealthPolicies) return <></>;

  const description = policy.user.duplicateHealthPolicies.flatMap(
    (duplicatePolicy) => {
      if (duplicatePolicy.id === policy.id) return [];

      if (
        insuranceType === 'EXPAT_HEALTH' &&
        duplicatePolicy.insuranceType === 'INCOMING'
      )
        return [];

      const { text } = getHealthStatus(duplicatePolicy.publicStatus);

      return (
        <li>
          <a
            href={getPolicyLink(duplicatePolicy)}
            className="hover:text-red-900"
          >
            {mapHealthInsuranceType(duplicatePolicy.insuranceType)}
            {' | '}
            {duplicatePolicy.insuranceType === 'PUBLIC_HEALTH' ? (
              <>
                {duplicatePolicy.providerId}
                {' | '}
              </>
            ) : (
              <></>
            )}
            {dayjs(duplicatePolicy.createdAt).format('DD MMM YYYY')}
            {' | '}
            {text.text}
          </a>
        </li>
      );
    }
  );

  if (!description || description.length === 0) return <></>;

  return (
    <FileErrorCard
      errorType="ERROR"
      className="!mt-0 mb-4"
      hideCloseButton={true}
      handleClose={() => {}}
      title="Customer has multiple health policies"
      description={
        <ul className="mt-2 list-disc space-y-2"> {description} </ul>
      }
      open={true}
    />
  );
};

export default DuplicateHealthPoliciesCard;
