import { gql } from '@apollo/client';

export const GET_DATA_REMOVAL_REQUESTS = gql`
  query DataRemovalRequests(
    $searchString: String!
    $offset: Float!
    $limit: Float!
  ) {
    dataRemovalRequests(
      searchString: $searchString
      offset: $offset
      limit: $limit
    ) {
      createdAt
      customerIoRecordsSuppressedAt
      dbRecordsDeletedAt
      documentsDeletedAt
      email
      id
      intercomRecordsDeletedAt
      premoderationChecks
      status
      stripeRecordsDeletedAt
      user {
        id
        firstName
        lastName
      }
    }
    totalDataRemovalRequests(searchString: $searchString)
  }
`;
