import { ApolloError, useMutation } from '@apollo/client';
import Button from 'components/Button';
import OverviewPage from 'components/OverviewPage';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import Table from 'components/TableRefactor/Table';
import { EmptyStateMessage } from 'components/TableRefactor/TableEmptyState';
import { HeaderConfig, RowConfig } from 'components/TableRefactor/types';
import { useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { paths } from 'routes/definedPaths';

import { CREATE_SD_CATEGORY } from '../sdCategories.mutations';
import { GET_SD_CATEGORIES } from '../sdCategories.queries';
import { SDCategory } from '../sdCategories.types';

const filterSenderOptions = [
  {
    id: 'all',
    label: 'All senders',
    title: 'All senders',
    show: true,
  },
  {
    id: 'advigonOnly',
    label: 'Advigon',
    title: 'Advigon',
    show: true,
  },
  {
    id: 'barmeniaOnly',
    label: 'Barmenia',
    title: 'Barmenia',
    show: true,
  },
  {
    id: 'hallescheOnly',
    label: 'Hallesche',
    title: 'Hallesche',
    show: true,
  },
];

const DEFAULT_NUMBER_OF_CATEGORIES_PER_PAGE = 25;

type CreateSDCategoryData = {
  createSDCategory: {
    id: string;
  } | null;
};

export const SDCategoriesListPage = () => {
  const [tableError, setTableError] = useState<ApolloError | undefined>(
    undefined
  );
  const history = useHistory();

  const [createSDCategory, { loading: isCreating }] =
    useMutation<CreateSDCategoryData>(CREATE_SD_CATEGORY, {
      refetchQueries: [GET_SD_CATEGORIES],
    });

  const defaultEmptyStateMessage: EmptyStateMessage = {
    title: 'No Categories',
    description: 'It looks like there are no categories yet.',
  };

  const tableFilters: FilterConfig[] = [
    {
      options: filterSenderOptions,
      filterType: 'TEXT',
      label: 'Sender',
      id: 'filterSender',
      placeholder: 'Select sender',
    },
  ];

  const tableHeaders: HeaderConfig[] = [
    {
      id: 'name',
      label: 'Name',
      width: 'w-none',
      minWidth: 'min-w-[180px]',
      sticky: false,
      threshold: 'left-[40px]',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'senderName',
      label: 'Sender',
      width: 'w-none',
      minWidth: 'min-w-[180px]',
      sticky: false,
      threshold: 'left-0',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'whitelist',
      label: 'Search terms',
      width: 'w-none',
      minWidth: 'min-w-[120px]',
      sticky: false,
      threshold: 'left-[220px]',
      border: 'right',
      component: ColumnHeader,
    },
    {
      id: 'blacklist',
      label: 'Blacklist',
      width: 'w-none',
      minWidth: 'min-w-[180px]',
      sticky: false,
      threshold: 'left-0',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'requiredActionCaption',
      label: 'Required action',
      width: 'w-none',
      minWidth: 'min-w-[180px]',
      sticky: false,
      threshold: 'left-0',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'requiredActionColor',
      label: '',
      width: 'w-[40px]',
      minWidth: 'min-w-[40px]',
      sticky: false,
      threshold: 'left-0',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'customerIoEvent',
      label: 'Customer.io event',
      width: 'w-none',
      minWidth: 'min-w-[80px]',
      sticky: false,
      threshold: 'left-0',
      border: 'none',
      component: ColumnHeader,
    },
  ];

  const rowConfig: RowConfig = {
    getResourceLink: (sdCategory: SDCategory) => {
      return generatePath(paths.scannedDocuments.categories.details.path, {
        id: sdCategory?.id,
      });
    },
    cells: [
      {
        type: 'TEXT',
        props: {
          getText: (sdCategory: SDCategory) => sdCategory.name || '',
        },
        textColor: 'light',
      },
      {
        type: 'TEXT',
        props: {
          getText: (sdCategory: SDCategory) =>
            sdCategory.attributes.senderName || '',
        },
        textColor: 'light',
      },
      {
        type: 'TEXT',
        props: {
          getText: (sdCategory: SDCategory) =>
            sdCategory.whitelist.map((s) => `"${s}"`)?.join(', ') || '-',
        },
        textColor: 'light',
      },
      {
        type: 'TEXT',
        props: {
          getText: (sdCategory: SDCategory) =>
            sdCategory.blacklist.map((s) => `"${s}"`)?.join(', ') || '-',
        },
        textColor: 'light',
      },
      {
        type: 'TEXT',
        props: {
          getText: (sdCategory: SDCategory) =>
            sdCategory.attributes.requiredAction?.caption || '-',
        },
        textColor: 'light',
      },
      {
        type: 'DOT',
        textColor: 'dark',
        props: {
          getDot: (sdCategory: SDCategory) => ({
            active: !!sdCategory.attributes.requiredAction,
            badgeColor: sdCategory.attributes.requiredAction?.color ?? 'gray',
            badgeCaption: sdCategory.attributes.requiredAction?.caption ?? '',
          }),
        },
      },
      {
        type: 'TEXT',
        props: {
          getText: (sdCategory: SDCategory) =>
            sdCategory.attributes.customerIoEvent || '',
        },
        textColor: 'light',
      },
    ],
  };

  return (
    <OverviewPage
      title="Categories"
      current="scannedDocuments"
      error={tableError}
    >
      <Table
        title="Categories"
        query={GET_SD_CATEGORIES}
        searchPlaceholder="Search by name"
        itemsPerPage={DEFAULT_NUMBER_OF_CATEGORIES_PER_PAGE}
        enableItemsPerPageDropdown
        rowConfig={rowConfig}
        filterConfig={tableFilters}
        emptyStateLabel={defaultEmptyStateMessage}
        headers={tableHeaders}
        onError={(error) => setTableError(error)}
        actionButton={
          <Button
            buttonType="primary"
            loading={isCreating}
            onClick={async () => {
              const { data: newCategoryData } = await createSDCategory();
              const newCategoryId = newCategoryData?.createSDCategory?.id;

              if (newCategoryId) {
                history.push(
                  generatePath(paths.scannedDocuments.categories.details.path, {
                    id: newCategoryId,
                  })
                );
              }
            }}
          >
            Create category
          </Button>
        }
      />
    </OverviewPage>
  );
};
