import { makeVar } from '@apollo/client';

import { AlertBannerState } from './models';

export const editableFormData = makeVar('');

export const alertBanners = makeVar<AlertBannerState[]>([]);

export const setNewAlertBanner = ({
  newAlertBanner,
  state,
}: {
  newAlertBanner: AlertBannerState;
  state: AlertBannerState[];
}) => {
  const newAlertBannersState = [newAlertBanner].concat(state);
  alertBanners(newAlertBannersState);
};
