import { DownloadIcon, TrashIcon } from '@heroicons/react/outline';
import { PaperClipIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { downloadBlob } from 'shared/utils/downloadBlob';

import { humanFileSize } from './getHumanReadableFileSize';
import * as styles from './styles';

interface AcceptedFileProps {
  file: File;
  onFileDelete?: () => void;
  disabled?: boolean;
  download?: boolean;
  color?: 'white' | 'gray';
}

const AcceptedFile = ({
  file,
  onFileDelete,
  disabled = false,
  download = false,
  color = 'white',
}: AcceptedFileProps) => {
  return (
    <div
      className={classNames(styles.acceptedFile, {
        [styles.disabled]: disabled,
        [styles.acceptedFileWhite]: color === 'white',
        [styles.acceptedFileGray]: color === 'gray',
      })}
    >
      <div className={styles.content}>
        <PaperClipIcon className={styles.fileIcon} />
        <div className={styles.fileText}>
          <h1
            className={classNames(styles.fileName, {
              [styles.fileNameDisabled]: disabled,
            })}
          >
            {file.name}
          </h1>
          <p className={styles.fileSize}>{humanFileSize(file.size)}</p>
        </div>
      </div>
      {onFileDelete && (
        <button onClick={onFileDelete} disabled={disabled} type="button">
          <TrashIcon
            className={classNames(styles.deleteFileIcon, {
              [styles.deleteFileDisabled]: disabled,
            })}
          />
        </button>
      )}
      {download && (
        <button
          onClick={() => downloadBlob(new Blob([file]), file.name)}
          disabled={disabled}
          type="button"
        >
          <DownloadIcon
            className={classNames(styles.deleteFileIcon, {
              [styles.deleteFileDisabled]: disabled,
            })}
          />
        </button>
      )}
    </div>
  );
};

export default AcceptedFile;
