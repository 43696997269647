import classNames from 'classnames';

export const cardWrapper = classNames('flex');

export const activityCircleWrapper = classNames(
  'flex',
  'flex-col',
  'mr-[16px]',
  'items-center'
);

export const activityCircle = classNames(
  'bg-white',
  'rounded-full',
  'w-[32px]',
  'h-[32px]',
  'flex',
  'justify-center'
);

export const icon = classNames('text-gray-500', 'w-[20px]');

export const activityLine = classNames(
  'w-[2px]',
  'bg-gray-300',
  'h-[calc(100%-38px)]'
);

export const textWrapper = classNames('mb-[14px]', 'flex');
export const textWrapperSmall = classNames('mb-[14px]', 'flex-inline');

export const darkText = classNames('text-sm', 'text-gray-900', 'not-italic');
export const lightText = classNames('text-sm', 'text-gray-600');
export const veryLightText = classNames('text-sm', 'text-gray-500');

export const card = classNames('flex', 'flex-col', 'pb-[38px]', 'pt-[6px]');

export const additionalInfoCardFull = classNames(
  'bg-white',
  'p-[16px]',
  'rounded-[8px]',
  'mb-[8px]',
  'flex',
  'flex-col'
);

export const additionalInfoCardSmall = classNames(
  'bg-gray-50',
  'p-[16px]',
  'rounded-[8px]',
  'mb-[8px]',
  'flex',
  'flex-col'
);

export const commentSection = classNames('flex', 'space-x-2', 'items-center');

export const documentWrapper = classNames('w-[304px]', 'mt-[6px]');

export const inlineText = classNames('inline', 'mr-1');
