import { InformationSection } from 'components/InformationCard';
import DateInformation from 'components/InformationCard/DateInformation';
import EditableInformationCard from 'components/InformationCard/EditableInformationCard';
import TextInformation from 'components/InformationCard/TextInformation';
import { UPDATE_HR_POLICY_INFORMATION } from 'graphql/publicHealth/internal/mutations';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';

interface HRInformationCardProps {
  policy: PublicHealthPolicy;
}

const HRInformationCard = ({ policy }: HRInformationCardProps) => {
  const hrInformation: InformationSection[] = [
    {
      title: 'HR Information',
      rows: [
        {
          id: 'employerName',
          title: 'Employer name',
          type: 'TEXT',
          component: TextInformation,
          editable: true,
          data: policy.hrInformation?.employerName ?? '',
        },
        {
          id: 'hrPersonName',
          title: 'HR person name',
          type: 'TEXT',
          component: TextInformation,
          editable: true,
          data: policy.hrInformation?.hrPersonName ?? '',
        },
        {
          id: 'email',
          title: 'HR person email',
          type: 'TEXT',
          component: TextInformation,
          editable: true,
          data: policy.hrInformation?.email ?? '',
        },
        {
          id: 'letterToEmployerSentAt',
          title: 'Notification sent at',
          type: 'DATE',
          component: DateInformation,
          editable: true,
          data: policy.hrInformation?.letterToEmployerSentAt ?? '',
        },
      ],
    },
  ];

  const hrInformationData = {
    employerName: policy.hrInformation?.employerName ?? '',
    hrPersonName: policy.hrInformation?.hrPersonName ?? '',
    email: policy.hrInformation?.email ?? '',
    letterToEmployerSentAt: policy.hrInformation?.letterToEmployerSentAt ?? '',
  };

  return policy.occupation === 'EMPLOYED' ? (
    <EditableInformationCard
      sections={hrInformation}
      editableData={hrInformationData}
      resourceId={policy.id}
      mutation={UPDATE_HR_POLICY_INFORMATION}
      successMessage="Your changes have been successfully saved"
    />
  ) : (
    <></>
  );
};

export default HRInformationCard;
