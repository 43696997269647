import { useTranslation } from '@getpopsure/i18n-react';
import LoadingState from 'components/LoadingState';
import useLogin from 'hooks/useLogin';
import PageGenericError from 'pages/errors/genericError';
import { retrieveHRPoliciesOverviewPath } from 'pages/policies/template/utils';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { generatePath, useHistory } from 'react-router-dom';
import { paths } from 'routes/definedPaths';

const LoginSuccess = () => {
  const { t } = useTranslation();
  const { getAuthenticatedUser, error, loading } = useLogin();
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      const response = await getAuthenticatedUser();
      const company = response.company?.toLowerCase().replace(' ', '-');
      let path = '/';
      if (
        response.roles.includes('PUBLIC_HEALTH_PROVIDER_AGENT') ||
        response.roles.includes('PUBLIC_HEALTH_PROVIDER_ADMIN') ||
        response.roles.includes('PUBLIC_HEALTH_PROVIDER_AGENT_PLUS')
      ) {
        path = generatePath(paths.policies.publicHealth.provider.path, {
          provider: company,
        });
      } else if (response.roles.includes('HR_PARTNER')) {
        path = retrieveHRPoliciesOverviewPath('public-health', 'de', company);
      } else if (response.roles.includes('FEATHER_ADMIN')) {
        if (!localStorage.getItem('country')) {
          localStorage.setItem('country', 'de');
        }
        path = generatePath(paths.customers.path);
      }
      history.push(path);
    }
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Login - Feather Admin Panel</title>
      </Helmet>
      {loading && (
        <LoadingState
          message={t('admin.signin.signingin.title', 'Signing in...')}
        />
      )}
      {error && <PageGenericError />}
    </>
  );
};

export default LoginSuccess;
