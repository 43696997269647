/**
 * Type is being shared in BE
 * (api/src/features/policies/privateHealth/types/FileUploadType.ts)
 *
 * If you make changes, make sure to make changes in the BE as well
 */
export type PrivateHealthFileType =
  | 'CONFIRMATION_OF_COVERAGE'
  | 'CONFIRMATION_OF_COVERAGE_FROM_PROVIDER'
  | 'SEPA_MANDATE'
  | 'EMPLOYMENT_CONTRACT'
  | 'BANK_STATEMENT'
  | 'FUTURE_INCOME'
  | 'DEPENDENT_CONFIRMATION_OF_COVERAGE'
  | 'TAX_STATEMENT'
  | 'RISK_SURCHARGE'
  | 'EXCLUSIONS'
  | 'INVOICE'
  | 'LETTER_OF_INTENTION'
  | 'PROOF_OF_INCOME'
  | 'OTHER_INTERNAL'
  | 'OTHER'
  | 'ADVICE_RECORD'
  | 'ARCHIVE'
  | 'SUMMARY'
  | 'HALLESCHE_ALL_IN_ONE'
  | 'SCANNED_DOCUMENT'
  | 'LETTER_FOR_EMPLOYER';

export const cantBeCoveredTypes = [
  'MEDICAL_RISK',
  'INCOME',
  'SCHUFA',
  'WORK_CONTRACT',
  'PROFESSION',
  'LIVING_OUTSIDE_OF_GERMANY',
  'OTHER',
] as const;
export type CantBeCoveredType = typeof cantBeCoveredTypes[number];

export const cantBeCoveredTypeMapper: Record<CantBeCoveredType, string> = {
  MEDICAL_RISK: 'Medical risk',
  INCOME: 'Income',
  SCHUFA: 'Schufa',
  WORK_CONTRACT: 'Work contract < 1 year',
  PROFESSION: 'Profession',
  LIVING_OUTSIDE_OF_GERMANY: 'Living outside of Germany',
  OTHER: 'Other',
};
