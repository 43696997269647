import { InformationSection } from 'components/InformationCard';
import ButtonInformation from 'components/InformationCard/ButtonInformation';
import ComboBoxInformation from 'components/InformationCard/ComboBoxInformation/ComboBoxInformation';
import { CurrencyInformation } from 'components/InformationCard/CurrencyInformation';
import DateInformation from 'components/InformationCard/DateInformation';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { PrivateHealthClaim } from 'pages/claims/privateHealth/models';
import { claimTypeNameMapper } from 'shared/claims/privateHealth/claimTypeNameMapper';
import {
  claimTypes,
  PrivateHealthClaimType,
} from 'shared/claims/privateHealth/models';
import { policyMappers } from 'shared/insurances/';

export const retrievePrivateHealthClaimInfoRows = (
  claimInfo: PrivateHealthClaim,
  { generateClaimNumber }: { generateClaimNumber?: (claimId: string) => void }
): InformationSection[] => [
  {
    id: 'claimInfo',
    title: 'Claim information',
    boldTitle: false,
    rows: [
      claimInfo.claimNumber
        ? {
            id: 'claimNumber',
            title: 'Claim number',
            data: claimInfo.claimNumber ?? '',
            type: 'TEXT' as const,
            component: TextInformation,
          }
        : {
            id: 'claimNumber',
            title: 'Claim number',
            data: 'Generate',
            type: 'BUTTON' as const,
            onClick: () => generateClaimNumber?.(claimInfo.id),
            component: ButtonInformation,
          },
      {
        id: 'status',
        title: 'Status',
        data: claimInfo.status ?? 'RECEIVED',
        statusMapping: (status) => {
          return {
            color:
              policyMappers.PRIVATE_HEALTH?.claim?.badgeColorMapper[status] ??
              'blue',
            text: {
              text:
                policyMappers.PRIVATE_HEALTH?.claim?.claimStatusMapper[
                  status
                ] ?? '',
              id: status,
            },
          };
        },
        type: 'STATUS',
        component: StatusInformation,
        required: true,
        editable: false,
      },
      {
        id: 'claimType',
        title: 'Claim type',
        data: claimInfo.claimType?.map(({ type }) => type).join(',') ?? '',
        type: 'DROPDOWN',
        component: ComboBoxInformation,
        editable: true,
        dropdownOptions: claimTypes.map((type) => ({
          id: type,
          label: claimTypeNameMapper[type],
        })),
        optionMapping: (option: string) =>
          claimTypeNameMapper[option as PrivateHealthClaimType],
        multipleOptions: true,
        placeholder: 'Choose a claim type',
      },
      {
        id: 'createdOn',
        title: 'Created on',
        data: claimInfo?.createdAt ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: false,
      },
      {
        id: 'amount',
        title: 'Claimed amount',
        data: claimInfo?.amount ?? 0,
        type: 'CURRENCY',
        component: CurrencyInformation,
        editable: true,
      },
    ],
  },
];
