import { ApolloError } from '@apollo/client';

export const checkNotFoundErrors = (error: ApolloError): boolean => {
  if (error.graphQLErrors !== null) {
    return error.graphQLErrors.some(
      (error) => error.extensions.code === 'RESOURCE_NOT_FOUND'
    );
  }
  return false;
};
