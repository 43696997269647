import { useQuery } from '@apollo/client';
import { capitalizeName } from '@getpopsure/public-utility';
import { ShieldCheckIcon, UserIcon } from '@heroicons/react/solid';
import { DetailsPage } from 'components/DetailsPage';
import { InternalLink, TabComponent } from 'components/DetailsPage/models';
import { TabInformation } from 'components/Tabs';
import { generatePath, useParams } from 'react-router';
import { paths } from 'routes/definedPaths';
import { insuranceNameMapper } from 'shared/insurances/insuranceNameMapper';
import { policyRouteMapper } from 'shared/insurances/policyRouteMapper';

import OverviewTab from '../components/OverviewTab';
import { GET_QUESTIONNAIRE } from '../graphql/questionnaire';
import { Questionnaire } from '../models/questionnaire';

const QuestionnaireDetailsPage = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useQuery<{ questionnaire: Questionnaire }>(
    GET_QUESTIONNAIRE,
    {
      variables: {
        id,
      },
      errorPolicy: 'none',
    }
  );

  const internalLinks: InternalLink[] = [
    ...(data?.questionnaire.user
      ? [
          {
            title: `Account: ${capitalizeName({
              firstName: data?.questionnaire?.user?.firstName ?? '',
              lastName: data?.questionnaire?.user?.lastName ?? '',
            })}`,
            icon: UserIcon,
            href: data?.questionnaire
              ? generatePath(paths.customers.details.path, {
                  id: data.questionnaire?.user?.id,
                })
              : '',
          },
        ]
      : []),
    ...(data?.questionnaire.userPolicy
      ? [
          {
            title: `${
              insuranceNameMapper[data.questionnaire.userPolicy.insuranceType]
            } policy`,
            icon: ShieldCheckIcon,
            href: policyRouteMapper[
              data.questionnaire.userPolicy.insuranceType
            ](data.questionnaire.userPolicy.id),
          },
        ]
      : []),
  ];

  const tabs: TabInformation[] = [
    {
      name: 'Overview',
      href: '#overview',
      withDot: false,
    },
  ];

  const tabComponents: TabComponent[] = [
    {
      id: 'overview',
      component: OverviewTab,
      props: {
        questionnaire: data?.questionnaire,
      },
    },
  ];

  return (
    <DetailsPage
      windowTitle="Questionnaire - Feather Admin Panel"
      pageTitle="Questionnaire"
      pageSubtitle={
        data?.questionnaire.user &&
        `${data.questionnaire.user.firstName} ${data.questionnaire.user.lastName}`
      }
      backLink={{
        text: 'Back',
      }}
      tabs={tabs}
      tabComponents={tabComponents}
      loading={loading}
      error={error}
      current="questionnaires"
      internalLinks={internalLinks}
    />
  );
};

export default QuestionnaireDetailsPage;
