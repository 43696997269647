import IssueCard from 'components/IssueCard';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Issue } from 'models/issue';

import * as styles from './styles';

dayjs.extend(relativeTime);

interface UnresolvedIssuesProps {
  issues?: Issue[];
  setOpenModal: (open: boolean) => void;
}

const UnresolvedIssues = ({ issues, setOpenModal }: UnresolvedIssuesProps) => {
  const handleClickOnIssue = () => {
    setOpenModal(false);
  };
  return (
    <div>
      {issues?.map((issue) => {
        return (
          <a href="#activity" key={issue.id} onClick={handleClickOnIssue}>
            <IssueCard
              issue={issue}
              smallVersion={false}
              showAuthor={true}
              className={styles.issue}
              showStatus={false}
              showCustomerInformed={false}
              cardColor="gray"
            />
          </a>
        );
      })}
    </div>
  );
};

export default UnresolvedIssues;
