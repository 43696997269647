import classNames from 'classnames';

const Loader = ({
  className,
  primaryColor = 'stroke-indigo-500',
  secondaryColor = 'stroke-indigo-300',
}: {
  className?: string;
  primaryColor?: string;
  secondaryColor?: string;
}) => (
  <svg
    data-testid="loader"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames(className ?? '', 'stroke-[10px]')}
  >
    <circle
      cx="16"
      cy="16"
      r="13.5"
      strokeWidth="5"
      className={`stroke-current !stroke-[5px] ${secondaryColor}`}
    />
    <mask id="path-2-inside-1_1760_34348" fill="white">
      <path d="M16 0C18.4383 2.90761e-08 20.8443 0.557268 23.0343 1.62924C25.2243 2.70121 27.1403 4.25952 28.636 6.18515C30.1317 8.11078 31.1675 10.3528 31.6643 12.7399C32.1612 15.127 32.1058 17.5961 31.5026 19.9586L26.661 18.7223C27.0759 17.0976 27.1139 15.3997 26.7723 13.7581C26.4306 12.1164 25.7183 10.5746 24.6897 9.25039C23.6611 7.92615 22.3435 6.85451 20.8374 6.11732C19.3314 5.38013 17.6768 4.9969 16 4.9969V0Z" />
    </mask>
    <path
      className={`stroke-current ${primaryColor}`}
      d="M16 0C18.4383 2.90761e-08 20.8443 0.557268 23.0343 1.62924C25.2243 2.70121 27.1403 4.25952 28.636 6.18515C30.1317 8.11078 31.1675 10.3528 31.6643 12.7399C32.1612 15.127 32.1058 17.5961 31.5026 19.9586L26.661 18.7223C27.0759 17.0976 27.1139 15.3997 26.7723 13.7581C26.4306 12.1164 25.7183 10.5746 24.6897 9.25039C23.6611 7.92615 22.3435 6.85451 20.8374 6.11732C19.3314 5.38013 17.6768 4.9969 16 4.9969V0Z"
      strokeWidth="10"
      mask="url(#path-2-inside-1_1760_34348)"
    />
  </svg>
);

export default Loader;
