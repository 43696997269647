import { gql } from '@apollo/client';

export const UPDATE_DOG_LIABILITY_QUESTIONNAIRE = gql`
  mutation UpdateDogLiabilityQuestionnaire(
    $answers: String!
    $questionnaireId: String!
  ) {
    updateQuestionnaire(answers: $answers, questionnaireId: $questionnaireId) {
      id
      answers
      questionnaireType
    }
  }
`;
