import { InformationSection } from 'components/InformationCard';
import ComboBoxInformation from 'components/InformationCard/ComboBoxInformation/ComboBoxInformation';
import DateInformation from 'components/InformationCard/DateInformation';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextBoxInformation from 'components/InformationCard/TextBoxInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { LegalClaim } from 'pages/claims/legal/models';
import {
  legalClaimTypes,
  LegalExpectedOutcomeType,
  legalExpectedOutcomeTypeMapper,
  legalExpectedOutcomeTypes,
  legalPreferredTimeDateMapper,
  LegalPreferredTimeDateType,
  legalPreferredTimeDateTypes,
} from 'shared/claims/legal/models';
import { policyMappers } from 'shared/insurances/';
import { InsuranceType } from 'shared/insurances/types';

export const retrieveClaimInfoRows =
  (insuranceType: InsuranceType) =>
  (claimInfo: LegalClaim): InformationSection[] => {
    const statusNameMapper =
      policyMappers[insuranceType]?.claim?.claimStatusMapper;
    const typeNameMapper = policyMappers[insuranceType]?.claim?.typeNameMapper;
    const badgeColorMapper =
      policyMappers[insuranceType]?.claim?.badgeColorMapper;

    return [
      {
        id: 'claimInfo',
        title: 'Claim information',
        boldTitle: false,
        rows: [
          {
            id: 'claimNumber',
            title: 'Claim number',
            data: claimInfo?.claimNumber ?? '',
            type: 'TEXT',
            component: TextInformation,
            editable: true,
            placeholder: 'Enter Roland claim number',
          },
          {
            id: 'status',
            title: 'Status',
            data: claimInfo?.status ?? 'RECEIVED',
            statusMapping: (status) => {
              return {
                color: badgeColorMapper ? badgeColorMapper[status] : 'blue',
                text: {
                  text: statusNameMapper ? statusNameMapper[status] : '',
                  id: status,
                },
              };
            },
            type: 'STATUS',
            component: StatusInformation,
            editable: false,
          },
          {
            id: 'createdOn',
            title: 'Created on',
            data: claimInfo?.createdAt ?? '',
            type: 'DATE',
            component: DateInformation,
            editable: false,
          },
          {
            id: 'claimType',
            title: 'Claim type',
            data: claimInfo.claimType?.map(({ type }) => type).join(',') ?? '',
            type: 'DROPDOWN',
            component: ComboBoxInformation,

            dropdownOptions: legalClaimTypes.map((type) => ({
              id: type,
              label: typeNameMapper ? typeNameMapper[type] : '',
            })),

            optionMapping: (option: string) =>
              typeNameMapper ? typeNameMapper[option] : '',

            placeholder: 'Choose a claim type',
            editable: true,
            multipleOptions: true,
            required: true,
          },
          {
            id: 'eventDescription',
            title: 'Event description',
            data: claimInfo?.eventDescription ?? '',
            component: TextBoxInformation,
            type: 'TEXTBOX',
            editable: true,
            placeholder: 'Start typing...',
          },
          {
            id: 'expectedOutcome',
            title: 'Consultation topic',
            data: claimInfo.expectedOutcome ?? '',
            type: 'DROPDOWN',
            component: ComboBoxInformation,

            dropdownOptions: legalExpectedOutcomeTypes.map((type) => ({
              id: type,
              label: legalExpectedOutcomeTypeMapper[type] ?? '',
            })),

            optionMapping: (option: string) =>
              legalExpectedOutcomeTypeMapper[
                option as LegalExpectedOutcomeType
              ] ?? '',

            placeholder: 'Choose a consultation topic',
            editable: true,
            multipleOptions: false,
          },
          {
            id: 'contacts',
            title: 'Phone number',
            data: claimInfo?.contacts ?? '',
            type: 'TEXT',
            component: TextInformation,
            editable: true,
            placeholder: 'Enter phone number',
          },
          {
            id: 'preferredTimeDate',
            title: 'Preferred call time',
            data: claimInfo.preferredTimeDate ?? '',
            type: 'DROPDOWN',
            component: ComboBoxInformation,

            dropdownOptions: legalPreferredTimeDateTypes.map((type) => ({
              id: type,
              label: legalPreferredTimeDateMapper[type] ?? '',
            })),

            optionMapping: (option: string) =>
              legalPreferredTimeDateMapper[
                option as LegalPreferredTimeDateType
              ] ?? '',

            placeholder: 'Choose a preferred call time',
            editable: true,
            multipleOptions: false,
          },
        ],
      },
    ];
  };
