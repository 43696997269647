import {
  ActionTypeSchema,
  CreateTaskTemplateSchema,
  Tag,
  UpdateTaskTemplateSchema,
} from 'pages/admin/taskTemplates/components/taskTemplate.types';
import { InsuranceType } from 'shared/insurances/types';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import {
  ActionType,
  NewTaskPayloadSchema,
  TaskActionsFormInput,
} from '../TaskEngine.types';
import { getSubmitLabel } from './getSubmitLabel';

export interface GetTaskPayload {
  formEntries: TaskActionsFormInput;
}

export interface GetTaskTemplatePayload extends GetTaskPayload {
  insuranceType: InsuranceType;
  tag: Tag;
  createTaskTemplate: boolean;
  updateTaskTemplate: boolean;
}

export const FormEntriesValidator = z.object({
  id: z.string().optional(),
  name: z.string().optional(),
  title: z.string(),
  description: z.string().optional(),
  actions: z.array(
    z.object({
      type: ActionTypeSchema,
      attributes: z
        .record(z.union([z.string(), z.instanceof(File)]))
        .optional(),
    })
  ),
});

export const getTaskPayload = ({ formEntries }: GetTaskPayload) => {
  const { title, description, actions } =
    FormEntriesValidator.parse(formEntries);

  const task = {
    metadata: {
      title,
      description,
    },
    actions: [
      ...actions.map((field) => {
        const inputId = `${field.type.toLowerCase()}-${uuidv4()}`;
        return {
          id: inputId,
          type: field.type,
          attributes: {
            ...field.attributes,
            name: inputId,
          },
        };
      }),
      {
        id: `submit-${uuidv4()}`,
        type: 'SUBMIT',
        attributes: {
          value: getSubmitLabel(
            actions.map((field) => field.type) as ActionType[]
          ),
        },
      },
    ],
  };

  return NewTaskPayloadSchema.parse(task);
};

export const getTaskTemplatePayload = ({
  insuranceType,
  tag,
  formEntries,
  createTaskTemplate,
  updateTaskTemplate,
}: GetTaskTemplatePayload) => {
  const { id, title, name, description, actions } =
    FormEntriesValidator.parse(formEntries);

  const taskTemplate = {
    name,
    tag,
    insuranceType,
    data: { title, description, actions },
  };

  const taskTemplatePayload = updateTaskTemplate
    ? UpdateTaskTemplateSchema.parse(taskTemplate)
    : CreateTaskTemplateSchema.parse(taskTemplate);

  return {
    template: {
      id: updateTaskTemplate ? id : undefined,
      ...taskTemplatePayload,
    },
    createTaskTemplate,
    updateTaskTemplate,
  };
};
