import { gql } from '@apollo/client';

export const ADD_COMPANY = gql`
  mutation AddCompany($name: String!, $sources: [String!]!, $type: String!) {
    addCompany(name: $name, sources: $sources, type: $type) {
      id
      name
    }
  }
`;

export const EDIT_COMPANY = gql`
  mutation EditCompany(
    $id: String!
    $name: String!
    $sources: [String!]!
    $type: String!
  ) {
    editCompany(id: $id, name: $name, sources: $sources, type: $type) {
      id
      name
      sources
      type
    }
  }
`;
