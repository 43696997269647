import { gql } from '@apollo/client';

export const GET_SYSTEM_TASKS = gql`
  query Tasks($where: TaskWhere!) {
    tasks(where: $where) {
      id
      description
      status
      data
      createdAt
      finishedAt
    }
  }
`;
