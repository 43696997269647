import { Trans, useTranslation } from '@getpopsure/i18n-react';
import Badge from 'components/Badge';
import CalendarDatePicker from 'components/CalendarDatePicker';
import FileDropzone from 'components/FileDropzone';
import Modal from 'components/Modal';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Issue } from 'models/issue';
import { useState } from 'react';
import {
  getInternalPublicHealthStatusColor,
  InternalPublicHealthStatus,
} from 'shared/mapInternalPublicHealthStatus';
import {
  getProviderPublicHealthStatusColor,
  ProviderPublicHealthStatus,
} from 'shared/mapProviderPublicHealthStatus';

import UnresolvedIssues from '../UnresolvedIssues';
import * as styles from './styles';

dayjs.extend(utc);

interface ChangeStatusWithFileUploadProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  updateStatus: (
    variables: {
      status: string;
      file?: File;
      startDate?: string;
      issueIds?: string[];
    },
    formId: string
  ) => void;
  newStatus: ProviderPublicHealthStatus | InternalPublicHealthStatus | null;
  defaultStartDate: string;
  confirmationOfCoverage?: string;
  openIssues?: Issue[];
  loading: boolean;
  providerView?: boolean;
}

const ChangeStatusWithFileUpload = ({
  open,
  setOpen,
  updateStatus,
  newStatus,
  defaultStartDate,
  confirmationOfCoverage,
  openIssues,
  loading,
  providerView = false,
}: ChangeStatusWithFileUploadProps) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [fileSelected, setFileSelected] = useState<File | undefined>(undefined);

  const handleStartDateChange = (date: Date | [Date, Date] | null) => {
    if (!date || Array.isArray(date)) return;
    setStartDate(dayjs(date).utc(true).format());
  };

  const showIssuesSection = openIssues && openIssues?.length !== 0;

  const onSubmit = () => {
    if (startDate === '') {
      return;
    }
    let issueIds;
    if (showIssuesSection) {
      issueIds = openIssues.map((issue) => issue.id);
    }
    updateStatus(
      {
        status: newStatus?.id ?? '',
        file: fileSelected,
        startDate,
        issueIds,
      },
      'formWithFile'
    );
  };

  const newStatusColor = providerView
    ? getProviderPublicHealthStatusColor(
        newStatus as ProviderPublicHealthStatus
      )
    : getInternalPublicHealthStatusColor(
        newStatus as InternalPublicHealthStatus
      );

  return (
    <Modal
      title={t(
        'admin.provider.policy.public.modal.changestatus.title',
        'Change status'
      )}
      open={open}
      setOpen={setOpen}
      handleConfirm={onSubmit}
      loading={loading}
      scrollable={!!openIssues}
      textLoading={t(
        'admin.provider.policy.public.modal.changestatus.confirming.action',
        'Confirming...'
      )}
    >
      <>
        <div className={styles.newStatusSection}>
          <h1 className={styles.sectionTitle}>
            {t(
              'admin.provider.policy.public.modal.changestatus.newstatus.title',
              'New status'
            )}
          </h1>
          <Badge color={newStatusColor} badgeType="full">
            {newStatus?.text}
          </Badge>
        </div>
        <div className={styles.reasonSection}>
          <h1 className={styles.sectionTitle}>
            {t(
              'admin.provider.policy.public.modal.changestatus.startdate.title',
              'Start date'
            )}
          </h1>
          <p className={styles.text}>
            {t(
              'admin.provider.policy.public.modal.changestatus.startdate.description',
              'Unless modified, this is the date picked by the customer in the questionnaire. You can update this date later.'
            )}
          </p>
          <CalendarDatePicker
            selectedDate={startDate ? new Date(startDate) : undefined}
            onChange={handleStartDateChange}
            className={styles.calendarDatePicker}
          />
        </div>
        {!confirmationOfCoverage && (
          <div className={styles.reasonSection}>
            {providerView ? (
              <h1 className={styles.sectionTitle}>
                <Trans i18nKey="admin.provider.policy.public.modal.changestatus.covered.finalconfirm.title">
                  Confirmation of coverage
                  <p className={styles.grayText}>(optional)</p>
                </Trans>
              </h1>
            ) : (
              <h1 className={styles.sectionTitle}>
                Confirmation of coverage from provider
                <p className={styles.grayText}>(optional)</p>
              </h1>
            )}
            {providerView && (
              <p className={styles.text}>
                {t(
                  'admin.provider.policy.public.modal.changestatus.covered.finalconfirm.description',
                  'You can still upload the confirmation certificate later.'
                )}
              </p>
            )}
            <div className={styles.dropzoneWrapper}>
              <FileDropzone
                uploadedFile={fileSelected}
                onFileSelect={setFileSelected}
              />
            </div>
          </div>
        )}
        {openIssues && openIssues.length !== 0 && (
          <div className={styles.reasonSection}>
            <h1 className={styles.sectionTitle}>
              {t(
                'admin.provider.policy.public.modal.changestatus.issues.title',
                'These issues will be resolved automatically'
              )}
            </h1>
            <UnresolvedIssues issues={openIssues} setOpenModal={setOpen} />
          </div>
        )}
      </>
    </Modal>
  );
};

export default ChangeStatusWithFileUpload;
