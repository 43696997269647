import { gql } from '@apollo/client';

export const GET_LEGAL_CLAIMS = gql`
  query GetLegalClaims(
    $insuranceType: String!
    $offset: Float!
    $limit: Float!
    $searchString: String!
    $sortColumn: String!
    $sortOrder: String!
    $filterStatus: [String]!
    $filterClaimType: [String]
    $createdAtRangeStart: DateTime
    $createdAtRangeEnd: DateTime
  ) {
    legalClaims(
      insuranceType: $insuranceType
      offset: $offset
      limit: $limit
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterStatus: $filterStatus
      filterClaimType: $filterClaimType
      createdAtRangeStart: $createdAtRangeStart
      createdAtRangeEnd: $createdAtRangeEnd
    ) {
      id
      status
      createdAt
      claimNumber

      claimType {
        id
        type
      }

      user {
        firstName
        lastName
        isDelinquent
        isFraudulent
        fraudulentAdditionalInfo
      }
    }
    totalLegalClaims(
      insuranceType: $insuranceType
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterStatus: $filterStatus
      filterClaimType: $filterClaimType
      createdAtRangeStart: $createdAtRangeStart
      createdAtRangeEnd: $createdAtRangeEnd
    )
  }
`;
