import classNames from 'classnames';

export const section = classNames(
  'px-[24px]',
  'py-[20px]',
  'border-t',
  'border-gray-200',
  'flex',
  'flex-col',
  'items-start',
  'justify-start'
);

export const sectionTitle = classNames(
  'text-sm',
  'text-gray-600',
  'font-bold',
  'mb-[8px]'
);
