import { gql } from '@apollo/client';

export const GET_SD_CATEGORIES = gql`
  query getSDCategories(
    $offset: Float!
    $searchString: String!
    $sortColumn: String!
    $sortOrder: String!
    $limit: Float
    $filterSender: String
  ) {
    sdCategories(
      offset: $offset
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      limit: $limit
      filterSender: $filterSender
    ) {
      id
      name
      whitelist
      blacklist
      attributes
    }
    totalSDCategoriesCount(
      searchString: $searchString
      filterSender: $filterSender
    )
  }
`;

export const GET_SD_CATEGORY_BY_ID = gql`
  query getSDCagetoryById($sdCategoryId: String!) {
    sdCategory(sdCategoryId: $sdCategoryId) {
      id
      name
      whitelist
      blacklist
      attributes
    }
  }
`;
