import QuestionnaireCard from 'components/QuestionnaireCard/QuestionnaireCard';
import { UPDATE_EXPAT_QUESTIONNAIRE } from 'pages/policies/expatHealth/graphql/updateQuestionnaire';
import { ExpatHealthPolicy } from 'pages/policies/expatHealth/models/ExpatHealthPolicy';

import SideSection from '../SideSection/SideSection';

interface QuestionnaireTabProps {
  policy: ExpatHealthPolicy;
}

const QuestionnaireTab = ({ policy }: QuestionnaireTabProps) => {
  return (
    <>
      <div className="relative flex w-full flex-col">
        <QuestionnaireCard
          questionnaireId={policy.questionnaire?.id ?? ''}
          answers={policy.questionnaire?.answers ?? {}}
          cardTitle="Personal information"
          policyId={policy.id}
          mutation={UPDATE_EXPAT_QUESTIONNAIRE}
          editDescription="Please apply the same changes to the overview page to keep everything up-to-date."
        />
      </div>
      <div className="w-full lg:ml-[20px] lg:w-auto">
        <SideSection policy={policy} />
      </div>
    </>
  );
};

export default QuestionnaireTab;
