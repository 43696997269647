import ActivitySection from 'components/ActivitySection/ActivitySection';
import {
  DocumentSection,
  DocumentsSection,
} from 'components/DocumentsSection/DocumentsSection';
import { PinnedNotes } from 'components/Notes/PinnedNotes';
import { Status } from 'models/statusColor';
import { REGENERATE_EXPAT_POLICY_FILE } from 'pages/policies/expatHealth/graphql/document';
import { GET_EXPAT_HEALTH_POLICY } from 'pages/policies/expatHealth/graphql/policy';
import { ExpatHealthPolicy } from 'pages/policies/expatHealth/models/ExpatHealthPolicy';
import { EditButton } from 'pages/policies/template/EditButton/EditButton';
import {
  ARCHIVE_POLICY_DOCUMENT,
  DELETE_POLICY_DOCUMENT,
  EDIT_POLICY_DOCUMENT,
  GENERATE_POLICY_DOCUMENTS_ARCHIVE,
  GET_POLICY_DOCUMENTS_ARCHIVE,
  RESTORE_POLICY_DOCUMENT,
  UPLOAD_POLICY_DOCUMENT,
} from 'pages/policies/template/mutations';
import { UploadButton } from 'pages/policies/template/UploadButton/UploadButton';
import { policyMappers } from 'shared/insurances';
import { policyDocumentCategories } from 'shared/policyDocumentCategories';

import { retrieveFileFriendlyName } from '../../../../../shared/utils/retrieveFileFriendlyName';
import OtherExpatPoliciesCard from '../OtherExpatPoliciesCard/OtherExpatPoliciesCard';

interface SideSectionProps {
  policy: ExpatHealthPolicy;
}

const SideSection = ({ policy }: SideSectionProps) => {
  const featherUploads: DocumentSection = {
    title: 'Feather uploads',
    documents: [],
  };

  const customerUploads: DocumentSection = {
    title: 'Customer uploads',
    documents: [],
  };

  const checkoutDownloads: DocumentSection = {
    title: 'Checkout downloads',
    documents: [],
  };

  const providerUploads: DocumentSection = {
    title: 'Provider uploads',
    documents: [],
  };

  const archivedDocuments: DocumentSection = {
    title: 'Archived documents',
    documents: [],
    defaultOpen: false,
  };

  policy?.policyDocuments
    .filter((document) => !document.archivedAt && !document.isFromProvider)
    .forEach((document) => {
      featherUploads.documents.push({
        ...document,
        friendlyName: document.name,
        fileName: document.blobName
          ? retrieveFileFriendlyName(document.blobName)
          : document.name,
        canArchive: true,
        canDelete: true,
        canEdit: true,
        canRegenerate: document.category === 'ANLAGE_6',
      });
    });

  policy?.customerUploads?.forEach((document) => {
    customerUploads.documents.push({
      ...document,
      fileName: retrieveFileFriendlyName(document.blobName),
    });
  });

  policy?.questionnaireDownloadDocuments.forEach((document) => {
    checkoutDownloads.documents.push({
      ...document,
      fileName: retrieveFileFriendlyName(document.blobName),
    });
  });

  policy?.policyDocuments
    .filter((document) => document.isFromProvider && !document.archivedAt)
    .forEach((document) => {
      providerUploads.documents.push({
        ...document,
        friendlyName: document.name,
        fileName: document.blobName
          ? retrieveFileFriendlyName(document.blobName)
          : document.name,
        canArchive: true,
        canDelete: true,
        canEdit: true,
      });
    });

  policy?.policyDocuments
    .filter((document) => document.archivedAt)
    .forEach((document) => {
      archivedDocuments.documents.push({
        ...document,
        friendlyName: document.name,
        fileName: document.blobName
          ? retrieveFileFriendlyName(document.blobName)
          : document.name,
        canArchive: true,
        canDelete: true,
      });
    });

  const statusMapper = (status: string): Status => {
    const policyMapper = policyMappers.INCOMING;
    return {
      text: {
        id: status,
        text: policyMapper?.statusMapper[status] ?? '',
      },
      color: policyMapper?.badgeColorMapper[status] ?? 'gray',
    };
  };

  return (
    <>
      <PinnedNotes
        notes={policy.pinnedNotes ?? []}
        refetchQueries={[GET_EXPAT_HEALTH_POLICY]}
      />
      <ActivitySection
        activity={policy?.activity ?? []}
        policyId={policy?.id ?? ''}
        statusMapping={statusMapper}
      />
      <OtherExpatPoliciesCard policy={policy} />
      <DocumentsSection
        sections={[
          featherUploads,
          customerUploads,
          providerUploads,
          checkoutDownloads,
          archivedDocuments,
        ]}
        documentCategories={policyDocumentCategories}
        getResourceQuery={GET_EXPAT_HEALTH_POLICY}
        resourceId={policy.id}
        deleteMutation={DELETE_POLICY_DOCUMENT}
        archiveMutation={ARCHIVE_POLICY_DOCUMENT}
        restoreMutation={RESTORE_POLICY_DOCUMENT}
        editMutation={EDIT_POLICY_DOCUMENT}
        regenerateMutation={
          policy.user?.isDelinquent === false
            ? REGENERATE_EXPAT_POLICY_FILE
            : undefined
        }
        downloadAllMutation={GENERATE_POLICY_DOCUMENTS_ARCHIVE}
        getDocumentArchiveQuery={GET_POLICY_DOCUMENTS_ARCHIVE}
        documentVisibilityOptions={[
          { id: 'CUSTOMER', label: 'Customer' },
          { id: 'PROVIDER', label: 'Provider' },
          { id: 'HR', label: 'HR' },
        ]}
        uploadButton={
          <UploadButton
            mutation={UPLOAD_POLICY_DOCUMENT}
            documentCategories={policyDocumentCategories}
            resourceId={policy.id}
            documentVisibilityOptions={[
              { id: 'CUSTOMER', label: 'Customer' },
              { id: 'PROVIDER', label: 'Provider' },
              { id: 'HR', label: 'HR' },
            ]}
            getResourceQuery={GET_EXPAT_HEALTH_POLICY}
          />
        }
        editButton={EditButton}
      />
    </>
  );
};

export default SideSection;
