import { generatePath } from 'react-router';
import { intercomUrl as incompleteIntercomPath } from 'shared/intercomUrl';

export const getIntercomUrl = (intercomUserId?: string): string | undefined => {
  const intercomId = process.env.REACT_APP_INTERCOM_EU_ID;
  return intercomId && intercomUserId
    ? generatePath<string>(incompleteIntercomPath, {
        intercomId,
        userId: intercomUserId,
      })
    : undefined;
};
