export type ProviderViewCompany = 'BARMER' | 'POPSURE';

export const mapCompany = (company: ProviderViewCompany) => {
  switch (company) {
    case 'BARMER':
      return 'Barmer';
    case 'POPSURE':
      return 'Feather';
  }
};
