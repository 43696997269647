import classNames from 'classnames';

import * as styles from './styles';

interface PageButtonProps {
  pageNumber: number;
  current: boolean;
  isVisible: boolean;
  handleClick: (page: number) => void;
}

const PageButton = ({
  pageNumber,
  current,
  isVisible,
  handleClick,
}: PageButtonProps) =>
  current ? (
    <button
      aria-current="page"
      className={classNames(styles.currentPageButton, {
        [styles.hidden]: !isVisible,
        [styles.flex]: isVisible,
      })}
    >
      {pageNumber}
    </button>
  ) : (
    <button
      onClick={() => handleClick(pageNumber)}
      className={classNames(styles.pageButton, {
        [styles.hidden]: !isVisible,
        [styles.flex]: isVisible,
      })}
    >
      {pageNumber}
    </button>
  );

export default PageButton;
