import { ModalFormData } from 'components/ModalForm/models';
import { retrievePolicySuggestion } from 'pages/claims/template/components/CreateClaimModal/utils';
import { ExpatHealthPolicy } from 'pages/policies/expatHealth/models/ExpatHealthPolicy';
import { claimTypeNameMapper as expatClaimTypeNameMapper } from 'shared/claims/expatHealth/claimTypeNameMapper';
import {
  deprecatedClaimTypes,
  deprecatedExpatClaimStatuses,
  ExpatClaimStatus,
  expatClaimStatuses,
  ExpatClaimType,
  expatClaimTypes,
} from 'shared/claims/expatHealth/models';
import { statusNameMapper as expatHealthStatusNameMapper } from 'shared/claims/expatHealth/statusNameMapper';

import { SubmittableClaim } from '../../../template/components/CreateClaimModal/models';

export const retrieveFormData = (
  policies: ExpatHealthPolicy[],
  setPolicySearchQuery: (query: string) => void,
  policiesLoading: boolean
): ModalFormData<
  SubmittableClaim<ExpatClaimStatus, ExpatClaimType>,
  ExpatClaimStatus | ExpatClaimType
>[] => [
  {
    id: 'policyId',
    label: 'User policy',
    renderDetails: {
      type: 'autoSuggest',
      options: policies.map(({ id, user, policyNumber }) => ({
        id,
        label: retrievePolicySuggestion({
          firstName: user?.firstName,
          lastName: user?.lastName,
          policyNumber,
        }),
      })),
      placeholder: 'Enter name, email or policy number',
      setExternalQuery: (query: string) => {
        setPolicySearchQuery(query);
      },
      useUnfilteredOptions: true,
    },
    color: 'gray',
    required: true,
    loading: policiesLoading,
  },
  {
    id: 'status',
    label: 'Status',
    renderDetails: {
      type: 'select',
      options: expatClaimStatuses.flatMap((status) =>
        deprecatedExpatClaimStatuses.includes(status)
          ? []
          : [
              {
                id: status,
                label: expatHealthStatusNameMapper[status],
              },
            ]
      ),
      placeholder: 'Select a status',
    },
    color: 'gray',
    required: true,
  },
  {
    id: 'claimType',
    label: 'Claim type',
    renderDetails: {
      type: 'multiSelect',
      options: expatClaimTypes.flatMap((type) => {
        if (deprecatedClaimTypes.includes(type)) {
          return [];
        }
        return {
          id: type,
          label: expatClaimTypeNameMapper[type],
        };
      }),
      placeholder: 'Select type',
    },
    color: 'gray',
    required: true,
  },
  {
    id: 'amount',
    label: 'Claim amount',
    renderDetails: { type: 'currency', placeholder: '0.00' },
    color: 'gray',
    required: true,
  },
  {
    id: 'iban',
    label: 'IBAN',
    renderDetails: { type: 'text', placeholder: 'Enter IBAN' },
    color: 'gray',
    required: true,
  },
  {
    id: 'uploadDocument',
    label: 'Upload a document',
    renderDetails: { type: 'upload' },
    required: true,
  },
];
