import * as styles from './styles';

interface TabProps {
  children: React.ReactElement | React.ReactElement[];
}

const Tab = ({ children }: TabProps) => {
  return <div className={styles.tab}>{children ?? null}</div>;
};

export default Tab;
