import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import Badge from 'components/Badge';
import { ButtonType } from 'components/Button/Button';
import Loader from 'components/Loader';
import { Fragment } from 'react';

import * as styles from './DropdownButton.styles';

export interface DropdownButtonOption {
  id: string;
  label: string;
  show: boolean;
  dot?: boolean;
}

interface DropdownButtonProps {
  options: DropdownButtonOption[];
  onSelectOption: (selectedOption: DropdownButtonOption) => void;
  disabled: boolean;
  buttonType?: ButtonType;
  loading?: boolean;
  children: React.ReactNode;
}

const DropdownButton = ({
  children,
  options,
  onSelectOption,
  disabled,
  buttonType = 'primary',
  loading,
}: DropdownButtonProps) => {
  return (
    <Listbox value={undefined} onChange={onSelectOption} disabled={disabled}>
      {({ open }) => (
        <>
          <div
            className={classNames(styles.selectWrapper, {
              [styles.disabledSelected]: disabled,
            })}
          >
            <Listbox.Button
              className={classNames(styles.button, {
                [styles.primary]: buttonType === 'primary',
                [styles.secondary]: buttonType === 'secondary',
                [styles.white]: buttonType === 'white',
                [styles.transparent]: buttonType === 'transparent',
                [styles.disabled]: disabled || loading,
              })}
            >
              {loading && (
                <Loader className={styles.loader} primaryColor="stroke-white" />
              )}
              {!loading && <span className="mr-[8px] w-max">{children}</span>}
              <ChevronDownIcon
                className={classNames(styles.icon, {
                  [styles.disabledIcon]: disabled,
                })}
              />
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={classNames(
                  styles.optionsList,
                  styles.optionsListBig
                )}
              >
                {options.map(
                  (option) =>
                    option.show && (
                      <Listbox.Option
                        key={option.id}
                        className={classNames(styles.listOption)}
                        value={option}
                      >
                        {({ selected, active }) => (
                          <div className="flex items-center space-x-2">
                            {option.dot && (
                              <Badge color="yellow" badgeType="dot" />
                            )}
                            <span
                              className={classNames({
                                [styles.listOptionTextSelected]: selected,
                                [styles.listOptionText]: !selected,
                                [styles.listOptionTextHover]: active,
                              })}
                            >
                              {option.label}
                            </span>
                          </div>
                        )}
                      </Listbox.Option>
                    )
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default DropdownButton;
