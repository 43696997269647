import { MappedDocumentData } from '../DocumentsSection';

export const getFileNameSortedDocuments = (
  documents: MappedDocumentData[]
): MappedDocumentData[] => {
  return [...documents].sort((a, b) => {
    const aDisplayName = (a?.friendlyName ?? a.fileName).toLocaleLowerCase();
    const bDisplayName = (b?.friendlyName ?? b.fileName).toLocaleLowerCase();

    if (aDisplayName < bDisplayName) {
      return -1;
    }

    if (aDisplayName > bDisplayName) {
      return 1;
    }

    return 0;
  });
};
