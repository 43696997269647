import classNames from 'classnames';

export const sectionTitle = classNames(
  'mb-[5px]',
  'text-sm',
  'text-gray-900',
  'font-bold',
  'flex'
);

export const status = classNames('ml-[8px]');

export const newStatusSection = classNames('mt-[16px]', 'flex', 'items-center');
export const newStatusSectionCol = classNames('flex-col', '!items-start');

export const section = classNames('mt-[24px]');

export const grayText = classNames('!text-gray-500', 'ml-[5px]');

export const calendarWrapper = classNames(
  '!w-[432px]',
  '!bg-gray-200',
  'mt-[8px]'
);

export const description = classNames('text-sm', 'text-gray-600');
