import { gql } from '@apollo/client';

export const UPDATE_EXPAT_SPAIN_STATUS = gql`
  mutation UpdateExpatSpainStatus(
    $insuranceType: String!
    $status: String!
    $id: String!
    $policyNumber: String
    $activeUntil: String
    $reinstateDate: String
    $price: Float
  ) {
    updateExpatSpainPolicyStatus(
      insuranceType: $insuranceType
      status: $status
      id: $id
      policyNumber: $policyNumber
      activeUntil: $activeUntil
      reinstateDate: $reinstateDate
      price: $price
    ) {
      id
      publicStatus
      policyNumber
      activeUntil
    }
  }
`;
