import { gql } from '@apollo/client';

export const UPDATE_HOUSEHOLD_QUESTIONNAIRE = gql`
  mutation UpdateHouseholdQuestionnaire(
    $answers: String!
    $questionnaireId: String!
  ) {
    updateQuestionnaire(answers: $answers, questionnaireId: $questionnaireId) {
      id
      answers
      questionnaireType
    }
  }
`;
