import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { RowConfig } from 'components/TableRefactor/types';
import { retrieveReadableClaimTypes } from 'pages/claims/template/components/ClaimsDetails/utils';
import {
  retrieveClaimsDetailsPath,
  statusMapping,
} from 'pages/claims/template/utils/utils';
import { policyMappers } from 'shared/insurances';

import { PrivateHealthClaim } from '../../../models';

export const rowConfig: RowConfig = {
  getResourceLink: (data: PrivateHealthClaim) =>
    retrieveClaimsDetailsPath('private-health', data.id),
  cells: [
    {
      type: 'TEXT',
      textColor: 'dark',
      props: {
        getText: (data: PrivateHealthClaim) => data.user.firstName ?? '',
      },
    },
    {
      type: 'TEXT',
      textColor: 'dark',
      props: {
        getText: (data: PrivateHealthClaim) => data.user.lastName ?? '',
      },
    },
    {
      type: 'STATUS',
      textColor: 'dark',
      props: {
        getStatus: ({ status }: PrivateHealthClaim) =>
          statusMapping(
            status && policyMappers.PRIVATE_HEALTH?.claim?.claimStatusMapper
              ? policyMappers.PRIVATE_HEALTH.claim.claimStatusMapper[status]
              : '',
            status && policyMappers.PRIVATE_HEALTH?.claim?.badgeColorMapper
              ? policyMappers.PRIVATE_HEALTH.claim.badgeColorMapper[status]
              : 'blue'
          ),
      },
    },
    {
      type: 'TEXT',
      textColor: 'dark',
      props: {
        getText: (data: PrivateHealthClaim) =>
          data.amount ? englishFormattedEuroCurrency(data.amount) : '',
      },
    },
    {
      type: 'DATE',
      textColor: 'light',
      props: { getDate: (data: PrivateHealthClaim) => data.createdAt },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: {
        getText: (data: PrivateHealthClaim) =>
          data.claimType?.length
            ? retrieveReadableClaimTypes(
                data.claimType.length,
                policyMappers.PRIVATE_HEALTH?.claim?.typeNameMapper[
                  data.claimType[0].type
                ] ?? ''
              )
            : '',
      },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: {
        getText: (data: PrivateHealthClaim) => data.policy.policyNumber,
      },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: {
        getText: (data: PrivateHealthClaim) =>
          data.policy.currentTariffInfo?.tariff ?? '',
      },
    },
  ],
};
