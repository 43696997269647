import { DotsVerticalIcon, XCircleIcon } from '@heroicons/react/outline';
import { CheckCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useState } from 'react';

import { ProgressIcon } from '../assets/ProgressIcon';
import type { Task, TaskStatus } from '../TaskEngine.types';
import * as styles from './TaskCard.styles';

interface TaskCardProps {
  task: Task;

  onClickTask(): void;

  onCancelTask?: () => void;
}

const iconMapping: Record<TaskStatus, JSX.Element> = {
  CANCELED: <XCircleIcon className="w-5 text-gray-500" />,
  COMPLETED: <CheckCircleIcon className="w-5" />,
  OPEN: <ProgressIcon className="w-5" />,
};

export const TaskCard = (props: TaskCardProps) => {
  const { task, onClickTask, onCancelTask } = props;
  const [openTaskOptions, setOpenTaskOptions] = useState(false);

  const ref = useOutsideClick(() => setOpenTaskOptions(false));

  const isCanceled = task.status === 'CANCELED';
  return (
    <div
      className={classNames(styles.taskItem, {
        [styles.taskItemMuted]: isCanceled,
        [styles.textMuted]: isCanceled,
        [styles.textBlack]: !isCanceled,
      })}
    >
      <button type="button" className={styles.taskButton} onClick={onClickTask}>
        <div className="min-w-5 mr-3">{iconMapping[task.status]}</div>
        {task.description.metadata?.title}
      </button>

      {task.status === 'OPEN' && onCancelTask && (
        <button
          onClick={() => setOpenTaskOptions((prev) => !prev)}
          type="button"
          className={styles.optionsButton}
          ref={ref}
        >
          <DotsVerticalIcon className="w-5" />
        </button>
      )}

      {openTaskOptions && (
        <div className={styles.dropdown}>
          <button
            className={styles.dropdownItem}
            type="button"
            onClick={onCancelTask}
          >
            Cancel task
          </button>
        </div>
      )}
    </div>
  );
};
