import { ApolloError } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import { BadgeColor } from 'components/Badge/Badge';
import OverviewPage from 'components/OverviewPage';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import Table from 'components/TableRefactor/Table';
import { HeaderConfig, RowConfig } from 'components/TableRefactor/types';
import { useState } from 'react';

import CreateReferralCodeModal from './components/CreateReferralCodeModal';
import DeactivateReferralCodeModal from './components/DeactivateReferralCodeModal';
import UpdateReferralCodeModal from './components/UpdateReferralCodeModal';
import { GET_REFERRAL_CODES } from './graphql/queries';
import { ReferralCode, ReferralCodeStatus } from './types';

const NUMBER_OF_REFERRAL_CODES_PER_PAGE = 12;

const headers: HeaderConfig[] = [
  {
    id: 'referralCode',
    label: 'Referral code',
    width: 'w-[250px]',
    minWidth: 'min-w-[300px]',
    sticky: true,
    component: SortableColumnHeader,
  },
  {
    id: 'isDiscountCode',
    label: 'Discount code',
    width: 'w-[150px]',
    minWidth: 'min-w-[100px]',
    component: SortableColumnHeader,
  },
  {
    id: 'status',
    label: 'Status',
    width: 'w-[150px]',
    minWidth: 'min-w-[100px]',
    component: SortableColumnHeader,
  },
  {
    id: 'createdAt',
    label: 'Created at',
    width: 'w-[150px]',
    minWidth: 'min-w-[100px]',
    component: SortableColumnHeader,
  },
  {
    id: 'actions',
    label: 'Action',
    width: 'w-[80px]',
    minWidth: 'min-w-[80px]',
    component: ColumnHeader,
  },
];
const statusMapper: Record<ReferralCodeStatus, string> = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

const statusBadgeColorMapper: Record<ReferralCodeStatus, BadgeColor> = {
  ACTIVE: 'green',
  INACTIVE: 'gray',
};

const statusMapping = (status: string) => ({
  text: {
    id: status,
    text: statusMapper[status as ReferralCodeStatus],
  },
  color: statusBadgeColorMapper[status as ReferralCodeStatus] ?? 'gray',
});

const filterStatusOptions = [
  {
    title: 'Any',
    label: 'Any',
    id: 'any',
  },
  {
    title: 'Active',
    label: 'Active',
    id: 'ACTIVE',
  },
  {
    title: 'Inactive',
    label: 'Inactive',
    id: 'INACTIVE',
  },
];

const ReferralCodesPage = () => {
  const { t } = useTranslation();

  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const [selectedReferralCode, setSelectedReferralCode] =
    useState<ReferralCode | null>(null);
  const [updateReferralCode, setUpdateReferralCode] = useState(false);
  const [deactivateReferralCode, setDeactivateReferralCode] = useState(false);

  const tableFilter: FilterConfig[] = [
    {
      filterType: 'TEXT',
      options: filterStatusOptions,
      label: 'Status',
      id: 'filterStatus',
    },
  ];

  const rowConfig: RowConfig = {
    resourceOnClick: (data) => {
      setSelectedReferralCode(data);
      setUpdateReferralCode(true);
    },
    cells: [
      {
        type: 'TEXT',
        textColor: 'dark',
        props: { getText: (data: ReferralCode) => data.referralCode },
      },
      {
        type: 'TEXT',
        textColor: 'dark',
        props: {
          getText: (data: ReferralCode) => (data.isDiscountCode ? 'Yes' : 'No'),
        },
      },
      {
        type: 'STATUS',
        textColor: 'dark',
        props: {
          getStatus: (data: ReferralCode) => statusMapping(data.status),
        },
      },
      {
        type: 'DATE',
        textColor: 'dark',
        props: { getDate: (item: ReferralCode) => item.createdAt },
      },
      {
        type: 'BUTTON',
        textColor: 'dark',
        props: {
          getButtonText: () =>
            t('referralCodes.table.action.deactivate', 'Deactivate'),
          onClick: (event) => {
            event.stopPropagation();
            setSelectedReferralCode(event.data);
            event.data.status === 'ACTIVE' && setDeactivateReferralCode(true);
          },
        },
      },
    ],
  };
  return (
    <OverviewPage
      title="Referral codes"
      current="admin-referral-codes"
      error={error}
    >
      <Table
        title={t('referralCodes.table.title', 'Referral codes')}
        headers={headers}
        rowConfig={rowConfig}
        filterConfig={tableFilter}
        query={GET_REFERRAL_CODES}
        itemsPerPage={NUMBER_OF_REFERRAL_CODES_PER_PAGE}
        onError={setError}
        filterConfig-={tableFilter}
        searchPlaceholder="Search referral code"
        emptyStateLabel={{
          title: t(
            'referralCodes.table.emptyState.title',
            'There are no referral codes yet.'
          ),
          description: t(
            'referralCodes.table.emptyState.description',
            'Looks like no referral codes were created.'
          ),
        }}
        actionButton={<CreateReferralCodeModal />}
      />
      <UpdateReferralCodeModal
        open={updateReferralCode}
        setOpen={setUpdateReferralCode}
        referralCode={selectedReferralCode as ReferralCode}
      />
      <DeactivateReferralCodeModal
        open={deactivateReferralCode}
        setOpen={setDeactivateReferralCode}
        referralCode={selectedReferralCode as ReferralCode}
      />
    </OverviewPage>
  );
};

export default ReferralCodesPage;
