import { ExternalLinkIcon } from '@heroicons/react/outline';
import type { EditComponentDefaultProps } from 'components/InformationCardV2/models';
import Link from 'components/Link';

interface InformationCardLinkProps extends EditComponentDefaultProps {
  title: string;
  isExternalLink?: boolean;
  href: string;
}

export const InformationCardLink = ({
  title,
  href,
  isExternalLink = false,
}: InformationCardLinkProps) => {
  return (
    <Link
      color="primary"
      text={title}
      href={href}
      IconComponent={isExternalLink ? ExternalLinkIcon : undefined}
      iconLeft={false}
      target={isExternalLink ? '_blank' : '_self'}
    />
  );
};
