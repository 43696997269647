import { getFixedT } from '@getpopsure/i18n-react';

export const issueCategories = [
  'MISSING_INFO',
  'CANCELED',
  'NOT_ELIGIBLE',
  'OTHER',
  'PROCESSING',
  'UNI_CHECK',
  'CUSTOMER_ADDRESS_INCOMPLETE',
  'CUSTOMER_ALREADY_MEMBER_EXISTING_CUSTOMER',
  'CUSTOMER_ALREADY_MEMBER_OTHER_PARTNER',
  'CUSTOMER_NOT_SUBJECT_TO_COMPULSORY_INSURANCE',
  'EMPLOYER_ADDRESS_INCOMPLETE',
  'PROOF_OF_UNI_MISSING',
] as const;

export type IssueCategory = typeof issueCategories[number];

export const mapIssueCategories = (category: IssueCategory): string => {
  const t = getFixedT();
  switch (category) {
    case 'MISSING_INFO':
    case 'CUSTOMER_ADDRESS_INCOMPLETE':
    case 'EMPLOYER_ADDRESS_INCOMPLETE':
    case 'PROOF_OF_UNI_MISSING':
      return t('admin.issue.type.missinginformation', 'Missing information');
    case 'CANCELED':
      return t('admin.issue.type.canceled', 'Canceled');
    case 'CUSTOMER_NOT_SUBJECT_TO_COMPULSORY_INSURANCE':
    case 'NOT_ELIGIBLE':
      return t('admin.issue.type.noteligible', 'Not eligible');
    case 'UNI_CHECK':
      return t('admin.issue.type.unicheck', 'Uni check');
    case 'PROCESSING':
      return t('admin.issue.type.processing', 'Processing');
    case 'CUSTOMER_ALREADY_MEMBER_EXISTING_CUSTOMER':
    case 'CUSTOMER_ALREADY_MEMBER_OTHER_PARTNER':
      return t('admin.issue.type.alreadycovered', 'Already covered');
    default:
      return t('admin.issue.type.other.label', 'Other');
  }
};
