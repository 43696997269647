import classNames from 'classnames';

export const container = classNames('animate-appear-in');

export const noDataContainer = classNames(
  'flex',
  'justify-center',
  'border-t',
  'border-t-gray-200',
  'px-[24px]',
  'py-[32px]'
);

export const loader = classNames('animate-spin');
