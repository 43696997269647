import classNames from 'classnames';

export const updateNoteTextArea = classNames('mt-[8px]', 'relative', 'z-[5]');
export const updateNoteCtaWrapper = classNames(
  'flex',
  'justify-end',
  'gap-[8px]',
  'mt-[8px]'
);
export const updateNoteCtaButton = classNames('text-xs');
