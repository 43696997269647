import FileErrorCard from 'components/FileErrorCard/FileErrorCard';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';

interface WaitingPeriodAlertProps {
  waitingPeriodDate?: string;
}

const WaitingPeriodAlert = ({ waitingPeriodDate }: WaitingPeriodAlertProps) => {
  if (!waitingPeriodDate || dayjs(waitingPeriodDate) <= dayjs()) {
    return <></>;
  }

  return (
    <FileErrorCard
      open={true}
      className="!mt-0 mb-4"
      title="Policy is in waiting period"
      hideCloseButton={true}
      handleClose={() => {}}
      errorType="WARNING"
      description={`The waiting period is until ${dayjs(
        waitingPeriodDate
      ).format(
        DATE_FORMAT
      )}. Please check the start date and relocation date if waiting period could be waived.  `}
    />
  );
};

export default WaitingPeriodAlert;
