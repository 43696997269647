import ChecklistInformation from 'components/InformationCard/ChecklistInformation';
import { CurrencyInformation } from 'components/InformationCard/CurrencyInformation';
import DateInformation from 'components/InformationCard/DateInformation';
import LinkInformation from 'components/InformationCard/LinkInformation';
import MultipleLineInformation from 'components/InformationCard/MultipleLineInformation';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import TextWithDetailsInformation from 'components/InformationCard/TextWithDetailsInformation';
import { ReferralCode } from 'components/ReferralCode/ReferralCode';
import { retrieveClaimsOverviewPath } from 'pages/claims/template/utils/utils';
import ChangeAccount from 'pages/policies/template/ChangeAccount/ChangeAccount';
import {
  InformationCardDetails,
  InformationCardEditableDetails,
} from 'pages/policies/template/models/PolicyTemplateData';
import { UPDATE_POLICY_CANCELATION_REQUEST } from 'pages/policies/template/mutations';
import {
  householdAddOnMapper,
  householdAddOns,
  householdPlanMapper,
} from 'shared/insurances/planMappers/household';
import {
  householdBadgeColorMapper,
  householdStatusMapper,
} from 'shared/insurances/statusMappers/household';
import { HouseholdStatus } from 'shared/insurances/types';
import { setQueryToUrl } from 'shared/utils/setQueryToUrl';

import { GET_HOUSEHOLD_POLICY } from '../graphql/singlePolicy';
import { UPDATE_HOUSEHOLD_POLICY } from '../graphql/updatePolicy';
import { HouseholdPolicyData } from '../models/HouseholdPolicy';

const getInsuredPersonInfo = (
  data?: HouseholdPolicyData
): InformationCardDetails => ({
  editable: false,
  cards: [
    {
      title: 'Insured person',
      rows: [
        {
          id: 'firstName',
          title: 'First name',
          data:
            data?.householdPolicy?.insuredPerson?.firstName ??
            data?.householdPolicy?.user?.firstName ??
            '',
          component: TextInformation,
          type: 'TEXT',
        },
        {
          id: 'lastName',
          title: 'Last name',
          data:
            data?.householdPolicy?.insuredPerson?.lastName ??
            data?.householdPolicy?.user?.lastName ??
            '',
          component: TextInformation,
          type: 'TEXT',
        },
        {
          id: 'dateOfBirth',
          title: 'Date of birth',
          data: data?.householdPolicy?.insuredPerson?.dateOfBirth ?? '',
          component: TextInformation,
          type: 'TEXT',
        },
        {
          id: 'gender',
          title: 'Gender',
          data: data?.householdPolicy?.insuredPerson?.gender ?? '',
          component: TextInformation,
          type: 'TEXT',
        },
        {
          id: 'email',
          title: 'Email',
          data: data?.householdPolicy?.user?.email ?? '',
          component: TextWithDetailsInformation,
          type: 'TEXT_WITH_DETAILS',
          detailsTitle: 'Change account',
          detailsLabel: 'Change account',
          renderModalDetails: (setOpen, modalOpen) => (
            <ChangeAccount
              policyDetailsMutation={GET_HOUSEHOLD_POLICY}
              setOpen={setOpen}
              modalOpen={modalOpen}
            />
          ),
        },
      ],
    },
  ],
});

const policyInformation = (
  data?: HouseholdPolicyData
): InformationCardEditableDetails<HouseholdPolicyData> => {
  const policy = data?.householdPolicy;
  const address = policy?.questionnaire?.answers?.personalInfo?.address;

  return {
    editable: true,
    mutation: UPDATE_HOUSEHOLD_POLICY,
    resourceId: policy?.id ?? '',
    extraVariables: {
      insuranceType: 'HOUSEHOLD',
    },
    editabledData: {
      policyNumber: policy?.policyNumber ?? '',
      price: policy?.price ?? 0,
      startDate: policy?.startDate ?? '',
      source: policy?.source ?? '',
      campaign: policy?.campaign ?? '',
      medium: policy?.medium ?? '',
      content: policy?.content ?? '',
      term: policy?.term ?? '',
    },
    cards: [
      {
        title: 'Policy information',
        rows: [
          {
            id: 'policyNumber',
            title: 'Policy number',
            data: policy?.policyNumber ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter policy number',
          },
          {
            id: 'status',
            title: 'Status',
            data: policy?.publicStatus ?? '',
            type: 'STATUS',
            component: StatusInformation,
            statusMapping: (status: string) => {
              return {
                text: {
                  id: status,
                  text: householdStatusMapper[status as HouseholdStatus] ?? '',
                },
                color:
                  householdBadgeColorMapper[status as HouseholdStatus] ??
                  'gray',
              };
            },
          },
          {
            id: 'startDate',
            title: 'Start date',
            data: policy?.startDate ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'plan',
            title: 'Tariff',
            data: policy?.quote?.plan
              ? householdPlanMapper[policy.quote.plan]
              : '',
            component: TextInformation,
            type: 'TEXT',
          },
          {
            id: 'addOns',
            title: 'Add-ons',
            type: 'CHECKLIST',
            component: ChecklistInformation,
            checklist: householdAddOns.flatMap((addOn) => {
              if (
                // Omit extended coverage
                addOn === 'EXTENDED_COVERAGE' ||
                // Omit bike coverage if plan is basic
                (policy?.quote?.plan === 'BASIC' &&
                  addOn === 'ADDITIONAL_BIKE_COVERAGE')
              ) {
                return [];
              }
              return {
                id: addOn,
                title: householdAddOnMapper[addOn],
                check: policy?.quote?.addOns?.includes(addOn) ?? false,
              };
            }),
          },
          {
            id: 'insuredSum',
            title: 'Insured sum',
            data: policy?.quote.insuredSum ?? 0,
            component: CurrencyInformation,
            type: 'CURRENCY',
            placeholder: '€ 0.00',
          },
          {
            id: 'price',
            title: 'Price',
            data: policy?.price ?? 0,
            component: CurrencyInformation,
            type: 'CURRENCY',
            editable: true,
            placeholder: '€ 0.00',
          },
          ...(policy?.numberOfClaims && policy.numberOfClaims > 0
            ? [
                {
                  id: 'claims',
                  title: 'Claims',
                  data: `${policy?.numberOfClaims} claims`,
                  href: setQueryToUrl(retrieveClaimsOverviewPath('household'), [
                    { key: 'search', value: policy?.policyNumber ?? '' },
                  ]),
                  externalLink: true,
                  type: 'LINK' as const,
                  component: LinkInformation,
                },
              ]
            : [
                {
                  id: 'claims',
                  title: 'Claims',
                  data: '',
                  type: 'TEXT' as const,
                  component: LinkInformation,
                },
              ]),
          {
            id: 'address',
            title: 'Address',
            type: 'MULTIPLELINE',
            component: MultipleLineInformation,
            data: address ?? {},
            multipleLinesData: [
              {
                id: 'house_number',
                label: 'House number',
                data: address?.house_number ?? '',
              },
              {
                id: 'street',
                label: 'Street',
                data: address?.street ?? '',
              },
              {
                id: 'city',
                label: 'City',
                data: address?.city ?? '',
              },
              {
                id: 'additional_information',
                label: 'Additional information',
                data: address?.additional_information ?? '',
              },
              {
                id: 'postcode',
                label: 'Postal code',
                data: address?.postcode ?? '',
              },
              {
                id: 'country',
                label: 'Country',
                data: address?.country ?? '',
              },
            ],
          },
          {
            id: 'createdAt',
            title: 'Created on',
            data: policy?.createdAt ?? '',
            component: DateInformation,
            type: 'DATE',
          },
          {
            id: 'source',
            title: 'Source',
            data: policy?.source ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter source',
          },
          {
            id: 'campaign',
            title: 'Campaign',
            data: policy?.campaign ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter campaign',
          },
          {
            id: 'medium',
            title: 'Medium',
            data: policy?.medium ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter medium',
          },
          {
            id: 'content',
            title: 'Content',
            type: 'TEXT',
            component: TextInformation,
            data: policy?.content ?? '',
            editable: true,
            placeholder: 'Enter content',
          },
          {
            id: 'term',
            title: 'Term',
            data: policy?.term ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter term',
          },
          {
            id: 'referralCode',
            title: 'Referral code',
            data: policy?.referral?.referralCode ?? '',
            type: policy?.referral?.status ? 'TEXT_WITH_DETAILS' : 'TEXT',
            detailsTitle: 'Add referral code',
            detailsLabel: 'Add referral code',
            component: TextWithDetailsInformation,
            renderModalDetails: (setOpen, modalOpen) => (
              <ReferralCode
                policyId={policy?.id ?? ''}
                userId={policy?.user?.id ?? ''}
                setOpen={setOpen}
                modalOpen={modalOpen}
              />
            ),
          },
        ],
      },
    ],
  };
};

const policyCancellationInformation = (
  data?: HouseholdPolicyData
): InformationCardEditableDetails<HouseholdPolicyData> => {
  const policy = data?.householdPolicy;

  return {
    editable: true,
    mutation: UPDATE_POLICY_CANCELATION_REQUEST,
    resourceId: policy?.policyCancelationRequest?.id ?? '',
    editabledData: {
      activeUntil: policy?.activeUntil ?? '',
      createdAt: policy?.policyCancelationRequest?.createdAt ?? '',
      canceledOnStripeAt:
        policy?.policyCancelationRequest?.canceledOnStripeAt ?? '',
      requestSentToProviderAt:
        policy?.policyCancelationRequest?.requestSentToProviderAt ?? '',
      cancelationConfirmedByProviderAt:
        policy?.policyCancelationRequest?.cancelationConfirmedByProviderAt ??
        '',
    },
    extraVariables: {
      insuranceType: 'HOUSEHOLD',
      policyId: policy?.id ?? '',
    },
    cards: [
      {
        title: 'Policy cancellation',
        rows: [
          {
            id: 'activeUntil',
            title: 'Active until',
            data: policy?.activeUntil ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'createdAt',
            title: 'Requested by customer on',
            data: policy?.policyCancelationRequest?.createdAt ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'canceledOnStripeAt',
            title: 'Stripe canceled on',
            data: policy?.policyCancelationRequest?.canceledOnStripeAt ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'requestSentToProviderAt',
            title: 'Request sent to provider on',
            data:
              policy?.policyCancelationRequest?.requestSentToProviderAt ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'cancelationConfirmedByProviderAt',
            title: 'Provider confirmed on',
            data:
              policy?.policyCancelationRequest
                ?.cancelationConfirmedByProviderAt ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
        ],
      },
    ],
  };
};

export const getOverviewTabCards = (
  data?: HouseholdPolicyData
): (
  | InformationCardDetails
  | InformationCardEditableDetails<HouseholdPolicyData>
)[] => [
  getInsuredPersonInfo(data),
  policyInformation(data),
  ...(data?.householdPolicy.publicStatus === 'CANCELED' ||
  data?.householdPolicy.publicStatus === 'DROPPED_OUT'
    ? [policyCancellationInformation(data)]
    : []),
];
