interface ParamsObject<K extends string, T extends string> {
  key: K;
  value: T;
}

export const setQueryToUrl = <K extends string, T extends string>(
  baseUrl: string,
  params: ParamsObject<K, T>[]
): string => {
  const urlParams = new URLSearchParams({});
  params.forEach(({ key, value }) => {
    if (!key || !value) {
      return;
    }
    urlParams.set(key, value);
  });
  return String(urlParams) ? `${baseUrl}?${String(urlParams)}` : baseUrl;
};
