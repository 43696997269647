import classNames from 'classnames';

export const form = classNames('pt-3');

export const inputWrapper = classNames('mb-6');

export const label = classNames(
  'font-bold',
  'inline-block',
  'pb-2',
  'text-gray-900',
  'text-sm'
);

export const actionsContainer = classNames(
  'mt-8',
  'border-t',
  'border-solid',
  'border-gray-300',
  'pb-6',
  'pt-8'
);

export const additionalFieldsContainer = classNames('mt-6');

export const userNotification = classNames('text-sm', 'text-gray-600');

export const submit = classNames('ml-2', 'min-w-[140px]');

export const errorMessage = classNames('text-sm', 'text-red-600');
