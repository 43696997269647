/**
 * NOTE: This type is not being shared with FE.
 * When updating, also update FE type
 *
 * Notion page for all vertical claim statuses:
 * https://www.notion.so/featherinsurance/Claim-statuses-2-0-22713a3fe92c49969b2e403f655678b2
 */
export const expatSpainClaimStatuses = [
  'APPROVED_WITH_OTHER_CLAIMS',
  'SUBMITTED_TO_PROVIDER',
  'RECEIVED',
  'CUSTOMER_PAID_OUT',
  'OTHER_PAID_OUT',
  'DUPLICATE',
  'INCOMPLETE',
  'DENIED_WITH_OTHER_CLAIMS',
  'DENIED',
  'FEATHER_PAID_OUT',
  'CLOSED',
  'MISSING_INFO_RECEIVED',
  'PROCESSED_WITH_OTHER_CLAIM',
  'MISSING_PAYMENT_INFO',
] as const;
export type ExpatSpainClaimStatus = typeof expatSpainClaimStatuses[number];

export const expatSpainClaimTypes = [
  'MEDICATION',
  'DOCTORS_VISIT',
  'EMERGENCY_ROOM',
  'HOSPITAL_OVERNIGHT',
  'NON_MEDICAL_PROFESSIONAL',
  'AMBULANCE',
  'HOSPITAL_TREATMENT',
  'PHYSICAL_THERAPY',
  'DENTAL',
  'COST_PLAN',
  'OTHER',
] as const;
export type ExpatSpainClaimType = typeof expatSpainClaimTypes[number];
