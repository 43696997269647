interface TableCellButtonProps {
  data: unknown;
  getButtonText: (data: any) => string;
  onClick?: (metaData: Record<string, any>) => void;
}

export const TableCellButton = ({
  onClick,
  getButtonText,
  data,
}: TableCellButtonProps) => {
  const cellData = getButtonText(data);

  const handleClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    onClick?.({ data, stopPropagation: () => e.stopPropagation() });
  };
  return (
    <button className="w-full text-left" type="button" onClick={handleClick}>
      {cellData}
    </button>
  );
};
