import { SelectButtonOption } from 'components/SelectButton';
import { StatusUpdateFormQuestion } from 'components/StatusUpdateModal/models';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';
import { StatusData } from 'pages/claims/template/models';
import { BikeClaimStatus, bikeClaimStatuses } from 'shared/claims/bike/models';
import { policyMappers } from 'shared/insurances';
import { InsuranceType } from 'shared/insurances/types';

export const claimStatusOptions = (
  insuranceType: InsuranceType
): SelectButtonOption<BikeClaimStatus>[] => {
  const statusMapper = policyMappers[insuranceType]?.claim?.claimStatusMapper;
  const badgeColorMapper =
    policyMappers[insuranceType]?.claim?.badgeColorMapper;

  return bikeClaimStatuses.map((statusId) => ({
    id: statusId,
    label: statusMapper?.[statusId] ?? '',
    title: statusMapper?.[statusId] ?? '',
    color: badgeColorMapper?.[statusId] ?? 'gray',
  }));
};

export const retrieveFormAndFooterTextByStatus = (
  status: BikeClaimStatus
): StatusData => ({
  form: formMapper[status],
  footerText: footerTextMapper(status),
});

const uploadForm: StatusUpdateFormQuestion = {
  id: 'uploadDocument',
  label: 'Upload document',
  data: {
    type: 'UPLOAD',
    fileName: {
      placeholder: 'Enter document friendly name',
      id: 'fileName',
    },
  },
};

const payoutAmountForm: StatusUpdateFormQuestion = {
  id: 'payoutAmount',
  label: 'Payout amount',
  data: { type: 'CURRENCY', placeholder: '0.00' },
  required: true,
};

const customerPaidOnForm: StatusUpdateFormQuestion = {
  id: 'customerPaidOn',
  label: 'Customer paid on',
  data: {
    type: 'DATE',
    defaultValue: dayjs().format(DATE_FORMAT),
    max: dayjs().format(DATE_FORMAT),
  },
  required: true,
};

const reasonForm: StatusUpdateFormQuestion = {
  id: 'reason',
  label: 'Reason',
  data: { type: 'TEXT_AREA', placeholder: 'Start typing here...' },
};

const formMapper: Record<BikeClaimStatus, StatusUpdateFormQuestion[]> = {
  DENIED: [uploadForm],
  MISSING_INFO_RECEIVED: [uploadForm],
  CUSTOMER_PAID_OUT: [payoutAmountForm, customerPaidOnForm, uploadForm],
  DUPLICATE: [uploadForm],
  SUBMITTED_TO_PROVIDER: [],
  RECEIVED: [reasonForm],
  INCOMPLETE: [uploadForm],
  DROPPED_OUT: [uploadForm],
  CLOSED: [uploadForm],
};

const footerTextMapper = (status: BikeClaimStatus): string | undefined => {
  if (status !== 'SUBMITTED_TO_PROVIDER' && status !== 'RECEIVED') {
    return 'Once confirmed, claim status will be updated and documents will be added to the claim.';
  }
};
