import { generatePath } from 'react-router';
import { stripeUrl as incompleteStripeUrl } from 'shared/stripeUrl';

export const getStripeUrl = (
  stripeCustomerToken?: string
): string | undefined =>
  stripeCustomerToken
    ? generatePath<string>(incompleteStripeUrl, {
        token: stripeCustomerToken,
      })
    : undefined;
