import Badge from 'components/Badge';
import { BadgeColor } from 'components/Badge/Badge';

interface Props {
  value: string;
  badgeColor: BadgeColor;
}

export const InformationCardStatus = ({ value, badgeColor }: Props) => (
  <Badge color={badgeColor} badgeType="full">
    {value}
  </Badge>
);
