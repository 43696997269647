import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import {
  HouseholdPlan,
  householdPlanMapper,
} from 'shared/insurances/planMappers/household';
import { householdStatusMapper } from 'shared/insurances/statusMappers/household';
import { HouseholdStatus } from 'shared/insurances/types';

const statusFilterOptions: SelectButtonOption<HouseholdStatus>[] = [
  {
    id: 'PENDING',
    label: householdStatusMapper.PENDING,
    title: householdStatusMapper.PENDING,
  },
  {
    id: 'ACTIVE',
    label: householdStatusMapper.ACTIVE,
    title: householdStatusMapper.ACTIVE,
  },
  {
    id: 'CANCELED',
    label: householdStatusMapper.CANCELED,
    title: householdStatusMapper.CANCELED,
  },
];

const planFilterOptions: SelectButtonOption<HouseholdPlan>[] = [
  {
    id: 'BASIC',
    label: householdPlanMapper.BASIC,
    title: householdPlanMapper.BASIC,
  },
  {
    id: 'ADVANCED',
    label: householdPlanMapper.ADVANCED,
    title: householdPlanMapper.ADVANCED,
  },
];

export const tableFilters: FilterConfig[] = [
  {
    options: statusFilterOptions,
    filterType: 'MULTIPLE',
    label: 'Status',
    id: 'filterStatus',
    placeholder: 'Select status(es)',
  },
  {
    options: planFilterOptions,
    filterType: 'MULTIPLE',
    label: 'Plan',
    id: 'filterPlan',
    placeholder: 'Select plan',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Start date',
    id: 'filterStartDate',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Created on',
    id: 'filterCreatedAt',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Archived on',
    id: 'filterArchivedAt',
  },
];
