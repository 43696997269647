import { gql } from '@apollo/client';

export const GET_NOTES = gql`
  query GetNotes($userId: String, $policyId: String, $claimId: String) {
    notes(userId: $userId, policyId: $policyId, claimId: $claimId) {
      id
      createdAt
      body
      pinned
      adminUser {
        id
        firstName
        lastName
      }
      updatedAt
      replyNotes {
        id
        createdAt
        body
        adminUser {
          id
          firstName
          lastName
        }
        updatedAt
      }
    }
  }
`;
