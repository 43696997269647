import ActivitySection from 'components/ActivitySection/ActivitySection';
import { PinnedNotes } from 'components/Notes/PinnedNotes';
import { GET_PUBLIC_POLICY } from 'graphql/publicHealth/internal/queries';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import { getInternalPublicHealthStatus } from 'shared/mapInternalPublicHealthStatus';

import DocumentsSection from '../DocumentsSection';

interface SideSectionProps {
  policy: PublicHealthPolicy;
}

const SideSection = ({ policy }: SideSectionProps) => {
  return (
    <>
      <PinnedNotes
        notes={policy.pinnedNotes ?? []}
        refetchQueries={[GET_PUBLIC_POLICY]}
      />
      <ActivitySection
        activity={policy.activity}
        policyId={policy.id}
        statusMapping={getInternalPublicHealthStatus}
      />
      <DocumentsSection data={policy} />
    </>
  );
};

export default SideSection;
