import { getClaimDetailsRouteMapper } from 'shared/claims/claimRouteMapper';
import { policyRouteMapper } from 'shared/insurances/policyRouteMapper';
import { InsuranceType } from 'shared/insurances/types';

const INSURANCE_TYPES_WITH_DETAILS_PAGE: InsuranceType[] = [
  'PRIVATE_HEALTH',
  'PUBLIC_HEALTH',
  'INCOMING',
  'INCOMING_ES',
  'DENTAL',
  'PET_HEALTH',
  'BIKE',
];

export const getPolicyDetailsBaseURLFromInsuranceType = (
  insuranceType?: InsuranceType,
  id?: string
): string | undefined => {
  if (!insuranceType || !id) {
    return;
  }

  return policyRouteMapper[insuranceType](id);
};

export const getClaimDetailsBaseURLFromInsuranceType = (
  insuranceType?: InsuranceType,
  claimId?: string
): string | undefined => {
  if (
    !insuranceType ||
    !claimId ||
    !INSURANCE_TYPES_WITH_DETAILS_PAGE.includes(insuranceType)
  ) {
    return;
  }

  const region = ['INCOMING_LT', 'INCOMING_EU'].includes(insuranceType)
    ? 'eu'
    : undefined;

  return (
    getClaimDetailsRouteMapper(region)[insuranceType](claimId) ?? undefined
  );
};
