import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { InformationRow, InformationSection } from 'components/InformationCard';
import DateInformation from 'components/InformationCard/DateInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { BikePolicy } from 'pages/policies/bike/models/BikePolicy';
import { policyMappers } from 'shared/insurances';
import { bikePlanMapper } from 'shared/insurances/planMappers/bike';
import { getReadableProviderName } from 'shared/utils/getReadableProviderName';

export const retrievePolicyInfoRows = (
  policyInfo: BikePolicy
): InformationSection[] => [
  {
    title: 'Policy information',
    boldTitle: false,
    rows: [
      {
        id: 'status',
        title: 'Status',
        data: policyMappers.BIKE?.statusMapper[policyInfo?.publicStatus] ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
      {
        id: 'policyNumber',
        title: 'Policy number',
        data: policyInfo?.policyNumber ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
      {
        id: 'startDate',
        title: 'Start date',
        data: policyInfo?.startDate ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: false,
      },
      {
        id: 'provider',
        title: 'Provider',
        data: policyInfo?.providerId
          ? getReadableProviderName(policyInfo.providerId)
          : '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
      {
        id: 'plan',
        title: 'Plan',
        data: policyInfo?.bikePlanId
          ? bikePlanMapper[policyInfo.bikePlanId]
          : '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
      ...(policyInfo?.publicStatus === 'CANCELED'
        ? [
            {
              id: 'activeUntil',
              title: 'Active until',
              data: policyInfo?.activeUntil ?? '',
              type: 'DATE',
              component: DateInformation,
              editable: false,
            } as InformationRow,
          ]
        : []),
      {
        id: 'purchaseDate',
        title: 'Purchase date',
        data: policyInfo?.bikeInfo?.purchaseDate ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: false,
      },
      {
        id: 'purchasePrice',
        title: 'Bike price',
        data: policyInfo?.bikeInfo?.purchasePrice
          ? englishFormattedEuroCurrency(policyInfo.bikeInfo.purchasePrice)
          : '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
    ],
  },
];
