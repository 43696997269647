import classNames from 'classnames';
import React from 'react';

import * as styles from './styles';

interface RadioButtonProps extends React.ComponentPropsWithRef<'input'> {
  clasName?: string;
  children: React.ReactNode;
  error?: boolean;
}

const RadioButton = React.forwardRef(
  (
    { className, children, error, ...props }: RadioButtonProps,
    ref?: React.ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <div className={styles.radioButtonWrapper}>
        <input
          className={classNames(className, styles.radioButtonInput, {
            [styles.radioButtonError]: error,
          })}
          {...props}
          type="radio"
          ref={ref}
        />
        <label htmlFor={props.id} className={styles.radioButtonLabel}>
          {children}
        </label>
      </div>
    );
  }
);

export default RadioButton;
