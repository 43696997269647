import { TFunction } from '@getpopsure/i18n-react';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import { HeaderConfig } from 'components/TableRefactor/types';

export const getTableHeaders = (t: TFunction): HeaderConfig[] => [
  {
    id: 'first-name',
    label: t(
      'admin.hr.companyPension.table.header.firstName.label',
      'First name'
    ),
    component: ColumnHeader,
  },
  {
    id: 'last-name',
    label: t(
      'admin.hr.companyPension.table.header.lastName.label',
      'Last name'
    ),
    component: ColumnHeader,
  },
  {
    id: 'status',
    label: t('admin.hr.companyPension.table.header.status.label', 'Status'),
    component: ColumnHeader,
  },
  {
    id: 'company-share',
    label: t(
      'admin.hr.companyPension.table.header.companyShare.label',
      'Company share'
    ),
    component: ColumnHeader,
  },
  {
    id: 'employee-share',
    label: t(
      'admin.hr.companyPension.table.header.employeeShare.label',
      'Employee share'
    ),
    component: ColumnHeader,
  },
  {
    id: 'total-contribution',
    label: t(
      'admin.hr.companyPension.table.header.totalContribution.label',
      'Total contribution'
    ),
    component: ColumnHeader,
  },
  {
    id: 'start-date',
    label: t(
      'admin.hr.companyPension.table.header.startDate.label',
      'Start date'
    ),
    component: ColumnHeader,
  },
  {
    id: 'insurance-number',
    label: t(
      'admin.hr.companyPension.table.header.insuranceNumber.label',
      'Insurance number'
    ),
    component: ColumnHeader,
  },
];
