import { useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import Badge from 'components/Badge';
import Modal from 'components/Modal';
import RadioButton from 'components/RadioButton';
import TextArea from 'components/TextArea';
import { Issue } from 'models/issue';
import UnresolvedIssues from 'pages/policies/publicHealth/components/UnresolvedIssues';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  getInternalPublicHealthStatusColor,
  InternalPublicHealthStatus,
} from 'shared/mapInternalPublicHealthStatus';
import {
  getProviderPublicHealthStatusColor,
  ProviderPublicHealthStatus,
} from 'shared/mapProviderPublicHealthStatus';

import * as styles from './styles';

interface ChangeStatusWithIssueProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  updateStatus: (
    variables: {
      status: string;
      category?: string;
      description?: string;
      isCustomerInformed?: boolean;
      issueIds?: string[];
    },
    formId: string
  ) => void;
  newStatus: ProviderPublicHealthStatus | InternalPublicHealthStatus | null;
  openIssues?: Issue[];
  loading: boolean;
  providerView?: boolean;
}

const ChangeStatusWithIssue = ({
  open,
  setOpen,
  updateStatus,
  newStatus,
  openIssues,
  loading,
  providerView = false,
}: ChangeStatusWithIssueProps) => {
  const { t } = useTranslation();

  const [description, setDescription] = useState('');
  const [isCustomerInformed, setIsCustomerInformed] = useState<
    boolean | undefined
  >(undefined);
  const [reasonError, setReasonError] = useState(false);
  const [shouldCreateIssue, setShouldCreateIssue] = useState(false);
  const [isCustomerInformedError, setIsCustomerInformedError] = useState(false);

  useEffect(() => {
    if (description !== '') {
      setReasonError(false);
    }
  }, [description]);

  useEffect(() => {
    if (isCustomerInformed !== undefined) {
      setIsCustomerInformedError(false);
    }
  }, [isCustomerInformed]);

  const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const yesOrNoBooleanValue = (value: string): boolean => value === 'yes';

  const handleIsCustomerInformedChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsCustomerInformed(yesOrNoBooleanValue(e.target.value));
  };

  const showIssuesSection =
    newStatus?.id !== 'missinginfo' && openIssues && openIssues?.length !== 0;

  const onSubmit = () => {
    if (reasonRequired && !description.trim()) {
      setReasonError(true);
      return;
    }

    if (
      newStatus?.id !== 'missinginfo' &&
      isCustomerInformed === undefined &&
      providerView
    ) {
      setIsCustomerInformedError(true);
      return;
    }

    let issueIds;
    if (showIssuesSection) {
      issueIds = openIssues.map((issue) => issue.id);
    }
    updateStatus(
      {
        status: newStatus?.id ?? '',
        category: newStatus?.id,
        description,
        isCustomerInformed,
        issueIds,
      },
      'formWithIssue'
    );
  };

  const newStatusColor = providerView
    ? getProviderPublicHealthStatusColor(
        newStatus as ProviderPublicHealthStatus
      )
    : getInternalPublicHealthStatusColor(
        newStatus as InternalPublicHealthStatus
      );

  const reasonRequired = shouldCreateIssue || newStatus?.id === 'missinginfo';

  return (
    <Modal
      title={t(
        'admin.provider.policy.public.modal.changestatus.title',
        'Change status'
      )}
      open={open}
      setOpen={setOpen}
      handleConfirm={onSubmit}
      loading={loading}
      scrollable={!!openIssues}
      textLoading={t(
        'admin.provider.policy.public.modal.changestatus.confirming.action',
        'Confirming...'
      )}
    >
      <>
        {providerView && (
          <p className={styles.text}>
            {t(
              'admin.provider.policy.public.modal.changestatus.description',
              'The following information is required in order to change the status.'
            )}
          </p>
        )}
        <div className={styles.newStatusSection}>
          <h1 className={styles.sectionTitle}>
            {t(
              'admin.provider.policy.public.modal.changestatus.newstatus.title',
              'New status'
            )}
          </h1>
          <Badge color={newStatusColor} badgeType="full">
            {newStatus?.text}
          </Badge>
        </div>
        {(newStatus?.id === 'noteligible' || newStatus?.id === 'canceled') && (
          <div className={styles.fieldSection}>
            <h1 className={styles.sectionTitle}>
              {providerView
                ? t(
                    'admin.provider.policy.public.modal.changestatus.createIssue.title',
                    'Create an issue to notify Feather?'
                  )
                : 'Create an issue to notify provider?'}
            </h1>
            <div className="flex flex-col">
              <div className={styles.radioButtons}>
                <RadioButton
                  id="yes"
                  name="shouldCreateIssue"
                  value="yes"
                  required
                  checked={!!shouldCreateIssue}
                  onChange={({ target }) => {
                    const value = yesOrNoBooleanValue(target.value);
                    setShouldCreateIssue(value);
                  }}
                >
                  {t(
                    'admin.provider.policy.public.modal.changestatus.createIssue.yes.label',
                    'Yes'
                  )}
                </RadioButton>
                <RadioButton
                  id="no"
                  name="shouldCreateIssue"
                  value="no"
                  required
                  checked={!shouldCreateIssue}
                  onChange={({ target }) => {
                    const value = yesOrNoBooleanValue(target.value);
                    setShouldCreateIssue(value);
                  }}
                >
                  {t(
                    'admin.provider.policy.public.modal.changestatus.createIssue.no.label',
                    'No'
                  )}
                </RadioButton>
              </div>
            </div>
          </div>
        )}
        {reasonRequired && (
          <div
            className={classNames(styles.fieldSection, 'animate-appear-down')}
          >
            <h1 className={styles.sectionTitle}>
              {t(
                'admin.provider.policy.public.modal.changestatus.reason.title',
                'Reason'
              )}
            </h1>
            {providerView && (
              <p className={styles.text}>
                {newStatus?.id === 'noteligible' &&
                  t(
                    'admin.provider.policy.public.modal.changestatus.reason.noteligible.description',
                    'Please explain to Feather why this customer is not eligible.'
                  )}
                {newStatus?.id === 'canceled' &&
                  t(
                    'admin.provider.policy.public.modal.changestatus.reason.canceled.description',
                    'Please explain to Feather why this application is canceled.'
                  )}
                {newStatus?.id === 'missinginfo' &&
                  t(
                    'admin.provider.policy.public.modal.changestatus.reason.missinginfo.description',
                    'Please explain to Feather what information is missing.'
                  )}
              </p>
            )}
            {!providerView && (
              <p className={styles.text}>
                {newStatus?.id === 'noteligible' &&
                  'Please explain to the provider why this customer is not eligible.'}
                {newStatus?.id === 'canceled' &&
                  'Please explain to the provider why this application is canceled.'}
                {newStatus?.id === 'missinginfo' &&
                  'Please explain to the provider what information is missing.'}
              </p>
            )}
            <TextArea
              color="gray"
              className={styles.textarea}
              placeholder={t(
                'admin.provider.policy.public.modal.changestatus.reason.placeholder',
                'Start typing here...'
              )}
              value={description}
              onChange={handleDescriptionChange}
              required
              error={reasonError}
              errorMessage="A reason is required."
            />
          </div>
        )}
        {providerView && newStatus?.id !== 'missinginfo' && (
          <div className={styles.fieldSection}>
            <h1 className={styles.sectionTitle}>
              {t(
                'admin.provider.policy.public.modal.changestatus.inform.title',
                'Did you inform the customer?'
              )}
            </h1>
            <div className="flex flex-col">
              <div className={styles.radioButtons}>
                <RadioButton
                  id="yes"
                  name="isCustomerInformed"
                  value="yes"
                  required
                  checked={!!isCustomerInformed}
                  onChange={handleIsCustomerInformedChange}
                  error={isCustomerInformedError}
                >
                  {t(
                    'admin.provider.policy.public.modal.changestatus.inform.yes.label',
                    'Yes'
                  )}
                </RadioButton>
                <RadioButton
                  id="no"
                  name="isCustomerInformed"
                  value="no"
                  required
                  checked={
                    !isCustomerInformed && isCustomerInformed !== undefined
                  }
                  onChange={handleIsCustomerInformedChange}
                  error={isCustomerInformedError}
                >
                  {t(
                    'admin.provider.policy.public.modal.changestatus.inform.no.label',
                    'No'
                  )}
                </RadioButton>
              </div>
              {isCustomerInformedError && (
                <span className="mt-1 text-xs text-red-400">
                  {t(
                    'admin.provider.policy.public.modal.changestatus.inform.error.label',
                    'Please select an option.'
                  )}
                </span>
              )}
            </div>
          </div>
        )}
        {showIssuesSection && (
          <div className={styles.fieldSection}>
            <h1 className={styles.sectionTitle}>
              {t(
                'admin.provider.policy.public.modal.changestatus.issues.title',
                'These issues will be resolved automatically'
              )}
            </h1>
            <UnresolvedIssues issues={openIssues} setOpenModal={setOpen} />
          </div>
        )}
        {shouldCreateIssue && (
          <div
            className={classNames(styles.fieldSection, 'animate-appear-down')}
          >
            <p className={styles.text}>
              {providerView
                ? t(
                    'admin.provider.policy.public.modal.changestatus.footer',
                    'A new issue will be created and Feather will be notified.'
                  )
                : 'A new issue will be created and the provider will be notified.'}
            </p>
          </div>
        )}
      </>
    </Modal>
  );
};

export default ChangeStatusWithIssue;
