import { gql } from '@apollo/client';

export const GET_HR_PRIVATE_HEALTH_POLICIES = gql`
  query hrPrivateHealthPolicies(
    $company: String!
    $filterStatus: [String!]!
    $searchString: String!
    $sortOrder: String!
    $sortColumn: String!
    $offset: Float!
    $limit: Float!
  ) {
    hrPrivateHealthPolicies(
      company: $company
      filterStatus: $filterStatus
      searchString: $searchString
      sortOrder: $sortOrder
      sortColumn: $sortColumn
      offset: $offset
      limit: $limit
    ) {
      id
      startDate
      policyNumber
      publicStatus
      providerId
      createdAt
      archivedAt
      user {
        firstName
        lastName
        dateOfBirth
      }
      hrLetterForEmployerDocument {
        id
        url
      }
    }
    totalHrPrivateHealthPolicies(
      company: $company
      filterStatus: $filterStatus
      searchString: $searchString
    )
  }
`;

export const GET_HR_PRIVATE_HEALTH_POLICY = gql`
  query HrPrivateHealthPolicy($id: String!) {
    hrPrivateHealthPolicy(id: $id) {
      id
      publicStatus
      providerId
      startDate
      insuredPerson {
        occupation
      }
      user {
        id
        firstName
        lastName
        dateOfBirth
      }
      currentTariffInfo {
        id
        employerContributions
        longTermCare
        tariffMonthlyPrice
        taxDeductibleAmount
        totalMonthlyPrice
        ownContributions
      }
      hrLetterForEmployerDocument {
        id
        url
      }
    }
  }
`;
