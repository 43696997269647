import { useTranslation } from '@getpopsure/i18n-react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { PaperClipIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useState } from 'react';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';
import { downloadBlob } from 'shared/utils/downloadBlob';
import { getMimeType } from 'shared/utils/getMimeType';

import * as styles from './styles';

interface DocumentCardProps {
  fileName: string;
  fileLink: string;
  resizedUrl?: string;
  onDelete?: () => void;
  onRegenerate?: (documentType?: string) => void;
  documentType?: string;
  documentId?: string;
  disabled?: boolean;
}

const DocumentCard = ({
  fileName,
  fileLink,
  resizedUrl,
  onDelete,
  onRegenerate,
  documentType,
  documentId,
  disabled = false,
}: DocumentCardProps) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isAnchorFocused, setIsAnchorFocused] = useState(false);
  const { t } = useTranslation();

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const ref = useOutsideClick(closePopup);

  const fetchBlob = async () => {
    const response = await fetch(resizedUrl ?? fileLink, {
      method: 'GET',
      headers: {
        'Content-Type': getMimeType(fileName),
      },
    });
    let blob = await response.blob();
    if (!blob.type) {
      blob = new Blob([blob], {
        type: getMimeType(fileName),
      });
    }

    return blob;
  };

  const truncatedFileName =
    fileName.length > 65 ? `${fileName.slice(0, 65)}...` : fileName;

  return (
    <div
      className={classNames(styles.documentCard, {
        // Outline CSS is done through JS to keep anchor and button as siblings and not as parent + child
        [styles.focusOutline]: isAnchorFocused,
        [styles.disabled]: disabled,
      })}
    >
      <a
        className={classNames(styles.downloadLink, {
          [styles.disabledLink]: disabled,
        })}
        href={fileLink}
        target="_blank"
        rel="noopener noreferrer"
        onFocus={() => {
          setIsAnchorFocused(true);
        }}
        onBlur={() => {
          setIsAnchorFocused(false);
        }}
      >
        <div className={styles.fileNameSection}>
          <div className={styles.fileIconContainer}>
            <PaperClipIcon className={classNames(styles.fileIcon)} />
          </div>
          <p
            className={classNames(styles.fileName, {
              [styles.fileNameDisabled]: disabled,
            })}
          >
            {truncatedFileName}
          </p>
        </div>
      </a>
      <div className={styles.dotIconContainer}>
        <button
          className={styles.dotIconButton}
          type="button"
          onClick={() => {
            setIsPopupOpen(!isPopupOpen);
          }}
          ref={ref}
        >
          <DotsVerticalIcon className={styles.dotIcon} />
        </button>
        {isPopupOpen && (
          <div className={styles.dropdown}>
            {documentType === 'SCANNED_DOCUMENT' && (
              <a
                className={styles.dropdownImageAnchor}
                href={`${generatePath(paths.scannedDocuments.details.path, {
                  id: documentId,
                })}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Details
              </a>
            )}
            {resizedUrl && !disabled && (
              <a
                className={styles.dropdownImageAnchor}
                href={resizedUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('admin.documents.action.openImage.label', 'Open image')}
              </a>
            )}
            {!resizedUrl && !disabled && (
              <button
                className={styles.dropdownButtonGray}
                type="button"
                onClick={async () => {
                  const blob = await fetchBlob();
                  downloadBlob(blob, fileName);
                }}
              >
                {t('admin.documents.action.download.label', 'Download')}
              </button>
            )}
            {onRegenerate && (
              <button
                className={styles.dropdownButtonGray}
                type="button"
                onClick={() => {
                  onRegenerate(documentType);
                }}
              >
                Re(generate)
              </button>
            )}
            {onDelete && (
              <button
                className={styles.dropdownButtonRed}
                type="button"
                onClick={() => {
                  onDelete();
                }}
              >
                Delete
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentCard;
