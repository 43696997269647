import { z, ZodType } from 'zod';

import {
  StatusUpdateFormDependencies,
  StatusUpdateFormQuestion,
} from '../models';

export const retrieveValidationByType = (type: string): ZodType | undefined => {
  switch (type) {
    case 'UPLOAD':
      return z.instanceof(File);

    case 'CURRENCY':
      return z.number();

    case 'TEXT_AREA':
    case 'SELECT':
    case 'TEXT':
    case 'DATE':
      return z.string();

    case 'RADIO':
      return z.boolean();

    default:
      return undefined;
  }
};

export const handleDependencyCheck = (
  dependencies: StatusUpdateFormDependencies[] | undefined,
  formAnswers: Record<string, any> | undefined
): boolean => {
  return (
    !dependencies ||
    dependencies.every((dependency) => {
      const answer = formAnswers?.[dependency.questionId];
      return answer === dependency.requiredValue;
    })
  );
};

export const filterAnswersByDependency = (
  form: StatusUpdateFormQuestion[],
  formAnswers: Record<string, any> | undefined
) => {
  return form.reduce((answers, currentQuestion) => {
    const includeQuestion = handleDependencyCheck(
      currentQuestion.fieldDependencies,
      formAnswers
    );

    if (includeQuestion) {
      const answer = formAnswers?.[currentQuestion.id];
      answers[currentQuestion.id] = answer;
    }

    return answers;
  }, {} as Record<string, any>);
};
