export const privateHealthIssueCategories = [
  'UNI_CHECK',
  'MISSING_INFO',
  'CANCELED',
  'NOT_ELIGIBLE',
  'OTHER',
] as const;

export type PrivateHealthIssueCategory =
  typeof privateHealthIssueCategories[number];
