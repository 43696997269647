import { useTranslation } from '@getpopsure/i18n-react';
import Loader from 'components/Loader';

import * as styles from './styles';

const TableLoadingState = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.tableWrapper}>
      <div className={styles.tableDataPlaceholder}>
        <div className={styles.tablePlaceholder}>
          <Loader className={styles.loader} />
          <p className={styles.text}>
            {t('admin.policytable.loading.label', 'Loading data...')}
          </p>
        </div>
      </div>
      <div className={styles.paginationPlaceholder}>
        <div className={styles.paginationTextPlaceholder} />
        <div className={styles.paginationButtonsPlaceholder} />
      </div>
    </div>
  );
};

export default TableLoadingState;
