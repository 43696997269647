import { useMutation, useReactiveVar } from '@apollo/client';
import { Trans, useTranslation } from '@getpopsure/i18n-react';
import FileErrorCard from 'components/FileErrorCard';
import { UPDATE_COMMISSION_ENTITLEMENT } from 'graphql/publicHealth/provider/mutations';
import { GET_PUBLIC_PROVIDER_POLICY } from 'graphql/publicHealth/provider/queries';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import { useState } from 'react';
import { commissionEntitlementCutoffDate } from 'shared/commissionEntitlementCutoffDate';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import ApproveCommissionModal from '../ApproveCommissionModal';
import DeclineCommissionModal from '../DeclineCommissionModal';

interface CommissionCheckCardProps {
  policy: PublicHealthPolicy;
}

const CommissionCheckCard = ({ policy }: CommissionCheckCardProps) => {
  const { t } = useTranslation();

  const alertBannersState = useReactiveVar(alertBanners);

  const [updateCommission, { loading }] = useMutation(
    UPDATE_COMMISSION_ENTITLEMENT,
    {
      refetchQueries: [GET_PUBLIC_PROVIDER_POLICY],
      onError: () => {
        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'WARNING',
          message: t(
            'admin.provider.policy.public.commissioncheck.error.label',
            'Something went wrong. Please try again.'
          ),
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
    }
  );

  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);

  const handleClickOnApprove = () => {
    setOpenApproveModal(true);
  };

  const handleClickOnDecline = () => {
    setOpenDeclineModal(true);
  };

  const handleChangeCommission = (status: 'ACCEPTED' | 'DECLINED') => {
    updateCommission({
      variables: {
        policyId: policy.id,
        newStatus: status,
      },
    })
      .then(() => {
        if (status === 'ACCEPTED') {
          const newAlertBanner: AlertBannerState = {
            id: uuidv4(),
            type: 'SUCCESS',
            message: t(
              'admin.provider.policy.public.commissioncheck.approve.success.label',
              'Confirmation successful. Commission payment will be made in the next payment cycle.'
            ),
          };

          setNewAlertBanner({ state: alertBannersState, newAlertBanner });
        }
        if (status === 'DECLINED') {
          const newAlertBanner: AlertBannerState = {
            id: uuidv4(),
            type: 'SUCCESS',
            message: t(
              'admin.provider.policy.public.commissioncheck.decline.success.label',
              'The commission entitlement is declined.'
            ),
          };

          setNewAlertBanner({ state: alertBannersState, newAlertBanner });
        }
      })
      .catch(() => {});

    setOpenApproveModal(false);
    setOpenDeclineModal(false);
  };

  return (
    <>
      <FileErrorCard
        open={
          policy.publicStatus === 'COVERED' &&
          policy.commissionEntitlement === 'NONE' &&
          !policy.commissionId &&
          !!policy.statusSetToCoveredAt &&
          new Date(policy.statusSetToCoveredAt) >=
            commissionEntitlementCutoffDate
        }
        title={t(
          'admin.provider.policy.public.commissioncheck.title',
          'Check commission entitlement'
        )}
        errorType="WARNING"
        handleClose={() => {}}
        className="!mt-0 mb-4"
        description={
          <>
            <Trans i18nKey="admin.provider.policy.public.commissioncheck.description">
              Commission entitlement is only needed if the policy is active and
              comes from an affiliate partner. <br /> A commission entitlement
              is not needed for policies that originate from company links.
            </Trans>
          </>
        }
        hideCloseButton={true}
        showActionButtons={true}
        primaryButton={{
          text: t(
            'admin.provider.policy.public.commissioncheck.approve.label',
            'Approve commission'
          ),
          onClick: handleClickOnApprove,
        }}
        secondaryButton={{
          text: t(
            'admin.provider.policy.public.commissioncheck.decline.label',
            'Decline commission'
          ),
          onClick: handleClickOnDecline,
        }}
      />
      <ApproveCommissionModal
        open={openApproveModal}
        setOpen={setOpenApproveModal}
        handleConfirm={handleChangeCommission}
        loading={loading}
      />
      <DeclineCommissionModal
        open={openDeclineModal}
        setOpen={setOpenDeclineModal}
        handleConfirm={handleChangeCommission}
        loading={loading}
      />
    </>
  );
};

export default CommissionCheckCard;
