import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import classNames from 'classnames';
import { NumberInput } from 'components/Input/NumberInput';
import { ChangeEvent } from 'react';

import TextWithDetailsInformation from '../TextWithDetailsInformation';
import * as styles from './styles';

const hasValue = (value: unknown) =>
  value !== null && value !== undefined && value !== '';

export interface CurrencyInformationProps {
  cell:
    | JSX.Element
    | string
    | boolean
    | string[]
    | number
    | Record<string, unknown>;
  editable?: boolean;
  editMode?: boolean;
  handleNumberChangeOnInput?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: string;
  id: string;
  className?: string;
  errorMessage?: string;
  detailsLabel?: string;
  detailsTitle?: string;
  renderModalDetails?: (
    setOpen: (prevValue: boolean) => void,
    modalOpen: boolean
  ) => JSX.Element;
}

export const CurrencyInformation = ({
  cell,
  editMode,
  editable,
  value,
  handleNumberChangeOnInput,
  id,
  className,
  placeholder,
  errorMessage,
  renderModalDetails,
  detailsLabel,
  detailsTitle,
}: CurrencyInformationProps) => {
  if (editable && editMode) {
    return (
      <NumberInput
        className={styles.input}
        id={id}
        value={hasValue(value) ? Number(value) : ''}
        onChange={handleNumberChangeOnInput}
        color="gray"
        placeholder={placeholder}
        error={!!errorMessage}
        errorMessage={errorMessage}
      />
    );
  }

  if (renderModalDetails) {
    return (
      <TextWithDetailsInformation
        editMode={editMode}
        editable={editable}
        cell={
          typeof cell === 'number'
            ? englishFormattedEuroCurrency(Number(cell))
            : ''
        }
        id={id}
        renderModalDetails={renderModalDetails}
        detailsLabel={detailsLabel}
        detailsTitle={detailsTitle}
      />
    );
  }

  return (
    <span className={classNames(className, styles.rowData)}>
      {typeof cell === 'number'
        ? englishFormattedEuroCurrency(Number(cell))
        : ''}
    </span>
  );
};
