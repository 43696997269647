import {
  ApolloCache,
  DefaultContext,
  MutationUpdaterFunction,
  StoreObject,
} from '@apollo/client';

type CreateNoteMutationUpdaterFn = MutationUpdaterFunction<
  any,
  {
    /**
     * Incorrect typing for the field "body," it should be a mandatory field.
     * For some reason, Apollo TS types define this as OperationVariables which doesn't require body to be a mandatory field.
     * This is a temporary solution to please TS compiler for Apollo. This should be harmless as it's not part of any validation.
     */
    body?: string;
    claimId?: string;
    policyId?: string;
    userId?: string;
    parentNoteId?: string;
  },
  DefaultContext,
  ApolloCache<any>
>;

export const updateCreateNoteCache: CreateNoteMutationUpdaterFn | undefined = (
  cache,
  result
) => {
  cache.modify({
    fields: {
      notes: (previous, { toReference }) => {
        return [toReference(result.data.createNote), ...previous];
      },
    },
  });
};

type DeleteNoteMutationUpdaterFn = MutationUpdaterFunction<
  any,
  {
    noteId?: string;
  },
  DefaultContext,
  ApolloCache<any>
>;

export const updateDeleteNoteCache: DeleteNoteMutationUpdaterFn | undefined = (
  cache,
  result
) => {
  cache.modify({
    fields: {
      notes: (existingNotes, { readField }) => {
        return existingNotes.filter((note: StoreObject) => {
          const deletedNoteId = result.data.deleteNote.id;
          const noteId = readField('id', note);

          return noteId !== deletedNoteId;
        });
      },
    },
  });
};
