import { DocumentSection } from 'components/DocumentsSection/DocumentsSection';
import { getDocumentSections } from 'pages/policies/template/defaultPolicyDocumentsConfig';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';
import { retrieveFileFriendlyName } from 'shared/utils/retrieveFileFriendlyName';

import { DentalPolicyData } from '../models/DentalPolicy';

export const getDentalDocumentSections = (
  data?: DentalPolicyData
): DocumentSection[] => {
  const documentSections = getDocumentSections(data?.dentalPolicy);

  const scannedDocuments: DocumentSection = {
    title: 'Scanned documents',
    documents:
      data?.dentalPolicy.scannedDocuments?.flatMap(
        ({ url, blobName, id, visibleInUserAccount }) => [
          {
            id,
            url,
            fileName: retrieveFileFriendlyName(blobName),
            resizedUrl: url,
            blobName,
            isVisibleToCustomer: visibleInUserAccount,
            detailsUrl: generatePath(paths.scannedDocuments.details.path, {
              id,
            }),
          },
        ]
      ) ?? [],
  };

  documentSections.splice(4, 0, scannedDocuments);
  return documentSections;
};
