import classNames from 'classnames';

export const section = classNames(
  'mt-[16px]',
  'flex',
  'flex-col',
  'space-y-2',
  'm-[5px]'
);

export const sectionTitle = classNames('text-sm', 'text-gray-900', 'font-bold');

export const text = classNames('text-sm', 'text-gray-600');

export const radioButtons = classNames(
  'flex',
  'items-center',
  'space-x-[32px]'
);

export const label = classNames('text-sm', 'font-bold', 'text-gray-900');

export const input = classNames('!py-[9px]', '!px-[12px]');

export const formWrapper = classNames(
  'transition-max-height',
  'ease-out',
  'duration-500',
  'overflow-hidden'
);
