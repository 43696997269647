import classNames from 'classnames';

export const errorCard = classNames(
  'p-[16px]',
  'rounded-[6px]',
  'flex',
  'items-start',
  'flex-col',
  'animate-appear-down',
  'mt-[8px]'
);

export const errorCardWarning = classNames('bg-yellow-50');
export const errorCardError = classNames('bg-red-100');

export const header = classNames(
  'flex',
  'justify-between',
  'w-full',
  'items-start'
);

export const titleWrapper = classNames('flex');

export const exclamationIcon = classNames('w-[20px]', 'h-[20px]', 'mr-[10px]');

export const exclamationIconWarning = classNames('text-yellow-400');
export const exclamationIconError = classNames('text-red-400');

export const title = classNames('text-sm', 'font-bold');

export const titleWarning = classNames('text-yellow-800');
export const titleError = classNames('text-red-900');

export const closeIcon = classNames('w-[20px]');

export const closeIconWarning = classNames('text-yellow-900');
export const closeIconError = classNames('text-red-900');

export const description = classNames('text-sm', 'px-[30px]', 'mt-[4px]');

export const descriptionWarning = classNames('text-yellow-700');
export const descriptionError = classNames('text-red-800');

export const actionButtonsSection = classNames(
  'ml-[30px]',
  'mt-4',
  'flex',
  'space-x-3'
);

export const button = classNames(
  'rounded-md',
  'text-sm',
  'py-[6px]',
  'px-2',
  'w-fit',
  'font-bold'
);
export const warningPrimaryButton = classNames(
  'bg-yellow-100',
  'text-yellow-800'
);
export const errorPrimaryButton = classNames('bg-red-200', 'text-red-800');

export const warningSecondaryButton = classNames('text-yellow-800');
export const errorSecondaryButton = classNames('text-red-800');
