import { DocumentNode, useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import { capitalizeName } from '@getpopsure/public-utility';
import Card from 'components/Card';
import Link from 'components/Link';
import { RichTextPreview } from 'components/RichTextEditor/components/RichTextPreview';
import dayjs from 'dayjs';
import { DATE_FORMAT_TIME } from 'models/date';
import { Note } from 'models/Note';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { truncatedString } from 'shared/utils/truncatedString';
import { v4 as uuidv4 } from 'uuid';

import { UPDATE_NOTE } from '../graphql/Notes.mutations';
import { PinIcon } from '../icons/PinIcon';
import * as styles from './styles';

interface Props {
  notes: Note[];
  refetchQueries?: DocumentNode[];
}

export const PinnedNotes = ({ notes, refetchQueries }: Props) => {
  const { t } = useTranslation();

  const alertBannersState = useReactiveVar(alertBanners);

  const onUnpinCompleted = () => {
    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'SUCCESS',
      message: 'Note unpinned successfully',
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };

  const onUnpinErrored = () => {
    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'WARNING',
      message: 'Something went wrong. Please try again.',
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };

  const [unpinNote] = useMutation(UPDATE_NOTE, {
    onCompleted: onUnpinCompleted,
    onError: onUnpinErrored,

    /**
     * TODO: [KONG] Look into how to update nested cache without a lot of code
     * This is a temporary solution for updated list of pinned notes.
     * Cache updating requires a lot of logic for nested queries / fragments which isn't very maintainable
     */
    refetchQueries,
  });

  const onUnpinNote = (noteId: string) => {
    unpinNote({
      variables: {
        noteId,
        pinned: false,
      },
    });
  };

  const hasNoNotes = notes.length === 0;

  return (
    <Card
      title={t('components.notes.pinnedNotes.title', 'Pinned notes')}
      boldTitle={false}
    >
      <div className={styles.cardInnerWrapper}>
        {hasNoNotes && (
          <div className={styles.noNotesWrapper}>
            <h4 className={styles.noNotesTitle}>
              {t('components.notes.pinnedNotes.noNotes.title', 'No notes')}
            </h4>
            <p className={styles.noNotesDescription}>
              {t(
                'components.notes.pinnedNotes.noNotes.description',
                'The pinned notes will appear here'
              )}
            </p>
          </div>
        )}

        {notes.map(({ id, adminUser, body, createdAt, updatedAt }) => {
          const name =
            adminUser?.firstName && adminUser?.lastName
              ? capitalizeName({
                  firstName: adminUser?.firstName,
                  lastName: adminUser?.lastName,
                })
              : null;

          const dateTimeToDisplay = updatedAt || createdAt;

          return (
            <div className={styles.content}>
              <header className={styles.header}>
                <h4 className={styles.authorName}>
                  {name ?? t('components.notes.pinnedNotes.name', 'No name')}
                </h4>
                <button
                  className={styles.pinButton}
                  type="button"
                  onClick={() => onUnpinNote(id)}
                  aria-label="pin note"
                >
                  <PinIcon pinned />
                </button>
              </header>
              <div className="mt-[8px]">
                <RichTextPreview
                  dangerouslySetHtml={truncatedString({
                    target:
                      body ??
                      t(
                        'components.notes.pinnedNotes.noBody',
                        'No content found.'
                      ),
                    maxLength: 130,
                  })}
                />
              </div>
              <p className={styles.date}>
                {dateTimeToDisplay
                  ? dayjs(dateTimeToDisplay).format(DATE_FORMAT_TIME)
                  : 'No date'}{' '}
                {updatedAt && '(edited)'}
              </p>
            </div>
          );
        })}

        <section className={styles.footerAction}>
          <Link
            text={t(
              'components.notes.pinnedNotes.viewAllNotes',
              'View all notes'
            )}
            color="secondary"
            href="#notes"
            className={styles.viewAllNotesButton}
          />
        </section>
      </div>
    </Card>
  );
};
