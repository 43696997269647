import { gql } from '@apollo/client';

export const UPDATE_PRIVATE_HEALTH_CLAIM_STATUS = gql`
  mutation UpdatePrivateHealthClaimStatus(
    $claimId: String!
    $email: String!
    $policyId: String!
    $userId: String!
    $status: String!
    $uploadDocument: Upload
    $fileName: String
  ) {
    updatePrivateHealthClaimStatus(
      claimId: $claimId
      email: $email
      policyId: $policyId
      userId: $userId
      status: $status
      uploadDocument: $uploadDocument
      fileName: $fileName
    ) {
      id
      status
    }
  }
`;
