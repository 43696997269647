import { gql } from '@apollo/client';

export const UPDATE_GENERIC_CLAIM = gql`
  mutation UpdateGenericClaim(
    $claimId: String!
    $claimType: [String]
    $eventDescription: String
    $iban: String
    $amount: Float
    $refundAmount: Float
    $featherPaidOn: String
    $customerPaidOn: String
    $thirdPartyPaidOn: String
    $dateOfEvent: String
  ) {
    updateGenericClaim(
      claimId: $claimId
      claimType: $claimType
      eventDescription: $eventDescription
      dateOfEvent: $dateOfEvent
      iban: $iban
      amount: $amount
      refundAmount: $refundAmount
      featherPaidOn: $featherPaidOn
      customerPaidOn: $customerPaidOn
      thirdPartyPaidOn: $thirdPartyPaidOn
    ) {
      id
      claimType {
        id
        type
      }
      eventDescription
      iban
      amount
      refundAmount
      featherPaidOn
      customerPaidOn
      thirdPartyPaidOn
      dateOfEvent
    }
  }
`;
