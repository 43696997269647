import { BadgeColor } from 'components/Badge/Badge';

import { PrivateHealthClaimStatus } from './models';

export const badgeColorMapper: Record<PrivateHealthClaimStatus, BadgeColor> = {
  RECEIVED: 'purple',
  SUBMITTED_TO_PROVIDER: 'blue',
  CLOSED: 'gray',
  DUPLICATE: 'gray',
  DROPPED_OUT: 'gray',
  INCOMPLETE: 'red',
  MISSING_INFO_RECEIVED: 'purple',
  DENIED: 'yellow',
};
