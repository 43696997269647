import { capitalizeName } from '@getpopsure/public-utility';
import { PolicyTemplateData } from 'pages/policies/template/models/PolicyTemplateData';
import { insuranceNameMapper } from 'shared/insurances/insuranceNameMapper';
import { policyDocumentCategories } from 'shared/policyDocumentCategories';

import { NUMBER_OF_POLICIES_PER_PAGE } from '../template/constants';
import { TemplateInsuranceType } from '../template/models/InsuranceTypes';
import { getDbInsuranceType } from '../template/utils';
import { getDentalDocumentSections } from './data/documentsData';
import { getOverviewTabCards } from './data/overviewTabData';
import { getQuestionnaireData } from './data/questionnaireData';
import {
  dentalNextStatuses,
  dentalStatusOptions,
  extendedValidatorObject,
  getDentalModals,
  retrieveModalId,
} from './data/statusData';
import { rowConfig, tableHeaders } from './data/tableData';
import { tableFilters } from './data/tableFilters';
import { GET_DENTAL_POLICIES } from './graphql/policies';
import { GET_DENTAL_POLICY } from './graphql/singlePolicy';
import { UPDATE_DENTAL_QUESTIONNAIRE } from './graphql/updateQuestionnaire';
import { UPDATE_DENTAL_POLICY_STATUS } from './graphql/updateStatus';
import { DentalPoliciesData } from './models/DentalPolicies';
import { DentalPolicyData } from './models/DentalPolicy';

export const getDentalPoliciesTemplateData = (): PolicyTemplateData<
  DentalPoliciesData,
  DentalPolicyData,
  string
> => {
  const insurancePath = 'dental';
  const insuranceType = getDbInsuranceType(
    insurancePath
  ) as TemplateInsuranceType;
  const insuranceName = insuranceNameMapper[insuranceType];

  return {
    insuranceType,
    navigation: {
      current: `policies-${insurancePath}`,
    },
    queries: {
      allPolicies: GET_DENTAL_POLICIES,
      policy: GET_DENTAL_POLICY,
    },
    mutations: {
      statusChange: UPDATE_DENTAL_POLICY_STATUS,
      editQuestionnaire: UPDATE_DENTAL_QUESTIONNAIRE,
    },
    copy: {
      table: {
        title: `${insuranceName} policies`,
        searchPlaceholder:
          'Search by name, email, policy number, policy id or utm source',
      },
      details: {
        pageTitle: `${insuranceName} policy - Feather Admin Panel`,
        title: insuranceName,
      },
    },
    features: {
      overviewTable: {
        numberOfRowsPerPage: NUMBER_OF_POLICIES_PER_PAGE,
        headersConfig: tableHeaders,
        filtersConfig: tableFilters,
        rowConfig,
      },
      details: {
        queryVariables: {
          insuranceType,
        },
        // TODO: [KONG] Move to component level
        insuredPersonName: (data?: DentalPolicyData) =>
          capitalizeName({
            firstName: data?.dentalPolicy.insuredPerson?.firstName ?? '',
            lastName: data?.dentalPolicy.insuredPerson?.lastName ?? '',
          }) ?? '',
        overviewTab: {
          getCards: getOverviewTabCards,
        },
      },
      statusChange: {
        statusOptions: dentalStatusOptions,
        statusChangeOptions: dentalNextStatuses,
        getModals: getDentalModals,
        retrieveModalId,
        extendedValidatorObject,
      },
      documents: {
        uploadOptions: policyDocumentCategories,
        getDocumentSections: getDentalDocumentSections,
      },
      questionnaire: {
        cardTitle: 'Personal information',
        editable: true,
        getQuestionnaireData,
      },
    },
  };
};
