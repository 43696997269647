import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';
import { Country } from 'shared/countries/models';
import { genericInsuranceTypes, InsuranceType } from 'shared/insurances/types';

import { BikePolicy } from '../bike/models/BikePolicy';
import { DentalPolicy } from '../dental/models/DentalPolicy';
import { DogLiabilityPolicy } from '../dogLiability/models/DogLiabilityPolicy';
import { ExpatSpainPolicy } from '../expatSpain/models/ExpatSpainPolicy';
import { isGenericInsuranceType } from '../generic/models/GenericInsuranceType';
import { GenericPolicy } from '../generic/models/GenericPolicy';
import { HouseholdPolicy } from '../household/models/HouseholdPolicy';
import { CompanyHealthPolicy } from '../hr/companyHealth/models/CompanyHealthPolicy';
import { CompanyLifePolicy } from '../hr/companyLife/models/CompanyLifePolicy';
import { CompanyPensionPolicy } from '../hr/companyPension/models/CompanyPensionPolicy';
import { LegalPolicy } from '../legal/models/LegalPolicy';
import { LiabilityPolicy } from '../liability/models/LiabilityPolicy';
import { LifePolicy } from '../life/models/LifePolicy';
import {
  hrInsurancePaths,
  HRTemplateInsuranceType,
  insurancePaths,
  PolicyHRTemplateInsurancePath,
  PolicyTemplateInsurancePath,
  TemplateInsuranceType,
} from './models/InsuranceTypes';

/**
 * TODO: [KONG] Refactor insurance types:
 * 1. Remove "generic"
 * 2. Reuse InsuranceType and replace other insurance names/values with a mapper
 */

export const isValidTemplateInsurancePath = (
  path: any
): path is PolicyTemplateInsurancePath => {
  return Boolean(insurancePaths.includes(path));
};

export const isValidHRTemplateInsurancePath = (
  path: any
): path is PolicyHRTemplateInsurancePath => {
  return Boolean(hrInsurancePaths.includes(path));
};

export const getHRPolicyData = (
  insuranceType: TemplateInsuranceType | HRTemplateInsuranceType,
  data: unknown
) => {
  if (insuranceType === 'COMPANY_PENSION') {
    return (
      (data as { hrCompanyPensionPolicy: CompanyPensionPolicy | null })
        ?.hrCompanyPensionPolicy ?? null
    );
  }
  if (insuranceType === 'COMPANY_HEALTH') {
    return (
      (data as { hrCompanyHealthPolicy: CompanyHealthPolicy | null })
        ?.hrCompanyHealthPolicy ?? null
    );
  }
  if (insuranceType === 'COMPANY_LIFE') {
    return (
      (data as { hrCompanyLifePolicy: CompanyLifePolicy | null })
        ?.hrCompanyLifePolicy ?? null
    );
  }
  if (insuranceType === 'PUBLIC_HEALTH') {
    return (
      (data as { hrPublicHealthPolicy: PublicHealthPolicy | null })
        ?.hrPublicHealthPolicy ?? null
    );
  }
};

export const getPolicyData = (
  insuranceType: TemplateInsuranceType | HRTemplateInsuranceType,
  data: unknown
) => {
  if (isGenericInsuranceType(insuranceType)) {
    return (
      (data as { genericPolicy: GenericPolicy | null })?.genericPolicy ?? null
    );
  }

  if (insuranceType === 'DENTAL') {
    return (
      (data as { dentalPolicy: DentalPolicy | null })?.dentalPolicy ?? null
    );
  }

  if (insuranceType === 'HOUSEHOLD') {
    return (
      (data as { householdPolicy: HouseholdPolicy | null })?.householdPolicy ??
      null
    );
  }

  if (insuranceType === 'LIABILITY') {
    return (
      (data as { liabilityPolicy: LiabilityPolicy | null })?.liabilityPolicy ??
      null
    );
  }

  if (insuranceType === 'LEGAL') {
    return (data as { legalPolicy: LegalPolicy | null })?.legalPolicy ?? null;
  }

  if (insuranceType === 'LIFE') {
    return (data as { lifePolicy: LifePolicy | null })?.lifePolicy ?? null;
  }

  if (insuranceType === 'BIKE') {
    return (data as { bikePolicy: BikePolicy | null })?.bikePolicy ?? null;
  }

  if (insuranceType === 'DOG_LIABILITY') {
    return (
      (data as { dogLiabilityPolicy: DogLiabilityPolicy | null })
        ?.dogLiabilityPolicy ?? null
    );
  }

  if (insuranceType === 'INCOMING_ES') {
    return (
      (data as { expatSpainPolicy: ExpatSpainPolicy | null })
        ?.expatSpainPolicy ?? null
    );
  }

  return null;
};

export const retrievePoliciesOverviewPath = (
  insuranceType: PolicyTemplateInsurancePath,
  country: Country = 'de'
) =>
  generatePath(paths.policies.internal.path, {
    insuranceType,
    country,
  });

export const retrieveHRPoliciesOverviewPath = (
  insuranceType: PolicyHRTemplateInsurancePath,
  country: Country = 'de',
  company: string
) =>
  generatePath(paths.policies.hr.path, {
    insuranceType,
    country,
    company,
  });

export const retrievePoliciesDetailsPath = ({
  insuranceType,
  id,
  country = 'de',
}: {
  insuranceType: PolicyTemplateInsurancePath | 'generic';
  id: string;
  country?: Country;
}) =>
  generatePath(paths.policies.internal.details.path, {
    insuranceType,
    id,
    country,
  });

export const retrieveHRPoliciesDetailsPath = ({
  insuranceType,
  id,
  company,
  country = 'de',
}: {
  insuranceType: PolicyHRTemplateInsurancePath;
  id: string;
  company: string;
  country?: Country;
}) =>
  generatePath(paths.policies.hr.details.path, {
    insuranceType,
    id,
    country,
    company,
  });

// TODO: [KONG] Remove & refactor once all insurance types are unified
export const getDbInsuranceType = (
  insuranceType: PolicyTemplateInsurancePath | 'generic'
): TemplateInsuranceType => {
  switch (insuranceType) {
    case 'basis-pension':
      return 'BASIS_PENSION';
    case 'bike':
      return 'BIKE';
    case 'car':
      return 'CAR';
    case 'company-health':
      return 'COMPANY_HEALTH';
    case 'company-life':
      return 'COMPANY_LIFE';
    case 'dental':
      return 'DENTAL';
    case 'disability':
      return 'DISABILITY';
    case 'dog-liability':
      return 'DOG_LIABILITY';
    case 'household':
      return 'HOUSEHOLD';
    case 'legal':
      return 'LEGAL';
    case 'liability':
      return 'LIABILITY';
    case 'life':
      return 'LIFE';
    case 'pension':
      return 'PENSION';
    case 'pet-health':
      return 'PET_HEALTH';
    case 'travel':
      return 'TRAVEL';
    case 'expat-health-es':
      return 'INCOMING_ES';
    case 'travel-fr':
      return 'TRAVEL_FR';
    case 'generic':
      return 'GENERIC';
    case 'expat-health-eu':
      return 'INCOMING_EU';
    case 'expat-health-long-term':
      return 'INCOMING_LT';
    case 'company-pension':
      return 'COMPANY_PENSION';
    case 'dog-liability-es':
      return 'DOG_LIABILITY_ES';
  }
};

export const getDbHRInsuranceType = (
  insuranceType: PolicyHRTemplateInsurancePath
): HRTemplateInsuranceType => {
  switch (insuranceType) {
    case 'public-health':
      return 'PUBLIC_HEALTH';
    case 'company-pension':
      return 'COMPANY_PENSION';
    case 'company-health':
      return 'COMPANY_HEALTH';
    case 'company-life':
      return 'COMPANY_LIFE';
    case 'private-health':
      return 'PRIVATE_HEALTH';
  }
};

// TODO: [KONG] Add insurances while adding archivable insurance
// This is a temporary solution for GENERIC policies before archive implementation
export const isArchivableInsurance = (insuranceType: InsuranceType): boolean =>
  [
    'DENTAL',
    'HOUSEHOLD',
    'LIABILITY',
    'LEGAL',
    'LIFE',
    'BIKE',
    'DOG_LIABILITY',
    ...genericInsuranceTypes,
  ].includes(insuranceType);

export const getOtherPolicies = (
  insuranceType: TemplateInsuranceType | HRTemplateInsuranceType,
  data: unknown
) => {
  if (insuranceType === 'DENTAL') {
    return (
      (data as { dentalPolicy: DentalPolicy | null })?.dentalPolicy?.user
        ?.dentalPolicies ?? null
    );
  }

  return null;
};
