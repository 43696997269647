import {
  combinedGenericClaimTypes,
  genericClaimStatuses,
} from 'shared/claims/generic/models';
import { z } from 'zod';

export const zodSubmittableClaimSchema = z.object({
  policyId: z.string().uuid(),
  status: z.enum(genericClaimStatuses),
  claimType: z.array(z.enum(combinedGenericClaimTypes)),
  eventDescription: z.string().optional(),
  amount: z.string().optional(),
  uploadDocument: z.custom<File>().optional(),
});
