import { useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import ActivityCircle from 'components/ActivityCircle';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { DATE_FORMAT_TIME } from 'models/date';
import { getAuthor } from 'shared/getAuthor';

import { Activity } from '../ActivityCards';
import * as styles from './styles';

dayjs.extend(relativeTime);

export interface PolicyArchiveActivityCardProps {
  activityCard: Activity;
  smallVersion: boolean;
  hasActivityLine: boolean;

  archivedAt?: string;
}

const PolicyArchiveActivityCard = ({
  activityCard,
  smallVersion,
  hasActivityLine,

  archivedAt,
}: PolicyArchiveActivityCardProps) => {
  const { t } = useTranslation();

  const activityAuthor = getAuthor(activityCard.author);

  const hasBeenArchived = Boolean(archivedAt);

  const description = hasBeenArchived
    ? t('policy.activity.archived', 'archived the policy')
    : t('policy.activity.unarchived', 'unarchived the policy');

  return (
    <div className={styles.cardWrapper}>
      <ActivityCircle
        activityType={activityCard.type}
        smallVersion={smallVersion}
        hasActivityLine={hasActivityLine}
      />
      <div className={smallVersion ? styles.cardSmall : styles.cardFull}>
        <div className={styles.lightText}>
          <p className={classNames(styles.darkText, styles.inlineText, 'mr-1')}>
            {activityAuthor}
          </p>
          <p>{description}</p>
          <p className={classNames(styles.veryLightText, styles.inlineText)}>
            {dayjs(archivedAt).format(DATE_FORMAT_TIME)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PolicyArchiveActivityCard;
