import { z } from 'zod';

const requiredError = 'This field is required.';

const baseSchema = {
  provider: z.string().min(1, { message: requiredError }),
  status: z.string().min(1, { message: requiredError }),
  startDate: z.string().min(1, { message: requiredError }),
  policyNumber: z.string().optional(),
  tariff: z.string().min(1, { message: requiredError }),
  deductible: z.string().min(1, { message: requiredError }),
  addOns: z.array(z.string()).optional(),
  price: z.string().optional(),
  file: z.custom<File>().optional(),
};

const existingCustomer = z.object({
  customerId: z.string(),
  newCustomer: z.literal(false).optional(),
  ...baseSchema,
});

const newCustomer = z.object({
  customerId: z.string().optional(),
  newCustomer: z.literal(true),
  firstName: z.string().min(1, { message: requiredError }),
  lastName: z.string().min(1, { message: requiredError }),
  email: z.string().min(1, { message: requiredError }),
  dateOfBirth: z.string().min(1, { message: requiredError }),
  ...baseSchema,
});

export const CreatePrivateHealthValidationSchema = z.union([
  existingCustomer,
  newCustomer,
]);
