import FileErrorCard from 'components/FileErrorCard';

export const CustomerDelinquencyBanner = ({
  customer,
  loading = false,
  linkToCustomer = false,
}: {
  customer?: {
    id: string;
    isDelinquent?: boolean | null;
  };
  loading?: boolean;
  linkToCustomer?: boolean;
}) => {
  if (loading) return null;
  if (!loading && customer?.isDelinquent === false) return null;

  const description = linkToCustomer ? (
    <>
      You can take this customer out of delinquency status manually on{' '}
      <a
        className="underline"
        href={`/internal/customers/${customer?.id}`}
        target="_blank"
        rel="noreferrer"
      >
        customer page
      </a>
    </>
  ) : null;

  return (
    <FileErrorCard
      open
      hideCloseButton
      handleClose={() => {}}
      title="Customer is delinquent"
      description={description}
      errorType="ERROR"
      className="!mt-0 mb-[16px]"
    />
  );
};
