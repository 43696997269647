import classNames from 'classnames';

export const issueCardWrapper = classNames(
  'p-[24px]',
  'rounded-[8px]',
  'w-[700px]',
  'bg-white',
  'mb-4',
  'last:mb-0'
);

export const header = classNames('flex', 'justify-between', 'items-center');
export const category = classNames('text-lg', 'text-gray-900', 'font-bold');
export const headerActions = classNames('flex', 'items-center', 'relative');
export const waitingForCustomer = classNames('text-sm', 'text-gray-500');

export const actionButton = classNames(
  'ml-[16px]',
  'flex',
  'flex-col',
  'justify-center',
  'items-center',
  'gap-y-[2px]',

  'w-[20px]',
  'h-[38px]',

  'group'
);
export const actionButtonDot = classNames(
  'bg-gray-500',
  'rounded-[50px]',
  'w-[4px]',
  'h-[4px]',
  'group-hover:bg-indigo-500',
  'group-focus:bg-indigo-500'
);

export const actionsDropdown = classNames(
  'absolute',
  'rounded-[4px]',
  'bg-white',
  'right-0',
  'top-11',
  'w-[200px]',
  'shadow-lg',
  'z-10',
  'border-gray-200',
  'border'
);
export const actionsDropdownButton = classNames(
  'text-gray-600',
  'text-sm',
  'px-[16px]',
  'py-[8px]',
  'w-full',
  'text-left',
  'hover:bg-indigo-500',
  'hover:text-white'
);
export const topBorder = classNames('border-t', 'border-gray-200');
export const actionsDropdownText = classNames(
  'text-gray-500',
  'text-sm',
  'px-[16px]',
  'py-[8px]',
  'w-full',
  'text-left'
);

export const description = classNames('mt-[16px]', 'text-sm', 'text-gray-600');
