import EditQuestionnaireButton from 'components/EditQuestionnaireButton/EditQuestionnaireButton';
import InformationCard, {
  InformationSection,
} from 'components/InformationCard';
import { DocumentNode } from 'graphql';
import { mapAnswersToInformationCard } from 'shared/questionnaire/mapAnswersToInformationCard';

import EmptyQuestionnaireState from './EmptyQuestionnaireState/EmptyQuestionnaireState';

interface QuestionnaireCardProps {
  questionnaireId: string;
  policyId: string;
  answers: object;
  cardTitle: string;
  mutation?: DocumentNode;
  editDescription?: string;
  editable?: boolean;
  refetchQueries?: DocumentNode[];
  additionalAction?: React.ReactNode;
}

const QuestionnaireCard = ({
  questionnaireId,
  answers,
  cardTitle,
  policyId,
  mutation,
  editDescription,
  editable = true,
  refetchQueries,
  additionalAction,
}: QuestionnaireCardProps) => {
  if (!questionnaireId && !answers) {
    return <EmptyQuestionnaireState />;
  }

  const questionnaireAnswers: InformationSection = {
    title: cardTitle,
    rows: mapAnswersToInformationCard(answers, [
      'legalGuardian',
      'legalGuardianName',
      'address',
      'placeOfBirth',
      'companyAddress',
    ]),
  };

  const editButton =
    editable && mutation ? (
      <EditQuestionnaireButton
        answers={answers}
        questionnaireId={questionnaireId}
        policyId={policyId}
        mutation={mutation}
        description={editDescription}
        refetchQueries={refetchQueries}
      />
    ) : (
      <></>
    );

  return (
    <InformationCard
      sections={[questionnaireAnswers]}
      additionalActions={
        <div className="flex space-x-2">
          {additionalAction} {editButton}
        </div>
      }
    />
  );
};

export default QuestionnaireCard;
