import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import SelectButton, { SelectButtonOption } from 'components/SelectButton';
import { UPDATE_POLICY_STATUS } from 'graphql/publicHealth/provider/mutations';
import { GET_PUBLIC_PROVIDER_POLICY } from 'graphql/publicHealth/provider/queries';
import { Issue } from 'models/issue';
import ChangeStatusWithFileUpload from 'pages/policies/publicHealth/components/ChangeStatusWithFileUpload';
import ChangeStatusWithIssue from 'pages/policies/publicHealth/components/ChangeStatusWithIssue';
import ChangeStatusWithOptionalIssue from 'pages/policies/publicHealth/components/ChangeStatusWithOptionalIssue';
import { useState } from 'react';
import { filterStatusChangeOptions } from 'shared/filterStatusChangeOptions';
import {
  getProviderPublicHealthStatusColor,
  ProviderPublicHealthStatus,
  ProviderPublicHealthStatusId,
} from 'shared/mapProviderPublicHealthStatus';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import ChangeStatusForm from '../../../../../../../components/ChangeStatusForm';

interface ChangeStatusProps {
  defaultStartDate: string;
  status: ProviderPublicHealthStatus;
  id: string;
  confirmationOfCoverageFromProvider?: string;
  openIssues?: Issue[];
}

const ChangeStatus = ({
  status,
  id,
  defaultStartDate,
  confirmationOfCoverageFromProvider,
  openIssues,
}: ChangeStatusProps) => {
  const { t } = useTranslation();
  const statusOptions: SelectButtonOption[] = [
    {
      title: t('admin.status.unicheck', 'Uni check'),
      label: t('admin.status.unicheck', 'Uni check'),
      id: 'unicheck',
      color: 'red',
    },
    {
      title: t('admin.status.new', 'New'),
      label: t('admin.status.new', 'New'),
      id: 'new',
      color: 'purple',
    },
    {
      title: t('admin.status.processing', 'Processing'),
      label: t('admin.status.processing', 'Processing'),
      id: 'processing',
      color: 'blue',
    },
    {
      title: t('admin.status.covered', 'Covered'),
      label: t('admin.status.covered', 'Covered'),
      id: 'covered',
      color: 'green',
    },
    {
      title: t('admin.status.missinginfo', 'Missing info'),
      label: t('admin.status.missinginfo', 'Missing info'),
      id: 'missinginfo',
      color: 'pink',
    },
    {
      title: t('admin.status.noteligible', 'Not eligible'),
      label: t('admin.status.noteligible', 'Not eligible'),
      id: 'noteligible',
      color: 'yellow',
    },
    {
      title: t('admin.status.canceled', 'Canceled'),
      label: t('admin.status.canceled', 'Canceled'),
      id: 'canceled',
      color: 'gray',
    },
  ];

  const alertBannersState = useReactiveVar(alertBanners);

  const [updateStatus, { loading }] = useMutation(UPDATE_POLICY_STATUS, {
    refetchQueries: [GET_PUBLIC_PROVIDER_POLICY],
    awaitRefetchQueries: true,
    onCompleted: () => {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message: t(
          'admin.alert.changestatus.success.header',
          'Status successfully updated.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
    onError: () => {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: t(
          'admin.alert.changestatus.warning.header',
          'Something went wrong. Please try again.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
  });

  const [form, setForm] = useState(false);
  const [formWithIssue, setFormWithIssue] = useState(false);
  const [formWithFile, setFormWithFile] = useState(false);
  const [formWithOptionalIssue, setFormWithOptionalIssue] = useState(false);
  const [newStatus, setNewStatus] = useState<ProviderPublicHealthStatus | null>(
    null
  );

  const currentStatus =
    statusOptions.find((element) => element.id === status.id) ??
    statusOptions[0];

  let currentStatusOptions: SelectButtonOption[] = [];

  switch (currentStatus.id) {
    case 'unicheck':
      currentStatusOptions = filterStatusChangeOptions(
        ['processing', 'covered', 'missinginfo', 'canceled'],
        statusOptions
      );
      break;
    case 'new':
      currentStatusOptions = filterStatusChangeOptions(
        ['unicheck'],
        statusOptions
      );
      break;
    case 'processing':
      currentStatusOptions = filterStatusChangeOptions(
        ['unicheck', 'new'],
        statusOptions
      );
      break;
    case 'covered':
      currentStatusOptions = filterStatusChangeOptions(
        ['unicheck', 'new', 'processing', 'missinginfo'],
        statusOptions
      );
      break;
    case 'missinginfo':
      currentStatusOptions = filterStatusChangeOptions(
        ['unicheck', 'new'],
        statusOptions
      );
      break;
    case 'noteligible':
    case 'canceled':
      currentStatusOptions = [];
      break;
  }

  const handleStatusChange = (option: SelectButtonOption) => {
    if (option.id === status.id) {
      return;
    }
    setNewStatus({
      id: option.id as ProviderPublicHealthStatusId,
      text: option.label,
    });

    switch (option.id) {
      case 'new':
        setForm(true);
        break;
      case 'processing':
        setFormWithOptionalIssue(true);
        break;
      case 'missinginfo':
      case 'canceled':
      case 'noteligible':
        setFormWithIssue(true);
        break;
      case 'covered':
        setFormWithFile(true);
    }
  };

  const handleUpdateStatus = (
    variables: {
      status: string;
      category?: string;
      description?: string;
      isCustomerInformed?: boolean;
      file?: File;
      startDate?: string;
      issueIds?: string[];
    },
    formId: string
  ) => {
    updateStatus({
      variables: {
        id,
        fileType: 'CONFIRMATION_OF_COVERAGE',
        newIssueStatus: 'resolved',
        ...variables,
      },
    })
      .catch(() => {})
      .then(() => {
        switch (formId) {
          case 'form':
            setForm(false);
            break;
          case 'formWithFile':
            setFormWithFile(false);
            break;
          case 'formWithIssue':
            setFormWithIssue(false);
            break;
          case 'formWithOptionalIssue':
            setFormWithOptionalIssue(false);
        }
      });
  };

  return (
    <>
      {form && (
        <ChangeStatusForm
          open={form}
          setOpen={setForm}
          updateStatus={handleUpdateStatus}
          newStatus={newStatus}
          openIssues={openIssues}
          loading={loading}
          showIssuesSection={newStatus?.id !== 'processing'}
          newStatusColor={
            newStatus ? getProviderPublicHealthStatusColor(newStatus) : 'gray'
          }
          formFields={[]}
          formId="form"
        />
      )}
      {formWithIssue && (
        <ChangeStatusWithIssue
          open={formWithIssue}
          setOpen={setFormWithIssue}
          updateStatus={handleUpdateStatus}
          newStatus={newStatus}
          openIssues={openIssues}
          loading={loading}
          providerView={true}
        />
      )}
      {formWithFile && (
        <ChangeStatusWithFileUpload
          open={formWithFile}
          setOpen={setFormWithFile}
          updateStatus={handleUpdateStatus}
          newStatus={newStatus}
          defaultStartDate={defaultStartDate}
          confirmationOfCoverage={confirmationOfCoverageFromProvider}
          openIssues={openIssues}
          loading={loading}
          providerView={true}
        />
      )}
      {formWithOptionalIssue && (
        <ChangeStatusWithOptionalIssue
          open={formWithOptionalIssue}
          setOpen={setFormWithOptionalIssue}
          updateStatus={handleUpdateStatus}
          newStatus={newStatus}
          loading={loading}
          providerView={true}
        />
      )}
      <SelectButton
        withDot={true}
        options={currentStatusOptions}
        handleOnChange={handleStatusChange}
        selected={currentStatus}
        disabled={currentStatusOptions.length === 0 || loading}
        label={t('admin.policytable.filterstatus.title', 'Status')}
      />
    </>
  );
};

export default ChangeStatus;
