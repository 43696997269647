import { TranslationFn } from '@getpopsure/i18n-react';
import { Gender } from '@popsure/public-models';

export const mapGender = (t: TranslationFn): { [T in Gender]: string } => {
  return {
    MALE: t(
      'admin.provider.policy.public.questionnaire.gender.male.label',
      'Male'
    ),
    FEMALE: t(
      'admin.provider.policy.public.questionnaire.gender.female.label',
      'Female'
    ),
    OTHER: t(
      'admin.provider.policy.public.questionnaire.gender.other.label',
      'Other'
    ),
  };
};
