import { gql } from '@apollo/client';

export const UPDATE_HOUSEHOLD_CLAIM = gql`
  mutation UpdateHouseholdClaim(
    $claimId: String!
    $claimType: [String]
    $dateOfEvent: String
    $eventDescription: String
    $iban: String
    $amount: Float
    $refundAmount: Float
    $otherCosts: Float
    $featherPaidOn: String
    $customerPaidOn: String
    $thirdPartyPaidOn: String
  ) {
    updateHouseholdClaim(
      claimId: $claimId
      claimType: $claimType
      dateOfEvent: $dateOfEvent
      eventDescription: $eventDescription
      iban: $iban
      amount: $amount
      refundAmount: $refundAmount
      otherCosts: $otherCosts
      featherPaidOn: $featherPaidOn
      customerPaidOn: $customerPaidOn
      thirdPartyPaidOn: $thirdPartyPaidOn
    ) {
      id
      claimType {
        id
        type
      }
      dateOfEvent
      eventDescription
      iban
      amount
      refundAmount
      featherPaidOn
      customerPaidOn
      thirdPartyPaidOn
      otherCosts
    }
  }
`;
