import {
  DocumentSection,
  DocumentsSection as DocumentsCard,
} from 'components/DocumentsSection/DocumentsSection';
import { SelectMenuOption } from 'components/SelectMenu';
import { INTERNAL_UPLOAD_PUBLIC_POLICY_DOCUMENT } from 'graphql/publicHealth/internal/mutations';
import { GET_PUBLIC_POLICY } from 'graphql/publicHealth/internal/queries';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import { EditButton } from 'pages/policies/template/EditButton/EditButton';
import {
  ARCHIVE_POLICY_DOCUMENT,
  DELETE_POLICY_DOCUMENT,
  EDIT_POLICY_DOCUMENT,
  GENERATE_POLICY_DOCUMENTS_ARCHIVE,
  GET_POLICY_DOCUMENTS_ARCHIVE,
  RESTORE_POLICY_DOCUMENT,
} from 'pages/policies/template/mutations';
import { UploadButton } from 'pages/policies/template/UploadButton/UploadButton';
import { policyDocumentCategories } from 'shared/policyDocumentCategories';
import { retrieveFileFriendlyName } from 'shared/utils/retrieveFileFriendlyName';

interface DocumentsSectionProps {
  data: PublicHealthPolicy;
}

const DocumentsSection = ({ data }: DocumentsSectionProps) => {
  const documents: DocumentSection[] = [
    {
      title: 'Feather uploads',
      documents: [
        ...data.policyDocuments
          .filter(
            (document) => !document.isFromProvider && !document.archivedAt
          )
          .map((document) => {
            return {
              ...document,
              friendlyName: document.name,
              fileName: document.blobName
                ? retrieveFileFriendlyName(document.blobName)
                : document.name,
              canArchive: true,
              canDelete: true,
              canEdit: true,
            };
          }),
        ...(data.powerOfAttorneyUrl
          ? [
              {
                id: '',
                url: data.powerOfAttorneyUrl,
                fileName: 'Power of attorney',
              },
            ]
          : []),
      ],
    },
    {
      title: 'Customer uploads',
      documents: [
        ...(data.healthCard?.photoUrl
          ? [
              {
                id: '',
                url: data.healthCard?.photoUrl,
                fileName: 'Health card photo',
              },
            ]
          : []),
        ...(data.customerUploads?.map((document) => ({
          ...document,
          friendlyName: document.name,
          fileName: retrieveFileFriendlyName(document.blobName),
        })) ?? []),
      ],
    },
    {
      title: 'Provider uploads',
      documents: data.policyDocuments
        .filter((document) => document.isFromProvider && !document.archivedAt)
        .map((document) => {
          return {
            ...document,
            friendlyName: document.name,
            fileName: document.blobName
              ? retrieveFileFriendlyName(document.blobName)
              : document.name,
            canArchive: true,
            canDelete: true,
            canEdit: true,
          };
        }),
    },
    {
      title: 'Student checklist',
      documents: [
        ...(data.studentChecklist?.acceptanceLetterUrl
          ? [
              {
                id: '',
                url: data.studentChecklist?.acceptanceLetterUrl,
                fileName: 'Acceptance letter',
              },
            ]
          : []),
        ...(data.studentChecklist?.proofOfConfirmationOfCoverage
          ? [
              {
                id: '',
                url: data.studentChecklist?.proofOfConfirmationOfCoverage,
                fileName: 'Prelim. confirmation of coverage (Feather)',
              },
            ]
          : []),
        ...(data.studentChecklist?.uniEnrollmentCertificateUrl
          ? [
              {
                id: '',
                url: data.studentChecklist?.uniEnrollmentCertificateUrl,
                fileName: 'Enrollment certificate',
              },
            ]
          : []),
      ],
    },
    {
      title: 'Archived documents',
      documents: data.policyDocuments
        .filter((document) => document.archivedAt)
        .map((document) => ({
          ...document,
          friendlyName: document.name,
          fileName: document.blobName
            ? retrieveFileFriendlyName(document.blobName)
            : document.name,
          canArchive: true,
          canDelete: true,
        })),
    },
  ];

  const fileTypeOptions: SelectMenuOption[] = [
    ...policyDocumentCategories,
    ...(data.occupation === 'UNIVERSITY_STUDENT'
      ? [
          {
            id: 'STUDENT_PRELIMINARY_CONFIRMATION_OF_COVERAGE',
            label: 'Student Prelim. confirmation of coverage',
          },
          { id: 'UNI_ENROLLMENT_CERTIFICATE', label: 'Enrollment certificate' },
          { id: 'ACCEPTANCE_LETTER', label: 'Acceptance letter' },
        ]
      : []),
  ].sort((a, b) => a.label.localeCompare(b.label));

  return (
    <>
      <DocumentsCard
        sections={documents}
        documentCategories={fileTypeOptions}
        getResourceQuery={GET_PUBLIC_POLICY}
        resourceId={data.id}
        deleteMutation={DELETE_POLICY_DOCUMENT}
        editMutation={EDIT_POLICY_DOCUMENT}
        archiveMutation={ARCHIVE_POLICY_DOCUMENT}
        restoreMutation={RESTORE_POLICY_DOCUMENT}
        downloadAllMutation={GENERATE_POLICY_DOCUMENTS_ARCHIVE}
        getDocumentArchiveQuery={GET_POLICY_DOCUMENTS_ARCHIVE}
        documentVisibilityOptions={[
          { id: 'CUSTOMER', label: 'Customer' },
          { id: 'HR', label: 'HR' },
          { id: 'PROVIDER', label: 'Provider' },
        ]}
        uploadButton={
          <UploadButton
            mutation={INTERNAL_UPLOAD_PUBLIC_POLICY_DOCUMENT}
            documentCategories={fileTypeOptions}
            resourceId={data.id}
            documentVisibilityOptions={[
              { id: 'CUSTOMER', label: 'Customer' },
              { id: 'PROVIDER', label: 'Provider' },
              { id: 'HR', label: 'HR' },
            ]}
            getResourceQuery={GET_PUBLIC_POLICY}
          />
        }
        editButton={EditButton}
      />
    </>
  );
};

export default DocumentsSection;
