import { ChangeEvent } from 'react';

import Input, { InputProps } from '../Input';

export const CurrencyInput = ({
  onChange,
  ...props
}: InputProps & { onChange: (value: string) => void }) => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!Number.isNaN(Number(e.target.value))) {
      // TODO: [KONG] Update to store number
      onChange(e.target.value);
    }
  };

  return <Input {...props} onChange={handleOnChange} prefix="€" suffix="EUR" />;
};
