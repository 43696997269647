import classNames from 'classnames';
import React from 'react';

import * as styles from './styles';

type TextAreaColor = 'white' | 'gray';

interface TextAreaProps extends React.ComponentPropsWithRef<'textarea'> {
  color: TextAreaColor;
  error?: boolean;
  errorMessage?: string;
}

const TextArea = React.forwardRef(
  (
    { color, className, error, errorMessage, ...props }: TextAreaProps,
    ref?: React.ForwardedRef<HTMLTextAreaElement>
  ) => {
    return (
      <>
        <textarea
          className={classNames(className, styles.textarea, {
            [styles.textAreaWhite]: color === 'white',
            [styles.textAreaGrey]: color === 'gray',
            [styles.errorState]: error,
          })}
          {...props}
          ref={ref}
        />
        {error && <p className={styles.errorMessage}>{errorMessage}</p>}
      </>
    );
  }
);

export default TextArea;
