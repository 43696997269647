import { SelectButtonOption } from 'components/SelectButton';
import { StatusUpdateFormQuestion } from 'components/StatusUpdateModal/models';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';
import {
  legalBadgeColorMapper,
  legalStatusMapper,
} from 'shared/insurances/statusMappers/legal';
import { LegalStatus } from 'shared/insurances/types';
import { z } from 'zod';

import { LegalPolicyData } from '../models/LegalPolicy';

export const legalNextStatuses: Record<LegalStatus, LegalStatus[]> = {
  PENDING: ['PENDING', 'ACTIVE', 'CANCELED'],
  ACTIVE: ['PENDING', 'ACTIVE', 'CANCELED'],
  CANCELED: ['PENDING', 'ACTIVE', 'CANCELED'],
  DROPPED_OUT: ['PENDING', 'ACTIVE', 'CANCELED'],
  APPLICATION_SENT: ['PENDING', 'ACTIVE', 'CANCELED'],
};

export const legalStatusOptions: SelectButtonOption<LegalStatus>[] = [
  {
    title: legalStatusMapper.PENDING,
    label: legalStatusMapper.PENDING,
    id: 'PENDING',
    color: legalBadgeColorMapper.PENDING,
  },
  {
    title: legalStatusMapper.ACTIVE,
    label: legalStatusMapper.ACTIVE,
    id: 'ACTIVE',
    color: legalBadgeColorMapper.ACTIVE,
  },
  {
    title: legalStatusMapper.CANCELED,
    label: legalStatusMapper.CANCELED,
    id: 'CANCELED',
    color: legalBadgeColorMapper.CANCELED,
  },
];

const getPolicyNumberForm = (
  policyNumber?: string
): StatusUpdateFormQuestion => ({
  id: 'policyNumber',
  label: 'Policy number',
  data: {
    type: 'TEXT',
    placeholder: 'Enter policy number',
    defaultValue: policyNumber,
  },
  required: true,
  errorMessage: 'A policy number is required.',
});

const activeUntilForm: StatusUpdateFormQuestion = {
  id: 'activeUntil',
  label: 'Active until',
  data: {
    type: 'DATE',
    defaultValue: dayjs().endOf('month').format(DATE_FORMAT),
  },
  required: true,
  errorMessage: 'An active until date is required.',
};

const reinstateSubscriptionForm: StatusUpdateFormQuestion = {
  id: 'reinstateSubscription',
  label: 'Reinstate the subscription?',
  data: {
    type: 'RADIO',
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
    defaultValue: false,
  },
  required: true,
  errorMessage: 'Please select an option.',
};

const outstandingPaymentsForm: StatusUpdateFormQuestion = {
  id: 'outstandingPayments',
  label: 'Did you manually collect all outstanding payments?',
  data: {
    type: 'RADIO',
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
    defaultValue: false,
  },
  required: true,
  errorMessage:
    'You can’t reinstate the subscription if you haven’t collected all outstanding payments yet.',
  fieldDependencies: [
    {
      questionId: 'reinstateSubscription',
      requiredValue: true,
    },
  ],
};

const reinstateDateForm: StatusUpdateFormQuestion = {
  id: 'reinstateDate',
  label: 'Reinstate date',
  data: {
    type: 'SELECT',
    options: [1, 2, 3].map((numberOfMonthsInAdvance) => {
      const date = dayjs()
        .add(numberOfMonthsInAdvance, 'month')
        .startOf('month')
        .format(DATE_FORMAT);
      return {
        id: date,
        label: date,
      };
    }),
    placeholder: 'Select a date',
  },
  required: true,
  errorMessage: 'Please select a date.',
  fieldDependencies: [
    {
      questionId: 'reinstateSubscription',
      requiredValue: true,
    },
    {
      questionId: 'outstandingPayments',
      requiredValue: true,
    },
  ],
};

const getPriceForm = (price?: number): StatusUpdateFormQuestion => ({
  id: 'price',
  label: 'Price',
  data: {
    type: 'CURRENCY',
    placeholder: '0.00',
    ...(price && { defaultValue: price }),
  },
  required: true,
  errorMessage: 'Please select a price.',
  fieldDependencies: [
    {
      questionId: 'reinstateSubscription',
      requiredValue: true,
    },
    {
      questionId: 'outstandingPayments',
      requiredValue: true,
    },
  ],
});

export const getLegalModals = (policyData?: LegalPolicyData) => {
  const policyNumberForm = getPolicyNumberForm(
    policyData?.legalPolicy.policyNumber
  );
  const priceForm = getPriceForm(policyData?.legalPolicy.price);

  return {
    PENDING: { showOpenIssues: false, formFields: [] },

    ACTIVE_AFTER_PENDING: {
      showOpenIssues: false,
      formFields: [policyNumberForm],
    },

    ACTIVE_AFTER_CANCELED: {
      showOpenIssues: false,
      bottomDescription:
        'Policy and stripe subscription will be reinstated upon confirmation.',
      bottomDescriptionDependencies: [
        {
          questionId: 'reinstateSubscription',
          requiredValue: true,
        },
        {
          questionId: 'outstandingPayments',
          requiredValue: true,
        },
      ],
      formFields: [
        policyNumberForm,
        reinstateSubscriptionForm,
        outstandingPaymentsForm,
        reinstateDateForm,
        priceForm,
      ],
    },

    CANCELED: {
      showOpenIssues: false,
      formFields: [activeUntilForm],
      bottomDescription:
        "Stripe subscription will be automatically canceled if the policy hasn't started yet upon confirmation.",
    },
  };
};

export const extendedValidatorObject = {
  outstandingPayments: z.literal(true),
};

export const retrieveModalId = (
  currentStatus: string,
  newStatus: string
): string => {
  return `${currentStatus}_AFTER_${newStatus}`;
};
