import {
  ApolloClient,
  from,
  InMemoryCache,
  ServerParseError,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { createUploadLink } from 'apollo-upload-client';

const cache = new InMemoryCache();

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope) => {
        scope.setTag('kind', operation.operationName);
        scope.setExtra('response', JSON.stringify(response, null, '\t'));
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, extensions }) => {
            if (
              extensions.code === 'RESOURCE_NOT_FOUND' ||
              extensions.code === 'USER_NOT_AUTHORIZED'
            ) {
              return;
            }
            Sentry.captureMessage(message);
          });
        }

        if (networkError) {
          const { statusCode } = networkError as ServerParseError;
          if (statusCode === 401) {
            return;
          }
          Sentry.captureException(networkError);
        }
      });
    }
  }
);

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'CSRF-Token': window.localStorage.getItem('csrfToken'),
    },
  };
});

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_BASE_URL}/graphql`,
  credentials: 'include',
  headers: {
    'Apollo-Require-Preflight': true,
  },
});

export const client = new ApolloClient({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  link: from([errorLink, authLink, httpLink]),
  cache,
  defaultOptions: {
    mutate: { errorPolicy: 'all' },
  },
});
