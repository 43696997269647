import { capitalizeName } from '@getpopsure/public-utility';
import Card from 'components/Card/Card';
import { PolicyCard } from 'components/PolicyCard';
import { ExpatHealthPolicy } from 'pages/policies/expatHealth/models/ExpatHealthPolicy';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';
import { policyMappers } from 'shared/insurances';

interface OtherExpatPoliciesCardProps {
  policy: ExpatHealthPolicy;
}

const OtherExpatPoliciesCard = ({ policy }: OtherExpatPoliciesCardProps) => {
  const policyMapper = policyMappers.INCOMING;

  const expatPolicies = policy.user?.expatHealthPolicies?.filter(
    (expatPolicy) => {
      return expatPolicy.id !== policy.id;
    }
  );

  if (!policyMapper || expatPolicies?.length === 0) {
    return <></>;
  }

  return (
    <Card title="Other expat policies" boldTitle={false}>
      <>
        {policy.user?.expatHealthPolicies?.map((expatPolicy) => {
          if (expatPolicy.id === policy.id) {
            return <></>;
          }

          return (
            <PolicyCard
              smallVersion={true}
              title={policyMapper.name}
              icon={policyMapper.icon}
              link={generatePath(
                paths.policies.expatHealth.internal.details.path,
                {
                  id: expatPolicy.id,
                }
              )}
              status={
                expatPolicy.publicStatus
                  ? policyMapper.statusMapper[expatPolicy.publicStatus]
                  : undefined
              }
              badgeColor={
                expatPolicy.publicStatus
                  ? policyMapper.badgeColorMapper[expatPolicy.publicStatus]
                  : undefined
              }
              insuredPerson={
                capitalizeName({
                  firstName:
                    expatPolicy.insuredPerson?.firstName ??
                    policy.user?.firstName ??
                    '',
                  lastName:
                    expatPolicy.insuredPerson?.lastName ??
                    policy.user?.lastName ??
                    '',
                }) ?? ''
              }
            />
          );
        })}
      </>
    </Card>
  );
};

export default OtherExpatPoliciesCard;
