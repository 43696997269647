/* eslint-disable react/prop-types */
import { useMutation, useReactiveVar } from '@apollo/client';
import Card from 'components/Card';
import { DocumentNode } from 'graphql';
import hashSum from 'hash-sum';
import { FormEvent, Fragment, useState } from 'react';
import { retrieveZodErrorMessages } from 'shared/errorHandling/retrieveZodErrorMessages';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';
import { ZodObject, ZodRawShape } from 'zod';

import { ActionButtons } from './components/ActionButtons';
import { InformationCardDate } from './components/InformationCardDate';
import { InformationCardDropdown } from './components/InformationCardDropdown';
import { InformationCardLink } from './components/InformationCardLink';
import { InformationCardStatus } from './components/InformationCardStatus';
import { InformationCardText } from './components/InformationCardText';
import { InformationCardTextWithDetails } from './components/InformationCardTextWithDetails';
import { Row } from './components/Row';
import { ComponentData, RowDefaultProps, Section } from './models';
import * as styles from './styles';

interface Props<Properties> {
  sections: Section<Properties>[];
  editMutation: DocumentNode;
  editSuccessMessage: string;
  validationSchema: ZodObject<ZodRawShape>;
  additionalVariables?: Record<string, any>;
  refetchQueries?: DocumentNode[];
}

export const EditableInformationCardV2 = <
  Properties extends Record<string, unknown>
>({
  sections,
  editMutation,
  editSuccessMessage,
  validationSchema,
  additionalVariables,
  refetchQueries,
}: Props<Properties>) => {
  const alertBannersState = useReactiveVar(alertBanners);

  // Build initial state from all the values
  const flattenedRows = sections.reduce(
    (acc: (RowDefaultProps<Properties> & ComponentData)[], { rows }) => [
      ...acc,
      ...rows,
    ],
    []
  );

  const initialState = flattenedRows.reduce((obj, row) => {
    if (row.propertyName && 'initialValue' in row.props) {
      return { ...obj, [row.propertyName]: row.props.initialValue };
    }
    return { ...obj };
  }, {});

  const [form, setForm] = useState<Partial<Properties>>(initialState);
  const [errors, setErrors] = useState<
    Partial<Record<keyof Properties, string>>
  >({});

  const [isInEditMode, setIsInEditMode] = useState(false);

  const [edit, { loading: submittingEditInfo }] = useMutation(editMutation, {
    onCompleted: () => {
      setIsInEditMode(false);

      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message: editSuccessMessage,
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },

    onError: () => {
      setIsInEditMode(false);

      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: 'Something went wrong. Please try again.',
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },

    refetchQueries,
    errorPolicy: 'none',
  });

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setErrors({});

    const validation = await validationSchema?.safeParseAsync(form);

    if (!validation.success) {
      const zodErrors = retrieveZodErrorMessages(validation.error);
      setErrors(zodErrors);

      return;
    }

    edit({ variables: { ...validation.data, ...(additionalVariables ?? {}) } });
  };

  const onEdit = () => {
    setIsInEditMode(true);
  };

  const onDiscard = () => {
    setForm(initialState);
    setIsInEditMode(false);
    setErrors({});
  };

  // Checks against initial state if any form have been changed
  const formHash = hashSum(form);
  const initialStateHash = hashSum(initialState);

  const isFormUpdated = formHash !== initialStateHash;

  return (
    <form onSubmit={onSubmit}>
      <Card
        title={sections[0].title}
        boldTitle
        actionButton={
          <ActionButtons
            isInEditMode={isInEditMode}
            onEdit={onEdit}
            onDiscard={onDiscard}
            canSave={isFormUpdated || !submittingEditInfo}
          />
        }
      >
        {sections.map(({ title: sectionTitle, rows }, idx) => (
          <Fragment key={sectionTitle}>
            {idx !== 0 && (
              <h3 className={styles.sectionTitle}>{sectionTitle}</h3>
            )}

            {rows.map(({ propertyName, type, label, isEditable, props }) => {
              const shouldDisplayEditField = isInEditMode && isEditable;
              const error = propertyName ? errors[propertyName] : '';

              if (type === 'TEXT') {
                const { onChange, placeholder, initialValue } = props;

                const currentValue = propertyName
                  ? form[propertyName]
                  : initialValue;

                return (
                  <Row
                    label={label}
                    shouldDisplayEditField={shouldDisplayEditField}
                    key={label}
                  >
                    <InformationCardText
                      value={currentValue as string}
                      onChange={(newValue: string) => {
                        onChange?.(newValue);

                        if (propertyName) {
                          setForm({
                            ...form,
                            [propertyName]: newValue,
                          });
                        }
                      }}
                      shouldDisplayEditField={shouldDisplayEditField}
                      placeholder={placeholder}
                      error={error}
                    />
                  </Row>
                );
              }

              if (type === 'DATE') {
                const { onChange, initialValue } = props;

                const currentValue = propertyName
                  ? form[propertyName]
                  : initialValue;

                return (
                  <Row
                    label={label}
                    shouldDisplayEditField={shouldDisplayEditField}
                    key={label}
                  >
                    <InformationCardDate
                      value={currentValue as Date}
                      onChange={(value: Date) => {
                        onChange?.(value);

                        if (propertyName) {
                          setForm({
                            ...form,
                            [propertyName]: value,
                          });
                        }
                      }}
                      shouldDisplayEditField={shouldDisplayEditField}
                      error={error}
                    />
                  </Row>
                );
              }

              if (type === 'LINK') {
                const { title, href } = props;

                return (
                  <Row label={label} key={label}>
                    <InformationCardLink title={title} href={href} />
                  </Row>
                );
              }

              if (type === 'STATUS') {
                const { badgeColor, initialValue } = props;

                const currentValue = propertyName
                  ? form[propertyName]
                  : initialValue;

                return (
                  <Row label={label} key={label}>
                    <InformationCardStatus
                      value={currentValue as string}
                      badgeColor={badgeColor}
                    />
                  </Row>
                );
              }

              if (type === 'DROPDOWN') {
                const { options, placeholder, onChange, initialValue } = props;

                const currentValue = propertyName
                  ? form[propertyName]
                  : initialValue;

                return (
                  <Row
                    label={label}
                    shouldDisplayEditField={shouldDisplayEditField}
                    key={label}
                  >
                    <InformationCardDropdown
                      value={currentValue as string}
                      options={options}
                      placeholder={placeholder}
                      shouldDisplayEditField={shouldDisplayEditField}
                      onChange={(selectedOption: string) => {
                        onChange?.(selectedOption);

                        if (propertyName) {
                          setForm({
                            ...form,
                            [propertyName]: selectedOption,
                          });
                        }
                      }}
                      error={error}
                    />
                  </Row>
                );
              }

              if (type === 'TEXT_WITH_DETAILS' && !isEditable) {
                const {
                  initialValue,
                  renderModalDetails,
                  detailsLabel,
                  detailsTitle,
                } = props;

                return (
                  <Row label={label} key={label}>
                    <InformationCardTextWithDetails
                      value={initialValue}
                      detailsLabel={detailsLabel}
                      detailsTitle={detailsTitle}
                      renderModalDetails={renderModalDetails}
                    />
                  </Row>
                );
              }

              return <></>;
            })}
          </Fragment>
        ))}
      </Card>
    </form>
  );
};
