import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import { HeaderConfig, RowConfig } from 'components/TableRefactor/types';
import { Status } from 'models/statusColor';
import { expatSpainPlanMapper } from 'shared/insurances/planMappers/expatSpain';
import { policyRouteMapper } from 'shared/insurances/policyRouteMapper';
import {
  expatSpainBadgeColorMapper,
  expatSpainStatusMapper,
} from 'shared/insurances/statusMappers/expatSpain';
import { ExpatSpainStatus } from 'shared/insurances/types';

import { ExpatSpainPolicy } from '../models/ExpatSpainPolicy';

export const tableHeaders: HeaderConfig[] = [
  {
    id: 'firstName',
    label: 'First name',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    sticky: true,
    threshold: 'left-0',
    border: 'none',
    component: SortableColumnHeader,
  },
  {
    id: 'lastName',
    label: 'Last name',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    sticky: true,
    threshold: 'left-[140px]',
    border: 'none',
    component: SortableColumnHeader,
  },
  {
    id: 'status',
    label: 'Status',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    sticky: true,
    threshold: 'left-[280px]',
    border: 'right',
    component: SortableColumnHeader,
  },
  {
    id: 'startDate',
    label: 'Start date',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: SortableColumnHeader,
  },
  {
    id: 'policyNumber',
    label: 'Policy number',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    component: SortableColumnHeader,
  },
  {
    id: 'plan',
    label: 'Plan',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: ColumnHeader,
  },
  {
    id: 'activeUntil',
    label: 'Active until',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: ColumnHeader,
  },
  {
    id: 'createdAt',
    label: 'Created on',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: SortableColumnHeader,
  },
  {
    id: 'source',
    label: 'Source',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: ColumnHeader,
  },
];

const displayArchivedStatus = () =>
  ({
    text: {
      id: 'ARCHIVED',
      text: 'Archived',
    },
    color: 'gray',
  } as Status);

const statusMapping = (status: string) => ({
  text: {
    id: status,
    text: expatSpainStatusMapper[status as ExpatSpainStatus],
  },
  color: expatSpainBadgeColorMapper[status as ExpatSpainStatus] ?? 'gray',
});

export const rowConfig: RowConfig = {
  getResourceLink: (data: ExpatSpainPolicy) =>
    policyRouteMapper.INCOMING_ES(data.id),

  cells: [
    {
      type: 'TEXT',
      textColor: 'dark',
      props: {
        getText: (data: ExpatSpainPolicy) =>
          data.insuredPerson?.firstName ?? '',
      },
    },
    {
      type: 'TEXT',
      textColor: 'dark',
      props: {
        getText: (data: ExpatSpainPolicy) => data.insuredPerson?.lastName ?? '',
      },
    },
    {
      type: 'STATUS',
      textColor: 'dark',
      props: {
        getStatus: (data: ExpatSpainPolicy) => {
          if (data.archivedAt) return displayArchivedStatus();
          return statusMapping(data.publicStatus);
        },
      },
    },
    {
      type: 'DATE',
      textColor: 'light',
      props: {
        getDate: (data: ExpatSpainPolicy) => data.startDate ?? '',
      },
    },
    {
      type: 'TEXT',
      props: { getText: (data: ExpatSpainPolicy) => data.policyNumber ?? '' },
      textColor: 'light',
    },
    {
      type: 'TEXT',
      props: {
        getText: (data: ExpatSpainPolicy) =>
          data?.quote.plan ? expatSpainPlanMapper[data.quote.plan] : '',
      },
      textColor: 'light',
    },
    {
      type: 'DATE',
      textColor: 'light',
      props: {
        getDate: (data: ExpatSpainPolicy) => data.activeUntil ?? '',
      },
    },
    {
      type: 'DATE',
      textColor: 'light',
      props: {
        getDate: (data: ExpatSpainPolicy) => data.createdAt ?? '',
      },
    },
    {
      type: 'TEXT',
      props: { getText: (data: ExpatSpainPolicy) => data.source ?? '' },
      textColor: 'light',
    },
  ],
};
