import classNames from 'classnames';

export const documentCard = classNames(
  'bg-gray-50',
  'rounded-[8px]',
  'mt-[8px]',
  'w-full',
  'h-[56px]',

  'flex',
  'justify-between',
  'items-center'
);

export const disabled = classNames('!bg-gray-200');
export const disabledLink = classNames('pointer-events-none');

export const focusOutline = classNames(
  'outline-none',
  'ring-2',
  'ring-indigo-500',
  'ring-offset-1'
);

export const downloadLink = classNames(
  'flex',
  'items-center',
  'group',
  'pl-[16px]',
  'py-[8px]',
  'w-full',
  'h-full'
);

export const fileNameSection = classNames('flex', 'items-center', 'break-all');

export const fileIconContainer = classNames('w-[20px]');

export const fileIcon = classNames(
  'text-gray-500',
  'w-[20px]',
  'h-[20px]',
  'group-hover:text-indigo-500',
  'group-focus:text-indigo-500'
);

export const fileName = classNames(
  'ml-[12px]',
  'text-sm',
  'text-gray-900',
  'group-hover:text-indigo-500',
  'group-focus:text-indigo-500'
);

export const fileNameDisabled = classNames('text-gray-600');

export const dotIconContainer = classNames('relative');

export const dotIconButton = classNames(
  'text-gray-400',
  'px-[16px]',
  'flex',
  'items-center',
  'h-[56px]',
  'hover:text-indigo-500',
  'focus:text-indigo-500'
);

export const dotIcon = classNames('w-[20px]', 'h-[20px]');

export const dropdown = classNames(
  'absolute',
  'rounded-[4px]',
  'bg-white',
  'right-4',
  'top-11',
  'w-[148px]',
  'shadow-lg',
  'z-10'
);

export const dropdownButton = classNames(
  'px-[16px]',
  'py-[14px]',
  'text-xs',
  'w-full',
  'text-left'
);

export const dropdownButtonGray = classNames(
  dropdownButton,
  'text-gray-600',
  'hover:text-gray-900'
);

export const dropdownButtonRed = classNames(
  dropdownButton,
  'text-red-500',
  'hover:text-red-700'
);

export const dropdownImageAnchor = classNames(dropdownButton, 'block');
