import { GET_BIKE_POLICIES } from 'pages/policies/bike/graphql/policies';
import { BikeClaimStatus, BikeClaimType } from 'shared/claims/bike/models';
import { InsuranceType } from 'shared/insurances/types';

import { ClaimsTemplateData } from '../template/models';
import { retrieveClaimInfoRows } from './modules/claimsDetails/claimInfo/data';
import { UPDATE_BIKE_CLAIM } from './modules/claimsDetails/graphql/mutations';
import { GET_BIKE_CLAIM } from './modules/claimsDetails/graphql/queries';
import { retrievePolicyInfoRows } from './modules/claimsDetails/policyInfo/data';
import { retrieveCreateClaimFormData } from './modules/createClaim/data';
import { CREATE_BIKE_CLAIM } from './modules/createClaim/graphql/mutations';
import { zodSubmittableClaimSchema } from './modules/createClaim/models';
import { filters } from './modules/overview/data/filters';
import { rowConfig } from './modules/overview/data/tableData';
import { tableHeaders } from './modules/overview/data/tableHeaders';
import { GET_BIKE_CLAIMS } from './modules/overview/graphql/queries';
import {
  claimStatusOptions,
  retrieveFormAndFooterTextByStatus,
} from './modules/statusUpdate/data';
import { UPDATE_BIKE_CLAIM_STATUS } from './modules/statusUpdate/graphql/mutations';

export const bikeClaimsData = (
  insuranceType: InsuranceType
): ClaimsTemplateData<BikeClaimStatus, BikeClaimType> => ({
  insuranceType,

  // GraphQL -----------------------------------------
  queries: {
    fetchAllClaims: GET_BIKE_CLAIMS,
    fetchSingleClaim: GET_BIKE_CLAIM,
    fetchAllPolicies: GET_BIKE_POLICIES,
  },

  mutations: {
    createClaim: CREATE_BIKE_CLAIM,
    updateClaim: UPDATE_BIKE_CLAIM,
    updateClaimStatus: UPDATE_BIKE_CLAIM_STATUS,
  },

  // Features ----------------------------------------
  overview: {
    headers: tableHeaders,
    filterConfig: filters,
    rowConfig,
  },
  claimCreation: {
    createClaimSubmissionSchema: zodSubmittableClaimSchema,
    retrieveFormData: retrieveCreateClaimFormData,
  },
  policyInfo: { retrievePolicyInfoRows },
  claimInfo: {
    retrieveClaimInfoRows,
  },
  statusUpdate: {
    dataByStatus: retrieveFormAndFooterTextByStatus,
    statusOptions: claimStatusOptions(insuranceType),
  },
});
