import { BadgeColor } from 'components/Badge/Badge';

import { ExpatSpainStatus } from '../types';

export const expatSpainStatusMapper: Record<ExpatSpainStatus, string> = {
  PENDING: 'Pending',
  ACTIVE: 'Covered',
  CANCELED: 'Canceled',
  DROPPED_OUT: 'Canceled',
};

export const expatSpainBadgeColorMapper: Record<ExpatSpainStatus, BadgeColor> =
  {
    PENDING: 'purple',
    ACTIVE: 'green',
    CANCELED: 'gray',
    DROPPED_OUT: 'gray',
  };
