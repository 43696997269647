import { BadgeColor } from 'components/Badge/Badge';

export const transactionStatusTypes = [
  'PENDING',
  'READY',
  'EXPORTED',
  'PAID',
  'SKIPPED',
  'IGNORED',
] as const;
export type TransactionStatus = typeof transactionStatusTypes[number];

export const transactionStatusColorMaper: Record<
  TransactionStatus,
  BadgeColor
> = {
  PENDING: 'red',
  READY: 'blue',
  EXPORTED: 'blue',
  PAID: 'green',
  SKIPPED: 'gray',
  IGNORED: 'yellow',
};
