import { gql } from '@apollo/client';

export const GET_HR_COMPANY_LIFE_POLICIES = gql`
  query HrCompanyLifePolicies(
    $company: String!
    $filterStatus: [String!]!
    $sortOrder: String!
    $sortColumn: String!
    $searchString: String!
    $offset: Float!
    $limit: Float!
  ) {
    hrCompanyLifePolicies(
      company: $company
      filterStatus: $filterStatus
      sortOrder: $sortOrder
      sortColumn: $sortColumn
      searchString: $searchString
      offset: $offset
      limit: $limit
    ) {
      id
      startDate
      lifeDetails {
        employerContribution
        lumpSumCoverage
      }
      policyNumber
      publicStatus
      user {
        firstName
        lastName
      }
    }
    totalHrCompanyLifePolicies(
      company: $company
      filterStatus: $filterStatus
      searchString: $searchString
    )
  }
`;
