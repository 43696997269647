import { GenericPolicyData } from '../models/GenericPolicyData';

export const getQuestionnaireData = (
  data?: GenericPolicyData
): { id?: string; answers?: object } => {
  if (data?.genericPolicy.typeformData) {
    return {
      id: data?.genericPolicy.typeformData?.id,
      answers: data?.genericPolicy.typeformData?.answers,
    };
  }

  if (data?.genericPolicy.questionnaire) {
    return {
      id: data?.genericPolicy.questionnaire?.id,
      answers: data?.genericPolicy.questionnaire?.answers,
    };
  }

  return {
    id: undefined,
    answers: undefined,
  };
};
