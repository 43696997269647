import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { InformationSection } from 'components/InformationCard';
import ComboBoxInformation from 'components/InformationCard/ComboBoxInformation/ComboBoxInformation';
import { CurrencyInformation } from 'components/InformationCard/CurrencyInformation';
import DateInformation from 'components/InformationCard/DateInformation';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { BikeClaim } from 'pages/claims/bike/models';
import { BikeClaimType, bikeClaimTypes } from 'shared/claims/bike/models';
import { policyMappers } from 'shared/insurances/';

export const retrieveClaimInfoRows = (
  claimInfo: BikeClaim
): InformationSection[] => [
  {
    id: 'claimInfo',
    title: 'Claim information',
    boldTitle: false,
    rows: [
      {
        id: 'claimNumber',
        title: 'Claim number',
        data: claimInfo?.claimNumber ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
      {
        id: 'status',
        title: 'Status',
        data: claimInfo?.status ?? 'RECEIVED',
        statusMapping: (status) => {
          return {
            color:
              policyMappers.BIKE?.claim?.badgeColorMapper[status] ?? 'blue',
            text: {
              text: policyMappers.BIKE?.claim?.claimStatusMapper[status] ?? '',
              id: status,
            },
          };
        },
        type: 'STATUS',
        component: StatusInformation,
        editable: false,
      },
      {
        id: 'createdOn',
        title: 'Created on',
        data: claimInfo?.createdAt ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: false,
      },
      {
        id: 'claimType',
        title: 'Claim type',
        data: claimInfo.claimType?.map(({ type }) => type).join(',') ?? '',
        type: 'DROPDOWN',
        component: ComboBoxInformation,

        dropdownOptions: bikeClaimTypes.map((type) => ({
          id: type,
          label: policyMappers.BIKE?.claim?.typeNameMapper
            ? policyMappers.BIKE.claim.typeNameMapper[type as BikeClaimType]
            : '',
        })),

        optionMapping: (option: string) =>
          policyMappers.BIKE?.claim?.typeNameMapper
            ? policyMappers.BIKE.claim.typeNameMapper[option as BikeClaimType]
            : '',

        placeholder: 'Choose a claim type',
        editable: true,
        multipleOptions: true,
        required: true,
      },
      {
        id: 'dateOfEvent',
        title: 'Event date',
        data: claimInfo?.dateOfEvent ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: true,
        required: true,
      },
      {
        id: 'eventDescription',
        title: 'Event description',
        data: claimInfo?.eventDescription ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: true,
      },
      {
        id: 'accountHoldersName',
        title: "Account holder's name",
        data: claimInfo?.accountHoldersName ?? '',
        type: 'TEXT',
        component: TextInformation,
        placeholder: "Enter account holder's name",
      },
      {
        id: 'iban',
        title: 'IBAN',
        data: claimInfo?.iban ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: true,
        placeholder: 'Enter IBAN',
        required: true,
      },
      {
        id: 'bic',
        title: 'BIC',
        data: claimInfo?.bic ?? '',
        type: 'TEXT',
        component: TextInformation,
        placeholder: 'Enter BIC',
      },
      {
        id: 'amount',
        title: 'Claimed amount',
        data: claimInfo?.amount ?? 0,
        type: 'CURRENCY',
        component: CurrencyInformation,
        editable: true,
        placeholder: englishFormattedEuroCurrency(0),
      },
      {
        id: 'refundAmount',
        title: 'Refund amount',
        data: claimInfo?.refundAmount ?? 0,
        type: 'CURRENCY',
        component: CurrencyInformation,
        editable: true,
        placeholder: englishFormattedEuroCurrency(0),
      },
      {
        id: 'customerPaidOn',
        title: 'Customer paid on',
        data: claimInfo?.customerPaidOn ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: true,
      },
    ],
  },
];
