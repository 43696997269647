import { PrivateHealthClaimType } from './models';

export const claimTypeNameMapper: Record<PrivateHealthClaimType, string> = {
  COST_PLAN: 'Cost plan',
  DOCTORS_VISIT: 'General doctor visit',
  MEDICATION: 'Medication',
  NON_MEDICAL_PROFESSIONAL: 'Non medical professional',
  SPECIALIST_VISIT: 'Specialist visit',
  DENTAL: 'Dental',
  AIDS: 'Aids',
  PHYSICAL_OR_SPEECH_THERAPY: 'Physical or speech therapy',
  HOSPITAL_TREATMENT: 'Hospital treatment',
  OTHER: 'Other',
};
