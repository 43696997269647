import Badge from 'components/Badge';
import { BadgeColor } from 'components/Badge/Badge';

interface TableCellDotProps {
  data: unknown;
  getDot: (data: any) => {
    active: boolean;
    badgeColor: BadgeColor;
    badgeCaption: string;
  };
}

export const TableCellDot = ({ getDot, data }: TableCellDotProps) => {
  const cellData = getDot(data);

  return (
    <div className="relative z-10">
      {cellData.active && (
        <Badge
          badgeType="dot"
          color={cellData.badgeColor ?? 'yellow'}
          className="peer"
        />
      )}
      {cellData.badgeCaption && (
        <div className="w-fit absolute bottom-10 z-10 hidden whitespace-nowrap rounded-md bg-white px-4 py-2 text-xs text-gray-600 shadow-3xl peer-hover:block">
          {cellData.badgeCaption}
        </div>
      )}
    </div>
  );
};
