import { DocumentNode } from 'graphql';
import { Issue } from 'models/issue';
import { Status } from 'models/statusColor';

import CommissionChangeActivityCard from './CommissionChangeActivityCard';
import EmptyActivityState from './EmptyActivityState';
import IssueCreationActivityCard from './IssueCreationActivityCard';
import IssueStatusChangeActivityCard from './IssueStatusChangeActivityCard';
import PolicyArchiveActivityCard from './PolicyArchiveActivityCard';
import PolicyStatusChangeActivityCard from './PolicyStatusChangeActivityCard';
import { PolicyStatusChangeActivityCardProps } from './PolicyStatusChangeActivityCard/PolicyStatusChangeActivityCard';

export interface Activity {
  id: string;
  type: string;
  createdAt: string;
  author?: {
    firstName?: string;
    lastName?: string;
    company: string;
  };
  newPolicyStatus?: string;
  newIssueStatus?: string;
  issue?: Issue;
  comment?: string;
  document?: {
    name: string;
    url: string;
    type?: string;
  };
  newCommissionStatus?: string;
}

interface ActivityCardsProps {
  activity: Activity[];
  smallVersion?: boolean;
  policyId: string;
  statusMapping: (status: string) => Status;
  showCustomerInformed: boolean;
  providerView?: boolean;
  issueStatusChangeMutation?: DocumentNode;
  policyQuery?: DocumentNode;
  uploadFileMutation?: DocumentNode;
  issueCategory?: string;
}
const ActivityCards = ({
  activity,
  smallVersion = false,
  policyId,
  showCustomerInformed,
  providerView = true,
  statusMapping,
  issueCategory,
  issueStatusChangeMutation,
  uploadFileMutation,
  policyQuery,
}: ActivityCardsProps) => {
  let CardComponent: React.ComponentType<PolicyStatusChangeActivityCardProps>;
  return (
    <div className="w-full">
      {activity.map((activityEntry, index) => {
        switch (activityEntry.type) {
          case 'STATUS_CHANGE':
            CardComponent = PolicyStatusChangeActivityCard;
            break;
          case 'ISSUE_CREATION':
            CardComponent = IssueCreationActivityCard;
            break;
          case 'ISSUE_STATUS_CHANGE':
            CardComponent = IssueStatusChangeActivityCard;
            break;
          case 'COMMISSION_CHECK_CHANGE':
            CardComponent = CommissionChangeActivityCard;
            break;
          case 'POLICY_ARCHIVE':
            CardComponent = PolicyArchiveActivityCard;
            break;
        }
        return (
          <CardComponent
            key={activityEntry.id}
            smallVersion={smallVersion}
            hasActivityLine={index !== activity.length - 1}
            activityCard={activityEntry}
            policyId={policyId}
            showCustomerInformed={showCustomerInformed}
            statusMapping={statusMapping}
            providerView={providerView}
            issueCategory={issueCategory}
            policyQuery={policyQuery}
            issueStatusChangeMutation={issueStatusChangeMutation}
            uploadFileMutation={uploadFileMutation}
          />
        );
      })}
      {activity.length === 0 && !smallVersion && <EmptyActivityState />}
    </div>
  );
};

export default ActivityCards;
