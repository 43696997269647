import { gql } from '@apollo/client';

export const GET_TK_API_REQUESTS = gql`
  query TkApiRequests($searchString: String!, $offset: Float!, $limit: Float!) {
    tkApiRequests(searchString: $searchString, offset: $offset, limit: $limit) {
      id
      createdAt
      status
      response
      externalId
      payload
      userPolicy {
        user {
          firstName
          lastName
        }
        publicStatus
        id
      }
    }
    totalTkApiRequestsCount(searchString: $searchString)
  }
`;
