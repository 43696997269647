import { gql } from '@apollo/client';

export const CREATE_PRIVATE_HEALTH_ISSUE = gql`
  mutation CreatePrivateHealthIssue(
    $policyOrClaimId: String!
    $category: String!
    $title: String
    $description: String!
    $document: Upload
    $documentName: String
  ) {
    # TODO: [KONG] Update name
    createPrivateHealthIssueNew(
      policyId: $policyOrClaimId
      category: $category
      title: $title
      description: $description
      document: $document
      documentName: $documentName
    ) {
      id
      category
      description
      title
    }
  }
`;

export const RESOLVE_PRIVATE_HEALTH_ISSUE = gql`
  mutation ResolvePrivateHealthIssue(
    $policyOrClaimId: String!
    $issueId: String!
    $document: Upload
    $documentName: String
  ) {
    resolvePrivateHealthIssue(
      policyId: $policyOrClaimId
      issueId: $issueId
      document: $document
      documentName: $documentName
    ) {
      id
    }
  }
`;

export const RE_OPEN_PRIVATE_HEALTH_ISSUE = gql`
  mutation ReOpenPrivateHealthIssue(
    $policyOrClaimId: String!
    $issueId: String!
    $comment: String!
    $document: Upload
    $documentName: String
  ) {
    reOpenPrivateHealthIssue(
      policyId: $policyOrClaimId
      issueId: $issueId
      comment: $comment
      document: $document
      documentName: $documentName
    ) {
      id
    }
  }
`;
