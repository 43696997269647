import { gql } from '@apollo/client';

export const TARIFF_INFO = gql`
  fragment TariffInfoFields on TariffInfo {
    employerContributions
    guaranteedYearlyBonus
    longTermCare
    noClaimsBonus
    riskFactorSurcharge
    sickDayPayout
    statutorySurcharge
    tariffMonthlyPrice
    taxDeductibleAmount
    totalMonthlyPrice
    id
    addOns
    createdAt
    deductible
    endDate
    riskLevel
    startDate
    updatedAt
    tariff
    ownContributions
  }
`;
