import { Listbox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ExclamationCircleIcon,
  SelectorIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import Badge from 'components/Badge';
import { BadgeColor } from 'components/Badge/Badge';
import { Fragment } from 'react';

import * as styles from './styles';

type DropdownColor = 'white' | 'gray' | 'dark';

export interface SelectMenuOption<SelectOption = void> {
  id: SelectOption extends void ? string : SelectOption;
  label: string;
  color?: BadgeColor;
  sectionName?: string;
  icon?: string;
}

interface SelectMenuProps<SelectOption> {
  options: SelectMenuOption<SelectOption>[];
  selected:
    | SelectMenuOption<SelectOption>
    | SelectMenuOption<SelectOption>[]
    | undefined;
  setSelected:
    | ((newSelected: SelectMenuOption<SelectOption>) => void)
    | ((newSelected: SelectMenuOption<SelectOption>[]) => void);
  placeholder: string;
  label?: string;
  disabled?: boolean;
  className?: string;
  color?: DropdownColor;
  multiple?: boolean;
  horizontal?: boolean;
  error?: boolean;
  errorMessage?: string;
  withDot?: boolean;
  clearButton?: boolean;
  dataTestId?: string;
}

const SelectMenu = <SelectOption extends string | void>({
  options,
  selected,
  setSelected,
  placeholder,
  label,
  disabled,
  className,
  color = 'white',
  multiple = false,
  horizontal = false,
  error,
  errorMessage,
  withDot = false,
  clearButton = false,
  dataTestId,
}: SelectMenuProps<SelectOption>) => {
  const getLabel = () => {
    if (Array.isArray(selected)) {
      if (selected.length === 0) return placeholder;
      const firstSelected = selected[0];
      return `${firstSelected.label} ${selected.length > 1 ? ', +' : ''}${
        selected.length > 1 ? selected.length - 1 : ''
      }`;
    }
    if (!selected) return placeholder;
    return selected.label;
  };

  const showPlaceholder = () => {
    if (!selected) return true;
    if (Array.isArray(selected) && selected.length === 0) return true;
    return false;
  };

  return (
    <Listbox
      value={selected}
      onChange={setSelected}
      disabled={disabled}
      multiple={multiple}
    >
      {({ open }) => (
        <div
          className={horizontal ? styles.horizontalLabel : styles.verticalLabel}
        >
          {label && (
            <Listbox.Label className={styles.label}>{label}</Listbox.Label>
          )}
          <div className={styles.selectWrapper}>
            <Listbox.Button
              className={classNames(styles.selected, className, {
                [styles.noSelection]: selected === undefined,
                [styles.disabledWhite]: disabled && color === 'white',
                [styles.disabledGray]: disabled && color === 'gray',
                [styles.disabledDark]: disabled && color === 'dark',
                [styles.selectedWhite]: color === 'white',
                [styles.selectedDark]: color === 'dark',
                [styles.selectedGray]: color === 'gray',
                [styles.errorState]: error,
              })}
              data-testid={dataTestId}
            >
              <div
                className={
                  showPlaceholder()
                    ? styles.textPlaceHolder
                    : 'flex items-center space-x-2'
                }
              >
                {withDot && !showPlaceholder() && (
                  <Badge
                    badgeType="dot"
                    color={
                      (!Array.isArray(selected)
                        ? selected?.color
                        : selected[0].color) ?? 'gray'
                    }
                  />
                )}
                {!Array.isArray(selected) && selected?.icon && (
                  <img
                    src={selected.icon}
                    alt="flag"
                    className="mr-1 rounded-sm"
                  />
                )}
                <span
                  className={classNames({
                    'text-gray-400': color === 'dark',
                  })}
                >
                  {getLabel()}
                </span>
              </div>
              <span className={styles.selectButton}>
                {error && (
                  <ExclamationCircleIcon className={styles.errorIcon} />
                )}
                <SelectorIcon
                  className={styles.selectIcon}
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            {error && <p className={styles.errorMessage}>{errorMessage}</p>}

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={classNames(styles.optionsList, {
                  [styles.selectedWhite]: color === 'white',
                  [styles.selectedGray]: color === 'gray' || color === 'dark',
                })}
              >
                {options.map((option, index) => (
                  <Fragment key={String(`${option.id}-${index}-FRAG`)}>
                    {option.sectionName && (
                      <div className="mb-1 mt-4 px-3 font-bold text-gray-600">
                        {option.sectionName}
                      </div>
                    )}
                    <Listbox.Option
                      key={String(`${option.id}-${index}`)}
                      className={({ active }) =>
                        classNames(styles.listOption, {
                          [styles.listOptionHover]: active,
                        })
                      }
                      value={option}
                      data-testid={`${option.id}-select-option`}
                    >
                      {({ selected, active }) => (
                        <div>
                          <div
                            className={classNames(
                              `flex items-center space-x-2`,
                              {
                                [styles.listOptionTextSelected]: selected,
                                [styles.listOptionText]: !selected,
                                [styles.listOptionTextHover]: active,
                              }
                            )}
                          >
                            {withDot && (
                              <Badge
                                badgeType="dot"
                                color={option.color ?? 'gray'}
                              />
                            )}
                            {option?.icon && (
                              <img
                                src={option.icon}
                                alt="flag"
                                className="mr-1 rounded-sm"
                              />
                            )}
                            <span>{option.label}</span>
                          </div>
                          {selected && (
                            <span
                              className={classNames(
                                { [styles.checkIconHover]: active },
                                styles.checkIcon
                              )}
                            >
                              <CheckIcon
                                className={styles.icon}
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </div>
                      )}
                    </Listbox.Option>
                  </Fragment>
                ))}
                {clearButton && (
                  <div
                    className={classNames(styles.clearFilterContainer, {
                      [styles.selectedWhite]: color === 'white',
                      [styles.selectedGray]: color === 'gray',
                    })}
                  >
                    <button
                      type="button"
                      className={styles.clearFilterButton}
                      disabled={!selected}
                      onClick={() => {
                        if (!multiple) {
                          setSelected(undefined as never);
                        } else {
                          setSelected([] as never);
                        }
                      }}
                    >
                      Clear
                    </button>
                  </div>
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

export default SelectMenu;
