import classNames from 'classnames';

export const loadingState = classNames('min-h-screen', 'flex', 'w-full');

export const contentWrapper = classNames(
  'mx-auto',
  'w-full',
  'max-w-md',
  'flex',
  'flex-col',
  'justify-center',
  'items-center'
);

export const loader = classNames('animate-spin');

export const text = classNames('text-gray-600', 'mt-[4px]');
