import { ModalFormData } from 'components/ModalForm/models';
import dayjs from 'dayjs';
import { retrievePolicySuggestion } from 'pages/claims/template/components/CreateClaimModal/utils';
import { LiabilityPolicy } from 'pages/policies/liability/models/LiabilityPolicy';
import { claimTypeNameMapper as labilityClaimTypeNameMapper } from 'shared/claims/liability/claimTypeNameMapper';
import {
  LiabilityClaimStatus,
  liabilityClaimStatuses,
  LiabilityClaimType,
  liabilityClaimTypes,
} from 'shared/claims/liability/models';
import { statusNameMapper as liabilityStatusNameMapper } from 'shared/claims/liability/statusNameMapper';

import { SubmittableClaim } from '../../../template/components/CreateClaimModal/models';

export const retrieveCreateClaimFormData = (
  policies: LiabilityPolicy[],
  setPolicySearchQuery: (query: string) => void,
  policiesLoading: boolean
): ModalFormData<
  SubmittableClaim<LiabilityClaimStatus, LiabilityClaimType>,
  LiabilityClaimStatus | LiabilityClaimType | string
>[] => [
  {
    id: 'policyId',
    label: 'User policy',
    renderDetails: {
      type: 'autoSuggest',
      options: policies.map(({ id, user, policyNumber }) => ({
        id,
        label: retrievePolicySuggestion({
          firstName: user?.firstName,
          lastName: user?.lastName,
          policyNumber,
        }),
      })),
      placeholder: 'Enter name, email or policy number',
      setExternalQuery: (query: string) => {
        setPolicySearchQuery(query);
      },
      useUnfilteredOptions: true,
    },
    color: 'gray',
    required: true,
    loading: policiesLoading,
  },
  {
    id: 'status',
    label: 'Status',
    renderDetails: {
      type: 'select',
      options: liabilityClaimStatuses.flatMap((status) => ({
        id: status,
        label: liabilityStatusNameMapper[status],
      })),
      placeholder: 'Select a status',
    },
    color: 'gray',
    required: true,
  },
  {
    id: 'claimType',
    label: 'Claim type',
    renderDetails: {
      type: 'multiSelect',
      options: liabilityClaimTypes.map((type) => ({
        id: type,
        label: labilityClaimTypeNameMapper[type],
      })),
      placeholder: 'Select type',
    },
    color: 'gray',
    required: true,
  },
  {
    id: 'dateOfEvent',
    label: 'Event date',
    renderDetails: { type: 'date', maxDate: dayjs().toISOString() },
    color: 'gray',
    required: true,
  },
  {
    id: 'eventDescription',
    label: 'Event description',
    renderDetails: { type: 'textarea', placeholder: 'Start typing...' },
    color: 'gray',
  },
  {
    id: 'amount',
    label: 'Claim amount',
    renderDetails: { type: 'currency', placeholder: '0.00' },
    color: 'gray',
  },
  {
    id: 'iban',
    label: 'IBAN',
    renderDetails: { type: 'text', placeholder: 'Enter IBAN' },
    color: 'gray',
  },
  {
    id: 'thirdPartyName',
    label: "Third party's name",
    renderDetails: { type: 'text', placeholder: 'Enter name' },
    color: 'gray',
  },
  {
    id: 'thirdPartyEmail',
    label: "Third party's email",
    renderDetails: { type: 'text', placeholder: 'Enter email address' },
    color: 'gray',
  },
  {
    id: 'uploadDocument',
    label: 'Upload a document',
    renderDetails: { type: 'upload' },
    required: true,
  },
];
