import { useMutation } from '@apollo/client';
import Modal from 'components/Modal';
import { UPDATE_MANY_SCANNED_DOCUMENTS } from 'pages/scannedDocuments/scannedDocuments.mutations';
import { GET_SCANNED_DOCUMENTS } from 'pages/scannedDocuments/scannedDocuments.queries';

type ArchiveDocumentBulkActionProps = {
  ids: string[];
  modalIsShown: boolean;
  closeModal: () => void;
  unarchive?: boolean;
};

export const ArchiveDocumentBulkAction = ({
  ids,
  modalIsShown,
  closeModal,
  unarchive,
}: ArchiveDocumentBulkActionProps) => {
  const [archiveDocument, { loading }] = useMutation(
    UPDATE_MANY_SCANNED_DOCUMENTS,
    {
      variables: {
        ids,
        archivedAt: unarchive ? null : Date.now(),
      },
      refetchQueries: [GET_SCANNED_DOCUMENTS],
    }
  );

  const handleConfirm = async () => {
    const result = await archiveDocument();

    if (!result.errors) {
      closeModal();
    }
  };

  const documentCount = ids.length;

  return (
    <>
      {modalIsShown && (
        <Modal
          open={modalIsShown}
          setOpen={closeModal}
          title={unarchive ? 'Un-Archive documents?' : 'Archive documents?'}
          confirmButtonLabel={unarchive ? 'Un-Archive' : 'Archive'}
          handleConfirm={handleConfirm}
          loading={loading}
        >
          <div className="text-sm text-gray-600">
            <p>
              Do you really want to {unarchive ? 'un-archive' : 'archive'}{' '}
              <span className="font-bold">{documentCount}</span> document(s)?
            </p>
          </div>
        </Modal>
      )}
    </>
  );
};
