/* eslint-disable react/no-danger */
import styles from './RichTextPreview.module.css';

/**
 * This component is used to preview rich text
 * coming from the Quill editor.
 *
 * Since the editor uses HTML to format the text,
 * we need to use the `dangerouslySetInnerHTML` prop
 * to render the contents.
 *
 * @param dangeroulslySetHtml
 */
export const RichTextPreview = ({
  dangerouslySetHtml,
}: {
  dangerouslySetHtml: string;
}) => (
  <div
    className={styles.container}
    dangerouslySetInnerHTML={{
      __html: dangerouslySetHtml,
    }}
  />
);
