import { useTranslation } from '@getpopsure/i18n-react';
import { CheckBadgeType } from 'components/CheckBadge/CheckBadge';
import InformationCard, {
  InformationRow,
  InformationSection,
} from 'components/InformationCard';
import DateInformation from 'components/InformationCard/DateInformation';
import DropdownInformation from 'components/InformationCard/DropdownInformation';
import EditableInformationCard from 'components/InformationCard/EditableInformationCard';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import TextWithCheckInformation from 'components/InformationCard/TextWithCheckInformation';
import { UPDATE_PUBLIC_PROVIDER_POLICY } from 'graphql/publicHealth/provider/mutations';
import useStateWithLocalStorage from 'hooks/useStateWithLocalStorage';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import HealthCardSection from 'pages/policies/publicHealth/components/HealthCardSection';
import StartDateConfirmationModal from 'pages/policies/publicHealth/components/StartDateConfirmationModal';
import StudentChecklistCard from 'pages/policies/publicHealth/internal/components/StudentChecklistCard';
import { commissionEntitlementCutoffDate } from 'shared/commissionEntitlementCutoffDate';
import {
  getAOKProviderBranches,
  mapProviderBranch,
} from 'shared/mapProviderBranches/mapProviderBranches';
import { getProviderPublicHealthStatus } from 'shared/mapProviderPublicHealthStatus';
import { mapUserOccupation } from 'shared/mapUserOccupation';

import CommissionCheckCard from '../CommissionCheckCard';
import SideSection from '../SideSection';
import * as styles from './styles';

interface OverviewTabProps {
  data: PublicHealthPolicy;
}

const OverviewTab = ({ data }: OverviewTabProps) => {
  const { t } = useTranslation();
  const { value } = useStateWithLocalStorage('userInformation');
  const userInformation = JSON.parse(value);

  const customerInformation: InformationSection[] = [
    {
      title: t(
        'admin.provider.policy.public.overview.customerinfo.title',
        'Customer Information'
      ),
      rows: [
        {
          id: 'firstname',
          title: t(
            'admin.provider.policy.public.overview.customerinfo.firstname.title',
            'First name'
          ),
          data: data.insuredPerson.firstName ?? data.user.firstName ?? '',
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'lastname',
          title: t(
            'admin.provider.policy.public.overview.customerinfo.lastname.title',
            'Last name'
          ),
          data: data.insuredPerson.lastName ?? data.user.lastName ?? '',
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'dateofbirth',
          title: t(
            'admin.provider.policy.public.overview.customerinfo.dateofbirth.title',
            'Date of birth'
          ),
          data: data.insuredPerson.dateOfBirth ?? data.user.dateOfBirth ?? '',
          type: 'DATE',
          component: DateInformation,
        },
        {
          id: 'occupation',
          title: t(
            'admin.provider.policy.public.overview.customerinfo.occupation.title',
            'Occupation'
          ),
          data: mapUserOccupation(data.occupation),
          type: 'TEXT',
          component: TextInformation,
        },
      ],
    },
  ];

  const statusSetToCoveredAt = data.statusSetToCoveredAt
    ? new Date(data.statusSetToCoveredAt)
    : '';

  const commissionEntitlementRowOptions: InformationRow[] = [
    ...(data.commissionEntitlement === 'NONE' &&
    [
      'APPLICATION_RECEIVED',
      'APPLICATION_SENT',
      'PROCESSING',
      'ERR_MISSING_INFO',
      'UNI_CHECK',
    ].includes(data.publicStatus)
      ? [
          {
            id: 'commission',
            title: t(
              'admin.provider.policy.public.overview.policyinfo.commission.title',
              'Commission'
            ),
            data: t(
              'admin.provider.policy.public.overview.policyinfo.commission.notyetneeded.label',
              'Commission check not yet needed'
            ),
            type: 'TEXT' as const,
            component: TextInformation,
          },
        ]
      : []),
    ...(data.commissionEntitlement === 'NONE' &&
    data.publicStatus === 'COVERED' &&
    !data.commissionId &&
    statusSetToCoveredAt >= commissionEntitlementCutoffDate
      ? [
          {
            id: 'commission',
            title: t(
              'admin.provider.policy.public.overview.policyinfo.commission.title',
              'Commission'
            ),
            data: t(
              'admin.provider.policy.public.overview.policyinfo.commission.needed.label',
              'Commission entitlement check needed'
            ),
            type: 'TEXT_WITH_CHECK' as const,
            check: 'exclamation' as CheckBadgeType,
            component: TextWithCheckInformation,
          },
        ]
      : []),
    ...(data.commissionEntitlement === 'ACCEPTED'
      ? [
          {
            id: 'commission',
            title: t(
              'admin.provider.policy.public.overview.policyinfo.commission.title',
              'Commission'
            ),
            data: t(
              'admin.provider.policy.public.overview.policyinfo.commission.approved.label',
              'Commission entitlement approved Policy activation confirmed'
            ),
            type: 'TEXT_WITH_CHECK' as const,
            check: 'check' as CheckBadgeType,
            component: TextWithCheckInformation,
          },
        ]
      : []),
    ...(data.commissionEntitlement === 'DECLINED'
      ? [
          {
            id: 'commission',
            title: t(
              'admin.provider.policy.public.overview.policyinfo.commission.title',
              'Commission'
            ),
            data: t(
              'admin.provider.policy.public.overview.policyinfo.commission.declined.label',
              'Commission entitlement declined'
            ),
            type: 'TEXT_WITH_CHECK' as const,
            check: 'cross' as CheckBadgeType,
            component: TextWithCheckInformation,
          },
        ]
      : []),
    ...((data.commissionEntitlement === 'NONE' &&
      ['CANCELED', 'CANT_BE_COVERED', 'DROPPED_OUT'].includes(
        data.publicStatus
      )) ||
    (data.commissionEntitlement === 'NONE' &&
      data.publicStatus === 'COVERED' &&
      (data.commissionId ||
        commissionEntitlementCutoffDate >= statusSetToCoveredAt))
      ? [
          {
            id: 'commission',
            title: t(
              'admin.provider.policy.public.overview.policyinfo.commission.title',
              'Commission'
            ),
            data: t(
              'admin.provider.policy.public.overview.policyinfo.commission.notneeded.label',
              'No commission entitlement check needed'
            ),
            type: 'TEXT' as const,
            component: TextInformation,
          },
        ]
      : []),
  ];

  const policyInformation: InformationSection[] = [
    {
      title: t(
        'admin.provider.policy.public.overview.policyinfo.title',
        'Policy Information'
      ),
      rows: [
        {
          id: 'providerBranch',
          title: t(
            'admin.provider.policy.public.overview.policyinfo.provider.title',
            'Provider'
          ),
          data: data.providerBranch ?? data.providerId,
          type: 'DROPDOWN',
          component: DropdownInformation,
          editable: data.providerId === 'AOK',
          dropdownOptions: getAOKProviderBranches(),
          optionMapping: mapProviderBranch,
          multipleOptions: false,
          placeholder: 'Select provider branch',
        },
        {
          id: 'status',
          title: t(
            'admin.provider.policy.public.overview.policyinfo.status.title',
            'Status'
          ),
          data: data.publicStatus,
          type: 'STATUS',
          component: StatusInformation,
          statusMapping: getProviderPublicHealthStatus,
        },
        ...(data.providerId === 'BARMER'
          ? commissionEntitlementRowOptions
          : []),
        {
          id: 'createdon',
          title: t(
            'admin.provider.policy.public.overview.customerinfo.createdon.title',
            'Created on'
          ),
          data: data.createdAt,
          type: 'DATE',
          component: DateInformation,
        },
        {
          id: 'startDate',
          title: t(
            'admin.provider.policy.public.overview.policyinfo.startdate.title',
            'Start date'
          ),
          data: data.startDate ?? '',
          type: 'DATE',
          component: DateInformation,
          editable: !(
            data.publicStatus === 'CANT_BE_COVERED' ||
            data.publicStatus === 'CANCELED' ||
            data.publicStatus === 'DROPPED_OUT' ||
            data.publicStatus === 'COVERED'
          ),
        },
        {
          id: 'svnr',
          title: t(
            'admin.provider.policy.public.overview.policyinfo.svnr.title',
            'SVNR'
          ),
          data: data.user.svnr,
          type: 'TEXT',
          component: TextInformation,
          editable: true,
        },
        {
          id: 'kvnr',
          title: t(
            'admin.provider.policy.public.overview.policyinfo.kvnr.title',
            'KVNR'
          ),
          data: data.kvnr,
          type: 'TEXT',
          component: TextInformation,
          editable: true,
        },
        ...(data.providerAgent ||
        userInformation.roles.includes('PUBLIC_HEALTH_PROVIDER_AGENT_PLUS')
          ? [
              {
                id: 'source',
                title: t(
                  'admin.provider.policy.public.overview.customerinfo.source.title',
                  'Source'
                ),
                data: data.source ?? '',
                type: 'TEXT' as const,
                component: TextInformation,
              },
            ]
          : []),
        ...(data.providerId === 'TK'
          ? [
              {
                id: 'tkApiId',
                title: t(
                  'admin.provider.policy.public.overview.policyInfo.tkApiId.title',
                  'TK API ID'
                ),
                data: data.tkApiId ?? '',
                type: 'TEXT' as const,
                component: TextInformation,
              },
            ]
          : []),
      ],
    },
  ];

  const policyInformationEditableData = {
    providerBranch: data.providerBranch ?? data.providerId,
    kvnr: data.kvnr ?? '',
    svnr: data.user.svnr ?? '',
    startDate: data.startDate ?? '',
  };
  return (
    <>
      <div className={styles.tabWrapper}>
        {data.providerId === 'BARMER' && <CommissionCheckCard policy={data} />}
        <InformationCard sections={customerInformation} />
        <EditableInformationCard
          sections={policyInformation}
          editableData={policyInformationEditableData}
          mutation={UPDATE_PUBLIC_PROVIDER_POLICY}
          resourceId={data.id}
          ConfirmationModal={StartDateConfirmationModal}
          oldConfirmationValue={[
            {
              id: 'startDate',
              value: data.startDate ?? '',
            },
          ]}
          successMessage={t(
            'admin.provider.policy.public.overview.policyinfo.edit.success.title',
            'Your changes have been successfully saved'
          )}
        />
        {data.occupation === 'UNIVERSITY_STUDENT' && (
          <StudentChecklistCard policy={data} editable={false} />
        )}
        {data.healthCard && <HealthCardSection policy={data} />}
      </div>
      <div className="w-full lg:ml-[20px] lg:w-auto">
        <SideSection policy={data} />
      </div>
    </>
  );
};

export default OverviewTab;
