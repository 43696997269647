import { DentalClaimStatus } from './models';

export const statusNameMapper: Record<DentalClaimStatus, string> = {
  RECEIVED: 'Received',
  SUBMITTED_TO_PROVIDER: 'Sent',
  APPROVED: 'Approved',
  DUPLICATE: 'Duplicate',
  INCOMPLETE: 'Missing info',
  CUSTOMER_PAID_OUT: 'Customer paid out',
  DENIED: 'Denied',
  FEATHER_PAID_OUT: 'Feather paid out',
  CLOSED: 'Closed',
  MISSING_INFO_RECEIVED: 'Info received',
  CLOSED_WITHOUT_PAYMENT: 'Closed without payment',
  OTHER_PAID_OUT: 'Paid to 3rd party',
  DROPPED_OUT: 'Dropped out',
};
