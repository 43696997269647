import { gql } from '@apollo/client';

export const UPDATE_DENTAL_POLICY = gql`
  mutation UpdateDentalPolicy(
    $id: String!
    $policyNumber: String!
    $startDate: String!
    $price: Float!
    $activeUntil: String
    $paymentReceivedAt: String
    $source: String
    $campaign: String
    $medium: String
    $term: String
    $content: String
  ) {
    updateDentalPolicyInfo(
      id: $id
      policyNumber: $policyNumber
      startDate: $startDate
      activeUntil: $activeUntil
      price: $price
      paymentReceivedAt: $paymentReceivedAt
      source: $source
      campaign: $campaign
      medium: $medium
      content: $content
      term: $term
    ) {
      id
      policyNumber
      startDate
      price
      paymentReceivedAt
      source
      campaign
      medium
      content
      term
      activeUntil
      endDate
    }
  }
`;
