import { ApolloError } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import { TrashIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import OverviewPage from 'components/OverviewPage';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import Table from 'components/TableRefactor/Table';
import { HeaderConfig, RowConfig } from 'components/TableRefactor/types';
import { GET_TASK_TEMPLATES } from 'pages/admin/taskTemplates/graphql/queries';
import { useState } from 'react';
import { insuranceNameMapper } from 'shared/insurances/insuranceNameMapper';
import { insuranceTypes } from 'shared/insurances/types';

import CreateTaskTemplateModal from './components/CreateTaskTemplateModal/CreateTaskTemplateModal';
import DeleteConfirmationModal from './components/DeleteConfirmationModal/DeleteConfirmationModal';
import { Tag, TaskTemplate } from './components/taskTemplate.types';
import UpdateTaskTemplateModal from './components/UpdateTaskTemplateModal/UpdateTaskTemplateModal';

const tagsMap: Record<Tag, string> = {
  CLAIMS: 'Claims',
  POLICIES: 'Policies',
};

const NUMBER_OF_TASK_TEMPLATES_PER_PAGE = 12;

interface TaskTemplateTableView extends TaskTemplate {
  createdAt: string;
  updatedAt: string;
}

const headers: HeaderConfig[] = [
  {
    id: 'name',
    label: 'Name',
    width: 'w-[350px]',
    minWidth: 'min-w-[300px]',
    sticky: true,
    component: SortableColumnHeader,
  },
  {
    id: 'insuranceType',
    label: 'Vertical',
    width: 'w-[150px]',
    minWidth: 'min-w-[100px]',
    component: SortableColumnHeader,
  },
  {
    id: 'tag',
    label: 'Area',
    width: 'w-[100px]',
    minWidth: 'min-w-[100px]',
    component: SortableColumnHeader,
  },
  {
    id: 'createdAt',
    label: 'Created on',
    width: 'w-[150px]',
    minWidth: 'min-w-[100px]',
    component: SortableColumnHeader,
  },
  {
    id: 'updatedAt',
    label: 'Updated on',
    width: 'w-[150px]',
    minWidth: 'min-w-[100px]',
    component: SortableColumnHeader,
  },
  {
    id: 'actions',
    label: '',
    width: 'w-[80px]',
    minWidth: 'min-w-[80px]',
    component: ColumnHeader,
  },
];

const tagFilterOptions = [
  {
    title: 'Any',
    label: 'Any',
    id: 'any',
  },
  {
    title: 'Policies',
    label: 'Policies',
    id: 'POLICIES',
  },
  {
    title: 'Claims',
    label: 'Claims',
    id: 'CLAIMS',
  },
];

const insuranceTypeFilterOptions = insuranceTypes.map((type) => {
  const insuranceType = insuranceNameMapper[type];
  return {
    title: insuranceType,
    label: insuranceType,
    id: type,
  };
});

const TaskTemplatesPage = () => {
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const [selectedTemplate, setSelectedTemplate] = useState<
    TaskTemplateTableView | undefined
  >(undefined);
  const [editTemplateModal, setEditTemplateModal] = useState(false);
  const [deleteTemplateModal, setDeleteTemplateModal] = useState(false);
  const { t } = useTranslation();

  const tableFilter: FilterConfig[] = [
    {
      filterType: 'TEXT',
      options: tagFilterOptions,
      label: 'Area',
      id: 'filterTag',
    },
    {
      filterType: 'MULTIPLE',
      options: insuranceTypeFilterOptions,
      label: 'Vertical',
      id: 'filterInsuranceType',
    },
  ];

  const rowConfig: RowConfig = {
    resourceOnClick: (data) => {
      setSelectedTemplate(data);
      setEditTemplateModal(true);
    },
    cells: [
      {
        type: 'TEXT',
        textColor: 'dark',
        props: { getText: (data: TaskTemplateTableView) => data.name },
      },
      {
        type: 'TEXT',
        textColor: 'dark',
        props: {
          getText: (data: TaskTemplateTableView) =>
            insuranceNameMapper[data.insuranceType],
        },
      },
      {
        type: 'TEXT',
        textColor: 'dark',
        props: { getText: (data: TaskTemplateTableView) => tagsMap[data.tag] },
      },
      {
        type: 'DATE',
        textColor: 'dark',
        props: { getDate: (data: TaskTemplateTableView) => data.createdAt },
      },
      {
        type: 'DATE',
        textColor: 'light',
        props: { getDate: (data: TaskTemplateTableView) => data.updatedAt },
      },
      {
        type: 'ICON_BUTTON',
        textColor: 'dark',
        props: {
          getButtonText: () => 'Delete',
          onClick: (event) => {
            event.stopPropagation();
            setSelectedTemplate(event.data);
            setDeleteTemplateModal(true);
          },
          Icon: () => (
            <TrashIcon className={classNames('h-5 w-5', 'text-indigo-500')} />
          ),
        },
      },
    ],
  };

  return (
    <OverviewPage
      title="Task Templates"
      current="admin-task-templates"
      error={error}
    >
      <Table
        title={t('taskTemplates.table.title', 'Task templates')}
        query={GET_TASK_TEMPLATES}
        itemsPerPage={NUMBER_OF_TASK_TEMPLATES_PER_PAGE}
        searchPlaceholder="Search template"
        headers={headers}
        rowConfig={rowConfig}
        emptyStateLabel={{
          title: t(
            'taskTemplates.table.emptyState.title',
            'There are no task templates yet.'
          ),
          description: t(
            'taskTemplates.table.emptyState.description',
            'Looks like no task templates were created.'
          ),
        }}
        filterConfig={tableFilter}
        onError={setError}
        actionButton={<CreateTaskTemplateModal />}
      />
      <DeleteConfirmationModal
        open={deleteTemplateModal}
        setOpen={setDeleteTemplateModal}
        taskTemplate={selectedTemplate as TaskTemplate}
      />
      <UpdateTaskTemplateModal
        open={editTemplateModal}
        setOpen={setEditTemplateModal}
        taskTemplate={selectedTemplate as TaskTemplate}
      />
    </OverviewPage>
  );
};

export default TaskTemplatesPage;
