const LogoSmall = ({ className }: { className?: string }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.01039 14.0916C4.10643 15.8303 3.42438 17.4133 3.02665 18.6254C2.67145 19.708 2.35858 20.8932 3.38437 19.0031C3.99315 17.8813 5.49085 16.3069 8.88838 15.7206C7.48379 15.4137 6.17551 14.8538 5.01039 14.0916Z"
        fill="#B1B0F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.01039 14.0916C5.46829 13.2108 5.98312 12.2901 6.54678 11.3574C7.8488 12.2537 9.4041 12.7988 11.0809 12.859C10.912 12.8566 10.74 12.8479 10.5649 12.8326C12.5724 13.458 13.6332 13.0444 14.42 12.7377C14.5201 12.6987 14.6157 12.6614 14.7083 12.6282C12.9282 14.6511 11.766 15.2457 8.99031 15.7034C8.95615 15.7091 8.92217 15.7148 8.88839 15.7206C7.4838 15.4137 6.17552 14.8537 5.01039 14.0916Z"
        fill="#B1B0F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.54446 11.3612C9.34183 6.73135 13.3426 1.80453 17.5544 0C18.0069 0.572128 18.5422 2.17861 18.2081 3.9236C17.6348 6.91709 15.2514 8.71445 12.4254 8.64333C12.8847 8.88512 14.6379 9.54278 16.7096 8.60285C15.8805 11.1996 13.7822 13.039 10.738 12.8457C9.19118 12.7281 7.75774 12.1972 6.54446 11.3612Z"
        fill="#B1B0F5"
      />
    </svg>
  );
};

export default LogoSmall;
