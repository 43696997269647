import { DotsVerticalIcon } from '@heroicons/react/solid';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { useState } from 'react';

interface Props {
  shouldArchivePolicy: boolean;
  onArchive: () => void;
  onUnarchive: () => void;
  collapsedButton?: boolean;
}

const archivePolicyDescription = (
  <>
    <p className="text-sm text-gray-600">
      Are you sure you want to archive this policy?
    </p>
    <p className="text-sm text-gray-600">
      Customer <span className="font-bold">will no longer</span> see this policy
      on their Feather account. You can still view the archived policy in admin
      panel.
    </p>
  </>
);

const unarchivePolicyDescription = (
  <>
    <p className="text-sm text-gray-600">
      Are you sure you want to unarchive this policy?
    </p>
    <p className="text-sm text-gray-600">
      Customer <span className="font-bold">will start seeing</span> this policy
      on their Feather account.
    </p>
  </>
);

export const ArchivePolicy = ({
  shouldArchivePolicy,
  onArchive,
  onUnarchive,
  collapsedButton = false,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const onClick = shouldArchivePolicy ? onArchive : onUnarchive;
  const buttonText = shouldArchivePolicy ? 'Archive' : 'Unarchive';

  const modalTitle = shouldArchivePolicy
    ? 'Archive policy?'
    : 'Unarchive policy?';

  const modalDescription = shouldArchivePolicy
    ? archivePolicyDescription
    : unarchivePolicyDescription;

  return (
    <>
      <Modal
        title={modalTitle}
        open={isModalOpen}
        setOpen={setIsModalOpen}
        confirmButtonLabel={buttonText}
        handleConfirm={() => {
          onClick();
          setIsModalOpen(false);
          setIsPopupOpen(false);
        }}
      >
        {modalDescription}
      </Modal>
      {!collapsedButton && (
        <Button
          className="ml-[16px] w-[82px] px-[17px] py-[9px]"
          buttonType="white"
          onClick={() => setIsModalOpen(true)}
        >
          {buttonText}
        </Button>
      )}
      {collapsedButton && (
        <div>
          <Button
            buttonType="white"
            Icon={DotsVerticalIcon}
            className="w-9 pr-1"
            onClick={() => setIsPopupOpen(!isPopupOpen)}
          />
          {isPopupOpen && (
            <div>
              <button
                type="button"
                onClick={() => setIsModalOpen(true)}
                className="absolute bottom-3 right-10 z-10 w-32 rounded-md bg-white px-5 py-[10px] shadow-lg"
              >
                <div className="flex text-sm text-gray-600">{buttonText}</div>
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};
