import { gql } from '@apollo/client';

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer(
    $firstName: String!
    $lastName: String!
    $email: String!
    $dateOfBirth: String
    $gender: String
  ) {
    createCustomer(
      firstName: $firstName
      lastName: $lastName
      email: $email
      dateOfBirth: $dateOfBirth
      gender: $gender
    ) {
      id
      firstName
      lastName
      email
      created
      dateOfBirth
    }
  }
`;
