import { gql } from '@apollo/client';

export const UPLOAD_CLAIM_DOCUMENT = gql`
  mutation UploadClaimDocument(
    $claimId: String!
    $userId: String
    $file: Upload!
    $type: String!
    $isVisibleToCustomer: Boolean!
    $friendlyName: String
    $category: String
    $internalNotes: String
  ) {
    uploadClaimDocument(
      claimId: $claimId
      userId: $userId
      file: $file
      type: $type
      isVisibleToCustomer: $isVisibleToCustomer
      friendlyName: $friendlyName
      category: $category
      internalNotes: $internalNotes
    ) {
      id
      url
      blobName
    }
  }
`;

export const DELETE_CLAIM_DOCUMENT = gql`
  mutation DeleteClaimDocument(
    $resourceId: String!
    $id: String!
    $blobName: String!
    $type: String!
  ) {
    deleteClaimDocument(
      claimId: $resourceId
      id: $id
      blobName: $blobName
      type: $type
    ) {
      id
      name
      type
      blobName
    }
  }
`;

export const UPDATE_CLAIM_DOCUMENT = gql`
  mutation UpdateClaimDocument(
    $id: String!
    $type: String!
    $category: String!
    $friendlyName: String
    $isVisibleToCustomer: Boolean!
    $internalNotes: String
  ) {
    updateClaimDocument(
      id: $id
      type: $type
      category: $category
      friendlyName: $friendlyName
      isVisibleToCustomer: $isVisibleToCustomer
      internalNotes: $internalNotes
    ) {
      id
      url
    }
  }
`;

export const ARCHIVE_CLAIM_DOCUMENT = gql`
  mutation ArchiveClaimDocument($id: String!) {
    archiveClaimDocument(id: $id) {
      id
    }
  }
`;

export const UNARCHIVE_CLAIM_DOCUMENT = gql`
  mutation UnarchiveClaimDocument($id: String!) {
    unarchiveClaimDocument(id: $id) {
      id
    }
  }
`;
