import { bikeClaimStatuses, bikeClaimTypes } from 'shared/claims/bike/models';
import { z } from 'zod';

export const zodSubmittableClaimSchema = z.object({
  policyId: z.string().uuid(),
  status: z.enum(bikeClaimStatuses),
  claimType: z.array(z.enum(bikeClaimTypes)),
  dateOfEvent: z.string(),
  eventDescription: z.string().optional(),
  iban: z.string().min(1, { message: 'This field is required' }),
  amount: z.string().optional(),
  uploadDocument: z.custom<File>().optional(),
});
