import { IssueWithCorrectTypes } from 'models/issue';

export const shouldDisplayIssueActivityDot = (
  issues?: IssueWithCorrectTypes[]
): boolean => {
  if (!issues) {
    return false;
  }

  const hasOpenIssueWithoutTasks = issues?.some(
    ({ status, tasks }) =>
      // Check if any issue is OPEN with no tasks attached
      status === 'OPEN' && (!tasks || tasks?.length === 0)
  );

  if (hasOpenIssueWithoutTasks) {
    return true;
  }

  const hasOpenIssueWithAllTasksCompleted = issues?.some(
    ({ status, tasks }) =>
      // Check if any issue is OPEN
      status === 'OPEN' &&
      // Check if all tasks are either COMPLETED or CANCELED
      tasks?.length ===
        tasks?.filter(
          ({ status }) => status === 'COMPLETED' || status === 'CANCELED'
        )?.length &&
      // Check that at least one task is COMPLETED
      tasks?.some(({ status }) => status === 'COMPLETED')
  );

  return hasOpenIssueWithAllTasksCompleted ?? false;
};
