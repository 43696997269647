import { CustomerDelinquencyBanner } from 'components/CustomerDelinquencyBanner';
import { CustomerFraudulentBanner } from 'components/CustomerFraudulentBanner';
import { PinnedNotes } from 'components/Notes/PinnedNotes';
import { Note } from 'models/Note';
import { PaymentMethod } from 'models/PaymentMethod';
import { User } from 'models/User';

import { GET_CUSTOMER } from '../../graphql/queries';
import { CustomerInfo } from './components/CustomerInfo';
import { CustomerPaymentMethods } from './components/CustomerPaymentMethods';
import { CustomerPolicies } from './components/CustomerPolicies';
import { CustomerPolicy } from './components/CustomerPolicies/models';
import * as styles from './styles';

interface Props {
  customer?: User;
  customerLoading: boolean;
  policies?: CustomerPolicy[];
  paymentMethods?: PaymentMethod[];
  pinnedNotes?: Note[];
}

export const OverviewTabView = ({
  policies,
  customer,
  customerLoading,
  paymentMethods,
  pinnedNotes,
}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <CustomerDelinquencyBanner
          customer={customer}
          loading={customerLoading}
        />
        <CustomerFraudulentBanner
          customer={customer}
          loading={customerLoading}
        />
        <CustomerPolicies
          customer={customer}
          policies={policies}
          loading={customerLoading}
        />
        <CustomerInfo customer={customer} loading={customerLoading} />
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.pinnedNotesContainer}>
          <PinnedNotes
            notes={pinnedNotes ?? []}
            refetchQueries={[GET_CUSTOMER]}
          />
        </div>
        <CustomerPaymentMethods paymentMethods={paymentMethods} />
      </div>
    </div>
  );
};
