import { gql } from '@apollo/client';

export const UPDATE_BIKE_CLAIM = gql`
  mutation UpdateBikeClaim(
    $claimId: String!
    $claimType: [String]
    $eventDescription: String
    $iban: String!
    $amount: Float
    $refundAmount: Float
    $customerPaidOn: String
    $dateOfEvent: String!
  ) {
    updateBikeClaim(
      claimId: $claimId
      claimType: $claimType
      eventDescription: $eventDescription
      iban: $iban
      amount: $amount
      refundAmount: $refundAmount
      customerPaidOn: $customerPaidOn
      dateOfEvent: $dateOfEvent
    ) {
      id
      claimType {
        id
        type
      }
      eventDescription
      iban
      amount
      refundAmount
      customerPaidOn
      dateOfEvent
    }
  }
`;
