import { useQuery } from '@apollo/client';
import { useState } from 'react';

import { TaskCard } from '../TaskCard/TaskCard';
import { Task } from '../TaskEngine.types';
import { TaskPreviewModal } from '../TaskPreviewModal/TaskPreviewModal';
import {
  SystemTasksLoader,
  SystemTasksPlaceholder,
} from './components/SystemTasksPlaceholder';
import { getSystemTaskAction, getSystemTaskTitle } from './SystemTasks.models';
import { GET_SYSTEM_TASKS } from './SystemTasks.queries';

interface SystemTasksProps {
  policyOrClaimId?: string;
}

export const SystemTasks = ({ policyOrClaimId }: SystemTasksProps) => {
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);

  const { data, loading } = useQuery<{ tasks: Task[] }>(GET_SYSTEM_TASKS, {
    variables: {
      where: {
        category: 'SYSTEM',
        description: {
          path: ['attributes', 'policyId'],
          equals: policyOrClaimId,
        },
      },
    },
  });

  const handleOpenTask = (task: Task) => () => setSelectedTask(task);

  const tasksWithMappedTitles: Task[] = (data?.tasks || []).map((task) => ({
    ...task,
    description: {
      ...task.description,
      metadata: {
        title: getSystemTaskTitle(task.description.type),
      },
      actions: getSystemTaskAction(task.description.type),
    },
  }));

  if (loading) return <SystemTasksLoader />;

  if (!tasksWithMappedTitles.length) return <SystemTasksPlaceholder />;

  return (
    <>
      <div className="mt-[24px]">
        {tasksWithMappedTitles.map((task) => (
          <TaskCard
            key={task.id}
            task={task}
            onClickTask={handleOpenTask(task)}
          />
        ))}
      </div>
      {selectedTask && (
        <TaskPreviewModal
          task={selectedTask}
          onClose={() => setSelectedTask(null)}
        />
      )}
    </>
  );
};
