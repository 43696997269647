import { useTranslation } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import { RichTextEditor } from 'components/RichTextEditor';

import * as styles from './styles';

interface Props {
  value: string;
  onChange: (value: string) => void;

  isLoading: boolean;

  onCancel: () => void;
  onUpdate: () => void;
}

export const EditableTextarea = ({
  value,
  onChange,
  isLoading,
  onUpdate,
  onCancel,
}: Props) => {
  const { t } = useTranslation();

  const isSubmitDisabled = isLoading || !value || value.trim() === '';

  return (
    <>
      <RichTextEditor
        className={styles.updateNoteTextArea}
        value={value}
        setValue={onChange}
        placeholder={t('notes.addNote.placeholder', 'Add a note')}
      />
      <div className={styles.updateNoteCtaWrapper}>
        <Button
          buttonType="white"
          className={styles.updateNoteCtaButton}
          onClick={onCancel}
          disabled={isLoading}
        >
          {t('notes.notesCard.edit.cancel.button', 'Cancel')}
        </Button>
        <Button
          buttonType="secondary"
          className={styles.updateNoteCtaButton}
          onClick={onUpdate}
          disabled={isSubmitDisabled}
        >
          {t('notes.notesCard.edit.save.button', 'Save')}
        </Button>
      </div>
    </>
  );
};
