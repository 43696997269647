import classNames from 'classnames';

export const container = classNames('animate-appear-in');

export const paymentMethodCard = classNames(
  'border-t',
  'border-t-gray-200',
  'px-[24px]',
  'py-[20px]',
  'flex',
  'justify-between',
  'items-center',
  'hover:bg-gray-50',
  'max-w-full',
  'min-w-full',
  'text-left'
);

export const cardNumberContainer = classNames('ml-[12px]');

export const sepaNumberContainer = classNames(
  'ml-[12px]',
  'flex',
  'self-center'
);

export const paypalEmailContainer = classNames(
  'ml-[12px]',
  'flex',
  'self-center',
  'max-w-[100%]'
);

export const noPaymentMethodsText = classNames(
  'text-sm',
  'text-gray-600',
  'text-center',
  'px-[24px]',
  'py-[30px]',
  'border-t',
  'border-t-gray-200'
);

export const defaultPayment = classNames(
  'px-[12px]',
  'py-[2px]',
  'text-sm',
  'text-gray-700',
  'bg-gray-300',
  'w-[70px]',
  'h-[24px]',
  'rounded-[4px]'
);

export const expireDate = classNames('text-xs', 'text-gray-600');

export const number = classNames('text-sm', 'text-ellipsis', 'overflow-clip');
