import ActivityCards from 'components/ActivityCards';
import { Activity } from 'components/ActivityCards/ActivityCards';
import {
  UPDATE_PROVIDER_ISSUE_STATUS,
  UPLOAD_PROVIDER_POLICY_FILE,
} from 'graphql/publicHealth/provider/mutations';
import { GET_PUBLIC_PROVIDER_POLICY } from 'graphql/publicHealth/provider/queries';
import { getProviderPublicHealthStatus } from 'shared/mapProviderPublicHealthStatus';

import * as styles from './styles';

interface ActivityTabProps {
  activity: Activity[];
  policyId: string;
}

const ActivityTab = ({ activity, policyId }: ActivityTabProps) => {
  return (
    <div className={styles.tabWrapper}>
      <ActivityCards
        activity={activity}
        policyId={policyId}
        statusMapping={getProviderPublicHealthStatus}
        showCustomerInformed={false}
        policyQuery={GET_PUBLIC_PROVIDER_POLICY}
        issueStatusChangeMutation={UPDATE_PROVIDER_ISSUE_STATUS}
        uploadFileMutation={UPLOAD_PROVIDER_POLICY_FILE}
      />
    </div>
  );
};

export default ActivityTab;
