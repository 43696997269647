import classNames from 'classnames';

export const optionTitle = classNames('flex', 'items-center');

export const textSelectStatus = classNames(
  'text-sm',
  'font-bold',
  'text-indigo-500'
);

export const textSelectStatusWithDot = classNames(
  'text-sm',
  'text-gray-600',
  'ml-[10px]',
  'font-bold'
);

export const textSelectAnyStatus = classNames(
  'text-sm',
  'font-bold',
  '!text-gray-600'
);
