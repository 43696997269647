import { useTranslation } from '@getpopsure/i18n-react';
import { CustomerDelinquencyBanner } from 'components/CustomerDelinquencyBanner';
import { CustomerFraudulentBanner } from 'components/CustomerFraudulentBanner';
import DuplicateHealthPoliciesCard from 'components/DuplicateHealthPoliciesCard/DuplicateHealthPoliciesCard';
import FileErrorCard from 'components/FileErrorCard';
import InformationCard from 'components/InformationCard';
import {
  EditableInformationCardV2,
  InformationCardV2,
} from 'components/InformationCardV2';
import { UPDATE_EXPAT_CANCELATION_INFORMATION } from 'pages/policies/expatHealth/graphql/updateCancelationInformation';
import { UPDATE_EXPAT_POLICY_INFORMATION } from 'pages/policies/expatHealth/graphql/updatePolicyInformation';
import { ExpatHealthPolicy } from 'pages/policies/expatHealth/models/ExpatHealthPolicy';

import SideSection from '../SideSection/SideSection';
import WaitingPeriodAlert from '../WaitingPeriodAlert/WaitingPeriodAlert';
import {
  getFailedPolicyConfirmationEvents,
  getInsuredPersonInformation,
  getPolicyCancelationInformation,
  getPolicyInformation,
  policyCancelationInformationZodSchema,
  policyInformationZodSchema,
} from './data';

interface OverviewTabProps {
  policy: ExpatHealthPolicy;
}

const OverviewTab = ({ policy }: OverviewTabProps) => {
  const { t } = useTranslation();

  if (!policy) {
    throw new Error('[Expat policies] Policy not found');
  }

  const isPolicyInactive =
    policy.publicStatus === 'CANCELED' || policy.publicStatus === 'DROPPED_OUT';

  return (
    <>
      <div className="relative flex w-full flex-col">
        <CustomerDelinquencyBanner customer={policy.user} linkToCustomer />
        <CustomerFraudulentBanner customer={policy.user} />
        {policy?.archivedAt && (
          <FileErrorCard
            open
            hideCloseButton
            handleClose={() => {}}
            title="This policy is archived"
            errorType="WARNING"
            className="mb-[16px] mt-0"
          />
        )}
        <WaitingPeriodAlert waitingPeriodDate={policy?.waitingPeriod} />
        <DuplicateHealthPoliciesCard
          policy={policy}
          insuranceType="EXPAT_HEALTH"
        />

        <InformationCardV2 sections={getInsuredPersonInformation(policy, t)} />

        <EditableInformationCardV2
          sections={getPolicyInformation(policy)}
          validationSchema={policyInformationZodSchema}
          editMutation={UPDATE_EXPAT_POLICY_INFORMATION}
          editSuccessMessage="You have successfully saved the changes"
          additionalVariables={{ id: policy?.id }}
        />

        {isPolicyInactive && (
          <EditableInformationCardV2
            sections={getPolicyCancelationInformation(policy)}
            validationSchema={policyCancelationInformationZodSchema}
            editMutation={UPDATE_EXPAT_CANCELATION_INFORMATION}
            editSuccessMessage="You have successfully saved the changes"
            additionalVariables={{ id: policy?.id }}
          />
        )}
        {policy.publicStatus === 'PENDING' &&
          policy.failedPolicyConfirmationEvents.length > 0 && (
            <InformationCard
              sections={getFailedPolicyConfirmationEvents(policy)}
            />
          )}
      </div>
      <div className="w-full lg:ml-[20px] lg:w-auto">
        <SideSection policy={policy} />
      </div>
    </>
  );
};

export default OverviewTab;
