interface Props {
  pinned?: boolean;
}
export const PinIcon = ({ pinned = false }: Props) => (
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.66545 11.3922L8.66545 17.4106L8.66545 11.3922Z"
      fill="currentColor"
    />
    <path
      d="M8.66545 11.3922L8.66545 17.4106"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.25998 10.154C4.25997 10.7063 4.70769 11.154 5.25998 11.154L11.7713 11.154C12.3236 11.154 12.7713 10.7063 12.7713 10.154L12.7713 10.0587C12.7713 9.75757 12.6355 9.47245 12.4018 9.28257L11.2462 8.34385C10.99 8.13579 10.853 7.81475 10.88 7.48586L11.135 4.37922C11.1829 3.79664 10.7229 3.29742 10.1384 3.29742L6.89298 3.29742C6.30844 3.29742 5.84852 3.79662 5.89633 4.3792L6.15125 7.48585C6.17824 7.81473 6.04124 8.13576 5.78511 8.34382L4.62947 9.28256C4.39571 9.47244 4.25998 9.75757 4.25998 10.0587L4.25998 10.154Z"
      fill={pinned ? 'currentColor' : 'transparent'}
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
