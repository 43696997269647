import { gql } from '@apollo/client';

export const GET_QUESTIONNAIRES = gql`
  query Query(
    $userPolicyExists: String!
    $questionnaireType: [String!]!
    $searchString: String!
    $offset: Float!
    $limit: Float!
    $createdAtRangeEnd: DateTime
    $createdAtRangeStart: DateTime
  ) {
    questionnaires(
      userPolicyExists: $userPolicyExists
      questionnaireType: $questionnaireType
      searchString: $searchString
      offset: $offset
      limit: $limit
      createdAtRangeEnd: $createdAtRangeEnd
      createdAtRangeStart: $createdAtRangeStart
    ) {
      answers
      createdAt
      id
      questionnaireType
      user {
        firstName
        lastName
      }
      userPolicy {
        id
        insuranceType
      }
    }
    totalQuestionnairesCount(
      userPolicyExists: $userPolicyExists
      questionnaireType: $questionnaireType
      searchString: $searchString
      createdAtRangeEnd: $createdAtRangeEnd
      createdAtRangeStart: $createdAtRangeStart
    )
  }
`;
