import Badge from 'components/Badge';
import { PolicyStatus } from 'models/publicHealthStatus';

import TextInformation, { TextInformationProps } from '../TextInformation';

const StatusInformation = ({
  cell,
  statusMapping,
  id,
}: TextInformationProps) => {
  if (!statusMapping) {
    throw new Error('Missing status mapping function');
  }
  const { text, color } = statusMapping(cell as PolicyStatus);
  const status = (
    <Badge color={color} badgeType="full">
      {text.text}
    </Badge>
  );
  return <TextInformation cell={status} id={id} />;
};

export default StatusInformation;
