import { gql } from '@apollo/client';

export const CREATE_TASK_TEMPLATE = gql`
  mutation createTaskTemplate($input: CreateTaskTemplateInput!) {
    createTaskTemplate(input: $input) {
      id
      name
      tag
      insuranceType
      data
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_TASK_TEMPLATE = gql`
  mutation updateTaskTemplate($id: String!, $input: UpdateTaskTemplateInput!) {
    updateTaskTemplate(id: $id, input: $input) {
      id
      name
      tag
      insuranceType
      data
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_TASK_TEMPLATE = gql`
  mutation deleteTaskTemplate($id: String!) {
    deleteTaskTemplate(id: $id) {
      id
    }
  }
`;
