import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { RowConfig } from 'components/TableRefactor/types';
import { LiabilityClaim } from 'pages/claims/liability/models';
import { retrieveReadableClaimTypes } from 'pages/claims/template/components/ClaimsDetails/utils';
import {
  retrieveClaimsDetailsPath,
  statusMapping,
} from 'pages/claims/template/utils/utils';
import { policyMappers } from 'shared/insurances';

export const rowConfig: RowConfig = {
  getResourceLink: (data: LiabilityClaim) =>
    retrieveClaimsDetailsPath('liability', data.id),
  cells: [
    {
      type: 'TEXT',
      textColor: 'dark',
      props: {
        getText: (data: LiabilityClaim) => data.user.firstName ?? '',
      },
    },
    {
      type: 'TEXT',
      textColor: 'dark',
      props: { getText: (data: LiabilityClaim) => data.user.lastName ?? '' },
    },
    {
      type: 'STATUS',
      textColor: 'dark',
      props: {
        getStatus: ({ status }: LiabilityClaim) =>
          statusMapping(
            status && policyMappers.LIABILITY?.claim?.claimStatusMapper
              ? policyMappers.LIABILITY.claim.claimStatusMapper[status]
              : '',
            status && policyMappers.LIABILITY?.claim?.badgeColorMapper
              ? policyMappers.LIABILITY.claim.badgeColorMapper[status]
              : 'blue'
          ),
      },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: {
        getText: (data: LiabilityClaim) =>
          data.claimType?.length
            ? retrieveReadableClaimTypes(
                data.claimType.length,
                policyMappers.LIABILITY?.claim?.typeNameMapper[
                  data.claimType[0].type
                ] ?? ''
              )
            : '',
      },
    },
    {
      type: 'DATE',
      textColor: 'light',
      props: { getDate: (data: LiabilityClaim) => data.createdAt },
    },
    {
      type: 'CLAIM_NUMBER',
      textColor: 'light',
      props: {
        getClaimDetails: (data: LiabilityClaim) => ({
          id: data.id,
          claimNumber: data.claimNumber,
        }),
      },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: {
        getText: (data: LiabilityClaim) =>
          data.provision ? englishFormattedEuroCurrency(data.provision) : '',
      },
    },
  ],
};
