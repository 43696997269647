import Link from 'components/Link';

interface TableCellLinkProps {
  data: unknown;
  getLink: (data: any) => {
    label: string;
    href: string;
  };
}

export const TableCellLink = ({ data, getLink }: TableCellLinkProps) => {
  const cellData = getLink(data);

  return (
    <div className="flex justify-start">
      <Link
        color="primary"
        text={cellData.label}
        href={cellData.href}
        disabled={!cellData.href}
        target="_blank"
      />
    </div>
  );
};
