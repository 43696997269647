import classNames from 'classnames';

export const columnHeader = classNames(
  'p-[14px]',
  'text-left',
  'text-sm',
  'font-bold',
  'text-gray-600',
  'border-b',
  'border-gray-300'
);

export const sticky = classNames('sticky', 'bg-white');

export const borderRight = classNames(
  'border-r',
  'border-gray-300',
  'table-breakpoint:border-r-0'
);

export const borderLeft = classNames(
  'border-l',
  'border-gray-300',
  'table-breakpoint:border-l-0'
);

export const contentWrapper = classNames(
  'flex',
  'justify-between',
  'items-center'
);
