import InformationCard, {
  InformationSection,
} from 'components/InformationCard';
import DateInformation from 'components/InformationCard/DateInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import TextWithCheckInformation from 'components/InformationCard/TextWithCheckInformation';
import QuestionnaireCard from 'components/QuestionnaireCard';
import { startCase } from 'lodash';

import { Questionnaire } from '../models/questionnaire';

const OverviewTab = ({ questionnaire }: { questionnaire: Questionnaire }) => {
  const details: InformationSection = {
    title: 'Questionnaire details',
    rows: [
      {
        id: 'name',
        title: 'Customer name',
        data: questionnaire.user
          ? `${questionnaire.user.firstName} ${questionnaire.user.lastName}`
          : '',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'id',
        title: 'ID',
        data: questionnaire.id,
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'questionnaireType',
        title: 'Questionnaire Type',
        data: startCase(questionnaire.questionnaireType),
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'policy',
        title: 'Policy purchased',
        data: '',
        check: questionnaire.userPolicy ? 'check' : 'cross',
        type: 'TEXT_WITH_CHECK',
        component: TextWithCheckInformation,
      },
      {
        id: 'createdAt',
        title: 'Created on',
        data: questionnaire.createdAt,
        type: 'DATE',
        component: DateInformation,
      },
    ],
  };

  return (
    <div className="relative flex w-full flex-col">
      <InformationCard sections={[details]} />
      <QuestionnaireCard
        questionnaireId={questionnaire.id}
        policyId=""
        answers={questionnaire.answers}
        cardTitle="Customer answers"
      />
    </div>
  );
};

export default OverviewTab;
