import classNames from 'classnames';

import * as styles from './styles';

interface CardProps {
  title: string | React.ReactNode;
  boldTitle: boolean;
  children?: React.ReactNode[] | React.ReactNode;
  actionButton?: React.ReactNode;
}

const Card = ({ title, boldTitle, children, actionButton }: CardProps) => {
  return (
    <div className={styles.card}>
      <div
        className={classNames(styles.cardRowTitle, {
          [styles.cardRowTitleWithButton]: actionButton,
        })}
      >
        {typeof title === 'string' ? (
          <h1
            className={classNames(styles.title, {
              [styles.titleBold]: boldTitle,
            })}
          >
            {title}
          </h1>
        ) : (
          title
        )}
        {actionButton}
      </div>
      {children}
    </div>
  );
};

export default Card;
