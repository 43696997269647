import { gql } from '@apollo/client';

export const GET_GENERIC_POLICIES = gql`
  query GenericPolicies(
    $limit: Float!
    $offset: Float!
    $searchString: String!
    $sortOrder: String!
    $insuranceType: String!
    $sortColumn: String!
    $filterStatus: [String]!
    $region: [String]
  ) {
    genericPolicies(
      limit: $limit
      offset: $offset
      searchString: $searchString
      sortOrder: $sortOrder
      insuranceType: $insuranceType
      sortColumn: $sortColumn
      filterStatus: $filterStatus
      region: $region
    ) {
      user {
        firstName
        lastName
        id
      }
      insuredPerson {
        firstName
        lastName
      }
      startDate
      source
      publicStatus
      insuranceType
      policyNumber
      id
      createdAt
      archivedAt
      regionOfPurchase
    }
    totalGenericPolicies(
      searchString: $searchString
      insuranceType: $insuranceType
      filterStatus: $filterStatus
      region: $region
    )
  }
`;
