import { InformationSection } from 'components/InformationCard';
import ComboBoxInformation from 'components/InformationCard/ComboBoxInformation/ComboBoxInformation';
import { CurrencyInformation } from 'components/InformationCard/CurrencyInformation';
import DateInformation from 'components/InformationCard/DateInformation';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { ExpatHealthClaim } from 'pages/claims/expatHealth/models';
import { claimTypeNameMapper } from 'shared/claims/expatHealth/claimTypeNameMapper';
import {
  deprecatedClaimTypes,
  ExpatClaimType,
  expatClaimTypes,
} from 'shared/claims/expatHealth/models';
import { policyMappers } from 'shared/insurances/';

export const retrieveExpatClaimInfoOldConfirmationValue = (
  claimInfo: ExpatHealthClaim
) => {
  return [
    {
      id: 'iban',
      value: claimInfo.iban ?? '',
    },
  ];
};

export const retrieveExpatClaimInfoRows = (
  claimInfo: ExpatHealthClaim
): InformationSection[] => [
  {
    id: 'claimInfo',
    title: 'Claim information',
    boldTitle: false,
    rows: [
      {
        id: 'claimNumber',
        title: 'Claim number',
        data: claimInfo?.claimNumber ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
      {
        id: 'status',
        title: 'Status',
        data: claimInfo?.status ?? 'RECEIVED',
        statusMapping: (status) => {
          return {
            color:
              policyMappers.INCOMING?.claim?.badgeColorMapper[status] ?? 'blue',
            text: {
              text:
                policyMappers.INCOMING?.claim?.claimStatusMapper[status] ?? '',
              id: status,
            },
          };
        },
        type: 'STATUS',
        component: StatusInformation,
        editable: false,
      },
      {
        id: 'claimType',
        title: 'Claim type',
        data: claimInfo.claimType?.map(({ type }) => type).join(',') ?? '',
        type: 'DROPDOWN',
        component: ComboBoxInformation,
        dropdownOptions: expatClaimTypes.flatMap((type) => {
          if (deprecatedClaimTypes.includes(type)) {
            return [];
          }
          return {
            id: type,
            label: claimTypeNameMapper[type as ExpatClaimType],
          };
        }),
        optionMapping: (option: string) =>
          claimTypeNameMapper[option as ExpatClaimType],
        placeholder: 'Choose a claim type',
        editable: true,
        multipleOptions: true,
        required: true,
      },
      {
        id: 'createdOn',
        title: 'Created on',
        data: claimInfo?.createdAt ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: false,
      },
      {
        id: 'amount',
        title: 'Claimed amount',
        data: claimInfo?.amount ?? 0,
        type: 'CURRENCY',
        component: CurrencyInformation,
        editable: true,
        required: true,
      },
      {
        id: 'iban',
        title: 'IBAN',
        data: claimInfo?.iban ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: true,
      },
      {
        id: 'refundAmount',
        title: 'Refund amount',
        data: claimInfo?.refundAmount ?? 0,
        type: 'CURRENCY',
        component: CurrencyInformation,
        editable: true,
      },
      {
        id: 'featherPaidOn',
        title: 'Feather paid on',
        data: claimInfo?.featherPaidOn ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: true,
      },
      {
        id: 'customerPaidOn',
        title: 'Customer paid on',
        data: claimInfo?.customerPaidOn ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: true,
      },
      {
        id: 'thirdPartyPaidOn',
        title: '3rd party paid on',
        data: claimInfo?.thirdPartyPaidOn ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: true,
      },
      {
        id: 'notificationSentToProviderAt',
        title: 'Sent to provider on',
        data: claimInfo?.notificationSentToProviderAt ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: true,
      },
    ],
  },
];
