import { gql } from '@apollo/client';

export const CREATE_AND_SEND_TK_API_REQUEST = gql`
  mutation CreateTkApiRequest($payload: String!, $policyId: String!) {
    createTkApiRequest(payload: $payload, policyId: $policyId) {
      id
      payload
      status
      userPolicy {
        id
      }
    }
  }
`;
