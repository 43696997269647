import {
  ChatAltIcon,
  CloudUploadIcon,
  ExclamationIcon,
  PencilIcon,
  PlusIcon,
  SpeakerphoneIcon,
  TagIcon,
  TrashIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';

import * as styles from './styles';

export type ActivityCircleType =
  | 'CLAIM_SUBMISSION'
  | 'CREATE'
  | 'EDIT'
  | 'NOTE'
  | 'POLICY_ARCHIVE'
  | 'REMOVE'
  | 'STATUS_CHANGE'
  | 'WARNING'
  | 'ISSUES_OR_TASKS';

interface ActivityCircleProps {
  activityType: ActivityCircleType | string;
  smallVersion?: boolean;
  hasActivityLine?: boolean;
}

const ActivityIcon = ({
  activityType,
  ...props
}: {
  activityType: ActivityCircleType | string;
  className: string;
}) => {
  const icons: Record<ActivityCircleType | string, React.ElementType> = {
    CLAIM_SUBMISSION: CloudUploadIcon,
    CREATE: PlusIcon,
    EDIT: PencilIcon,
    NOTE: ChatAltIcon,
    POLICY_ARCHIVE: TagIcon,
    REMOVE: TrashIcon,
    STATUS_CHANGE: TagIcon,
    WARNING: ExclamationIcon,
    ISSUES_OR_TASKS: SpeakerphoneIcon,
  };

  const Icon = icons[activityType] || SpeakerphoneIcon;

  return <Icon {...props} />;
};

const ActivityCircle = ({
  activityType,
  smallVersion = false,
  hasActivityLine = false,
}: ActivityCircleProps) => {
  return (
    <div className={styles.activityCircleWrapper}>
      <div
        className={classNames(styles.activityCircle, {
          [styles.activityCircleFull]: !smallVersion,
          [styles.activityCircleSmall]: smallVersion,
        })}
      >
        <ActivityIcon activityType={activityType} className={styles.icon} />
      </div>
      {hasActivityLine && (
        <div
          className={
            smallVersion ? styles.activityLineSmall : styles.activityLineFull
          }
        />
      )}
    </div>
  );
};

export default ActivityCircle;
