import { gql } from '@apollo/client';

export const GET_GENERIC_POLICY = gql`
  query GenericPolicy($id: String!, $insuranceType: String!) {
    genericPolicy(id: $id, insuranceType: $insuranceType) {
      startDate
      source
      campaign
      medium
      content
      term
      publicStatus
      providerId
      price
      policyPurchaseEmail
      policyNumber
      providerPolicyNumber
      insuranceType
      id
      formId
      createdAt
      genericPolicyData
      archivedAt
      activeUntil
      regionOfPurchase
      numberOfClaims
      company {
        id
        name
      }

      user {
        firstName
        lastName
        stripeToken
        intercomEUId
        id
        email
        isDelinquent
        isFraudulent
        fraudulentAdditionalInfo
      }
      insuredPerson {
        firstName
        lastName
      }
      policyDocuments {
        id
        name
        url
        category
        isFromProvider
        archivedAt
        internalNotes
        isVisibleToCustomer
        isVisibleToHr
        isVisibleToProvider
      }
      questionnaireDownloadDocuments {
        id
        url
        blobName
      }
      customerUploads {
        id
        url
        blobName
      }

      # policy cancelation request
      policyCancelationRequest {
        id
        cancelationConfirmedByProviderAt
        createdAt
        canceledOnStripeAt
        requestSentToProviderAt
      }

      typeformData {
        id
        answers
      }
      questionnaire {
        id
        answers
      }

      pinnedNotes {
        id
        body
        createdAt
        adminUser {
          firstName
          lastName
        }
        updatedAt
      }

      referral {
        referralCode
        status
      }

      customerSalary
    }
  }
`;
