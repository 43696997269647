import classNames from 'classnames';

export const pageButtonSepartor = classNames(
  'bg-white',
  'text-gray-600',
  'relative',
  'items-center',
  'px-4',
  'py-2',
  'text-sm',
  'mr-px',
  'rounded-[2px]'
);

export const hidden = classNames('hidden');

export const flex = classNames('inline-flex');
