import { useMutation } from '@apollo/client';
import classnames from 'classnames';
import Button from 'components/Button';
import FileErrorCard from 'components/FileErrorCard';
import Modal from 'components/Modal';
import { ACTIVATE_DENTAL_POLICY } from 'pages/scannedDocuments/scannedDocuments.mutations';
import {
  GET_DENTAL_POLICY_BY_NAME,
  GET_SCANNED_DOCUMENT_BY_ID,
} from 'pages/scannedDocuments/scannedDocuments.queries';
import {
  BasePolicy,
  ScannedDocument,
} from 'pages/scannedDocuments/scannedDocuments.types';
import { getPolicyDetailsBaseURLFromInsuranceType } from 'pages/scannedDocuments/scannedDocuments.utils';
import { useState } from 'react';
import { insuranceIconMapper } from 'shared/insurances/insuranceIconMapper';
import { insuranceNameMapper } from 'shared/insurances/insuranceNameMapper';

import * as styles from './ScannedDocument.ActivateDentalPolicy.styles';

export const ActivateDentalPolicy = ({
  scannedDocument,
  policyToActivate,
}: {
  scannedDocument: ScannedDocument;
  policyToActivate?: BasePolicy;
}) => {
  const { policyNumber } = scannedDocument;

  const [showModal, setShowModal] = useState(false);

  const [activateDentalPolicy, { loading }] = useMutation(
    ACTIVATE_DENTAL_POLICY,
    {
      variables: {
        scannedDocumentId: scannedDocument.id,
        userPolicyId: policyToActivate?.id,
        policyNumber: scannedDocument.policyNumber,
      },
      refetchQueries: [GET_SCANNED_DOCUMENT_BY_ID, GET_DENTAL_POLICY_BY_NAME],
    }
  );

  const handleConfirm = async () => {
    await activateDentalPolicy();
    setShowModal(false);
  };

  const confirmationRequired =
    policyToActivate && !scannedDocument.userPolicyId;

  return (
    <>
      {showModal && (
        <Modal
          title="Activate dental policy?"
          open={showModal}
          setOpen={setShowModal}
          confirmButtonLabel="Activate"
          handleConfirm={handleConfirm}
        >
          <div className="flex flex-col">
            {confirmationRequired && (
              <FileErrorCard
                open={true}
                className="mb-5 mt-5"
                title="Unconfirmed relation"
                hideCloseButton={true}
                handleClose={() => {}}
                errorType="WARNING"
                description="Accepting this action will also confirm that below policy belongs to this document."
              />
            )}
            <p className={classnames(styles.label, 'mt-5')}>
              Policy to activate
            </p>
            <div className="mt-3 flex items-center">
              <a
                href={
                  getPolicyDetailsBaseURLFromInsuranceType(
                    policyToActivate?.insuranceType,
                    policyToActivate?.id
                  ) ?? ''
                }
                target="_blank"
                rel="noreferrer"
                className={styles.policyCard}
              >
                {policyToActivate?.insuranceType && (
                  <img
                    className={styles.policyIcon}
                    src={insuranceIconMapper[policyToActivate.insuranceType]}
                    alt=""
                  />
                )}
                <p className="flex flex-col">
                  <span className={styles.policyType}>
                    {policyToActivate?.insuranceType &&
                      insuranceNameMapper[policyToActivate?.insuranceType]}
                  </span>
                  <span className={styles.policyNumber}>
                    {`${policyToActivate?.questionnaires?.answers?.personalInfo?.name?.firstName} ${policyToActivate?.questionnaires?.answers?.personalInfo?.name?.lastName}`}
                  </span>
                </p>
              </a>
            </div>
            <p className={classnames(styles.label, 'mb-2 mt-7')}>
              Policy number
            </p>
            <p className={styles.policyNumber}>{policyNumber}</p>
            <p className={classnames(styles.text, 'mt-5 font-bold')}>
              Confirming this action will add above policy number, activate the
              policy and expose the document to the user account.
            </p>
          </div>
        </Modal>
      )}
      <Button
        loading={loading}
        buttonType="primary"
        className="flex gap-2"
        onClick={() => setShowModal(true)}
      >
        <span className="flex gap-2">
          <span>Activate policy</span>
        </span>
      </Button>
    </>
  );
};
