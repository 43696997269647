import { PrivateHealthIssueCategory } from './models';

export const privateHealthIssueCategoryMapper: Record<
  PrivateHealthIssueCategory,
  string
> = {
  UNI_CHECK: 'Unicheck',
  MISSING_INFO: 'Missing info',
  CANCELED: 'Canceled',
  NOT_ELIGIBLE: 'Not eligible',
  OTHER: 'Other',
};
