import {
  expatSpainClaimStatuses,
  expatSpainClaimTypes,
} from 'shared/claims/expatSpain/models';
import { z } from 'zod';

const requiredError = 'This field is required.';

export const ZSubmittableExpatSpainClaimSchema = z.object({
  policyId: z.string().min(1, { message: requiredError }),
  status: z.enum(expatSpainClaimStatuses),
  claimType: z.array(z.enum(expatSpainClaimTypes)),
  amount: z.string().min(1, { message: requiredError }),
  iban: z.string().min(1, { message: requiredError }),
  diagnosis: z.string(),
  uploadDocument: z
    .custom<File>()
    .refine((file) => file instanceof File, 'Please upload a document'),
});
