import { useTranslation } from '@getpopsure/i18n-react';

import * as styles from '../styles';

export const OpenButton = ({ url }: { url: string }) => {
  const { t } = useTranslation();
  return (
    <a
      className={styles.dropdownOption}
      target="_blank"
      href={url}
      rel="noreferrer"
    >
      {t('admin.documents.action.open.label', 'Open image')}
    </a>
  );
};
