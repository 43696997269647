import InformationCard, {
  InformationSection,
} from 'components/InformationCard';
import DateInformation from 'components/InformationCard/DateInformation';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { TkApiRequest } from 'models/tkApiRequest';
import { getInternalPublicHealthStatus } from 'shared/mapInternalPublicHealthStatus';

const OverviewTab = ({ request }: { request: TkApiRequest }) => {
  const details: InformationSection = {
    title: 'Request details',
    rows: [
      {
        id: 'name',
        title: 'Customer name',
        data: request.userPolicy.user
          ? `${request.userPolicy.user.firstName} ${request.userPolicy.user.lastName}`
          : '',
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'policyStatus',
        title: 'Policy status',
        data: request.userPolicy.publicStatus,
        statusMapping: getInternalPublicHealthStatus,
        type: 'STATUS' as const,
        component: StatusInformation,
      },
      {
        id: 'createdAt',
        title: 'Created on',
        data: request.createdAt,
        type: 'DATE',
        component: DateInformation,
      },
      {
        id: 'externalId',
        title: 'External ID',
        data: request.externalId,
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'status',
        title: 'Status',
        data: request.status,
        type: 'STATUS' as const,
        statusMapping: (status: string) => {
          if (status === 'SUCCESS') {
            return {
              color: 'green',
              text: { text: 'Success', id: 'SUCCESS' },
            };
          }
          if (status === 'FAIL') {
            return {
              color: 'red',
              text: { text: 'Fail', id: 'FAIL' },
            };
          }
          if (status === 'PENDING') {
            return {
              color: 'yellow' as const,
              text: { text: 'Pending', id: 'PENDING' },
            };
          }
          return {
            color: 'gray',
            text: { text: status, id: status },
          };
        },
        component: StatusInformation,
      },
      {
        id: 'response',
        title: 'Response',
        data: request.response,
        type: 'TEXT',
        component: TextInformation,
      },
      {
        id: 'payload',
        title: 'Payload',
        data: request.payload.toString(),
        type: 'TEXT',
        component: TextInformation,
      },
    ],
  };

  return (
    <div className="relative flex w-full flex-col">
      <InformationCard sections={[details]} />
    </div>
  );
};

export default OverviewTab;
