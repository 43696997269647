import { gql } from '@apollo/client';

export const CREATE_EXPAT_CLAIM = gql`
  mutation CreateExpatClaim(
    $insuranceType: String!
    $userId: String!
    $policyId: String!
    $policyNumber: String
    $status: String!
    $claimType: [String!]!
    $amount: Float!
    $iban: String!
    $uploadDocument: Upload
  ) {
    createExpatClaim(
      insuranceType: $insuranceType
      userId: $userId
      policyId: $policyId
      policyNumber: $policyNumber
      status: $status
      claimType: $claimType
      amount: $amount
      iban: $iban
      uploadDocument: $uploadDocument
    ) {
      id
      userPolicyId
      status
      user {
        id
        firstName
        lastName
      }
      claimType {
        id
        createdAt
        type
      }
      amount
      iban
    }
  }
`;
