import classNames from 'classnames';

export const feathers = classNames('w-[80px]', 'mb-[32px]');

export const contentWrapper = classNames(
  'absolute',
  'flex',
  'flex-col',
  'justify-center',
  'items-center',
  'mx-auto',
  'right-0',
  'left-0',
  'my-auto',
  'top-0',
  'bottom-0'
);

export const title = classNames(
  'text-2xl',
  'font-medium',
  'text-gray-900',
  'mb-[8px]'
);

export const text = classNames(
  'text-base',
  'font-normal',
  'text-gray-600',
  'text-center'
);

export const button = classNames(
  'mt-[32px]',
  'py-[10px]',
  'flex',
  'justify-center',
  'text-base',
  'text-white',
  'rounded-[6px]',
  'bg-indigo-500',
  'w-[212px]',
  'hover:bg-indigo-600',
  'focus:ring-2',
  'focus:ring-offset-2',
  'focus:bg-indigo-600',
  'focus:ring-indigo-500',
  'outline-none'
);
