import {
  householdClaimStatuses,
  householdClaimTypes,
} from 'shared/claims/household/models';
import { z } from 'zod';

export const zodSubmittableClaimSchema = z.object({
  policyId: z.string().uuid(),
  status: z.enum(householdClaimStatuses),
  claimType: z.array(z.enum(householdClaimTypes)),
  eventDescription: z.string().optional(),
  dateOfEvent: z.string().optional(),
  amount: z.string().optional(),
  iban: z.string().optional(),
  uploadDocument: z.instanceof(File).optional(),
});
