import classNames from 'classnames';

export const sectionTitle = classNames(
  'mb-[5px]',
  'text-sm',
  'text-gray-900',
  'font-bold',
  'flex'
);

export const newStatusSection = classNames('mt-[16px]');

export const reasonSection = classNames('mt-[24px]');

export const text = classNames('text-sm', 'text-gray-600');

export const dropzoneWrapper = classNames('mt-[8px]');

export const grayText = classNames('!text-gray-500', 'ml-[5px]');

export const calendarDatePicker = classNames(
  '!w-[432px]',
  '!bg-gray-200',
  'mt-[8px]'
);

export const description = classNames('text-sm', 'text-gray-600');
