import { useTranslation } from '@getpopsure/i18n-react';

import * as styles from './styles';

export interface EmptyStateMessage {
  title: string | React.ReactElement;
  description: string | React.ReactElement;
}

interface TableEmptyStateProps {
  searchString: string;
  filterOption: boolean;
  defaultMessage: EmptyStateMessage;
}

const TableEmptyState = ({
  searchString,
  filterOption,
  defaultMessage,
}: TableEmptyStateProps) => {
  const { t } = useTranslation();
  const message: EmptyStateMessage = defaultMessage;

  if (searchString !== '') {
    message.title = (
      <p>
        {t('admin.policytable.emptystate.search.title', 'No results found for')}{' '}
        '<b>{searchString}</b>'.
      </p>
    );
    message.description = t(
      'admin.policytable.emptystate.search.subtitle',
      'Please check the spelling or try searching another term.'
    );
  }

  if (filterOption) {
    message.title = t(
      'admin.policytable.emptystate.filter.title',
      'No results found.'
    );
    message.description = t(
      'admin.policytable.emptystate.filter.subtitle',
      'Please choose another filter or try again later.'
    );
  }

  if (filterOption && searchString !== '') {
    message.title = t(
      'admin.policytable.emptystate.searchfilter.title',
      'No results found.'
    );
    message.description = t(
      'admin.policytable.emptystate.searchfilter.subtitle',
      'Please try adjusting your search or choosing another filter.'
    );
  }
  return (
    <div className={styles.tableWrapper}>
      <div className={styles.tableDataPlaceholder}>
        <img
          src="/static/error-states/feathers.svg"
          alt="feathers"
          className={styles.feathers}
        />
        <h1 className={styles.title}>{message.title}</h1>
        <p className={styles.text}>{message.description}</p>
      </div>
    </div>
  );
};

export default TableEmptyState;
