import { mapCompany, ProviderViewCompany } from 'shared/mapCompany';

export const getAuthor = (author?: {
  firstName?: string;
  lastName?: string;
  company: string;
}) => {
  if (!author) {
    return null;
  }
  if (!author.firstName || !author.lastName) {
    return mapCompany(author.company as ProviderViewCompany);
  }
  return `${author.firstName} ${author.lastName}`;
};
