import classNames from 'classnames';

export const radioButtonWrapper = classNames('flex', 'items-center');

export const radioButtonInput = classNames(
  'form-radio',
  'focus:ring-1',
  'focus:ring-indigo-500',
  'h-4',
  'w-4',
  'text-indigo-500',
  'boder-gray-400'
);

export const radioButtonError = classNames('border-2', 'border-red-400');

export const radioButtonLabel = classNames(
  'ml-[8px]',
  'block',
  'text-sm',
  'text-gray-900'
);
