import { ApolloError } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import { TrashIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import OverviewPage from 'components/OverviewPage';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import Table from 'components/TableRefactor/Table';
import { HeaderConfig, RowConfig } from 'components/TableRefactor/types';
import { useState } from 'react';
import { insuranceNameMapper } from 'shared/insurances/insuranceNameMapper';

import CreateReferralCampaignModal from './components/CreateReferralCampaignModal';
import DeleteConfirmationModal from './components/DeleteReferralCampaignModal';
import UpdateReferralCampaignModal from './components/UpdateReferralCampaignModal';
import { GET_REFERRAL_CAMPAIGNS } from './graphql/queries';
import { ReferralCampaign } from './types';

const NUMBER_OF_REFERRAL_CAMPAIGNS_PER_PAGE = 12;

const headers: HeaderConfig[] = [
  {
    id: 'title',
    label: 'Campaign name',
    width: 'w-[250px]',
    minWidth: 'min-w-[300px]',
    sticky: true,
    component: SortableColumnHeader,
  },
  {
    id: 'description',
    label: 'Description',
    width: 'w-[350px]',
    minWidth: 'min-w-[300px]',
    sticky: true,
    component: SortableColumnHeader,
  },
  {
    id: 'amount',
    label: 'Amount',
    width: 'w-[150px]',
    minWidth: 'min-w-[100px]',
    component: SortableColumnHeader,
  },
  {
    id: 'sourceInsuranceType',
    label: 'Source',
    width: 'w-[150px]',
    minWidth: 'min-w-[100px]',
    component: SortableColumnHeader,
  },
  {
    id: 'targetInsuranceType',
    label: 'Target',
    width: 'w-[150px]',
    minWidth: 'min-w-[100px]',
    component: SortableColumnHeader,
  },

  {
    id: 'startDate',
    label: 'Start date',
    width: 'w-[150px]',
    minWidth: 'min-w-[100px]',
    component: SortableColumnHeader,
  },
  {
    id: 'endDate',
    label: 'End date',
    width: 'w-[150px]',
    minWidth: 'min-w-[100px]',
    component: SortableColumnHeader,
  },
  {
    id: 'actions',
    label: '',
    width: 'w-[80px]',
    minWidth: 'min-w-[80px]',
    component: ColumnHeader,
  },
];

const ReferralCampaignsPage = () => {
  const { t } = useTranslation();

  const [selectedCampaign, setSelectedCampaign] = useState<
    ReferralCampaign | undefined
  >(undefined);
  const [updateCampaignModal, setUpdateCampaignModal] = useState(false);
  const [deleteCampaignModal, setDeleteCampaignModal] = useState(false);
  const [error, setError] = useState<ApolloError | undefined>(undefined);

  const tableFilter: FilterConfig[] = [];

  const rowConfig: RowConfig = {
    resourceOnClick: (data) => {
      setSelectedCampaign(data);
      setUpdateCampaignModal(true);
    },
    cells: [
      {
        type: 'TEXT',
        textColor: 'dark',
        props: { getText: (data: ReferralCampaign) => data.title ?? '' },
      },
      {
        type: 'TEXT',
        textColor: 'dark',
        props: { getText: (data: ReferralCampaign) => data.description ?? '' },
      },
      {
        type: 'TEXT',
        textColor: 'dark',
        props: { getText: (data: ReferralCampaign) => data.amount.toString() },
      },
      {
        type: 'TEXT',
        textColor: 'dark',
        props: {
          getText: (data: ReferralCampaign) =>
            insuranceNameMapper[data.sourceInsuranceType],
        },
      },
      {
        type: 'TEXT',
        textColor: 'dark',
        props: {
          getText: (data: ReferralCampaign) =>
            insuranceNameMapper[data.targetInsuranceType],
        },
      },
      {
        type: 'DATE',
        textColor: 'dark',
        props: { getDate: (data: ReferralCampaign) => data.startDate },
      },
      {
        type: 'DATE',
        textColor: 'dark',
        props: { getDate: (data: ReferralCampaign) => data.endDate },
      },
      {
        type: 'ICON_BUTTON',
        textColor: 'dark',
        props: {
          getButtonText: () => 'Delete',
          onClick: (event) => {
            event.stopPropagation();
            setSelectedCampaign(event.data);
            setDeleteCampaignModal(true);
          },
          Icon: () => (
            <TrashIcon className={classNames('h-5 w-5', 'text-indigo-500')} />
          ),
        },
      },
    ],
  };

  return (
    <OverviewPage
      title="Referral campaigns"
      current="admin-referral-campaigns"
      error={error}
    >
      <Table
        title={t('referralCampaigns.table.title', 'Referral campaigns')}
        headers={headers}
        rowConfig={rowConfig}
        filterConfig={tableFilter}
        query={GET_REFERRAL_CAMPAIGNS}
        itemsPerPage={NUMBER_OF_REFERRAL_CAMPAIGNS_PER_PAGE}
        onError={setError}
        filterConfig-={tableFilter}
        searchPlaceholder="Search campaign"
        emptyStateLabel={{
          title: t(
            'referralCampaigns.table.emptyState.title',
            'There are no referral campaigns yet.'
          ),
          description: t(
            'referralCampaigns.table.emptyState.description',
            'Looks like no referral campaigns were created.'
          ),
        }}
        actionButton={<CreateReferralCampaignModal />}
      />
      <UpdateReferralCampaignModal
        open={updateCampaignModal}
        setOpen={setUpdateCampaignModal}
        referralCampaign={selectedCampaign as ReferralCampaign}
      />
      <DeleteConfirmationModal
        open={deleteCampaignModal}
        setOpen={setDeleteCampaignModal}
        referralCampaign={selectedCampaign as ReferralCampaign}
      />
    </OverviewPage>
  );
};

export default ReferralCampaignsPage;
