import classNames from 'classnames';

export const button = classNames(
  'py-[9px]',
  'px-[13px]',
  'rounded-[6px]',
  'text-sm',
  'font-bold',
  'flex',
  'justify-center',
  'group'
);

export const linkButton = classNames('text-xs');

export const disabled = classNames(
  'cursor-default',
  'opacity-50',
  'pointer-events-none'
);

export const primary = classNames(
  'bg-indigo-500',
  'text-white',
  'hover:bg-indigo-600',
  'outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'focus:bg-indigo-600',
  'focus:ring-indigo-500'
);

export const secondary = classNames(
  'bg-indigo-100',
  'text-indigo-500',
  'hover:bg-indigo-200',
  'focus:outline-none',
  'focus:bg-indigo-200',
  'focus:ring-2',
  'focus:ring-offset-2',
  'focus:ring-indigo-500'
);

export const white = classNames(
  'text-gray-600',
  'bg-white',
  'hover:bg-indigo-50',
  'hover:text-indigo-500',
  'focus:text-indigo-500',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'focus:ring-indigo-500'
);

export const transparent = classNames(
  'text-gray-500',
  'bg-transparent',
  'hover:text-indigo-500',
  'focus:text-indigo-500',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'focus:ring-indigo-500'
);

export const confirmButtonContent = classNames(
  'flex',
  'justify-center',
  'items-center'
);

export const loader = classNames(
  'animate-spin',
  'w-[20px]',
  'h-[20px]',
  'mr-[5px]'
);

export const icon = classNames('h-[20px]', 'w-[20px]', 'mr-[8px]');
export const linkButtonIcon = classNames('h-[16px]', 'w-[16px]', 'mr-[8px]');
export const iconPrimary = classNames('text-white');
export const iconSecondary = classNames(
  'text-indigo-300',
  'group-hover:text-indigo-500',
  'group-focus:text-indigo-500'
);
export const iconWhite = classNames(
  'text-gray-500',
  'group-hover:text-indigo-300',
  'group-focus:text-indigo-300'
);
