import { gql } from '@apollo/client';

export const GET_DOG_LIABILITY_CLAIM = gql`
  query GetDogLiabilityClaim($insuranceType: String!, $claimId: String!) {
    dogLiabilityClaim(insuranceType: $insuranceType, claimId: $claimId) {
      id

      user {
        id
        firstName
        lastName
        email
        isDelinquent
        isFraudulent
        fraudulentAdditionalInfo
      }

      intercomEuUserId
      stripeCustomerToken
      status
      createdAt
      amount
      iban
      refundAmount
      customerPaidOn
      claimNumber
      eventDescription
      thirdPartyPaidOn
      provision
      dateOfEvent

      claimType {
        id
        type
      }

      thirdParty

      policy {
        id
        publicStatus
        startDate
        policyNumber
        activeUntil
        price
        dogName
      }

      documents {
        userUploads {
          id
          url
          blobName
          resizedUrl
        }
        scannedDocuments {
          id
          url
          blobName
        }
        featherUploads {
          id
          url
          blobName
          resizedUrl
          isVisibleToCustomer
          internalNotes
          friendlyName
          category
        }
        providerUploads {
          id
          url
          blobName
          resizedUrl
          isVisibleToCustomer
          internalNotes
          friendlyName
          category
        }
        archivedDocuments {
          id
          url
          blobName
          resizedUrl
          isVisibleToCustomer
          archivedAt
          internalNotes
          friendlyName
          category
        }
      }

      issues {
        id
        category
        description
        status
        title
        createdAt
        updatedAt
        author {
          firstName
          lastName
        }

        tasks {
          id
          description
          status
          data
          createdAt
          finishedAt
        }
      }

      pinnedNotes {
        id
        body
        createdAt
        adminUser {
          firstName
          lastName
        }
        updatedAt
      }
    }
  }
`;
