import { useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import ActivityCircle from 'components/ActivityCircle';
import Badge from 'components/Badge';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { DocumentNode } from 'graphql';
import { DATE_FORMAT_TIME } from 'models/date';
import { PolicyStatus } from 'models/publicHealthStatus';
import { Status } from 'models/statusColor';
import { getAuthor } from 'shared/getAuthor';

import { Activity } from '../ActivityCards';
import * as styles from './styles';

dayjs.extend(relativeTime);

export interface PolicyStatusChangeActivityCardProps {
  activityCard: Activity;
  smallVersion: boolean;
  hasActivityLine: boolean;
  policyId?: string;
  statusMapping?: (status: string) => Status;
  showCustomerInformed: boolean;
  providerView?: boolean;
  issueStatusChangeMutation?: DocumentNode;
  policyQuery?: DocumentNode;
  uploadFileMutation?: DocumentNode;
  issueCategory?: string;
  archivedAt?: string;
}

const PolicyStatusChangeActivityCard = ({
  activityCard,
  smallVersion,
  hasActivityLine,
  statusMapping,
}: PolicyStatusChangeActivityCardProps) => {
  const { t } = useTranslation();
  const newStatus = statusMapping?.(
    activityCard.newPolicyStatus as PolicyStatus
  );
  const activityAuthor = getAuthor(activityCard.author);
  return (
    <div className={styles.cardWrapper}>
      <ActivityCircle
        activityType={activityCard.type}
        smallVersion={smallVersion}
        hasActivityLine={hasActivityLine}
      />
      <div className={smallVersion ? styles.cardSmall : styles.cardFull}>
        <p className={styles.lightText}>
          {activityAuthor ? (
            <>
              <p
                className={classNames(
                  styles.darkText,
                  styles.inlineText,
                  'mr-1'
                )}
              >
                {activityAuthor}
              </p>
              {t(
                'admin.provider.policy.public.activity.changedstatus.subtitle',
                'changed status to'
              )}
            </>
          ) : (
            t(
              'admin.provider.policy.public.activity.system.changedstatus.subtitle',
              'Status automatically changed to'
            )
          )}
          <Badge
            color={newStatus?.color ?? 'gray'}
            badgeType="full"
            className={classNames(styles.inlineText, 'mx-1')}
          >
            {newStatus?.text.text}
          </Badge>
          <p className={classNames(styles.veryLightText, styles.inlineText)}>
            {dayjs(activityCard.createdAt).format(DATE_FORMAT_TIME)}
          </p>
        </p>
      </div>
    </div>
  );
};

export default PolicyStatusChangeActivityCard;
