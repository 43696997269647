import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import {
  LegalClaimStatus,
  legalClaimStatuses,
  LegalClaimType,
  legalClaimTypes,
} from 'shared/claims/legal/models';
import { policyMappers } from 'shared/insurances';

const statusOptions: SelectButtonOption<LegalClaimStatus>[] =
  legalClaimStatuses.map((status) => ({
    id: status,
    label: policyMappers.LEGAL?.claim?.claimStatusMapper[status] ?? '',
    title: policyMappers.LEGAL?.claim?.claimStatusMapper[status] ?? '',
  }));

const claimTypeOptions: SelectButtonOption<LegalClaimType>[] =
  legalClaimTypes.map((type) => ({
    id: type,
    label: policyMappers.LEGAL?.claim?.typeNameMapper[type] ?? '',
    title: policyMappers.LEGAL?.claim?.typeNameMapper[type] ?? '',
  }));

export const filters: FilterConfig[] = [
  {
    options: statusOptions,
    filterType: 'MULTIPLE',
    label: 'Status',
    id: 'filterStatus',
    placeholder: 'Status',
  },
  {
    options: claimTypeOptions,
    filterType: 'MULTIPLE',
    label: 'Claim type',
    id: 'filterClaimType',
    placeholder: 'Claim type',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Created on',
    id: 'createdAt',
  },
];
