import classNames from 'classnames';

export const searchButton = classNames(
  'absolute',
  'inset-y-0',
  'left-0',
  'pl-3',
  'flex',
  'items-center',
  'disabled:cursor-default',
  'disabled:hover:text-gray-400',
  'text-gray-400',
  'hover:text-indigo-500'
);

export const searchIcon = classNames('h-[20px]', 'w-[20px]');

export const inputWrapper = classNames('relative');

export const input = classNames(
  'block',
  'w-full',
  'outline-none',
  'px-[14px]',
  'py-[10px]',
  'rounded-[6px]',
  'placeholder-gray-500',
  'text-sm',
  'text-gray-900',
  'truncate'
);

export const searchInput = classNames('pl-10');

export const inputWhite = classNames('bg-white');
export const inputGray = classNames('bg-gray-200');

export const inputFocus = classNames('focus:ring-indigo-500', 'focus:ring-1');

export const inputError = classNames(
  'border',
  'border-red-500',
  'pr-[40px]',
  'focus:ring-red-500',
  'focus:ring-2'
);

export const inputWithPrefix = classNames('pl-[28px]');

export const errorIconWrapper = classNames(
  'absolute',
  'inset-y-0',
  'right-0',
  'pr-3',
  'flex',
  'items-center',
  'pointer-events-none'
);

export const hidden = classNames('hidden');

export const errorIcon = classNames('h-5', 'w-5', 'text-red-400');

export const inputDisableWhite = classNames('!bg-indigo-50');

export const inputDisableGray = classNames('!bg-gray-300');

export const clearIconWrapper = classNames(
  'absolute',
  'inset-y-0',
  'right-0',
  'pr-3',
  'flex',
  'items-center',
  'hover:text-gray-600',
  'text-gray-400',
  'focus:text-gray-600'
);

export const clearIcon = classNames('h-5', 'w-5');

export const prefixContainer = classNames(
  'pointer-events-none',
  'absolute',
  'inset-y-0',
  'left-0',
  'flex',
  'items-center',
  'pl-3'
);

export const spanText = classNames('text-gray-500', 'text-sm');

export const suffixContainer = classNames(
  'absolute',
  'inset-y-0',
  'right-0',
  'flex',
  'items-center',
  'p-[12px]'
);
