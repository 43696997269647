import { insuranceTypes } from 'shared/insurances/types';
import { z } from 'zod';

export const InsuranceTypeSchema = z.enum(insuranceTypes);

export const ReferralCampaignSchema = z.object({
  id: z.string().nullish(),
  title: z.string().nullable(),
  description: z.string().nullable(),
  sourceInsuranceType: InsuranceTypeSchema,
  targetInsuranceType: InsuranceTypeSchema,
  amount: z.number(),
  startDate: z.string(),
  endDate: z.string(),
});

export type ReferralCampaign = z.infer<typeof ReferralCampaignSchema>;
