import { getFixedT } from '@getpopsure/i18n-react';

export const getHRStatus = () => {
  const t = getFixedT();
  return [
    {
      title: t('admin.status.received', 'Received'),
      label: t('admin.status.received', 'Received'),
      id: 'received',
    },
    {
      title: t('admin.status.processing', 'Processing'),
      label: t('admin.status.processing', 'Processing'),
      id: 'processing',
    },
    {
      title: t('admin.status.covered', 'Covered'),
      label: t('admin.status.covered', 'Covered'),
      id: 'covered',
    },
    {
      title: t('admin.status.canceled', 'Canceled'),
      label: t('admin.status.canceled', 'Canceled'),
      id: 'canceled',
    },
  ];
};
