import classNames from 'classnames';

export const header = classNames('mb-6', 'mt-4', 'flex', 'justify-between');

export const subtitle = classNames('text-lg', 'font-bold', 'text-gray-800');

export const date = classNames('text-sm', 'font-bold', 'text-gray-800');

export const row = classNames('last:mb-0', 'mb-4', 'grid', 'grid-cols-2');

export const rowFullWidth = classNames('last:mb-0', 'mb-4');

export const rowText = classNames('text-sm', 'text-gray-900');

export const invalidAnswer = classNames('text-sm', 'text-red-500');

export const answersPlaceholder = classNames(
  'flex',
  'min-h-[84px]',
  'items-center',
  'justify-center',
  'bg-gray-50',
  'text-sm',
  'text-gray-600'
);

export const taskDetailsContainer = classNames(
  'border-t',
  'border-solid',
  'border-gray-300',
  'pt-2'
);

export const filePreview = classNames(
  'mb-2',
  'flex',
  'rounded-lg',
  'bg-gray-50',
  'p-3',
  'text-gray-900',
  'last:mb-0',
  'block',
  'truncate'
);

export const fileIcon = classNames(
  'flex',
  'align-center',
  'min-w-[20px]',
  'mr-3',
  'text-gray-500'
);
