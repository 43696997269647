import { getFixedT } from '@getpopsure/i18n-react';
import { BadgeColor } from 'components/Badge/Badge';

export type IssueStatusId =
  | 'unresolved'
  | 'resolved'
  | 'customercontacted'
  | 'other';

export type IssueStatusType = 'OPEN' | 'RESOLVED' | 'CUSTOMER_CONTACTED';
export interface IssueStatus {
  id: IssueStatusId;
  text: string;
}

export const mapIssueStatus = (status: IssueStatusType): IssueStatus => {
  const t = getFixedT();
  switch (status) {
    case 'OPEN':
      return {
        id: 'unresolved',
        text: t('admin.activity.issue.status.unresolved', 'Unresolved'),
      };
    case 'RESOLVED':
      return {
        id: 'resolved',
        text: t('admin.activity.issue.status.resolved', 'Resolved'),
      };
    case 'CUSTOMER_CONTACTED':
      return {
        id: 'customercontacted',
        text: t(
          'admin.activity.issue.status.customercontacted',
          'Customer contacted'
        ),
      };
    default:
      return {
        id: 'other',
        text: t('admin.activity.issue.status.other.label', 'Other'),
      };
  }
};

export const getIssueStatusColor = (status: IssueStatus): BadgeColor => {
  const colors: {
    [K in IssueStatusId]: BadgeColor;
  } = {
    unresolved: 'yellow',
    customercontacted: 'blue',
    resolved: 'green',
    other: 'gray',
  };
  return colors[status.id];
};

export const getIssueStatus = (status: string) => {
  const text = mapIssueStatus(status as IssueStatusType);
  const color = getIssueStatusColor(text);
  return { text, color };
};
