import { BadgeColor } from 'components/Badge/Badge';

export const SD_CATEGORY_SPECIAL_FLAGS = [
  'DENTAL_ACTIVATION',
  'PRIVATE_ACTIVATION',
] as const;
export type SDCategorySpecialFlag = typeof SD_CATEGORY_SPECIAL_FLAGS[number];

export type SDCategory = {
  id: string;
  name: string;
  whitelist: string[];
  blacklist: string[];
  attributes: {
    senderName?: string;
    requiredAction?: {
      caption?: string;
      color?: BadgeColor;
    };
    specialFlags?: SDCategorySpecialFlag[];
    customerIoEvent?: string;
  };
};
