import { ModalFormData } from 'components/ModalForm/models';
import { retrievePolicySuggestion } from 'pages/claims/template/components/CreateClaimModal/utils';
import { DentalPolicy } from 'pages/policies/dental/models/DentalPolicy';
import { claimTypeNameMapper as dentalClaimTypeNameMapper } from 'shared/claims/dental/claimTypeNameMapper';
import {
  DentalClaimStatus,
  dentalClaimStatuses,
  DentalClaimType,
  dentalClaimTypes,
  deprecatedDentalClaimStatuses,
} from 'shared/claims/dental/models';
import { statusNameMapper as dentalStatusNameMapper } from 'shared/claims/dental/statusNameMapper';

import { SubmittableClaim } from '../../../template/components/CreateClaimModal/models';

export const retrieveDentalCreateClaimFormData = (
  policies: DentalPolicy[],
  setPolicySearchQuery: (query: string) => void,
  policiesLoading: boolean
): ModalFormData<
  SubmittableClaim<DentalClaimStatus, DentalClaimType>,
  DentalClaimStatus | DentalClaimType
>[] => [
  {
    id: 'policyId',
    label: 'User policy',
    renderDetails: {
      type: 'autoSuggest',
      options: policies.map(({ id, insuredPerson, policyNumber }) => ({
        id,
        label: retrievePolicySuggestion({
          firstName: insuredPerson?.firstName,
          lastName: insuredPerson?.lastName,
          policyNumber,
        }),
      })),
      placeholder: 'Enter name, email or policy number',
      setExternalQuery: (query: string) => {
        setPolicySearchQuery(query);
      },
      useUnfilteredOptions: true,
    },
    color: 'gray',
    required: true,
    loading: policiesLoading,
  },
  {
    id: 'status',
    label: 'Status',
    renderDetails: {
      type: 'select',
      options: dentalClaimStatuses.flatMap((status) =>
        deprecatedDentalClaimStatuses.includes(status)
          ? []
          : [
              {
                id: status,
                label: dentalStatusNameMapper[status],
              },
            ]
      ),
      placeholder: 'Select a status',
    },
    color: 'gray',
    required: true,
  },
  {
    id: 'claimType',
    label: 'Claim type',
    renderDetails: {
      type: 'multiSelect',
      options: dentalClaimTypes.map((type) => ({
        id: type,
        label: dentalClaimTypeNameMapper[type],
      })),
      placeholder: 'Select type',
    },
    color: 'gray',
    required: true,
  },
  {
    id: 'amount',
    label: 'Claim amount',
    renderDetails: { type: 'currency', placeholder: '0.00' },
    color: 'gray',
  },
  {
    id: 'iban',
    label: 'IBAN',
    renderDetails: { type: 'text', placeholder: 'Enter IBAN' },
    color: 'gray',
  },
  {
    id: 'uploadDocument',
    label: 'Upload a document',
    renderDetails: { type: 'upload' },
    required: true,
  },
];
