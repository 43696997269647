import FileErrorCard from 'components/FileErrorCard';

export const CustomerFraudulentBanner = ({
  customer,
  loading = false,
}: {
  customer?: {
    id: string;
    isFraudulent?: boolean | null;
    fraudulentAdditionalInfo?: string | null;
  };
  loading?: boolean;
}) => {
  if (loading) return null;
  if (!loading && customer?.isFraudulent === false) return null;

  return (
    <FileErrorCard
      open
      hideCloseButton
      handleClose={() => {}}
      title="Customer is fraudulent"
      description={customer?.fraudulentAdditionalInfo ?? ''}
      errorType="ERROR"
      className="!mt-0 mb-[16px]"
    />
  );
};
