import * as Sentry from '@sentry/react';
import PageGenericError from 'pages/errors/genericError';
import { Component, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  eventId?: string;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: object) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo as Record<string, unknown>);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  public render() {
    if (this.state.hasError) {
      return <PageGenericError />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
