import classNames from 'classnames';
import Loader from 'components/Loader';
import React from 'react';

import * as styles from './styles';

export type ButtonType = 'primary' | 'secondary' | 'white' | 'transparent';

interface ButtonProps extends React.ComponentPropsWithRef<'button'> {
  buttonType: ButtonType;
  loading?: boolean;
  loadingText?: string;
  Icon?: React.ComponentType<any>;
}

const Button = React.forwardRef(
  (
    {
      buttonType,
      className,
      loading = false,
      loadingText,
      Icon,
      ...props
    }: ButtonProps,
    ref?: React.ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <button
        className={classNames(className, styles.button, {
          [styles.primary]: buttonType === 'primary',
          [styles.secondary]: buttonType === 'secondary',
          [styles.white]: buttonType === 'white',
          [styles.transparent]: buttonType === 'transparent',
          [styles.disabled]: props.disabled || loading,
        })}
        {...props}
        ref={ref}
        disabled={loading || props.disabled}
      >
        <span className={styles.confirmButtonContent}>
          {loading && (
            <Loader className={styles.loader} primaryColor="stroke-white" />
          )}
          {Icon && (
            <Icon
              className={classNames(className, styles.icon, {
                [styles.iconPrimary]: buttonType === 'primary',
                [styles.iconSecondary]: buttonType === 'secondary',
                [styles.iconWhite]: buttonType === 'white',
              })}
            />
          )}
          {loading ? loadingText ?? props.children : props.children}
        </span>
      </button>
    );
  }
);

export default Button;
