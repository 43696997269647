import { useTranslation } from '@getpopsure/i18n-react';
import { ConfirmationModalProps } from 'components/InformationCard/EditableInformationCard';
import Modal from 'components/Modal';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';

import * as styles from './styles';

const StartDateConfirmationModal = ({
  open,
  setOpen,
  handleConfirm,
  loading,
  newFormData,
}: ConfirmationModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t(
        'admin.provider.policy.public.overview.startdate.confirmation.title',
        'Save changes?'
      )}
      open={open}
      setOpen={setOpen}
      handleConfirm={handleConfirm}
      loading={loading}
    >
      <>
        <div className={styles.wrapper}>
          <span className={styles.title}>
            {t(
              'admin.provider.policy.public.overview.startdate.confirmation.subtitle',
              'New start date'
            )}
          </span>
          <span className={styles.text}>
            {dayjs(newFormData.startDate?.toString()).format(DATE_FORMAT)}
          </span>
        </div>
        <span className={styles.additionalText}>
          {t(
            'admin.provider.policy.public.overview.startdate.confirmation.description',
            'The confirmation of coverage will be automatically regenerated only if the policy status is “Covered”.'
          )}
        </span>
      </>
    </Modal>
  );
};

export default StartDateConfirmationModal;
