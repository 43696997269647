import { BadgeColor } from 'components/Badge/Badge';

import { GenericStatus } from '../types';

export const genericStatusMapper: Record<GenericStatus, string> = {
  PENDING: 'Pending',
  ACTIVE: 'Covered',
  CANCELED: 'Canceled',
  DROPPED_OUT: 'Canceled',
};

export const genericBadgeColorMapper: Record<GenericStatus, BadgeColor> = {
  PENDING: 'purple',
  ACTIVE: 'green',
  CANCELED: 'gray',
  DROPPED_OUT: 'gray',
};
