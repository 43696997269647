import { GET_DENTAL_POLICIES } from 'pages/policies/dental/graphql/policies';
import {
  DentalClaimStatus,
  DentalClaimType,
} from 'shared/claims/dental/models';

import { ClaimsTemplateData } from '../template/models';
import {
  retrieveDentalClaimInfoOldConfirmationValue,
  retrieveDentalClaimInfoRows,
} from './modules/claimsDetails/claimInfo/data';
import ConfirmationModal from './modules/claimsDetails/components/ConfirmationModal';
import {
  SUBMIT_DENTAL_CLAIM,
  UPDATE_DENTAL_CLAIM,
} from './modules/claimsDetails/graphql/mutations';
import { GET_DENTAL_CLAIM } from './modules/claimsDetails/graphql/queries';
import { retrieveDentalPolicyInfoRows } from './modules/claimsDetails/policyInfo/data';
import { retrieveDentalCreateClaimFormData } from './modules/createClaim/data';
import { CREATE_DENTAL_CLAIM } from './modules/createClaim/graphql/mutations';
import { ZSubmittableDentalClaimSchema } from './modules/createClaim/models';
import { filters } from './modules/overview/data/filters';
import { rowConfig } from './modules/overview/data/tableData';
import { tableHeaders } from './modules/overview/data/tableHeaders';
import { GET_DENTAL_CLAIMS } from './modules/overview/graphql/queries';
import {
  dentalClaimStatusOptions,
  retrieveFormAndFooterTextByStatus,
} from './modules/statusUpdate/data';
import { UPDATE_DENTAL_CLAIM_STATUS } from './modules/statusUpdate/graphql/mutations';

export const dentalClaimsData: ClaimsTemplateData<
  DentalClaimStatus,
  DentalClaimType
> = {
  insuranceType: 'DENTAL',

  // GraphQL -----------------------------------------
  queries: {
    fetchAllClaims: GET_DENTAL_CLAIMS,
    fetchSingleClaim: GET_DENTAL_CLAIM,
    fetchAllPolicies: GET_DENTAL_POLICIES,
  },

  mutations: {
    createClaim: CREATE_DENTAL_CLAIM,
    updateClaim: UPDATE_DENTAL_CLAIM,
    updateClaimStatus: UPDATE_DENTAL_CLAIM_STATUS,
    submitClaim: SUBMIT_DENTAL_CLAIM,
  },

  // Features ----------------------------------------
  overview: {
    headers: tableHeaders,
    filterConfig: filters,
    rowConfig,
  },
  claimCreation: {
    createClaimSubmissionSchema: ZSubmittableDentalClaimSchema,
    retrieveFormData: retrieveDentalCreateClaimFormData,
  },
  policyInfo: { retrievePolicyInfoRows: retrieveDentalPolicyInfoRows },
  claimInfo: {
    retrieveClaimInfoRows: retrieveDentalClaimInfoRows,
    retrieveClaimInfoOldConfirmationValue:
      retrieveDentalClaimInfoOldConfirmationValue,
    ClaimInfoConfirmationModal: ConfirmationModal,
  },
  statusUpdate: {
    dataByStatus: retrieveFormAndFooterTextByStatus,
    statusOptions: dentalClaimStatusOptions,
  },
};
