import { TFunction } from '@getpopsure/i18n-react';
import DateInformation from 'components/InformationCard/DateInformation';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { InformationCardDetails } from 'pages/policies/template/models/PolicyTemplateData';

import { CompanyHealthPolicy } from '../models/CompanyHealthPolicy';
import { companyHealthStatusMapper } from '../utils';

export const getOverviewTabCards = (t: TFunction) => {
  return (data?: {
    hrCompanyHealthPolicy: CompanyHealthPolicy;
  }): InformationCardDetails[] => [
    {
      editable: false,
      cards: [
        {
          title: t(
            'admin.hr.companyHealth.overview.employeeInformation.title',
            'Employee information'
          ),
          rows: [
            {
              id: 'firstName',
              title: t(
                'admin.hr.companyHealth.overview.firstName.label',
                'First name'
              ),
              data: data?.hrCompanyHealthPolicy?.user?.firstName ?? '',
              component: TextInformation,
              type: 'TEXT',
            },
            {
              id: 'lastName',
              title: t(
                'admin.hr.companyHealth.overview.lastName.label',
                'Last name'
              ),
              data: data?.hrCompanyHealthPolicy?.user?.lastName ?? '',
              component: TextInformation,
              type: 'TEXT',
            },
            {
              id: 'dateOfBirth',
              title: t(
                'admin.hr.companyHealth.overview.dateOfBirth.label',
                'Date of birth'
              ),
              data: data?.hrCompanyHealthPolicy?.user?.dateOfBirth ?? '',
              component: DateInformation,
              type: 'DATE',
            },
          ],
        },
      ],
    },
    {
      editable: false,
      cards: [
        {
          title: t(
            'admin.hr.companyHealth.overview.policyInformation.title',
            'Policy information'
          ),
          rows: [
            {
              id: 'provider',
              title: t(
                'admin.hr.companyHealth.overview.provider.label',
                'Provider'
              ),
              data: data?.hrCompanyHealthPolicy?.providerId ?? '',
              component: TextInformation,
              type: 'TEXT',
            },
            {
              id: 'status',
              title: t(
                'admin.hr.companyHealth.overview.status.label',
                'Status'
              ),
              data: data?.hrCompanyHealthPolicy?.publicStatus ?? '',
              statusMapping: companyHealthStatusMapper(t),
              component: StatusInformation,
              type: 'STATUS',
            },
            {
              id: 'startDate',
              title: t(
                'admin.hr.companyHealth.overview.startDate.label',
                'Start date'
              ),
              data: data?.hrCompanyHealthPolicy?.startDate ?? '',
              component: DateInformation,
              type: 'DATE',
            },
            {
              id: 'policyNumber',
              title: t(
                'admin.hr.companyHealth.overview.policyNumber.label',
                'Policy number'
              ),
              data: data?.hrCompanyHealthPolicy?.policyNumber ?? '',
              component: TextInformation,
              type: 'TEXT',
            },
          ],
        },
      ],
    },
    {
      editable: false,
      cards: [
        {
          title: t(
            'admin.hr.companyHealth.overview.contributionDetails.title',
            'Contribution details'
          ),
          rows: [
            {
              id: 'contribution',
              title: t(
                'admin.hr.companyHealth.overview.contribution.label',
                'Contribution'
              ),
              data:
                data?.hrCompanyHealthPolicy?.healthDetails
                  ?.employerContribution ?? '',
              component: TextInformation,
              type: 'TEXT',
            },
            {
              id: 'budget',
              title: t(
                'admin.hr.companyHealth.overview.yearlyBudget.label',
                'Yearly budget'
              ),
              data:
                data?.hrCompanyHealthPolicy?.healthDetails?.yearlyBudget ?? '',
              component: TextInformation,
              type: 'TEXT',
            },
            {
              id: 'preventiveHealthcareAddon',
              title: t(
                'admin.hr.companyHealth.overview.preventiveHealthcareAddon.label',
                'Preventive health care addon'
              ),
              data:
                data?.hrCompanyHealthPolicy?.healthDetails
                  ?.preventiveHealthCareAddon ?? '',
              component: TextInformation,
              type: 'TEXT',
            },
            {
              id: 'dentalAddon',
              title: t(
                'admin.hr.companyHealth.overview.dentalAddon.label',
                'Dental addon'
              ),
              data:
                data?.hrCompanyHealthPolicy?.healthDetails?.dentalAddon ?? '',
              component: TextInformation,
              type: 'TEXT',
            },
            {
              id: 'dailySicknessAllowance',
              title: t(
                'admin.hr.companyHealth.overview.dailySicknessAllowance.label',
                'Daily sickness allowance'
              ),
              data:
                data?.hrCompanyHealthPolicy?.healthDetails
                  ?.dailySicknessAllowance ?? '',
              component: TextInformation,
              type: 'TEXT',
            },
            {
              id: 'stationaryAddon',
              title: t(
                'admin.hr.companyHealth.overview.stationaryAddon.label',
                'Stationary addon'
              ),
              data:
                data?.hrCompanyHealthPolicy?.healthDetails?.stationaryAddon ??
                '',
              component: TextInformation,
              type: 'TEXT',
            },
          ],
        },
      ],
    },
  ];
};
