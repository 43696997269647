import classNames from 'classnames';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import useQueryParams from 'hooks/useQueryParams';
import { useHistory } from 'react-router';

import * as styles from './ScannedDocuments.FilterTabs.styles';

const SUBFILTER_SEPARATOR = '__';

export type TabOption = {
  label: string;
  id: string;
  icon?: string;
  subFilterOptions?: TabOption[];
  subFilterPlaceholder?: string;
};

type FilterTabsProps = {
  tabOptions: TabOption[];
  noFilterTabId: string;
  filterName: string;
  filtersToResetOnChange?: string[];
};

const FilterTabs = ({
  tabOptions,
  noFilterTabId,
  filterName,
  filtersToResetOnChange,
}: FilterTabsProps) => {
  const querySearchParam = useQueryParams();
  const history = useHistory();

  const updateQueryParams = ({
    newTabId,
    newSubFilterId,
  }: {
    newTabId: string;
    newSubFilterId?: string;
  }) => {
    if (newTabId === noFilterTabId) {
      querySearchParam.delete(filterName);
    } else {
      const paramValue = [newTabId, newSubFilterId]
        .filter(Boolean)
        .join(SUBFILTER_SEPARATOR);

      querySearchParam.set(filterName, paramValue);

      filtersToResetOnChange?.forEach((filterToReset) =>
        querySearchParam.delete(filterToReset)
      );
    }

    querySearchParam.set('offset', '0');
    history.replace(`?${querySearchParam.toString()}`);
  };

  const filterValueFromQueryParams = querySearchParam.get(filterName);

  const tabIdFromQueryParams =
    filterValueFromQueryParams?.split(SUBFILTER_SEPARATOR)[0];

  const selectedTabId = tabIdFromQueryParams ?? noFilterTabId;

  return (
    <nav className={styles.nav}>
      <div className={styles.tabsInnerContainer} aria-label="Tabs">
        {tabOptions.map(
          ({
            label,
            id: tabId,
            icon,
            subFilterOptions,
            subFilterPlaceholder,
          }) => {
            const tabIsSelected = tabId === selectedTabId;

            return (
              <div
                className={classNames(styles.option, {
                  [styles.withSubOptions]: !!subFilterOptions?.length,
                })}
                key={`${label}${tabId}`}
              >
                <button
                  type="button"
                  key={tabId}
                  className={classNames(styles.tab, {
                    [styles.currentTab]: tabIsSelected,
                    [styles.disabledTab]: !tabIsSelected,
                  })}
                  onClick={() => updateQueryParams({ newTabId: tabId })}
                >
                  {icon && <img src={icon} alt="" className="w-3 rounded-sm" />}
                  <span>{label}</span>
                </button>

                {tabIsSelected &&
                  subFilterOptions &&
                  subFilterOptions.length > 0 && (
                    <div className="w-full">
                      <SelectMenu
                        options={subFilterOptions}
                        selected={subFilterOptions.find(
                          (option) =>
                            option.id ===
                            querySearchParam
                              .get(filterName)
                              ?.split(SUBFILTER_SEPARATOR)[1]
                        )}
                        setSelected={(
                          selectedOption: SelectMenuOption<string>
                        ) => {
                          updateQueryParams({
                            newTabId: tabId,
                            newSubFilterId: selectedOption.id,
                          });
                        }}
                        placeholder={subFilterPlaceholder ?? ''}
                      />
                    </div>
                  )}
              </div>
            );
          }
        )}
      </div>
    </nav>
  );
};

export default FilterTabs;
