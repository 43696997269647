import { TaskAction } from '../TaskEngine.types';

export const getSystemTaskTitle = (type: string) => {
  const systemTasksTitleMapping: Record<string, string> = {
    PRIVATE_HEALTH_RELOCATION_DATE_UPDATE:
      'Provide your date of entry to Germany',
    BIKE_FRAME_NUMBER_UPDATE: 'Provide your frame number',
    CLAIM_MISSING_INFO_REDIRECT: 'We need extra details for your claim',
    LIABILITY_ADDRESS_UPDATE: 'Update your address',
    PRIVATE_HEALTH_TAX_ID_UPDATE: 'Add your tax ID',
    PRIVATE_HEALTH_ADDRESS_UPDATE: 'Provide your address',
    PRIVATE_HEALTH_PASSPORT_INFO_UPDATE: 'Provide your passport information',
    PRIVATE_HEALTH_IBAN_UPDATE: 'Set up direct debit',
    PRIVATE_HEALTH_WORK_CONTRACT_UPLOAD: 'Upload your work contract',
  };

  return systemTasksTitleMapping[type] || type;
};

export const getSystemTaskAction = (type: string) => {
  const systemTasksActionMapping: Record<string, Array<TaskAction>> = {
    PRIVATE_HEALTH_TAX_ID_UPDATE: [
      {
        type: 'INPUT',
        id: 'germanTaxId',
        attributes: {
          name: 'tax-id',
          placeholder: 'Tax ID',
        },
      },
    ],
    PRIVATE_HEALTH_RELOCATION_DATE_UPDATE: [
      {
        type: 'DATE',
        id: 'relocationDate',
        attributes: {
          name: 'relocation-date',
        },
      },
    ],
    BIKE_FRAME_NUMBER_UPDATE: [
      {
        type: 'INPUT',
        id: 'frameNumber',
        attributes: {
          name: 'frame-number',
          placeholder: 'Frame number',
        },
      },
    ],
    PRIVATE_HEALTH_ADDRESS_UPDATE: [
      {
        type: 'ADDRESS',
        id: 'address',
      },
    ],
    PRIVATE_HEALTH_PASSPORT_INFO_UPDATE: [
      {
        type: 'INPUT',
        id: 'passportNumber',
        attributes: {
          name: 'passport-number',
          placeholder: 'Passport number',
        },
      },
      {
        type: 'DATE',
        id: 'passportExpiry',
        attributes: {
          name: 'passport-expiry',
        },
      },
    ],
    LIABILITY_ADDRESS_UPDATE: [
      {
        id: 'address',
        type: 'ADDRESS',
      },
    ],
    CLAIM_MISSING_INFO_REDIRECT: [],
  };

  return systemTasksActionMapping[type];
};
