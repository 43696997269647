import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import { dogLiabilityStatusMapper } from 'shared/insurances/statusMappers/dogLiability';
import { DogLiabilityStatus } from 'shared/insurances/types';

const statusFilterOptions: SelectButtonOption<DogLiabilityStatus>[] = [
  {
    id: 'PENDING',
    label: dogLiabilityStatusMapper.PENDING,
    title: dogLiabilityStatusMapper.PENDING,
  },
  {
    id: 'ACTIVE',
    label: dogLiabilityStatusMapper.ACTIVE,
    title: dogLiabilityStatusMapper.ACTIVE,
  },
  {
    id: 'CANCELED',
    label: dogLiabilityStatusMapper.CANCELED,
    title: dogLiabilityStatusMapper.CANCELED,
  },
];

export const tableFilters: FilterConfig[] = [
  {
    options: statusFilterOptions,
    filterType: 'MULTIPLE',
    label: 'Status',
    id: 'filterStatus',
    placeholder: 'Select status(es)',
  },
  /**
   * TODO: [KONG] Remove placeholder after Filter component refactor
   * This is a temporary solution to table component's filters rendering certain index positions
   */
  {
    filterType: 'DATE_RANGE',
    label: 'placeholder',
    id: 'placeholder',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Start date',
    id: 'filterStartDate',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Created on',
    id: 'filterCreatedAt',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Archived on',
    id: 'filterArchivedAt',
  },
];
