import { DocumentNode, useQuery } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import ChangeStatus from 'components/ChangeStatus/ChangeStatus';
import { DetailsPage } from 'components/DetailsPage';
import {
  ExternalLink,
  HeaderInfoText,
  InternalLink,
  TabComponent,
} from 'components/DetailsPage/models';
import { DocumentSection } from 'components/DocumentsSection/DocumentsSection';
import { TabInformation } from 'components/Tabs';
import { countryIconMapper } from 'shared/countries/models';

import HROverviewTab from '../../HROverviewTab';
import {
  HRTemplateInsuranceType,
  TemplateInsuranceType,
} from '../../models/InsuranceTypes';
import { PolicyTemplateData } from '../../models/PolicyTemplateData';
import { getHRPolicyData } from '../../utils';
import HRSideSection from '../HRSideSection';

const PolicyDetailsHRTemplate = ({
  templateData,
  insuranceType,
  policyId,
}: {
  templateData: PolicyTemplateData<any, any> & {
    queries: { policy: DocumentNode };
  };
  insuranceType: TemplateInsuranceType | HRTemplateInsuranceType;
  policyId: string;
}) => {
  const { navigation, features, copy, queries, mutations } = templateData;

  const { t } = useTranslation();

  const {
    loading: policyFetchLoading,
    error: policyFetchError,
    data: policyData,
  } = useQuery(queries.policy, {
    variables: {
      id: policyId,
      ...features.details?.queryVariables,
    },
  });

  const policy = getHRPolicyData(insuranceType, policyData);

  const internalLinks: InternalLink[] = [
    ...(features.details?.internalLinks ?? []),
  ];

  const externalLinks: ExternalLink[] = [
    ...(features.details?.externalLinks ?? []),
  ];

  const tabs: TabInformation[] = [
    {
      name: t('admin.details.tab.overview.label', 'Overview'),
      href: '#overview',
      withDot: false,
    },
    ...(features.details?.additionalTabs ?? []),
  ];

  const sideSectionComponent = (
    <HRSideSection
      id={policy?.id ?? ''}
      documentsData={
        features.documents && queries.policy
          ? {
              sections: features.documents.getDocumentSections(
                policyData
              ) as DocumentSection[],
              options: features.documents.uploadOptions,
              uploadMutation: mutations.uploadFile,
              getPolicyQuery: queries.policy,
              additionalQueries: features.documents.additionalQueryVariables,
              deleteFileMutation: mutations.deleteFile,
            }
          : undefined
      }
    />
  );

  const tabComponents: TabComponent[] = [
    {
      id: 'overview',
      component: HROverviewTab,
      props: {
        cards: features.details?.overviewTab.getCards(policyData),
        sideSection: sideSectionComponent,
        policyData: policy,
      },
    },
    ...(features.details?.additionalTabComponents ?? []),
  ];

  const policyRegion = policy?.regionOfPurchase;

  const currentStatusOption =
    features.statusChange?.statusOptions.find(
      (statusOption) => statusOption.id === policy?.publicStatus
    ) ?? features.statusChange?.statusOptions[0];

  const loading = policyFetchLoading;
  const error = policyFetchError;

  const infoTexts: HeaderInfoText[] = [
    ...(features.details?.infoTexts ? features.details.infoTexts : []),
    ...(policyRegion
      ? [
          {
            title: policyRegion.toUpperCase(),
            icon: (
              <img
                className="rounded-sm"
                src={countryIconMapper[policyRegion]}
                alt={policyRegion}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <DetailsPage
      windowTitle={copy.details.pageTitle}
      pageTitle={copy.details.title}
      pageSubtitle={features.details?.insuredPersonName(policyData)}
      backLink={{
        text: t('admin.details.back.link.label', 'Back'),
      }}
      loading={loading}
      tabs={tabs}
      tabComponents={tabComponents}
      current={navigation.current}
      internalLinks={internalLinks}
      externalLinks={externalLinks}
      headerInfoTexts={infoTexts}
      hasLanguageSelector={features.languageSelector}
      error={error}
      headerActions={
        features.statusChange &&
        mutations.statusChange &&
        currentStatusOption && (
          <div className="flex items-center justify-between">
            <ChangeStatus
              status={currentStatusOption}
              policyId={policyId}
              statusOptions={features.statusChange.statusOptions}
              modals={features.statusChange.getModals(policyData)}
              mutation={mutations.statusChange}
              mutationOperationVariables={{
                refetchQueries: [queries.policy],
                awaitRefetchQueries: true,
                errorPolicy: 'none',
              }}
              statusChangeOptions={features.statusChange.statusChangeOptions}
              mutationVariables={features.statusChange.additionalQueryVariables}
              retrieveModalId={features.statusChange.retrieveModalId}
              extendedValidatorObject={
                features.statusChange.extendedValidatorObject
              }
            />
          </div>
        )
      }
    />
  );
};

export default PolicyDetailsHRTemplate;
