import Modal from 'components/Modal';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';
import { PaymentMethod } from 'models/PaymentMethod';
import { paymentBrandNameMapper } from 'shared/paymentMethods/paymentBrandNameMapper';

import * as styles from './styles';

interface Props {
  paymentMethod: PaymentMethod | null;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const PaymentMethodModal = ({
  paymentMethod,
  setIsOpen,
  isOpen,
}: Props) => {
  const isSelectedMethodCard = paymentMethod?.type === 'CARD';
  const isSelectedMethodSepa = paymentMethod?.type === 'SEPA';
  const isSelectedMethodPaypal = paymentMethod?.type === 'PAYPAL';

  return (
    <Modal
      title="Payment method"
      open={isOpen}
      setOpen={() => setIsOpen(!isOpen)}
      handleConfirm={() => {}}
      hideActions
    >
      <>
        {isSelectedMethodCard && (
          <>
            <div className={styles.row}>
              <div className={styles.infoTitle}>Payment type</div>
              <div className={styles.infoContent}>Credit card</div>
            </div>
            <div className={styles.row}>
              <div className={styles.infoTitle}>Card type</div>
              <div className={styles.infoContent}>
                {paymentMethod.details?.brand
                  ? paymentBrandNameMapper[paymentMethod.details.brand]
                  : ''}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.infoTitle}>Card number</div>
              <div className={styles.infoContent}>
                {paymentMethod.details?.last4
                  ? `•••• ${paymentMethod.details.last4}`
                  : ''}
              </div>
            </div>
          </>
        )}
        {isSelectedMethodSepa && (
          <>
            <div className={styles.row}>
              <div className={styles.infoTitle}>Payment type</div>
              <div className={styles.infoContent}>SEPA debit</div>
            </div>
            <div className={styles.row}>
              <div className={styles.infoTitle}>IBAN</div>
              <div className={styles.infoContent}>
                {paymentMethod?.iban ?? ''}
              </div>
            </div>
          </>
        )}
        {isSelectedMethodPaypal && (
          <>
            <div className={styles.row}>
              <div className={styles.infoTitle}>Payment type</div>
              <div className={styles.infoContent}>PayPal</div>
            </div>
            <div className={styles.row}>
              <div className={styles.infoTitle}>Email</div>
              <div className={styles.infoContent}>
                {paymentMethod?.details?.payerEmail ?? ''}
              </div>
            </div>
          </>
        )}
        <div className={styles.row}>
          <div className={styles.infoTitle}>Default payment</div>
          <div className={styles.infoContent}>
            {paymentMethod?.isDefault ? 'Yes' : 'No'}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.infoTitle}>Created at</div>
          <div className={styles.infoContent}>
            {paymentMethod?.createdAt
              ? dayjs(paymentMethod?.createdAt).format(DATE_FORMAT)
              : ''}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.infoTitle}>Updated at</div>
          <div className={styles.infoContent}>
            {paymentMethod?.updatedAt
              ? dayjs(paymentMethod?.updatedAt).format(DATE_FORMAT)
              : ''}
          </div>
        </div>
      </>
    </Modal>
  );
};
