import { useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import FileDropzone from 'components/FileDropzone';
import Input from 'components/Input';
import Modal from 'components/Modal';
import RadioButton from 'components/RadioButton';
import { ChangeEvent, useState } from 'react';

import * as styles from './styles';

interface ResolveIssueFormProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  updateStatus: (
    variables: {
      newStatus: string;
      newFilename?: string;
      type?: string;
      file?: File;
    },
    formId: string
  ) => void;
  loading: boolean;
}

const ResolveIssueForm = ({
  open,
  setOpen,
  updateStatus,
  loading,
}: ResolveIssueFormProps) => {
  const { t } = useTranslation();

  const [uploadDocument, setUploadDocument] = useState<boolean | undefined>(
    false
  );
  const [documentName, setDocumentName] = useState('');
  const [fileSelected, setFileSelected] = useState<File | undefined>(undefined);

  const handleUploadDocumentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUploadDocument(e.target.value === 'yes');
  };

  const handleChangeDocumentName = (e: ChangeEvent<HTMLInputElement>) => {
    setDocumentName(e.target.value);
  };

  const onSubmit = () => {
    updateStatus(
      {
        newStatus: 'resolved',
        newFilename: documentName,
        file: fileSelected,
      },
      'resolveForm'
    );
  };
  return (
    <Modal
      title={t('admin.activity.resolveissue.modal.header', 'Resolve issue')}
      open={open}
      setOpen={setOpen}
      handleConfirm={onSubmit}
      confirmButtonLabel={t(
        'admin.activity.resolveissue.modal.resolve.label',
        'Resolve issue'
      )}
      loading={loading}
      textLoading={t(
        'admin.activity.resolveissue.modal.loading.action',
        'Resolving issue...'
      )}
    >
      <div
        className={classNames(styles.formWrapper, {
          'max-h-[70px]': !uploadDocument,
          'max-h-[350px]': uploadDocument,
        })}
      >
        <div className={styles.section}>
          <h1 className={styles.sectionTitle}>
            {t(
              'admin.activity.resolveissue.modal.uploaddoc.header',
              'Do you want to upload a document?'
            )}
          </h1>
          <div className={styles.radioButtons}>
            <RadioButton
              id="yes"
              name="uploadDocument"
              value="yes"
              checked={!!uploadDocument}
              onChange={handleUploadDocumentChange}
            >
              {t('admin.activity.resolveissue.modal.uploaddoc.yes', 'Yes')}
            </RadioButton>
            <RadioButton
              id="no"
              name="uploadDocument"
              value="no"
              checked={!uploadDocument && uploadDocument !== undefined}
              onChange={handleUploadDocumentChange}
            >
              {t('admin.activity.resolveissue.modal.uploaddoc.no', 'No')}
            </RadioButton>
          </div>
        </div>

        <>
          <div className={styles.section}>
            <label htmlFor="documentName" className={styles.label}>
              {t(
                'admin.activity.resolveissue.modal.uploaddoc.name.header',
                'Document name'
              )}
            </label>
            <Input
              id="documentName"
              type="text"
              required={true}
              placeholder={t(
                'admin.activity.resolveissue.modal.uploaddoc.name.placeholder',
                'Your document name'
              )}
              onChange={handleChangeDocumentName}
              className={styles.input}
              color="gray"
            />
          </div>
          <div className={styles.section}>
            <FileDropzone
              uploadedFile={fileSelected}
              onFileSelect={setFileSelected}
            />
          </div>
        </>
      </div>
    </Modal>
  );
};

export default ResolveIssueForm;
