import { TFunction } from '@getpopsure/i18n-react';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import { HeaderConfig } from 'components/TableRefactor/types';

export const getTableHeaders = (t: TFunction): HeaderConfig[] => [
  {
    id: 'first-name',
    label: t(
      'admin.hr.companyHealth.table.header.firstName.label',
      'First name'
    ),
    component: ColumnHeader,
  },
  {
    id: 'last-name',
    label: t('admin.hr.companyHealth.table.header.lastName.label', 'Last name'),
    component: ColumnHeader,
  },
  {
    id: 'status',
    label: t('admin.hr.companyHealth.table.header.status.label', 'Status'),
    component: ColumnHeader,
  },
  {
    id: 'yearly-budget',
    label: t(
      'admin.hr.companyHealth.table.header.yearlyBudget.label',
      'Yearly budget'
    ),
    component: ColumnHeader,
  },
  {
    id: 'contribution',
    label: t(
      'admin.hr.companyHealth.table.header.contribution.label',
      'Contribution'
    ),
    component: ColumnHeader,
  },
  {
    id: 'start-date',
    label: t(
      'admin.hr.companyHealth.table.header.startDate.label',
      'Start date'
    ),
    component: ColumnHeader,
  },
  {
    id: 'insurance-number',
    label: t(
      'admin.hr.companyHealth.table.header.insuranceNumber.label',
      'Insurance number'
    ),
    component: ColumnHeader,
  },
];
