import {
  DocumentNode,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import Button from 'components/Button';
import ComboBox from 'components/ComboBox';
import { ComboBoxOption } from 'components/ComboBox/ComboBox';
import { User } from 'models/User';
import { GET_CUSTOMERS } from 'pages/customers/graphql/queries';
import { useState } from 'react';
import { useParams } from 'react-router';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import { UPDATE_USER_IN_POLICY } from '../mutations';

const ChangeAccount = ({
  modalOpen,
  setOpen,
  policyDetailsMutation,
}: {
  modalOpen: boolean;
  setOpen: (open: boolean) => void;
  policyDetailsMutation: DocumentNode;
}) => {
  const { id } = useParams<{
    id: string;
  }>();
  const [customersQuery, setCustomersQuery] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState<
    ComboBoxOption | undefined
  >();
  const [customerError, setCustomerError] = useState('');

  const {
    data: users,
    loading: loadingUsers,
    error: errorUsers,
  } = useQuery<{
    customers: User[];
  }>(GET_CUSTOMERS, {
    variables: {
      limit: 25,
      offset: 0,
      searchString: customersQuery,
      sortColumn: '',
      sortOrder: '',
    },
    notifyOnNetworkStatusChange: false,
  });

  const alertBannersState = useReactiveVar(alertBanners);

  const [updateUser] = useMutation(UPDATE_USER_IN_POLICY, {
    refetchQueries: [policyDetailsMutation],
    onCompleted: () => {
      setOpen?.(false);

      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message: 'Account successfully updated.',
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
    onError: () => {
      setOpen?.(false);

      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: 'Something went wrong. Please try again.',
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
  });

  let customerOptions: ComboBoxOption[] = [];
  if (!loadingUsers && !errorUsers && users) {
    customerOptions = users.customers.map((user) => {
      return {
        id: user.id,
        label: user.email,
      };
    });
  }

  const handleConfirm = () => {
    if (!selectedCustomer) {
      setCustomerError('This field is missing. Please select a customer.');
      return;
    }

    updateUser({
      variables: {
        policyId: id,
        userId: selectedCustomer.id,
      },
    });
  };

  return (
    <div>
      <p className="mb-4 text-sm text-gray-600">
        Select the customer account that you want to move this policy to.
      </p>
      <ComboBox
        options={customerOptions}
        useExternalQuery={true}
        setExternalQuery={setCustomersQuery}
        useUnfilteredOptions
        selectedOption={selectedCustomer}
        setSelectedOption={(option: ComboBoxOption) => {
          setSelectedCustomer(option);
          setCustomerError('');
        }}
        placeholder="Enter email of Feather account holder"
        label="Customer"
        color="gray"
        error={customerError}
      />
      <p className="mt-6 text-sm text-gray-600">
        Upon confirmation, the policy will be moved to the selected account. If
        the other account has no remaining policy, please consider requesting
        its deletion.
      </p>
      <div className="mt-4 flex justify-end space-x-2">
        <Button
          buttonType="secondary"
          onClick={() => {
            setCustomersQuery('');
            setOpen?.(false);
          }}
        >
          Cancel
        </Button>
        <Button
          buttonType="primary"
          className="w-[140px]"
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default ChangeAccount;
