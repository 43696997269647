interface TableCellButtonIconProps {
  data: unknown;
  getButtonText: (data: any) => string;
  onClick?: (metaData: Record<string, any>) => void;
  Icon: React.ComponentType<any>;
}
export const TableCellIconButton = ({
  onClick,
  Icon,
  data,
}: TableCellButtonIconProps) => {
  const handleClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    onClick?.({ data, stopPropagation: () => e.stopPropagation() });
  };

  return (
    <button className="w-full text-left" type="button" onClick={handleClick}>
      <Icon />
    </button>
  );
};
