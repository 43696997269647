import Input, { InputProps } from '../Input';

type NumberInputProps = InputProps;

export const NumberInput = ({ value, ...props }: NumberInputProps) => (
  <Input
    {...props}
    type="number"
    value={
      value !== null && value !== undefined && value !== '' ? Number(value) : ''
    }
  />
);
