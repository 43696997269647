import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import Button from 'components/Button';
import FileErrorCard from 'components/FileErrorCard';
import Link from 'components/Link';
import { ModalForm } from 'components/ModalForm';
import { PolicyCard } from 'components/PolicyCard';
import { Status } from 'models/statusColor';
import { SubmittableClaim } from 'pages/claims/template/components/CreateClaimModal/models';
import { ClaimsTemplateData } from 'pages/claims/template/models';
import {
  UPDATE_SCANNED_DOCUMENT,
  UPDATE_SCANNED_DOCUMENTS_USER_CLAIMS_RELATION,
} from 'pages/scannedDocuments/scannedDocuments.mutations';
import { GET_SCANNED_DOCUMENT_BY_ID } from 'pages/scannedDocuments/scannedDocuments.queries';
import { ScannedDocument } from 'pages/scannedDocuments/scannedDocuments.types';
import { getPolicyDetailsBaseURLFromInsuranceType } from 'pages/scannedDocuments/scannedDocuments.utils';
import { useState } from 'react';
import { insuranceIconMapper } from 'shared/insurances/insuranceIconMapper';
import { insuranceNameMapper } from 'shared/insurances/insuranceNameMapper';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { Type } from 'typescript';
import { v4 as uuidv4 } from 'uuid';

import * as styles from './ScannedDocument.CreateAndConnect.styles';

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
export const _DUMMY_CREATE_CLAIM = gql`
  query {
    dummyQueryDoNotUse
  }
`;

export const CreateAndConnect = ({
  scannedDocument,
  claimsTemplateData,
}: {
  scannedDocument: ScannedDocument;
  claimsTemplateData: ClaimsTemplateData<any, any>;
}) => {
  const matchingPolicy =
    scannedDocument.userPolicy ?? scannedDocument.relatedUserPolicy;

  const insuranceType = matchingPolicy?.insuranceType;

  const [showModal, setShowModal] = useState(false);

  const createClaimMutation = claimsTemplateData?.mutations.createClaim;
  const createClaimData = claimsTemplateData?.claimCreation;
  const createClaimSubmissionSchema =
    claimsTemplateData?.claimCreation?.createClaimSubmissionSchema;

  const retrieveFormData = claimsTemplateData?.claimCreation?.retrieveFormData;

  const formData = retrieveFormData?.([], () => {}, false) ?? [];

  // policyId and uploadDocument are not needed as we already know the id and we don't actually upload a document but attach the scanned document instead
  const formDataWithoutIdAndUpload = formData?.filter(
    ({ id }) => id !== 'policyId' && id !== 'uploadDocument'
  );
  const submitValidationSchema = createClaimSubmissionSchema?.omit({
    policyId: true,
    uploadDocument: true,
  });

  const alertBannersState = useReactiveVar(alertBanners);

  const onSubmitSuccess = () => {
    setShowModal(false);

    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'SUCCESS',
      message: 'New claim was successfully created.',
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };

  const onSubmitError = () => {
    setShowModal(false);

    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'WARNING',
      message: 'Something went wrong. Please try again.',
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };

  const [createClaim, { loading }] = useMutation(
    createClaimMutation ?? _DUMMY_CREATE_CLAIM,
    {
      refetchQueries: [],
      notifyOnNetworkStatusChange: true,
      onCompleted: onSubmitSuccess,
      onError: onSubmitError,
    }
  );

  const [changeRelatedClaims] = useMutation(
    UPDATE_SCANNED_DOCUMENTS_USER_CLAIMS_RELATION,
    {
      variables: {
        scannedDocumentId: scannedDocument.id,
        connectedClaimsData: [],
      },
    }
  );

  const [setDocumentPolicyIdAndDone] = useMutation(UPDATE_SCANNED_DOCUMENT, {
    variables: {
      id: scannedDocument.id,
      userPolicyId: matchingPolicy?.id,
      status: 'DONE',
    },
    refetchQueries: [GET_SCANNED_DOCUMENT_BY_ID],
  });

  const handleConnect = async (createdClaimId: string) => {
    await changeRelatedClaims({
      variables: {
        scannedDocumentId: scannedDocument.id,
        connectedClaimsData: [
          {
            claimId: createdClaimId,
          },
        ],
      },
    });
    await setDocumentPolicyIdAndDone();
  };

  const onSubmit = async (answers: Partial<SubmittableClaim<Status, Type>>) => {
    const userId = matchingPolicy?.user.id;
    const policyNumber = matchingPolicy?.policyNumber;
    const policyId = matchingPolicy?.id;

    if (userId) {
      const creationResult = await createClaim({
        variables: {
          ...answers,
          policyId,
          userId,
          insuranceType,
          ...(policyNumber && { policyNumber }),
          ...(answers.amount ? { amount: Number(answers.amount) } : {}),
        },
      });

      if (creationResult.data) {
        const mutationResult: { id?: string } =
          (Object.values(creationResult.data)[0] as unknown as {
            id?: string;
          }) ?? {};

        if (mutationResult.id) {
          handleConnect(mutationResult.id);
        } else {
          throw new Error("Couldn't find claim id to connect doc to");
        }
      }
    }
  };

  const confirmationRequired = matchingPolicy && !scannedDocument.userPolicyId;

  if (!claimsTemplateData) {
    return null;
  }

  return (
    <>
      {createClaimData && createClaimMutation && submitValidationSchema && (
        <ModalForm<any, any>
          isOpen={showModal}
          setIsOpen={setShowModal}
          loading={loading}
          submitButtonLabel="Create & connect"
          title="Create claim & connect"
          formData={formDataWithoutIdAndUpload}
          onSubmit={onSubmit}
          submitValidationSchema={submitValidationSchema}
          scrollable
          bottomElement={
            <div>
              {confirmationRequired && (
                <FileErrorCard
                  open={true}
                  className="mb-5 mt-5"
                  title="Unconfirmed relation"
                  hideCloseButton={true}
                  handleClose={() => {}}
                  errorType="WARNING"
                  description="Accepting this action will also confirm that below policy belongs to this scanned document."
                />
              )}
              <p className={classNames(styles.label, 'mt-5')}>
                Matching policy
              </p>
              <PolicyCard
                title={
                  (matchingPolicy?.insuranceType &&
                    insuranceNameMapper[matchingPolicy?.insuranceType]) ??
                  ''
                }
                icon={
                  (matchingPolicy?.insuranceType &&
                    insuranceIconMapper[matchingPolicy.insuranceType]) ??
                  ''
                }
                link={
                  getPolicyDetailsBaseURLFromInsuranceType(
                    matchingPolicy?.insuranceType,
                    matchingPolicy?.id
                  ) ?? ''
                }
                insuredPerson={`${matchingPolicy?.user.firstName} ${matchingPolicy?.user.lastName}`}
                policyNumber={matchingPolicy?.policyNumber}
                regionOfPurchase={matchingPolicy?.regionOfPurchase}
                smallVersion
                openInNewTab
              />

              <Link
                color="primary"
                text="Document link"
                href={scannedDocument.url}
                IconComponent={ExternalLinkIcon}
                iconLeft={false}
                target="_blank"
                className="mb-6"
              />
              <p className={styles.bottomText}>
                Once confirmed, a new claim will be created, above scanned
                document will be connected to it and both will be exposed to the
                customer in their account.
              </p>
              <p className={styles.bottomTextBold}>
                All other claim connections of this document will be removed!
              </p>
            </div>
          }
        />
      )}
      <Button
        buttonType="secondary"
        onClick={() => setShowModal(true)}
        loading={false}
      >
        Create
      </Button>
    </>
  );
};
