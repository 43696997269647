import flagAT from '../icons/at.svg';
import flagBE from '../icons/be.svg';
import flagCY from '../icons/cy.svg';
import flagDE from '../icons/de.svg';
import flagEE from '../icons/ee.svg';
import flagES from '../icons/es.svg';
import flagEU from '../icons/eu.svg';
import flagFI from '../icons/fi.svg';
import flagFR from '../icons/fr.svg';
import flagGR from '../icons/gr.svg';
import flagHR from '../icons/hr.svg';
import flagIT from '../icons/it.svg';
import flagLT from '../icons/lt.svg';
import flagLV from '../icons/lv.svg';
import flagMC from '../icons/mc.svg';
import flagMT from '../icons/mt.svg';
import flagNL from '../icons/nl.svg';
import flagPT from '../icons/pt.svg';
import flagSE from '../icons/se.svg';

export const countries = [
  'de',
  'es',
  'fr',
  'eu',
  'at',
  'be',
  'hr',
  'cy',
  'ee',
  'fi',
  'gr',
  'it',
  'lv',
  'lt',
  'mt',
  'mc',
  'nl',
  'pt',
  'se',
] as const;
export type Country = typeof countries[number];

export const countryIconMapper: Record<Country, string> = {
  de: flagDE,
  es: flagES,
  fr: flagFR,
  eu: flagEU,
  nl: flagNL,
  it: flagIT,
  be: flagBE,
  se: flagSE,
  mt: flagMT,
  at: flagAT,
  fi: flagFI,
  cy: flagCY,
  gr: flagGR,
  ee: flagEE,
  pt: flagPT,
  lt: flagLT,
  hr: flagHR,
  lv: flagLV,
  mc: flagMC,
};

export const countryNameMapper: Record<Country, string> = {
  de: 'Germany',
  es: 'Spain',
  fr: 'France',
  eu: 'European Union',
  nl: 'Netherlands',
  it: 'Italy',
  be: 'Belgium',
  se: 'Sweden',
  mt: 'Malta',
  at: 'Austria',
  fi: 'Finland',
  cy: 'Cyprus',
  gr: 'Greece',
  ee: 'Estonia',
  pt: 'Portugal',
  lt: 'Lithuania',
  hr: 'Croatia',
  lv: 'Latvia',
  mc: 'Monaco',
};
