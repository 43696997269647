import { gql } from '@apollo/client';

export const UPDATE_LIFE_POLICY = gql`
  mutation UpdateLifePolicy(
    $insuranceType: String!
    $id: String!
    $policyNumber: String!
    $startDate: String!
    $endDate: String!
    $price: Float!
    $source: String!
    $campaign: String
    $medium: String
    $term: String
    $content: String
  ) {
    updateLifePolicyInfo(
      insuranceType: $insuranceType
      id: $id
      policyNumber: $policyNumber
      startDate: $startDate
      endDate: $endDate
      price: $price
      source: $source
      campaign: $campaign
      medium: $medium
      content: $content
      term: $term
    ) {
      id
      policyNumber
      startDate
      endDate
      price
      source
      campaign
      medium
      content
      term
    }
  }
`;
