import Modal from 'components/Modal';
import RadioButton from 'components/RadioButton/RadioButton';
import { QuestionnaireAnswers } from 'models/questionnaire';
import omitDeep from 'omit-deep-lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import JSONInput from 'react-json-editor-ajrm';
import { jsonEditorLocale } from 'shared/jsonEditorLocate';

interface EditQuestionnaireFormProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  answers?: QuestionnaireAnswers;
  loading: boolean;
  questionnaireId: string;
  policyId: string;
  editQuestionnaire: (variables: {
    questionnaireId: string;
    answers: string;
    policyId: string;
    submitApp: boolean;
  }) => void;
  getEditableAnswers?: (answers: QuestionnaireAnswers) => object;
  description?: string;
  allowResubmitToProvider?: boolean;
}

const EditQuestionnaireForm = ({
  open,
  setOpen,
  answers,
  questionnaireId,
  editQuestionnaire,
  loading,
  policyId,
  getEditableAnswers,
  description,
  allowResubmitToProvider = false,
}: EditQuestionnaireFormProps) => {
  const [questionnaire, setQuestionnaire] = useState<object>(
    omitDeep(
      getEditableAnswers ? getEditableAnswers(answers ?? {}) : answers ?? {}
    )
  );
  const [questionnaireError, setQuestionnaireError] = useState(false);
  const [submitApp, setSubmitApp] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const handleSaveQuestionnaire = () => {
    editQuestionnaire({
      questionnaireId,
      policyId,
      answers: JSON.stringify(questionnaire),
      submitApp,
    });
  };

  const handleQuestionnaireChange = ({
    jsObject,
    error,
  }: {
    jsObject: object;
    error: object | boolean;
  }) => {
    if (error) {
      setQuestionnaireError(true);
      return;
    }
    setQuestionnaire(jsObject);
    setQuestionnaireError(false);
    setDisableSubmit(false);
  };

  const handleSubmitAppChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSubmitApp(e.target.value === 'yes');
  };

  useEffect(() => {
    if (!open) {
      setDisableSubmit(true);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Edit questionnaire"
      handleConfirm={handleSaveQuestionnaire}
      confirmButtonLabel="Save"
      textLoading="Saving..."
      disabled={questionnaireError || disableSubmit}
      loading={loading}
    >
      <div className="max-h-fit flex flex-col">
        <JSONInput
          locale={jsonEditorLocale}
          width="432px"
          placeholder={
            getEditableAnswers
              ? getEditableAnswers(answers ?? {})
              : answers ?? {}
          }
          onChange={handleQuestionnaireChange}
          confirmGood={false}
          colors={{
            default: '#26262E',
            background_warning: '#F5F5FA',
            background: '#F5F5FA',
            keys: '#26262E',
            keys_whiteSpace: '#26262E',
            /* @ts-expect-error: Ignoring because error property was not declared but is needed */
            error: '#F87171',
            colon: '#26262E',
            primitive: '#6160A2',
            string: '#6160A2',
            number: '#6160A2',
          }}
          style={{
            labels: { color: '#F5F5FA' },
            container: {
              borderRadius: '8px',
              maxHeight: 'fit-content',
              height: '500px',
            },
            warningBox: { backgroundColor: '#F5F5FA' },
            errorMessage: { color: '#F87171' },
            outerBox: { maxHeight: 'fit-content', height: 'auto' },
          }}
        />
        {allowResubmitToProvider && (
          <div className="m-1 mt-4 flex flex-col space-y-2">
            <h1 className="text-sm font-bold text-gray-900">
              Do you want to resubmit new application document to provider?
            </h1>
            <div className="flex items-center space-x-6">
              <RadioButton
                id="yes"
                name="submitApp"
                value="yes"
                required
                checked={!!submitApp}
                onChange={handleSubmitAppChange}
              >
                Yes
              </RadioButton>
              <RadioButton
                id="no"
                name="submitApp"
                value="no"
                required
                checked={!submitApp}
                onChange={handleSubmitAppChange}
              >
                No
              </RadioButton>
            </div>
          </div>
        )}
        {description && (
          <p className="mt-4 text-sm text-gray-600">{description}</p>
        )}
      </div>
    </Modal>
  );
};

export default EditQuestionnaireForm;
