import { DocumentNode, useMutation, useReactiveVar } from '@apollo/client';
import { SelectButtonOption } from 'components/SelectButton';
import { StatusDropdown } from 'components/StatusDropdown';
import { StatusUpdateModal } from 'components/StatusUpdateModal';
import { retrieveValidationByType } from 'components/StatusUpdateModal/utils';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';
import { useState } from 'react';
import { CombinedClaimStatus } from 'shared/claims/models';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import { StatusData } from '../../models';

interface Props<Status> {
  claimId: string;
  claimStatus?: Status;
  refetchQueries: DocumentNode[];
  updateStatusMutation: DocumentNode;
  statusOptions: SelectButtonOption<Status>[];
  // TODO: [KONG] Remove string type - stricter SelectButtonOption generic type
  dataByStatus: (statusId?: Status | string) => StatusData;
  userInfo: Partial<{
    email: string;
    policyId: string;
    userId: string;
  }>;
  createdOn?: string;
}

export const StatusUpdate = <
  Form extends Record<string, any>,
  Status extends CombinedClaimStatus
>({
  claimId,
  claimStatus,
  refetchQueries,
  updateStatusMutation,
  dataByStatus,
  statusOptions,
  userInfo: { email, policyId, userId },
  createdOn,
}: Props<Status>) => {
  const currentStatus = statusOptions.find(({ id }) => id === claimStatus);

  const [selectedStatus, setSelectedStatus] = useState<
    SelectButtonOption<Status> | undefined
  >(currentStatus);

  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const [updateClaimStatus, { loading }] = useMutation(updateStatusMutation);

  const { footerText, form } = dataByStatus(selectedStatus?.id);

  const onStatusChange = (option: SelectButtonOption<Status>) => {
    if (option.id !== currentStatus?.id) {
      setSelectedStatus(option);
      setIsStatusModalOpen(true);
    }
  };

  const alertBannersState = useReactiveVar(alertBanners);

  const onStatusUpdateComplete = () => {
    setIsStatusModalOpen(false);

    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'SUCCESS',
      message: 'Status successfully updated',
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };

  const onStatusUpdate = (form?: Record<string, any>) => {
    if (!selectedStatus?.id) {
      throw new Error('[Claim status update] No new status to submit');
    }

    if (!email || !policyId || !userId) {
      throw new Error('[Claim status update] One or more user info not found');
    }

    if (!createdOn) {
      throw new Error('[Claim status update] Created date not found');
    }

    updateClaimStatus({
      variables: {
        // Other collected fields from form
        ...form,

        // Mandatory fields for all statuses
        claimId,
        status: selectedStatus.id,
        email,
        policyId,
        userId,
        createdOn: dayjs(createdOn).format(DATE_FORMAT),
      },
      onCompleted: onStatusUpdateComplete,
      refetchQueries,
    });
  };

  const validatorObject = form.reduce(
    (acc, { id, required, data: { type } }) => {
      const validation = retrieveValidationByType(type);
      return {
        ...acc,
        ...(validation && {
          [id]: required ? validation : validation?.optional(),
        }),
      };
    },
    {}
  );

  const validationSchema = z.object({
    ...validatorObject,
  });

  return (
    <>
      {selectedStatus && (
        <StatusUpdateModal<Form, Status>
          isOpen={isStatusModalOpen}
          setOpen={setIsStatusModalOpen}
          onSubmit={onStatusUpdate}
          form={form}
          status={selectedStatus}
          bottomText={footerText}
          validationSchema={validationSchema}
          loading={loading}
        />
      )}
      <StatusDropdown<Status>
        options={statusOptions}
        onChange={onStatusChange}
        status={currentStatus}
      />
    </>
  );
};
