import ActivityCards from 'components/ActivityCards';
import { Activity } from 'components/ActivityCards/ActivityCards';
import { INTERNAL_UPLOAD_PUBLIC_POLICY_FILE } from 'graphql/publicHealth/internal/mutations';
import { GET_PUBLIC_POLICY } from 'graphql/publicHealth/internal/queries';
import { UPDATE_INTERNAL_ISSUE_STATUS } from 'graphql/shared/issues';
import { getInternalPublicHealthStatus } from 'shared/mapInternalPublicHealthStatus';

interface ActivityTabProps {
  activity: Activity[];
  policyId: string;
}

const ActivityTab = ({ activity, policyId }: ActivityTabProps) => {
  return (
    <div className="relative flex w-full flex-col">
      <ActivityCards
        activity={activity}
        policyId={policyId}
        showCustomerInformed={false}
        statusMapping={getInternalPublicHealthStatus}
        providerView={false}
        policyQuery={GET_PUBLIC_POLICY}
        issueStatusChangeMutation={UPDATE_INTERNAL_ISSUE_STATUS}
        uploadFileMutation={INTERNAL_UPLOAD_PUBLIC_POLICY_FILE}
        issueCategory="OTHER"
      />
    </div>
  );
};

export default ActivityTab;
