import { gql } from '@apollo/client';

export const GET_READY_TRANSACTIONS_DETAILS = gql`
  query ReadyTransactionDetails($insuranceType: String!) {
    readyTransactionDetails(insuranceType: $insuranceType) {
      readyCount
      invalidIBANCount
      mismatchedAmountsCount
      missingClaimNumberCount
      delinquentCustomersCount
    }
  }
`;
