import { getFixedT } from '@getpopsure/i18n-react';

export const mapUserOccupation = (
  occupation: string,
  salary?: string,
  otherSituation?: string
): string => {
  const t = getFixedT();

  switch (occupation) {
    case 'MINI_JOB':
      return t('admin.provider.public.occupation.minijob.label', 'Mini Job');
    case 'EMPLOYED':
      return salary === 'MINI_JOB'
        ? t('admin.provider.public.occupation.minijob.label', 'Mini Job')
        : t('admin.provider.public.occupation.employed.label', 'Employed');
    case 'SELF_EMPLOYED':
      return t(
        'admin.provider.public.occupation.selfemployed.label',
        'Self-Employed'
      );
    case 'UNIVERSITY_STUDENT':
      return t('admin.provider.public.occupation.student.label', 'Student');
    case 'OTHER':
      return mapOtherSituation(otherSituation);
    default:
      return t('admin.provider.public.occupation.other.label', 'Other');
  }
};

const mapOtherSituation = (otherSituation?: string): string => {
  const t = getFixedT();

  switch (otherSituation) {
    case 'RETIRED':
      return t('admin.provider.public.othersituation.retired.label', 'Retired');
    case 'LANGUAGE_SCHOOL':
      return t(
        'admin.provider.public.othersituation.languageschool.label',
        'Language School'
      );
    case 'JOB_HUNTING':
      return t(
        'admin.provider.public.othersituation.jobhunting.label',
        'Job hunting'
      );
    case 'APPRENTICE':
      return t(
        'admin.provider.public.othersituation.apprentice.label',
        'Apprentice'
      );
    case 'FSJ':
      return t(
        'admin.provider.public.othersituation.fsj.label',
        'Voluntary Year'
      );
    default:
      return t('admin.provider.public.occupation.other.label', 'Other');
  }
};
