import { capitalizeName } from '@getpopsure/public-utility';
import { PrivateHealthPolicy } from 'models/privateHealthPolicy';
import { BikePolicy } from 'pages/policies/bike/models/BikePolicy';
import { DentalPolicy } from 'pages/policies/dental/models/DentalPolicy';
import { DogLiabilityPolicy } from 'pages/policies/dogLiability/models/DogLiabilityPolicy';
import { ExpatHealthPolicy } from 'pages/policies/expatHealth/models/ExpatHealthPolicy';
import { GenericPolicy } from 'pages/policies/generic/models/GenericPolicy';
import { HouseholdPolicy } from 'pages/policies/household/models/HouseholdPolicy';
import { LegalPolicy } from 'pages/policies/legal/models/LegalPolicy';
import { LiabilityPolicy } from 'pages/policies/liability/models/LiabilityPolicy';
import { InsuranceType } from 'shared/insurances/types';

export const retrievePoliciesData = (
  insuranceType: InsuranceType,
  data: unknown
) => {
  if (insuranceType === 'INCOMING') {
    return (
      (data as { expatPolicies: ExpatHealthPolicy[] })?.expatPolicies ?? null
    );
  }

  if (insuranceType === 'DENTAL') {
    return (data as { dentalPolicies: DentalPolicy[] })?.dentalPolicies ?? null;
  }

  if (insuranceType === 'PRIVATE_HEALTH') {
    return (
      (data as { privateHealthPolicies: PrivateHealthPolicy[] })
        ?.privateHealthPolicies ?? null
    );
  }

  if (
    [
      'TRAVEL',
      'DISABILITY',
      'PET_HEALTH',
      'COMPANY_HEALTH',
      'COMPANY_LIFE',
      'INCOMING_LT',
    ].includes(insuranceType)
  ) {
    return (
      (data as { genericPolicies: GenericPolicy[] })?.genericPolicies ?? null
    );
  }

  if (insuranceType === 'BIKE') {
    return (data as { bikePolicies: BikePolicy[] })?.bikePolicies ?? null;
  }

  if (insuranceType === 'LEGAL') {
    return (data as { legalPolicies: LegalPolicy[] })?.legalPolicies ?? null;
  }

  if (insuranceType === 'DOG_LIABILITY') {
    return (
      (data as { dogLiabilityPolicies: DogLiabilityPolicy[] })
        ?.dogLiabilityPolicies ?? null
    );
  }

  if (insuranceType === 'HOUSEHOLD') {
    return (
      (data as { householdPolicies: HouseholdPolicy[] })?.householdPolicies ??
      null
    );
  }

  if (insuranceType === 'LIABILITY') {
    return (
      (data as { liabilityPolicies: LiabilityPolicy[] })?.liabilityPolicies ??
      null
    );
  }

  if (insuranceType === 'INCOMING_ES') {
    return (
      (data as { expatSpainPolicies: ExpatHealthPolicy[] })
        ?.expatSpainPolicies ?? null
    );
  }

  return null;
};

export const retrievePolicySuggestion = ({
  firstName,
  lastName,
  policyNumber,
}: {
  firstName?: string;
  lastName?: string;
  policyNumber?: string;
}): string =>
  firstName && lastName
    ? String(
        `${capitalizeName({
          firstName,
          lastName,
        })}${policyNumber ? `, ${policyNumber}` : ''}`
      )
    : 'No name';
