import { PaymentMethodBrand } from 'models/PaymentMethod';

import amexIcon from './icons/amex.svg';
import cartesbancairesIcon from './icons/cartesbancaires.svg';
import mastercardIcon from './icons/mastercard.svg';
import unionIcon from './icons/union.svg';
import visaIcon from './icons/visa.svg';

export const paymentBrandIconMapper: Record<PaymentMethodBrand, string> = {
  visa: visaIcon,
  amex: amexIcon,
  mastercard: mastercardIcon,
  unionpay: unionIcon,
  cartes_bancaires: cartesbancairesIcon,
};
