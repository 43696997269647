import useStateWithLocalStorage from 'hooks/useStateWithLocalStorage';
import Page404 from 'pages/errors/404';
import { useEffect } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import routes from 'routes';
import redirects from 'routes/redirects';
import { isValidCountry } from 'shared/countries/utils';

const AppSwitch = () => {
  const match = useRouteMatch<{ country: string }>(
    '/:country/internal/:insuranceType/(policies|claims)/:id?'
  );
  const { setValue: setGlobalCountry } = useStateWithLocalStorage('country');

  useEffect(() => {
    if (match?.params.country && isValidCountry(match.params.country)) {
      setGlobalCountry(match.params.country);
    }
  }, [match?.params.country, setGlobalCountry]);

  return (
    <Switch>
      {routes.map(({ component, path }) => (
        <Route exact path={path} key={path} component={component} />
      ))}
      {redirects.map(({ to, from }) => (
        <Redirect exact to={to} from={from} />
      ))}
      <Route path="*" component={Page404} />
    </Switch>
  );
};
export default AppSwitch;
