import { useMutation } from '@apollo/client';
import Input from 'components/Input';
import Modal from 'components/Modal';
import { UPDATE_MANY_SCANNED_DOCUMENTS } from 'pages/scannedDocuments/scannedDocuments.mutations';
import { GET_SCANNED_DOCUMENTS } from 'pages/scannedDocuments/scannedDocuments.queries';
import { useState } from 'react';

type RenameDocumentBulkActionProps = {
  ids: string[];
  modalIsShown: boolean;
  closeModal: () => void;
};

export const RenameDocumentBulkAction = ({
  ids,
  modalIsShown,
  closeModal,
}: RenameDocumentBulkActionProps) => {
  const [friendlyName, setFriendlyName] = useState('');

  const [renameDocuments, { loading }] = useMutation(
    UPDATE_MANY_SCANNED_DOCUMENTS,
    {
      variables: {
        ids,
        friendlyName,
      },
      refetchQueries: [GET_SCANNED_DOCUMENTS],
    }
  );

  const handleConfirm = async () => {
    const result = await renameDocuments();

    if (!result.errors) {
      closeModal();
    }
  };

  const documentCount = ids.length;

  return (
    <>
      {modalIsShown && (
        <Modal
          open={modalIsShown}
          setOpen={closeModal}
          title="Rename documents?"
          confirmButtonLabel="Rename"
          handleConfirm={handleConfirm}
          loading={loading}
          disabled={!friendlyName}
        >
          <div className="text-sm text-gray-600">
            <p className="font-bold text-gray-900">
              New friendly name to apply:
            </p>
            <Input
              className="mt-3"
              type="text"
              value={friendlyName}
              color="gray"
              placeholder="New_Friendly_Name.pdf"
              onChange={(e) => setFriendlyName(e.target.value)}
            />
            <p className="mt-5 text-sm text-gray-600">
              Changing the friendly name of a document will only affect the
              displayed name in the admin panel and user account. The filename
              itself will stay untouched.
            </p>
            <p className="mt-5">
              Do you really want to rename{' '}
              <span className="font-bold">{documentCount}</span> document(s)?
            </p>
          </div>
        </Modal>
      )}
    </>
  );
};
