import ChecklistInformation from 'components/InformationCard/ChecklistInformation';
import { CurrencyInformation } from 'components/InformationCard/CurrencyInformation';
import DateInformation from 'components/InformationCard/DateInformation';
import LinkInformation from 'components/InformationCard/LinkInformation';
import MultipleLineInformation from 'components/InformationCard/MultipleLineInformation';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import TextWithDetailsInformation from 'components/InformationCard/TextWithDetailsInformation';
import { ReferralCode } from 'components/ReferralCode/ReferralCode';
import { retrieveClaimsOverviewPath } from 'pages/claims/template/utils/utils';
import ChangeAccount from 'pages/policies/template/ChangeAccount/ChangeAccount';
import {
  InformationCardDetails,
  InformationCardEditableDetails,
} from 'pages/policies/template/models/PolicyTemplateData';
import { UPDATE_POLICY_CANCELATION_REQUEST } from 'pages/policies/template/mutations';
import { legalPlanMapper } from 'shared/insurances/planMappers/legal';
import {
  legalBadgeColorMapper,
  legalStatusMapper,
} from 'shared/insurances/statusMappers/legal';
import { InsuranceType, LegalStatus } from 'shared/insurances/types';
import { getReadableProviderName } from 'shared/utils/getReadableProviderName';
import { setQueryToUrl } from 'shared/utils/setQueryToUrl';

import { GET_LEGAL_POLICY } from '../graphql/singlePolicy';
import { UPDATE_LEGAL_POLICY } from '../graphql/updatePolicy';
import { legalAddOnMapper, legalAddOns } from '../models/LegalAddOn';
import { LegalPolicyData } from '../models/LegalPolicy';

const insuranceType: InsuranceType = 'LEGAL';

const getInsuredPersonInfo = (
  data?: LegalPolicyData
): InformationCardDetails => ({
  editable: false,
  cards: [
    {
      title: 'Insured person',
      rows: [
        {
          id: 'firstName',
          title: 'First name',
          data: data?.legalPolicy?.insuredPerson?.firstName ?? '',
          component: TextInformation,
          type: 'TEXT',
        },
        {
          id: 'lastName',
          title: 'Last name',
          data: data?.legalPolicy?.insuredPerson?.lastName ?? '',
          component: TextInformation,
          type: 'TEXT',
        },
        {
          id: 'dateOfBirth',
          title: 'Date of birth',
          data: data?.legalPolicy?.insuredPerson?.dateOfBirth ?? '',
          component: TextInformation,
          type: 'TEXT',
        },
        {
          id: 'gender',
          title: 'Gender',
          data: data?.legalPolicy?.insuredPerson?.gender ?? '',
          component: TextInformation,
          type: 'TEXT',
        },
        {
          id: 'email',
          title: 'Email',
          data: data?.legalPolicy?.user?.email ?? '',
          component: TextWithDetailsInformation,
          type: 'TEXT_WITH_DETAILS',
          detailsTitle: 'Change account',
          detailsLabel: 'Change account',
          renderModalDetails: (setOpen, modalOpen) => (
            <ChangeAccount
              policyDetailsMutation={GET_LEGAL_POLICY}
              setOpen={setOpen}
              modalOpen={modalOpen}
            />
          ),
        },
      ],
    },
  ],
});

const policyInformation = (
  data?: LegalPolicyData
): InformationCardEditableDetails<LegalPolicyData> => {
  const policy = data?.legalPolicy;

  return {
    editable: true,
    mutation: UPDATE_LEGAL_POLICY,
    refetchQueries: [GET_LEGAL_POLICY],
    resourceId: policy?.id ?? '',
    extraVariables: {
      insuranceType,
    },
    editabledData: {
      policyNumber: policy?.policyNumber ?? '',
      price: policy?.price ?? 0,
      startDate: policy?.startDate ?? '',
      source: policy?.source ?? '',
      campaign: policy?.campaign ?? '',
      medium: policy?.medium ?? '',
      content: policy?.content ?? '',
      term: policy?.term ?? '',
      addOns: policy?.addOns ?? [],
      partnerName: policy?.partnerName ?? {},
      address: policy?.address ?? {},
    },
    cards: [
      {
        title: 'Policy information',
        rows: [
          {
            id: 'policyNumber',
            title: 'Policy number',
            data: policy?.policyNumber ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter policy number',
          },
          {
            id: 'providerId',
            title: 'Provider',
            data: policy?.providerId
              ? getReadableProviderName(policy.providerId)
              : '',
            component: TextInformation,
            type: 'TEXT',
          },
          {
            id: 'status',
            title: 'Status',
            data: policy?.publicStatus ?? '',
            type: 'STATUS',
            component: StatusInformation,
            statusMapping: (status: string) => {
              return {
                text: {
                  id: status,
                  text: legalStatusMapper[status as LegalStatus] ?? '',
                },
                color: legalBadgeColorMapper[status as LegalStatus] ?? 'gray',
              };
            },
          },
          {
            id: 'startDate',
            title: 'Start date',
            data: policy?.startDate ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'legalPlanId',
            title: 'Plan',
            data: policy?.legalPlanId
              ? legalPlanMapper[policy.legalPlanId]
              : '',
            component: TextInformation,
            type: 'TEXT',
          },
          {
            id: 'addOns',
            title: 'Add-ons',
            type: 'CHECKLIST' as const,
            component: ChecklistInformation,
            checklist: legalAddOns.flatMap((addOn) => ({
              id: addOn,
              title: legalAddOnMapper[addOn],
              check: policy?.addOns?.includes(addOn) ?? false,
            })),
            editable: true,
          },
          {
            id: 'partnerName',
            title: "Partner's name",
            data: policy?.partnerName ?? {},
            component: MultipleLineInformation,
            type: 'MULTIPLELINE',
            editable: true,
            multipleLinesData: [
              {
                id: 'firstName',
                label: 'First name',
                data: policy?.partnerName?.firstName ?? '',
              },
              {
                id: 'lastName',
                label: 'Last name',
                data: policy?.partnerName?.lastName ?? '',
              },
            ],
          },
          {
            id: 'price',
            title: 'Price',
            data: policy?.price ?? 0,
            component: CurrencyInformation,
            type: 'CURRENCY',
            editable: true,
            placeholder: '€ 0.00',
          },
          ...(policy?.numberOfClaims && policy.numberOfClaims > 0
            ? [
                {
                  id: 'claims',
                  title: 'Claims',
                  data: `${policy?.numberOfClaims} claims`,
                  href: setQueryToUrl(retrieveClaimsOverviewPath('legal'), [
                    { key: 'search', value: policy?.policyNumber ?? '' },
                  ]),
                  externalLink: true,
                  type: 'LINK' as const,
                  component: LinkInformation,
                },
              ]
            : [
                {
                  id: 'claims',
                  title: 'Claims',
                  data: '',
                  type: 'TEXT' as const,
                  component: LinkInformation,
                },
              ]),
          {
            id: 'address',
            title: 'Address',
            type: 'MULTIPLELINE',
            component: MultipleLineInformation,
            data: policy?.address ?? {},
            multipleLinesData: [
              {
                id: 'houseNumber',
                label: 'House number',
                data: policy?.address?.houseNumber ?? '',
              },
              {
                id: 'street',
                label: 'Street',
                data: policy?.address?.street ?? '',
              },
              {
                id: 'city',
                label: 'City',
                data: policy?.address?.city ?? '',
              },
              {
                id: 'additionalInformation',
                label: 'Additional information',
                data: policy?.address?.additionalInformation ?? '',
              },
              {
                id: 'postcode',
                label: 'Postal code',
                data: policy?.address?.postcode ?? '',
              },
              {
                id: 'country',
                label: 'Country',
                data: policy?.address?.country ?? '',
              },
            ],
            editable: true,
          },
          {
            id: 'createdAt',
            title: 'Created on',
            data: policy?.createdAt ?? '',
            component: DateInformation,
            type: 'DATE',
          },
          {
            id: 'source',
            title: 'Source',
            data: policy?.source ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter source',
          },
          {
            id: 'campaign',
            title: 'Campaign',
            data: policy?.campaign ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter campaign',
          },
          {
            id: 'medium',
            title: 'Medium',
            data: policy?.medium ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter medium',
          },
          {
            id: 'content',
            title: 'Content',
            type: 'TEXT',
            component: TextInformation,
            data: policy?.content ?? '',
            editable: true,
            placeholder: 'Enter content',
          },
          {
            id: 'term',
            title: 'Term',
            data: policy?.term ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter term',
          },
          {
            id: 'referralCode',
            title: 'Referral code',
            data: policy?.referral?.referralCode ?? '',
            type: policy?.referral?.status ? 'TEXT_WITH_DETAILS' : 'TEXT',
            detailsTitle: 'Add referral code',
            detailsLabel: 'Add referral code',
            component: TextWithDetailsInformation,
            renderModalDetails: (setOpen, modalOpen) => (
              <ReferralCode
                policyId={policy?.id ?? ''}
                userId={policy?.user?.id ?? ''}
                setOpen={setOpen}
                modalOpen={modalOpen}
              />
            ),
          },
        ],
      },
    ],
  };
};

const policyCancellationInformation = (
  data?: LegalPolicyData
): InformationCardEditableDetails<LegalPolicyData> => {
  const policy = data?.legalPolicy;

  return {
    editable: true,
    mutation: UPDATE_POLICY_CANCELATION_REQUEST,
    resourceId: policy?.policyCancelationRequest?.id ?? '',
    editabledData: {
      activeUntil: policy?.activeUntil ?? '',
      createdAt: policy?.policyCancelationRequest?.createdAt ?? '',
      canceledOnStripeAt:
        policy?.policyCancelationRequest?.canceledOnStripeAt ?? '',
      requestSentToProviderAt:
        policy?.policyCancelationRequest?.requestSentToProviderAt ?? '',
      cancelationConfirmedByProviderAt:
        policy?.policyCancelationRequest?.cancelationConfirmedByProviderAt ??
        '',
    },
    extraVariables: {
      insuranceType,
      policyId: policy?.id ?? '',
    },
    cards: [
      {
        title: 'Policy cancellation',
        rows: [
          {
            id: 'activeUntil',
            title: 'Active until',
            data: policy?.activeUntil ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'createdAt',
            title: 'Requested by customer on',
            data: policy?.policyCancelationRequest?.createdAt ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'canceledOnStripeAt',
            title: 'Stripe canceled on',
            data: policy?.policyCancelationRequest?.canceledOnStripeAt ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'requestSentToProviderAt',
            title: 'Request sent to provider on',
            data:
              policy?.policyCancelationRequest?.requestSentToProviderAt ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'cancelationConfirmedByProviderAt',
            title: 'Provider confirmed on',
            data:
              policy?.policyCancelationRequest
                ?.cancelationConfirmedByProviderAt ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
        ],
      },
    ],
  };
};

export const getOverviewTabCards = (
  data?: LegalPolicyData
): (
  | InformationCardDetails
  | InformationCardEditableDetails<LegalPolicyData>
)[] => [
  getInsuredPersonInfo(data),
  policyInformation(data),
  ...(data?.legalPolicy.publicStatus === 'CANCELED' ||
  data?.legalPolicy.publicStatus === 'DROPPED_OUT'
    ? [policyCancellationInformation(data)]
    : []),
];
