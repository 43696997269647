import Button from 'components/Button';
import Card from 'components/Card';
import { useState } from 'react';

import * as styles from './TextPreviewInformation.styles';

export const TextPreviewInformation = ({
  textContent,
}: {
  textContent?: string;
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  if (!textContent) {
    return null;
  }

  const lineArray = textContent.split('\n');

  const toggleButtonCaption = isCollapsed ? 'View more' : 'View less';
  const textContentClass = isCollapsed
    ? styles.textContentCollapsed
    : styles.textContent;

  return (
    <Card
      title="Text content"
      boldTitle
      actionButton={
        <Button
          buttonType="secondary"
          onClick={() => setIsCollapsed((value) => !value)}
        >
          {toggleButtonCaption}
        </Button>
      }
    >
      <div className={styles.textContainer}>
        <div className={textContentClass}>
          {lineArray.map((line, index) => (
            <p key={String(`TextPreview${index}`)}>{line}</p>
          ))}
        </div>
      </div>
    </Card>
  );
};
