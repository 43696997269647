import { InsuranceType } from 'shared/insurances/types';

import { CustomerPolicy } from '../models';

const statusOrder = [
  'APPLICATION_RECEIVED',
  'UNI_CHECK',
  'PENDING',
  'PROCESSING',
  'APPLICATION_SENT',
  'MISSING_INFO',
  'ERR_MISSING_INFO',
  'COVERED',
  'ACTIVE',
  'NOT_ELIGIBLE',
  'CANT_BE_COVERED',
  'DROPPED_OUT',
  'CANCELED',
];

export const orderPoliciesByStatus = (
  policies: CustomerPolicy[]
): CustomerPolicy[] => {
  const reversedStatusOrder = statusOrder.reverse();
  return reversedStatusOrder.reduce((currentArray, status) => {
    policies.forEach((policy) => {
      if (!policy.publicStatus && !currentArray.includes(policy)) {
        currentArray.push(policy);
      }

      if (status === policy.publicStatus) {
        currentArray.unshift(policy);
      }
    });
    return currentArray;
  }, [] as CustomerPolicy[]);
};

export const getDisplayNameForPolicy = ({
  policyInsuranceType,
  accountHolderName,
  insuredPersonName,
  policies,
}: {
  policyInsuranceType: InsuranceType;
  accountHolderName: string | null;
  insuredPersonName: string | null;
  policies: CustomerPolicy[];
}): string | null => {
  const hasMoreThanOnePolicyFromSameVertical =
    policies.filter(
      ({ insuranceType }) => insuranceType === policyInsuranceType
    ).length > 1;

  if (hasMoreThanOnePolicyFromSameVertical) {
    return insuredPersonName ?? accountHolderName;
  }

  if (accountHolderName !== insuredPersonName) {
    return insuredPersonName;
  }

  return null;
};
