import { gql } from '@apollo/client';

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer(
    $id: String!
    $firstName: String
    $lastName: String
    $email: String
    $dateOfBirth: String
    $gender: String
    $language: String
    $intercomEUId: String
    $stripeToken: String
    $isDelinquent: Boolean
    $isFraudulent: Boolean
    $fraudulentAdditionalInfo: String
  ) {
    updateCustomer(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      dateOfBirth: $dateOfBirth
      gender: $gender
      language: $language
      intercomEUId: $intercomEUId
      stripeToken: $stripeToken
      isDelinquent: $isDelinquent
      isFraudulent: $isFraudulent
      fraudulentAdditionalInfo: $fraudulentAdditionalInfo
    ) {
      id
      firstName
      lastName
      email
      dateOfBirth
      gender
      language
      intercomEUId
      stripeToken
      isDelinquent
      isFraudulent
      fraudulentAdditionalInfo
      created
    }
  }
`;
