import { TFunction } from '@getpopsure/i18n-react';
import { DocumentSection } from 'components/DocumentsSection/DocumentsSection';

import { CompanyHealthPolicy } from '../models/CompanyHealthPolicy';

export const getDocumentSections =
  (t: TFunction) =>
  (data?: {
    hrCompanyHealthPolicy: CompanyHealthPolicy;
  }): DocumentSection[] => {
    const featherUploads: DocumentSection = {
      title: t(
        'admin.hr.companyHealth.featherUploads.label',
        'Feather uploads'
      ),
      documents: [],
    };

    data?.hrCompanyHealthPolicy.hrVisiblePolicyDocuments?.forEach(
      (document) => {
        featherUploads.documents.push({
          id: document.id,
          url: document.url,
          friendlyName: document.name,
          fileName: document.name,
          resizedUrl: document.resizedUrl,
        });
      }
    );

    return [featherUploads];
  };
