import { gql } from '@apollo/client';

export const CREATE_DOG_LIABILITY_CLAIM = gql`
  mutation CreateDogLiabilityClaim(
    $insuranceType: String!
    $userId: String!
    $policyId: String!
    $policyNumber: String
    $status: String!
    $claimType: [String!]!
    $eventDescription: String
    $amount: Float
    $iban: String
    $thirdPartyName: String
    $thirdPartyEmail: String
    $uploadDocument: Upload
    $dateOfEvent: String
  ) {
    createDogLiabilityClaim(
      insuranceType: $insuranceType
      userId: $userId
      policyId: $policyId
      policyNumber: $policyNumber
      status: $status
      claimType: $claimType

      eventDescription: $eventDescription
      amount: $amount
      iban: $iban
      thirdPartyName: $thirdPartyName
      thirdPartyEmail: $thirdPartyEmail
      uploadDocument: $uploadDocument
      dateOfEvent: $dateOfEvent
    ) {
      userPolicyId
      status
      user {
        id
        firstName
        lastName
      }
      claimType {
        id
        createdAt
        type
      }
      amount
      eventDescription
      iban
      dateOfEvent
    }
  }
`;
