import classNames from 'classnames';
import { CheckBox } from 'components/CheckBox';

import * as styles from '../styles';
import { TableCell } from '../TableCell/TableCell';
import { TableCellButton } from '../TableCell/TableCellButton';
import { TableCellCheck } from '../TableCell/TableCellCheck';
import { TableCellClaimNumber } from '../TableCell/TableCellClaimNumber';
import { TableCellDate } from '../TableCell/TableCellDate';
import { TableCellDot } from '../TableCell/TableCellDot';
import { TableCellFile } from '../TableCell/TableCellFile';
import { TableCellIconButton } from '../TableCell/TableCellIconButton';
import { TableCellLink } from '../TableCell/TableCellLink';
import { TableCellStatus } from '../TableCell/TableCellStatus';
import { TableCellText } from '../TableCell/TableCellText';
import { TableCellTextWithCheck } from '../TableCell/TableCellTextWithCheck';
import { CellConfig, HeaderConfig, RowConfig } from '../types';

const tableCellComponents: Record<Pick<CellConfig, 'type'>['type'], any> = {
  BUTTON: TableCellButton,
  CHECK: TableCellCheck,
  CLAIM_NUMBER: TableCellClaimNumber,
  DATE: TableCellDate,
  DOT: TableCellDot,
  FILE: TableCellFile,
  LINK: TableCellLink,
  STATUS: TableCellStatus,
  TEXT: TableCellText,
  TEXT_WITH_CHECK: TableCellTextWithCheck,
  ICON_BUTTON: TableCellIconButton,
};

type TableRowProps<T> = {
  rowIndex: number;
  headers: HeaderConfig[];
  checkboxProps?: CheckBoxProps;
  rowConfig: RowConfig;
  data: T;
};

type CheckBoxProps = {
  onCheckBoxClick: () => void;
  checked: boolean;
  onShiftCheckBoxClick: () => void;
  muted: boolean;
};

export const TableRow = <T,>({
  rowIndex,
  rowConfig,
  headers,
  data,
  checkboxProps,
}: TableRowProps<T>) => {
  return (
    <tr
      key={rowIndex}
      className={classNames(styles.tableRow, {
        [styles.clickable]:
          rowConfig.getResourceLink || rowConfig.resourceOnClick,
        [styles.checked]: checkboxProps?.checked,
      })}
    >
      {checkboxProps && (
        <td className={styles.checkboxWrapper}>
          <CheckBox
            checked={checkboxProps.checked}
            muted={checkboxProps.muted}
            setChecked={checkboxProps.onCheckBoxClick}
            onShiftClick={() => checkboxProps.onShiftCheckBoxClick?.()}
            dataTestId={`TABLE_ROW_SELECT_${rowIndex}`}
          />
        </td>
      )}
      {rowConfig.cells.map((cell, cellIndex) => {
        const Component = tableCellComponents[cell.type];

        return (
          <TableCell
            key={String(`${rowIndex}-${cellIndex}`)}
            header={headers[cellIndex]}
            textColor={cell.textColor}
            className={classNames({
              'relative z-10': cell.type === 'CHECK',
            })}
            resourceOnClick={
              rowConfig.resourceOnClick
                ? () => rowConfig.resourceOnClick?.(data)
                : undefined
            }
            getResourceLink={
              rowConfig.getResourceLink
                ? () => rowConfig.getResourceLink?.(data) ?? ''
                : undefined
            }
          >
            <Component
              header={headers[cellIndex]}
              data={data}
              {...cell.props}
            />
          </TableCell>
        );
      })}
    </tr>
  );
};
