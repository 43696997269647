import { InformationSection } from 'components/InformationCard';
import DateInformation from 'components/InformationCard/DateInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { PrivateHealthPolicy } from 'models/privateHealthPolicy';
import { getDeductible } from 'pages/policies/privateHealth/utils/mapDeductibleOptions';
import { policyMappers } from 'shared/insurances';

export const retrievePrivateHealthPolicyInfoRows = (
  policyInfo: PrivateHealthPolicy
): InformationSection[] => [
  {
    title: 'Policy information',
    boldTitle: false,
    rows: [
      {
        id: 'status',
        title: 'Status',
        data:
          policyMappers.PRIVATE_HEALTH?.statusMapper[
            policyInfo?.publicStatus
          ] ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
      {
        id: 'insuranceNumber',
        title: 'Policy number',
        data: policyInfo?.policyNumber ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
      {
        id: 'startDate',
        title: 'Start date',
        data: policyInfo?.startDate ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: false,
      },
      {
        id: 'tariff',
        title: 'Tariff',
        data: policyInfo?.currentTariffInfo?.tariff ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
      {
        id: 'deductible',
        title: 'Deductible',
        data:
          policyInfo?.currentTariffInfo?.deductible &&
          policyInfo.currentTariffInfo.tariff
            ? getDeductible(
                policyInfo.currentTariffInfo.tariff,
                policyInfo?.currentTariffInfo?.deductible
              )
            : '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
    ],
  },
];
