import { getFixedT } from '@getpopsure/i18n-react';
import { BadgeColor } from 'components/Badge/Badge';
import { PolicyStatus } from 'models/publicHealthStatus';

export type HRPublicHealthStatusId =
  | 'any'
  | 'received'
  | 'processing'
  | 'canceled'
  | 'covered'
  | 'contactfeather';

export type HRPublicHealthStatus = {
  id: HRPublicHealthStatusId;
  text: string;
};

const mapHRPublicHealthStatus = (
  status: PolicyStatus
): HRPublicHealthStatus => {
  const t = getFixedT();
  switch (status) {
    case 'APPLICATION_RECEIVED':
      return { id: 'received', text: t('admin.status.received', 'Received') };
    case 'APPLICATION_SENT':
      return {
        id: 'processing',
        text: t('admin.status.processing', 'Processing'),
      };
    case 'PROCESSING':
      return {
        id: 'processing',
        text: t('admin.status.processing', 'Processing'),
      };
    case 'COVERED':
      return { id: 'covered', text: t('admin.status.covered', 'Covered') };
    case 'ERR_MISSING_INFO':
      return { id: 'received', text: t('admin.status.received', 'Received') };
    case 'DROPPED_OUT':
      return { id: 'canceled', text: t('admin.status.canceled', 'Canceled') };
    case 'CANCELED':
      return { id: 'canceled', text: t('admin.status.canceled', 'Canceled') };
    case 'CANT_BE_COVERED':
      return { id: 'canceled', text: t('admin.status.canceled', 'Canceled') };
    default:
      return {
        id: 'contactfeather',
        text: t('admin.status.contactfeather', 'Contact Feather'),
      };
  }
};

const getHRPublicHealthStatusColor = (
  status: HRPublicHealthStatus
): BadgeColor => {
  const colors: { [K in HRPublicHealthStatusId]: BadgeColor } = {
    covered: 'green',
    processing: 'blue',
    received: 'purple',
    canceled: 'gray',
    contactfeather: 'gray',
    any: 'gray',
  };
  return colors[status.id];
};

export const getHRPublicHealthStatus = (status: PolicyStatus) => {
  const text = mapHRPublicHealthStatus(status);
  const color = getHRPublicHealthStatusColor(text);
  return { text, color };
};
