import { gql } from '@apollo/client';

export const CREATE_DATA_REMOVAL_REQUEST = gql`
  mutation CreateDataRemovalRequest($email: String!) {
    createDataRemovalRequest(email: $email) {
      id
      email
    }
  }
`;
