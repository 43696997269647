import { gql } from '@apollo/client';

export const UPDATE_CLAIM_PAYMENT_TRANSACTION = gql`
  mutation Mutation(
    $transactionId: String!
    $transactionStatus: String!
    $userPolicyId: String
    $userClaimId: String
    $note: String
    $iban: String
  ) {
    updateClaimPaymentTransaction(
      transactionId: $transactionId
      userPolicyId: $userPolicyId
      userClaimId: $userClaimId
      transactionStatus: $transactionStatus
      note: $note
      iban: $iban
    ) {
      id
      userClaim {
        id
        claimNumber
        refundAmount
        iban
        status
        customerPaidOn
        amount
      }
      userPolicy {
        id
        policyNumber
        user {
          firstName
          lastName
        }
      }
    }
  }
`;
