import classNames from 'classnames';

import { HeaderConfig } from '../types';
import * as styles from './styles';

interface ColumnHeaderProps {
  header: HeaderConfig;
}

const headerDefaults = {
  minWidth: 'min-w-[130px]',
  width: 'w-[130px]',
  sticky: false,
  threshold: '',
  border: 'none',
};

const ColumnHeader = ({ header }: ColumnHeaderProps) => {
  const headerConfig = { ...headerDefaults, ...header };
  const isLgScreen = window.innerWidth > 1024;

  return (
    <th
      key={headerConfig.id}
      scope="col"
      className={classNames(
        styles.columnHeader,
        headerConfig.minWidth,
        headerConfig.width,
        {
          [`${header.threshold} ${styles.sticky}`]:
            headerConfig.sticky && isLgScreen,
          [styles.borderRight]: headerConfig.border === 'right' && isLgScreen,
          [styles.borderLeft]: headerConfig.border === 'left' && isLgScreen,
        }
      )}
    >
      <div className={styles.contentWrapper}>
        <span>{headerConfig.label}</span>
      </div>
    </th>
  );
};

export default ColumnHeader;
