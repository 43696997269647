import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import { FormInputLabel } from 'components/FormInputLabel';
import Input from 'components/Input/Input';
import { SubmitHandler, useForm } from 'react-hook-form';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import { CREATE_REFERRAL } from './mutation';

interface ReferralCodeProps {
  policyId: string;
  userId: string;
  setOpen: (open: boolean) => void;
  modalOpen: boolean;
}

interface ReferralCodePayload {
  referralCode: string;
}

export const ReferralCode = ({
  policyId,
  userId,
  setOpen,
}: ReferralCodeProps) => {
  const { t } = useTranslation();

  const alertBannersState = useReactiveVar(alertBanners);

  const [createReferral, { loading }] = useMutation(CREATE_REFERRAL, {
    onCompleted: () => {
      setOpen?.(false);

      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message: t(
          'referral.form.alert.success',
          'Referral code added. The process will take a few minutes.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
    onError: () => {
      setOpen?.(false);

      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: t('referral.form.alert.error', 'Something went wrong.'),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ReferralCodePayload>();

  const submit: SubmitHandler<ReferralCodePayload> = ({ referralCode }) => {
    createReferral({
      variables: {
        input: {
          referralCode,
          policyIds: [policyId],
          userId,
        },
      },
    });
  };

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className="mt-2 flex-col space-y-4 text-sm text-gray-900"
    >
      <FormInputLabel
        htmlFor="referralCode"
        title={t('referral.form.code.label', 'Referral code')}
      />
      <Input
        id="title"
        color="gray"
        className="mt-2"
        placeholder={t(
          'referralCode.form.code.placeholder',
          'Referral code (8 characters long)'
        )}
        required
        {...register('referralCode', {
          minLength: {
            value: 8,
            message: t(
              'referralCode.form.code.error',
              'Referral code must be 8 characters long'
            ),
          },
          maxLength: {
            value: 8,
            message: t(
              'referralCode.form.code.error',
              'Referral code must be 8 characters long'
            ),
          },
        })}
      />
      {errors.referralCode && (
        <p className="text-sm text-red-600">{errors.referralCode.message}</p>
      )}
      <div className="mt-4 flex justify-end space-x-2">
        <Button
          buttonType="secondary"
          onClick={() => {
            setOpen?.(false);
          }}
          type="button"
        >
          Cancel
        </Button>
        <Button
          buttonType="primary"
          className="w-[140px]"
          type="submit"
          disabled={loading}
        >
          {t('referral.form.submit', 'Save')}
        </Button>
      </div>
    </form>
  );
};
