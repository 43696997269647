import { RowConfig } from 'components/TableRefactor/types';
import { HouseholdClaim } from 'pages/claims/household/models';
import { retrieveReadableClaimTypes } from 'pages/claims/template/components/ClaimsDetails/utils';
import {
  retrieveClaimsDetailsPath,
  statusMapping,
} from 'pages/claims/template/utils/utils';
import { policyMappers } from 'shared/insurances';

export const rowConfig: RowConfig = {
  getResourceLink: (data: HouseholdClaim) =>
    retrieveClaimsDetailsPath('household', data.id),
  cells: [
    {
      type: 'TEXT',
      textColor: 'dark',
      props: { getText: (data: HouseholdClaim) => data.user.firstName ?? '' },
    },
    {
      type: 'TEXT',
      textColor: 'dark',
      props: { getText: (data: HouseholdClaim) => data.user.lastName ?? '' },
    },
    {
      type: 'STATUS',
      textColor: 'dark',
      props: {
        getStatus: ({ status }: HouseholdClaim) =>
          statusMapping(
            status && policyMappers.HOUSEHOLD?.claim?.claimStatusMapper
              ? policyMappers.HOUSEHOLD.claim.claimStatusMapper[status]
              : '',
            status && policyMappers.HOUSEHOLD?.claim?.badgeColorMapper
              ? policyMappers.HOUSEHOLD.claim.badgeColorMapper[status]
              : 'blue'
          ),
      },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: {
        getText: (data: HouseholdClaim) =>
          data.claimType?.length
            ? retrieveReadableClaimTypes(
                data.claimType.length,
                policyMappers.HOUSEHOLD?.claim?.typeNameMapper[
                  data.claimType[0].type
                ] ?? ''
              )
            : '',
      },
    },
    {
      type: 'DATE',
      textColor: 'light',
      props: { getDate: (data: HouseholdClaim) => data.createdAt },
    },
    {
      type: 'DATE',
      textColor: 'light',
      props: { getDate: (data: HouseholdClaim) => data.customerPaidOn ?? '' },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: { getText: (data: HouseholdClaim) => data.claimNumber ?? '' },
    },
  ],
};
