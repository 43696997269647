import { gql } from '@apollo/client';

export const UPDATE_PRIVATE_HEALTH_CLAIM = gql`
  mutation UpdatePrivateHealthClaim(
    $claimId: String!
    $amount: Float!
    $claimType: [String!]
  ) {
    updatePrivateHealthClaim(
      claimId: $claimId
      amount: $amount
      claimType: $claimType
    ) {
      id
      amount
      claimType {
        id
        type
      }
    }
  }
`;

export const SUBMIT_PRIVATE_HEALTH_CLAIM = gql`
  mutation SubmitPrivateHealthClaim($claimId: String!, $comment: String) {
    submitPrivateHealthClaim(claimId: $claimId, comment: $comment)
  }
`;
