import { ApolloError } from '@apollo/client';
import { language, useTranslation } from '@getpopsure/i18n-react';
import LanguageSelector from 'components/LanguageSelector';
import OverviewPage from 'components/OverviewPage';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import Table from 'components/TableRefactor/Table';
import { EmptyStateMessage } from 'components/TableRefactor/TableEmptyState';
import {
  CellConfig,
  HeaderConfig,
  RowConfig,
  ViewConfig,
} from 'components/TableRefactor/types';
import { GET_PUBLIC_PROVIDER_POLICIES } from 'graphql/publicHealth/provider/queries';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { commissionEntitlementCutoffDate } from 'shared/commissionEntitlementCutoffDate';
import { getProviderPublicHealthStatus } from 'shared/mapProviderPublicHealthStatus';
import { mapUserOccupation } from 'shared/mapUserOccupation';

import { getFilterViewOptions } from './getFilterViewOptions';
import { getOccupations } from './getOccupations';
import { getStatus } from './getStatus';
import * as styles from './styles';

const NUMBER_OF_POLICIES_PER_PAGE = 12;

interface Data {
  providerPublicHealthPolicies: PublicHealthPolicy[];
  providerTotalPublicHealthPolicies: {
    totalPolicies: number;
    numberOfActivityDot: number;
  };
}

type ProviderPublicHealthPageParams = {
  provider?: string;
};

const ProviderPublicHealthPage = ({
  match,
}: RouteComponentProps<ProviderPublicHealthPageParams>) => {
  const [error, setError] = useState<ApolloError | undefined>();
  const { t } = useTranslation();
  const locale = language();

  const [filterStatusOptions, setFilterStatusOptions] = useState(getStatus());
  const [filterOccupationOptions, setFilterOccupationOptions] = useState(
    getOccupations()
  );
  const { provider } = match.params;

  const [filterViewOptions, setFilterViewOptions] = useState(
    getFilterViewOptions(provider ?? '')
  );

  useEffect(() => {
    setFilterStatusOptions(getStatus());
    setFilterOccupationOptions(getOccupations());
    setFilterViewOptions(getFilterViewOptions(provider ?? ''));
  }, [locale]);

  const tableFilters: FilterConfig[] = [
    {
      filterType: 'MULTIPLE',
      options: filterStatusOptions,
      label: t('admin.policytable.filterstatus.title', 'Status'),
      id: 'filterStatus',
    },
    {
      filterType: 'MULTIPLE',
      options: filterOccupationOptions,
      label: t('admin.policytable.filterOccupation.title', 'Occupation'),
      id: 'filterOccupation',
    },
  ];

  const viewConfig: ViewConfig = {
    id: 'filterView',
    options: filterViewOptions.map((viewOption) =>
      viewOption.id === 'action-pending'
        ? {
            ...viewOption,
            displayDot: (data: Data) =>
              !!data?.providerTotalPublicHealthPolicies.numberOfActivityDot,
          }
        : viewOption
    ),
    getGeneralActivityDot: (data: Data) =>
      !!data?.providerTotalPublicHealthPolicies.numberOfActivityDot,
  };

  const tableHeaders: HeaderConfig[] = [
    {
      id: 'activityDot',
      label: '',
      width: 'w-[40px]',
      minWidth: 'min-w-[40px]',
      sticky: true,
      threshold: 'left-0',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'firstname',
      label: t(
        'admin.provider.allpolicies.public.table.firstname.header',
        'First name'
      ),
      width: 'w-none',
      minWidth: 'min-w-[118px]',
      sticky: true,
      threshold: 'left-[40px]',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'lastname',
      label: t(
        'admin.provider.allpolicies.public.table.lastname.header',
        'Last name'
      ),
      width: 'w-none',
      minWidth: 'min-w-[120px]',
      sticky: true,
      threshold: 'left-[158px]',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'status',
      label: t(
        'admin.provider.allpolicies.public.table.status.header',
        'Status'
      ),
      width: 'w-[152px]',
      minWidth: 'min-w-[152px]',
      sticky: true,
      threshold: 'left-[278px]',
      border: 'right',
      component: ColumnHeader,
    },
    ...(provider === 'barmer'
      ? [
          {
            id: 'commission',
            label: t(
              'admin.provider.allpolicies.public.table.commission.header',
              'Commission'
            ),
            width: 'w-[100px]',
            minWidth: 'min-w-[100px]',
            component: ColumnHeader,
          },
        ]
      : []),
    {
      id: 'occupation',
      label: t(
        'admin.provider.allpolicies.public.table.occupation.header',
        'Occupation'
      ),
      width: 'w-[136px]',
      minWidth: 'min-w-[136px]',
      component: ColumnHeader,
    },
    {
      id: 'submitted',
      label: t(
        'admin.provider.allpolicies.public.table.createdon.header',
        'Created on'
      ),
      width: 'w-[160px]',
      minWidth: 'min-w-[160px]',
      component: SortableColumnHeader,
    },
    {
      id: 'updatedat',
      label: t(
        'admin.provider.allpolicies.public.table.updatedon.header',
        'Updated on'
      ),
      width: 'w-[155px]',
      minWidth: 'min-w-[155px]',
      component: SortableColumnHeader,
    },
    {
      id: 'egk',
      label: t('admin.provider.allpolicies.public.table.egk.header', 'eGK'),
      width: 'w-[64px]',
      minWidth: 'min-w-[64px]',
      component: ColumnHeader,
    },
    {
      id: 'kvnr',
      label: t('admin.provider.allpolicies.public.table.kvnr.header', 'KVNR'),
      width: 'w-[64px]',
      minWidth: 'min-w-[64px]',
      component: ColumnHeader,
    },
    {
      id: 'svnr',
      label: t('admin.provider.allpolicies.public.table.svnr.header', 'SVNR'),
      width: 'w-[64px]',
      minWidth: 'min-w-[64px]',
      component: ColumnHeader,
    },
  ];

  const defaultEmptyStateMessage: EmptyStateMessage = {
    title: t(
      'admin.policytable.emptystate.nopolicies.title',
      'There are no policies yet.'
    ),
    description: t(
      'admin.policytable.emptystate.nopolicies.description',
      'It looks like no employees have signed up with Feather.'
    ),
  };

  const commissionCell: CellConfig = {
    type: 'CHECK',
    textColor: 'light',
    props: {
      getCheck: (data: PublicHealthPolicy) => {
        if (
          data.publicStatus === 'COVERED' &&
          data.commissionEntitlement === 'NONE' &&
          !data.commissionId &&
          new Date(data.statusSetToCoveredAt ?? '') >=
            commissionEntitlementCutoffDate
        ) {
          return {
            check: 'exclamation',
            badgeCaption: t(
              'admin.provider.allpolicies.public.table.commission.exclamation.label',
              'Check policy activation and confirm commission'
            ),
          };
        }
        if (data.commissionEntitlement === 'ACCEPTED') {
          return {
            check: 'check',
            badgeCaption: t(
              'admin.provider.allpolicies.public.table.commission.check.label',
              'Commission confirmed and policy activated'
            ),
          };
        }
        if (data.commissionEntitlement === 'DECLINED') {
          return {
            check: 'cross',
            badgeCaption: t(
              'admin.provider.allpolicies.public.table.commission.cross.label',
              'Commission declined'
            ),
          };
        }
        return { check: 'empty' };
      },
    },
  };

  const rowConfig: RowConfig = {
    getResourceLink: (data: PublicHealthPolicy) =>
      `/${provider}/public-health/policies/${data.id}`,
    cells: [
      {
        type: 'DOT',
        props: {
          getDot: (data: PublicHealthPolicy) => ({
            active: data.hasActivityDot,
            badgeColor: 'yellow',
            badgeCaption: t(
              'admin.provider.allpolicies.public.table.actionneeded.label',
              'Action needed'
            ),
          }),
        },
        textColor: 'dark',
      },
      {
        type: 'TEXT',
        props: {
          getText: (data: PublicHealthPolicy) =>
            data.insuredPerson.firstName ?? data.user.firstName ?? '',
        },
        textColor: 'dark',
      },
      {
        type: 'TEXT',
        props: {
          getText: (data: PublicHealthPolicy) =>
            data.insuredPerson.lastName ?? data.user.lastName ?? '',
        },
        textColor: 'dark',
      },
      {
        type: 'STATUS',
        props: {
          getStatus: (data: PublicHealthPolicy) =>
            getProviderPublicHealthStatus(data.publicStatus),
        },
        textColor: 'light',
      },
      ...(provider === 'barmer' ? [commissionCell] : []),
      {
        type: 'TEXT_WITH_CHECK',
        textColor: 'light',
        props: {
          getTextWithCheck: (data: PublicHealthPolicy) => {
            const occupationLabel = mapUserOccupation(
              data.occupation,
              data.salary,
              data.otherSituation
            );
            return {
              label: occupationLabel,
              check:
                data.occupation === 'UNIVERSITY_STUDENT'
                  ? data.studentChecklist?.completed
                    ? 'check'
                    : 'cross'
                  : 'empty',
            };
          },
        },
      },
      {
        type: 'DATE',
        props: {
          getDate: (data: PublicHealthPolicy) => data.createdAt,
        },
        textColor: 'light',
      },
      {
        type: 'DATE',
        props: {
          getDate: (data: PublicHealthPolicy) => data.updatedAt ?? '',
        },
        textColor: 'light',
      },
      {
        type: 'CHECK',
        props: {
          getCheck: (data: PublicHealthPolicy) => ({
            check: data.healthCard?.completed ? 'check' : 'cross',
          }),
        },
        textColor: 'light',
      },
      {
        type: 'CHECK',
        props: {
          getCheck: (data: PublicHealthPolicy) => ({
            check: data.kvnr ? 'check' : 'cross',
          }),
        },
        textColor: 'light',
      },
      {
        type: 'CHECK',
        props: {
          getCheck: (data: PublicHealthPolicy) => ({
            check: data.user.svnr ? 'check' : 'cross',
          }),
        },
        textColor: 'light',
      },
    ],
  };

  const isProviderTK = provider === 'tk';
  const searchPlaceholder = isProviderTK
    ? t(
        'policies.publicHealth.provider.tk.searchInput',
        'Search by name, email or TK API ID'
      )
    : t(
        'policies.publicHealth.provider.generic.searchInput',
        'Search by name or email'
      );

  return (
    <>
      <OverviewPage
        title="All Policies - Feather Admin Panel"
        current="policies-public"
        error={error}
      >
        <div className={styles.languageSelectorWrapper}>
          <LanguageSelector />
        </div>
        <Table
          title={t(
            'admin.provider.allpolicies.public.title',
            'Public health policies'
          )}
          itemsPerPage={NUMBER_OF_POLICIES_PER_PAGE}
          searchPlaceholder={searchPlaceholder}
          filterConfig={tableFilters}
          headers={tableHeaders}
          emptyStateLabel={defaultEmptyStateMessage}
          query={GET_PUBLIC_PROVIDER_POLICIES}
          rowConfig={rowConfig}
          onError={setError}
          queryVariables={{
            provider,
          }}
          viewConfig={viewConfig}
          countPath={['providerTotalPublicHealthPolicies', 'totalPolicies']}
        />
      </OverviewPage>
    </>
  );
};

export default ProviderPublicHealthPage;
