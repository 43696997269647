export type UserRole =
  | 'FEATHER_ADMIN'
  | 'HR_PARTNER'
  | 'PUBLIC_HEALTH_PROVIDER_AGENT'
  | 'PUBLIC_HEALTH_PROVIDER_ADMIN'
  | 'PUBLIC_HEALTH_PROVIDER_AGENT_PLUS';

export const mapUserRole = (role: UserRole) => {
  switch (role) {
    case 'FEATHER_ADMIN':
      return 'Feather Admin';
    case 'HR_PARTNER':
      return 'HR Partner';
    case 'PUBLIC_HEALTH_PROVIDER_AGENT':
      return 'Public Health Provider Agent';
    case 'PUBLIC_HEALTH_PROVIDER_ADMIN':
      return 'Public Health Provider Admin';
    case 'PUBLIC_HEALTH_PROVIDER_AGENT_PLUS':
      return 'Public Health Provider Agent Plus';
  }
};
