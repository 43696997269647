import classNames from 'classnames';

export const hidden = classNames('hidden');

export const tabsWrapper = classNames();

export const tabs = classNames(
  'mb-[32px]',
  'w-full',
  'max-w-[1200px]',
  'sticky',
  'z-10',
  'bg-gray-200'
);

export const nav = classNames(
  'flex',
  'justify-between',
  'border-b',
  'border-gray-300',
  'md:mx-[40px]',
  'mx-[20px]',
  'flex-col',
  'md:flex-row',
  'flex-col-reverse'
);

export const tabsInnerContainer = classNames('flex', 'space-x-8');

export const currentTab = classNames('border-indigo-500', 'text-indigo-500');

export const disabledTab = classNames(
  'border-transparent',
  'text-gray-500',
  'hover:text-gray-600',
  'hover:border-gray-500'
);

export const tab = classNames(
  'group',
  'inline-flex',
  'items-center',
  'py-4',
  'px-1',
  'border-b-2',
  'font-bold',
  'text-sm',
  'items-center',
  'space-x-2'
);

export const linksContainer = classNames('flex');

export const linkInnerContainer = classNames('flex', 'items-center');

export const icon = classNames('text-gray-500', 'w-[20px]');
