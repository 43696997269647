import { TFunction } from '@getpopsure/i18n-react';
import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import { PrivateHealthStatus } from 'shared/insurances/types';

export const getFilterStatusOptions = (
  t: TFunction
): SelectButtonOption<PrivateHealthStatus>[] => [
  {
    title: t('admin.hr.privateHealth.status.received.label', 'Received'),
    label: t('admin.hr.privateHealth.status.received.label', 'Received'),
    id: 'APPLICATION_RECEIVED',
  },
  {
    title: t('admin.hr.privateHealth.status.covered.label', 'Covered'),
    label: t('admin.hr.privateHealth.status.covered.label', 'Covered'),
    id: 'ACTIVE',
  },
  {
    title: t('admin.hr.privateHealth.status.processing.label', 'Processing'),
    label: t('admin.hr.privateHealth.status.processing.label', 'Processing'),
    id: 'ERR_MISSING_INFO',
  },
  {
    title: t('admin.hr.privateHealth.status.canceled.label', 'Canceled'),
    label: t('admin.hr.privateHealth.status.canceled.label', 'Canceled'),
    id: 'CANCELED',
  },
];

export const getTableFiltersConfig = (t: TFunction): FilterConfig[] => [
  {
    id: 'filterStatus',
    options: getFilterStatusOptions(t),
    filterType: 'MULTIPLE',
    label: t('admin.hr.privateHealth.table.filter.status.label', 'Status'),
  },
];
