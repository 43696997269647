import { useTranslation } from '@getpopsure/i18n-react';
import * as Sentry from '@sentry/react';

import { ValidAddressSchema } from '../TaskEngine.types';
import * as styles from './TaskPreviewModal.styles';

export const AddressPreview = ({ data }: { data: Record<string, unknown> }) => {
  const { t } = useTranslation();
  const address = ValidAddressSchema.safeParse(data);

  if (!address.success) {
    Sentry.captureMessage(
      `[TASK_ENGINE]: customer provided an invalid address. Error: ${address.error.message.toString()}`,
      'warning'
    );

    return (
      <div className={styles.row}>
        <p className={styles.rowText}>
          {t('taskEngine.taskPreviewModal.addressPreview.label', 'Address')}
        </p>
        <p className={styles.invalidAnswer}>
          {t(
            'taskEngine.taskPreviewModal.addressPreview.error',
            'The customer provided an invalid address'
          )}
        </p>
      </div>
    );
  }

  /**
   * The generic tasks do not nest the address inside an `address` object,
   * so we have to check if the address is nested or not
   */
  let parsedAddress;

  if ('address' in address.data) {
    parsedAddress = address.data.address;
  } else {
    parsedAddress = address.data;
  }

  const { street, houseNumber, city, postcode, additionalInformation } =
    parsedAddress;

  return (
    <div className={styles.row}>
      <p className={styles.rowText}>
        {t('taskEngine.taskPreviewModal.addressPreview.label', 'Address')}
      </p>
      <div>
        <p className={styles.rowText}>
          {street}, {houseNumber}
        </p>
        {additionalInformation && (
          <p className={styles.rowText}>{additionalInformation}</p>
        )}
        <p className={styles.rowText}>{postcode}</p>
        <p className={styles.rowText}> {city}</p>
      </div>
    </div>
  );
};
