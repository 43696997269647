import FileErrorCard from 'components/FileErrorCard';
import { TkApiRequest } from 'models/tkApiRequest';

interface TkApiRequestErrorsProps {
  requests: TkApiRequest[];
}

const TkApiRequestErrors = ({ requests }: TkApiRequestErrorsProps) => {
  return (
    <>
      {requests?.map((request) => {
        if (!request.response) {
          return <></>;
        }
        const { messages } = JSON.parse(request.response);
        const messageString = messages
          ?.map((message: { message: string }) => message.message)
          .join('. ');
        return (
          <FileErrorCard
            key={request.id}
            className="!mt-0 mb-4"
            open={true}
            title="TK API fails"
            errorType="WARNING"
            handleClose={() => {}}
            description={messageString}
            hideCloseButton={true}
          />
        );
      })}
    </>
  );
};

export default TkApiRequestErrors;
