import classNames from 'classnames';

export const tableWrapper = classNames('mx-[40px]', 'mb-[10px]');

export const tableDataPlaceholder = classNames(
  'bg-white',
  'w-full',
  'rounded-b-[8px]',
  'flex',
  'flex-col',
  'justify-center',
  'items-center',
  'h-[630px]',
  'pb-[48px]'
);

export const feathers = classNames('h-[80px]');

export const title = classNames('text-xl', 'text-gray-900', 'mt-[16px]');

export const text = classNames('text-sm', 'text-gray-600', 'mt-[4px]');
