import { useTranslation } from '@getpopsure/i18n-react';
import LoadingState from 'components/LoadingState';
import { client } from 'graphql/client';
import useLogin from 'hooks/useLogin';
import PageGenericError from 'pages/errors/genericError';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { paths } from 'routes/definedPaths';

const Logout = () => {
  const { t } = useTranslation();
  const { logout, loading, error } = useLogin();
  const history = useHistory();

  useEffect(() => {
    logout().then((success) => {
      if (success) {
        history.replace({
          pathname: paths.auth.path,
          state: { fromLogout: true },
        });
      }
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Logout - Feather Admin Panel</title>
      </Helmet>
      {loading && client.resetStore() && (
        <LoadingState
          message={t('admin.signin.loggingout.title', 'Logging out...')}
        />
      )}
      {error && <PageGenericError />}
    </>
  );
};

export default Logout;
