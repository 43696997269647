import classNames from 'classnames';

export const rowData = classNames(
  'text-sm',
  'text-gray-900',
  'flex',
  'justify-between',
  'w-full'
);

export const input = classNames('w-[264px]', '!px-[12px]', '!py-[9px]');

export const detailsButton = classNames(
  'ml-4',
  'font-normal',
  'text-indigo-500',
  'hover:text-indigo-600'
);
