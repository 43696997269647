import { GET_LIABILITY_POLICIES } from 'pages/policies/liability/graphql/policies';
import {
  LiabilityClaimStatus,
  LiabilityClaimType,
} from 'shared/claims/liability/models';

import { ClaimsTemplateData } from '../template/models';
import {
  retrieveAdditionalLiabilityClaimInfoRows,
  retrieveLiabilityClaimInfoRows,
} from './modules/claimsDetails/claimInfo/data';
import { UPDATE_LIABILITY_CLAIM } from './modules/claimsDetails/graphql/mutations';
import { GET_LIABILITY_CLAIM } from './modules/claimsDetails/graphql/queries';
import { retrieveLiabilityPolicyInfoRows } from './modules/claimsDetails/policyInfo/data';
import { retrieveCreateClaimFormData } from './modules/createClaim/data';
import { CREATE_LIABILITY_CLAIM } from './modules/createClaim/graphql/mutations';
import { zodSubmittableClaimSchema } from './modules/createClaim/models';
import { filters } from './modules/overview/data/filters';
import { rowConfig } from './modules/overview/data/tableData';
import { tableHeaders } from './modules/overview/data/tableHeaders';
import { GET_LIABILITY_CLAIMS } from './modules/overview/graphql/queries';
import {
  liabilityClaimStatusOptions,
  retrieveFormAndFooterTextByStatus,
} from './modules/statusUpdate/data';
import { UPDATE_LIABILITY_CLAIM_STATUS } from './modules/statusUpdate/graphql/mutations';

export const liabilityClaimsData: ClaimsTemplateData<
  LiabilityClaimStatus,
  LiabilityClaimType
> = {
  insuranceType: 'LIABILITY',

  // GraphQL -----------------------------------------
  queries: {
    fetchAllClaims: GET_LIABILITY_CLAIMS,
    fetchSingleClaim: GET_LIABILITY_CLAIM,
    fetchAllPolicies: GET_LIABILITY_POLICIES,
  },

  mutations: {
    updateClaim: UPDATE_LIABILITY_CLAIM,
    updateClaimStatus: UPDATE_LIABILITY_CLAIM_STATUS,
    createClaim: CREATE_LIABILITY_CLAIM,
  },

  // Features ----------------------------------------
  overview: {
    headers: tableHeaders,
    filterConfig: filters,
    rowConfig,
  },
  policyInfo: { retrievePolicyInfoRows: retrieveLiabilityPolicyInfoRows },
  claimInfo: {
    retrieveClaimInfoRows: retrieveLiabilityClaimInfoRows,
    retrieveAdditionalClaimInfoRows: retrieveAdditionalLiabilityClaimInfoRows,
  },
  statusUpdate: {
    dataByStatus: retrieveFormAndFooterTextByStatus,
    statusOptions: liabilityClaimStatusOptions,
  },
  claimCreation: {
    createClaimSubmissionSchema: zodSubmittableClaimSchema,
    retrieveFormData: retrieveCreateClaimFormData,
  },
};
