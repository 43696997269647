import classNames from 'classnames';

export const selectWrapper = classNames('relative');

export const selected = classNames(
  'relative',
  'w-full',
  'rounded-[6px]',
  'px-[8px]',
  'py-[4px]',
  'text-left',
  'cursor-default',
  'font-bold',
  'flex',
  'justify-between',
  'items-center'
);

export const selectedGrey = classNames(
  'bg-gray-300',
  'text-gray-600',
  'hover:bg-gray-400',
  'focus:bg-gray-400',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-indigo-500',
  'focus:ring-offset-2',
  'focus:ring-offset-white',
  'focus:border-indigo-500'
);

export const selectedWhite = classNames(
  'bg-white',
  'text-gray-600',
  'hover:bg-indigo-50',
  'focus:bg-indigo-50',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-indigo-500',
  'focus:ring-offset-2',
  'focus:ring-offset-white',
  'focus:border-indigo-500',
  'py-[8px]',
  'px-[12px]'
);

export const selectedTransparent = classNames(
  'bg-transparent',
  'text-indigo-500',
  'hover:text-indigo-600',
  'focus:text-indigo-600',
  'focus:outline-none'
);

export const selectedSmall = classNames('text-xs');
export const selectedBig = classNames('text-sm');

export const disabledSelected = classNames('pointer-events-none');

export const disabledIcon = classNames('text-gray-400');

export const icon = classNames('h-5', 'w-5');

export const optionsList = classNames(
  'absolute',
  'z-10',
  'right-0',
  'mt-2',
  'bg-white',
  'shadow-lg',
  'rounded-[6px]',
  'py-1',
  'overflow-auto',
  'focus:outline-none',
  'text-gray-600'
);

export const optionsListSmall = classNames('w-[150px]', 'text-xs');
export const optionsListBig = classNames('w-[190px]', 'text-sm');

export const listOption = classNames(
  'cursor-pointer',
  'relative',
  'py-[8px]',
  'pl-[16px]'
);

export const listOptionHover = classNames('text-gray-900');

export const listOptionText = classNames('font-normal', 'text-gray-600');

export const listOptionTextSelected = classNames('!text-gray-500');

export const listOptionTextHover = classNames('text-gray-900');
