/**
 * NOTE: This type is being shared with BE.
 * When updating, also update BE type
 *
 * Notion page for all vertical claim statuses:
 * https://www.notion.so/featherinsurance/Claim-statuses-2-0-22713a3fe92c49969b2e403f655678b2
 */
export const legalClaimStatuses = [
  'RECEIVED',
  'SUBMITTED_TO_PROVIDER',
  'CLOSED',
] as const;
export type LegalClaimStatus = typeof legalClaimStatuses[number];

export const legalClaimTypes = ['BOOK_A_CONSULTATION', 'LEGAL_CLAIM'] as const;
export type LegalClaimType = typeof legalClaimTypes[number];

export const legalPreferredTimeDateTypes = [
  'Morning_800_1200',
  'Noon_1200_1400',
  'Afternoon_1400_1800',
] as const;
export type LegalPreferredTimeDateType =
  typeof legalPreferredTimeDateTypes[number];

export const legalPreferredTimeDateMapper: Record<
  LegalPreferredTimeDateType,
  string
> = {
  Morning_800_1200: 'Morning: 8:00-12:00',
  Noon_1200_1400: 'Noon: 12:00-14:00',
  Afternoon_1400_1800: 'Afternoon: 14:00-18:00',
};

export const legalExpectedOutcomeTypes = [
  'UNDERSTANDING_LEGAL_OPTIONS',
  'CONTRACT',
  'PREVIOUS_CONSULTATION',
  'OTHER',
] as const;
export type LegalExpectedOutcomeType = typeof legalExpectedOutcomeTypes[number];

export const legalExpectedOutcomeTypeMapper: Record<
  LegalExpectedOutcomeType,
  string
> = {
  UNDERSTANDING_LEGAL_OPTIONS: 'Understanding my legal options',
  CONTRACT: 'Questions about a contract',
  PREVIOUS_CONSULTATION: 'I have questions about a previous consultation',
  OTHER: 'Other',
};
