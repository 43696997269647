import { changeLanguage } from '@getpopsure/i18n-react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { paths } from 'routes/definedPaths';

import useStateWithLocalStorage from './useStateWithLocalStorage';

export interface AuthValues {
  email: string;
  password: string;
}

interface LoginError {
  errorStatus?: number;
  message: string;
}

const useLogin = () => {
  const [auth, setAuth] = useState<boolean>(false);
  const [error, setErrorMessage] = useState<LoginError | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const { setValue: setUserInformation } =
    useStateWithLocalStorage('userInformation');

  useEffect(() => {
    if (auth) {
      history.push(paths.auth.loginSuccess.path);
    }
  }, [auth, history]);

  const login = async (values: AuthValues) => {
    if (error) setErrorMessage(null);

    try {
      setLoading(true);
      const { email, password } = values;
      const body = {
        email,
        password,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/login`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'CSRF-Token': window.localStorage.getItem('csrfToken') ?? '',
          },
          body: JSON.stringify(body),
          credentials: 'include',
        }
      );

      if (response.status === 401) {
        const errorMessage = (await response.json()).message;
        setErrorMessage({
          errorStatus: 401,
          message: errorMessage,
        });
        return;
      }

      if (response.status === 200) {
        setAuth(true);
        return;
      }

      setErrorMessage({
        errorStatus: response.status,
        message: response.statusText,
      });
    } catch (errorMessage) {
      setErrorMessage({
        message: errorMessage as string,
      });
    } finally {
      setLoading(false);
    }
  };

  const getAuthenticatedUser = async () => {
    if (error) setErrorMessage(null);

    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/users/me`,
        {
          credentials: 'include',
          headers: {
            'CSRF-Token': window.localStorage.getItem('csrfToken') ?? '',
          },
        }
      );

      if (response.status === 200) {
        const responseBody = await response.json();
        setUserInformation(JSON.stringify(responseBody));
        changeLanguage(responseBody.language ?? 'en');
        dayjs.locale(responseBody.language ?? 'en');
        return responseBody;
      }
      setErrorMessage({
        errorStatus: response.status,
        message: response.statusText,
      });
    } catch (errorMessage) {
      setErrorMessage({
        message: errorMessage as string,
      });
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    if (error) setErrorMessage(null);

    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/logout`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'CSRF-Token': window.localStorage.getItem('csrfToken') ?? '',
          },
        }
      );
      if (response.status === 200) {
        setUserInformation('');
        changeLanguage('en');
        dayjs.locale('en');
        return true;
      }

      setErrorMessage({
        errorStatus: response.status,
        message: response.statusText,
      });
      return false;
    } catch (errorMessage) {
      setErrorMessage({
        message: errorMessage as string,
      });
    } finally {
      setLoading(false);
    }
  };

  const loginGoogle = async () => {
    try {
      setLoading(true);
      window.open(
        `${process.env.REACT_APP_API_BASE_URL}/login/google`,
        '_self'
      );
    } catch (errorMessage) {
      setErrorMessage({
        message: errorMessage as string,
      });
    } finally {
      setLoading(false);
    }
  };

  return { login, loginGoogle, getAuthenticatedUser, logout, loading, error };
};

export default useLogin;
