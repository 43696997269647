import { BadgeColor } from 'components/Badge/Badge';
import ChangeStatus, {
  StatusChangeModalData,
} from 'components/ChangeStatus/ChangeStatus';
import { SelectButtonOption } from 'components/SelectButton';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';
import { Status } from 'models/statusColor';
import { GET_EXPAT_HEALTH_POLICY } from 'pages/policies/expatHealth/graphql/policy';
import { UPDATE_EXPAT_STATUS } from 'pages/policies/expatHealth/graphql/updateStatus';
import { z } from 'zod';

interface ChangeStatusActionProps {
  status: Status;
  colorMapper: Record<string, BadgeColor> | undefined;
  activeUntil?: string;
  policyId: string;
  startDate?: string;
  policyNumber?: string;
  price?: number;
}

const ChangeStatusAction = ({
  status,
  colorMapper,
  activeUntil,
  policyId,
  startDate,
  policyNumber,
  price,
}: ChangeStatusActionProps) => {
  const statusOptions: SelectButtonOption<string>[] = [
    {
      title: 'Pending',
      label: 'Pending',
      id: 'PENDING',
      color: 'purple',
    },
    {
      title: 'Covered',
      label: 'Covered',
      id: 'ACTIVE',
      color: 'green',
    },
    {
      title: 'Canceled',
      label: 'Canceled',
      id: 'CANCELED',
      color: 'gray',
    },
  ];

  const reinstatementDateOptions: { id: string; label: string }[] = Array.from(
    {
      length: 3,
    },
    (_, i) => {
      const followingMonth = dayjs()
        .add(i + 1, 'month')
        .date(1);
      return {
        label: followingMonth.format('DD MMMM YYYY'),
        id: followingMonth.format(DATE_FORMAT),
      };
    }
  );

  const modals: { [key: string]: StatusChangeModalData } = {
    PENDING: { showOpenIssues: false, formFields: [] },
    ACTIVE_AFTER_PENDING: {
      showOpenIssues: false,
      bottomDescription:
        'Feather confirmation of coverage will be generated upon confirmation.',
      formFields: [
        {
          id: 'startDate',
          data: {
            type: 'DATE',
            defaultValue: startDate,
          },
          required: true,
          label: 'Start date',
          description:
            'This is the date picked by the customer in the questionnaire. ',
          errorMessage: 'A start date is required.',
        },
        {
          id: 'policyNumber',
          data: {
            type: 'TEXT',
            placeholder: 'Enter policy number',
            defaultValue: policyNumber,
          },
          required: true,
          label: 'Policy number',
          errorMessage: 'A policy number is required.',
        },
        {
          id: 'file',
          data: {
            type: 'UPLOAD',
          },
          required: false,
          label: 'Confirmation of coverage from provider',
        },
      ],
    },
    ACTIVE_AFTER_CANCELED: {
      showOpenIssues: false,
      bottomDescription:
        'Policy and stripe subscription will be reinstated upon confirmation.',
      bottomDescriptionDependencies: [
        {
          questionId: 'paymentsCollected',
          requiredValue: true,
        },
        {
          questionId: 'subscriptionToReinstate',
          requiredValue: true,
        },
      ],
      formFields: [
        {
          id: 'policyNumber',
          data: {
            type: 'TEXT',
            placeholder: 'Enter policy number',
            defaultValue: policyNumber,
          },
          required: true,
          label: 'Policy number',
          errorMessage: 'A policy number is required.',
        },
        {
          id: 'subscriptionToReinstate',
          data: {
            type: 'RADIO',
            defaultValue: false,
            options: [
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ],
          },
          required: true,
          label: 'Reinstate the subscription?',
          errorMessage: 'Please select an option.',
        },
        {
          id: 'paymentsCollected',
          data: {
            type: 'RADIO',
            defaultValue: false,
            options: [
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ],
          },
          required: true,
          label: 'Did you manually collect all outstanding payments?',
          errorMessage:
            'You can’t reinstate the subscription if you haven’t collected all outstanding payments yet.',
          fieldDependencies: [
            {
              questionId: 'subscriptionToReinstate',
              requiredValue: true,
            },
          ],
        },
        {
          id: 'reinstateDate',
          data: {
            type: 'SELECT',
            options: reinstatementDateOptions,
            placeholder: 'Select a date',
          },
          required: true,
          label: 'Reinstate date',
          errorMessage: 'Please select a date.',
          fieldDependencies: [
            {
              questionId: 'paymentsCollected',
              requiredValue: true,
            },
            {
              questionId: 'subscriptionToReinstate',
              requiredValue: true,
            },
          ],
        },
        {
          id: 'price',
          data: {
            type: 'CURRENCY',
            defaultValue: price,
            placeholder: 'Select a price',
          },
          required: true,
          label: 'Price',
          errorMessage: 'Please select a price.',
          fieldDependencies: [
            {
              questionId: 'paymentsCollected',
              requiredValue: true,
            },
            {
              questionId: 'subscriptionToReinstate',
              requiredValue: true,
            },
          ],
        },
      ],
    },
    CANCELED: {
      showOpenIssues: false,
      formFields: [
        {
          id: 'activeUntil',
          data: {
            type: 'DATE',
            defaultValue: activeUntil,
          },
          required: true,
          label: 'Active until',
          errorMessage: 'An active until date is required.',
        },
      ],
      bottomDescription:
        "Stripe subscription will be automatically canceled if the policy hasn't started yet upon confirmation.",
    },
  };

  const statusChanges: { [key: string]: string[] } = {
    PENDING: ['PENDING', 'ACTIVE', 'CANCELED'],
    ACTIVE: ['ACTIVE', 'CANCELED'],
    CANCELED: ['ACTIVE', 'CANCELED'],
  };

  const currentStatusOption =
    statusOptions.find((statusOption) => statusOption.id === status.text.id) ??
    statusOptions[0];

  const extendedValidatorObject = { paymentsCollected: z.literal(true) };

  const retrieveModalId = (
    currentStatus: string,
    newStatus: string
  ): string => {
    return `${currentStatus}_AFTER_${newStatus}`;
  };

  return (
    <ChangeStatus
      status={currentStatusOption}
      policyId={policyId}
      statusOptions={statusOptions}
      modals={modals}
      mutation={UPDATE_EXPAT_STATUS}
      mutationOperationVariables={{
        refetchQueries: [GET_EXPAT_HEALTH_POLICY],
        awaitRefetchQueries: true,
        // Remove when global errorPolicy value is change to 'none' instead of 'all'
        errorPolicy: 'none',
      }}
      mutationVariables={{ newFilename: 'Confirmation of coverage - Provider' }}
      statusChangeOptions={statusChanges}
      extendedValidatorObject={extendedValidatorObject}
      retrieveModalId={retrieveModalId}
    />
  );
};

export default ChangeStatusAction;
