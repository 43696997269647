import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import {
  ExpatSpainClaimStatus,
  expatSpainClaimStatuses,
} from 'shared/claims/expatSpain/models';
import { statusNameMapper as expatSpainHealthStatusNameMapper } from 'shared/claims/expatSpain/statusNameMapper';

const statusOptions: SelectButtonOption<ExpatSpainClaimStatus>[] =
  expatSpainClaimStatuses.map((status) => ({
    id: status,
    label: expatSpainHealthStatusNameMapper[status],
    title: expatSpainHealthStatusNameMapper[status],
  }));

export const filters: FilterConfig[] = [
  {
    options: statusOptions,
    filterType: 'MULTIPLE',
    label: 'Status',
    id: 'filterStatus',
    placeholder: 'Status',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Created on',
    id: 'createdAt',
  },
];
