import classNames from 'classnames';
import TextArea from 'components/TextArea';

import { TextInformationProps } from '../TextInformation';
import * as styles from './styles';

const TextBoxInformation = ({
  cell,
  editMode,
  editable,
  value,
  handleChangeOnTextbox,
  id,
  className,
  placeholder,
  errorMessage,
  ...props
}: TextInformationProps) => {
  return editable && editMode ? (
    <TextArea
      className={styles.input}
      id={id}
      value={value}
      onChange={handleChangeOnTextbox}
      color="gray"
      placeholder={placeholder}
      error={!!errorMessage}
      errorMessage={errorMessage}
    />
  ) : (
    <span className={classNames(className, styles.rowData)}>
      {cell as string}
    </span>
  );
};

export default TextBoxInformation;
