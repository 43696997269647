import { PuzzleIcon } from '@heroicons/react/outline';
import { paths } from 'routes/definedPaths';

import { NavigationLink } from '../NavBar';

export const adminLinks: NavigationLink = {
  name: 'Admin',
  icon: PuzzleIcon,
  id: 'admin',
  children: [
    {
      name: 'Users',
      href: paths.adminUsers.internal.path,
      id: 'admin-users',
    },
    {
      name: 'Companies',
      href: paths.companies.internal.path,
      id: 'admin-companies',
    },
    {
      name: 'Task templates',
      href: paths.taskTemplates.path,
      id: 'admin-task-templates',
    },
  ],
};
