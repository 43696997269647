import { GET_PRIVATE_POLICIES } from 'pages/policies/privateHealth/graphql/queries';
import {
  PrivateHealthClaimStatus,
  PrivateHealthClaimType,
} from 'shared/claims/privateHealth/models';

import { ClaimsTemplateData } from '../template/models';
import { retrievePrivateHealthClaimInfoRows } from './modules/claimsDetails/claimInfo/data';
import {
  SUBMIT_PRIVATE_HEALTH_CLAIM,
  UPDATE_PRIVATE_HEALTH_CLAIM,
} from './modules/claimsDetails/graphql/mutations';
import { GET_PRIVATE_HEALTH_CLAIM } from './modules/claimsDetails/graphql/queries';
import { retrievePrivateHealthPolicyInfoRows } from './modules/claimsDetails/policyInfo/data';
import { retrievePrivateHealthCreateClaimFormData } from './modules/createClaim/data';
import { CREATE_PRIVATE_HEALTH_CLAIM } from './modules/createClaim/graphql/mutations';
import { ZSubmittablePrivateHealthClaimSchema } from './modules/createClaim/models';
import { filters } from './modules/overview/data/filters';
import { rowConfig } from './modules/overview/data/tableData';
import { privateHealthTableHeaders } from './modules/overview/data/tableHeaders';
import { GET_PRIVATE_HEALTH_CLAIMS } from './modules/overview/graphql/queries';
import {
  privateHealthClaimStatusOptions,
  retrieveFormAndFooterTextByStatus,
} from './modules/statusUpdate/data';
import { UPDATE_PRIVATE_HEALTH_CLAIM_STATUS } from './modules/statusUpdate/graphql/mutations';

export const privateHealthClaimsData: ClaimsTemplateData<
  PrivateHealthClaimStatus,
  PrivateHealthClaimType
> = {
  insuranceType: 'PRIVATE_HEALTH',

  // GraphQL -----------------------------------------
  queries: {
    fetchAllClaims: GET_PRIVATE_HEALTH_CLAIMS,
    fetchSingleClaim: GET_PRIVATE_HEALTH_CLAIM,
    fetchAllPolicies: GET_PRIVATE_POLICIES,
  },

  mutations: {
    createClaim: CREATE_PRIVATE_HEALTH_CLAIM,
    updateClaim: UPDATE_PRIVATE_HEALTH_CLAIM,
    updateClaimStatus: UPDATE_PRIVATE_HEALTH_CLAIM_STATUS,
    submitClaim: SUBMIT_PRIVATE_HEALTH_CLAIM,
  },

  // Features ----------------------------------------
  overview: {
    headers: privateHealthTableHeaders,
    filterConfig: filters,
    rowConfig,
  },
  claimCreation: {
    createClaimSubmissionSchema: ZSubmittablePrivateHealthClaimSchema,
    retrieveFormData: retrievePrivateHealthCreateClaimFormData,
  },
  policyInfo: { retrievePolicyInfoRows: retrievePrivateHealthPolicyInfoRows },
  claimInfo: {
    retrieveClaimInfoRows: retrievePrivateHealthClaimInfoRows,
  },
  statusUpdate: {
    dataByStatus: retrieveFormAndFooterTextByStatus,
    statusOptions: privateHealthClaimStatusOptions,
  },
};
