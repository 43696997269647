import { useEffect, useState } from 'react';

const useStateWithLocalStorage = (localStorageKey: string) => {
  const [value, setValue] = useState(
    localStorage.getItem(localStorageKey) || ''
  );

  useEffect(() => {
    localStorage.setItem(localStorageKey, value);
    window.dispatchEvent(new Event('storage'));
  }, [value]);

  return { value, setValue };
};

export default useStateWithLocalStorage;
