import { useTranslation } from '@getpopsure/i18n-react';
import {
  DocumentSection,
  DocumentsSection as DocumentsCard,
} from 'components/DocumentsSection/DocumentsSection';
import { SelectMenuOption } from 'components/SelectMenu';
import {
  EDIT_PROVIDER_POLICY_FILE,
  UPLOAD_PROVIDER_POLICY_DOCUMENT,
} from 'graphql/publicHealth/provider/mutations';
import { GET_PUBLIC_PROVIDER_POLICY } from 'graphql/publicHealth/provider/queries';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import { EditButton } from 'pages/policies/template/EditButton/EditButton';
import { DELETE_POLICY_DOCUMENT } from 'pages/policies/template/mutations';
import { UploadButton } from 'pages/policies/template/UploadButton/UploadButton';
import { retrieveFileFriendlyName } from 'shared/utils/retrieveFileFriendlyName';

interface DocumentsSectionProps {
  data: PublicHealthPolicy;
}

const DocumentsSection = ({ data }: DocumentsSectionProps) => {
  const { t } = useTranslation();
  const documents: DocumentSection[] = [
    {
      title: t('admin.documents.category.feather.title', 'Feather uploads'),
      documents: [
        ...(data.providerVisiblePolicyDocuments
          ?.filter((document) => !document.isFromProvider)
          .map((document) => {
            return {
              ...document,
              friendlyName: document.name,
              fileName: document.blobName
                ? retrieveFileFriendlyName(document.blobName)
                : document.name,
              canDelete: document.isFromProvider,
              canEdit: document.isFromProvider,
            };
          }) ?? []),
        // TODO: Update to get full document object instead of only url
        ...(data.powerOfAttorneyUrl
          ? [
              {
                id: '',
                url: data.powerOfAttorneyUrl,
                fileName: 'Power of attorney',
              },
            ]
          : []),
      ],
    },
    {
      title: t('admin.documents.category.customer.title', 'Customer uploads'),
      documents: [
        // TODO: Update to get full document object instead of only url
        ...(data.healthCard?.photoUrl
          ? [
              {
                id: '',
                url: data.healthCard?.photoUrl,
                fileName: 'Health card photo',
              },
            ]
          : []),
        ...(data.studentChecklist?.uniEnrollmentCertificateUrl
          ? [
              {
                id: '',
                url: data.studentChecklist?.uniEnrollmentCertificateUrl,
                fileName: t(
                  'admin.documents.type.enrollmentcertificate',
                  'Enrollment certificate'
                ),
              },
            ]
          : []),
        ...(data.studentChecklist?.acceptanceLetterUrl
          ? [
              {
                id: '',
                url: data.studentChecklist?.acceptanceLetterUrl,
                fileName: t(
                  'admin.documents.type.acceptanceLetter',
                  'Acceptance letter'
                ),
              },
            ]
          : []),
      ],
    },
    {
      title: t('admin.documents.category.provider.title', 'Provider uploads'),
      documents:
        data.providerVisiblePolicyDocuments
          ?.filter((document) => document.isFromProvider)
          .map((document) => {
            return {
              ...document,
              friendlyName: document.name,
              fileName: document.blobName
                ? retrieveFileFriendlyName(document.blobName)
                : document.name,
              canDelete: document.isFromProvider,
              canEdit: document.isFromProvider,
            };
          }) ?? [],
    },
  ];

  const fileTypeOptions: SelectMenuOption[] = [
    {
      id: 'CONFIRMATION_OF_COVERAGE_FROM_PROVIDER',
      label: t(
        'admin.documents.type.finalconfirmation',
        'Final confirmation of coverage'
      ),
    },
    {
      id: 'PRELIMINARY_CONFIRMATION_OF_COVERAGE_FROM_PROVIDER',
      label: t(
        'admin.documents.type.prelimconfirmation',
        'Prelim. confirmation of coverage'
      ),
    },
    {
      id: 'DEPENDENT_CONFIRMATION_OF_COVERAGE',
      label: t(
        'admin.documents.type.dependentConfirmation',
        'Dependent confirmation of coverage'
      ),
    },
    {
      id: 'OTHER',
      label: t('admin.documents.type.other.label', 'Other'),
    },
  ];

  return (
    <>
      <DocumentsCard
        sections={documents}
        documentCategories={fileTypeOptions}
        getResourceQuery={GET_PUBLIC_PROVIDER_POLICY}
        resourceId={data.id}
        deleteMutation={DELETE_POLICY_DOCUMENT}
        documentVisibilityOptions={[{ id: 'CUSTOMER', label: 'Customer' }]}
        editMutation={EDIT_PROVIDER_POLICY_FILE}
        displayInternalNotes={false}
        uploadButton={
          <UploadButton
            mutation={UPLOAD_PROVIDER_POLICY_DOCUMENT}
            documentCategories={fileTypeOptions}
            resourceId={data.id}
            documentVisibilityOptions={[{ id: 'CUSTOMER', label: 'Customer' }]}
            getResourceQuery={GET_PUBLIC_PROVIDER_POLICY}
            displayInternalNotes={false}
          />
        }
        editButton={EditButton}
      />
    </>
  );
};

export default DocumentsSection;
