import { useMutation, useReactiveVar } from '@apollo/client';
import { Trans, useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import { DEACTIVATE_REFERRAL_CODE } from '../../graphql/mutations';
import { GET_REFERRAL_CODES } from '../../graphql/queries';
import { ReferralCode } from '../../types';

interface DeactivateReferralCodeProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  referralCode: ReferralCode;
}

const DeactivateReferralCodeModal = ({
  open,
  setOpen,
  referralCode,
}: DeactivateReferralCodeProps) => {
  const { t } = useTranslation();

  const alertBannersState = useReactiveVar(alertBanners);

  const [deactivateReferralCode, { loading }] = useMutation(
    DEACTIVATE_REFERRAL_CODE,
    {
      refetchQueries: [GET_REFERRAL_CODES],
      onCompleted: () => {
        setOpen(false);

        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'SUCCESS',
          message: t(
            'referralCodes.deactivateReferralCode.alert.success',
            'Referral code deactivated.'
          ),
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
      onError: () => {
        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'WARNING',
          message: 'Something went wrong. Please try again.',
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
    }
  );

  const handleSubmit = async () => {
    deactivateReferralCode({
      variables: { referralCode: referralCode.referralCode },
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={t(
        'referralCodes.deactivateReferralCode.modal.title',
        'Deactivate referral code'
      )}
      loading={loading}
      textLoading={t(
        'referralCode.deactivateReferralCode.modal.textLoading',
        'Deactivating referral code...'
      )}
      hideActions
    >
      <div>
        <div className={classNames('text-gray-600', 'text-sm')}>
          <Trans
            key="referralCodes.deactivateReferralCodes.delete.confirm"
            referralCode={referralCode?.referralCode ?? ''}
          >
            Do you really want to deactivate this referral code? "
            {{ referralCode: referralCode?.referralCode }}"
          </Trans>
        </div>
        <div className={classNames('flex', 'justify-end', 'mt-[24px]')}>
          <Button
            type="button"
            buttonType="secondary"
            onClick={() => setOpen(false)}
          >
            {t('admin.modal.cancel.action', 'Cancel')}
          </Button>
          <Button
            loading={loading}
            type="submit"
            buttonType="primary"
            className={classNames('ml-2', 'min-w-[140px]')}
            onClick={handleSubmit}
          >
            {t(
              'referralCodes.deactivateReferralCode.delete.action',
              'Deactivate'
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeactivateReferralCodeModal;
