import { gql } from '@apollo/client';

export const UPDATE_EXPAT_SPAIN_POLICY = gql`
  mutation UpdateExpatSpainPolicy(
    $insuranceType: String!
    $id: String!
    $policyNumber: String!
    $providerPolicyNumber: String
    $startDate: String!
    $arrivalDate: String!
    $price: Float!
    $source: String
    $campaign: String
    $medium: String
    $term: String
    $content: String
  ) {
    updateExpatSpainPolicyInfo(
      insuranceType: $insuranceType
      id: $id
      policyNumber: $policyNumber
      providerPolicyNumber: $providerPolicyNumber
      startDate: $startDate
      arrivalDate: $arrivalDate
      price: $price
      source: $source
      campaign: $campaign
      medium: $medium
      content: $content
      term: $term
    ) {
      id
      policyNumber
      providerPolicyNumber
      startDate
      arrivalDate
      price
      source
      campaign
      medium
      content
      term
    }
  }
`;
