type LogoColor = 'white' | 'dark';

const Logo = ({
  className,
  color,
}: {
  className?: string;
  color: LogoColor;
}) => (
  <svg
    width="456"
    height="144"
    viewBox="0 0 456 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className ?? ''}
  >
    <path
      d="M131.379 71.1593H126C124.343 71.1593 123 69.8161 123 68.1593V65.7798C123 64.1229 124.343 62.7798 126 62.7798H131.379V56.6464C131.379 50.0234 132.906 44.9266 135.958 41.356C139.068 37.7853 143.531 36 149.348 36C151.018 36 152.717 36.1728 154.445 36.5183C155.338 36.6625 156.16 36.8427 156.909 37.0589C158.152 37.4175 158.828 38.6804 158.668 39.9635L158.37 42.3457C158.13 44.2662 156.156 45.4456 154.254 45.0873C152.972 44.8458 151.682 44.725 150.384 44.725C147.217 44.725 144.913 45.7617 143.474 47.8349C142.034 49.9082 141.314 53.1909 141.314 57.683V62.7798H150.926C152.583 62.7798 153.926 64.1229 153.926 65.7798V68.1593C153.926 69.8161 152.583 71.1593 150.926 71.1593H141.314V103.405C141.314 105.062 139.971 106.405 138.314 106.405H134.379C132.723 106.405 131.379 105.062 131.379 103.405V71.1593Z"
      fill={color === 'dark' ? '#4C4C53' : '#ffff'}
    />
    <path
      d="M260.697 71.1593H254.54C252.883 71.1593 251.54 69.8161 251.54 68.1593V65.7798C251.54 64.1229 252.883 62.7798 254.54 62.7798H260.697V44.9607C260.697 43.3038 262.04 41.9607 263.697 41.9607H267.632C269.288 41.9607 270.632 43.3038 270.632 44.9607V62.7798H278.775C280.432 62.7798 281.775 64.1229 281.775 65.7798V68.1593C281.775 69.8161 280.432 71.1593 278.775 71.1593H270.632V103.405C270.632 105.062 269.288 106.405 267.632 106.405H263.697C262.04 106.405 260.697 105.062 260.697 103.405V71.1593Z"
      fill={color === 'dark' ? '#4C4C53' : '#ffff'}
    />
    <path
      d="M286.462 44.6151C286.462 42.9583 287.805 41.6151 289.462 41.6151H293.396C295.053 41.6151 296.396 42.9583 296.396 44.6151V67.8766C297.663 65.8033 299.42 64.2196 301.666 63.1253C303.97 61.9735 306.619 61.3976 309.613 61.3976C314.854 61.3976 318.857 63.0102 321.621 66.2353C324.443 69.4028 325.854 73.9812 325.854 79.9707V103.405C325.854 105.062 324.511 106.405 322.854 106.405H318.92C317.263 106.405 315.92 105.062 315.92 103.405V81.7848C315.92 77.8686 315.171 74.9603 313.674 73.0598C312.234 71.1593 309.988 70.209 306.936 70.209C303.595 70.209 301.004 71.3033 299.161 73.4917C297.318 75.6802 296.396 78.7325 296.396 82.6487V103.405C296.396 105.062 295.053 106.405 293.396 106.405H289.462C287.805 106.405 286.462 105.062 286.462 103.405V44.6151Z"
      fill={color === 'dark' ? '#4C4C53' : '#ffff'}
    />
    <path
      d="M380.788 65.7798C380.788 64.1229 382.132 62.7798 383.788 62.7798H387.326C388.969 62.7798 390.307 64.1015 390.326 65.7445L390.377 70.1226C391.587 67.531 393.257 65.5442 395.388 64.162C397.576 62.7798 400.081 62.0887 402.903 62.0887C403.652 62.0887 404.516 62.1751 405.495 62.3479C405.621 62.37 405.744 62.3931 405.865 62.4172C407.162 62.6755 407.909 63.9393 407.774 65.2553L407.464 68.307C407.258 70.3211 405.122 71.5624 403.105 71.3856C402.698 71.3499 402.285 71.3321 401.867 71.3321C398.354 71.3321 395.618 72.6278 393.66 75.2194C391.702 77.7534 390.723 81.2953 390.723 85.845V103.405C390.723 105.062 389.38 106.405 387.723 106.405H383.788C382.132 106.405 380.788 105.062 380.788 103.405V65.7798Z"
      fill={color === 'dark' ? '#4C4C53' : '#ffff'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M240.003 100.847C236.175 104.302 231.103 106.405 225.54 106.405C213.613 106.405 203.944 96.736 203.944 84.8085C203.944 72.881 213.613 63.2119 225.54 63.2119C231.103 63.2119 236.175 65.3152 240.003 68.7698L240.111 66.092C240.175 64.4831 241.498 63.2119 243.108 63.2119H245.864C247.521 63.2119 248.864 64.5551 248.864 66.2119V103.405C248.864 105.062 247.521 106.405 245.864 106.405H243.108C241.498 106.405 240.175 105.134 240.111 103.525L240.003 100.847ZM238.831 84.8085C238.831 92.1485 232.88 98.0987 225.54 98.0987C218.2 98.0987 212.25 92.1485 212.25 84.8085C212.25 77.4685 218.2 71.5183 225.54 71.5183C232.88 71.5183 238.831 77.4685 238.831 84.8085Z"
      fill={color === 'dark' ? '#4C4C53' : '#ffff'}
    />
    <path
      d="M374.989 84.1499C374.989 84.2564 374.989 84.3096 374.98 84.4304C374.868 85.8123 373.613 87.0668 372.231 87.1786C372.111 87.1884 372.001 87.1884 371.781 87.1884H340.353C340.956 90.4186 342.519 92.9917 345.042 94.9079C347.62 96.7694 350.801 97.7001 354.586 97.7001C357.671 97.7001 360.409 97.0514 362.799 95.7541C364.45 94.858 365.276 94.4099 365.722 94.3212C366.634 94.14 367.092 94.2225 367.882 94.7105C368.27 94.9494 368.71 95.4546 369.591 96.4651C370.241 97.2111 370.566 97.5842 370.757 98.0973C371.034 98.8422 370.925 100.011 370.515 100.692C370.232 101.16 369.945 101.386 369.371 101.839C367.945 102.962 366.335 103.882 364.541 104.598C361.524 105.803 358.124 106.405 354.339 106.405C349.512 106.405 345.262 105.447 341.587 103.531C337.912 101.615 335.06 98.9593 333.03 95.5649C331.056 92.1158 330.068 88.2286 330.068 83.9035C330.068 79.6332 331.028 75.8008 332.948 72.4064C334.923 68.9573 337.638 66.2746 341.093 64.3584C344.549 62.4422 348.443 61.4841 352.776 61.4841C357.054 61.4841 360.866 62.4422 364.212 64.3584C367.612 66.2198 370.245 68.8751 372.11 72.3243C374.03 75.7187 374.989 79.6605 374.989 84.1499ZM352.776 69.6964C349.485 69.6964 346.688 70.6818 344.384 72.6528C342.135 74.5689 340.764 77.1421 340.27 80.3723H365.199C364.76 77.1969 363.416 74.6237 361.168 72.6528C358.919 70.6818 356.122 69.6964 352.776 69.6964Z"
      fill={color === 'dark' ? '#4C4C53' : '#ffff'}
    />
    <path
      d="M200.489 84.1499C200.489 84.2564 200.489 84.3096 200.479 84.4304C200.367 85.8123 199.113 87.0668 197.731 87.1786C197.61 87.1884 197.5 87.1884 197.281 87.1884H165.852C166.455 90.4186 168.019 92.9917 170.542 94.9079C173.119 96.7694 176.301 97.7001 180.085 97.7001C183.17 97.7001 185.908 97.0514 188.299 95.7541C189.95 94.858 190.775 94.4099 191.222 94.3212C192.133 94.14 192.591 94.2225 193.382 94.7105C193.769 94.9494 194.209 95.4546 195.09 96.4651C195.741 97.2111 196.066 97.5842 196.257 98.0973C196.534 98.8422 196.424 100.011 196.014 100.692C195.731 101.16 195.444 101.386 194.871 101.839C193.445 102.962 191.835 103.882 190.04 104.598C187.024 105.803 183.623 106.405 179.838 106.405C175.012 106.405 170.761 105.447 167.086 103.531C163.411 101.615 160.559 98.9593 158.53 95.5649C156.555 92.1158 155.568 88.2286 155.568 83.9035C155.568 79.6332 156.528 75.8008 158.448 72.4064C160.422 68.9573 163.137 66.2746 166.593 64.3584C170.048 62.4422 173.942 61.4841 178.275 61.4841C182.553 61.4841 186.365 62.4422 189.711 64.3584C193.112 66.2198 195.744 68.8751 197.609 72.3243C199.529 75.7187 200.489 79.6605 200.489 84.1499ZM178.275 69.6964C174.984 69.6964 172.187 70.6818 169.883 72.6528C167.635 74.5689 166.263 77.1421 165.77 80.3723H190.698C190.26 77.1969 188.916 74.6237 186.667 72.6528C184.418 70.6818 181.621 69.6964 178.275 69.6964Z"
      fill={color === 'dark' ? '#4C4C53' : '#ffff'}
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="24"
      y="24"
      width="76"
      height="97"
    >
      <path
        d="M95.361 24C63.0377 37.8489 33.3033 90.0099 25.6279 113.402C23.923 118.599 22.4212 124.287 27.345 115.215C30.2962 109.777 37.5995 102.123 54.2535 99.3766C67.5767 97.1796 73.1556 94.3255 81.6999 84.6155C77.7608 86.0279 72.6729 88.9801 61.8116 85.5963C76.8726 86.9186 87.2512 77.9932 91.3061 65.2937C81.362 69.8054 72.9465 66.6486 70.742 65.488C84.3066 65.8294 95.7472 57.2021 98.4987 42.8333C100.103 34.4573 97.5333 26.7462 95.361 24Z"
        fill="#6160A2"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M92.586 25.2849C61.3498 40.8181 33.0831 90.681 25.6279 113.402C23.923 118.599 22.4212 124.287 27.345 115.215C30.2962 109.777 37.5995 102.123 54.2535 99.3766C65.3216 97.5515 71.0451 95.2729 77.564 88.987C78.7181 87.8743 79.328 85.5653 77.8039 86.0624C76.0372 86.6388 74.2168 84.2097 75.9172 83.46C82.0816 80.7418 86.7493 75.7572 89.6713 69.4885C90.4038 67.9172 88.742 66.363 87.0633 66.796C84.0409 67.5757 81.2347 67.6989 78.8083 67.496C76.7007 67.3198 77.011 64.7899 79.0658 64.2892C85.2864 62.7734 91.4961 59.6713 95.0714 55.778C98.3973 52.1562 97.4313 48.4073 98.4987 42.8333C99.7317 36.3943 98.4985 30.3482 96.8752 26.6486C96.1494 24.9943 94.2035 24.4805 92.586 25.2849Z"
        fill="#6160A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.518 43.6457C121.518 74.9947 96.4411 100.814 65.0104 100.814C33.5797 100.814 8.5025 74.9947 8.5025 43.6457C8.5025 12.2966 33.5797 -13.5223 65.0104 -13.5223C96.4411 -13.5223 121.518 12.2966 121.518 43.6457ZM65.0104 85.8136C87.9346 85.8136 106.518 66.9344 106.518 43.6457C106.518 20.3569 87.9346 1.47769 65.0104 1.47769C42.0862 1.47769 23.5025 20.3569 23.5025 43.6457C23.5025 66.9344 42.0862 85.8136 65.0104 85.8136Z"
        fill="#90DF9B"
      />
      <path
        d="M106.518 43.6456C106.518 66.9344 87.9346 85.8136 65.0104 85.8136C42.0862 85.8136 23.5025 66.9344 23.5025 43.6456C23.5025 20.3569 42.0862 1.47766 65.0104 1.47766C87.9346 1.47766 106.518 20.3569 106.518 43.6456Z"
        fill="#8E8CEE"
      />
    </g>
  </svg>
);

export default Logo;
