/* eslint-disable react/prop-types */
import Card from 'components/Card';
import { Fragment } from 'react';

import { InformationCardDate } from './components/InformationCardDate';
import { InformationCardLink } from './components/InformationCardLink';
import { InformationCardStatus } from './components/InformationCardStatus';
import { InformationCardText } from './components/InformationCardText';
import { InformationCardTextWithDetails } from './components/InformationCardTextWithDetails';
import { Row } from './components/Row';
import { Section } from './models';
import * as styles from './styles';

interface Props {
  sections: Section[];
}

export const InformationCardV2 = ({ sections }: Props) => (
  <Card title={sections[0].title} boldTitle>
    {sections.map(({ title: sectionTitle, rows }, idx) => (
      <Fragment key={sectionTitle}>
        {idx !== 0 && <h3 className={styles.sectionTitle}>{sectionTitle}</h3>}

        {rows.map(({ type, label, props }) => {
          if (type === 'TEXT') {
            const { initialValue } = props;

            return (
              <Row label={label} key={label}>
                <InformationCardText value={initialValue} />
              </Row>
            );
          }

          if (type === 'DATE') {
            const { initialValue } = props;

            return (
              <Row label={label} key={label}>
                <InformationCardDate value={initialValue} />
              </Row>
            );
          }

          if (type === 'LINK') {
            const { title, href } = props;

            return (
              <Row label={label} key={label}>
                <InformationCardLink title={title} href={href} />
              </Row>
            );
          }

          if (type === 'STATUS') {
            const { initialValue, badgeColor } = props;

            return (
              <Row label={label} key={label}>
                <InformationCardStatus
                  value={initialValue}
                  badgeColor={badgeColor}
                />
              </Row>
            );
          }

          if (type === 'TEXT_WITH_DETAILS') {
            const {
              initialValue,
              renderModalDetails,
              detailsLabel,
              detailsTitle,
            } = props;

            return (
              <Row label={label} key={label}>
                <InformationCardTextWithDetails
                  value={initialValue}
                  detailsLabel={detailsLabel}
                  detailsTitle={detailsTitle}
                  renderModalDetails={renderModalDetails}
                />
              </Row>
            );
          }

          return <></>;
        })}
      </Fragment>
    ))}
  </Card>
);
