import { Redirect } from 'models/routes';
import { generatePath } from 'react-router';

import { paths } from './definedPaths';

const redirects: Redirect[] = [
  {
    from: '/internal/public-health/policies',
    to: paths.policies.publicHealth.internal.path,
  },
  {
    from: '/internal/public-health/policies/:id',
    to: paths.policies.publicHealth.internal.details.path,
  },
  {
    from: '/internal/public-health/issues',
    to: paths.policies.publicHealth.internal.issues.path,
  },
  {
    from: '/:provider/public-health/policies',
    to: paths.policies.publicHealth.provider.path,
  },
  {
    from: '/:provider/public-health/policies/:id',
    to: paths.policies.publicHealth.provider.details.path,
  },
  {
    from: '/internal/private-health/policies',
    to: paths.policies.privateHealth.internal.path,
  },
  {
    from: '/internal/private-health/policies/:id',
    to: paths.policies.privateHealth.internal.details.path,
  },
  {
    from: '/internal/expat-health/policies',
    to: paths.policies.expatHealth.internal.path,
  },
  {
    from: '/internal/expat-health/policies/:id',
    to: paths.policies.expatHealth.internal.details.path,
  },
  {
    from: '/internal/:insuranceType/policies',
    to: generatePath(paths.policies.internal.path, {
      country: 'de',
      insuranceType: ':insuranceType',
    }),
  },
  {
    from: '/internal/:insuranceType/policies/:id',
    to: generatePath(paths.policies.internal.details.path, {
      country: 'de',
      insuranceType: ':insuranceType',
      id: ':id',
    }),
  },
  {
    from: '/internal/:insurancePathName/claims',
    to: generatePath(paths.claims.internal.path, {
      country: 'de',
      insurancePathName: ':insurancePathName',
    }),
  },
  {
    from: '/internal/:insurancePathName/claims/:id',
    to: generatePath(paths.claims.internal.details.path, {
      country: 'de',
      insurancePathName: ':insurancePathName',
      id: ':id',
    }),
  },
];

export default redirects;
