import { SelectMenuOption } from 'components/SelectMenu';
import { ClaimFileType, ClaimFileVisibilityTarget } from 'models/Claim';
import { ClaimDocumentCategory } from 'pages/claims/template/models';

export const typeOptions: SelectMenuOption<ClaimFileType>[] = [
  {
    id: 'FEATHER_UPLOADS',
    label: 'Feather uploads',
  },
  {
    id: 'CUSTOMER_UPLOADS',
    label: 'Customer uploads',
  },
  {
    id: 'PROVIDER_UPLOADS',
    label: 'Provider uploads',
  },
];

export const updatableTypeOptions: SelectMenuOption<ClaimFileType>[] = [
  {
    id: 'FEATHER_UPLOADS',
    label: 'Feather uploads',
  },
  {
    id: 'PROVIDER_UPLOADS',
    label: 'Provider uploads',
  },
];

export const visibilityOptions: SelectMenuOption<ClaimFileVisibilityTarget>[] =
  [
    {
      id: 'CUSTOMER',
      label: 'Customer',
    },
  ];

export const categoryOptions: SelectMenuOption<ClaimDocumentCategory>[] = [
  { id: 'CLAIM_PAYOUT', label: 'Claim payout' },
  { id: 'CLAIM_STATEMENT', label: 'Claim statement' },
  { id: 'INVOICE', label: 'Invoice' },
  { id: 'OTHER', label: 'Other' },
];
