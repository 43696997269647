import { useQuery } from '@apollo/client';
import { DetailsPage } from 'components/DetailsPage';
import LoadingState from 'components/LoadingState';
import NavBar from 'components/NavBar';
import { TabInformation } from 'components/Tabs';
import Page403 from 'pages/errors/403';
import PageGenericError from 'pages/errors/genericError';
import { RouteComponentProps, useHistory } from 'react-router';
import { paths } from 'routes/definedPaths';
import { checkUnauthenticatedErrors } from 'shared/errorHandling/unauthenticatedError';
import { checkUnauthorizedErrors } from 'shared/errorHandling/unauthorizedErrors';

import { GET_SD_CATEGORY_BY_ID } from '../sdCategories.queries';
import { SDCategory } from '../sdCategories.types';
import { DeleteCategory } from './OverviewTab/Delete/sdCategory.Delete';
import { OverviewTab } from './OverviewTab/sdCategory.OverviewTab';

type SDCategoryDetailsPageParams = {
  id?: string;
};

type GetScannedDocumentData = {
  sdCategory: SDCategory;
};

export const SDCategoryDetailsPage = ({
  match,
}: RouteComponentProps<SDCategoryDetailsPageParams>) => {
  const history = useHistory();

  const { data, error, loading } = useQuery<GetScannedDocumentData>(
    GET_SD_CATEGORY_BY_ID,
    {
      variables: {
        sdCategoryId: match.params.id,
      },
    }
  );

  const sdCategory = data?.sdCategory;
  if (error && checkUnauthenticatedErrors(error)) {
    history.push(paths.auth.path);
  }
  if (error && checkUnauthorizedErrors(error)) return <Page403 />;
  if (error) return <PageGenericError />;

  if (loading || !sdCategory) {
    return (
      <div className="flex h-screen w-screen">
        <NavBar current="scannedDocuments" />
        <LoadingState message="Loading data..." />
      </div>
    );
  }

  const tabs: TabInformation[] = [
    {
      name: 'Overview',
      href: '#overview',
      withDot: false,
    },
  ];

  const tabComponents = [
    {
      id: 'overview',
      component: OverviewTab,
      props: { sdCategory },
    },
  ];

  return (
    <DetailsPage
      windowTitle="Category details - Feather Admin Panel"
      pageTitle="Category"
      backLink={{ text: 'Back' }}
      tabs={tabs}
      tabComponents={tabComponents}
      loading={loading}
      current="scannedDocuments"
      pageSubtitle={`"${sdCategory.name}"`}
      headerInfoTexts={[]}
      headerActions={
        <div className="flex items-center space-x-4">
          <div className="relative flex items-center">
            <DeleteCategory sdCategory={sdCategory} />
          </div>
        </div>
      }
      error={error}
    />
  );
};
