/**
 * NOTE: This type is not being shared with BE.
 * When updating, also update BE type
 */
export const householdAddOns = [
  'EXTENDED_COVERAGE',
  'BROKEN_GLASS_COVERAGE',
  'ADDITIONAL_BIKE_COVERAGE',
] as const;
export type HouseholdAddOn = typeof householdAddOns[number];

export const householdPlans = [
  'BASIC',
  'ADVANCED',
  'PREMIUM',
  'EXTENDED',
] as const;
export type HouseholdPlan = typeof householdPlans[number];

export const householdPlanMapper: Record<HouseholdPlan, string> = {
  BASIC: 'Basic',
  ADVANCED: 'Advanced',
  PREMIUM: 'Premium',
  EXTENDED: 'Extended',
};

export const householdAddOnMapper: Record<HouseholdAddOn, string> = {
  EXTENDED_COVERAGE: 'Extended coverage',
  BROKEN_GLASS_COVERAGE: 'Broken glass coverage',
  ADDITIONAL_BIKE_COVERAGE: 'Comprehensive bike coverage',
};
