import { gql } from '@apollo/client';

export const CREATE_REFERRAL_CODE = gql`
  mutation createReferralCode(
    $referralCode: String!
    $isDiscountCode: Boolean!
  ) {
    createReferralCode(
      referralCode: $referralCode
      isDiscountCode: $isDiscountCode
    ) {
      id
      referralCode
      isDiscountCode
      status
      createdAt
    }
  }
`;

export const UPDATE_REFERRAL_CODE = gql`
  mutation updateReferralCode($id: String!, $input: UpdateReferralCodeInput!) {
    updateReferralCode(id: $id, input: $input) {
      id
      referralCode
      isDiscountCode
      status
      createdAt
    }
  }
`;

export const DEACTIVATE_REFERRAL_CODE = gql`
  mutation deactivateReferralCode($referralCode: String!) {
    deactivateReferralCode(referralCode: $referralCode) {
      id
    }
  }
`;
