import { Gender } from '@popsure/public-models';
import { Claim } from 'models/Claim';
import { CombinedClaimStatus, CombinedClaimType } from 'shared/claims/models';
import { Country } from 'shared/countries/models';
import { InsuranceType } from 'shared/insurances/types';

import { SDCategory } from './categories/sdCategories.types';

export const SCANNED_DOCUMENTS_STATUSES = [
  'PENDING_UPLOAD',
  'NEW',
  'IN_PROGRESS',
  'WAITING',
  'DONE',
] as const;
export type ScannedDocumentStatus = typeof SCANNED_DOCUMENTS_STATUSES[number];

export type BasePolicy = {
  id: string;
  createdAt: string;
  policyNumber?: string;
  providerPolicyNumber?: string;
  dentalPublicStatus?: string;
  regionOfPurchase?: Country;
  user: {
    id: string;
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
    email: string;
    gender?: Gender;
    intercomEUId?: string;
    stripeToken?: string;
  };
  insuranceType?: InsuranceType;
  questionnaires?: {
    answers?: {
      personalInfo?: {
        name?: {
          firstName?: string;
          lastName?: string;
        };
      };
    };
  };
};

export type BasePolicyWithStatus = BasePolicy & {
  publicStatus: string;
};

export type ScannedDocumentSource = 'email' | 'n8n';

export type ScannedDocument = {
  archivedAt?: string;
  blobName: string;
  categories: SDCategory[];
  cayaId?: string;
  confirmed?: boolean;
  connectedUserClaims?: Claim<unknown, string>[];
  createdAt: string;
  documentDate?: string;
  friendlyName?: string;
  id: string;
  insuranceType?: string;
  meta?: {
    source?: ScannedDocumentSource;
  };
  policyNumber?: string;
  providerPolicyNumber?: string;
  processed?: boolean;
  recipientName?: string;
  relatedUserPolicy?: BasePolicy;
  userPolicy?: BasePolicy;
  senderName?: string;
  status?: ScannedDocumentStatus;
  subject?: string;
  textContent?: string;
  url?: string;
  userPolicyId?: string;
  visibleInUserAccount?: boolean;
};

export type CustomerIoTrackPayload = {
  customerId: string;
  requestData: {
    name: string;
    data: Record<string, any>;
  };
};

export type ClaimData = {
  id?: string;
  amount?: number;
  createdAt?: string;
  status?: CombinedClaimStatus;
  claimType?: {
    type: CombinedClaimType;
  }[];
  userPolicies?: BasePolicy;
};

export type RelatedClaimsData = {
  userClaimsBySimilarPolicyNumber?: ClaimData[];
};
