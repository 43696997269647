import { ChatAlt2Icon } from '@heroicons/react/outline';
import { paths } from 'routes/definedPaths';

import { NavigationLink } from '../NavBar';

export const issuesLinks: NavigationLink = {
  name: 'Issues',
  icon: ChatAlt2Icon,
  id: 'issues',
  children: [
    {
      name: 'Public health',
      href: paths.policies.publicHealth.internal.issues.path,
      id: 'issues-public',
    },
  ],
};
