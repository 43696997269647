import classNames from 'classnames';
import React from 'react';

import * as styles from './styles';

interface Props {
  label: string;
  children: React.ReactNode;
  shouldDisplayEditField?: boolean;
}

export const Row = ({
  label,
  children,
  shouldDisplayEditField = false,
}: Props) => (
  <div
    className={classNames({
      [styles.row]: !shouldDisplayEditField,
      [styles.rowEditMode]: shouldDisplayEditField,
    })}
  >
    <h4 className={styles.rowLabel}>{label}</h4>
    {children}
  </div>
);
