import { PrivateHealthQuestionnaireAnswers } from 'models/privateHealthQuestionnaire';
import { QuestionnaireAnswers } from 'models/questionnaire';
import omitDeep from 'omit-deep-lodash';
import { filterNullKeys } from 'shared/filterNullKeys';

export const getEditablePrivateQuestionnaireAnswers = (
  answers: QuestionnaireAnswers
) => {
  if (!answers) return answers;

  const privateAnswers = answers as PrivateHealthQuestionnaireAnswers;

  return {
    personalInfo: {
      ...omitDeep(filterNullKeys(privateAnswers.personalInfo), '__typename'),
    },
    financialHistory: {
      ...omitDeep(
        filterNullKeys(privateAnswers.financialHistory),
        '__typename'
      ),
    },
    insuranceHistory: {
      ...omitDeep(
        filterNullKeys(privateAnswers.insuranceHistory),
        '__typename'
      ),
    },
    medicalHistory: {
      ...omitDeep(filterNullKeys(privateAnswers.medicalHistory), '__typename'),
    },
    tariffInfo: {
      ...omitDeep(filterNullKeys(privateAnswers.tariffInfo), '__typename'),
    },
    ...omitDeep(privateAnswers, '__typename'),
  };
};
