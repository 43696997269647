import { TFunction } from '@getpopsure/i18n-react';
import { policyMappers } from 'shared/insurances';

const companyPensionStatusNameMapper = (
  t: TFunction
): Record<string, string> => ({
  PENDING: t('admin.hr.companyPension.status.pending.label', 'Pending'),
  ACTIVE: t('admin.hr.companyPension.status.covered.label', 'Covered'),
  CANCELED: t('admin.hr.companyPension.status.canceled.label', 'Canceled'),
  DROPPED_OUT: t(
    'admin.hr.companyPension.status.droppedOut.label',
    'Dropped out'
  ),
});

export const companyPensionStatusMapper =
  (t: TFunction) => (status: string) => ({
    text: {
      id: status,
      text: companyPensionStatusNameMapper(t)[status],
    },
    color: policyMappers.COMPANY_PENSION?.badgeColorMapper[status] ?? 'gray',
  });
