import { gql } from '@apollo/client';
import { POLICY_ACTIVITY } from 'graphql/publicHealth/fragments/policyActivity';

import { HEALTH_CARD } from '../fragments/healthCard';

export const GET_PUBLIC_PROVIDER_POLICIES = gql`
  query GetPublicHealthPolicies(
    $offset: Float!
    $limit: Float!
    $searchString: String!
    $sortColumn: String!
    $sortOrder: String!
    $filterStatus: [String]!
    $filterOccupation: [String]!
    $provider: String!
    $filterView: String!
  ) {
    providerPublicHealthPolicies(
      offset: $offset
      limit: $limit
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterStatus: $filterStatus
      filterOccupation: $filterOccupation
      provider: $provider
      filterView: $filterView
    ) {
      id
      providerId
      createdAt
      statusSetToCoveredAt
      user {
        id
        firstName
        lastName
        email
        dateOfBirth
        svnr
      }

      insuredPerson {
        firstName
        lastName
      }

      kvnr
      publicStatus
      startDate
      studentChecklist {
        completed
      }
      occupation
      source
      hasActivityDot
      updatedAt
      healthCard {
        id
        completed
      }
      commissionEntitlement
      commissionId
      tkApiId
    }
    providerTotalPublicHealthPolicies(
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterStatus: $filterStatus
      filterOccupation: $filterOccupation
      provider: $provider
      filterView: $filterView
    ) {
      totalPolicies
      numberOfActivityDot
    }
  }
`;

export const GET_PUBLIC_PROVIDER_POLICY = gql`
  ${HEALTH_CARD}
  ${POLICY_ACTIVITY}
  query GetPublicHealthPolicy($id: String!) {
    providerPublicHealthPolicy(id: $id) {
      id
      createdAt
      statusSetToCoveredAt
      providerId
      providerBranch
      startDate
      providerAgent {
        id
      }
      user {
        id
        firstName
        lastName
        dateOfBirth
        svnr
      }

      insuredPerson {
        firstName
        lastName
        gender
        dateOfBirth
      }

      kvnr
      publicStatus
      studentChecklist {
        completed
        uniEnrollmentCertificateUrl
        iban
        proofOfConfirmationOfCoverage
        acceptanceLetterUrl
        address {
          city
          street
          country
          postcode
          houseNumber
          additionalInformation
        }
      }
      occupation
      source
      hasActivityDot
      powerOfAttorneyUrl
      providerVisiblePolicyDocuments {
        id
        name
        url
        resizedUrl
        category
        isFromProvider
        isVisibleToCustomer
      }
      openIssues {
        id
        createdAt
        author {
          email
          company
          firstName
          lastName
        }
        description
        category
        status
      }
      questionnaire {
        answers
      }
      ...PolicyActivity
      ...HealthCard
      commissionEntitlement
      commissionId
    }
  }
`;
