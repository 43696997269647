import { SelectMenuOption } from 'components/SelectMenu';

import { ActionType, AdditionalFieldsAttributes } from './TaskEngine.types';

export const userActionsDropdownOptions: Array<SelectMenuOption<ActionType>> = [
  {
    id: 'INPUT',
    label: 'Text input',
  },
  {
    id: 'TEXTAREA',
    label: 'Long input',
  },
  {
    id: 'FILE',
    label: 'Multiple document upload',
  },
  {
    id: 'ADDRESS',
    label: 'Address input',
  },
  {
    id: 'DATE',
    label: 'Date picker',
  },
  {
    id: 'CURRENCY',
    label: 'Currency input',
  },
  {
    id: 'DOWNLOAD',
    label: 'Download document',
  },
  {
    id: 'LINK',
    label: 'Complete a flow',
  },
];

export const additionalUserActionFields: Record<
  ActionType,
  Array<AdditionalFieldsAttributes>
> = {
  INPUT: [
    {
      id: 'placeholder',
      name: 'placeholder',
      label: 'Placeholder',
      type: 'text',
      required: true,
    },
  ],
  TEXTAREA: [
    {
      id: 'placeholder',
      name: 'placeholder',
      label: 'Placeholder',
      type: 'text',
      required: true,
    },
  ],
  LINK: [
    {
      id: 'href',
      name: 'href',
      label: 'Link',
      type: 'text',
      required: true,
    },
  ],
  DOWNLOAD: [
    {
      type: 'file',
      id: 'file',
      name: 'file',
      label: 'Select document',
      required: true,
    },
  ],
  ADDRESS: [],
  DATE: [],
  CURRENCY: [],
  FILE: [],
  SUBMIT: [],
};
