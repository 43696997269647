import classNames from 'classnames';

import * as styles from './styles';

export const BADGE_COLORS = [
  'red',
  'blue',
  'purple',
  'green',
  'gray',
  'pink',
  'yellow',
] as const;

export type BadgeColor = typeof BADGE_COLORS[number];

export type BadgeType = 'full' | 'dot';

interface BadgeProps {
  color: BadgeColor;
  children?: React.ReactNode;
  className?: string;
  badgeType: BadgeType;
}

const Badge = ({ color, children, className, badgeType }: BadgeProps) => {
  return (
    <>
      {badgeType === 'full' && (
        <div className={classNames(className, styles.cell, styles[color])}>
          {children}
        </div>
      )}
      {badgeType === 'dot' && (
        <svg
          className={classNames(className, 'h-2 w-2', styles[`${color}Dot`])}
          fill="currentColor"
          viewBox="0 0 8 8"
        >
          <circle fill="currentColor" cx={4} cy={4} r={4} />
        </svg>
      )}
    </>
  );
};

export default Badge;
