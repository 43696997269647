import { SelectMenuOption } from 'components/SelectMenu';
import { insuranceNameMapper } from 'shared/insurances/insuranceNameMapper';
import { InsuranceType } from 'shared/insurances/types';

export const insuranceTypeDropdownOptions: Array<
  SelectMenuOption<InsuranceType>
> = Object.entries(insuranceNameMapper).map(([key, value]) => ({
  id: key as InsuranceType,
  label: value,
}));
