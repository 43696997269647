import classNames from 'classnames';

export const tableWrapper = classNames(
  'mb-[80px]',
  'overflow-x-auto',
  'justify-self-center',
  'w-full'
);

export const title = classNames('text-2xl', 'font-bold', 'text-gray-900');

export const subtitle = classNames('text-l', 'font-bold', 'text-gray-600');

export const actionsBar = classNames(
  'mx-[40px]',
  'flex',
  'items-center',
  'mt-[16px]',
  'justify-between',
  'flex-col',
  'md:flex-row',
  'space-y-2'
);

export const searchBar = classNames(
  'flex',
  'items-center',
  'items-start',
  'flex-wrap',
  'gap-2'
);

export const tableContent = classNames(
  'mt-2',
  'mx-[40px]',
  'align-middle',
  'rounded-[4px]',
  'overflow-x-scroll'
);

export const table = classNames('w-full', 'border-separate');

export const tableHeader = classNames(
  'bg-white',
  'border-b',
  'border-gray-300'
);

export const pagination = classNames('mx-[40px]');

export const tableRow = classNames('bg-white');

export const clickable = classNames(
  'cursor-pointer',
  'hover:bg-gray-50',
  'focus:bg-gray-200',
  'clickable-row'
);

export const checked = classNames('bg-indigo-100');

export const checkboxWrapper = classNames(
  'border-b border-t border-b-gray-200 border-t-gray-200 p-3'
);
