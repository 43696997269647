import { useQuery } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import { capitalizeName } from '@getpopsure/public-utility';
import { ClipboardListIcon } from '@heroicons/react/outline';
import { BriefcaseIcon } from '@heroicons/react/solid';
import { DetailsPage } from 'components/DetailsPage';
import { HeaderInfoText } from 'components/DetailsPage/models';
import { GET_PUBLIC_PROVIDER_POLICY } from 'graphql/publicHealth/provider/queries';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import ActivityTab from 'pages/policies/publicHealth/provider/components/ActivityTab';
import OverviewTab from 'pages/policies/publicHealth/provider/components/OverviewTab/OverviewTab';
import { RouteComponentProps } from 'react-router';
import { mapUserOccupation } from 'shared/mapUserOccupation';

import PolicyActions from '../components/PolicyActions/PolicyActions';
import QuestionnaireTabPublicProvider from '../components/QuestionnaireTabPublicProvider';

type PublicHealthPolicyPageParams = {
  id: string;
  provider: string;
};

interface Data {
  providerPublicHealthPolicy: PublicHealthPolicy;
}

const PublicHealthPolicyPage = ({
  match,
}: RouteComponentProps<PublicHealthPolicyPageParams>) => {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<Data>(GET_PUBLIC_PROVIDER_POLICY, {
    variables: {
      id: match.params.id,
    },
  });

  const tabs = [
    {
      name: t('admin.provider.policy.public.tabs.overview', 'Overview'),
      href: '#overview',
      withDot: false,
    },
    {
      name: t(
        'admin.provider.policy.public.tabs.questionnaire',
        'Questionnaire'
      ),
      href: '#questionnaire',
      withDot: false,
    },
    {
      name: t('admin.provider.policy.public.tabs.activity', 'Activity'),
      href: '#activity',
      withDot: !!data?.providerPublicHealthPolicy.hasActivityDot,
    },
  ];

  const headerInfos: HeaderInfoText[] = [
    {
      icon: <BriefcaseIcon />,
      title: mapUserOccupation(
        data?.providerPublicHealthPolicy.occupation ?? ''
      ),
      ...(data?.providerPublicHealthPolicy.occupation ===
        'UNIVERSITY_STUDENT' && {
        check: data.providerPublicHealthPolicy.studentChecklist?.completed
          ? 'check'
          : 'cross',
      }),
    },
    {
      icon: <ClipboardListIcon />,
      title: 'eGK',
      check: data?.providerPublicHealthPolicy.healthCard?.completed
        ? 'check'
        : 'cross',
    },
  ];

  const tabComponents = [
    {
      id: 'overview',
      component: OverviewTab,
      props: {
        data: data?.providerPublicHealthPolicy,
      },
    },
    {
      id: 'questionnaire',
      component: QuestionnaireTabPublicProvider,
      props: {
        policy: data?.providerPublicHealthPolicy,
      },
    },
    {
      id: 'activity',
      component: ActivityTab,
      props: {
        activity: data?.providerPublicHealthPolicy.activity,
        policyId: data?.providerPublicHealthPolicy.id,
      },
    },
  ];

  const name =
    data?.providerPublicHealthPolicy.insuredPerson.firstName &&
    data?.providerPublicHealthPolicy.insuredPerson.lastName
      ? capitalizeName({
          firstName: data.providerPublicHealthPolicy.insuredPerson.firstName,
          lastName: data.providerPublicHealthPolicy.insuredPerson.lastName,
        }) ?? 'No name'
      : data?.providerPublicHealthPolicy.user.firstName &&
        data?.providerPublicHealthPolicy.user.lastName
      ? capitalizeName({
          firstName: data.providerPublicHealthPolicy.user.firstName,
          lastName: data.providerPublicHealthPolicy.user.lastName,
        }) ?? 'No name'
      : 'No name';

  return (
    <DetailsPage
      windowTitle="Policy - Feather Admin Panel"
      pageTitle={name}
      backLink={{
        text: 'Back',
      }}
      tabs={tabs}
      tabComponents={tabComponents}
      current="policies-public"
      loading={loading || !data}
      error={error}
      headerActions={
        <PolicyActions policy={data?.providerPublicHealthPolicy} />
      }
      headerInfoTexts={headerInfos}
      hasLanguageSelector={true}
    />
  );
};

export default PublicHealthPolicyPage;
