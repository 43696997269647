import { useQuery } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import { RecentActivityEntry } from 'components/ActivityLog/ActivityLog';
import Card from 'components/Card';
import CardSection from 'components/Card/CardSection';
import Link from 'components/Link';
import {
  GET_CLAIM_ACTIVITY,
  GET_POLICY_ACTIVITY,
} from 'shared/activityLog/queries';
import { IActivityLogEntry } from 'shared/activityLog/types';

type QueryType = typeof GET_CLAIM_ACTIVITY | typeof GET_POLICY_ACTIVITY;

export const RecentActivity = ({
  query,
  variables,
}: {
  query: QueryType;
  variables?: Record<string, any> & { pageSize?: number };
}) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(query, { variables });

  if (!loading && !data) return null;

  return (
    <Card
      title={t(
        'admin.provider.policy.template.recentactivity.title',
        'Recent Activity'
      )}
      boldTitle={false}
    >
      <CardSection className="!justify-left !items-start lg:w-[350px]">
        {loading ||
          (data?.activityLog?.length === 0 && (
            <p className="self-stretch text-center text-gray-600">
              {t(
                'admin.provider.policy.template.recentactivity.noactivity',
                'No activity yet'
              )}
            </p>
          ))}

        {!loading &&
          data?.activityLog?.map((entry: IActivityLogEntry, i: number) => (
            <RecentActivityEntry
              key={entry.eventId}
              {...entry}
              hasActivityLine={i !== data.activityLog.length - 1}
            />
          ))}
      </CardSection>
      {data?.activityLog?.length > 0 && (
        <CardSection className="!items-center !justify-center lg:w-[350px]">
          <Link
            text={t(
              'admin.provider.policy.template.recentactivity.viewall.action',
              'View all'
            )}
            color="secondary"
            href="#activity"
            className="text-m font-bold"
          />
        </CardSection>
      )}
    </Card>
  );
};
