import CheckBadge from 'components/CheckBadge';
import { CheckBadgeType } from 'components/CheckBadge/CheckBadge';

interface TableCellTextWithCheckProps {
  data: unknown;
  getTextWithCheck: (data: any) => {
    label: string;
    check: CheckBadgeType;
    badgeCaption?: string;
  };
}

export const TableCellTextWithCheck = ({
  getTextWithCheck,
  data,
}: TableCellTextWithCheckProps) => {
  const cellData = getTextWithCheck(data);

  return (
    <div className="flex items-center space-x-1">
      <p>{cellData.label}</p>
      {cellData.check && (
        <CheckBadge
          checkType={cellData.check}
          badgeCaption={cellData.badgeCaption}
        />
      )}
    </div>
  );
};
