import { useTranslation } from '@getpopsure/i18n-react';
import Modal from 'components/Modal';

interface ApproveCommissionModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleConfirm: (status: 'ACCEPTED' | 'DECLINED') => void;
  loading: boolean;
}

const ApproveCommissionModal = ({
  open,
  setOpen,
  handleConfirm,
  loading,
}: ApproveCommissionModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t(
        'admin.provider.policy.public.commissioncheck.modal.approve.title',
        'Approve commission?'
      )}
      open={open}
      setOpen={setOpen}
      handleConfirm={() => handleConfirm('ACCEPTED')}
      confirmButtonLabel={t(
        'admin.provider.policy.public.commissioncheck.modal.approve.action.label',
        'Approve'
      )}
      textLoading={t(
        'admin.provider.policy.public.commissioncheck.modal.approve.loading',
        'Approving...'
      )}
      loading={loading}
    >
      <span className="text-sm text-gray-600">
        {t(
          'admin.provider.policy.public.commissioncheck.modal.approve.description',
          'The confirmation of commission entitlement may only be issued if the policy is active (e.g. if DEÜV registration is available). After approving, the commission payment will be made in the next payment cycle.'
        )}
      </span>
    </Modal>
  );
};

export default ApproveCommissionModal;
