import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import { liabilityStatusMapper } from 'shared/insurances/statusMappers/liability';
import { LiabilityStatus } from 'shared/insurances/types';

import { getLiabilityReadableTariff } from '../utils';

const statusFilterOptions: SelectButtonOption<LiabilityStatus>[] = [
  {
    id: 'PENDING',
    label: liabilityStatusMapper.PENDING,
    title: liabilityStatusMapper.PENDING,
  },
  {
    id: 'ACTIVE',
    label: liabilityStatusMapper.ACTIVE,
    title: liabilityStatusMapper.ACTIVE,
  },
  {
    id: 'CANCELED',
    label: liabilityStatusMapper.CANCELED,
    title: liabilityStatusMapper.CANCELED,
  },
];

/**
 * Liability tariff filters do not use tariff types
 * They follow this logic:
 * 1. No members chosen = Single
 * 2. Partner chosen with no other members = Partner
 * 3. Any other combination adds all members = Partner, parent(s) and kid(s)
 */
const planFilterOptions: SelectButtonOption<'SINGLE' | 'PARTNER' | 'ALL'>[] = [
  {
    id: 'SINGLE',
    label: getLiabilityReadableTariff(),
    title: getLiabilityReadableTariff(),
  },
  {
    id: 'PARTNER',
    label: getLiabilityReadableTariff(['PARTNER']),
    title: getLiabilityReadableTariff(['PARTNER']),
  },
  {
    id: 'ALL',
    label: getLiabilityReadableTariff(['PARENTS', 'KIDS']),
    title: getLiabilityReadableTariff(['PARENTS', 'KIDS']),
  },
];

export const tableFilters: FilterConfig[] = [
  {
    options: statusFilterOptions,
    filterType: 'MULTIPLE',
    label: 'Status',
    id: 'filterStatus',
    placeholder: 'Select status(es)',
  },
  {
    options: planFilterOptions,
    filterType: 'MULTIPLE',
    label: 'Tariff',
    id: 'filterPlan',
    placeholder: 'Select tariff(s)',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Start date',
    id: 'filterStartDate',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Created on',
    id: 'filterCreatedAt',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Archived on',
    id: 'filterArchivedAt',
  },
];
