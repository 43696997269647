import { capitalizeName } from '@getpopsure/public-utility';
import { PolicyTemplateData } from 'pages/policies/template/models/PolicyTemplateData';
import { insuranceNameMapper } from 'shared/insurances/insuranceNameMapper';
import { policyDocumentCategories } from 'shared/policyDocumentCategories';

import { NUMBER_OF_POLICIES_PER_PAGE } from '../template/constants';
import { getDocumentSections } from '../template/defaultPolicyDocumentsConfig';
import { TemplateInsuranceType } from '../template/models/InsuranceTypes';
import { getDbInsuranceType } from '../template/utils';
import { getOverviewTabCards } from './data/overviewTabData';
import { getQuestionnaireData } from './data/questionnaireData';
import {
  extendedValidatorObject,
  getModals,
  nextStatuses,
  retrieveModalId,
  statusOptions,
} from './data/statusData';
import { rowConfig, tableHeaders } from './data/tableData';
import { filters } from './data/tableFilters';
import { GET_BIKE_POLICIES } from './graphql/policies';
import { GET_BIKE_POLICY } from './graphql/singlePolicy';
import { UPDATE_BIKE_QUESTIONNAIRE } from './graphql/updateQuestionnaire';
import { UPDATE_BIKE_POLICY_STATUS } from './graphql/updateStatus';
import { BikeDocumentType } from './models/BikeDocumentType';
import { BikePoliciesData } from './models/BikePolicies';
import { BikePolicyData } from './models/BikePolicy';

const insurancePath = 'bike';
const insuranceType = getDbInsuranceType(
  insurancePath
) as TemplateInsuranceType;
const insuranceName = insuranceNameMapper[insuranceType];

export const getBikePoliciesTemplateData = (): PolicyTemplateData<
  BikePoliciesData,
  BikePolicyData,
  BikeDocumentType
> => {
  return {
    insuranceType,
    navigation: {
      current: `policies-${insurancePath}`,
    },
    queries: {
      allPolicies: GET_BIKE_POLICIES,
      policy: GET_BIKE_POLICY,
    },
    mutations: {
      statusChange: UPDATE_BIKE_POLICY_STATUS,
      editQuestionnaire: UPDATE_BIKE_QUESTIONNAIRE,
    },
    copy: {
      table: {
        title: `${insuranceName} policies`,
        searchPlaceholder:
          'Search by name, email, policy number, policy id or utm source',
      },
      details: {
        pageTitle: `${insuranceName} policy - Feather Admin Panel`,
        title: insuranceName,
      },
    },
    features: {
      overviewTable: {
        numberOfRowsPerPage: NUMBER_OF_POLICIES_PER_PAGE,
        headersConfig: tableHeaders,
        filtersConfig: filters,
        rowConfig,
      },
      details: {
        queryVariables: {
          insuranceType,
        },
        insuredPersonName: (data?: BikePolicyData) =>
          capitalizeName({
            firstName: data?.bikePolicy.insuredPerson?.firstName ?? '',
            lastName: data?.bikePolicy.insuredPerson?.lastName ?? '',
          }) ?? '',
        overviewTab: {
          getCards: getOverviewTabCards,
        },
      },
      statusChange: {
        statusOptions,
        statusChangeOptions: nextStatuses,
        getModals,
        retrieveModalId,
        extendedValidatorObject,
        additionalQueryVariables: { insuranceType },
      },
      documents: {
        uploadOptions: [
          { id: 'CUSTOMER_UPLOADS', label: 'Customer uploads' },
        ].concat(policyDocumentCategories),
        getDocumentSections: (data?: BikePolicyData) =>
          getDocumentSections(data?.bikePolicy),
      },
      questionnaire: {
        cardTitle: 'Personal information',
        editable: true,
        getQuestionnaireData,
      },
    },
  };
};
