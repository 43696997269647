import { useTranslation } from '@getpopsure/i18n-react';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import * as Sentry from '@sentry/react';
import z from 'zod';

import type { DataPreviewProps } from '../TaskEngine.types';
import * as styles from './TaskPreviewModal.styles';

export const CurrencyPreview = ({ data, actionId }: DataPreviewProps) => {
  const { t } = useTranslation();

  /**
   * Validate that the actionId property exists in the data object
   * and return the numeric value
   */
  const CurrencyDataObjectSchema = z
    .object({ [actionId]: z.coerce.number() })
    .transform((object) => {
      return object[actionId];
    });

  const amount = CurrencyDataObjectSchema.safeParse(data);
  const label = t(
    'taskEngine.taskPreviewModal.currencyPreview.label',
    'Amount'
  );

  if (!amount.success) {
    Sentry.captureMessage(
      `[TASK_ENGINE]: customer provided an invalid currency amount. Error: ${amount.error.message.toString()}`,
      'warning'
    );

    return (
      <div className={styles.row}>
        <p className={styles.rowText}>{label}</p>
        <p className={styles.invalidAnswer}>
          {t(
            'taskEngine.taskPreviewModal.currencyPreview.error',
            'The customer provided an invalid amount'
          )}
        </p>
      </div>
    );
  }

  return (
    <div className={styles.row}>
      <p className={styles.rowText}>{label}</p>
      <p className={styles.rowText}>
        {englishFormattedEuroCurrency(amount.data, true)}
      </p>
    </div>
  );
};
