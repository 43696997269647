/**
 * NOTE: This type is being shared with BE.
 * When updating, also update BE type
 *
 * Notion page for all vertical claim statuses:
 * https://www.notion.so/featherinsurance/Claim-statuses-2-0-22713a3fe92c49969b2e403f655678b2
 */
export const bikeClaimStatuses = [
  'RECEIVED',
  'SUBMITTED_TO_PROVIDER',
  'INCOMPLETE',
  'MISSING_INFO_RECEIVED',
  'CUSTOMER_PAID_OUT',
  'DUPLICATE',
  'DENIED',
  'DROPPED_OUT',
  'CLOSED',
] as const;
export type BikeClaimStatus = typeof bikeClaimStatuses[number];

export const bikeClaimTypes = [
  'FULLY_DAMAGED',
  'STOLEN',
  'PARTIALLY_DAMAGED',
  'STOLEN_PARTS',
  'WEAR_AND_TEAR',
] as const;
export type BikeClaimType = typeof bikeClaimTypes[number];
