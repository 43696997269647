import { gql } from '@apollo/client';

export const UPDATE_BIKE_POLICY = gql`
  mutation UpdateBikePolicy(
    $insuranceType: String!
    $id: String!
    $policyNumber: String!
    $startDate: String!
    $endDate: String!
    $price: Float!
    $source: String!
    $campaign: String
    $medium: String
    $term: String
    $content: String
    $brand: String!
    $model: String!
    $frameNumber: String
  ) {
    updateBikePolicyInfo(
      insuranceType: $insuranceType
      id: $id
      policyNumber: $policyNumber
      startDate: $startDate
      endDate: $endDate
      price: $price
      source: $source
      campaign: $campaign
      medium: $medium
      content: $content
      term: $term
      brand: $brand
      model: $model
      frameNumber: $frameNumber
    ) {
      id
      policyNumber
      startDate
      endDate
      price
      source
      campaign
      medium
      content
      term
    }
  }
`;
