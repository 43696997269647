import { gql } from '@apollo/client';

export const CREATE_LEGAL_CLAIM = gql`
  mutation CreateLegalClaim(
    $insuranceType: String!
    $userId: String!
    $policyId: String!
    $policyNumber: String
    $status: String!
    $claimType: [String!]!
    $claimNumber: String
    $uploadDocument: Upload
  ) {
    createLegalClaim(
      insuranceType: $insuranceType
      userId: $userId
      policyId: $policyId
      policyNumber: $policyNumber
      status: $status
      claimType: $claimType
      claimNumber: $claimNumber
      uploadDocument: $uploadDocument
    ) {
      userPolicyId
      status
      user {
        id
        firstName
        lastName
      }
      claimType {
        id
        createdAt
        type
      }
      claimNumber
    }
  }
`;
