import classNames from 'classnames';

export const row = classNames('flex', 'mt-[16px]');

export const infoTitle = classNames(
  'text-sm',
  'text-gray-900',
  'font-bold',
  'w-[180px]'
);

export const infoContent = classNames('text-sm', 'text-gray-900');
