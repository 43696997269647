import { BadgeColor } from 'components/Badge/Badge';

import { SDCategory } from './categories/sdCategories.types';
import { ScannedDocumentStatus } from './scannedDocuments.types';

export const statusMapping = (
  status?: string
): {
  text: {
    text: string;
    id: ScannedDocumentStatus | '';
  };
  color: BadgeColor;
} => {
  switch (status) {
    case 'PENDING_UPLOAD':
      return {
        text: {
          id: 'PENDING_UPLOAD',
          text: 'Pending upload',
        },
        color: 'gray',
      };
    case 'NEW':
      return {
        text: {
          id: 'NEW',
          text: 'New',
        },
        color: 'pink',
      };
    case 'IN_PROGRESS':
      return {
        text: {
          id: 'IN_PROGRESS',
          text: 'In progress',
        },
        color: 'blue',
      };
    case 'WAITING':
      return {
        text: {
          id: 'WAITING',
          text: 'Waiting',
        },
        color: 'yellow',
      };
    case 'DONE':
      return {
        text: {
          id: 'DONE',
          text: 'Done',
        },
        color: 'green',
      };
    default:
      return {
        text: {
          id: '',
          text: '---',
        },
        color: 'gray',
      };
  }
};

const colorPriorityLookup: Record<BadgeColor, number> = {
  red: 1,
  pink: 2,
  blue: 3,
  purple: 4,
  yellow: 5,
  green: 6,
  gray: 7,
};

export const getCategoryActionDotData = ({
  allCategories,
  documentCategoryNames,
  status,
}: {
  allCategories: SDCategory[];
  documentCategoryNames: string[];
  status?: ScannedDocumentStatus;
}): {
  badgeColor: BadgeColor;
  badgeCaption: string;
  data: boolean;
} => {
  const categoriesRequiringAction = allCategories.filter(
    (category) =>
      documentCategoryNames.includes(category.name) &&
      category.attributes.requiredAction?.caption &&
      category.attributes.requiredAction?.color
  );

  const requiresAction = status === 'NEW' && categoriesRequiringAction.length;

  if (requiresAction) {
    const categoriesSortedByPriority = categoriesRequiringAction.sort(
      (a, b) => {
        const colorA = a.attributes.requiredAction?.color ?? 'gray';
        const colorB = b.attributes.requiredAction?.color ?? 'gray';

        return colorPriorityLookup[colorA] - colorPriorityLookup[colorB];
      }
    );
    const category = categoriesSortedByPriority[0];
    return {
      data: true,
      badgeColor: category.attributes.requiredAction?.color ?? 'gray',
      badgeCaption: category.attributes.requiredAction?.caption ?? '',
    };
  }
  return {
    data: false,
    badgeColor: 'gray',
    badgeCaption: '',
  };
};
