import { InformationSection } from 'components/InformationCard';
import ComboBoxInformation from 'components/InformationCard/ComboBoxInformation/ComboBoxInformation';
import { CurrencyInformation } from 'components/InformationCard/CurrencyInformation';
import DateInformation from 'components/InformationCard/DateInformation';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { DentalClaim } from 'pages/claims/dental/models';
import { claimTypeNameMapper as dentalClaimTypeNameMapper } from 'shared/claims/dental/claimTypeNameMapper';
import { DentalClaimType, dentalClaimTypes } from 'shared/claims/dental/models';
import { policyMappers } from 'shared/insurances/';

export const retrieveDentalClaimInfoOldConfirmationValue = (
  claimInfo: DentalClaim
) => {
  return [
    {
      id: 'iban',
      value: claimInfo.iban ?? '',
    },
  ];
};

export const retrieveDentalClaimInfoRows = (
  claimInfo: DentalClaim
): InformationSection[] => [
  {
    id: 'claimInfo',
    title: 'Claim information',
    boldTitle: false,
    rows: [
      {
        id: 'claimNumber',
        title: 'Claim number',
        data: claimInfo?.claimNumber ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
      {
        id: 'status',
        title: 'Status',
        data: claimInfo?.status ?? 'RECEIVED',
        statusMapping: (status) => {
          return {
            color:
              policyMappers.DENTAL?.claim?.badgeColorMapper[status] ?? 'blue',
            text: {
              text:
                policyMappers.DENTAL?.claim?.claimStatusMapper[status] ?? '',
              id: status,
            },
          };
        },
        type: 'STATUS',
        component: StatusInformation,
        editable: false,
      },
      {
        id: 'claimType',
        title: 'Claim type',
        data: claimInfo.claimType?.map(({ type }) => type).join(',') ?? '',
        type: 'DROPDOWN',
        component: ComboBoxInformation,
        dropdownOptions: dentalClaimTypes.map((type) => ({
          id: type,
          label: dentalClaimTypeNameMapper[type as DentalClaimType],
        })),
        optionMapping: (option: string) =>
          dentalClaimTypeNameMapper[option as DentalClaimType],
        placeholder: 'Choose a claim type',
        editable: true,
        multipleOptions: true,
        required: true,
      },
      {
        id: 'createdOn',
        title: 'Created on',
        data: claimInfo?.createdAt ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: false,
      },
      {
        id: 'amount',
        title: 'Claimed amount',
        data: claimInfo?.amount ?? 0,
        type: 'CURRENCY',
        component: CurrencyInformation,
        editable: true,
        required: true,
      },
      {
        id: 'iban',
        title: 'IBAN',
        data: claimInfo?.iban ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: true,
      },
      {
        id: 'refundAmount',
        title: 'Refund amount',
        data:
          claimInfo.refundAmount !== null &&
          claimInfo.refundAmount !== undefined
            ? claimInfo.refundAmount
            : undefined,
        type: 'CURRENCY',
        component: CurrencyInformation,
        editable: true,
      },
      {
        id: 'featherPaidOn',
        title: 'Feather paid on',
        data: claimInfo?.featherPaidOn ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: true,
      },
      {
        id: 'customerPaidOn',
        title: 'Customer paid on',
        data: claimInfo?.customerPaidOn ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: true,
      },
    ],
  },
];
