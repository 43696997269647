export const getFileType = (fileName: string): string =>
  fileName.split('.').pop() ?? '';

export const getMimeType = (fileName: string): string => {
  const extension = getFileType(fileName);
  switch (extension) {
    case 'pdf':
      return 'application/pdf';
    case 'png':
      return 'image/png';
    case 'jpg':
      return 'image/jpeg';
    case 'jpeg':
      return 'image/jpeg';
    case 'zip':
      return 'application/zip';
    case 'csv':
      return 'text/csv';
    case 'json':
      return 'application/json';
    case 'txt':
      return 'text/plain';
    default:
      return 'application/octet-stream';
  }
};
