import { useTranslation } from '@getpopsure/i18n-react';
import FileErrorCard from 'components/FileErrorCard';
import { useState } from 'react';
import { ErrorCode } from 'react-dropzone';

interface RejectedFileProps {
  errorCode: ErrorCode;
}

const RejectedFile = ({ errorCode }: RejectedFileProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  const errorMapping: Partial<Record<ErrorCode, { title: string }>> = {
    'file-too-large': {
      title: t(
        'admin.documents.error.filetoolarge.title',
        'The file must be less than 20MB.'
      ),
    },
    'file-invalid-type': {
      title: t(
        'admin.documents.error.incorrectformat.title',
        'Only PDF, PNG, JPG and GIF files are accepted.'
      ),
    },
  };

  return (
    <FileErrorCard
      open={open}
      title={errorMapping[errorCode]?.title ?? ''}
      handleClose={() => setOpen(false)}
      errorType="WARNING"
    />
  );
};

export default RejectedFile;
