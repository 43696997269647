import { getFixedT } from '@getpopsure/i18n-react';

export const getStatus = () => {
  const t = getFixedT();
  return [
    {
      title: t('admin.status.unicheck', 'Uni check'),
      label: t('admin.status.unicheck', 'Uni check'),
      id: 'unicheck',
    },
    {
      title: t('admin.status.new', 'New'),
      label: t('admin.status.new', 'New'),
      id: 'new',
    },
    {
      title: t('admin.status.covered', 'Covered'),
      label: t('admin.status.covered', 'Covered'),
      id: 'covered',
    },
    {
      title: t('admin.status.canceled', 'Canceled'),
      label: t('admin.status.canceled', 'Canceled'),
      id: 'canceled',
    },
    {
      title: t('admin.status.processing', 'Processing'),
      label: t('admin.status.processing', 'Processing'),
      id: 'processing',
    },
    {
      title: t('admin.status.missinginfo', 'Missing info'),
      label: t('admin.status.missinginfo', 'Missing info'),
      id: 'missinginfo',
    },
    {
      title: t('admin.status.noteligible', 'Not eligible'),
      label: t('admin.status.noteligible', 'Not eligible'),
      id: 'noteligible',
    },
  ];
};
