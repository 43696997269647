import { InsuranceType } from './types';

export const insuranceNameMapper: Record<InsuranceType, string> = {
  BIKE: 'Bike',
  CAR: 'Car',
  DENTAL: 'Dental',
  DOG_LIABILITY: 'Dog liability',
  // TODO: [KONG] Should this stay as generic?
  HOUSEHOLD: 'Household',
  INCOMING: 'Expat health',
  // TODO: [KONG] What's the name of this insurance?
  JOB: 'Job',
  LEGAL: 'Legal',
  LIFE: 'Life',
  LIABILITY: 'Liability',
  PRIVATE_HEALTH: 'Private health',
  PUBLIC_HEALTH: 'Public health',
  // Generic policies
  GENERIC: 'Generic',
  TRAVEL: 'Travel',
  PET_HEALTH: 'Pet health',
  DISABILITY: 'Disability',
  COMPANY_HEALTH: 'Company health',
  COMPANY_LIFE: 'Company life',
  PENSION: 'Pension',
  BASIS_PENSION: 'Basis pension',
  INCOMING_ES: 'Expat health',
  TRAVEL_FR: 'Travel',
  INCOMING_EU: 'Expat health',
  INCOMING_LT: 'Expat health long-term',
  COMPANY_PENSION: 'Company pension',
  DOG_LIABILITY_ES: 'Dog liability',
};
