import classNames from 'classnames';

export const label = classNames(
  'text-sm',
  'font-bold',
  'text-gray-900',
  'mb-[8px]'
);

export const selectWrapper = classNames('relative');

export const selected = classNames(
  'relative',
  'w-full',
  'text-gray-900',
  'rounded-[6px]',
  'pl-[12px]',
  'pr-10',
  'py-[9px]',
  'text-left',
  'cursor-default',
  'focus:outline-none',
  'focus:ring-1',
  'focus:ring-indigo-500',
  'focus:border-indigo-500',
  'text-sm',
  'placeholder-gray-500'
);

export const selectedWhite = classNames('bg-white');
export const selecteddGray = classNames('bg-gray-200');

export const disabledWhite = classNames('!bg-gray-50');
export const disabledGray = classNames('!bg-gray-300');

export const noSelection = classNames('text-gray-500');

export const selectButton = classNames(
  'absolute',
  'inset-y-0',
  'right-0',
  'flex',
  'items-center',
  'pr-2',
  'flex',
  'content-center'
);

export const selectIcon = classNames('h-5', 'w-5', 'text-gray-400');

export const optionsList = classNames(
  'absolute',
  'z-10',
  'mt-1',
  'w-full',
  'shadow-lg',
  'max-h-60',
  'rounded-[6px]',
  'py-1',
  'overflow-auto',
  'focus:outline-none',
  'text-sm'
);

export const listOption = classNames(
  'cursor-default',
  'select-none',
  'relative',
  'py-2',
  'pl-3',
  'pr-9'
);

export const listOptionHover = classNames('bg-indigo-500');

export const listOptionText = classNames('font-normal', 'text-gray-900');

export const listOptionTextSelected = classNames(
  'font-bold',
  'text-indigo-500'
);

export const listOptionTextHover = classNames('!text-white');

export const checkIcon = classNames(
  'absolute',
  'inset-y-0',
  'right-0',
  'flex',
  'items-center',
  'pr-4',
  'text-indigo-500'
);

export const checkIconHover = classNames('!text-white');

export const icon = classNames('h-5', 'w-5');

export const textPlaceHolder = classNames('!text-gray-500');

export const errorState = classNames('border-red-400', 'border');

export const errorMessage = classNames('text-xs', 'text-red-400', 'mt-1');
