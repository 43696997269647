import { useTranslation } from '@getpopsure/i18n-react';
import { ReplyIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import ActivityCircle from 'components/ActivityCircle';
import DocumentCard from 'components/DocumentsCard/DocumentCard';
import IssueCard from 'components/IssueCard';
import dayjs from 'dayjs';
import { DocumentNode } from 'graphql';
import { DATE_FORMAT_TIME } from 'models/date';
import { Status } from 'models/statusColor';
import { getAuthor } from 'shared/getAuthor';
import { IssueStatusType, mapIssueStatus } from 'shared/mapIssueStatus';

import { Activity } from '../ActivityCards';
import * as styles from './styles';

interface IssueStatusChangeActivityCardProps {
  activityCard: Activity;
  hasActivityLine: boolean;
  smallVersion: boolean;
  policyId?: string;
  statusMapping?: (status: string) => Status;
  showCustomerInformed: boolean;
  providerView?: boolean;
  issueStatusChangeMutation?: DocumentNode;
  policyQuery?: DocumentNode;
  uploadFileMutation?: DocumentNode;
  issueCategory?: string;
}

const IssueStatusChangeActivityCard = ({
  activityCard,
  smallVersion,
  hasActivityLine,
  policyId,
  showCustomerInformed,
  providerView = true,
  issueCategory,
  issueStatusChangeMutation,
  policyQuery,
  uploadFileMutation,
}: IssueStatusChangeActivityCardProps) => {
  const { t } = useTranslation();
  const activityAuthor = getAuthor(activityCard.author);
  let updateText = '';

  if (activityCard.newIssueStatus) {
    if (activityCard.newIssueStatus === 'OPEN') {
      updateText = activityAuthor
        ? t(
            'admin.provider.policy.public.activity.unresolvedissue.subtitle',
            'unresolved an issue'
          )
        : t(
            'admin.provider.policy.public.activity.system.unresolvedissue.subtitle',
            'Issue automatically unresolved'
          );
    } else if (activityCard.newIssueStatus === 'RESOLVED') {
      updateText = activityAuthor
        ? t(
            'admin.provider.policy.public.activity.resolvedissue.subtitle',
            'resolved an issue'
          )
        : t(
            'admin.provider.policy.public.activity.system.resolvedissue.subtitle',
            'Issue automatically resolved'
          );
    } else {
      updateText = `${
        activityAuthor
          ? t(
              'admin.provider.policy.public.activity.changedissuestatus.subtitle',
              'changed the issue status to'
            )
          : t(
              'admin.provider.policy.public.activity.system.changedissuestatus.subtitle',
              'Issue status automatically changed to'
            )
      }  
        '${
          mapIssueStatus(activityCard.newIssueStatus as IssueStatusType).text
        }'`;
    }
  }

  let documentName = activityCard.document?.name;
  if (activityCard.document?.type === 'CONFIRMATION_OF_COVERAGE') {
    documentName = t(
      'admin.documents.type.finalconfirmation',
      'Final confirmation of coverage'
    );
  }
  if (
    activityCard.document?.type ===
    'PRELIMINARY_CONFIRMATION_OF_COVERAGE_FROM_PROVIDER'
  ) {
    documentName = t(
      'admin.documents.type.prelimconfirmation',
      'Prelim. confirmation of coverage'
    );
  }

  return (
    <div className={styles.cardWrapper}>
      <ActivityCircle
        activityType={activityCard.type}
        hasActivityLine={hasActivityLine}
        smallVersion={smallVersion}
      />
      <div className={styles.card}>
        <div
          className={classNames(styles.lightText, {
            [styles.textWrapper]: !smallVersion,
            [styles.textWrapperSmall]: smallVersion,
          })}
        >
          {activityAuthor && (
            <p className={classNames(styles.darkText, styles.inlineText)}>
              {activityAuthor}
            </p>
          )}
          <p className={styles.inlineText}>{updateText}</p>
          <p className={styles.veryLightText}>
            {dayjs(activityCard.createdAt).format(DATE_FORMAT_TIME)}
          </p>
        </div>
        {(activityCard.comment || (activityCard.document && !smallVersion)) && (
          <div
            className={
              smallVersion
                ? styles.additionalInfoCardSmall
                : styles.additionalInfoCardFull
            }
          >
            {activityCard.comment && (
              <div className={styles.commentSection}>
                <ReplyIcon className={styles.icon} />
                <p className={styles.lightText}>{activityCard.comment}</p>
              </div>
            )}
            {activityCard.document && !smallVersion && (
              <div className={styles.documentWrapper}>
                <DocumentCard
                  fileLink={activityCard.document?.url}
                  fileName={documentName ?? ''}
                />
              </div>
            )}
          </div>
        )}
        {activityCard.issue && (
          <IssueCard
            policyId={policyId ?? ''}
            showAuthor={false}
            issue={activityCard.issue}
            smallVersion={smallVersion}
            showStatus={!smallVersion}
            showCustomerInformed={showCustomerInformed}
            cardColor={smallVersion ? 'gray' : 'white'}
            providerView={providerView}
            issueCategory={issueCategory}
            issueStatusChangeMutation={issueStatusChangeMutation}
            policyQuery={policyQuery}
            uploadFileMutation={uploadFileMutation}
          />
        )}
      </div>
    </div>
  );
};

export default IssueStatusChangeActivityCard;
