import { capitalizeName } from '@getpopsure/public-utility';
import Card from 'components/Card/Card';
import { PolicyCard } from 'components/PolicyCard';
import { Policy } from 'models/policy';
import { PolicyTemplateInsurancePath } from 'pages/policies/template/models/InsuranceTypes';
import {
  getDbInsuranceType,
  retrievePoliciesDetailsPath,
} from 'pages/policies/template/utils';
import { policyMappers } from 'shared/insurances';

interface OtherExpatPoliciesCardProps {
  title: string;
  policies: Policy[];
  insuranceType: PolicyTemplateInsurancePath;
}

export const OtherPoliciesCard = ({
  title,
  policies,
  insuranceType,
}: OtherExpatPoliciesCardProps) => {
  const dbInsuranceType = getDbInsuranceType(insuranceType);
  const policyMapper = policyMappers[dbInsuranceType];

  const otherPolicies = policies.map(({ id, insuredPerson, publicStatus }) => ({
    name: policyMapper?.name ?? '',
    icon: policyMapper?.icon ?? '',
    policyId: id,
    status: policyMapper?.statusMapper[publicStatus],
    statusBadgeColor: policyMapper?.badgeColorMapper[publicStatus],
    insuredPersonName:
      insuredPerson?.firstName && insuredPerson?.lastName
        ? capitalizeName({
            firstName: insuredPerson.firstName,
            lastName: insuredPerson.lastName,
          }) ?? ''
        : '',
  }));

  return (
    <Card title={title} boldTitle={false}>
      {otherPolicies.map(
        ({
          name,
          status,
          icon,
          policyId,
          statusBadgeColor,
          insuredPersonName,
        }) => (
          <PolicyCard
            smallVersion={true}
            title={name}
            icon={icon}
            link={retrievePoliciesDetailsPath({
              insuranceType,
              id: policyId,
            })}
            status={status}
            badgeColor={statusBadgeColor}
            insuredPerson={insuredPersonName}
          />
        )
      )}
    </Card>
  );
};
