import { gql } from '@apollo/client';

export const CREATE_NOTE = gql`
  mutation CreateNote(
    $userId: String
    $policyId: String
    $claimId: String
    $body: String!
    $parentNoteId: String
  ) {
    createNote(
      userId: $userId
      policyId: $policyId
      claimId: $claimId
      body: $body
      parentNoteId: $parentNoteId
    ) {
      id
      createdAt
      body
      pinned
      userId
      claimId
      policyId
      authorId
      parentNoteId
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation UpdateNote($noteId: String!, $body: String, $pinned: Boolean) {
    updateNote(noteId: $noteId, body: $body, pinned: $pinned) {
      id
      pinned
      body
      updatedAt
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation DeleteNote($noteId: String!) {
    deleteNote(noteId: $noteId) {
      id
    }
  }
`;
