import { getFixedT } from '@getpopsure/i18n-react';
import { BadgeColor } from 'components/Badge/Badge';

export type ProviderPublicHealthStatusId =
  | 'any'
  | 'new'
  | 'unicheck'
  | 'processing'
  | 'covered'
  | 'missinginfo'
  | 'canceled'
  | 'noteligible'
  | 'contactfeather';

export type ProviderPublicHealthStatus = {
  id: ProviderPublicHealthStatusId;
  text: string;
};

export const mapProviderPublicHealthStatus = (
  status: string
): ProviderPublicHealthStatus => {
  const t = getFixedT();
  switch (status) {
    case 'APPLICATION_RECEIVED':
      return { id: 'unicheck', text: t('admin.status.unicheck', 'Uni check') };
    case 'APPLICATION_SENT':
      return { id: 'new', text: t('admin.status.new', 'New') };
    case 'PROCESSING':
      return {
        id: 'processing',
        text: t('admin.status.processing', 'Processing'),
      };
    case 'COVERED':
      return { id: 'covered', text: t('admin.status.covered', 'Covered') };
    case 'ERR_MISSING_INFO':
      return {
        id: 'missinginfo',
        text: t('admin.status.missinginfo', 'Missing info'),
      };
    case 'DROPPED_OUT':
      return { id: 'canceled', text: t('admin.status.canceled', 'Canceled') };
    case 'CANCELED':
      return { id: 'canceled', text: t('admin.status.canceled', 'Canceled') };
    case 'CANT_BE_COVERED':
      return {
        id: 'noteligible',
        text: t('admin.status.noteligible', 'Not eligible'),
      };
    default:
      return {
        id: 'contactfeather',
        text: t('admin.status.contactfeather', 'Contact Feather'),
      };
  }
};

export const getProviderPublicHealthStatusColor = (
  status: ProviderPublicHealthStatus
): BadgeColor => {
  const colors: {
    [K in ProviderPublicHealthStatusId]: BadgeColor;
  } = {
    new: 'purple',
    processing: 'blue',
    covered: 'green',
    missinginfo: 'pink',
    canceled: 'gray',
    noteligible: 'yellow',
    contactfeather: 'gray',
    unicheck: 'red',
    any: 'gray',
  };
  return colors[status.id];
};

export const getProviderPublicHealthStatus = (status: string) => {
  const text = mapProviderPublicHealthStatus(status);
  const color = getProviderPublicHealthStatusColor(text);
  return { text, color };
};
