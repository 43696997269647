import classNames from 'classnames';

export const card = classNames(
  'bg-white',
  'rounded-[8px]',
  'mb-[16px]',
  'min-w-[350px]'
);

export const cardRowTitle = classNames(
  'px-[24px]',
  'py-[20px]',
  'flex',
  'justify-between'
);

export const cardRowTitleWithButton = classNames('!py-[15px]');

export const title = classNames('text-lg', 'text-gray-900');

export const titleBold = classNames('font-bold');
