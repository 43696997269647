import { ModalFormData } from 'components/ModalForm/models';
import { retrievePolicySuggestion } from 'pages/claims/template/components/CreateClaimModal/utils';
import { LegalPolicy } from 'pages/policies/legal/models/LegalPolicy';
import {
  LegalClaimStatus,
  legalClaimStatuses,
  LegalClaimType,
  legalClaimTypes,
} from 'shared/claims/legal/models';
import { policyMappers } from 'shared/insurances';
import { InsuranceType } from 'shared/insurances/types';

import { SubmittableClaim } from '../../../template/components/CreateClaimModal/models';

export const retrieveCreateClaimFormData =
  (insuranceType: InsuranceType) =>
  (
    policies: LegalPolicy[],
    setPolicySearchQuery: (query: string) => void,
    policiesLoading: boolean
  ): ModalFormData<
    SubmittableClaim<LegalClaimStatus, LegalClaimType>,
    LegalClaimStatus | LegalClaimType | string
  >[] => {
    const statusNameMapper =
      policyMappers[insuranceType]?.claim?.claimStatusMapper;
    const typeNameMapper = policyMappers[insuranceType]?.claim?.typeNameMapper;

    return [
      {
        id: 'policyId',
        label: 'User policy',
        renderDetails: {
          type: 'autoSuggest',
          options: policies.map(({ id, user, policyNumber }) => ({
            id,
            label: retrievePolicySuggestion({
              firstName: user?.firstName,
              lastName: user?.lastName,
              policyNumber,
            }),
          })),
          placeholder: 'Enter name, email or policy number',
          setExternalQuery: (query: string) => {
            setPolicySearchQuery(query);
          },
          useUnfilteredOptions: true,
        },
        color: 'gray',
        required: true,
        loading: policiesLoading,
      },
      {
        id: 'status',
        label: 'Status',
        renderDetails: {
          type: 'select',
          options: legalClaimStatuses.flatMap((status) => ({
            id: status,
            label: statusNameMapper ? statusNameMapper[status] : '',
          })),
          placeholder: 'Select a status',
        },
        color: 'gray',
        required: true,
      },
      {
        id: 'claimType',
        label: 'Claim type',
        renderDetails: {
          type: 'multiSelect',
          options: legalClaimTypes.map((type) => ({
            id: type,
            label: typeNameMapper ? typeNameMapper[type] : '',
          })),
          placeholder: 'Select type',
        },
        color: 'gray',
        required: true,
      },
      {
        id: 'claimNumber',
        label: 'Claim number',
        renderDetails: {
          type: 'text',
          placeholder: 'Enter Roland claim number (e.g. S-23-00000000)',
        },
        color: 'gray',
      },
      {
        id: 'uploadDocument',
        label: 'Upload a document',
        renderDetails: { type: 'upload' },
      },
    ];
  };
