import { BadgeColor } from 'components/Badge/Badge';

// Temporary functions to accomodate private and expat statuses, while these verticals are not worked on

export type HealthStatusId =
  | 'received'
  | 'unicheck'
  | 'sent'
  | 'processing'
  | 'covered'
  | 'missinginfo'
  | 'canceled'
  | 'noteligible'
  | 'contactfeather'
  | 'pending'
  | 'active';

export type HealthStatus = {
  id: HealthStatusId;
  text: string;
};

export const mapHealthStatus = (status: string): HealthStatus => {
  switch (status) {
    case 'APPLICATION_RECEIVED':
      return { id: 'received', text: 'Received' };
    case 'APPLICATION_SENT':
      return { id: 'sent', text: 'Sent' };
    case 'PROCESSING':
      return {
        id: 'processing',
        text: 'Processing',
      };
    case 'COVERED':
      return { id: 'covered', text: 'Covered' };
    case 'ERR_MISSING_INFO':
      return {
        id: 'missinginfo',
        text: 'Missing info',
      };
    case 'DROPPED_OUT':
      return { id: 'canceled', text: 'Canceled' };
    case 'CANCELED':
      return { id: 'canceled', text: 'Canceled' };
    case 'CANT_BE_COVERED':
      return {
        id: 'noteligible',
        text: 'Not eligible',
      };
    case 'UNI_CHECK':
      return {
        id: 'unicheck',
        text: 'Uni check',
      };
    case 'PENDING':
      return {
        id: 'pending',
        text: 'Pending',
      };
    case 'ACTIVE':
      return {
        id: 'covered',
        text: 'Covered',
      };
    default:
      return {
        id: 'contactfeather',
        text: 'Contact Feather',
      };
  }
};

export const getHealthStatusColor = (status: HealthStatus): BadgeColor => {
  const colors: {
    [K in HealthStatusId]: BadgeColor;
  } = {
    received: 'purple',
    sent: 'blue',
    processing: 'blue',
    covered: 'green',
    missinginfo: 'pink',
    canceled: 'gray',
    noteligible: 'yellow',
    contactfeather: 'gray',
    unicheck: 'red',
    pending: 'yellow',
    active: 'green',
  };
  return colors[status.id];
};

export const getHealthStatus = (status: string) => {
  const text = mapHealthStatus(status);
  const color = getHealthStatusColor(text);
  return { text, color };
};
