import { gql } from '@apollo/client';

export const UPDATE_DOG_LIABILITY_CLAIM = gql`
  mutation UpdateDogLiabilityClaim(
    $claimId: String!
    $claimType: [String]
    $eventDescription: String
    $iban: String
    $amount: Float
    $provision: Float
    $refundAmount: Float
    $customerPaidOn: String
    $thirdPartyPaidOn: String
    $thirdPartyName: String
    $thirdPartyEmail: String
    $thirdPartyAddress: JSON
    $dateOfEvent: String
  ) {
    updateDogLiabilityClaim(
      claimId: $claimId
      claimType: $claimType
      eventDescription: $eventDescription
      iban: $iban
      amount: $amount
      provision: $provision
      refundAmount: $refundAmount
      customerPaidOn: $customerPaidOn
      thirdPartyPaidOn: $thirdPartyPaidOn
      thirdPartyName: $thirdPartyName
      thirdPartyEmail: $thirdPartyEmail
      thirdPartyAddress: $thirdPartyAddress
      dateOfEvent: $dateOfEvent
    ) {
      id
      claimType {
        id
        type
      }
      eventDescription
      iban
      provision
      amount
      refundAmount
      customerPaidOn
      thirdPartyPaidOn
      dateOfEvent
    }
  }
`;
