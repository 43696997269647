import classNames from 'classnames';

export const taskItem = classNames(
  'rounded-2',
  'text-sm',
  'mb-4',
  'flex',
  'rounded-lg',
  'last:mb-0',
  'w-full',
  'ring-1',
  'ring-gray-300',
  'focus:outline-none',
  'focus:ring-2',
  'hover:ring-indigo-500',
  'relative',
  'bg-white'
);

export const textBlack = classNames('text-gray-900', 'hover:text-gray-900');

export const textMuted = classNames('text-gray-600', 'hover:text-gray-600');

export const taskItemMuted = classNames('line-through', 'hover:ring-gray-300');

export const taskButton = classNames(
  'focus:outline-indigo-500',
  'flex',
  'flex-grow',
  'bg-transparent',
  'text-left',
  'p-4'
);

export const optionsButton = classNames(
  'focus:outline-indigo-500',
  'px-4',
  'py-2'
);

export const dropdown = classNames(
  'absolute',
  'rounded-[4px]',
  'bg-white',
  'right-4',
  'top-11',
  'w-[148px]',
  'shadow-lg',
  'z-10'
);

export const dropdownItem = classNames(
  'w-full',
  'px-4',
  'py-2',
  'text-left',
  'hover:bg-gray-50'
);
