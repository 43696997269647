import { useMutation } from '@apollo/client';
import Modal from 'components/Modal';
import SelectButton, { SelectButtonOption } from 'components/SelectButton';
import { statusMapping } from 'pages/scannedDocuments/scannedDocuments.mappings';
import { UPDATE_MANY_SCANNED_DOCUMENTS } from 'pages/scannedDocuments/scannedDocuments.mutations';
import { GET_SCANNED_DOCUMENTS } from 'pages/scannedDocuments/scannedDocuments.queries';
import {
  SCANNED_DOCUMENTS_STATUSES,
  ScannedDocumentStatus,
} from 'pages/scannedDocuments/scannedDocuments.types';
import { useState } from 'react';

type ChangeStatusBulkActionProps = {
  ids: string[];
  modalIsShown: boolean;
  closeModal: () => void;
};

export const ChangeStatusBulkAction = ({
  ids,
  modalIsShown,
  closeModal,
}: ChangeStatusBulkActionProps) => {
  const visibleStatuses = SCANNED_DOCUMENTS_STATUSES.filter(
    (status) => status !== 'PENDING_UPLOAD'
  );

  const statusOptions = visibleStatuses.map((status) => ({
    id: status,
    label: statusMapping(status).text.text,
    color: statusMapping(status).color,
    title: statusMapping(status).text.text,
  }));

  const [selectedStatusOption, setSelectedStatusOption] = useState<
    SelectButtonOption<ScannedDocumentStatus>
  >(statusOptions[0]);

  const [changeStatus, { loading }] = useMutation(
    UPDATE_MANY_SCANNED_DOCUMENTS,
    {
      variables: {
        ids,
        status: selectedStatusOption.id,
      },
      refetchQueries: [GET_SCANNED_DOCUMENTS],
    }
  );

  const handleConfirm = async () => {
    const result = await changeStatus();

    if (!result.errors) {
      closeModal();
    }
  };

  const documentCount = ids.length;

  return (
    <>
      {modalIsShown && (
        <Modal
          open={modalIsShown}
          setOpen={closeModal}
          title="Change status?"
          confirmButtonLabel="Change"
          handleConfirm={handleConfirm}
          loading={loading}
        >
          <div className="text-sm text-gray-600">
            <p className="mb-2 mt-4 font-bold text-gray-900">New status</p>
            <div className="rounded-md bg-gray-200 p-3">
              <SelectButton
                withDot={true}
                options={statusOptions}
                selected={selectedStatusOption}
                label="Status"
                handleOnChange={setSelectedStatusOption}
                disabled={false}
              />
            </div>
            <p className="mt-6">
              Do you really want to the status of{' '}
              <span className="font-bold">{documentCount}</span> document(s) to{' '}
              <span className="font-bold">
                &quot;{selectedStatusOption.label}&quot;
              </span>
              ?
            </p>
          </div>
        </Modal>
      )}
    </>
  );
};
