import ComboBox from 'components/ComboBox';
import { ComboBoxOption } from 'components/ComboBox/ComboBox';

import TextInformation, { TextInformationProps } from '../TextInformation';
import * as styles from './styles';

const ComboBoxInformation = ({
  cell,
  id,
  editMode,
  editable,
  handleChange,
  handleDropdownChange,
  handleMultipleDropdownChange,
  dropdownOptions,
  optionMapping,
  value,
  multipleOptions,
  placeholder,
  useExternalQuery,
  setExternalQuery,
}: TextInformationProps) => {
  if (!dropdownOptions) {
    throw new Error('Dropdown options missing');
  }

  if (!optionMapping) {
    throw new Error('Option mapping function missing');
  }

  const handleOptionChange = (option: ComboBoxOption) => {
    if (handleDropdownChange && !multipleOptions) {
      handleDropdownChange(option, id);
    }
  };

  const handleMultipleOptionsChange = (options: ComboBoxOption[]) => {
    if (handleMultipleDropdownChange && multipleOptions) {
      handleMultipleDropdownChange(options, id);
    }
  };

  const getSelectedOption = () => {
    if (multipleOptions) {
      const options = value?.split(',').map((option) => option.trim());
      return dropdownOptions.filter((option) => options?.includes(option.id));
    }
    return dropdownOptions.find((option) => option.id === value);
  };

  const getMappedValue = () => {
    if (multipleOptions && Array.isArray(cell)) {
      return cell
        .toString()
        .split(',')
        .map((option) => optionMapping(option.trim()))
        .join(', ');
    }
    return optionMapping(cell.toString());
  };

  /**
   * TODO: [KONG] Refactor component to display a string
   * but store a collection of strings in an array.
   *
   * Skipping refactoring as updated logic might affect other components
   *  */
  if (editMode && editable) {
    return (
      <ComboBox
        className={styles.dropdown}
        selectedOption={getSelectedOption()}
        options={dropdownOptions}
        setSelectedOption={
          multipleOptions ? handleMultipleOptionsChange : handleOptionChange
        }
        placeholder={placeholder ?? ''}
        multiple={multipleOptions}
        useExternalQuery={useExternalQuery}
        setExternalQuery={setExternalQuery}
        useUnfilteredOptions
      />
    );
  }

  return (
    <TextInformation
      editMode={editMode}
      editable={editable}
      cell={getMappedValue() ?? ''}
      handleChange={handleChange}
      id={id}
    />
  );
};

export default ComboBoxInformation;
