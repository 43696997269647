import Page404 from 'pages/errors/404';
import { useParams } from 'react-router';
import { Country } from 'shared/countries/models';

import { getTemplateDataByInsuranceType } from '../data';
import { getTemplateInsuranceTypeCountry } from '../models/InsuranceTypes';
import PoliciesOverviewTemplate from '../PoliciesOverviewTemplate/PoliciesOverviewTemplate';
import { getDbInsuranceType, isValidTemplateInsurancePath } from '../utils';

const PoliciesOverviewTemplateGuard = () => {
  const { insuranceType, country } = useParams<{
    insuranceType: string;
    country: Country;
  }>();

  if (!isValidTemplateInsurancePath(insuranceType)) {
    return <Page404 />;
  }

  const dbInsuranceType = getDbInsuranceType(insuranceType);

  if (!getTemplateInsuranceTypeCountry()[dbInsuranceType].includes(country)) {
    return <Page404 />;
  }

  const templateData = getTemplateDataByInsuranceType(country)[dbInsuranceType];

  return (
    <PoliciesOverviewTemplate
      templateData={templateData}
      insuranceType={dbInsuranceType}
    />
  );
};

export default PoliciesOverviewTemplateGuard;
