import { DetailedHTMLProps, LabelHTMLAttributes } from 'react';

interface Props
  extends DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  > {
  className?: string;
  title: string;
  optional?: boolean;
}

export const FormInputLabel = ({
  className = '',
  title,
  optional = false,
  ...labelProps
}: Props) => {
  return (
    <label
      className={`text-sm font-bold text-gray-900 ${className}`}
      {...labelProps}
    >
      {title}{' '}
      {optional && (
        <span className="text-sm font-bold text-gray-500">(optional)</span>
      )}
    </label>
  );
};
