import { useTranslation } from '@getpopsure/i18n-react';

import * as styles from './TaskPreviewModal.styles';

export const LinkPreview = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.rowFullWidth}>
      <p className={styles.rowText}>
        {t(
          'taskEngine.taskPreviewModal.linkPreview.label',
          'Customer marked the task as completed.'
        )}
      </p>
    </div>
  );
};
