import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import { HeaderConfig } from 'components/TableRefactor/types';

export const privateHealthTableHeaders: HeaderConfig[] = [
  {
    id: 'first-name',
    label: 'First name',
    width: 'w-[180px]',
    minWidth: 'min-w-[180px]',
    sticky: true,
    threshold: 'left-0',
    border: 'none',
    component: SortableColumnHeader,
  },
  {
    id: 'last-name',
    label: 'Last name',
    width: 'w-[180px]',
    minWidth: 'min-w-[180px]',
    sticky: true,
    threshold: 'left-[180px]',
    border: 'none',
    component: SortableColumnHeader,
  },
  {
    id: 'status',
    label: 'Status',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    sticky: true,
    threshold: 'left-[360px]',
    border: 'right',
    component: SortableColumnHeader,
  },
  {
    id: 'amount',
    label: 'Amount',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: ColumnHeader,
  },
  {
    id: 'created-at',
    label: 'Created on',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: SortableColumnHeader,
  },
  {
    id: 'claim-type',
    label: 'Claim type',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    component: ColumnHeader,
  },
  {
    id: 'insurance-number',
    label: 'Policy number',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    component: ColumnHeader,
  },
  {
    id: 'tariff',
    label: 'Tariff',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: ColumnHeader,
  },
];
