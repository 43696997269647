import { gql } from '@apollo/client';

export const UPDATE_EXPAT_POLICY_INFORMATION = gql`
  mutation UpdateExpatHealthPolicy(
    $id: String!
    $source: String
    $campaign: String
    $medium: String
    $term: String
    $content: String
    $endDate: String
    $policyNumber: String
  ) {
    updateExpatHealthPolicyInformation(
      source: $source
      campaign: $campaign
      medium: $medium
      content: $content
      term: $term
      endDate: $endDate
      policyNumber: $policyNumber
      id: $id
    ) {
      endDate
      policyNumber
      id
      source
      campaign
      medium
      content
      term
    }
  }
`;
