export const SystemTasksLoader = () => (
  <div
    data-testid="system-tasks-loader"
    className="mt-[24px] animate-pulse opacity-80"
  >
    <div className="mb-4 h-12 w-full rounded-lg bg-gray-300" />
    <div className="h-12 w-full rounded-lg bg-gray-300" />
  </div>
);

export const SystemTasksPlaceholder = () => (
  <div className="mt-[24px] flex w-full flex-col items-center justify-center rounded-lg bg-white px-4 py-10 text-gray-900">
    <h3 className="mb-1">No tasks</h3>
    <p className="text-center text-sm text-gray-600">
      System created tasks appear here
    </p>
  </div>
);
