import { gql } from '@apollo/client';

export const UPDATE_LEGAL_POLICY = gql`
  mutation UpdateLegalPolicy(
    $insuranceType: String!
    $id: String!
    $policyNumber: String!
    $startDate: String!
    $price: Float!
    $source: String!
    $campaign: String
    $medium: String
    $term: String
    $content: String
    $addOns: [String]
    $partnerName: JSON
    $address: JSON
  ) {
    updateLegalPolicyInfo(
      insuranceType: $insuranceType
      id: $id
      policyNumber: $policyNumber
      startDate: $startDate
      price: $price
      source: $source
      campaign: $campaign
      medium: $medium
      content: $content
      term: $term
      addOns: $addOns
      partnerName: $partnerName
      address: $address
    ) {
      id
      policyNumber
      startDate
      price
      source
      campaign
      medium
      content
      term
      partnerName {
        firstName
        lastName
      }
      address {
        houseNumber
        street
        city
        additionalInformation
        postcode
        country
      }
    }
  }
`;
