import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import {
  LegalPlan,
  legalPlanMapper,
} from 'shared/insurances/planMappers/legal';
import { legalStatusMapper } from 'shared/insurances/statusMappers/legal';
import { LegalStatus } from 'shared/insurances/types';

const statusFilterOptions: SelectButtonOption<LegalStatus>[] = [
  {
    id: 'PENDING',
    label: legalStatusMapper.PENDING,
    title: legalStatusMapper.PENDING,
  },
  {
    id: 'ACTIVE',
    label: legalStatusMapper.ACTIVE,
    title: legalStatusMapper.ACTIVE,
  },
  {
    id: 'CANCELED',
    label: legalStatusMapper.CANCELED,
    title: legalStatusMapper.CANCELED,
  },
];

const planFilterOptions: SelectButtonOption<LegalPlan>[] = [
  {
    id: 'BASIC',
    label: legalPlanMapper.BASIC,
    title: legalPlanMapper.BASIC,
  },
  {
    id: 'ADVANCED',
    label: legalPlanMapper.ADVANCED,
    title: legalPlanMapper.ADVANCED,
  },
];

export const tableFilters: FilterConfig[] = [
  {
    options: statusFilterOptions,
    filterType: 'MULTIPLE',
    label: 'Status',
    id: 'filterStatus',
    placeholder: 'Select status(es)',
  },
  {
    options: planFilterOptions,
    filterType: 'MULTIPLE',
    label: 'Plan',
    id: 'filterPlan',
    placeholder: 'Select plan(s)',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Start date',
    id: 'filterStartDate',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Created on',
    id: 'filterCreatedAt',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Archived on',
    id: 'filterArchivedAt',
  },
];
