import { useMutation, useReactiveVar } from '@apollo/client';
import Button from 'components/Button';
import dayjs from 'dayjs';
import { useState } from 'react';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { downloadBlob } from 'shared/utils/downloadBlob';
import { getMimeType } from 'shared/utils/getMimeType';
import { v4 as uuidv4 } from 'uuid';

import { EXPORT_QONTO_TRANSFERS_CSV } from '../../graphql/exportCSVFile';
import { GET_QONTO_TRANSACTIONS } from '../../graphql/transactions';
import {
  expatTransactionInsuranceTypes,
  TransactionInsuranceType,
} from '../../models/TransactionInsuranceType';
import ExportConfirmationModal from '../ExportConfirmationModal';

const ExportButton = ({
  insuranceType,
}: {
  insuranceType: TransactionInsuranceType;
}) => {
  const [openModal, setOpenModal] = useState(false);

  const alertBannersState = useReactiveVar(alertBanners);

  const [exportCSV, { loading }] = useMutation(EXPORT_QONTO_TRANSFERS_CSV, {
    refetchQueries: [GET_QONTO_TRANSACTIONS],
    onCompleted: () => {
      setOpenModal(false);

      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message: 'Transactions successfully exported as CSV file.',
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
    onError: () => {
      setOpenModal(false);

      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: 'Something went wrong. Please try again.',
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
  });

  const fetchBlob = async (url: string) => {
    const response = await fetch(url ?? '', {
      method: 'GET',
      headers: {
        'Content-Type': getMimeType('export.csv'),
      },
    });
    let blob = await response.blob();
    if (!blob.type) {
      blob = new Blob([blob], {
        type: getMimeType('export.csv'),
      });
    }

    return blob;
  };

  const handleClickOnExport = () => {
    exportCSV({
      variables: {
        insuranceType,
      },
    }).then(async (result) => {
      const fileUrl = result.data.exportTransfersCSV;
      const blob = await fetchBlob(fileUrl);
      const insuranceTypeName = expatTransactionInsuranceTypes.includes(
        insuranceType
      )
        ? 'expat'
        : insuranceType.toLowerCase();
      downloadBlob(
        blob,
        `${insuranceTypeName} claims qonto export - ${dayjs().format(
          'DD/MM/YYYY'
        )}.csv`
      );
    });
  };

  return (
    <>
      <Button
        buttonType="primary"
        className="w-32"
        onClick={() => setOpenModal(true)}
      >
        Export as CSV
      </Button>
      {openModal && (
        <ExportConfirmationModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          handleClickOnExport={handleClickOnExport}
          loadingExport={loading}
          insuranceType={insuranceType}
        />
      )}
    </>
  );
};

export default ExportButton;
