import { useTranslation } from '@getpopsure/i18n-react';
import Modal from 'components/Modal';

interface ResetCommissionEntitlementModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleConfirm: (status: 'NONE') => void;
  loading: boolean;
}

export const ResetCommissionEntitlementModal = ({
  open,
  setOpen,
  handleConfirm,
  loading,
}: ResetCommissionEntitlementModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t(
        'admin.internal.policy.public.commissioncheck.modal.reset.title',
        'Reset the commission entitlement?'
      )}
      open={open}
      setOpen={setOpen}
      handleConfirm={() => handleConfirm('NONE')}
      confirmButtonLabel={t(
        'admin.internal.policy.public.commissioncheck.modal.reset.action.label',
        'Reset'
      )}
      textLoading={t(
        'admin.internal.policy.public.commissioncheck.modal.reset.loading',
        'Resetting...'
      )}
      loading={loading}
    >
      <span className="text-sm text-gray-600">
        {t(
          'admin.internal.policy.public.commissioncheck.modal.reset.description',
          'Resetting the commission entitlement will remove the current status. The provider can always set back the commission.'
        )}
      </span>
    </Modal>
  );
};
