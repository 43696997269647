import { useTranslation } from '@getpopsure/i18n-react';
import Header from 'components/Header';
import { Helmet } from 'react-helmet';

import * as styles from './styles';

const Page403 = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>Access Denied - Feather Admin Panel</title>
      </Helmet>
      <div>
        <Header />
        <div className={styles.contentWrapper}>
          <img
            src="/static/error-states/feathers.svg"
            alt="feathers"
            className={styles.feathers}
          />
          <h1 className={styles.title}>
            {t('admin.error.accessdenied.title', 'Access denied')}
          </h1>
          <span className={styles.text}>
            {t(
              'admin.error.accessdenied.description',
              "Sorry, it looks like you don't have permissions to access this page."
            )}
          </span>
        </div>
      </div>
    </>
  );
};

export default Page403;
