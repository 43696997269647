import { gql } from '@apollo/client';

export const GET_EXPAT_POLICIES = gql`
  query GetExpatHealthPolicies(
    $offset: Float!
    $limit: Float!
    $sortColumn: String!
    $sortOrder: String!
    $searchString: String!
    $filterStatus: [String]!
    $filterPlan: [String]
    $filterView: String
    $endDateRangeStart: DateTime
    $endDateRangeEnd: DateTime
    $activeUntilRangeStart: DateTime
    $activeUntilRangeEnd: DateTime
    $createdAtRangeStart: DateTime
    $createdAtRangeEnd: DateTime
  ) {
    expatPolicies(
      offset: $offset
      limit: $limit
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      searchString: $searchString
      filterStatus: $filterStatus
      filterPlan: $filterPlan
      endDateRangeStart: $endDateRangeStart
      endDateRangeEnd: $endDateRangeEnd
      activeUntilRangeStart: $activeUntilRangeStart
      activeUntilRangeEnd: $activeUntilRangeEnd
      createdAtRangeStart: $createdAtRangeStart
      createdAtRangeEnd: $createdAtRangeEnd
      filterView: $filterView
    ) {
      activeUntilDate
      createdAt
      endDate
      id
      insuranceType
      publicStatus
      policyNumber
      startDate
      archivedAt
      source
      campaign
      medium
      content
      term
      insuredPerson {
        firstName
        lastName
      }
      plan
      user {
        id
        firstName
        lastName
      }
    }
    totalExpatPolicies(
      searchString: $searchString
      filterStatus: $filterStatus
      filterPlan: $filterPlan
      endDateRangeStart: $endDateRangeStart
      endDateRangeEnd: $endDateRangeEnd
      activeUntilRangeStart: $activeUntilRangeStart
      activeUntilRangeEnd: $activeUntilRangeEnd
      createdAtRangeStart: $createdAtRangeStart
      createdAtRangeEnd: $createdAtRangeEnd
      filterView: $filterView
    )
  }
`;
