import classNames from 'classnames';

export const cardWrapper = classNames('flex');

export const cardFull = classNames(
  'flex',
  'items-center',
  'justify-center',
  'space-x-1',
  'pb-[38px]',
  'pt-[6px]'
);

export const cardSmall = classNames(
  'flex',
  'flex-col',
  'items-start',
  'space-y-1',
  'justify-start',
  'pb-[38px]',
  'pt-[6px]'
);

export const darkText = classNames('text-sm', 'text-gray-900');
export const lightText = classNames('text-sm', 'text-gray-600');
export const veryLightText = classNames('text-sm', 'text-gray-500');

export const inlineText = classNames('inline');
