import classNames from 'classnames';
import { BadgeColor } from 'components/Badge/Badge';
import { CheckBadgeType } from 'components/CheckBadge/CheckBadge';
import Input from 'components/Input';
import { SelectMenuOption } from 'components/SelectMenu';
import { PolicyStatus } from 'models/publicHealthStatus';
import { ChangeEvent } from 'react';

import { MultipleLineRow } from '..';
import * as styles from './styles';

export interface TextInformationProps {
  cell:
    | JSX.Element
    | string
    | boolean
    | string[]
    | number
    | Record<string, unknown>;
  multipleCells?: MultipleLineRow[];
  statusMapping?: (status: PolicyStatus) => {
    color: BadgeColor;
    text: { text: string; id: string };
  };
  editable?: boolean;
  editMode?: boolean;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeOnTextbox?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  handleDateChange?: (date: Date, id: string) => void;
  handleDropdownChange?: (option: SelectMenuOption, id: string) => void;
  handleMultipleDropdownChange?: (
    options: SelectMenuOption[],
    id: string
  ) => void;
  handleToggleChange?: (value: string, id: string) => void;
  handleArrayChange?: (value: string[], id: string) => void;
  dropdownOptions?: SelectMenuOption[];
  optionMapping?: (option: string) => string | undefined;
  multipleOptions?: boolean;
  placeholder?: string;
  value?: string;
  id: string;
  className?: string;
  href?: string;
  externalLink?: boolean;
  useExternalQuery?: boolean;
  setExternalQuery?: (query: string) => void;
  checklist?: {
    id?: string;
    title: string;
    check: boolean;
  }[];
  errorMessage?: string;
  check?: CheckBadgeType;
  detailsLabel?: string;
  detailsTitle?: string;
  renderModalDetails?: (
    setOpen: (prevValue: boolean) => void,
    modalOpen: boolean
  ) => JSX.Element;
  isModalScrollable?: boolean;
  onClick?: (metaData: Record<string, any>) => void;
}

const TextInformation = ({
  cell,
  editMode,
  editable,
  value,
  handleChange,
  id,
  className,
  placeholder,
  errorMessage,
}: TextInformationProps) => {
  return editable && editMode ? (
    <Input
      className={styles.input}
      id={id}
      type="text"
      value={String(value)}
      onChange={handleChange}
      color="gray"
      placeholder={placeholder}
      error={!!errorMessage}
      errorMessage={errorMessage}
    />
  ) : (
    <span className={classNames(className, styles.rowData)}>
      {cell as string}
    </span>
  );
};

export default TextInformation;
