import { EditComponentDefaultProps } from 'components/InformationCardV2/models';
import Input from 'components/Input';
import Modal from 'components/Modal';
import React, { useState } from 'react';

interface InformationCardTextWithDetailsProps
  extends EditComponentDefaultProps {
  onChange?: (value: string) => void;
  placeholder?: string;
  value?: string;
  detailsLabel: string;
  detailsTitle: string;
  renderModalDetails: (
    setOpen: (prevValue: boolean) => void,
    modalOpen: boolean
  ) => JSX.Element;
}

export const InformationCardTextWithDetails = ({
  shouldDisplayEditField = false,
  value,
  onChange,
  placeholder,
  detailsLabel,
  renderModalDetails,
  detailsTitle,
  error,
}: InformationCardTextWithDetailsProps) => {
  const [details, setDetails] = useState(false);
  if (shouldDisplayEditField) {
    return (
      <Input
        className="w-[264px] !px-[12px] !py-[9px]"
        type="text"
        value={value ?? ''}
        onChange={(e) => onChange?.(e.target.value)}
        color="gray"
        placeholder={placeholder}
        error={!!error}
        errorMessage={error}
      />
    );
  }

  return (
    <>
      <span className="flex w-full justify-between text-sm text-gray-900">
        <span>{value} </span>
        <button
          type="button"
          className="ml-4 font-normal text-indigo-500 hover:text-indigo-600"
          onClick={() => setDetails(true)}
        >
          {detailsLabel ?? 'See details'}
        </button>
      </span>
      <Modal
        open={details}
        setOpen={setDetails}
        title={detailsTitle ?? ''}
        hideActions={true}
      >
        {renderModalDetails(setDetails, details)}
      </Modal>
    </>
  );
};
