import { useMutation } from '@apollo/client';
import Modal from 'components/Modal';
import { UPDATE_MANY_SCANNED_DOCUMENTS } from 'pages/scannedDocuments/scannedDocuments.mutations';
import { GET_SCANNED_DOCUMENTS } from 'pages/scannedDocuments/scannedDocuments.queries';

type UnprocessDocumentBulkActionProps = {
  ids: string[];
  modalIsShown: boolean;
  closeModal: () => void;
};

export const UnprocessDocumentBulkAction = ({
  ids,
  modalIsShown,
  closeModal,
}: UnprocessDocumentBulkActionProps) => {
  const [unprocessDocuments, { loading }] = useMutation(
    UPDATE_MANY_SCANNED_DOCUMENTS,
    {
      variables: {
        ids,
        processed: 'No',
      },
      refetchQueries: [GET_SCANNED_DOCUMENTS],
    }
  );

  const handleConfirm = async () => {
    const result = await unprocessDocuments();

    if (!result.errors) {
      closeModal();
    }
  };

  const documentCount = ids.length;

  return (
    <>
      {modalIsShown && (
        <Modal
          open={modalIsShown}
          setOpen={closeModal}
          title="Un-process documents?"
          confirmButtonLabel="Un-process"
          handleConfirm={handleConfirm}
          loading={loading}
        >
          <div className="text-sm text-gray-600">
            <p>
              Do you really want to un-process{' '}
              <span className="font-bold">{documentCount}</span> document(s)?
            </p>
          </div>
        </Modal>
      )}
    </>
  );
};
