import { gql } from '@apollo/client';

export const CREATE_HOUSEHOLD_CLAIM = gql`
  mutation CreateHouseholdClaim(
    $insuranceType: String!
    $userId: String!
    $policyId: String!
    $policyNumber: String
    $status: String!
    $claimType: [String!]!
    $eventDescription: String
    $amount: Float
    $iban: String
    $dateOfEvent: String
    $uploadDocument: Upload
  ) {
    createHouseholdClaim(
      insuranceType: $insuranceType
      userId: $userId
      policyId: $policyId
      policyNumber: $policyNumber
      status: $status
      claimType: $claimType
      dateOfEvent: $dateOfEvent

      eventDescription: $eventDescription
      amount: $amount
      iban: $iban
      uploadDocument: $uploadDocument
    ) {
      userPolicyId
      status
      user {
        id
        firstName
        lastName
      }
      claimType {
        id
        createdAt
        type
      }
      amount
      eventDescription
      iban
      dateOfEvent
    }
  }
`;
