import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css';
import './index.css';

import { language } from '@getpopsure/i18n-react';
import { de, enIE } from 'date-fns/locale';
import DatePicker, { registerLocale } from 'react-datepicker';

registerLocale('de', de);
registerLocale('en', enIE);

interface CalendarDatePickerProps {
  id?: string;
  selectedDate?: Date;
  endDate?: Date;
  onChange: (date: Date | [Date, Date] | null) => void;
  className?: string;
  range?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

const CalendarDatePicker = ({
  id,
  selectedDate,
  onChange,
  className,
  endDate,
  range,
  minDate,
  maxDate,
}: CalendarDatePickerProps) => {
  return (
    <DatePicker
      id={id}
      showYearDropdown
      selected={range ? null : selectedDate}
      onChange={onChange}
      dateFormat="dd/MM/yyyy"
      dateFormatCalendar="MMMM"
      calendarStartDay={1}
      className={className}
      locale={language()}
      startDate={range ? selectedDate : null}
      endDate={range ? endDate : null}
      selectsRange={range}
      placeholderText={range ? 'DD/MM/YYYY - DD/MM/YYYY' : 'DD/MM/YYYY'}
      minDate={minDate}
      maxDate={maxDate}
      dropdownMode="select"
    />
  );
};

export default CalendarDatePicker;
