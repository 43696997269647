import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import { HeaderConfig, RowConfig } from 'components/TableRefactor/types';
import { Status } from 'models/statusColor';
import { legalPlanMapper } from 'shared/insurances/planMappers/legal';
import { policyRouteMapper } from 'shared/insurances/policyRouteMapper';
import {
  legalBadgeColorMapper,
  legalStatusMapper,
} from 'shared/insurances/statusMappers/legal';
import { LegalStatus } from 'shared/insurances/types';

import { LegalPolicy } from '../models/LegalPolicy';

export const tableHeaders: HeaderConfig[] = [
  {
    id: 'firstName',
    label: 'First name',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    sticky: true,
    threshold: 'left-0',
    border: 'none',
    component: SortableColumnHeader,
  },
  {
    id: 'lastName',
    label: 'Last name',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    sticky: true,
    threshold: 'left-[140px]',
    border: 'none',
    component: SortableColumnHeader,
  },
  {
    id: 'status',
    label: 'Status',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    sticky: true,
    threshold: 'left-[280px]',
    border: 'right',
    component: SortableColumnHeader,
  },
  {
    id: 'startDate',
    label: 'Start date',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: SortableColumnHeader,
  },
  {
    id: 'policyNumber',
    label: 'Policy number',
    width: 'w-[140px]',
    minWidth: 'min-w-[140px]',
    component: SortableColumnHeader,
  },
  {
    id: 'plan',
    label: 'Plan',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: ColumnHeader,
  },
  {
    id: 'createdAt',
    label: 'Created on',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: SortableColumnHeader,
  },
  {
    id: 'source',
    label: 'Source',
    width: 'w-[120px]',
    minWidth: 'min-w-[120px]',
    component: ColumnHeader,
  },
];

const displayArchivedStatus = () =>
  ({
    text: {
      id: 'ARCHIVED',
      text: 'Archived',
    },
    color: 'gray',
  } as Status);

const statusMapping = (status: string) => ({
  text: {
    id: status,
    text: legalStatusMapper[status as LegalStatus],
  },
  color: legalBadgeColorMapper[status as LegalStatus] ?? 'gray',
});

export const rowConfig: RowConfig = {
  getResourceLink: (data: LegalPolicy) => policyRouteMapper.LEGAL(data.id),
  cells: [
    {
      type: 'TEXT',
      props: {
        getText: (data: LegalPolicy) =>
          data.insuredPerson?.firstName ?? data.user?.firstName ?? '',
      },
      textColor: 'dark',
    },
    {
      type: 'TEXT',
      props: {
        getText: (data: LegalPolicy) =>
          data.insuredPerson?.lastName ?? data.user?.lastName ?? '',
      },
      textColor: 'dark',
    },
    {
      type: 'STATUS',
      textColor: 'dark',
      props: {
        getStatus: (data: LegalPolicy) => {
          if (data.archivedAt) return displayArchivedStatus();
          return statusMapping(data.publicStatus);
        },
      },
    },
    {
      type: 'DATE',
      props: { getDate: (data: LegalPolicy) => data.startDate ?? '' },
      textColor: 'light',
    },
    {
      type: 'TEXT',
      props: { getText: (data: LegalPolicy) => data.policyNumber ?? '' },
      textColor: 'light',
    },
    {
      type: 'TEXT',
      props: {
        getText: (data: LegalPolicy) =>
          data.legalPlanId ? legalPlanMapper[data.legalPlanId] : '',
      },
      textColor: 'light',
    },
    {
      type: 'DATE',
      props: { getDate: (data: LegalPolicy) => data.createdAt ?? '' },
      textColor: 'light',
    },
    {
      type: 'TEXT',
      props: { getText: (data: LegalPolicy) => data.source ?? '' },
      textColor: 'light',
    },
  ],
};
