import { SelectButtonOption } from 'components/SelectButton';
import { StatusUpdateFormQuestion } from 'components/StatusUpdateModal/models';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';
import { StatusData } from 'pages/claims/template/models';
import {
  deprecatedHouseholdClaimStatuses,
  HouseholdClaimStatus,
  householdClaimStatuses,
} from 'shared/claims/household/models';
import { policyMappers } from 'shared/insurances';

export const householdClaimStatusOptions: SelectButtonOption<HouseholdClaimStatus>[] =
  householdClaimStatuses.map((statusId) => ({
    id: statusId,
    label: policyMappers.HOUSEHOLD?.claim?.claimStatusMapper[statusId] ?? '',
    title: policyMappers.HOUSEHOLD?.claim?.claimStatusMapper[statusId] ?? '',
    color: policyMappers.HOUSEHOLD?.claim?.badgeColorMapper[statusId],
    ...(deprecatedHouseholdClaimStatuses.includes(statusId) && {
      disabled: true,
    }),
  }));

export const retrieveFormAndFooterTextByStatus = (
  status: HouseholdClaimStatus
): StatusData => ({
  form: formMapper[status],
  footerText: footerTextMapper(status),
});

const uploadForm: StatusUpdateFormQuestion = {
  id: 'uploadDocument',
  label: 'Upload document',
  data: {
    type: 'UPLOAD',
    fileName: {
      placeholder: 'Enter document friendly name',
      id: 'fileName',
    },
  },
};

const payoutAmountForm: StatusUpdateFormQuestion = {
  id: 'payoutAmount',
  label: 'Payout amount',
  data: { type: 'CURRENCY', placeholder: '0.00' },
  required: true,
};

const featherPaidOnForm: StatusUpdateFormQuestion = {
  id: 'featherPaidOn',
  label: 'Feather paid on',
  data: {
    type: 'DATE',
    defaultValue: dayjs().format(DATE_FORMAT),
    max: dayjs().format(DATE_FORMAT),
  },
  required: true,
};

const customerPaidOnForm: StatusUpdateFormQuestion = {
  id: 'customerPaidOn',
  label: 'Customer paid on',
  data: {
    type: 'DATE',
    defaultValue: dayjs().format(DATE_FORMAT),
    max: dayjs().format(DATE_FORMAT),
  },
  required: true,
};

const thirdPartyPaidOnForm: StatusUpdateFormQuestion = {
  id: 'thirdPartyPaidOn',
  label: '3rd party paid on',
  data: {
    type: 'DATE',
    defaultValue: dayjs().format(DATE_FORMAT),
    max: dayjs().format(DATE_FORMAT),
  },
  required: true,
};

const reasonForm: StatusUpdateFormQuestion = {
  id: 'reason',
  label: 'Reason',
  data: { type: 'TEXT_AREA', placeholder: 'Start typing here...' },
};

const formMapper: Record<HouseholdClaimStatus, StatusUpdateFormQuestion[]> = {
  DENIED: [uploadForm],
  INCOMPLETE: [uploadForm],
  ON_HOLD: [uploadForm],
  MISSING_INFO_RECEIVED: [uploadForm],
  SUBMITTED_TO_PROVIDER: [],
  IN_REVIEW: [],
  FEATHER_PAID_OUT: [payoutAmountForm, featherPaidOnForm, uploadForm],
  CUSTOMER_PAID_OUT: [payoutAmountForm, customerPaidOnForm, uploadForm],
  OTHER_PAID_OUT: [payoutAmountForm, thirdPartyPaidOnForm, uploadForm],
  DUPLICATE: [uploadForm],
  DROPPED_OUT: [uploadForm],
  RECEIVED: [reasonForm],
  CLOSED: [uploadForm],
  APPROVED_WITH_OTHER_CLAIMS: [],
};

const noFooterTextStatuses: HouseholdClaimStatus[] = [
  'SUBMITTED_TO_PROVIDER',
  'RECEIVED',
  'ON_HOLD',
  'IN_REVIEW',
];

const footerTextMapper = (status: HouseholdClaimStatus): string | undefined =>
  !noFooterTextStatuses.includes(status)
    ? 'Once confirmed, claim status will be updated and documents will be added to the claim.'
    : undefined;
