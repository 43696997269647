import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { InformationRow, InformationSection } from 'components/InformationCard';
import DateInformation from 'components/InformationCard/DateInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { DogLiabilityPolicy } from 'pages/policies/dogLiability/models/DogLiabilityPolicy';
import { policyMappers } from 'shared/insurances';

export const retrievePolicyInfoRows = (
  policyInfo: DogLiabilityPolicy
): InformationSection[] => [
  {
    title: 'Policy information',
    boldTitle: false,
    rows: [
      {
        id: 'status',
        title: 'Status',
        data:
          policyMappers.DOG_LIABILITY?.statusMapper[policyInfo?.publicStatus] ??
          '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
      {
        id: 'policyNumber',
        title: 'Policy number',
        data: policyInfo?.policyNumber ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
      {
        id: 'startDate',
        title: 'Start date',
        data: policyInfo?.startDate ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: false,
      },
      ...(policyInfo?.publicStatus === 'CANCELED'
        ? [
            {
              id: 'activeUntil',
              title: 'Active until',
              data: policyInfo?.activeUntil ?? '',
              type: 'DATE',
              component: DateInformation,
              editable: false,
            } as InformationRow,
          ]
        : []),
      {
        id: 'dogName',
        title: 'Dog name',
        data: policyInfo?.dogName ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
      {
        id: 'price',
        title: 'Price',
        data: policyInfo?.price
          ? englishFormattedEuroCurrency(policyInfo.price)
          : '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
    ],
  },
];
