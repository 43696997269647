import { OperationVariables } from '@apollo/client';
import {
  DocumentSection,
  DocumentsSection,
} from 'components/DocumentsSection/DocumentsSection';
import { SelectMenuOption } from 'components/SelectMenu';
import { DocumentNode } from 'graphql';

interface SideSectionProps {
  id: string;
  documentsData?: {
    sections: DocumentSection[];
    options: SelectMenuOption[];
    uploadMutation?: DocumentNode;
    deleteFileMutation?: DocumentNode;
    getPolicyQuery: DocumentNode;
    additionalQueries?: OperationVariables;
  };
}

const HRSideSection = ({ id, documentsData }: SideSectionProps) => {
  return documentsData ? (
    <div className="w-full lg:ml-[20px] lg:w-auto">
      <DocumentsSection sections={documentsData.sections} />
    </div>
  ) : (
    <></>
  );
};

export default HRSideSection;
