import { TFunction } from '@getpopsure/i18n-react';
import { RowConfig } from 'components/TableRefactor/types';
import { retrieveHRPoliciesDetailsPath } from 'pages/policies/template/utils';

import { CompanyHealthPolicy } from '../models/CompanyHealthPolicy';
import { companyHealthStatusMapper } from '../utils';

export const getRowConfig = (t: TFunction, company: string): RowConfig => ({
  getResourceLink: (data: CompanyHealthPolicy) =>
    retrieveHRPoliciesDetailsPath({
      insuranceType: 'company-health',
      id: data.id,
      company,
      country: 'de',
    }),
  cells: [
    {
      type: 'TEXT',
      textColor: 'dark',
      props: {
        getText: (data: CompanyHealthPolicy) => data.user?.firstName ?? '',
      },
    },
    {
      type: 'TEXT',
      textColor: 'dark',
      props: {
        getText: (data: CompanyHealthPolicy) => data.user?.lastName ?? '',
      },
    },
    {
      type: 'STATUS',
      textColor: 'light',
      props: {
        getStatus: (data: CompanyHealthPolicy) =>
          companyHealthStatusMapper(t)(data.publicStatus),
      },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: {
        getText: (data: CompanyHealthPolicy) =>
          data.healthDetails?.yearlyBudget ?? '',
      },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: {
        getText: (data: CompanyHealthPolicy) =>
          data.healthDetails?.employerContribution ?? '',
      },
    },
    {
      type: 'DATE',
      textColor: 'light',
      props: {
        getDate: (data: CompanyHealthPolicy) => data.startDate ?? '',
      },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: {
        getText: (data: CompanyHealthPolicy) => data.policyNumber ?? '',
      },
    },
  ],
});
