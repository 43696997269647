import type { DocumentNode } from '@apollo/client';
import Button from 'components/Button';
import { TaskList } from 'components/TaskEngine';
import { SystemTasks } from 'components/TaskEngine/SystemTasks';
import type { IssueWithCorrectTypes } from 'models/issue';
import { useState } from 'react';
import { useParams } from 'react-router';
import { InsuranceType } from 'shared/insurances/types';
import { IssueCategoryCombined } from 'shared/issues/shared/models';

import { CreateIssueModal } from './components/CreateIssueModal';
import { IssueCard } from './components/IssueCard';
import fadingServerIcon from './icons/fading-server.svg';
import plusIcon from './icons/plus.svg';

interface IssuesTabProps {
  issues: IssueWithCorrectTypes[];
  refetchQueries: DocumentNode[];
  issueCategories: IssueCategoryCombined[];
  issueCategoryMapper: Record<IssueCategoryCombined, string>;
  createIssueMutation: DocumentNode;
  resolveIssueMutation: DocumentNode;
  reOpenIssueMutation: DocumentNode;
  userId: string;
  insuranceType: InsuranceType;
  taskContext?: string;
}

export const IssuesTab = ({
  issues,
  refetchQueries,
  createIssueMutation,
  issueCategories,
  issueCategoryMapper,
  resolveIssueMutation,
  reOpenIssueMutation,
  userId,
  taskContext,
  insuranceType,
}: IssuesTabProps) => {
  const { id: policyOrClaimId } = useParams<{ id: string }>();

  const [openCreateIssueModal, setOpenCreateIssueModal] = useState(false);

  const onClickPlusIcon = () => {
    setOpenCreateIssueModal(true);
  };

  if (!policyOrClaimId) {
    throw new Error('[Issues tab] Neither policy id or claim id found');
  }

  return (
    <>
      {openCreateIssueModal && (
        <CreateIssueModal
          isOpen={openCreateIssueModal}
          setIsOpen={(isOpen: boolean) => setOpenCreateIssueModal(isOpen)}
          policyOrClaimId={policyOrClaimId}
          createIssueMutation={createIssueMutation}
          issueCategories={issueCategories}
          issueCategoryMapper={issueCategoryMapper}
          refetchQueries={refetchQueries}
        />
      )}
      <div className="animate-appear-in">
        <header className="flex items-center justify-between">
          <h1 className="text-lg font-bold leading-6 text-gray-900">Issues</h1>
          <Button buttonType="white" onClick={onClickPlusIcon}>
            <img src={plusIcon} alt="plus icon" />
          </Button>
        </header>
        <div className="mt-[24px]">
          {issues && issues?.length > 0 ? (
            issues.map((issue) => (
              <IssueCard
                key={issue.id}
                issue={issue}
                resolveIssueMutation={resolveIssueMutation}
                refetchQueries={refetchQueries}
                reOpenIssueMutation={reOpenIssueMutation}
                policyOrClaimId={policyOrClaimId}
              >
                <TaskList
                  taskContext={taskContext}
                  issueStatus={issue.status}
                  issueId={issue.id}
                  userId={userId}
                  tasks={issue.tasks}
                  refetchQueries={refetchQueries}
                  policyOrClaimId={policyOrClaimId}
                  insuranceType={insuranceType}
                />
              </IssueCard>
            ))
          ) : (
            <div className="flex h-[345px] w-[704px] items-center justify-center rounded-[8px] bg-white">
              <div className="flex flex-col items-center">
                <img alt="grey servers" src={fadingServerIcon} />
                <h4 className="text-md mt-[16px] text-gray-900">
                  No issues yet
                </h4>
                <p className="mt-[8px] text-sm text-gray-600">
                  You can create a new one above
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="ml-16 w-full animate-appear-in">
        <header className="flex h-[34px] items-center">
          <h1 className="text-lg font-bold leading-6 text-gray-900">
            System tasks
          </h1>
        </header>
        <SystemTasks policyOrClaimId={policyOrClaimId} />
      </div>
    </>
  );
};
