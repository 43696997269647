import { gql } from '@apollo/client';

export const GET_CUSTOMER = gql`
  query GetCustomer($userId: String!) {
    customer(userId: $userId) {
      id
      firstName
      lastName
      email
      dateOfBirth
      gender
      language
      intercomEUId
      stripeToken
      created
      isDelinquent
      isFraudulent
      fraudulentAdditionalInfo
      policies {
        id
        insuranceType
        startDate
        price
        publicStatus
        insuredPerson {
          firstName
          lastName
        }
        providerId
        regionOfPurchase
      }
      paymentMethods {
        id
        createdAt
        isDefault
        type
        iban
        updatedAt
        details {
          brand
          expMonth
          expYear
          last4
          payerEmail
          payerId
        }
      }

      pinnedNotes {
        id
        body
        createdAt
        adminUser {
          firstName
          lastName
        }
        updatedAt
      }
    }
  }
`;
