import { InformationRow, MultipleLineRow } from 'components/InformationCard';
import MultipleLineInformation from 'components/InformationCard/MultipleLineInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import lowerCase from 'lodash/lowerCase';
import upperFirst from 'lodash/upperFirst';

export const mapAnswersToInformationCard = (
  answers: object,
  multipleLineKeys: string[]
): InformationRow[] => {
  return Object.entries(answers).flatMap(([key, value]) => {
    if (
      value &&
      typeof value === 'object' &&
      !value.code &&
      !Array.isArray(value)
    ) {
      if (multipleLineKeys.includes(key)) {
        return {
          id: key,
          title: upperFirst(lowerCase(key)),
          multipleLinesData: mapAnswerToMultipleLinesRow(value),
          type: 'MULTIPLELINE',
          component: MultipleLineInformation,
        };
      }
      return mapAnswersToInformationCard(value, multipleLineKeys);
    }

    return {
      id: key,
      title: upperFirst(lowerCase(key)),
      data:
        typeof value === 'object'
          ? JSON.stringify(value, null, ' ')
          : value.toString(),
      type: 'TEXT',
      component: TextInformation,
    };
  });
};

const mapAnswerToMultipleLinesRow = (
  nestedValue: object
): MultipleLineRow[] => {
  return Object.entries(nestedValue).flatMap(([key, value]) => {
    if (
      value &&
      typeof value === 'object' &&
      !value.code &&
      !Array.isArray(value)
    ) {
      return mapAnswerToMultipleLinesRow(value);
    }

    return {
      label: upperFirst(lowerCase(key)),
      data:
        typeof value === 'object'
          ? JSON.stringify(value, null, ' ')
          : value.toString(),
    };
  });
};
