import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import { DownloadIcon } from '@heroicons/react/outline';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { DocumentNode, FieldNode, OperationDefinitionNode } from 'graphql';
import { useEffect } from 'react';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { downloadBlob } from 'shared/utils/downloadBlob';
import { fetchBlob } from 'shared/utils/fetchBlob';
import { v4 as uuidv4 } from 'uuid';

export const DownloadAllButton = ({
  mutation,
  resourceId,
  query,
  archiveFileName = 'Documents_archive',
}: {
  mutation: DocumentNode;
  query: DocumentNode;
  resourceId: string;
  archiveFileName?: string;
}) => {
  const { t } = useTranslation();
  const alertBannersState = useReactiveVar(alertBanners);

  const mutationName = (
    (mutation.definitions as OperationDefinitionNode[])[0].selectionSet
      .selections[0] as FieldNode
  ).name.value;
  const queryName = (
    (query.definitions as OperationDefinitionNode[])[0].selectionSet
      .selections[0] as FieldNode
  ).name.value;

  const [downloadAll, { data: workflowData, loading }] = useMutation(mutation, {
    variables: {
      id: resourceId,
    },
    onCompleted: () => {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message: t(
          'admin.documents.downloadAll.success.title',
          'Combining documents into zip file... Wait a few minutes.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
    onError: () => {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: t(
          'admin.documents.downloadAll.error.title',
          'Something went wrong. Please try again.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
  });

  const {
    data: archiveData,
    startPolling,
    stopPolling,
  } = useQuery(query, {
    variables: { workflowId: workflowData?.[mutationName] },
    notifyOnNetworkStatusChange: true,
    skip: !workflowData?.[mutationName],
  });

  useEffect(() => {
    if (workflowData?.[mutationName]) {
      startPolling(500);
    }
  }, [workflowData, startPolling, mutationName]);

  useEffect(() => {
    async function downloadArchive() {
      if (archiveData?.[queryName]) {
        stopPolling();
        const blob = await fetchBlob(archiveData[queryName], 'application/zip');
        downloadBlob(blob, archiveFileName);
      }
    }
    downloadArchive();
  }, [archiveData, stopPolling, queryName, archiveFileName]);

  const showLoading = Boolean(
    loading || (workflowData && !archiveData?.[queryName])
  );

  return (
    <Button
      buttonType="secondary"
      onClick={() => downloadAll()}
      data-testid="download-all-button"
      disabled={showLoading}
    >
      {showLoading ? (
        <Loader className="h-[20px] w-[20px] animate-spin" />
      ) : (
        <DownloadIcon className="h-[20px] text-indigo-500" />
      )}
    </Button>
  );
};
