import { gql } from '@apollo/client';

export const GENERATE_CLAIM_NUMBER = gql`
  mutation GenerateClaimNumber($claimId: String!) {
    generateClaimNumber(claimId: $claimId) {
      id
      claimNumber
    }
  }
`;
