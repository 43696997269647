import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import FileDropzone from 'components/FileDropzone';
import Input from 'components/Input';
import Modal from 'components/Modal';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import { DocumentNode } from 'graphql';
import { useState } from 'react';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

export const UploadButton = ({
  mutation,
  documentCategories,
  getResourceQuery,
  resourceId,
  documentVisibilityOptions,
  displayInternalNotes = true,
}: {
  mutation: DocumentNode;
  documentCategories: SelectMenuOption[];
  documentVisibilityOptions?: SelectMenuOption[];
  resourceId: string;
  getResourceQuery?: DocumentNode;
  displayInternalNotes?: boolean;
}) => {
  const { t } = useTranslation();
  const alertBannersState = useReactiveVar(alertBanners);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File>();
  const [updatedDocument, setUpdatedDocument] = useState<
    | {
        friendlyName?: string;
        category?: string;
        internalNotes?: string;
        isVisibleToCustomer?: boolean;
        isVisibleToHr?: boolean;
        isVisibleToProvider?: boolean;
      }
    | undefined
  >({
    isVisibleToCustomer: true,
    isVisibleToHr: true,
    isVisibleToProvider: true,
  });
  const [nameError, setNameError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);

  const [uploadDocument, { loading }] = useMutation(mutation, {
    variables: {
      id: resourceId,
    },
    refetchQueries: getResourceQuery ? [getResourceQuery] : [],
    onCompleted: () => {
      setOpen(false);
      resetForm();
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message: t(
          'admin.documents.upload.success.title',
          'Document was uploaded successfully.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
    onError: () => {
      setOpen(false);
      resetForm();
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: t(
          'admin.documents.upload.error.title',
          'Something went wrong. Please try again.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
  });

  const resetForm = () => {
    setUpdatedDocument({
      isVisibleToCustomer: true,
      isVisibleToHr: true,
      isVisibleToProvider: true,
    });
    setFile(undefined);
  };

  const handleUploadDocument = () => {
    if (!file) return;

    if (updatedDocument?.category !== 'CUSTOMER_UPLOADS') {
      if (!updatedDocument?.friendlyName) {
        setNameError(true);
      }

      if (!updatedDocument?.category) {
        setCategoryError(true);
      }
    }

    if (
      (updatedDocument?.friendlyName && updatedDocument?.category) ||
      updatedDocument?.category === 'CUSTOMER_UPLOADS'
    ) {
      setCategoryError(false);
      setNameError(false);
      uploadDocument({
        variables: {
          id: resourceId,
          file,
          friendlyName: updatedDocument.friendlyName,
          category: updatedDocument.category,
          internalNotes: updatedDocument.internalNotes,
          isVisibleToCustomer: updatedDocument.isVisibleToCustomer,
          isVisibleToHr: updatedDocument.isVisibleToHr,
          isVisibleToProvider: updatedDocument.isVisibleToProvider,
        },
      });
    }
  };

  const handleOpenModal = () => {
    resetForm();
    setOpen(true);
  };

  const getVisibility = () => {
    const selected: SelectMenuOption[] = [];
    if (updatedDocument?.isVisibleToCustomer) {
      const customerOption = documentVisibilityOptions?.find(
        (option) => option.id === 'CUSTOMER'
      );
      if (customerOption) selected.push(customerOption);
    }
    if (updatedDocument?.isVisibleToHr) {
      const hrOption = documentVisibilityOptions?.find(
        (option) => option.id === 'HR'
      );
      if (hrOption) selected.push(hrOption);
    }
    if (updatedDocument?.isVisibleToProvider) {
      const providerOption = documentVisibilityOptions?.find(
        (option) => option.id === 'PROVIDER'
      );
      if (providerOption) selected.push(providerOption);
    }
    return selected;
  };

  const updateVisibility = (newSelected: SelectMenuOption[]) => {
    const customer = newSelected.find((option) => option.id === 'CUSTOMER');
    const hr = newSelected.find((option) => option.id === 'HR');
    const provider = newSelected.find((option) => option.id === 'PROVIDER');

    setUpdatedDocument({
      ...updatedDocument,
      isVisibleToCustomer: !!customer,
      isVisibleToHr: !!hr,
      isVisibleToProvider: !!provider,
    });
  };

  return (
    <>
      <Button
        type="button"
        className="w-[88px]"
        onClick={() => handleOpenModal()}
        buttonType="secondary"
      >
        {t('admin.documents.action.upload.label', 'Upload')}
      </Button>
      <Modal
        title={t('admin.documents.uploadModal.title', 'Upload document')}
        open={open}
        setOpen={setOpen}
        confirmButtonLabel={t(
          'admin.documents.uploadModal.confirm.label',
          'Confirm'
        )}
        loading={loading}
        handleConfirm={handleUploadDocument}
        disabled={!file}
      >
        <div className="flex flex-col space-y-4">
          <FileDropzone
            uploadedFile={file}
            onFileSelect={setFile}
            onFileDelete={() => setFile(undefined)}
            color="gray"
          />

          {file && (
            <>
              {updatedDocument?.category !== 'CUSTOMER_UPLOADS' && (
                <div className="flex items-center justify-between">
                  <label className="text-sm font-bold" htmlFor="name">
                    {t('admin.documents.uploadModal.name.label', 'Name')}
                  </label>
                  <Input
                    id="name"
                    color="gray"
                    className="w-[288px]"
                    placeholder={t(
                      'admin.documents.uploadModal.name.placeholder',
                      'User-friendly name'
                    )}
                    value={updatedDocument?.friendlyName}
                    onChange={(e) =>
                      setUpdatedDocument({
                        ...updatedDocument,
                        friendlyName: e.target.value,
                      })
                    }
                    error={nameError}
                    errorMessage={t(
                      'admin.documents.uploadModal.error.label',
                      'This field is required'
                    )}
                  />
                </div>
              )}
              <div>
                <SelectMenu
                  color="gray"
                  options={documentCategories}
                  selected={documentCategories.find(
                    (option) => option.id === updatedDocument?.category
                  )}
                  setSelected={(newSelected: SelectMenuOption) =>
                    setUpdatedDocument({
                      ...updatedDocument,
                      category: newSelected.id,
                    })
                  }
                  placeholder={t(
                    'admin.documents.uploadModal.category.placeholder',
                    'Document category'
                  )}
                  label={t(
                    'admin.documents.uploadModal.category.label',
                    'Category'
                  )}
                  horizontal
                  className="w-[288px]"
                  error={categoryError}
                  errorMessage={t(
                    'admin.documents.uploadModal.error.label',
                    'This field is required'
                  )}
                />
              </div>
              {documentVisibilityOptions &&
                updatedDocument?.category !== 'CUSTOMER_UPLOADS' && (
                  <div>
                    <SelectMenu
                      color="gray"
                      options={documentVisibilityOptions}
                      selected={getVisibility()}
                      setSelected={updateVisibility}
                      placeholder={t(
                        'admin.documents.uploadModal.visibility.placeholder',
                        'Visibility option(s)'
                      )}
                      label={t(
                        'admin.documents.uploadModal.visibility.label',
                        'Visibility'
                      )}
                      horizontal
                      className="w-[288px]"
                      multiple
                    />
                  </div>
                )}
              {displayInternalNotes &&
                updatedDocument?.category !== 'CUSTOMER_UPLOADS' && (
                  <div className="flex items-center justify-between">
                    <label className="text-sm font-bold" htmlFor="notes">
                      {t('admin.documents.uploadModal.notes.label', 'Notes')}
                    </label>
                    <Input
                      id="notes"
                      color="gray"
                      className="w-[288px]"
                      placeholder={t(
                        'admin.documents.uploadModal.notes.placeholder',
                        'Internal notes'
                      )}
                      value={updatedDocument?.internalNotes}
                      onChange={(e) =>
                        setUpdatedDocument({
                          ...updatedDocument,
                          internalNotes: e.target.value,
                        })
                      }
                    />
                  </div>
                )}
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
