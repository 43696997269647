import { SelectMenuOption } from 'components/SelectMenu';

import {
  hiMedicalPlusTariffNames,
  hiMedicalTariffNames,
  ksTariffNames,
  nkSelectLTariffNames,
  nkSelectSTariffNames,
  nkSelectXLTariffNames,
  nkTariffNames,
  primoPlusTariffNames,
  primoTariffNames,
} from './mapTariffNames';

export type PrimoDeductible = '0' | '300' | '600' | '1200';
export type NKDeductible = '0' | '300' | '600' | '1200' | '3000';
export type NKSelectDeductible = '0' | '600' | '1200' | '3000';
export type HiMedicalDeductible = '500' | '1200';
export type KSDeductible = '0' | '300' | '600' | '1200';

const primoDeductible: Record<PrimoDeductible, string> = {
  0: '€0 (Primo.Bonus Z)',
  300: '€300 (Primo.SB1Z)',
  600: '€600  (Primo.SB2Z)',
  1200: '€1200 (Primo.SB3Z)',
};

const primoPlusDeductible: Record<PrimoDeductible, string> = {
  0: '€0 (Primo.Bonus Z plus)',
  300: '€300 (Primo.SB1Z plus)',
  600: '€600  (Primo.SB2Z plus)',
  1200: '€1200 (Primo.SB3Z plus)',
};

const nkDeductible: Record<NKDeductible, string> = {
  0: '€0 (NK.bonus)',
  300: '€300 (NK.3)',
  600: '€600 (NK.2)',
  1200: '€1200 (NK.1)',
  3000: '€3000 (NK.4)',
};

const nkSelectDeductible: Record<NKSelectDeductible, string> = {
  0: '€0 (Bonus)',
  600: '€600',
  1200: '€1200',
  3000: '€3000',
};

const hiMedicalDeductible: Record<HiMedicalDeductible, string> = {
  500: '€500',
  1200: '€1200',
};

const ksDeductible: Record<KSDeductible, string> = {
  0: '€0 (KS.bonus)',
  300: '€300 (KS.3)',
  600: '€600 (KS.1)',
  1200: '€1200 (KS.2)',
};

export const getDeductible = (tariff: string, deductible: string) => {
  if (primoTariffNames.includes(tariff))
    return primoDeductible[deductible as PrimoDeductible];
  if (primoPlusTariffNames.includes(tariff))
    return primoPlusDeductible[deductible as PrimoDeductible];
  if (ksTariffNames.includes(tariff))
    return ksDeductible[deductible as KSDeductible];
  if (nkTariffNames.includes(tariff))
    return nkDeductible[deductible as NKDeductible];
  if (nkSelectXLTariffNames.includes(tariff))
    return nkSelectDeductible[deductible as NKSelectDeductible];
  if (nkSelectLTariffNames.includes(tariff))
    return nkSelectDeductible[deductible as NKSelectDeductible];
  if (nkSelectSTariffNames.includes(tariff))
    return nkSelectDeductible[deductible as NKSelectDeductible];
  if (hiMedicalTariffNames.includes(tariff))
    return hiMedicalDeductible[deductible as HiMedicalDeductible];
  if (hiMedicalPlusTariffNames.includes(tariff))
    return hiMedicalDeductible[deductible as HiMedicalDeductible];
  return deductible;
};

const mapDeductibleGroup = (
  group: Record<string, string>
): SelectMenuOption<string>[] => {
  return Object.entries(group).map(([id, label]) => {
    return {
      id,
      label,
    };
  });
};

export const getDeductibleOptions = (tariff: string) => {
  let deductibleGroup;
  if (primoTariffNames.includes(tariff)) deductibleGroup = primoDeductible;
  if (primoPlusTariffNames.includes(tariff))
    deductibleGroup = primoPlusDeductible;
  if (ksTariffNames.includes(tariff)) deductibleGroup = ksDeductible;
  if (nkTariffNames.includes(tariff)) deductibleGroup = nkDeductible;
  if (nkSelectXLTariffNames.includes(tariff))
    deductibleGroup = nkSelectDeductible;
  if (nkSelectLTariffNames.includes(tariff))
    deductibleGroup = nkSelectDeductible;
  if (nkSelectSTariffNames.includes(tariff))
    deductibleGroup = nkSelectDeductible;
  if (hiMedicalTariffNames.includes(tariff))
    deductibleGroup = hiMedicalDeductible;
  if (hiMedicalPlusTariffNames.includes(tariff))
    deductibleGroup = hiMedicalDeductible;

  return mapDeductibleGroup(deductibleGroup ?? { 0: '€0' });
};
