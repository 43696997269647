import { ApolloError, useMutation, useReactiveVar } from '@apollo/client';
import OverviewPage from 'components/OverviewPage';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import Table from 'components/TableRefactor/Table';
import { HeaderConfig, RowConfig } from 'components/TableRefactor/types';
import { useState } from 'react';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import CreateDataRemovalRequest from './components/CreateDataRemovalRequest';
import DataRemovalRequestDetails from './components/DataRemovalRequestDetails';
import { HANDLE_REMOVAL_REQUEST } from './graphql/handleRequest';
import { GET_DATA_REMOVAL_REQUESTS } from './graphql/requests';
import { DataRemovalRequest } from './models';

const DataRemovalRequestsPage = () => {
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<
    DataRemovalRequest | undefined
  >(undefined);

  const alertBannersState = useReactiveVar(alertBanners);

  const [handleRequest] = useMutation(HANDLE_REMOVAL_REQUEST, {
    onCompleted: () => {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message:
          'The data removal request is being processed in the background.',
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
    onError: () => {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: 'Something went wrong. Please try again.',
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
  });

  const tableHeaders: HeaderConfig[] = [
    {
      id: 'customer',
      label: 'Customer',
      width: 'w-none',
      minWidth: 'min-w-[200px]',
      component: ColumnHeader,
    },
    {
      id: 'email',
      label: 'Email',
      width: 'w-none',
      component: ColumnHeader,
    },
    {
      id: 'status',
      label: 'Status',
      width: 'w-[64px]',
      minWidth: 'min-w-[64px]',
      component: ColumnHeader,
    },
    {
      id: 'action',
      label: 'Action',
      width: 'w-[80px]',
      minWidth: 'min-w-[80px]',
      component: ColumnHeader,
    },
    {
      id: 'dbRecordsDeletedAt',
      label: 'Database deleted on',
      width: 'w-[114px]',
      minWidth: 'min-w-[114px]',
      component: ColumnHeader,
    },
    {
      id: 'documentsDeletedAt',
      label: 'Documents deleted on',
      width: 'w-[114px]',
      minWidth: 'min-w-[114px]',
      component: ColumnHeader,
    },
    {
      id: 'stripeRecordsDeletedAt',
      label: 'Stripe subs. deleted on',
      width: 'w-[114px]',
      minWidth: 'min-w-[114px]',
      component: ColumnHeader,
    },
    {
      id: 'intercomRecordsDeletedAt',
      label: 'Intercom deleted on',
      width: 'w-[114px]',
      minWidth: 'min-w-[114px]',
      component: ColumnHeader,
    },
    {
      id: 'customerIoRecordsSuppressedAt',
      label: 'Customer.io deleted on',
      width: 'w-[114px]',
      minWidth: 'min-w-[114px]',
      component: ColumnHeader,
    },
  ];

  const rowConfig: RowConfig = {
    resourceOnClick: (data: DataRemovalRequest) => {
      setSelectedRequest(data);
      setDetailsModal(true);
    },
    cells: [
      {
        type: 'LINK',
        textColor: 'dark',
        props: {
          getLink: (data: DataRemovalRequest) => ({
            label:
              data.user && data.user.firstName && data.user.lastName
                ? `${data.user.firstName} ${data.user.lastName}`
                : '',
            href: data.user
              ? generatePath(paths.customers.details.path, {
                  id: data.user.id,
                })
              : '',
          }),
        },
      },
      {
        type: 'TEXT',
        textColor: 'dark',
        props: {
          getText: (data: DataRemovalRequest) => data.email,
        },
      },
      {
        type: 'CHECK',
        textColor: 'light',
        props: {
          getCheck: (data: DataRemovalRequest) => {
            if (data.status === 'SUCCESS') {
              return { check: 'check' };
            }

            if (
              data.status === 'PENDING' &&
              data.premoderationChecks.length > 0
            ) {
              return {
                check: 'error',
                badgeCaption: `Data deletion fails: ${data.premoderationChecks.join(
                  ', '
                )}`,
              };
            }

            return { check: 'exclamation' };
          },
        },
      },
      {
        type: 'BUTTON',
        textColor: 'light',
        props: {
          getButtonText: (data: DataRemovalRequest) =>
            data.status === 'PENDING' ? 'Delete' : '',
          onClick: (event) => {
            event.stopPropagation();
            if (event.data.status === 'PENDING') {
              handleRequest({ variables: { requestId: event.data.id } });
            }
          },
        },
      },
      {
        type: 'DATE',
        textColor: 'light',
        props: {
          getDate: (data: DataRemovalRequest) => data.dbRecordsDeletedAt,
        },
      },
      {
        type: 'DATE',
        textColor: 'light',
        props: {
          getDate: (data: DataRemovalRequest) => data.documentsDeletedAt,
        },
      },
      {
        type: 'DATE',
        textColor: 'light',
        props: {
          getDate: (data: DataRemovalRequest) => data.stripeRecordsDeletedAt,
        },
      },
      {
        type: 'DATE',
        textColor: 'light',
        props: {
          getDate: (data: DataRemovalRequest) => data.intercomRecordsDeletedAt,
        },
      },
      {
        type: 'DATE',
        textColor: 'light',
        props: {
          getDate: (data: DataRemovalRequest) =>
            data.customerIoRecordsSuppressedAt,
        },
      },
    ],
  };

  return (
    <OverviewPage
      title="Data removal requests - Feather Admin Panel"
      current="dataRemovalRequests"
      error={error}
    >
      <Table
        title="Data removal requests"
        query={GET_DATA_REMOVAL_REQUESTS}
        itemsPerPage={12}
        searchPlaceholder="Search requests by email"
        headers={tableHeaders}
        rowConfig={rowConfig}
        emptyStateLabel={{
          title: 'There are no data removal requests.',
          description: 'It looks like there are no data removal requests yet.',
        }}
        onError={setError}
        actionButton={<CreateDataRemovalRequest />}
      />
      <DataRemovalRequestDetails
        open={detailsModal}
        setOpen={setDetailsModal}
        request={selectedRequest}
        handleRequest={handleRequest}
      />
    </OverviewPage>
  );
};

export default DataRemovalRequestsPage;
