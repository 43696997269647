import classNames from 'classnames';

export const documentCard = classNames(
  'bg-gray-50',
  'rounded-[8px]',
  'mt-[8px]',
  'w-full',
  'py-3',
  'flex',
  'justify-between',
  'items-start',
  'has-[:focus]:outline-none',
  'has-[:focus]:ring-2',
  'has-[:focus]:ring-indigo-500',
  'has-[:focus]:ring-offset-1'
);

export const downloadLink = classNames(
  'flex',
  'items-start',
  'group',
  'pl-[12px]',
  'w-full',
  'h-full',
  'flex-col'
);

export const fileNameSection = classNames(
  'flex',
  'items-center',
  'break-all',
  'justify-between',
  'w-full'
);

export const fileIconContainer = classNames('w-[20px]');

export const fileIcon = classNames(
  'text-gray-500',
  'w-[20px]',
  'h-[20px]',
  'group-hover:text-indigo-500',
  'group-focus:text-indigo-500'
);

export const fileName = classNames(
  'ml-[12px]',
  'text-sm',
  'text-gray-900',
  'group-hover:text-indigo-500',
  'group-focus:text-indigo-500'
);

export const note = classNames(
  'text-xs',
  'text-gray-600',
  'text-wrap',
  'pl-[32px]',
  'pr-[44px]',
  'pt-1'
);

export const dotIconContainer = classNames('relative');

export const dotIconButton = classNames(
  'text-gray-400',
  'px-[16px]',
  'flex',
  'items-center',
  'hover:text-indigo-500',
  'focus:text-indigo-500',
  'focus:outline-none'
);

export const dotIcon = classNames('w-[20px]', 'h-[20px]');

export const dropdown = classNames(
  'absolute',
  'rounded-[4px]',
  'bg-white',
  'right-0',
  'top-7',
  'w-[200px]',
  'shadow-lg',
  'z-10',
  'flex',
  'flex-col',
  'px-4',
  'py-1',
  'items-start'
);

export const dropdownOption = classNames(
  'text-gray-600',
  'py-2',
  'text-sm',
  'hover:text-gray-900'
);

export const redDropdownOption = classNames(
  'text-red-600',
  'py-2',
  'text-sm',
  'hover:text-red-900'
);
