import { gql } from '@apollo/client';

export const GET_EXPAT_SPAIN_HEALTH_CLAIMS = gql`
  query GetExpatSpainHealthClaims(
    $offset: Float!
    $limit: Float!
    $searchString: String!
    $sortColumn: String!
    $sortOrder: String!
    $filterStatus: [String]!
    $createdAtRangeStart: DateTime
    $createdAtRangeEnd: DateTime
  ) {
    expatSpainHealthClaims(
      offset: $offset
      limit: $limit
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterStatus: $filterStatus
      createdAtRangeStart: $createdAtRangeStart
      createdAtRangeEnd: $createdAtRangeEnd
    ) {
      id
      status
      amount
      createdAt
      refundAmount
      featherPaidOn
      customerPaidOn
      user {
        firstName
        lastName
        isDelinquent
        isFraudulent
        fraudulentAdditionalInfo
      }
      policy {
        insuredPerson {
          firstName
          lastName
        }
      }
    }
    totalExpatSpainHealthClaims(
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterStatus: $filterStatus
      createdAtRangeStart: $createdAtRangeStart
      createdAtRangeEnd: $createdAtRangeEnd
    )
  }
`;
