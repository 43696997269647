import classNames from 'classnames';

export const searchBar = classNames(
  'relative',
  'rounded-[6px]',
  'w-[320px]',
  'z-5'
);

export const searchButton = classNames(
  'absolute',
  'inset-y-0',
  'left-0',
  'pl-3',
  'flex',
  'items-center',
  'disabled:cursor-default',
  'text-gray-400',
  'hover:text-indigo-500',
  'hover:disabled:text-gray-400'
);

export const searchIcon = classNames('h-[20px]', 'w-[20px]');

export const searchInput = classNames(
  'focus:ring-indigo-500',
  'outline-none',
  'focus:ring-1',
  'block',
  'w-full',
  'pl-10',
  'text-sm',
  'rounded-[6px]',
  'px-[16px]',
  'py-[10px]',
  'disabled:bg-gray-50',
  'pr-[40px]'
);

export const clearIconWrapper = classNames(
  'absolute',
  'inset-y-0',
  'right-0',
  'pr-3',
  'flex',
  'items-center',
  'hover:text-gray-600',
  'text-gray-400',
  'focus:text-gray-600'
);

export const clearIcon = classNames('h-5', 'w-5');

export const hidden = classNames('hidden');

export const placeholderDropdown = classNames(
  'absolute',
  'top-11',

  'rounded-[6px]',
  'bg-white',
  'w-full',
  'drop-shadow-lg',
  'z-10',

  'text-sm',
  'text-gray-600',

  'px-[16px]',
  'py-[12px]',

  'animate-appear-in-from-top'
);
