import classNames from 'classnames';

export const textarea = classNames(
  'w-full',
  'rounded-[6px]',
  'text-sm',
  'text-gray-900',
  'outline-none',
  'focus:ring-1',
  'focus:ring-indigo-500',
  'placeholder-gray-500',
  'disabled:text-gray-600',
  'px-[13px]',
  'py-[9px]'
);

export const textAreaWhite = classNames('bg-white', 'disabled:bg-gray-50');
export const textAreaGrey = classNames('bg-gray-200', 'disabled:bg-gray-300');

export const errorState = classNames('border-red-400', 'border');
export const errorMessage = classNames('text-xs', 'text-red-400');
