import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import { lifeStatusMapper } from 'shared/insurances/statusMappers/life';
import { LifeStatus } from 'shared/insurances/types';

const statusFilterOptions: SelectButtonOption<LifeStatus>[] = [
  {
    id: 'PENDING',
    label: lifeStatusMapper.PENDING,
    title: lifeStatusMapper.PENDING,
  },
  {
    id: 'APPLICATION_SENT',
    label: lifeStatusMapper.APPLICATION_SENT,
    title: lifeStatusMapper.APPLICATION_SENT,
  },
  {
    id: 'ACTIVE',
    label: lifeStatusMapper.ACTIVE,
    title: lifeStatusMapper.ACTIVE,
  },
  {
    id: 'CANCELED',
    label: lifeStatusMapper.CANCELED,
    title: lifeStatusMapper.CANCELED,
  },
];

export const tableFilters: FilterConfig[] = [
  {
    options: statusFilterOptions,
    filterType: 'MULTIPLE',
    label: 'Status',
    id: 'filterStatus',
    placeholder: 'Select status(es)',
  },
  /**
   * TODO: [KONG] Remove placeholder after Filter component refactor
   * This is a temporary solution to table component's filters rendering certain index positions
   */
  {
    filterType: 'DATE_RANGE',
    label: 'placeholder',
    id: 'placeholder',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Start date',
    id: 'filterStartDate',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Created on',
    id: 'filterCreatedAt',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Archived on',
    id: 'filterArchivedAt',
  },
];
