import { useMutation } from '@apollo/client';
import { TrashIcon } from '@heroicons/react/outline';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { DELETE_SD_CATEGORY } from 'pages/scannedDocuments/categories/sdCategories.mutations';
import { GET_SD_CATEGORIES } from 'pages/scannedDocuments/categories/sdCategories.queries';
import { SDCategory } from 'pages/scannedDocuments/categories/sdCategories.types';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { paths } from 'routes/definedPaths';

export const DeleteCategory = ({ sdCategory }: { sdCategory: SDCategory }) => {
  const history = useHistory();
  const { id } = sdCategory;

  const [showModal, setShowModal] = useState(false);

  const [deleteCategory, { loading }] = useMutation(DELETE_SD_CATEGORY, {
    variables: {
      sdCategoryId: id,
    },
    refetchQueries: [GET_SD_CATEGORIES],
  });

  const handleConfirm = async () => {
    const result = await deleteCategory();

    if (!result.errors) {
      setShowModal(false);
      history.push(paths.scannedDocuments.categories.path);
    }
  };

  return (
    <>
      {showModal && (
        <Modal
          open={showModal}
          setOpen={setShowModal}
          title="Delete category?"
          confirmButtonLabel="Delete"
          handleConfirm={handleConfirm}
          loading={loading}
        >
          <div className="text-sm text-gray-600">
            <p>
              Do you really want to delete this category?
              <p className="mt-3">
                This process{' '}
                <span className="font-bold">cannot be undone.</span>
              </p>
            </p>
          </div>
        </Modal>
      )}
      <Button buttonType="transparent" onClick={() => setShowModal(true)}>
        <span className="mr-2 w-5">
          <TrashIcon />
        </span>
        Delete category
      </Button>
    </>
  );
};
