import { GET_HOUSEHOLD_POLICIES } from 'pages/policies/household/graphql/policies';
import {
  HouseholdClaimStatus,
  HouseholdClaimType,
} from 'shared/claims/household/models';

import { ClaimsTemplateData } from '../template/models';
import { retrieveHouseholdClaimInfoRows } from './modules/claimsDetails/claimInfo/data';
import { UPDATE_HOUSEHOLD_CLAIM } from './modules/claimsDetails/graphql/mutations';
import { GET_HOUSEHOLD_CLAIM } from './modules/claimsDetails/graphql/queries';
import { retrieveHouseholdPolicyInfoRows } from './modules/claimsDetails/policyInfo/data';
import { retrieveCreateClaimFormData } from './modules/createClaim/data';
import { CREATE_HOUSEHOLD_CLAIM } from './modules/createClaim/graphql/mutations';
import { zodSubmittableClaimSchema } from './modules/createClaim/models';
import { filters } from './modules/overview/data/filters';
import { rowConfig } from './modules/overview/data/tableData';
import { tableHeaders } from './modules/overview/data/tableHeaders';
import { GET_HOUSEHOLD_CLAIMS } from './modules/overview/graphql/queries';
import {
  householdClaimStatusOptions,
  retrieveFormAndFooterTextByStatus,
} from './modules/statusUpdate/data';
import { UPDATE_HOUSEHOLD_CLAIM_STATUS } from './modules/statusUpdate/graphql/mutations';

export const householdClaimsData: ClaimsTemplateData<
  HouseholdClaimStatus,
  HouseholdClaimType
> = {
  insuranceType: 'HOUSEHOLD',

  // GraphQL -----------------------------------------
  queries: {
    fetchAllClaims: GET_HOUSEHOLD_CLAIMS,
    fetchSingleClaim: GET_HOUSEHOLD_CLAIM,
    fetchAllPolicies: GET_HOUSEHOLD_POLICIES,
  },

  mutations: {
    updateClaim: UPDATE_HOUSEHOLD_CLAIM,
    updateClaimStatus: UPDATE_HOUSEHOLD_CLAIM_STATUS,
    createClaim: CREATE_HOUSEHOLD_CLAIM,
  },

  // Features ----------------------------------------
  overview: {
    headers: tableHeaders,
    filterConfig: filters,
    rowConfig,
  },
  policyInfo: { retrievePolicyInfoRows: retrieveHouseholdPolicyInfoRows },
  claimInfo: {
    retrieveClaimInfoRows: retrieveHouseholdClaimInfoRows,
  },
  claimCreation: {
    retrieveFormData: retrieveCreateClaimFormData,
    createClaimSubmissionSchema: zodSubmittableClaimSchema,
  },
  statusUpdate: {
    dataByStatus: retrieveFormAndFooterTextByStatus,
    statusOptions: householdClaimStatusOptions,
  },
};
