import classNames from 'classnames';

export const toggleWrapper = classNames(
  'group',
  'relative',
  'inline-flex',
  'h-5',
  'w-10',
  'flex-shrink-0',
  'cursor-pointer',
  'items-center',
  'justify-center',
  'rounded-full',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-indigo-500',
  'focus:ring-offset-2'
);

export const toggle = classNames(
  'pointer-events-none',
  'absolute',
  'h-full',
  'w-full',
  'rounded-md',
  'bg-white'
);

export const toggleBody = classNames(
  'pointer-events-none',
  'absolute',
  'mx-auto',
  'h-4',
  'w-9',
  'rounded-full',
  'transition-colors',
  'duration-200',
  'ease-in-out'
);

export const circle = classNames(
  'pointer-events-none',
  'absolute',
  'left-0',
  'inline-block',
  'h-5',
  'w-5',
  'transform',
  'rounded-full',
  'border',
  'border-gray-400',
  'bg-white',
  'shadow',
  'ring-0',
  'transition-transform',
  'duration-200',
  'ease-in-out'
);
