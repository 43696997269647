import { gql } from '@apollo/client';

export const UPDATE_EXPAT_QUESTIONNAIRE = gql`
  mutation UpdateExpatQuestionnaire(
    $answers: String!
    $questionnaireId: String!
  ) {
    updateExpatHealthQuestionnaire(
      answers: $answers
      questionnaireId: $questionnaireId
    ) {
      id
      answers
      questionnaireType
    }
  }
`;
