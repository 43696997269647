import { gql } from '@apollo/client';

export const GET_CUSTOMERS = gql`
  query GetCustomers(
    $offset: Float!
    $limit: Float!
    $searchString: String!
    $sortColumn: String!
    $sortOrder: String!
  ) {
    customers(
      offset: $offset
      limit: $limit
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
    ) {
      id
      email
      firstName
      lastName
      created
      dateOfBirth
      isDelinquent
    }
    totalCustomers: totalCustomers(
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
    )
  }
`;
