import { SearchIcon } from '@heroicons/react/outline';
import { ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import React from 'react';

import * as styles from './styles';

export type InputColor = 'white' | 'gray';

export interface InputProps extends React.ComponentPropsWithRef<'input'> {
  error?: boolean;
  color: InputColor;
  search?: boolean;
  clearSearch?: () => void;
  errorMessage?: string;
  prefix?: string;
  suffix?: string;
}

const Input = React.forwardRef(
  (
    {
      className,
      color,
      clearSearch,
      search = false,
      error = false,
      errorMessage,
      prefix,
      suffix,
      ...props
    }: InputProps,
    ref?: React.ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <div>
        <div className={styles.inputWrapper}>
          {search && (
            <button
              className={styles.searchButton}
              type="submit"
              disabled={props.disabled}
            >
              <SearchIcon className={styles.searchIcon} aria-hidden="true" />
            </button>
          )}
          {prefix && (
            <div className={styles.prefixContainer}>
              <span className={styles.spanText}>{prefix}</span>
            </div>
          )}
          <input
            className={classNames(styles.input, className, {
              [styles.inputGray]: color === 'gray',
              [styles.inputWhite]: color === 'white',
              [styles.inputDisableGray]: props.disabled && color === 'gray',
              [styles.inputDisableWhite]: props.disabled && color === 'white',
              [styles.inputError]: error,
              [styles.inputFocus]: !error,
              [styles.searchInput]: search,
              [styles.inputWithPrefix]: Boolean(prefix),
            })}
            {...props}
            ref={ref}
          />
          {suffix && (
            <div className={styles.suffixContainer}>
              <span className={styles.spanText}>{suffix}</span>
            </div>
          )}
          {error && (
            <div className={classNames(styles.errorIconWrapper)}>
              <ExclamationCircleIcon
                className={styles.errorIcon}
                aria-hidden="true"
              />
            </div>
          )}
          {search && (
            <button
              className={classNames(styles.clearIconWrapper, {
                [styles.hidden]: props.value === '',
              })}
              onClick={clearSearch}
            >
              <XCircleIcon className={styles.clearIcon} />
            </button>
          )}
        </div>
        {error && <p className="mt-1 text-xs text-red-400">{errorMessage}</p>}
      </div>
    );
  }
);

export default Input;
