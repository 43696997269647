import { TFunction } from '@getpopsure/i18n-react';
import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import { GenericStatus } from 'shared/insurances/types';

const getStatusFilterOptions = (
  t: TFunction
): SelectButtonOption<GenericStatus>[] => [
  {
    id: 'PENDING',
    label: t('admin.hr.companyHealth.status.pending.label', 'Pending'),
    title: t('admin.hr.companyHealth.status.pending.label', 'Pending'),
  },
  {
    id: 'ACTIVE',
    label: t('admin.hr.companyHealth.status.covered.label', 'Covered'),
    title: t('admin.hr.companyHealth.status.covered.label', 'Covered'),
  },
  {
    id: 'CANCELED',
    label: t('admin.hr.companyHealth.status.canceled.label', 'Canceled'),
    title: t('admin.hr.companyHealth.status.canceled.label', 'Canceled'),
  },
  {
    id: 'DROPPED_OUT',
    label: t('admin.hr.companyHealth.status.droppedOut.label', 'Dropped out'),
    title: t('admin.hr.companyHealth.status.droppedOut.label', 'Dropped out'),
  },
];

export const getTableFilters = (t: TFunction): FilterConfig[] => [
  {
    id: 'filterStatus',
    options: getStatusFilterOptions(t),
    filterType: 'MULTIPLE',
    label: t('admin.hr.companyHealth.table.filter.status.label', 'Status'),
  },
];
