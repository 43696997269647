import LanguageSelector from 'components/LanguageSelector';

import * as styles from './styles';

const Header = () => {
  return (
    <nav className={styles.navbar}>
      <div className={styles.navbarContent}>
        <LanguageSelector />
      </div>
    </nav>
  );
};

export default Header;
