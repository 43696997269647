import { gql } from '@apollo/client';

export const UPDATE_INTERNAL_ISSUE_STATUS = gql`
  mutation UpdateInternalPublicHealthIssue(
    $userPolicyId: String!
    $issueId: String!
    $newStatus: String!
    $comment: String
    $documentId: String
  ) {
    updatePolicyIssue(
      userPolicyId: $userPolicyId
      issueId: $issueId
      newStatus: $newStatus
      comment: $comment
      documentId: $documentId
    ) {
      id
      status
    }
  }
`;
