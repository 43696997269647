import { RecentActivity } from 'components/ActivityLog/RecentActivity';
import { PinnedNotes } from 'components/Notes/PinnedNotes';
import { PrivateHealthPolicy } from 'models/privateHealthPolicy';
import { GET_POLICY_ACTIVITY } from 'shared/activityLog/queries';

import { GET_PRIVATE_POLICY } from '../../graphql/queries';
import DocumentsSection from '../DocumentsSection/DocumentsSection';

interface SideSectionProps {
  policy: PrivateHealthPolicy;
}

const SideSection = ({ policy }: SideSectionProps) => {
  return (
    <>
      <PinnedNotes
        notes={policy.pinnedNotes ?? []}
        refetchQueries={[GET_PRIVATE_POLICY]}
      />
      <RecentActivity
        query={GET_POLICY_ACTIVITY}
        variables={{ policyId: policy.id, userId: policy.user.id, pageSize: 3 }}
      />
      <DocumentsSection policy={policy} />
    </>
  );
};

export default SideSection;
