import { ApolloError } from '@apollo/client';
import NavBar from 'components/NavBar/NavBar';
import Page403 from 'pages/errors/403';
import PageGenericError from 'pages/errors/genericError';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { paths } from 'routes/definedPaths';
import { checkUnauthenticatedErrors } from 'shared/errorHandling/unauthenticatedError';
import { checkUnauthorizedErrors } from 'shared/errorHandling/unauthorizedErrors';

interface OverviewPageProps {
  error?: ApolloError;
  title: string;
  current: string;
  children?: React.ReactElement | (React.ReactElement | undefined)[];
}

const OverviewPage = ({
  error,
  title,
  current,
  children,
}: OverviewPageProps) => {
  const history = useHistory();

  if (error && checkUnauthenticatedErrors(error)) {
    history.push(paths.auth.path);
  }
  if (error && checkUnauthorizedErrors(error)) return <Page403 />;
  if (error) return <PageGenericError />;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="flex h-screen w-screen flex-col md:flex-row">
        <NavBar current={current} />
        <div className="w-full overflow-y-scroll pt-6 md:pt-[82px]">
          {children}
        </div>
      </div>
    </>
  );
};

export default OverviewPage;
