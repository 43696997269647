import { useLazyQuery, useMutation } from '@apollo/client';
import {
  AddOn,
  contributionsForTariff,
  Tariff,
} from '@getpopsure/private-health-insurance-pricing-engine';
import { roundToTwoDecimal } from '@getpopsure/private-health-insurance-pricing-engine/dist/cjs/util';
import { ArrowRightIcon, ExternalLinkIcon } from '@heroicons/react/solid';
import Button from 'components/Button';
import Modal from 'components/Modal';
import TextArea from 'components/TextArea';
import dayjs from 'dayjs';
import hashSum from 'hash-sum';
import { Policy } from 'models/policy';
import { TariffInfo } from 'models/tariffInfo';
import { getPolicyDetailsBaseURLFromInsuranceType } from 'pages/scannedDocuments/scannedDocuments.utils';
import { useState } from 'react';

import { CREATE_MANY_TARIFF_INFOS } from '../../graphql/mutations';
import { GET_TARIFF_INFOS_BY_SIMILAR_POLICY_NUMBERS } from '../../graphql/queries';

type FindTariffInfosBySimilarPolicyNumberData = {
  findTariffInfosBySimilarPolicyNumber: {
    tariffInfo: TariffInfo | null;
    policy: Policy;
  }[];
};

type BAPData = {
  AKTION_LAUF: string;
  AKTION_NAME: string;
  ANREDE_VN: string;
  BAP: string;
  BEZIRK: string;
  'Beitrag bisher': string;
  'Beitrag neu': string;
  'Differenz absolut': string;
  'Differenz prozentual': string;
  'Fremd-OB': string;
  'Fremd-OB-Typ': string;
  GEB_DATUM_VP: string;
  HKV_VM_NR: string;
  LAENDERKENNZEICHEN_VN: string;
  NACHNAME_VN: string;
  NACHNAME_VP: string;
  ORT_VN: string;
  Ort: string;
  PLZ: string;
  PLZ_VN: string;
  PTN_K_VP: string;
  SC: string;
  STRASSE_NR: string;
  STRASSE_NR_VN: string;
  'Steuerlicher Abzugsfaktor Tarif': string;
  'Summe Monatlich Absetzbarer Beitrag': string;
  TARIFBEZEICHNUNG: string;
  TARIFSTUFENBEZEICHNUNG: BAPTariffUnitName;
  TELEFONNUMMER_VN: string;
  TITEL_VN: string;
  VD: string;
  VERMITTLERNAME_1: string;
  VERMITTLERNAME_2: string;
  VORNAME_VN: string;
  VORNAME_VP: string;
  'VV-Nr.': string;
  VVP_WEG_K: string;
  VWH: string;
  'Vertrags-Nr.': string;
};

const BAP_TARIFF_UNIT_NAMES = [
  'NK.BON',
  'KT.43',
  'PVN',
  'URZ.',
  'KS.BON',
  'JOKER.FLEX',
  'KT.29',
  'KT.64',
  'KS.1',
  'NK.XLBON',
  'KS.2',
  'KT.22',
  'NK.1',
  'NK.4',
  'P.BONZ',
  'OLGAFLEX.RI',
  'P.BONZ+',
  'NK.XL3000',
  'P.SBZ 3',
  'CSR.120',
  'CAZ.30',
  'CAZ.120',
  'CSR.30',
  'CG.230',
  'CSD.100',
  'BEB.50',
  'CG.320',
  'PVB',
  'NK.FLEX',
] as const;
type BAPTariffUnitName = typeof BAP_TARIFF_UNIT_NAMES[number];

type DeductibleValue = '0' | '300' | '600' | '1200' | '3000';

type TariffUnitData =
  | {
      type: 'ADDON';
      tariffName?: undefined;
      addonName: AddOn | 'OLGAFLEX.RI';
      sickpayMinDays?: number;
      deductible?: undefined;
    }
  | {
      type: 'TARIFF';
      tariffName: Tariff;
      deductible: DeductibleValue;
      guaranteedYearlyBonus?: number;
      addonName?: undefined;
    }
  | {
      type: 'PVN';
      tariffName?: undefined;
      addonName?: undefined;
      deductible?: undefined;
    }
  | {
      type: 'UNSUPPORTED';
      tariffName?: undefined;
      addonName?: undefined;
      deductible?: undefined;
    };

const bapTariffUnitNameLookup: Record<BAPTariffUnitName, TariffUnitData> = {
  PVN: {
    type: 'PVN',
  },
  'URZ.': {
    type: 'ADDON',
    addonName: 'URZ',
  },
  'JOKER.FLEX': {
    type: 'ADDON',
    addonName: 'FLEX',
  },
  'KT.22': {
    type: 'ADDON',
    addonName: 'KT',
    sickpayMinDays: 22,
  },
  'KT.29': {
    type: 'ADDON',
    addonName: 'KT',
    sickpayMinDays: 29,
  },
  'KT.43': {
    type: 'ADDON',
    addonName: 'KT',
    sickpayMinDays: 43,
  },
  'KT.64': {
    type: 'ADDON',
    addonName: 'KT',
    sickpayMinDays: 64,
  },
  'KS.1': {
    type: 'TARIFF',
    tariffName: 'KS',
    deductible: '600',
  },
  'KS.2': {
    type: 'TARIFF',
    tariffName: 'KS',
    deductible: '1200',
  },
  'NK.BON': {
    type: 'TARIFF',
    tariffName: 'NK',
    deductible: '0',
  },
  'NK.1': {
    type: 'TARIFF',
    tariffName: 'NK',
    deductible: '1200',
  },
  'NK.4': {
    type: 'TARIFF',
    tariffName: 'NK',
    deductible: '600',
  },
  'KS.BON': {
    type: 'TARIFF',
    tariffName: 'KS',
    deductible: '0',
  },
  'NK.FLEX': {
    type: 'ADDON',
    addonName: 'NKSFLEX',
  },
  'NK.XLBON': {
    type: 'TARIFF',
    tariffName: 'NKSelectXL',
    deductible: '0',
  },
  'NK.XL3000': {
    type: 'TARIFF',
    tariffName: 'NKSelectXL',
    deductible: '3000',
  },
  'P.BONZ': {
    type: 'TARIFF',
    tariffName: 'Primo',
    deductible: '0',
  },
  'P.BONZ+': {
    type: 'TARIFF',
    tariffName: 'PrimoPlus',
    deductible: '0',
  },
  'P.SBZ 3': {
    type: 'TARIFF',
    tariffName: 'Primo',
    deductible: '1200',
  },
  'OLGAFLEX.RI': {
    type: 'UNSUPPORTED',
  },
  'CSR.120': {
    type: 'UNSUPPORTED',
  },
  'CAZ.30': {
    type: 'UNSUPPORTED',
  },
  'CAZ.120': {
    type: 'UNSUPPORTED',
  },
  'CSR.30': {
    type: 'UNSUPPORTED',
  },
  'CG.230': {
    type: 'UNSUPPORTED',
  },
  'CSD.100': {
    type: 'UNSUPPORTED',
  },
  'BEB.50': {
    type: 'UNSUPPORTED',
  },
  'CG.320': {
    type: 'UNSUPPORTED',
  },
  PVB: {
    type: 'UNSUPPORTED',
  },
};

const csvToJSON = (csv?: string): BAPData[] => {
  if (!csv) {
    return [];
  }
  const [headerLine, ...contentLines] = csv.split('\n');
  const keys = headerLine.split(';');

  const objArray = contentLines.map((line) => {
    const obj: Record<string, any> = {};

    const dataArr = line.split(';');

    keys.forEach((key, index) => {
      obj[key] = dataArr[index];
    });

    return obj;
  });

  return objArray as BAPData[];
};
const strToNum = (str: string): number => {
  return parseFloat(str.replace(',', '.'));
};

const propertyMapping: Record<string, keyof BAPData> = {
  policyNumber: 'Vertrags-Nr.',
  hallescheIdentifier: 'TARIFSTUFENBEZEICHNUNG',
  oldPrice: 'Beitrag bisher',
  newPrice: 'Beitrag neu',
  taxDeductible: 'Summe Monatlich Absetzbarer Beitrag',
  insuredPersonFirstName: 'VORNAME_VP',
  insuredPersonLastName: 'NACHNAME_VP',
  dateOfBirth: 'GEB_DATUM_VP',
};

const mapBAPDataToTariffUpdateData = (bapDataArray: BAPData[]) => {
  return bapDataArray.flatMap((bapData) => {
    const policyNumber = bapData[propertyMapping.policyNumber];

    const insuredPersonFullName = `${
      bapData[propertyMapping.insuredPersonFirstName]
    } ${bapData[propertyMapping.insuredPersonLastName]}`;

    const hallescheIdentifier = bapData[
      propertyMapping.hallescheIdentifier
    ] as BAPTariffUnitName;

    const { type, addonName, tariffName, deductible } =
      bapTariffUnitNameLookup[hallescheIdentifier];

    const featherIdentifier = tariffName || addonName;

    const oldPrice = strToNum(bapData[propertyMapping.oldPrice]);
    const newPrice = strToNum(bapData[propertyMapping.newPrice]);
    const taxDeductibleAmount = strToNum(
      bapData[propertyMapping.taxDeductible]
    );
    const dobStr = bapData[propertyMapping.dateOfBirth];
    const [month, day, shortYear] = dobStr.split('/');
    const year = shortYear <= '40' ? `20${shortYear}` : `19${shortYear}`;

    const dateOfBirth = `${year}-${month}-${day}`;

    return [
      {
        policyNumber,
        type,
        insuredPersonFullName,
        oldPrice,
        newPrice,
        taxDeductibleAmount,
        hallescheIdentifier,
        featherIdentifier,
        deductible,
        dateOfBirth,
      },
    ];
  });
};

type TariffInfoWithOptionalId = Omit<TariffInfo, 'id'> & { id?: string };

type HalleschePriceInfo = Partial<
  Omit<TariffInfo, 'tariffMonthlyPrice'> & {
    tariffPlusSurcharge: number;
    dateOfBirth: string;
  }
>;

type PartialTariffInfosLookup = Record<
  string, // policy number
  Record<
    string, // insured person full name
    {
      old: HalleschePriceInfo;
      new: HalleschePriceInfo;
      dbMatch?: {
        policy?: Policy;
        tariffInfo: TariffInfo | null;
      };
      mergedTariffInfo?: TariffInfoWithOptionalId;
    }
  >
>;

export const Reconciliation = () => {
  const [modalIsShown, setModalIsShown] = useState(false);
  const [csvContent, setCSVContent] = useState<string | undefined>();
  const [partialTariffInfosLookup, setPartialTariffInfosLookup] =
    useState<PartialTariffInfosLookup>({});
  const [mergedTariffInfos, setMergedTariffInfos] = useState<
    TariffInfoWithOptionalId[]
  >([]);
  const [matchText, setMatchText] = useState<string | undefined>();
  const [outDatedText, setOutDatedText] = useState<string | undefined>();
  const [createManyTariffInfos, { loading }] = useMutation(
    CREATE_MANY_TARIFF_INFOS
  );
  const [getTariffInfosBySimilarPolicyNumber] =
    useLazyQuery<FindTariffInfosBySimilarPolicyNumberData>(
      GET_TARIFF_INFOS_BY_SIMILAR_POLICY_NUMBERS
    );
  const openModal = () => setModalIsShown(true);
  const closeModal = () => setModalIsShown(false);

  const handleConfirm = async () => {
    const result = await createManyTariffInfos({
      variables: {
        tariffInfoData: mergedTariffInfos,
      },
    });

    if (!result.errors) {
      closeModal();
    }
  };

  const handleProcessClick = async () => {
    const bapDataArray = csvToJSON(csvContent);

    const tariffUpdateData = mapBAPDataToTariffUpdateData(bapDataArray);

    const policyNumbersWithoutDupes = Array.from(
      new Set(
        bapDataArray.map((bapData) => bapData[propertyMapping.policyNumber])
      )
    );

    const result = await getTariffInfosBySimilarPolicyNumber({
      variables: {
        policyNumbers: policyNumbersWithoutDupes,
      },
    });

    const policyTariffInfoPairs =
      result.data?.findTariffInfosBySimilarPolicyNumber;

    const newPartialTariffInfos = tariffUpdateData.reduce((acc, updateData) => {
      const {
        type,
        oldPrice,
        newPrice,
        taxDeductibleAmount,
        featherIdentifier,
        policyNumber,
        insuredPersonFullName,
        dateOfBirth,
      } = updateData;

      const roundedNewTaxDeductibleAmount = Number(
        (
          (acc[policyNumber]?.[insuredPersonFullName]?.new
            ?.taxDeductibleAmount ?? 0) + taxDeductibleAmount
        ).toFixed(2)
      );

      switch (type) {
        case 'ADDON':
          return {
            ...acc,
            [policyNumber]: {
              ...acc[policyNumber],
              [insuredPersonFullName]: {
                old: {
                  ...acc[policyNumber]?.[insuredPersonFullName]?.old,
                  addOns: [
                    ...(acc[policyNumber]?.[insuredPersonFullName]?.old
                      ?.addOns ?? []),
                    {
                      add_on: featherIdentifier ?? '',
                      monthly_price: oldPrice,
                    },
                  ],
                  taxDeductibleAmount: undefined,
                },
                new: {
                  ...acc[policyNumber]?.[insuredPersonFullName]?.new,
                  addOns: [
                    ...(acc[policyNumber]?.[insuredPersonFullName]?.new
                      ?.addOns ?? []),
                    {
                      add_on: featherIdentifier ?? '',
                      monthly_price: newPrice,
                    },
                  ],
                  taxDeductibleAmount: roundedNewTaxDeductibleAmount,
                },
              },
            },
          };
        case 'TARIFF':
          return {
            ...acc,
            [policyNumber]: {
              ...acc[policyNumber],
              [insuredPersonFullName]: {
                old: {
                  ...acc[policyNumber]?.[insuredPersonFullName]?.old,
                  tariff: featherIdentifier,
                  tariffPlusSurcharge: oldPrice,
                  taxDeductibleAmount: undefined,
                  dateOfBirth,
                },
                new: {
                  ...acc[policyNumber]?.[insuredPersonFullName]?.new,
                  tariff: featherIdentifier,
                  tariffPlusSurcharge: newPrice,
                  taxDeductibleAmount: roundedNewTaxDeductibleAmount,
                  dateOfBirth,
                },
              },
            },
          };
        case 'PVN':
          return {
            ...acc,
            [policyNumber]: {
              ...acc[policyNumber],
              [insuredPersonFullName]: {
                old: {
                  ...acc[policyNumber]?.[insuredPersonFullName]?.old,
                  longTermCare: oldPrice,
                  taxDeductibleAmount: undefined,
                },
                new: {
                  ...acc[policyNumber]?.[insuredPersonFullName]?.new,
                  longTermCare: newPrice,
                  taxDeductibleAmount: roundedNewTaxDeductibleAmount,
                },
              },
            },
          };
        case 'UNSUPPORTED':
        default:
          return {
            ...acc,
          };
      }
    }, {} as PartialTariffInfosLookup);

    const newMergedTariffInfos: TariffInfoWithOptionalId[] = [];
    let dbMatchCount = 0;

    Object.entries(newPartialTariffInfos).forEach(
      ([policyNumber, insuredPersonDataRecord]) => {
        Object.entries(insuredPersonDataRecord).forEach(
          ([insuredPersonFullName, tariffChangeData]) => {
            const dbMatch = policyTariffInfoPairs?.find(({ policy }) => {
              const hasSimilarPolicyNumber =
                policy.policyNumber?.includes(policyNumber);

              const hasSimilarName = insuredPersonFullName.includes(
                policy.insuredPerson?.firstName ?? ''
              );

              return hasSimilarPolicyNumber && hasSimilarName;
            });

            newPartialTariffInfos[policyNumber][insuredPersonFullName].dbMatch =
              dbMatch;

            if (!dbMatch) {
              return;
            }

            dbMatchCount++;

            const mergedTariffInfo = {
              ...dbMatch.tariffInfo,
              ...tariffChangeData.new,
            };

            const policyStartstartDate = dbMatch.policy.startDate ?? Date.now();
            const { dateOfBirth } = tariffChangeData.new;
            const ageInYearOfPolicyStartDate = dayjs(policyStartstartDate).diff(
              dateOfBirth,
              'years'
            );
            let hasStatutorySurcharge =
              ageInYearOfPolicyStartDate >= 21 &&
              ageInYearOfPolicyStartDate <= 60;

            if (
              mergedTariffInfo.tariff === 'HiMedical' ||
              mergedTariffInfo.tariff === 'HiMedicalPlus'
            ) {
              hasStatutorySurcharge = false;
            }
            const tariffPlusSurcharge =
              mergedTariffInfo.tariffPlusSurcharge ?? 0;
            const tariffPrice = hasStatutorySurcharge
              ? tariffPlusSurcharge / 1.1
              : tariffPlusSurcharge;
            const statutorySurcharge = tariffPlusSurcharge - tariffPrice;
            const longTermCare = mergedTariffInfo.longTermCare ?? 0;
            const riskFactorSurcharge =
              mergedTariffInfo.riskFactorSurcharge ?? 0;

            const addOnsPrice = roundToTwoDecimal(
              Object.values(mergedTariffInfo.addOns ?? {}).reduce(
                (sum, { monthly_price }) => sum + monthly_price,
                0
              ) ?? 0
            );

            const totalPrice = roundToTwoDecimal(
              tariffPrice +
                statutorySurcharge +
                riskFactorSurcharge +
                addOnsPrice +
                longTermCare
            );

            const addOns: Partial<Record<AddOn, number>> =
              mergedTariffInfo.addOns?.reduce(
                (acc, { monthly_price, add_on }) => ({
                  ...acc,
                  [add_on]: monthly_price,
                }),
                {}
              ) ?? {};

            const hasEmployerContributions =
              !!dbMatch?.tariffInfo?.employerContributions;

            const { employerContributions, ownContributions } =
              contributionsForTariff({
                tariff: {
                  tariffPrice,
                  statutorySurcharge,
                  riskSurcharge: riskFactorSurcharge,
                  longTermCare,
                  addOns,
                  totalPrice,
                },
                employmentStatus: hasEmployerContributions
                  ? 'EMPLOYED_IN_GERMANY'
                  : 'EMPLOYED_OUTSIDE_OF_GERMANY',
              });

            const {
              tariffPlusSurcharge: tps,
              dateOfBirth: dob,
              ...cleanedUpMergedTariffInfo
            } = mergedTariffInfo;

            const newMergedTariffInfo = dbMatch
              ? {
                  ...cleanedUpMergedTariffInfo,
                  ...(employerContributions ? { employerContributions } : {}),
                  ownContributions,
                  totalMonthlyPrice: totalPrice,
                  startDate: '2024-01-01T00:00:00.000Z',
                  deductible: cleanedUpMergedTariffInfo.deductible ?? '0',
                  tariff: cleanedUpMergedTariffInfo.tariff ?? 'NO_TARIFF_NAME',
                  longTermCare: cleanedUpMergedTariffInfo.longTermCare ?? 0,
                  statutorySurcharge: roundToTwoDecimal(statutorySurcharge),
                  tariffMonthlyPrice: roundToTwoDecimal(tariffPrice),
                  riskFactorSurcharge:
                    cleanedUpMergedTariffInfo.riskFactorSurcharge ?? 0,
                  userPolicyId: dbMatch.policy.id,
                }
              : undefined;

            newPartialTariffInfos[policyNumber][
              insuredPersonFullName
            ].mergedTariffInfo = newMergedTariffInfo;

            if (newMergedTariffInfo) {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              const { __typename, ...newTariffInfo } = newMergedTariffInfo;
              const hasChanges =
                hashSum(newMergedTariffInfo) !== hashSum(dbMatch.tariffInfo);

              if (hasChanges) {
                newMergedTariffInfos.push(newTariffInfo);
              }
            }
          }
        );
      }
    );

    setPartialTariffInfosLookup(newPartialTariffInfos);
    setMergedTariffInfos(newMergedTariffInfos);
    setMatchText(
      `${dbMatchCount}/${policyNumbersWithoutDupes.length} matching policies found. (${policyNumbersWithoutDupes.length} unique policy numbers according to BAP list)`
    );
    setOutDatedText(
      `Number of outdated tariff infos: ${newMergedTariffInfos.length}`
    );
  };

  return (
    <>
      {modalIsShown && (
        <Modal
          open={modalIsShown}
          setOpen={closeModal}
          title="BAP Reconciliation"
          confirmButtonLabel="Confirm"
          handleConfirm={handleConfirm}
          loading={loading}
          scrollable
          disabled={Object.keys(partialTariffInfosLookup).length < 1}
        >
          <div className="text-sm text-gray-600">
            <TextArea
              color="gray"
              placeholder="Paste CSV contents here"
              value={csvContent}
              onChange={(e) => setCSVContent(e.target.value)}
            />
            <div className="flex gap-2">
              <Button
                className="mt-4"
                buttonType="secondary"
                onClick={handleProcessClick}
              >
                Preview changes
              </Button>
              <Button
                className="mt-4"
                buttonType="transparent"
                onClick={async () => {
                  const styleTag =
                    '<style>*{font-family:sans-serif}.flex{display:flex}.gap-2{gap:8px}.p-2{padding:8px}.font-bold{font-weight:700}.bg-purple-50{background:rgba(245, 243, 255,1)}.py-2{padding-bottom:8px;padding-top:8px}.mb-1{margin-bottom:4px}.mb-4{margin-bottom:16px}.ml-1{margin-left:4px}.ml-2{margin-left:8px}.ml-4{margin-left:16px}.text-red-500{color:rgba(239,68,68,1)}.text-green-500{color:rgba(16,185,129)}.text-blue-500{color:rgba(59,130,246)}.opacity-50{opacity:.5}.rounded{border-radius:4px}.border{border:1px solid rgba(0,0,0,.2)}.h-4{height:16px}.w-4{width:16px}.items-center{align-items:center}.justify-center{justify-items:center}</style>';
                  const htmlContent =
                    document.getElementById('previewChanges')?.innerHTML;
                  const html = `<html><head>${styleTag}</head><body>${htmlContent}</body></html>`;
                  const a = document.body.appendChild(
                    document.createElement('a')
                  );
                  a.download = 'preview.html';
                  a.href = `data:text/html,${html}`;
                  a.click();
                }}
                disabled={Object.keys(partialTariffInfosLookup).length < 1}
              >
                Save Preview
              </Button>
            </div>
            <div id="previewChanges">
              {matchText && <p className="mt-4 font-bold">{matchText}</p>}
              {outDatedText && <p className="mt-4 font-bold">{outDatedText}</p>}
              {Object.entries(partialTariffInfosLookup).map(
                ([policyNumber, insuredPersonLookup]) => {
                  const insuredPersonContent = Object.entries(
                    insuredPersonLookup
                  ).map(([insuredPersonFullname, partialTariffInfos]) => {
                    const oldTariffInfoContent = Object.entries(
                      partialTariffInfos.old
                    ).map(([key, value]) => {
                      if (
                        typeof value === 'string' ||
                        typeof value === 'number' ||
                        typeof value === 'undefined'
                      ) {
                        return (
                          <p key={`${insuredPersonFullname}_old_${key}`}>
                            {key}: {value ?? ' - '}
                          </p>
                        );
                      }
                      if (Array.isArray(value)) {
                        return (
                          <div
                            key={`${insuredPersonFullname}_old_${key}`}
                            className="py-2"
                          >
                            <p className="font-bold">AddOns</p>
                            {value.map(({ add_on, monthly_price }) => (
                              <p
                                className="ml-2"
                                key={`${insuredPersonFullname}_old_addOns_${add_on}`}
                              >
                                {add_on}: {monthly_price}
                              </p>
                            ))}
                          </div>
                        );
                      }
                      return null;
                    });
                    const newTariffInfoContent = Object.entries(
                      partialTariffInfos.new
                    ).map(([key, value]) => {
                      if (
                        typeof value === 'string' ||
                        typeof value === 'number'
                      ) {
                        const oldValue =
                          partialTariffInfos.old[
                            key as keyof HalleschePriceInfo
                          ];
                        const priceChanged =
                          oldValue !== undefined && value !== oldValue;

                        const priceChangedClass =
                          (oldValue ?? 0) < value
                            ? 'font-bold text-red-500'
                            : 'font-bold text-green-500';

                        return (
                          <p key={`${insuredPersonFullname}_new_${key}`}>
                            {key}:{' '}
                            <span
                              className={priceChanged ? priceChangedClass : ''}
                            >
                              {value}
                            </span>
                          </p>
                        );
                      }
                      if (Array.isArray(value)) {
                        return (
                          <div
                            key={`${insuredPersonFullname}_new_${key}`}
                            className="py-2"
                          >
                            <p className="font-bold">AddOns</p>
                            {value.map(({ add_on, monthly_price }) => {
                              const oldValue =
                                partialTariffInfos.old?.addOns?.find(
                                  (a) => a.add_on === add_on
                                )?.monthly_price;
                              const priceChanged =
                                monthly_price !== undefined &&
                                monthly_price !== oldValue;

                              const priceChangedClass =
                                (oldValue ?? 0) < monthly_price
                                  ? 'font-bold text-red-500'
                                  : 'font-bold text-green-500';
                              return (
                                <p
                                  className="ml-2"
                                  key={`${insuredPersonFullname}_new_addOns_${add_on}`}
                                >
                                  {add_on}:{' '}
                                  <span
                                    className={
                                      priceChanged ? priceChangedClass : ''
                                    }
                                  >
                                    {monthly_price}
                                  </span>
                                </p>
                              );
                            })}
                          </div>
                        );
                      }
                      return null;
                    });
                    const { dbMatch, mergedTariffInfo } = partialTariffInfos;
                    const insuredPersonNameFromPolicy =
                      dbMatch?.policy?.insuredPerson?.firstName &&
                      dbMatch?.policy?.insuredPerson?.lastName
                        ? `${dbMatch?.policy?.insuredPerson?.firstName} ${dbMatch?.policy?.insuredPerson?.lastName}`
                        : '';

                    const policyLink = `https://admin.feather-insurance.com${getPolicyDetailsBaseURLFromInsuranceType(
                      'PRIVATE_HEALTH',
                      dbMatch?.policy?.id
                    )}`;

                    const hasChanges =
                      hashSum(mergedTariffInfo) !==
                      hashSum(dbMatch?.tariffInfo);

                    return (
                      <div
                        className="mb-4 p-2 text-xs"
                        key={`${policyNumber}_${insuredPersonFullname}`}
                      >
                        {dbMatch ? (
                          <a
                            className="mb-1 ml-1 flex cursor-pointer items-center gap-2 font-bold text-indigo-500 hover:text-indigo-900"
                            href={policyLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {insuredPersonNameFromPolicy ||
                              insuredPersonFullname}
                            <div className="w-[14px]">
                              <ExternalLinkIcon />
                            </div>
                          </a>
                        ) : (
                          <p className="mb-1 ml-1 font-bold opacity-50">
                            {insuredPersonFullname}
                          </p>
                        )}
                        <div
                          className={
                            dbMatch
                              ? 'mb-4 flex gap-2'
                              : 'mb-4 flex gap-2 opacity-50'
                          }
                        >
                          <div className="rounded border p-2">
                            <p className="mb-2 font-bold">Hallesche old</p>
                            {oldTariffInfoContent}
                          </div>
                          <div className="flex items-center justify-center">
                            <div className="h-4 w-4">
                              <ArrowRightIcon />
                            </div>
                          </div>
                          <div className="rounded border p-2">
                            <p className="mb-2 font-bold">Hallesche new</p>
                            {newTariffInfoContent}
                          </div>
                        </div>
                        {hasChanges ? (
                          <div className="flex gap-2">
                            {dbMatch?.tariffInfo && (
                              <div className="rounded bg-purple-50 p-2">
                                <div className="mt-1">
                                  <p className="mb-2 font-bold">
                                    Current tariff info
                                  </p>
                                  {Object.entries(
                                    dbMatch?.tariffInfo ?? {}
                                  ).map(([key, value]) => {
                                    if (
                                      (typeof value === 'string' ||
                                        typeof value === 'number' ||
                                        typeof value === 'undefined') &&
                                      key !== '__typename' &&
                                      key !== 'id'
                                    ) {
                                      return (
                                        <p
                                          key={`${dbMatch.tariffInfo?.id}_${key}`}
                                          className="ml-2"
                                        >
                                          {key}: {value ?? ' - '}
                                        </p>
                                      );
                                    }

                                    if (Array.isArray(value)) {
                                      return (
                                        <div
                                          key={`${insuredPersonFullname}_${key}`}
                                          className="py-2"
                                        >
                                          <p className="ml-2 font-bold">
                                            AddOns
                                          </p>
                                          {value.map(
                                            ({ add_on, monthly_price }) => {
                                              return (
                                                <p
                                                  className="ml-4"
                                                  key={`${dbMatch.tariffInfo?.id}_${key}_${add_on}`}
                                                >
                                                  {add_on}: {monthly_price}
                                                </p>
                                              );
                                            }
                                          )}
                                        </div>
                                      );
                                    }

                                    return null;
                                  })}
                                </div>
                              </div>
                            )}
                            <div className="flex items-center justify-center">
                              <div className="h-4 w-4">
                                <ArrowRightIcon />
                              </div>
                            </div>
                            {mergedTariffInfo && (
                              <div className="rounded bg-purple-50 p-2">
                                <div className="mt-1">
                                  <p className="mb-2 font-bold">
                                    New tariff info
                                  </p>
                                  {Object.entries(mergedTariffInfo ?? {}).map(
                                    ([key, value]) => {
                                      if (
                                        (typeof value === 'string' ||
                                          typeof value === 'number' ||
                                          typeof value === 'undefined') &&
                                        key !== '__typename' &&
                                        key !== 'id'
                                      ) {
                                        const tariffInfoKey =
                                          key as keyof TariffInfo;
                                        const valueWillChange =
                                          mergedTariffInfo?.[tariffInfoKey] !==
                                          dbMatch?.tariffInfo?.[tariffInfoKey];

                                        return (
                                          <p
                                            key={`${mergedTariffInfo?.id}_merged_${key}`}
                                            className="ml-2"
                                          >
                                            {key}:{' '}
                                            <span
                                              className={
                                                valueWillChange
                                                  ? 'font-bold text-blue-500'
                                                  : ''
                                              }
                                            >
                                              {value ?? ' - '}
                                            </span>
                                          </p>
                                        );
                                      }

                                      if (Array.isArray(value)) {
                                        const addOnsChanged =
                                          hashSum(mergedTariffInfo.addOns) !==
                                          hashSum(dbMatch?.tariffInfo?.addOns);

                                        return (
                                          <div
                                            key={`${insuredPersonFullname}_${key}`}
                                            className="py-2"
                                          >
                                            <p
                                              className={
                                                addOnsChanged
                                                  ? 'ml-2 font-bold text-blue-500'
                                                  : 'ml-2 font-bold'
                                              }
                                            >
                                              AddOns
                                            </p>
                                            {value.map(
                                              ({ add_on, monthly_price }) => {
                                                const oldValue =
                                                  dbMatch?.tariffInfo?.addOns?.find(
                                                    (a) => a.add_on === add_on
                                                  )?.monthly_price;
                                                const priceChanged =
                                                  monthly_price !== undefined &&
                                                  monthly_price !== oldValue;

                                                return (
                                                  <p
                                                    className={
                                                      priceChanged
                                                        ? 'ml-4 font-bold text-blue-500'
                                                        : 'ml-4'
                                                    }
                                                    key={`${mergedTariffInfo?.id}_merged_${key}_${add_on}`}
                                                  >
                                                    {add_on}: {monthly_price}
                                                  </p>
                                                );
                                              }
                                            )}
                                          </div>
                                        );
                                      }
                                      return null;
                                    }
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        ) : dbMatch ? (
                          <p className="font-bold text-green-500 ">
                            ----- Tariff info already up to date -----
                          </p>
                        ) : (
                          <p className="font-bold opacity-50">
                            ----- No matching policy -----
                          </p>
                        )}
                      </div>
                    );
                  });
                  return (
                    <div className="mt-8" key={policyNumber}>
                      <p className="mb-1 font-bold">{policyNumber}</p>
                      {insuredPersonContent}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </Modal>
      )}
      <Button buttonType="transparent" onClick={() => openModal()}>
        BAP Reconciliaton
      </Button>
    </>
  );
};
