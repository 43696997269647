import { ModalFormData } from 'components/ModalForm/models';
import { retrievePolicySuggestion } from 'pages/claims/template/components/CreateClaimModal/utils';
import { GenericPolicy } from 'pages/policies/generic/models/GenericPolicy';
import { claimTypeNameMapper as genericClaimTypeNameMapper } from 'shared/claims/generic/claimTypeNameMapper';
import {
  GenericClaimStatus,
  genericClaimStatuses,
  GenericClaimType,
} from 'shared/claims/generic/models';
import { statusNameMapper as genericStatusNameMapper } from 'shared/claims/generic/statusNameMapper';

import { SubmittableClaim } from '../../../template/components/CreateClaimModal/models';

export const retrieveCreateClaimFormData =
  (genericClaimTypes: GenericClaimType[]) =>
  (
    policies: GenericPolicy[],
    setPolicySearchQuery: (query: string) => void,
    policiesLoading: boolean
  ): ModalFormData<
    SubmittableClaim<GenericClaimStatus, GenericClaimType>,
    GenericClaimType | GenericClaimStatus | string
  >[] =>
    [
      {
        id: 'policyId',
        label: 'User policy',
        renderDetails: {
          type: 'autoSuggest',
          options: policies.map(({ id, user, policyNumber }) => ({
            id,
            label: retrievePolicySuggestion({
              firstName: user?.firstName,
              lastName: user?.lastName,
              policyNumber,
            }),
          })),
          placeholder: 'Enter name, email or policy number',
          setExternalQuery: (query: string) => {
            setPolicySearchQuery(query);
          },
          useUnfilteredOptions: true,
        },
        color: 'gray',
        required: true,
        loading: policiesLoading,
      },
      {
        id: 'status',
        label: 'Status',
        renderDetails: {
          type: 'select',
          options: genericClaimStatuses.flatMap((status) => ({
            id: status,
            label: genericStatusNameMapper[status],
          })),
          placeholder: 'Select a status',
        },
        color: 'gray',
        required: true,
      },
      {
        id: 'claimType',
        label: 'Claim type',
        renderDetails: {
          type: 'multiSelect',
          options: genericClaimTypes.map((type) => ({
            id: type,
            label: genericClaimTypeNameMapper[type],
          })),
          placeholder: 'Select type',
        },
        color: 'gray',
        required: true,
      },
      {
        id: 'dateOfEvent',
        label: 'Event date',
        renderDetails: { type: 'date', maxDate: new Date().toISOString() },
        color: 'gray',
      },
      {
        id: 'eventDescription',
        label: 'Event description',
        renderDetails: { type: 'textarea', placeholder: 'Start typing...' },
        color: 'gray',
      },
      {
        id: 'amount',
        label: 'Claim amount',
        renderDetails: { type: 'currency', placeholder: '0.00' },
        color: 'gray',
      },
      {
        id: 'uploadDocument',
        label: 'Upload a document',
        renderDetails: { type: 'upload' },
      },
    ];
