import classNames from 'classnames';

export const selectWrapper = classNames('relative');

export const disabledSelected = classNames('pointer-events-none');

export const disabledIcon = classNames('text-gray-400');

export const icon = classNames('h-5', 'w-5');

export const button = classNames(
  'py-[9px]',
  'px-[13px]',
  'rounded-[6px]',
  'text-sm',
  'font-bold',
  'flex',
  'justify-center',
  'group'
);

export const disabled = classNames(
  'cursor-default',
  'opacity-50',
  'pointer-events-none'
);

export const primary = classNames(
  'bg-indigo-500',
  'text-white',
  'hover:bg-indigo-600',
  'outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'focus:bg-indigo-600',
  'focus:ring-indigo-500'
);

export const secondary = classNames(
  'bg-indigo-100',
  'text-indigo-500',
  'hover:bg-indigo-200',
  'focus:outline-none',
  'focus:bg-indigo-200',
  'focus:ring-2',
  'focus:ring-offset-2',
  'focus:ring-indigo-500'
);

export const white = classNames(
  'text-gray-600',
  'bg-white',
  'hover:bg-indigo-50',
  'hover:text-indigo-500',
  'focus:text-indigo-500',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'focus:ring-indigo-500'
);

export const transparent = classNames(
  'text-gray-500',
  'bg-transparent',
  'hover:text-indigo-500',
  'focus:text-indigo-500',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'focus:ring-indigo-500'
);

export const optionsList = classNames(
  'absolute',
  'z-10',
  'right-0',
  'mt-2',
  'bg-white',
  'shadow-lg',
  'rounded-[6px]',
  'py-1',
  'overflow-auto',
  'focus:outline-none',
  'text-gray-600'
);

export const loader = classNames(
  'animate-spin',
  'w-[20px]',
  'h-[20px]',
  'mr-[5px]'
);

export const optionsListBig = classNames('w-[190px]', 'text-sm');

export const listOption = classNames(
  'cursor-pointer',
  'relative',
  'py-[8px]',
  'pl-[16px]'
);

export const listOptionText = classNames('font-normal', 'text-gray-600');

export const listOptionTextSelected = classNames('!text-gray-500');

export const listOptionTextHover = classNames('text-gray-900');
