import { BadgeColor } from 'components/Badge/Badge';

import { DogLiabilityStatus } from '../types';

export const dogLiabilityStatusMapper: Record<DogLiabilityStatus, string> = {
  PENDING: 'Pending',
  ACTIVE: 'Covered',
  CANCELED: 'Canceled',
  DROPPED_OUT: 'Canceled',
};

export const dogLiabilityBadgeColorMapper: Record<
  DogLiabilityStatus,
  BadgeColor
> = {
  PENDING: 'purple',
  ACTIVE: 'green',
  CANCELED: 'gray',
  DROPPED_OUT: 'gray',
};
