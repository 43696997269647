import { useMutation, useReactiveVar } from '@apollo/client';
import { GET_CUSTOMERS } from 'pages/customers/graphql/queries';
import { useState } from 'react';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import { CreateCustomerModalView } from './CreateCustomerModal.view';
import { CREATE_CUSTOMER } from './graphql/mutations';
import {
  SubmittableCustomer,
  SubmittableCustomerValidationError,
  ZSubmittableCustomerSchema,
} from './models';
import { retrieveErrorMessages } from './utils';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const CreateCustomerModal = ({ isOpen, setIsOpen }: Props) => {
  const [customer, setCustomer] = useState<Partial<SubmittableCustomer>>({});
  const [validationError, setValidationError] = useState<
    Partial<SubmittableCustomerValidationError>
  >({});
  const [submissionDisabled, setSubmissionDisabled] = useState(false);

  const alertBannersState = useReactiveVar(alertBanners);

  const onSuccessfulCustomerCreation = () => {
    setIsOpen(false);
    setCustomer({});

    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'SUCCESS',
      message: 'New customer account successfully created',
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };

  const [createCustomer, { loading }] = useMutation(CREATE_CUSTOMER, {
    refetchQueries: [GET_CUSTOMERS],
    onCompleted: onSuccessfulCustomerCreation,
    notifyOnNetworkStatusChange: true,
    onError: () => {
      setIsOpen(false);
      setCustomer({});

      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: 'Something went wrong. Please try again.',
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
  });

  const onChange = (input: Partial<SubmittableCustomer>) => {
    setSubmissionDisabled(false);
    setCustomer(input);
  };

  const onCreateCustomer = () => {
    const validation = ZSubmittableCustomerSchema.safeParse({ ...customer });

    if (!validation.success) {
      setValidationError({ ...retrieveErrorMessages(validation.error) });
      setSubmissionDisabled(true);
      return;
    }

    setValidationError({});

    createCustomer({ variables: customer });
  };

  return (
    <CreateCustomerModalView
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onChange={onChange}
      answers={customer}
      onCreateCustomer={onCreateCustomer}
      submissionDisabled={submissionDisabled}
      validationError={validationError}
      loading={loading}
    />
  );
};
