import { GET_LEGAL_POLICIES } from 'pages/policies/legal/graphql/policies';
import { LegalClaimStatus, LegalClaimType } from 'shared/claims/legal/models';
import { InsuranceType } from 'shared/insurances/types';

import { ClaimsTemplateData } from '../template/models';
import { retrieveClaimInfoRows } from './modules/claimsDetails/claimInfo/data';
import { UPDATE_LEGAL_CLAIM } from './modules/claimsDetails/graphql/mutations';
import { GET_LEGAL_CLAIM } from './modules/claimsDetails/graphql/queries';
import { retrievePolicyInfoRows } from './modules/claimsDetails/policyInfo/data';
import { retrieveCreateClaimFormData } from './modules/createClaim/data';
import { CREATE_LEGAL_CLAIM } from './modules/createClaim/graphql/mutations';
import { zodSubmittableClaimSchema } from './modules/createClaim/models';
import { filters } from './modules/overview/data/filters';
import { rowConfig } from './modules/overview/data/tableData';
import { tableHeaders } from './modules/overview/data/tableHeaders';
import { GET_LEGAL_CLAIMS } from './modules/overview/graphql/queries';
import {
  claimStatusOptions,
  retrieveFormAndFooterTextByStatus,
} from './modules/statusUpdate/data';
import { UPDATE_LEGAL_CLAIM_STATUS } from './modules/statusUpdate/graphql/mutations';

export const legalClaimsData = (
  insuranceType: InsuranceType
): ClaimsTemplateData<LegalClaimStatus, LegalClaimType> => ({
  insuranceType,

  // GraphQL -----------------------------------------
  queries: {
    fetchAllClaims: GET_LEGAL_CLAIMS,
    fetchSingleClaim: GET_LEGAL_CLAIM,
    fetchAllPolicies: GET_LEGAL_POLICIES,
  },

  mutations: {
    createClaim: CREATE_LEGAL_CLAIM,
    updateClaim: UPDATE_LEGAL_CLAIM,
    updateClaimStatus: UPDATE_LEGAL_CLAIM_STATUS,
  },

  // Features ----------------------------------------
  overview: {
    headers: tableHeaders,
    filterConfig: filters,
    rowConfig,
  },
  claimCreation: {
    createClaimSubmissionSchema: zodSubmittableClaimSchema,
    retrieveFormData: retrieveCreateClaimFormData(insuranceType),
  },
  policyInfo: { retrievePolicyInfoRows },
  claimInfo: {
    retrieveClaimInfoRows: retrieveClaimInfoRows(insuranceType),
  },
  statusUpdate: {
    dataByStatus: retrieveFormAndFooterTextByStatus,
    statusOptions: claimStatusOptions(insuranceType),
  },
});
