import { claimFileTypes } from 'models/Claim';
import { claimDocumentCategories } from 'pages/claims/template/models';
import { z } from 'zod';

export const updateClaimDocumentSchema = z.object({
  id: z.string().uuid(),
  friendlyName: z.string().optional(),
  type: z.enum(claimFileTypes, { required_error: 'This field is required' }),
  category: z.enum(claimDocumentCategories, {
    required_error: 'This field is required',
  }),
  isVisibleToCustomer: z.boolean(),
  internalNote: z.string().optional(),
});
