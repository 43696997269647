import RadioButton from 'components/RadioButton';
import { ChangeEvent } from 'react';

import TextInformation, { TextInformationProps } from '../TextInformation';
import * as styles from './styles';

const ToggleInformation = ({
  cell,
  editMode,
  editable,
  value,
  handleToggleChange,
  id,
}: TextInformationProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleToggleChange?.(event.target.value, id);
  };

  if (editMode && editable) {
    return (
      <div className={styles.radioButtonWrapper}>
        <RadioButton
          id={`${id}-yes`}
          name={id}
          value="yes"
          checked={value?.toUpperCase() === 'YES'}
          onChange={handleChange}
        >
          Yes
        </RadioButton>
        <RadioButton
          id={`${id}-no`}
          name={id}
          value="no"
          checked={value?.toUpperCase() === 'NO'}
          onChange={handleChange}
        >
          No
        </RadioButton>
      </div>
    );
  }
  return (
    <TextInformation
      editMode={editMode}
      editable={editable}
      cell={cell.toString()}
      handleChange={handleChange}
      id={id}
      className="capitalize"
    />
  );
};

export default ToggleInformation;
