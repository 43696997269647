import CalendarDatePicker from 'components/CalendarDatePicker';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';

import TextInformation, { TextInformationProps } from '../TextInformation';
import TextWithDetailsInformation from '../TextWithDetailsInformation';

const DateInformation = ({
  cell,
  editMode,
  editable,
  value,
  handleChange,
  handleDateChange,
  id,
  renderModalDetails,
  detailsLabel,
  detailsTitle,
}: TextInformationProps) => {
  const dateString = cell ? dayjs(cell.toString()).format(DATE_FORMAT) : '';
  const detailedDateString = cell
    ? dayjs(cell.toString()).format('DD/MM/YYYY, HH:mm')
    : '';

  const handleDatePickerChange = (date: Date | [Date, Date] | null) => {
    if (!date || Array.isArray(date)) return;

    if (handleDateChange) {
      handleDateChange(
        new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
        id
      );
    }
  };

  if (editMode && editable) {
    return (
      <CalendarDatePicker
        selectedDate={value ? new Date(value) : undefined}
        onChange={handleDatePickerChange}
      />
    );
  }

  if (renderModalDetails) {
    return (
      <TextWithDetailsInformation
        editMode={editMode}
        editable={editable}
        cell={dateString}
        handleChange={handleChange}
        id={id}
        renderModalDetails={renderModalDetails}
        detailsLabel={detailsLabel}
        detailsTitle={detailsTitle}
      />
    );
  }

  return (
    <TextInformation
      editMode={editMode}
      editable={editable}
      cell={
        <div className="group relative">
          <p>{dateString}</p>
          {detailedDateString && (
            <p className="absolute bottom-5 left-3 hidden w-max rounded-md bg-white px-4 py-2 text-gray-600 shadow-3xl group-hover:inline">
              {detailedDateString}
            </p>
          )}
        </div>
      }
      handleChange={handleChange}
      id={id}
    />
  );
};

export default DateInformation;
