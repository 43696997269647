import { NetworkStatus } from '@apollo/client';
import hashSum from 'hash-sum';
import useQueryParams from 'hooks/useQueryParams';
import { useEffect, useState } from 'react';

import { NormalizedData, ObjectWithNullishId } from '../../types';
import { arrayFromNtoM, nonNullish } from './utils';

export const useBulkSelect = <T extends ObjectWithNullishId>({
  normalizedData,
  onBulkSelection,
  networkStatus,
}: {
  normalizedData: NormalizedData<T>;
  onBulkSelection?: (items: any[]) => void;
  networkStatus: NetworkStatus;
}) => {
  const queryParams = useQueryParams();

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [lastSelectedRowIndex, setLastSelectedRowIndex] = useState<
    number | undefined
  >();

  const numberOfItems = normalizedData.items.length;

  const onCheckBoxClick = (clickedRowIndex: number) => () => {
    setLastSelectedRowIndex(clickedRowIndex);

    const clickedRowIsCurrentlySelected =
      selectedRows.includes(clickedRowIndex);

    if (clickedRowIsCurrentlySelected) {
      const selectedRowWithoutClicked = selectedRows.filter(
        (index) => index !== clickedRowIndex
      );
      setSelectedRows(selectedRowWithoutClicked);
    } else {
      const selectedRowPlusClicked = [...selectedRows, clickedRowIndex];
      setSelectedRows(selectedRowPlusClicked);
    }
  };

  const onShiftCheckBoxClick = (clickedRowIndex: number) => () => {
    if (lastSelectedRowIndex === undefined) {
      setLastSelectedRowIndex(clickedRowIndex);
      return;
    }

    const rowsBetweenLastSelectedAndClicked = arrayFromNtoM(
      clickedRowIndex,
      lastSelectedRowIndex
    );

    const shouldAddToSelection = !selectedRows.includes(clickedRowIndex);

    if (shouldAddToSelection) {
      const selectedRowsPlusSelection = [
        ...selectedRows,
        ...rowsBetweenLastSelectedAndClicked,
      ];
      setSelectedRows(selectedRowsPlusSelection);
    } else {
      const selectedRowWithoutSelection = selectedRows.filter(
        (index) => !rowsBetweenLastSelectedAndClicked.includes(index)
      );
      setSelectedRows(selectedRowWithoutSelection);
    }

    setLastSelectedRowIndex(clickedRowIndex);
  };

  const anyRowsSelected = selectedRows.length > 0;

  const allRowsSelected =
    anyRowsSelected && selectedRows.length >= normalizedData.items.length;

  const selectedItems = normalizedData.items.filter((_, index) =>
    selectedRows.includes(index)
  );

  const selectedItemIds = selectedItems
    .map((item) => item?.id)
    .filter(nonNullish);

  const onToggleSelectAll = () => {
    if (allRowsSelected) {
      setSelectedRows([]);
    } else {
      const allIndexes = arrayFromNtoM(0, numberOfItems - 1);

      setSelectedRows(allIndexes);
    }
  };

  const queryParamsStr = queryParams.toString();

  useEffect(() => {
    // Clear selections when data is changing
    setSelectedRows([]);
    setLastSelectedRowIndex(undefined);
  }, [networkStatus, queryParamsStr]);

  useEffect(() => {
    onBulkSelection?.(selectedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashSum(selectedItemIds)]);

  return {
    onCheckBoxClick,
    onShiftCheckBoxClick,
    onToggleSelectAll,
    selectedItems,
    allRowsSelected,
    anyRowsSelected,
    selectedRows,
    selectedItemIds,
  };
};
