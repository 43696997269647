import { BadgeColor } from 'components/Badge/Badge';
import { Status } from 'models/statusColor';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';
import { countries, Country } from 'shared/countries/models';
import { InsuranceType } from 'shared/insurances/types';

import { InsurancePathName, insurancePathNames } from '../models';

export const statusMapping = (status: string, color: BadgeColor): Status => ({
  text: {
    text: status,
    id: status,
  },
  color,
});

export const isInsurancePathName = (path: any): path is InsurancePathName =>
  insurancePathNames.includes(path);

export const retrieveClaimsOverviewPath = (
  insurancePathName: InsurancePathName,
  country: Country = 'de'
): string =>
  generatePath(paths.claims.internal.path, {
    insurancePathName,
    country,
  });

export const retrieveClaimsDetailsPath = (
  insurancePathName: InsurancePathName,
  claimId: string,
  country: Country = 'de'
): string =>
  generatePath(paths.claims.internal.details.path, {
    insurancePathName,
    id: claimId,
    country,
  });

export const getInsurancePathByCountry = (): Record<
  InsurancePathName,
  Country[]
> => ({
  travel: ['de'],
  'pet-health': ['de'],
  'company-health': ['de'],
  'company-life': ['de'],
  disability: ['de'],
  dental: ['de'],
  household: ['de'],
  liability: ['de'],
  legal: ['de'],
  bike: ['de'],
  'dog-liability': ['de'],
  'expat-health': ['de'],
  'private-health': ['de'],
  'expat-health-es': ['es'],
  'expat-health-eu': [
    ...countries.filter((country) => country !== 'es' && country !== 'de'),
  ],
  'expat-health-long-term': [...countries],
});

export const getInsuranceTypeFromPath = (
  pathName: InsurancePathName
): InsuranceType => {
  const insuranceTypeMap: Record<InsurancePathName, InsuranceType> = {
    bike: 'BIKE',
    'company-health': 'COMPANY_HEALTH',
    'company-life': 'COMPANY_LIFE',
    dental: 'DENTAL',
    disability: 'DISABILITY',
    'dog-liability': 'DOG_LIABILITY',
    household: 'HOUSEHOLD',
    legal: 'LEGAL',
    liability: 'LIABILITY',
    'pet-health': 'PET_HEALTH',
    travel: 'TRAVEL',
    'private-health': 'PRIVATE_HEALTH',
    'expat-health': 'INCOMING',
    'expat-health-es': 'INCOMING_ES',
    'expat-health-eu': 'INCOMING_EU',
    'expat-health-long-term': 'INCOMING_LT',
  };
  return insuranceTypeMap[pathName];
};
