import { gql } from '@apollo/client';

export const HEALTH_CARD = gql`
  fragment HealthCard on PublicHealthPolicy {
    healthCard {
      id
      photoUrl
      completed
      address {
        city
        street
        country
        postcode
        houseNumber
        additionalInformation
      }
    }
  }
`;
