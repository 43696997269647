import classNames from 'classnames';

export const inputContainer = classNames('mt-[16px]');

export const label = classNames('block', 'flex');

export const labelTitle = classNames('text-sm', 'font-bold', 'text-gray-900');

export const labelOptional = classNames('text-sm', 'text-gray-500', 'ml-[8px]');

export const input = classNames('mt-[8px]');

export const dateInput = classNames('!w-[432px]');

export const error = classNames('text-sm', 'text-red-400', 'mt-[16px]');
