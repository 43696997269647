import {
  ChatIcon,
  CheckCircleIcon,
  CodeIcon,
  ExclamationCircleIcon,
  EyeIcon,
  MailIcon,
  XCircleIcon,
} from '@heroicons/react/outline';

import * as styles from './styles';

export type CheckBadgeType =
  | 'check'
  | 'cross'
  | 'exclamation'
  | 'error'
  | 'email'
  | 'chat'
  | 'n8n'
  | 'eyeOpen'
  | 'empty';

interface CheckBadgeProps {
  checkType: CheckBadgeType;
  badgeCaption?: string;
}

const CheckBadge = ({ checkType, badgeCaption }: CheckBadgeProps) => {
  return (
    <div className="peer relative z-10">
      {checkType === 'eyeOpen' && <EyeIcon className={styles.eyeIcon} />}
      {checkType === 'email' && <MailIcon className={styles.eyeIcon} />}
      {checkType === 'chat' && <ChatIcon className={styles.eyeIcon} />}
      {checkType === 'n8n' && <CodeIcon className={styles.eyeIcon} />}
      {checkType === 'check' && (
        <CheckCircleIcon className={styles.checkIcon} />
      )}
      {checkType === 'cross' && <XCircleIcon className={styles.crossIcon} />}
      {checkType === 'exclamation' && (
        <ExclamationCircleIcon className={styles.exclamationIcon} />
      )}
      {checkType === 'error' && (
        <ExclamationCircleIcon className={styles.errorIcon} />
      )}
      {badgeCaption && (
        <div className={styles.badgeCaption}>{badgeCaption}</div>
      )}
    </div>
  );
};

export default CheckBadge;
