import classNames from 'classnames';

export const dotIconContainer = classNames('relative', 'z-50');

export const dotIconButton = classNames(
  'text-gray-400',
  'flex',
  'items-center',
  'hover:text-indigo-500',
  'focus:text-indigo-500'
);

export const dotIcon = classNames('w-[20px]', 'h-[20px]');

export const dropdown = classNames(
  'absolute',
  'rounded-[6px]',
  'bg-white',
  'right-0',
  'top-6',
  'w-[148px]',
  'shadow-xl',
  'z-10',
  'animate-[appear-down_0.1s_both]'
);

export const dropdownButton = classNames(
  'px-[16px]',
  'py-[12px]',
  'text-xs',
  'w-full',
  'text-left',
  'hover:text-white',
  'hover:bg-indigo-500'
);

export const disabledDropdownButton = classNames(
  'cursor-default',
  'opacity-50',
  'pointer-events-none'
);

export const dropdownButtonGray = classNames(dropdownButton, 'text-gray-600');

export const dropdownButtonRed = classNames(dropdownButton, 'text-red-500');
