import {
  claimTypes,
  privateHealthClaimStatuses,
} from 'shared/claims/privateHealth/models';
import { z } from 'zod';

const requiredError = 'This field is required.';

export const ZSubmittablePrivateHealthClaimSchema = z.object({
  policyId: z.string().min(1, { message: requiredError }),
  status: z.enum(privateHealthClaimStatuses),
  claimType: z.array(z.enum(claimTypes)),
  amount: z.string().optional(),
  invoiceYear: z.string().optional(),
  uploadDocument: z.custom<File>().optional(),
});
