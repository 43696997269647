import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import Modal from 'components/Modal';
import { DocumentNode } from 'graphql';
import { useState } from 'react';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import * as styles from '../styles';

export const DeleteButton = ({
  mutation,
  documentId,
  documentName,
  getResourceQuery,
  additionalVariables = {},
}: {
  mutation: DocumentNode;
  documentId: string;
  documentName: string;
  getResourceQuery?: DocumentNode;
  additionalVariables?: Record<string, unknown>;
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const alertBannersState = useReactiveVar(alertBanners);
  const [deleteDocument, { loading }] = useMutation(mutation, {
    variables: {
      id: documentId,

      ...additionalVariables,
    },
    refetchQueries: getResourceQuery ? [getResourceQuery] : [],
    onCompleted: () => {
      setOpen(false);
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message: t('admin.documents.delete.success.title', 'Document deleted.'),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
    onError: () => {
      setOpen(false);
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: t(
          'admin.documents.delete.error.title',
          'Something went wrong. Please try again.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
  });

  const handleDeleteFile = () => {
    deleteDocument();
  };

  return (
    <>
      <button
        type="button"
        className={styles.redDropdownOption}
        onClick={() => {
          setOpen(true);
        }}
      >
        {t('admin.documents.action.delete.label', 'Delete')}
      </button>
      <Modal
        title={t('modal.document.delete.title', 'Delete document?')}
        open={open}
        setOpen={setOpen}
        confirmButtonLabel={t('modal.document.delete.cta', 'Delete')}
        handleConfirm={handleDeleteFile}
        loading={loading}
      >
        <div className="text-sm text-gray-600">
          <p>
            {t(
              'modal.document.delete.description.section1',
              'Do you want to delete “{{fileName}}”?',
              {
                fileName: documentName,
              }
            )}{' '}
            <span>
              {t('modal.document.delete.description.section2', 'This')}
            </span>{' '}
            <span className="font-bold">
              {t(
                'modal.document.delete.description.section3',
                'cannot be undone.'
              )}
            </span>
          </p>
        </div>
      </Modal>
    </>
  );
};
