import { init } from '@getpopsure/i18n-react';
import { translations } from 'locales';

const isStaging = process.env.REACT_APP_DEPLOY_ENV === 'staging';

init({
  phrase: {
    projectId: 'da669f04712b973474102560082bbd4c',
    allowInContextEditor: isStaging,
  },
  debug: false,
  resources: translations,
  saveMissing: true,
  missingKeyHandler: (_lng, _ns, key) => {
    if (isStaging || process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.info(`[Translation Log] Missing key: ${key}`);
    }
  },
});
