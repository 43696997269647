import { gql } from '@apollo/client';

export const UPDATE_DENTAL_POLICY_STATUS = gql`
  mutation UpdateDentalPolicyStatus(
    $status: String!
    $id: String!
    $policyNumber: String
    $confirmationOfCoverageDocument: Upload
    $activeUntil: String
    $newFilename: String
    $reinstateDate: String
    $price: Float
  ) {
    updateDentalPolicyStatus(
      status: $status
      id: $id
      policyNumber: $policyNumber
      activeUntil: $activeUntil
      reinstateDate: $reinstateDate
      price: $price
    ) {
      id
      publicStatus
      policyNumber
      activeUntil
    }
    uploadDentalDocument(
      id: $id
      newFilename: $newFilename
      file: $confirmationOfCoverageDocument
    ) {
      id
      url
      name
    }
  }
`;
