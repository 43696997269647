import { GET_EXPAT_POLICIES } from 'pages/policies/expatHealth/graphql/policies';
import {
  ExpatClaimStatus,
  ExpatClaimType,
} from 'shared/claims/expatHealth/models';

import { ClaimsTemplateData } from '../template/models';
import {
  retrieveExpatClaimInfoOldConfirmationValue,
  retrieveExpatClaimInfoRows,
} from './modules/claimsDetails/claimInfo/data';
import ConfirmationModal from './modules/claimsDetails/components/ConfirmationModal';
import { UPDATE_EXPAT_CLAIM } from './modules/claimsDetails/graphql/mutations';
import { GET_EXPAT_HEALTH_CLAIM } from './modules/claimsDetails/graphql/queries';
import { retrieveExpatPolicyInfoRows } from './modules/claimsDetails/policyInfo/data';
import { retrieveFormData } from './modules/createClaim/data';
import { CREATE_EXPAT_CLAIM } from './modules/createClaim/graphql/mutations';
import { ZSubmittableExpatClaimSchema } from './modules/createClaim/models';
import { filters } from './modules/overview/data/filters';
import { rowConfig } from './modules/overview/data/tableData';
import { tableHeaders } from './modules/overview/data/tableHeaders';
import { GET_EXPAT_HEALTH_CLAIMS } from './modules/overview/graphql/queries';
import {
  dataByStatus,
  expatClaimStatusOptions,
} from './modules/statusUpdate/data';
import { UPDATE_EXPAT_CLAIM_STATUS } from './modules/statusUpdate/graphql/mutations';

export const expatHealthClaimsData: ClaimsTemplateData<
  ExpatClaimStatus,
  ExpatClaimType
> = {
  insuranceType: 'INCOMING',

  // GraphQL ----------------------------------------
  queries: {
    fetchAllClaims: GET_EXPAT_HEALTH_CLAIMS,
    fetchSingleClaim: GET_EXPAT_HEALTH_CLAIM,
    fetchAllPolicies: GET_EXPAT_POLICIES,
  },
  mutations: {
    createClaim: CREATE_EXPAT_CLAIM,
    updateClaim: UPDATE_EXPAT_CLAIM,
    updateClaimStatus: UPDATE_EXPAT_CLAIM_STATUS,
  },

  // Features ----------------------------------------
  overview: {
    headers: tableHeaders,
    filterConfig: filters,
    rowConfig,
  },
  claimCreation: {
    createClaimSubmissionSchema: ZSubmittableExpatClaimSchema,
    retrieveFormData,
  },
  policyInfo: { retrievePolicyInfoRows: retrieveExpatPolicyInfoRows },
  claimInfo: {
    retrieveClaimInfoRows: retrieveExpatClaimInfoRows,
    retrieveClaimInfoOldConfirmationValue:
      retrieveExpatClaimInfoOldConfirmationValue,
    ClaimInfoConfirmationModal: ConfirmationModal,
  },
  statusUpdate: {
    dataByStatus,
    statusOptions: expatClaimStatusOptions,
  },
};
