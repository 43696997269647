import { TFunction } from '@getpopsure/i18n-react';
import { BadgeColor } from 'components/Badge/Badge';

type PrivateHealthHrStatusId =
  | 'received'
  | 'missinginfo'
  | 'covered'
  | 'canceled'
  | 'contactfeather';

type PrivateHealthHrStatus = {
  id: PrivateHealthHrStatusId;
  text: string;
};

const mapPrivateHealthHrStatus = (
  status: string,
  t: TFunction
): PrivateHealthHrStatus => {
  switch (status) {
    case 'APPLICATION_RECEIVED':
      return {
        id: 'received',
        text: t('admin.hr.privateHealth.status.received.label', 'Received'),
      };
    case 'APPLICATION_SENT':
    case 'PENDING':
    case 'ERR_MISSING_INFO':
      return {
        id: 'missinginfo',
        text: t('admin.hr.privateHealth.status.processing.label', 'Processing'),
      };
    case 'CANT_BE_COVERED':
    case 'DROPPED_OUT':
    case 'CANCELED':
      return {
        id: 'canceled',
        text: t('admin.hr.privateHealth.status.canceled.label', 'Canceled'),
      };
    case 'ACTIVE':
      return {
        id: 'covered',
        text: t('admin.hr.privateHealth.status.covered.label', 'Covered'),
      };
    default:
      return {
        id: 'contactfeather',
        text: 'Contact Feather',
      };
  }
};

const getPrivateHealthHrStatusColor = (
  status: PrivateHealthHrStatus
): BadgeColor => {
  const colors: {
    [K in ReturnType<typeof mapPrivateHealthHrStatus>['id']]: BadgeColor;
  } = {
    received: 'purple',
    covered: 'green',
    missinginfo: 'blue',
    canceled: 'gray',
    contactfeather: 'gray',
  };
  return colors[status.id];
};

export const getPrivateHealthHrStatus = (status: string, t: TFunction) => {
  const text = mapPrivateHealthHrStatus(status, t);
  const color = getPrivateHealthHrStatusColor(text);
  return { text, color };
};
