import { gql } from '@apollo/client';

export const HOUESHOLD_POLICY_ACTIVITY = gql`
  fragment HouseholdPolicyActivity on HouseholdPolicy {
    activity {
      id
      createdAt
      author {
        id
        company
        firstName
        lastName
      }
      newPolicyStatus
      newIssueStatus
      newCommissionStatus
      issue {
        id
        createdAt
        author {
          email
          company
          firstName
          lastName
        }
        description
        isCustomerInformed
        category
        status
        document {
          id
          name
          url
        }
      }
      comment
      type
      document {
        name
        url
        type
      }
    }
  }
`;
