/**
 * NOTE: This type is not being shared with BE.
 * When updating, also update BE type
 *
 * Notion page for all vertical claim statuses:
 * https://www.notion.so/featherinsurance/Claim-statuses-2-0-22713a3fe92c49969b2e403f655678b2
 */
export const liabilityClaimStatuses = [
  'RECEIVED',
  'INCOMPLETE',
  'MISSING_INFO_RECEIVED',
  'SUBMITTED_TO_PROVIDER',
  'OTHER_PAID_OUT',
  'FEATHER_PAID_OUT',
  'CUSTOMER_PAID_OUT',
  'DENIED',
  'DUPLICATE',
  'IN_REVIEW',
  'DROPPED_OUT',
  'ON_HOLD',
  'CLOSED',
  'PROCESSED_WITH_OTHER_CLAIMS',
] as const;
export type LiabilityClaimStatus = typeof liabilityClaimStatuses[number];

export const deprecatedLiabilityClaimStatuses: LiabilityClaimStatus[] = [
  'PROCESSED_WITH_OTHER_CLAIMS',
];

export const liabilityClaimTypes = [
  'KEY_LOST',
  'PROPERTY_DAMAGE',
  'INJURY_TO_OTHERS',
  'OTHER',
] as const;
export type LiabilityClaimType = typeof liabilityClaimTypes[number];
