import { gql } from '@apollo/client';

export const UPDATE_DENTAL_CLAIM = gql`
  mutation UpdateDentalClaim(
    $claimId: String!
    $amount: Float!
    $claimType: [String!]
    $iban: String
    $refundAmount: Float
    $featherPaidOn: String
    $customerPaidOn: String
  ) {
    updateDentalClaim(
      claimId: $claimId
      amount: $amount
      claimType: $claimType
      iban: $iban
      refundAmount: $refundAmount
      featherPaidOn: $featherPaidOn
      customerPaidOn: $customerPaidOn
    ) {
      id
      amount
      claimType {
        id
        type
      }
      iban
      refundAmount
      featherPaidOn
      customerPaidOn
      thirdPartyPaidOn
    }
  }
`;

export const SUBMIT_DENTAL_CLAIM = gql`
  mutation SubmitDentalClaim($claimId: String!, $comment: String) {
    submitDentalClaim(claimId: $claimId, comment: $comment)
  }
`;
