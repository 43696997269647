import {
  ApolloError,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import Button from 'components/Button';
import { ComboBoxOption } from 'components/ComboBox/ComboBox';
import { GET_COMPANIES } from 'graphql/admin/companies/queries';
import { ADD_ADMIN_USER } from 'graphql/admin/users/mutations';
import { GET_ADMIN_USERS } from 'graphql/admin/users/queries';
import { useEffect, useState } from 'react';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import { DataCompanies } from '../../types';
import CreateAdminUserForm from '../CreateAdminUserForm';

const CreateAdminUserButton = () => {
  const [companiesQuery, setCompaniesQuery] = useState('');

  const {
    data: companies,
    loading: loadingCompanies,
    error: errorCompanies,
    fetchMore,
  } = useQuery<DataCompanies>(GET_COMPANIES, {
    variables: {
      limit: 25,
      offset: 0,
      searchString: companiesQuery,
      sortColumn: '',
      sortOrder: '',
      filterType: 'any',
    },
    notifyOnNetworkStatusChange: false,
  });

  useEffect(() => {
    fetchMore({
      variables: {
        searchString: companiesQuery,
      },
    });
  }, [companiesQuery]);

  let companyOptions: ComboBoxOption[] = [];

  if (!loadingCompanies && !errorCompanies && companies) {
    companyOptions = companies.companies.map((company) => {
      return {
        id: company.id,
        label: company.name,
      };
    });
  }

  const alertBannersState = useReactiveVar(alertBanners);

  const onCreateAdminUserComplete = () => {
    setForm(false);

    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'SUCCESS',
      message: 'A new admin user was successfully created.',
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };

  const onCreateAdminUserError = (error: ApolloError) => {
    setForm(false);

    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'WARNING',
      message: error.message,
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };

  const [createAdminUser, { loading, error, reset }] = useMutation(
    ADD_ADMIN_USER,
    {
      refetchQueries: [GET_ADMIN_USERS],
      onCompleted: onCreateAdminUserComplete,
      onError: onCreateAdminUserError,

      /**
       * TODO: [KONG] Remove errorPolicy for better use of error handling
       *
       * This is a temporary solution to stop the rest of resolver chain when admin user creation fails.
       *
       * Ideally, we should make this default in our config and specify which requests should return partial results.
       * See docs for more info:
       * https://www.apollographql.com/docs/react/data/error-handling/#graphql-error-policies
       */
      errorPolicy: 'none',
    }
  );
  const [form, setForm] = useState(false);

  const handleCreateAdminUser = async (variables: {
    firstName: string;
    lastName: string;
    email: string;
    isFeather: boolean;
    companyId?: string;
    roles?: string[];
    language: string;
    hasAccessToNewAdminPanel: boolean;
    hasAccessToOldAdminPanel: boolean;
  }) => {
    if (!variables.isFeather && !variables.companyId && !variables.roles) {
      return;
    }

    createAdminUser({
      variables,
    });
  };

  const openForm = (open: boolean) => {
    setForm(open);
    if (open) {
      reset();
    }
  };

  const handleButtonClick = () => {
    openForm(true);
  };

  return (
    <>
      <Button buttonType="primary" onClick={handleButtonClick}>
        Create admin
      </Button>
      {form && (
        <CreateAdminUserForm
          open={form}
          setOpen={setForm}
          createAdminUser={handleCreateAdminUser}
          loading={loading}
          companyOptions={companyOptions}
          errorMessage={error?.message}
          setCompaniesQuery={setCompaniesQuery}
        />
      )}
    </>
  );
};

export default CreateAdminUserButton;
