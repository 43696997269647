import classNames from 'classnames';

export const issueCardWrapperFull = classNames(
  'p-[16px]',
  'rounded-[8px]',
  'w-[700px]'
);

export const issueCardWrapperSmall = classNames(
  'p-[16px]',
  'rounded-[8px]',
  'w-[260px]'
);

export const grayCard = classNames('bg-gray-50');
export const whiteCard = classNames('bg-white');

export const issueCardHeader = classNames(
  'flex',
  'justify-between',
  'items-center'
);

export const issueCategory = classNames(
  'flex',
  'items-center',
  'gap-x-1',
  'flex-wrap'
);

export const issueStatus = classNames(
  'bg-gray-300',
  'py-[4px]',
  'px-[8px]',
  'rounded-[4px]',
  'text-xs',
  'text-gray-600',
  'font-bold'
);

export const issueCategoryText = classNames('mr-1');

export const darkText = classNames('text-sm', 'text-gray-900');
export const lightText = classNames('text-sm', 'text-gray-600');
export const veryLightText = classNames('text-sm', 'text-gray-500');

export const crossIcon = classNames(
  'text-gray-500',
  'fill-gray',
  'w-[16px]',
  'mr-[4px]'
);

export const customerInformedText = classNames('text-xs', 'text-gray-600');

export const description = classNames('mt-[14px]');

export const customerInformedSection = classNames(
  'flex',
  'items-center',
  'space-x-1',
  'mt-1'
);
