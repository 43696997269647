import classNames from 'classnames';

export const section = classNames(
  'mt-4',
  'flex',
  'flex-col',
  'space-y-2',
  'first:mt-0'
);

export const errorMessage = classNames('text-sm', 'text-red-600');
