import { TFunction } from '@getpopsure/i18n-react';
import { Gender } from '@popsure/public-models';
import { InformationSection } from 'components/InformationCard';
import DateInformation from 'components/InformationCard/DateInformation';
import DropdownInformation from 'components/InformationCard/DropdownInformation';
import TextBoxInformation from 'components/InformationCard/TextBoxInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import ToggleInformation from 'components/InformationCard/ToggleInformation';
import { User } from 'models/User';
import { mapGender } from 'shared/mapGender';
import { Language, mapLanguage } from 'shared/mapLanguage';
import { z } from 'zod';

export const retrieveCustomerRows = (
  t: TFunction,
  customer: User
): InformationSection[] => [
  {
    title: 'Customer information',
    boldTitle: false,
    rows: [
      {
        id: 'firstName',
        title: 'First name',
        data: customer?.firstName ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: true,
        required: true,
        errorMessage: 'This field is required.',
      },
      {
        id: 'lastName',
        title: 'Last name',
        data: customer?.lastName ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: true,
        required: true,
        errorMessage: 'This field is required.',
      },
      {
        id: 'email',
        title: 'Email address',
        data: customer?.email ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: true,
        required: true,
        errorMessage:
          "Email is either missing or invalid. Make sure it's written like example@example.com",
        zodValidationSchema: z.object({
          email: z.string().email(),
        }),
      },
      {
        id: 'dateOfBirth',
        title: 'Date of birth',
        data: customer?.dateOfBirth ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: true,
      },
      {
        id: 'gender',
        title: 'Gender',
        data: mapGender(t)[customer?.gender ?? 'OTHER'],
        type: 'DROPDOWN',
        component: DropdownInformation,
        editable: true,
        dropdownOptions: [
          { id: 'MALE', label: 'Male' },
          { id: 'FEMALE', label: 'Female' },
          { id: 'OTHER', label: 'Other' },
        ],
        optionMapping: (option: string) => mapGender(t)[option as Gender],
        placeholder: 'Select gender',
        multipleOptions: false,
      },
      {
        id: 'language',
        title: 'Language',
        data: customer?.language ? mapLanguage(customer?.language) : '',
        editable: true,
        type: 'DROPDOWN',
        component: DropdownInformation,
        dropdownOptions: [
          { id: 'en', label: 'English' },
          { id: 'de', label: 'German' },
        ],
        optionMapping: (option: string) => mapLanguage(option as Language),
        placeholder: 'Select language',
        multipleOptions: false,
      },
      {
        id: 'intercomEUId',
        title: 'Intercom user ID',
        data: customer?.intercomEUId ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: true,
      },
      {
        id: 'stripeToken',
        title: 'Stripe customer token',
        data: customer?.stripeToken ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: true,
      },
      {
        id: 'isDelinquent',
        title: 'Delinquent',
        data: customer.isDelinquent ? 'Yes' : 'No',
        type: 'TOGGLE',
        component: ToggleInformation,
        editable: true,
      },
      {
        id: 'isFraudulent',
        title: 'Fraudulent',
        data: customer.isFraudulent ? 'Yes' : 'No',
        type: 'TOGGLE',
        component: ToggleInformation,
        editable: true,
      },
      {
        id: 'fraudulentAdditionalInfo',
        title: 'Fraudulent additional info',
        data: customer.fraudulentAdditionalInfo ?? '',
        type: 'TEXTBOX',
        component: TextBoxInformation,
        editable: true,
      },
      {
        id: 'created',
        title: 'Created on',
        data: customer?.created ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: false,
      },
    ],
  },
];
