import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { InformationSection } from 'components/InformationCard';
import ComboBoxInformation from 'components/InformationCard/ComboBoxInformation/ComboBoxInformation';
import { CurrencyInformation } from 'components/InformationCard/CurrencyInformation';
import DateInformation from 'components/InformationCard/DateInformation';
import MultipleLineInformation from 'components/InformationCard/MultipleLineInformation';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { LiabilityClaim } from 'pages/claims/liability/models';
import {
  LiabilityClaimType,
  liabilityClaimTypes,
} from 'shared/claims/liability/models';
import { policyMappers } from 'shared/insurances/';

export const retrieveLiabilityClaimInfoRows = (
  claimInfo: LiabilityClaim
): InformationSection[] => [
  {
    id: 'claimInfo',
    title: 'Claim information',
    boldTitle: false,
    rows: [
      {
        id: 'claimNumber',
        title: 'Claim number',
        data: claimInfo?.claimNumber ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: false,
      },
      {
        id: 'status',
        title: 'Status',
        data: claimInfo?.status ?? 'RECEIVED',
        statusMapping: (status) => {
          return {
            color:
              policyMappers.LIABILITY?.claim?.badgeColorMapper[status] ??
              'blue',
            text: {
              text:
                policyMappers.LIABILITY?.claim?.claimStatusMapper[status] ?? '',
              id: status,
            },
          };
        },
        type: 'STATUS',
        component: StatusInformation,
        editable: false,
      },
      {
        id: 'createdOn',
        title: 'Created on',
        data: claimInfo?.createdAt ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: false,
      },
      {
        id: 'claimType',
        title: 'Claim type',
        data: claimInfo.claimType?.map(({ type }) => type).join(',') ?? '',
        type: 'DROPDOWN',
        component: ComboBoxInformation,
        dropdownOptions: liabilityClaimTypes.map((type) => ({
          id: type,
          label: policyMappers.LIABILITY?.claim?.typeNameMapper
            ? policyMappers.LIABILITY.claim.typeNameMapper[
                type as LiabilityClaimType
              ]
            : '',
        })),
        optionMapping: (option: string) =>
          policyMappers.LIABILITY?.claim?.typeNameMapper
            ? policyMappers.LIABILITY.claim.typeNameMapper[
                option as LiabilityClaimType
              ]
            : '',
        placeholder: 'Choose a claim type',
        editable: true,
        multipleOptions: true,
        required: true,
      },
      {
        id: 'dateOfEvent',
        title: 'Event date',
        data: claimInfo?.dateOfEvent ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: true,
      },
      {
        id: 'eventDescription',
        title: 'Event description',
        data: claimInfo?.eventDescription ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: true,
      },
      {
        id: 'iban',
        title: 'IBAN',
        data: claimInfo?.iban ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: true,
      },
      {
        id: 'amount',
        title: 'Claimed amount',
        data: claimInfo?.amount ?? 0,
        type: 'CURRENCY',
        component: CurrencyInformation,
        editable: true,
        placeholder: englishFormattedEuroCurrency(0),
      },
      {
        id: 'provision',
        title: 'Claim provision',
        data:
          claimInfo.provision !== null && claimInfo.provision !== undefined
            ? claimInfo.provision
            : undefined,
        type: 'CURRENCY',
        component: CurrencyInformation,
        editable: true,
        placeholder: englishFormattedEuroCurrency(0),
      },
      {
        id: 'refundAmount',
        title: 'Refund amount',
        data:
          claimInfo.refundAmount !== null &&
          claimInfo.refundAmount !== undefined
            ? claimInfo.refundAmount
            : undefined,
        type: 'CURRENCY',
        component: CurrencyInformation,
        editable: true,
        placeholder: englishFormattedEuroCurrency(0),
      },
      {
        id: 'otherCosts',
        title: 'Other costs',
        data: claimInfo?.otherCosts ?? 0,
        type: 'CURRENCY',
        component: CurrencyInformation,
        editable: true,
        placeholder: englishFormattedEuroCurrency(0),
      },
      {
        id: 'featherPaidOn',
        title: 'Feather paid on',
        data: claimInfo?.featherPaidOn ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: true,
      },
      {
        id: 'customerPaidOn',
        title: 'Customer paid on',
        data: claimInfo?.customerPaidOn ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: true,
      },
      {
        id: 'thirdPartyPaidOn',
        title: '3rd party paid on',
        data: claimInfo?.thirdPartyPaidOn ?? '',
        type: 'DATE',
        component: DateInformation,
        editable: true,
      },
    ],
  },
];

export const retrieveAdditionalLiabilityClaimInfoRows = ({
  thirdParty,
}: LiabilityClaim): InformationSection[] => [
  {
    id: 'claimInfo',
    title: 'Third party details',
    boldTitle: false,
    rows: [
      {
        id: 'thirdPartyName',
        title: 'Name',
        data: thirdParty?.name ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: true,
      },
      {
        id: 'thirdPartyEmail',
        title: 'Email',
        data: thirdParty?.email ?? '',
        type: 'TEXT',
        component: TextInformation,
        editable: true,
      },
      {
        id: 'thirdPartyAddress',
        title: 'Address',
        type: 'MULTIPLELINE',
        component: MultipleLineInformation,
        editable: true,
        data: thirdParty?.address ?? {},
        multipleLinesData: [
          {
            id: 'house_number',
            label: 'House number',
            data: thirdParty?.address?.house_number ?? '',
          },
          {
            id: 'street',
            label: 'Street',
            data: thirdParty?.address?.street ?? '',
          },
          {
            id: 'city',
            label: 'City',
            data: thirdParty?.address?.city ?? '',
          },
          {
            id: 'additional_information',
            label: 'Additional information',
            data: thirdParty?.address?.additional_information ?? '',
          },
          {
            id: 'postcode',
            label: 'Postal code',
            data: thirdParty?.address?.postcode ?? '',
          },
          {
            id: 'country',
            label: 'Country',
            data: thirdParty?.address?.country ?? '',
          },
        ],
      },
    ],
  },
];
