import { useEffect, useState } from 'react';
import { retrieveZodErrorMessages } from 'shared/errorHandling/retrieveZodErrorMessages';
import { ZodObject, ZodRawShape, ZodUnion } from 'zod';

import { ModalFormView } from './ModalForm.view';
import { ModalFormData, SubmitValidationError } from './models';

interface Props<SubmittableForm, SelectOption> {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  loading: boolean;
  submitValidationSchema:
    | ZodObject<ZodRawShape>
    | ZodUnion<[ZodObject<ZodRawShape>, ZodObject<ZodRawShape>]>;
  onSubmit: (answers: Partial<SubmittableForm>) => void;
  submitButtonLabel: string;
  title: string;
  formData: ModalFormData<SubmittableForm, SelectOption>[];
  bottomElement?: React.ReactNode;
  scrollable?: boolean;
}

export const ModalForm = <
  SubmittableForm extends Object,
  SelectOption extends string
>({
  isOpen,
  setIsOpen,
  submitValidationSchema,
  onSubmit,
  loading,
  submitButtonLabel,
  title,
  formData,
  bottomElement,
  scrollable,
}: Props<SubmittableForm, SelectOption>) => {
  const [answers, setAnswers] = useState<Partial<SubmittableForm>>({});
  const [validationError, setValidationError] = useState<
    Partial<SubmitValidationError<SubmittableForm>>
  >({});
  const [submissionDisabled, setSubmissionDisabled] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setAnswers({});
      setValidationError({});
    }
  }, [isOpen]);

  const onChange = (input: Partial<SubmittableForm>) => {
    setSubmissionDisabled(false);
    setAnswers(input);
  };

  const handleSubmit = () => {
    const validation = submitValidationSchema.safeParse({ ...answers });

    if (!validation.success) {
      setValidationError({
        ...retrieveZodErrorMessages<SubmitValidationError<SubmittableForm>>(
          validation.error
        ),
      });
      setSubmissionDisabled(true);
      return;
    }

    setValidationError({});

    onSubmit(answers);
  };

  return (
    <ModalFormView<SubmittableForm, SelectOption>
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onChange={onChange}
      answers={answers}
      submissionDisabled={submissionDisabled}
      validationError={validationError}
      loading={loading}
      title={title}
      handleSubmit={handleSubmit}
      submitButtonLabel={submitButtonLabel}
      formData={formData}
      bottomElement={bottomElement}
      scrollable={scrollable}
    />
  );
};
