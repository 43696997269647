import Page404 from 'pages/errors/404';
import { useParams } from 'react-router';
import { Country } from 'shared/countries/models';

import { getTemplateDataByInsuranceType } from '../data';
import { getTemplateInsuranceTypeCountry } from '../models/InsuranceTypes';
import PolicyDetailsTemplate from '../PolicyDetailsTemplate/PolicyDetailsTemplate';
import { getDbInsuranceType, isValidTemplateInsurancePath } from '../utils';

const PolicyDetailsTemplateGuard = () => {
  const { insuranceType, id, country } = useParams<{
    insuranceType: string;
    id: string;
    country: Country;
  }>();

  if (!isValidTemplateInsurancePath(insuranceType)) {
    return <Page404 />;
  }

  const dbInsuranceType = getDbInsuranceType(insuranceType);

  if (!getTemplateInsuranceTypeCountry()[dbInsuranceType].includes(country)) {
    return <Page404 />;
  }

  const templateData = getTemplateDataByInsuranceType(country)[dbInsuranceType];
  const policyQuery = templateData.queries.policy;

  if (policyQuery) {
    return (
      <PolicyDetailsTemplate
        insuranceType={dbInsuranceType}
        templateData={{
          ...templateData,
          queries: { ...templateData.queries, policy: policyQuery },
        }}
        policyId={id}
      />
    );
  }

  return <Page404 />;
};

export default PolicyDetailsTemplateGuard;
