import { TFunction } from '@getpopsure/i18n-react';
import DateInformation from 'components/InformationCard/DateInformation';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import { PolicyStatus } from 'models/publicHealthStatus';
import { InformationCardDetails } from 'pages/policies/template/models/PolicyTemplateData';
import { getHRPublicHealthStatus } from 'shared/mapHRPublicHealthStatus';

export const getOverviewTabCards = (t: TFunction) => {
  return (data?: {
    hrPublicHealthPolicy: PublicHealthPolicy;
  }): InformationCardDetails[] => [
    {
      editable: false,
      cards: [
        {
          title: t(
            'admin.hr.publicHealth.overview.employeeInformation.title',
            'Employee information'
          ),
          rows: [
            {
              id: 'firstName',
              title: t(
                'admin.hr.publicHealth.overview.firstName.label',
                'First name'
              ),
              data: data?.hrPublicHealthPolicy?.user?.firstName ?? '',
              component: TextInformation,
              type: 'TEXT',
            },
            {
              id: 'lastName',
              title: t(
                'admin.hr.publicHealth.overview.lastName.label',
                'Last name'
              ),
              data: data?.hrPublicHealthPolicy?.user?.lastName ?? '',
              component: TextInformation,
              type: 'TEXT',
            },
            {
              id: 'dateOfBirth',
              title: t(
                'admin.hr.publicHealth.overview.dateOfBirth.label',
                'Date of birth'
              ),
              data: data?.hrPublicHealthPolicy?.user?.dateOfBirth ?? '',
              component: DateInformation,
              type: 'DATE',
            },
          ],
        },
      ],
    },
    {
      editable: false,
      cards: [
        {
          title: t(
            'admin.hr.publicHealth.overview.policyInformation.title',
            'Policy information'
          ),
          rows: [
            {
              id: 'provider',
              title: t(
                'admin.hr.publicHealth.overview.provider.label',
                'Provider'
              ),
              data: data?.hrPublicHealthPolicy?.providerId ?? '',
              component: TextInformation,
              type: 'TEXT',
            },
            {
              id: 'status',
              title: t('admin.hr.publicHealth.overview.status.label', 'Status'),
              data: data?.hrPublicHealthPolicy?.publicStatus ?? '',
              statusMapping: (status: string) =>
                getHRPublicHealthStatus(status as PolicyStatus),
              component: StatusInformation,
              type: 'STATUS',
            },
            {
              id: 'startDate',
              title: t(
                'admin.hr.publicHealth.overview.startDate.label',
                'Start date'
              ),
              data: data?.hrPublicHealthPolicy?.startDate ?? '',
              component: DateInformation,
              type: 'DATE',
            },
            {
              id: 'submittedOn',
              title: t(
                'admin.hr.publicHealth.overview.submittedOn.label',
                'Submitted on'
              ),
              data: data?.hrPublicHealthPolicy?.createdAt ?? '',
              component: DateInformation,
              type: 'DATE',
            },
            {
              id: 'svnr',
              title: t('admin.hr.publicHealth.overview.svnr.label', 'SVNR'),
              data: data?.hrPublicHealthPolicy?.user.svnr ?? '',
              component: TextInformation,
              type: 'TEXT',
            },
          ],
        },
      ],
    },
  ];
};
