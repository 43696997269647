import classNames from 'classnames';

export const title = classNames(
  'text-sm',
  'text-gray-900',
  'font-bold',
  'mr-2'
);
export const text = classNames('text-sm', 'text-gray-900');

export const wrapper = classNames('mb-6');

export const additionalText = classNames('text-sm', 'text-gray-600');
