import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { UserIcon } from '@heroicons/react/solid';
import { ActivityLog } from 'components/ActivityLog/ActivityLog';
import { DetailsPage } from 'components/DetailsPage';
import { ExternalLink, InternalLink } from 'components/DetailsPage/models';
import { NotesTab } from 'components/Notes/NotesTab';
import { TabInformation } from 'components/Tabs';
import { Status } from 'models/statusColor';
import { ArchivePolicy } from 'pages/policies/template/ArchivePolicy';
import {
  ARCHIVE_POLICY,
  UNARCHIVE_POLICY,
} from 'pages/policies/template/mutations';
import { generatePath, RouteComponentProps } from 'react-router';
import { paths } from 'routes/definedPaths';
import { policyMappers } from 'shared/insurances';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { getIntercomUrl } from 'shared/utils/getIntercomUrl';
import { getStripeUrl } from 'shared/utils/getStripeUrl';
import { v4 as uuidv4 } from 'uuid';

import ChangeStatusAction from '../components/ChangeStatusAction/ChangeStatusAction';
import OverviewTab from '../components/OverviewTab/OverviewTab';
import QuestionnaireTab from '../components/QuestionnaireTab/QuestionnaireTab';
import { GET_EXPAT_HEALTH_POLICY } from '../graphql/policy';
import { ExpatHealthPolicy } from '../models/ExpatHealthPolicy';

type ExpatHealthPolicyPageParams = {
  id: string;
};

interface Data {
  expatHealthPolicy: ExpatHealthPolicy;
}

const ExpatHealthPolicyPage = ({
  match,
}: RouteComponentProps<ExpatHealthPolicyPageParams>) => {
  const { loading, error, data } = useQuery<Data>(GET_EXPAT_HEALTH_POLICY, {
    variables: {
      id: match.params.id,
    },
  });

  const alertBannersState = useReactiveVar(alertBanners);

  const onArchiveOrUnarchiveComplete = (isArchived: boolean) => {
    const message = isArchived
      ? 'Policy successfully archived.'
      : 'Policy successfully unarchived.';

    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'SUCCESS',
      message,
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };
  const onArchiveOrUnarchiveError = () => {
    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'WARNING',
      message: 'Something went wrong. Please try again.',
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
  };

  const [archivePolicy] = useMutation(ARCHIVE_POLICY, {
    variables: {
      insuranceType: 'INCOMING',
      id: match.params.id,
    },
    refetchQueries: [GET_EXPAT_HEALTH_POLICY],
    errorPolicy: 'none',
    onCompleted: () => onArchiveOrUnarchiveComplete(true),
    onError: onArchiveOrUnarchiveError,
  });

  const [unarchivePolicy] = useMutation(UNARCHIVE_POLICY, {
    variables: {
      insuranceType: 'INCOMING',
      id: match.params.id,
    },
    refetchQueries: [GET_EXPAT_HEALTH_POLICY],
    errorPolicy: 'none',
    onCompleted: () => onArchiveOrUnarchiveComplete(false),
    onError: onArchiveOrUnarchiveError,
  });

  const policy = data?.expatHealthPolicy;

  const tabs: TabInformation[] = [
    {
      name: 'Overview',
      href: '#overview',
      withDot: false,
    },
    {
      name: 'Questionnaire',
      href: '#questionnaire',
      withDot: false,
    },
    {
      name: 'Activity',
      href: '#activity',
      withDot: false,
    },
    {
      name: 'Notes',
      href: '#notes',
      withDot: false,
    },
  ];

  const tabComponents = [
    {
      id: 'overview',
      component: OverviewTab,
      props: { policy },
    },
    {
      id: 'questionnaire',
      component: QuestionnaireTab,
      props: { policy },
    },
    {
      id: 'activity',
      component: ActivityLog,
      props: {
        where: [
          { table: 'user_policies', field: 'id', value: policy?.id },
          { table: 'user', field: 'id', value: policy?.user?.id },
          ...(policy?.questionnaire?.id
            ? [
                {
                  table: 'questionnaires',
                  field: 'id',
                  value: policy.questionnaire.id,
                },
              ]
            : []),
        ],
      },
    },
    {
      id: 'notes',
      component: NotesTab,
      props: {
        type: 'POLICY',
        userOrPolicyOrClaimId: policy?.id,
        refetchQueries: [GET_EXPAT_HEALTH_POLICY],
      },
    },
  ];

  const externalLinks: ExternalLink[] = [
    {
      title: 'Intercom',
      href: getIntercomUrl(policy?.user?.intercomEUId) ?? '',
    },
    {
      title: 'Stripe',
      href: getStripeUrl(policy?.user?.stripeToken) ?? '',
    },
  ];

  const internalLinks: InternalLink[] = [
    {
      title: `Account: ${policy?.user?.firstName} ${policy?.user?.lastName}`,
      icon: UserIcon,
      href: data
        ? generatePath(paths.customers.details.path, {
            id: policy?.user?.id,
          })
        : '',
    },
  ];

  const statusMapper = (status: string): Status => {
    const policyMapper = policyMappers.INCOMING;
    return {
      text: {
        id: status,
        text: policyMapper?.statusMapper[status] ?? '',
      },
      color: policyMapper?.badgeColorMapper[status] ?? 'gray',
    };
  };

  return (
    <DetailsPage
      windowTitle="Expat policy - Feather Admin Panel"
      pageTitle="Expat health"
      pageSubtitle={
        policy?.insuredPerson
          ? `${policy.insuredPerson.firstName} ${policy.insuredPerson.lastName}`
          : `${policy?.user?.firstName} ${policy?.user?.lastName}`
      }
      backLink={{
        text: 'Back',
      }}
      loading={loading}
      tabs={tabs}
      tabComponents={tabComponents}
      current="policies-expat"
      internalLinks={internalLinks}
      externalLinks={externalLinks}
      headerInfoTexts={[]}
      error={error}
      headerActions={
        <div className="flex items-center justify-between">
          <ChangeStatusAction
            status={statusMapper(policy?.publicStatus ?? '')}
            colorMapper={policyMappers.INCOMING?.badgeColorMapper}
            activeUntil={policy?.activeUntilDate}
            startDate={policy?.startDate}
            policyNumber={policy?.policyNumber}
            policyId={policy?.id ?? ''}
            price={policy?.price}
          />
          <ArchivePolicy
            onArchive={archivePolicy}
            onUnarchive={unarchivePolicy}
            shouldArchivePolicy={Boolean(!policy?.archivedAt)}
          />
        </div>
      }
    />
  );
};

export default ExpatHealthPolicyPage;
