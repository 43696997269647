import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { useState } from 'react';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import { CREATE_REFERRAL_CAMPAIGN } from '../../graphql/mutations';
import { GET_REFERRAL_CAMPAIGNS } from '../../graphql/queries';
import { ReferralCampaign } from '../../types';
import ReferralCampaignForm from '../ReferralCampaignForm';

const CreateReferralCampaignModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const alertBannersState = useReactiveVar(alertBanners);

  const [createReferralCampaign, { error, loading, reset }] = useMutation(
    CREATE_REFERRAL_CAMPAIGN,
    {
      refetchQueries: [GET_REFERRAL_CAMPAIGNS],
      onCompleted: () => {
        setOpen(false);

        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'SUCCESS',
          message: t(
            'referralCampaigns.createReferralCampaign.alert.success',
            'New campaign created'
          ),
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
      onError: () => {
        const newAlertBanner: AlertBannerState = {
          id: uuidv4(),
          type: 'WARNING',
          message: t(
            'referralCampaigns.createReferralCampaign.alert.error',
            'Something went wrong. Please try again.'
          ),
        };

        setNewAlertBanner({ state: alertBannersState, newAlertBanner });
      },
    }
  );

  const handleButtonClick = () => openForm(true);

  const openForm = (open: boolean) => {
    setOpen(open);
    if (open) {
      reset();
    }
  };

  const handleSubmit = async (input: ReferralCampaign) => {
    createReferralCampaign({
      variables: { input },
    });
  };

  return (
    <>
      <Button onClick={handleButtonClick} buttonType="primary">
        {t(
          'referralCampaign.createReferralCampaign.button.caption',
          'New campaign'
        )}
      </Button>
      <Modal
        open={open}
        setOpen={setOpen}
        title={t(
          'referralCampaign.createReferralCampaign.modal.title',
          'Create new campaign'
        )}
        loading={loading}
        textLoading={t(
          'referralCampaign.createReferralCampaign.modal.textLoading',
          'Creating...'
        )}
        hideActions
      >
        <ReferralCampaignForm
          onSubmit={handleSubmit}
          setOpen={setOpen}
          loading={loading}
          error={error}
        />
      </Modal>
    </>
  );
};

export default CreateReferralCampaignModal;
