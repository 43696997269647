import { ModalFormData } from 'components/ModalForm/models';
import { retrievePolicySuggestion } from 'pages/claims/template/components/CreateClaimModal/utils';
import { ExpatSpainPolicy } from 'pages/policies/expatSpain/models/ExpatSpainPolicy';
import { claimTypeNameMapper as expatSpainClaimTypeNameMapper } from 'shared/claims/expatSpain/claimTypeNameMapper';
import {
  ExpatSpainClaimStatus,
  expatSpainClaimStatuses,
  ExpatSpainClaimType,
  expatSpainClaimTypes,
} from 'shared/claims/expatSpain/models';
import { statusNameMapper as expatSpainStatusNameMapper } from 'shared/claims/expatSpain/statusNameMapper';

import { SubmittableClaim } from '../../../template/components/CreateClaimModal/models';

export const retrieveFormData = (
  policies: ExpatSpainPolicy[],
  setPolicySearchQuery: (query: string) => void,
  policiesLoading: boolean
): ModalFormData<
  SubmittableClaim<ExpatSpainClaimStatus, ExpatSpainClaimType>,
  ExpatSpainClaimStatus | ExpatSpainClaimType
>[] => [
  {
    id: 'policyId',
    label: 'User policy',
    renderDetails: {
      type: 'autoSuggest',
      options: policies.map(({ id, user, policyNumber }) => ({
        id,
        label: retrievePolicySuggestion({
          firstName: user?.firstName,
          lastName: user?.lastName,
          policyNumber,
        }),
      })),
      placeholder: 'Enter name, email or policy number',
      setExternalQuery: (query: string) => {
        setPolicySearchQuery(query);
      },
      useUnfilteredOptions: true,
    },
    color: 'gray',
    required: true,
    loading: policiesLoading,
  },
  {
    id: 'status',
    label: 'Status',
    renderDetails: {
      type: 'select',
      options: expatSpainClaimStatuses.map((status) => ({
        id: status,
        label: expatSpainStatusNameMapper[status],
      })),
      placeholder: 'Select a status',
    },
    color: 'gray',
    required: true,
  },
  {
    id: 'claimType',
    label: 'Claim type',
    renderDetails: {
      type: 'multiSelect',
      options: expatSpainClaimTypes.map((type) => {
        return {
          id: type,
          label: expatSpainClaimTypeNameMapper[type],
        };
      }),
      placeholder: 'Select type',
    },
    color: 'gray',
    required: true,
  },
  {
    id: 'diagnosis',
    label: 'Diagnosis',
    renderDetails: {
      type: 'text',
      placeholder: 'Diagnosis made by the doctor',
    },
    color: 'gray',
    required: true,
  },
  {
    id: 'amount',
    label: 'Claim amount',
    renderDetails: { type: 'currency', placeholder: '0.00' },
    color: 'gray',
    required: true,
  },
  {
    id: 'iban',
    label: 'IBAN',
    renderDetails: { type: 'text', placeholder: 'Enter IBAN' },
    color: 'gray',
    required: true,
  },
  {
    id: 'uploadDocument',
    label: 'Upload a document',
    renderDetails: { type: 'upload' },
    required: true,
  },
];
