import classNames from 'classnames';

export const cell = classNames(
  'inline-flex',
  'items-center',
  'px-2.5',
  'py-0.5',
  'rounded-full',
  'text-xs'
);

export const blue = classNames('bg-blue-100', 'text-blue-800');
export const red = classNames('bg-red-100', 'text-red-800');
export const green = classNames('bg-green-100', 'text-green-800');
export const pink = classNames('bg-pink-100', 'text-pink-800');
export const yellow = classNames('bg-yellow-100', 'text-yellow-800');
export const gray = classNames('bg-gray-300', 'text-gray-700');
export const purple = classNames('bg-purple-100', 'text-purple-800');

export const blueDot = classNames('text-blue-600');
export const redDot = classNames('text-red-600');
export const greenDot = classNames('text-green-400');
export const pinkDot = classNames('text-pink-700');
export const yellowDot = classNames('text-yellow-400');
export const grayDot = classNames('text-gray-600');
export const purpleDot = classNames('text-purple-600');
