import classNames from 'classnames';

export const button = classNames(
  'relative',
  'inline-flex',
  'items-center',
  'px-2',
  'py-2',
  'rounded-[2px]',
  'bg-white',
  'text-sm',
  'text-gray-600',
  'mr-px',
  'hover:bg-gray-50',
  'hover:text-indigo-500',
  'focus:bg-gray-50',
  'focus:text-indigo-500',
  'focus:outline-indigo',
  'disabled:pointer-events-none',
  'disabled:text-gray-500'
);

export const borderRight = classNames('rounded-r-[6px]');

export const borderLeft = classNames('rounded-l-[6px]');

export const icon = classNames('h-5', 'w-5');
