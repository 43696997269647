import { gql } from '@apollo/client';

export const UPDATE_EXPAT_SPAIN_CLAIM = gql`
  mutation UpdateExpatSpainClaim(
    $claimId: String!
    $amount: Float!
    $claimType: [String!]
    $iban: String
    $refundAmount: Float
    $featherPaidOn: String
    $customerPaidOn: String
    $thirdPartyPaidOn: String
    $notificationSentToProviderAt: String
    $eventDescription: String
  ) {
    updateExpatSpainClaim(
      claimId: $claimId
      amount: $amount
      claimType: $claimType
      iban: $iban
      refundAmount: $refundAmount
      featherPaidOn: $featherPaidOn
      customerPaidOn: $customerPaidOn
      thirdPartyPaidOn: $thirdPartyPaidOn
      notificationSentToProviderAt: $notificationSentToProviderAt
      eventDescription: $eventDescription
    ) {
      id
      amount
      claimType {
        id
        type
      }
      iban
      refundAmount
      featherPaidOn
      customerPaidOn
      thirdPartyPaidOn
      notificationSentToProviderAt
      eventDescription
    }
  }
`;
