import { DownloadIcon } from '@heroicons/react/outline';
import Link from 'components/Link';

interface TableCellFileProps {
  data: unknown;
  getFileUrl: (data: any) => string;
}

export const TableCellFile = ({ getFileUrl, data }: TableCellFileProps) => {
  const cellData = getFileUrl(data);

  return (
    <div className="flex justify-end">
      <Link
        color="primary"
        IconComponent={DownloadIcon}
        href={cellData}
        disabled={!cellData}
      />
    </div>
  );
};
