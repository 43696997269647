import { InformationSection } from 'components/InformationCard';
import EditableInformationCard, {
  ConfirmationModalProps,
} from 'components/InformationCard/EditableInformationCard';
import { DocumentNode } from 'graphql';

interface Props {
  claimId: string;
  claimInfoData: InformationSection[];
  refetchQueries: DocumentNode[];
  updateClaimMutation: DocumentNode;
  ConfirmationModal?: React.ElementType<ConfirmationModalProps>;
  oldConfirmationValue?: { id: string; value: string | number }[];
}

export const ClaimInfo = ({
  refetchQueries,
  updateClaimMutation,
  claimInfoData,
  claimId,
  ConfirmationModal,
  oldConfirmationValue,
}: Props) => {
  const claimInfoRows = claimInfoData.find(
    ({ id }) => id === 'claimInfo'
  )?.rows;

  if (!claimInfoRows) {
    throw new Error('[Claim info] Rows are not found');
  }

  const editableRows = claimInfoRows.reduce((acc, currentValue) => {
    const editableValue =
      currentValue.data !== undefined || currentValue.data !== null
        ? currentValue.data
        : undefined;

    return {
      ...acc,
      [currentValue.id]: currentValue.multipleOptions
        ? [...currentValue.data.split(',')]
        : editableValue,
    };
  }, {});

  return (
    <EditableInformationCard
      sections={claimInfoData}
      editableData={editableRows}
      mutation={updateClaimMutation}
      resourceId={claimId}
      refetchQueries={refetchQueries}
      extraVariables={{ claimId }}
      successMessage="You have successfully saved the changes"
      ConfirmationModal={ConfirmationModal}
      oldConfirmationValue={oldConfirmationValue}
    />
  );
};
