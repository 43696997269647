import { gql } from '@apollo/client';

export const PRIVATE_QUESTIONNAIRE_FIELDS = gql`
  fragment PrivateQuestionnaireAnswerFields on PrivateHealthAnswers {
    investigation
    specify
    tariffInfo
    personalInfo {
      name {
        firstName
        lastName
      }
      address {
        city
        street
        country
        postcode
        houseNumber
        additionalInformation
      }
      coverageStartDate
      currentlyLivingInGermany
      dateOfBirth
      email
      gender
      maritalStatus
      nationality
      passportExpiry
      passportNumber
      personalInfoReview
      phoneNumber
      residencePermit
      residencePermitStatus
      whatTypeOfResidencePermit
      relocationDate
      livedInGermanyForLast36Months
    }
    medicalHistory {
      heightInCm
      weightInKg
      hivPositive
      dentalCondition
      jawCondition
      hasMissingTeeth
      missingBodyPart
      hasMissingBodyPart
      hasTakenMedication
      maximumEyeDiopters
      undergoingTreatment
      lowerJawMissingTeeth
      upperJawMissingTeeth
      assistingVisionDevice
      sufferedFromCondition
      sufferingFromCondition
      unableToProduceChildren
      hasReceivedPsychotherapy
      missinBodyPartExplanation
      hadOperationBecauseOfVision
      missingTeethUpperOrLowerJaw
      admittedToHospitalPast10years
      planToUndergoMedicalTreatment
      howManyTeethAffectedByCondition
      hasBeenAtHospitalBecauseOfVision
      sufferingFromConditionExplanation
      answersCheck
      diagnosedTreatedOrGivenMedicalAdvice
      medicalHistoryReview
      insuranceLawDisclaimer
      dataProcessingDisclaimer
    }
    financialHistory {
      income
      employerName
      workContract
      schufaConsent
      employerAddress {
        city
        street
        country
        postcode
        houseNumber
        additionalInformation
      }
      employmentStatus
      hasGermanTaxId
      whatIsYourJobTitle
      financialHistoryReview
      hasWebsite
      futureIncome
      bankStatement
      incomeEstimate
      jobDescription
      incomeStatement
      hasLinkedInProfile
      linkedInProfileUrl
      selfEmploymentIntro
      workingAsFreelancerSince
      germanTaxId
      employmentStartDate
      incomeExpectations
      previousActivity
      previousActivityExplanation
      monthlyIncomeBeforeTaxWhenEmployed
      whatKindOfOtherEmployment
      employedOutsideGermany
      websiteUrl
    }
    insuranceHistory {
      # Legacy fields
      hasHealthInsurance
      insuranceHistoryReview
      hadConsistentHealthInsurance
      endOfLastHealthInsurance
      currentHealthInsurance
      hasBeenInsuredBetweenHealthInsurances

      # Updated fields
      startDate
      livingInGermany
      hadGermanHealthProvider
      insuranceHistoryV2Review
      mostRecentHealthInsurance
      endOfPreviousHealthInsurance
      hasBeenHealthInsuredAtAllTimes
    }
  }
`;
