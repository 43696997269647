import * as styles from './styles';

interface Props {
  title: string;
  count: number;
}

export const PoliciesTitle = ({ title, count }: Props) => {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.count}>{count}</div>
    </div>
  );
};
