/* eslint-disable react/no-danger */
import styles from './RichTextPreview.module.css';

export const RichTextPreview = ({
  dangerouslySetHtml,
}: {
  dangerouslySetHtml: string;
}) => (
  <div
    className={styles.container}
    dangerouslySetInnerHTML={{
      __html: dangerouslySetHtml,
    }}
  />
);
