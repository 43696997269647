import { useTranslation } from '@getpopsure/i18n-react';
import InformationCard, {
  InformationSection,
} from 'components/InformationCard';
import ChecklistInformation from 'components/InformationCard/ChecklistInformation';
import MultipleLineInformation from 'components/InformationCard/MultipleLineInformation';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';

interface HealthCardSectionProps {
  policy: PublicHealthPolicy;
}

const HealthCardSection = ({ policy }: HealthCardSectionProps) => {
  const { t } = useTranslation();
  const healthCardSection: InformationSection[] = [
    {
      title: t(
        'admin.policy.public.overview.healthcardchecklist.title',
        'Health card checklist'
      ),
      rows: [
        {
          id: 'address',
          title: t(
            'admin.policy.public.overview.healthcardchecklist.address.title',
            'Address'
          ),
          type: 'MULTIPLELINE',
          component: MultipleLineInformation,
          editable: false,
          multipleLinesData: [
            {
              label: t(
                'admin.policy.public.overview.healthcardchecklist.address.housenumber.label',
                'House number'
              ),
              data: policy.healthCard?.address?.houseNumber ?? '',
            },
            {
              label: t(
                'admin.policy.public.overview.healthcardchecklist.address.street.label',
                'Street'
              ),
              data: policy.healthCard?.address?.street ?? '',
            },
            {
              label: t(
                'admin.policy.public.overview.healthcardchecklist.address.city.label',
                'City'
              ),
              data: policy.healthCard?.address?.city ?? '',
            },
            {
              label: t(
                'admin.policy.public.overview.healthcardchecklist.address.additionalinformation.label',
                'Additional information'
              ),
              data: policy.healthCard?.address?.additionalInformation ?? '',
            },
            {
              label: t(
                'admin.policy.public.overview.healthcardchecklist.address.postcode.label',
                'Postal code'
              ),
              data: policy.healthCard?.address?.postcode ?? '',
            },
            {
              label: t(
                'admin.policy.public.overview.healthcardchecklist.address.country.label',
                'Country'
              ),
              data: policy.healthCard?.address?.country ?? '',
            },
          ],
        },
        {
          id: 'documents',
          title: t(
            'admin.policy.public.overview.healthcardchecklist.documents.title',
            'Documents'
          ),
          type: 'CHECKLIST',
          editable: false,
          component: ChecklistInformation,
          checklist: [
            {
              title: t(
                'admin.documents.type.healthcardphoto',
                'Health card photo'
              ),
              check: Boolean(policy.healthCard?.photoUrl),
            },
          ],
        },
      ],
    },
  ];

  return <InformationCard sections={healthCardSection} />;
};

export default HealthCardSection;
