import classNames from 'classnames';

export const ibanTitle = classNames(
  'text-sm',
  'text-gray-900',
  'font-bold',
  'mr-2'
);
export const ibanText = classNames('text-sm', 'text-gray-900');

export const ibanWrapper = classNames('mb-6');

export const additionalText = classNames('text-sm', 'text-gray-600');
