import { CheckBox } from 'components/CheckBox';

interface Props {
  title: string;
  description: string;
  numberOfTasks: number;
  checked: boolean;
  setChecked: (checked: boolean) => void;
}

export const StatusChangeIssueCard = ({
  title,
  description,
  numberOfTasks,
  checked,
  setChecked,
}: Props) => {
  return (
    <div className="flex h-[128px] items-start rounded-lg border-[1px] border-gray-300 p-[16px]">
      <CheckBox
        className="mt-[2px]"
        small
        checked={checked ?? false}
        setChecked={setChecked}
      />
      <div className="pl-[8px]">
        <h4 className="text-sm text-gray-900">{title}</h4>
        <p className="mt-[8px] h-[40px] overflow-hidden text-ellipsis text-sm text-gray-600">
          {description}
        </p>
        {numberOfTasks > 0 ? (
          <p className="mt-[8px] text-sm font-bold text-indigo-500">
            {numberOfTasks} open task(s)
          </p>
        ) : (
          <p className="mt-[8px] text-sm font-bold text-gray-600">
            No open tasks
          </p>
        )}
      </div>
    </div>
  );
};
