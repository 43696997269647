import { TFunction } from '@getpopsure/i18n-react';
import { DocumentSection } from 'components/DocumentsSection/DocumentsSection';

import { CompanyPensionPolicy } from '../models/CompanyPensionPolicy';

export const getDocumentSections =
  (t: TFunction) =>
  (data?: {
    hrCompanyPensionPolicy: CompanyPensionPolicy;
  }): DocumentSection[] => {
    const featherUploads: DocumentSection = {
      title: t(
        'admin.hr.companyPension.featherUploads.label',
        'Feather uploads'
      ),
      documents: [],
    };

    data?.hrCompanyPensionPolicy.hrVisiblePolicyDocuments?.forEach(
      (document) => {
        featherUploads.documents.push({
          id: document.id,
          url: document.url,
          fileName: document.name,
          resizedUrl: document.resizedUrl,
        });
      }
    );

    return [featherUploads];
  };
