import { ApolloError } from '@apollo/client';
import OverviewPage from 'components/OverviewPage';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import Table from 'components/TableRefactor/Table';
import { EmptyStateMessage } from 'components/TableRefactor/TableEmptyState';
import { HeaderConfig, RowConfig } from 'components/TableRefactor/types';
import { GET_COMPANIES } from 'graphql/admin/companies/queries';
import { useState } from 'react';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';
import { mapCompanyType } from 'shared/mapCompanyType';

import CreateCompanyButton from './components/CreateCompanyButton';

const NUMBER_OF_COMPANIES_PER_PAGE = 12;

export interface Company {
  id: string;
  name: string;
  sources: string[];
  createdAt: string;
  active: boolean;
  activeUsers: number;
  type: string;
}

const filterOptions = [
  {
    title: 'Any',
    label: 'Any',
    id: 'any',
  },
  {
    title: 'Provider',
    label: 'Provider',
    id: 'provider',
  },
  {
    title: 'HR',
    label: 'HR',
    id: 'hr',
  },
];

const CompaniesPage = () => {
  const [error, setError] = useState<ApolloError | undefined>(undefined);

  const tableFilter: FilterConfig = {
    filterType: 'TEXT',
    options: filterOptions,
    label: 'Company type',
    id: 'filterType',
  };

  const tableHeaders: HeaderConfig[] = [
    {
      id: 'name',
      label: 'Company name',
      width: 'w-none',
      minWidth: 'min-w-[152px]',
      sticky: true,
      threshold: 'left-0',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'sources',
      label: 'Sources',
      width: 'w-none',
      minWidth: 'min-w-[240px]',
      sticky: true,
      threshold: 'left-[152px]',
      border: 'right',
      component: ColumnHeader,
    },
    {
      id: 'type',
      label: 'Company type',
      width: 'w-[150px]',
      minWidth: 'min-w-[150px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'admin-users',
      label: 'Active admin users',
      width: 'w-[158px]',
      minWidth: 'min-w-[158px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'createdAt',
      label: 'Created on',
      width: 'w-[125px]',
      minWidth: 'min-w-[125px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'active',
      label: 'Active',
      width: 'w-[80px]]',
      minWidth: 'min-w-[80px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: ColumnHeader,
    },
  ];

  const rowConfig: RowConfig = {
    getResourceLink: (data: Company) => {
      return generatePath(paths.companies.internal.details.path, {
        id: data.id,
      });
    },
    cells: [
      {
        type: 'TEXT',
        textColor: 'dark',
        props: {
          getText: (data: Company) => data.name,
        },
      },
      {
        type: 'TEXT',
        textColor: 'light',
        props: {
          getText: (data: Company) => {
            return data.sources.reduce((previousValue, currentValue, index) => {
              if (index === 0) {
                return `${previousValue} ${currentValue}`;
              }
              return `${previousValue}, ${currentValue}`;
            }, '');
          },
        },
      },
      {
        type: 'TEXT',
        textColor: 'light',
        props: {
          getText: (data: Company) => mapCompanyType(data.type) ?? '',
        },
      },
      {
        type: 'TEXT',
        textColor: 'light',
        props: {
          getText: (data: Company) => `${data.activeUsers} users`,
        },
      },
      {
        type: 'DATE',
        textColor: 'light',
        props: {
          getDate: (data: Company) => data.createdAt,
        },
      },
      {
        type: 'CHECK',
        textColor: 'light',
        props: {
          getCheck: (data: Company) => ({
            check: data.active ? 'check' : 'cross',
          }),
        },
      },
    ],
  };

  const defaultEmptyStateMessage: EmptyStateMessage = {
    title: 'There are no companies yet.',
    description: 'Looks like no companies were created.',
  };

  return (
    <OverviewPage
      title="All Companies - Feather Admin Panel"
      current="admin-companies"
      error={error}
    >
      <Table
        title="Companies"
        query={GET_COMPANIES}
        itemsPerPage={NUMBER_OF_COMPANIES_PER_PAGE}
        searchPlaceholder="Search by company name or source"
        headers={tableHeaders}
        rowConfig={rowConfig}
        emptyStateLabel={defaultEmptyStateMessage}
        filterConfig={[tableFilter]}
        onError={setError}
        actionButton={<CreateCompanyButton />}
      />
    </OverviewPage>
  );
};

export default CompaniesPage;
