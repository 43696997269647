import { ApolloError } from '@apollo/client';

export const checkUnauthorizedErrors = (error: ApolloError): boolean => {
  // For unauthorized errors the server always returns an graphql error with code not authorized
  if (error.graphQLErrors !== null) {
    return error.graphQLErrors.some(
      (graphQLError) => graphQLError.extensions.code === 'USER_NOT_AUTHORIZED'
    );
  }
  return false;
};
