import classNames from 'classnames';

export const container = classNames(
  'grid',
  'grid-cols-12',
  'gap-x-[16px]',
  'animate-appear-in'
);

export const leftContainer = classNames('col-span-8');

export const rightContainer = classNames('col-span-2');
