import { gql } from '@apollo/client';

export const GET_QUESTIONNAIRE = gql`
  query GetQuestionnaireDetails($id: String!) {
    questionnaire(id: $id) {
      id
      questionnaireType
      createdAt
      answers
      userId
      externalId
      userPolicy {
        id
        insuranceType
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
