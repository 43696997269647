import {
  ExclamationCircleIcon,
  ExclamationIcon,
  XIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import { ReactNode } from 'react';

import * as styles from './styles';

type ErrorType = 'ERROR' | 'WARNING';

type FileErrorCardButton = {
  text: string;
  onClick(): void;
};

interface FileErrorCardProps {
  open: boolean;
  title: string;
  handleClose?: () => void;
  errorType: ErrorType;
  className?: string;
  description?: ReactNode;
  hideCloseButton?: boolean;
  showActionButtons?: boolean;
  primaryButton?: FileErrorCardButton;
  secondaryButton?: FileErrorCardButton;
}

const FileErrorCard = ({
  open,
  title,
  handleClose,
  errorType,
  className,
  description,
  hideCloseButton = false,
  showActionButtons = false,
  primaryButton,
  secondaryButton,
}: FileErrorCardProps) => {
  return (
    <div
      className={classNames(className, styles.errorCard, {
        hidden: !open,
        [styles.errorCardWarning]: errorType === 'WARNING',
        [styles.errorCardError]: errorType === 'ERROR',
      })}
    >
      <div className={styles.header}>
        <div className="flex flex-col">
          <div className={styles.titleWrapper}>
            {errorType === 'WARNING' && (
              <ExclamationCircleIcon
                className={classNames(
                  styles.exclamationIcon,
                  styles.exclamationIconWarning
                )}
              />
            )}
            {errorType === 'ERROR' && (
              <ExclamationIcon
                className={classNames(
                  styles.exclamationIcon,
                  styles.exclamationIconError
                )}
              />
            )}
            <h1
              className={classNames(styles.title, {
                [styles.titleWarning]: errorType === 'WARNING',
                [styles.titleError]: errorType === 'ERROR',
              })}
            >
              {title}
            </h1>
          </div>
          {description && (
            <p
              className={classNames(styles.description, {
                [styles.descriptionWarning]: errorType === 'WARNING',
                [styles.descriptionError]: errorType === 'ERROR',
              })}
            >
              {description}
            </p>
          )}
          {showActionButtons && (
            <div className={styles.actionButtonsSection}>
              <button
                type="button"
                className={classNames(styles.button, {
                  [styles.warningPrimaryButton]: errorType === 'WARNING',
                  [styles.errorPrimaryButton]: errorType === 'ERROR',
                })}
                onClick={primaryButton?.onClick}
              >
                {primaryButton?.text}
              </button>
              {!!secondaryButton && (
                <button
                  type="button"
                  className={classNames(styles.button, {
                    [styles.warningSecondaryButton]: errorType === 'WARNING',
                    [styles.errorSecondaryButton]: errorType === 'ERROR',
                  })}
                  onClick={secondaryButton?.onClick}
                >
                  {secondaryButton?.text}
                </button>
              )}
            </div>
          )}
        </div>
        {(!hideCloseButton || !handleClose) && (
          <button onClick={handleClose} type="button">
            <XIcon
              className={classNames(styles.closeIcon, {
                [styles.closeIconWarning]: errorType === 'WARNING',
                [styles.closeIconError]: errorType === 'ERROR',
              })}
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default FileErrorCard;
