import { ConfirmationModalProps } from 'components/InformationCard/EditableInformationCard';
import Modal from 'components/Modal';

import * as styles from './styles';

const ConfirmationModal = ({
  open,
  setOpen,
  handleConfirm,
  loading,
  newFormData,
}: ConfirmationModalProps) => {
  return (
    <Modal
      title="Save changes?"
      open={open}
      setOpen={setOpen}
      handleConfirm={handleConfirm}
      loading={loading}
    >
      <>
        <div className={styles.ibanWrapper}>
          <span className={styles.ibanTitle}>New IBAN </span>
          <span className={styles.ibanText}>{String(newFormData.iban)}</span>
        </div>
        <span className={styles.additionalText}>
          If there is a payment transaction linked to this claim in status{' '}
          <span className="font-bold">Skipped</span>, it will be moved to status{' '}
          <span className="font-bold">Ready</span>.
        </span>
      </>
    </Modal>
  );
};

export default ConfirmationModal;
