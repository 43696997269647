import { EditComponentDefaultProps } from 'components/InformationCardV2/models';
import Input from 'components/Input';

interface InformationCardTextProps extends EditComponentDefaultProps {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
}

export const InformationCardText = ({
  shouldDisplayEditField = false,
  value,
  onChange,
  placeholder,
  error,
}: InformationCardTextProps) => {
  if (shouldDisplayEditField) {
    return (
      <Input
        className="w-[264px] !px-[12px] !py-[9px]"
        type="text"
        value={value ?? ''}
        onChange={(e) => onChange?.(e.target.value)}
        color="gray"
        placeholder={placeholder ?? ''}
        error={!!error}
        errorMessage={error}
      />
    );
  }

  return <span className="text-sm text-gray-900">{value ?? ''}</span>;
};
