import classNames from 'classnames';

export const currentPageButton = classNames(
  'z-10',
  'bg-indigo-100',
  'border-indigo-300',
  'text-indigo-500',
  'relative',
  'items-center',
  'px-4',
  'py-2',
  'border',
  'rounded-[2px]',
  'text-sm',
  'mr-px'
);

export const hidden = classNames('hidden');

export const flex = classNames('inline-flex');

export const pageButton = classNames(
  'bg-white',
  'text-gray-600',
  'hover:bg-gray-50',
  'relative',
  'items-center',
  'px-4',
  'py-2',
  'text-sm',
  'mr-px',
  'rounded-[2px]',
  'hover:bg-indigo-50',
  'hover:text-indigo-500',
  'focus:bg-indigo-50',
  'focus:text-indigo-500',
  'focus:outline-indigo'
);
