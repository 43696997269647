import { useMutation, useReactiveVar } from '@apollo/client';
import { ArrowRightIcon, ExternalLinkIcon } from '@heroicons/react/outline';
import Modal from 'components/Modal';
import { UPDATE_PROVIDER_POLICY_NUMBER_BATCHED } from 'pages/scannedDocuments/scannedDocuments.mutations';
import { GET_POLICIES_BY_POLICY_NUMBERS_FOR_LIST } from 'pages/scannedDocuments/scannedDocuments.queries';
import {
  BasePolicy,
  ScannedDocument,
} from 'pages/scannedDocuments/scannedDocuments.types';
import { getPolicyDetailsBaseURLFromInsuranceType } from 'pages/scannedDocuments/scannedDocuments.utils';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import * as styles from './ScannedDocuments.styles';

type SaveProviderPolicyNumberBulkActionProps = {
  selectedDocuments: ScannedDocument[];
  selectedPolicies: BasePolicy[];
  modalIsShown: boolean;
  closeModal: () => void;
};

export const SaveProviderPolicyNumberBulkAction = ({
  selectedDocuments,
  selectedPolicies,
  modalIsShown,
  closeModal,
}: SaveProviderPolicyNumberBulkActionProps) => {
  const alertBannersState = useReactiveVar(alertBanners);

  const onSaveSuccess = () => {
    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'SUCCESS',
      message: 'Policies successfully updated.',
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    closeModal();
  };

  const onSaveError = () => {
    const newAlertBanner: AlertBannerState = {
      id: uuidv4(),
      type: 'WARNING',
      message: 'Something went wrong. Please try again.',
    };

    setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    closeModal();
  };

  const documentAndPolicyPairs = selectedDocuments.flatMap(
    (scannedDocument) => {
      const matchingPolicy = selectedPolicies?.find(
        (policy) => policy.policyNumber === scannedDocument.policyNumber
      );
      if (matchingPolicy) {
        return {
          scannedDocument,
          policy: matchingPolicy,
        };
      }
      return [];
    }
  );

  const updateDataArray = documentAndPolicyPairs.flatMap(
    ({ policy, scannedDocument }) => {
      const { providerPolicyNumber: documentPPN } = scannedDocument;
      const { providerPolicyNumber: policyPPN, id: policyId } = policy;

      if (documentPPN && documentPPN !== policyPPN) {
        return [
          {
            id: policyId,
            providerPolicyNumber: documentPPN,
          },
        ];
      }
      return [];
    }
  );

  const [saveData, { loading }] = useMutation(
    UPDATE_PROVIDER_POLICY_NUMBER_BATCHED,
    {
      variables: {
        updateDataArray,
      },
      refetchQueries: [GET_POLICIES_BY_POLICY_NUMBERS_FOR_LIST],
      notifyOnNetworkStatusChange: true,
      onCompleted: onSaveSuccess,
      onError: onSaveError,
    }
  );

  const handleConfirm = async () => {
    await saveData();
  };

  return (
    <>
      {modalIsShown && (
        <Modal
          open={modalIsShown}
          setOpen={closeModal}
          title="Save provider policy number(s)?"
          confirmButtonLabel="Save"
          handleConfirm={handleConfirm}
          loading={loading}
        >
          <div className="text-sm text-gray-600">
            <div className={styles.tableWrapper}>
              <div className={styles.tableContent}>
                <table className={styles.table}>
                  <thead>
                    <tr className={styles.tableHeader}>
                      <td className="p-3" />
                      <td className="p-3">
                        <div className="flex items-center gap-2">
                          <span className="font-bold">Before</span>
                        </div>
                      </td>
                      <td className="p-3" />
                      <td className="p-3">
                        <div className="flex items-center gap-2">
                          <span className="font-bold">After</span>
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {documentAndPolicyPairs.map(
                      ({ scannedDocument, policy }) => {
                        const {
                          providerPolicyNumber: documentPPN,
                          policyNumber,
                        } = scannedDocument;
                        const { providerPolicyNumber: policyPPN } = policy;

                        const documentLink = generatePath(
                          paths.scannedDocuments.details.path,
                          {
                            id: scannedDocument.id,
                          }
                        );

                        const policyLink =
                          getPolicyDetailsBaseURLFromInsuranceType(
                            policy.insuranceType,
                            policy.id
                          );

                        const isNewPPN = documentPPN && !policyPPN;
                        const isChangedPPN =
                          documentPPN && policyPPN && documentPPN !== policyPPN;

                        let updateTextStyle = styles.textUnchanged;

                        if (isNewPPN) {
                          updateTextStyle = styles.textNew;
                        } else if (isChangedPPN) {
                          updateTextStyle = styles.textUpdated;
                        }

                        return (
                          <tr key={scannedDocument.id}>
                            <td className={styles.tableCell}>
                              <a
                                className={styles.link}
                                href={policyLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Policy
                                <div className={styles.icon}>
                                  <ExternalLinkIcon />
                                </div>
                              </a>
                              <a
                                className={styles.link}
                                href={documentLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Document
                                <div className={styles.icon}>
                                  <ExternalLinkIcon />
                                </div>
                              </a>
                            </td>
                            <td className={styles.tableCell}>
                              <p>{policyPPN || '-'}</p>
                              <p>{policyNumber}</p>
                            </td>
                            <td className={styles.tableCell}>
                              <div className={styles.icon}>
                                <ArrowRightIcon />
                              </div>
                            </td>
                            <td className={styles.tableCell}>
                              <p className={updateTextStyle}>
                                {documentPPN || '-'}
                              </p>
                              <p>{policyNumber}</p>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
