import { useMutation, useReactiveVar } from '@apollo/client';
import { Trans, useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { DELETE_TASK_TEMPLATE } from 'pages/admin/taskTemplates/graphql/mutations';
import { GET_TASK_TEMPLATES } from 'pages/admin/taskTemplates/graphql/queries';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import { TaskTemplate } from '../taskTemplate.types';

interface DeleteConfirmationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  taskTemplate: TaskTemplate;
}

const DeleteConfirmationModal = ({
  open,
  setOpen,
  taskTemplate,
}: DeleteConfirmationModalProps) => {
  const { t } = useTranslation();

  const alertBannersState = useReactiveVar(alertBanners);

  const [deleteTaskTemplate, { loading }] = useMutation(DELETE_TASK_TEMPLATE, {
    refetchQueries: [GET_TASK_TEMPLATES],
    onCompleted: () => {
      setOpen(false);

      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message: t(
          'taskTemplates.deleteTaskTemplate.alert.success',
          'Task template deleted.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
    onError: () => {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: t(
          'taskTemplates.deleteTaskTemplate.alert.error',
          'Something went wrong. Please try again.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
  });

  const handleSubmit = async () => {
    await deleteTaskTemplate({
      variables: { id: taskTemplate.id },
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={t(
        'taskTemplates.deleteTaskTemplate.modal.title',
        'Delete template'
      )}
      loading={loading}
      textLoading="Deleting template..."
      hideActions
    >
      <div>
        <div className={classNames('text-gray-600', 'text-sm')}>
          <Trans
            key="taskTemplates.deleteTaskTemplates.delete.confirm"
            templateName={taskTemplate?.name}
          >
            Do you really want to delete this template? "
            {{ templateName: taskTemplate?.name }}"
            <p className={classNames('pt-1')}>
              This action <b>cannot be undone.</b>
            </p>
          </Trans>
        </div>
        <div className={classNames('flex', 'justify-end', 'mt-[24px]')}>
          <Button
            type="button"
            buttonType="secondary"
            onClick={() => setOpen(false)}
          >
            {t('admin.modal.cancel.action', 'Cancel')}
          </Button>
          <Button
            loading={loading}
            type="submit"
            buttonType="primary"
            className={classNames('ml-2', 'min-w-[140px]')}
            onClick={handleSubmit}
          >
            {t('taskTemplates.deleteTaskTemplate.delete.action', 'Delete')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;
