import { TextInformationProps } from '../TextInformation';

const ButtonInformation = ({ cell, onClick }: TextInformationProps) => {
  return (
    <button
      type="button"
      className="text-sm font-bold text-indigo-500 hover:text-indigo-600"
      onClick={onClick}
    >
      {String(cell)}
    </button>
  );
};

export default ButtonInformation;
