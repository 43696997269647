import { useTranslation } from '@getpopsure/i18n-react';
import Modal from 'components/Modal';

interface DeclineCommissionModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleConfirm: (status: 'ACCEPTED' | 'DECLINED') => void;
  loading: boolean;
}

const DeclineCommissionModal = ({
  open,
  setOpen,
  handleConfirm,
  loading,
}: DeclineCommissionModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t(
        'admin.provider.policy.public.commissioncheck.modal.decline.title',
        'Decline commission?'
      )}
      open={open}
      setOpen={setOpen}
      handleConfirm={() => handleConfirm('DECLINED')}
      confirmButtonLabel={t(
        'admin.provider.policy.public.commissioncheck.modal.decline.action.label',
        'Decline'
      )}
      textLoading={t(
        'admin.provider.policy.public.commissioncheck.modal.decline.loading',
        'Declining...'
      )}
      loading={loading}
    >
      <span className="text-sm text-gray-600">
        {t(
          'admin.provider.policy.public.commissioncheck.modal.decline.action.description',
          'After declining the commission entitlement, no commission will be paid out for this policy.'
        )}
      </span>
    </Modal>
  );
};

export default DeclineCommissionModal;
