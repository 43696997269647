import { gql } from '@apollo/client';

export const GET_REFERRAL_CODES = gql`
  query getAllReferralCodes(
    $searchString: String
    $offset: Int
    $limit: Int
    $sortColumn: String
    $sortOrder: String
    $filterStatus: String
  ) {
    getAllReferralCodes(
      searchString: $searchString
      offset: $offset
      limit: $limit
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterStatus: $filterStatus
    ) {
      id
      referralCode
      isDiscountCode
      status
      createdAt
    }
    getReferralCodesCount(
      searchString: $searchString
      filterStatus: $filterStatus
    )
  }
`;
