import { SelectButtonOption } from 'components/SelectButton';
import { StatusUpdateFormQuestion } from 'components/StatusUpdateModal/models';
import { StatusData } from 'pages/claims/template/models';
import {
  PrivateHealthClaimStatus,
  privateHealthClaimStatuses,
} from 'shared/claims/privateHealth/models';
import { policyMappers } from 'shared/insurances';

export const privateHealthClaimStatusOptions: SelectButtonOption<PrivateHealthClaimStatus>[] =
  privateHealthClaimStatuses.map((statusId) => ({
    id: statusId,
    label:
      policyMappers.PRIVATE_HEALTH?.claim?.claimStatusMapper[statusId] ?? '',
    title:
      policyMappers.PRIVATE_HEALTH?.claim?.claimStatusMapper[statusId] ?? '',
    color: policyMappers.PRIVATE_HEALTH?.claim?.badgeColorMapper[statusId],
  }));

export const retrieveFormAndFooterTextByStatus = (
  status: PrivateHealthClaimStatus
): StatusData => ({
  form: formMapper[status],
  footerText: footerTextMapper(status),
});

const uploadForm: StatusUpdateFormQuestion = {
  id: 'uploadDocument',
  label: 'Upload document',
  data: {
    type: 'UPLOAD',
    fileName: { placeholder: 'Enter document friendly name', id: 'fileName' },
  },
};

const formMapper: Record<PrivateHealthClaimStatus, StatusUpdateFormQuestion[]> =
  {
    SUBMITTED_TO_PROVIDER: [],
    RECEIVED: [uploadForm],
    DUPLICATE: [uploadForm],
    DROPPED_OUT: [uploadForm],
    INCOMPLETE: [uploadForm],
    CLOSED: [uploadForm],
    MISSING_INFO_RECEIVED: [uploadForm],
    DENIED: [uploadForm],
  };

const footerTextMapper = (
  status: PrivateHealthClaimStatus
): string | undefined => {
  if (status !== 'SUBMITTED_TO_PROVIDER') {
    return 'Once confirmed, claim status will be updated and documents will be added to the claim.';
  }
};
