import { SelectButtonOption } from 'components/SelectButton';
import { StatusUpdateFormQuestion } from 'components/StatusUpdateModal/models';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';
import { StatusData } from 'pages/claims/template/models';
import {
  DogLiabilityClaimStatus,
  dogLiabilityClaimStatuses,
} from 'shared/claims/dogLiability/models';
import { policyMappers } from 'shared/insurances';
import { InsuranceType } from 'shared/insurances/types';

export const claimStatusOptions = (
  insuranceType: InsuranceType
): SelectButtonOption<DogLiabilityClaimStatus>[] => {
  const statusMapper = policyMappers[insuranceType]?.claim?.claimStatusMapper;
  const badgeColorMapper =
    policyMappers[insuranceType]?.claim?.badgeColorMapper;

  return dogLiabilityClaimStatuses.map((statusId) => ({
    id: statusId,
    label: statusMapper?.[statusId] ?? '',
    title: statusMapper?.[statusId] ?? '',
    color: badgeColorMapper?.[statusId] ?? 'gray',
  }));
};

export const retrieveFormAndFooterTextByStatus = (
  status: DogLiabilityClaimStatus
): StatusData => ({
  form: formMapper[status],
  footerText: footerTextMapper(status),
});

const uploadForm: StatusUpdateFormQuestion = {
  id: 'uploadDocument',
  label: 'Upload document',
  data: {
    type: 'UPLOAD',
    fileName: {
      placeholder: 'Enter document friendly name',
      id: 'fileName',
    },
  },
};

const payoutAmountForm: StatusUpdateFormQuestion = {
  id: 'payoutAmount',
  label: 'Payout amount',
  data: { type: 'CURRENCY', placeholder: '0.00' },
  required: true,
};

const customerPaidOnForm: StatusUpdateFormQuestion = {
  id: 'customerPaidOn',
  label: 'Customer paid on',
  data: {
    type: 'DATE',
    defaultValue: dayjs().format(DATE_FORMAT),
    max: dayjs().format(DATE_FORMAT),
  },
  required: true,
};

const thirdPartyPaidOnForm: StatusUpdateFormQuestion = {
  id: 'thirdPartyPaidOn',
  label: 'Third party paid on',
  data: {
    type: 'DATE',
    defaultValue: dayjs().format(DATE_FORMAT),
    max: dayjs().format(DATE_FORMAT),
  },
  required: true,
};

const formMapper: Record<DogLiabilityClaimStatus, StatusUpdateFormQuestion[]> =
  {
    DENIED: [uploadForm],
    MISSING_INFO_RECEIVED: [uploadForm],
    CUSTOMER_PAID_OUT: [payoutAmountForm, customerPaidOnForm, uploadForm],
    DUPLICATE: [uploadForm],
    OTHER_PAID_OUT: [payoutAmountForm, thirdPartyPaidOnForm, uploadForm],
    RECEIVED: [uploadForm],
    INCOMPLETE: [uploadForm],
    DROPPED_OUT: [uploadForm],
    IN_REVIEW: [],
    ON_HOLD: [uploadForm],
    CLOSED: [uploadForm],
  };

const footerTextMapper = (
  status: DogLiabilityClaimStatus
): string | undefined => {
  if (status !== 'IN_REVIEW') {
    return 'Once confirmed, claim status will be updated and documents will be added to the claim.';
  }
};
