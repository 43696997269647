import { useQuery } from '@apollo/client';
import { ShieldCheckIcon } from '@heroicons/react/solid';
import { DetailsPage } from 'components/DetailsPage';
import { InternalLink, TabComponent } from 'components/DetailsPage/models';
import { TabInformation } from 'components/Tabs';
import { TkApiRequest } from 'models/tkApiRequest';
import { useParams } from 'react-router';
import { policyRouteMapper } from 'shared/insurances/policyRouteMapper';

import OverviewTab from '../components/OverviewTab';
import { GET_TK_API_REQUEST } from '../graphql/request';

const TkApiRequestDetailsPage = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useQuery<{ tkApiRequest: TkApiRequest }>(
    GET_TK_API_REQUEST,
    {
      variables: {
        id,
      },
    }
  );

  const internalLinks: InternalLink[] = [
    ...(data?.tkApiRequest.userPolicy
      ? [
          {
            title: 'Public health policy',
            icon: ShieldCheckIcon,
            href: policyRouteMapper.PUBLIC_HEALTH(
              data.tkApiRequest.userPolicy.id
            ),
          },
        ]
      : []),
  ];

  const tabs: TabInformation[] = [
    {
      name: 'Overview',
      href: '#overview',
      withDot: false,
    },
  ];

  const tabComponents: TabComponent[] = [
    {
      id: 'overview',
      component: OverviewTab,
      props: {
        request: data?.tkApiRequest,
      },
    },
  ];

  return (
    <DetailsPage
      windowTitle="TK API Request - Feather Admin Panel"
      pageTitle="TK API Request"
      current="tkApiRequests"
      backLink={{
        text: 'Back',
      }}
      tabs={tabs}
      tabComponents={tabComponents}
      loading={loading}
      internalLinks={internalLinks}
      error={error}
    />
  );
};

export default TkApiRequestDetailsPage;
