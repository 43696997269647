import { gql } from '@apollo/client';

export const UPDATE_EXPAT_CLAIM_STATUS = gql`
  mutation UpdateExpatClaimStatus(
    $claimId: String!
    $email: String!
    $policyId: String!
    $userId: String!
    $status: String!
    $createdOn: String!
    $uploadDocument: Upload
    $fileName: String
    $payoutAmount: Float
    $featherPaidOn: String
    $customerPaidOn: String
    $reason: String
  ) {
    updateExpatClaimStatus(
      claimId: $claimId
      email: $email
      policyId: $policyId
      userId: $userId
      status: $status
      createdOn: $createdOn
      uploadDocument: $uploadDocument
      fileName: $fileName
      payoutAmount: $payoutAmount
      featherPaidOn: $featherPaidOn
      customerPaidOn: $customerPaidOn
      reason: $reason
    ) {
      id
      status
    }
  }
`;
