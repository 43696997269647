import { Trans, useTranslation } from '@getpopsure/i18n-react';
import Header from 'components/Header';
import { Helmet } from 'react-helmet';

import * as styles from './styles';

const PageGenericError = () => {
  const { t } = useTranslation();
  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <>
      <Helmet>
        <title>Error - Feather Admin Panel</title>
      </Helmet>
      <div>
        <Header />
        <div className={styles.contentWrapper}>
          <img
            src="/static/error-states/error.svg"
            alt="feathers"
            className={styles.feathers}
          />
          <h1 className={styles.title}>
            {t(
              'admin.error.somethingwentwrong.title',
              'Something went wrong...'
            )}
          </h1>
          <Trans i18nKey="admin.error.somethingwentwrong.description">
            <span className={styles.text}>
              Sorry, something didn't work as it should.
            </span>
            <span className={styles.text}>
              Starting over should address this issue.
            </span>
          </Trans>
          <button className={styles.button} onClick={refreshPage}>
            {t('admin.error.refreshpage.action', 'Refresh page')}
          </button>
        </div>
      </div>
    </>
  );
};

export default PageGenericError;
