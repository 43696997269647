import { useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import Badge from 'components/Badge';
import Modal from 'components/Modal';
import RadioButton from 'components/RadioButton';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import TextArea from 'components/TextArea';
import { Issue } from 'models/issue';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  getInternalPublicHealthStatusColor,
  InternalPublicHealthStatus,
} from 'shared/mapInternalPublicHealthStatus';
import { ProviderPublicHealthStatus } from 'shared/mapProviderPublicHealthStatus';

import UnresolvedIssues from '../UnresolvedIssues';
import * as styles from './styles';

interface ChangeStatusWithOptionalIssueProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  updateStatus: (
    variables: {
      status: string;
      category?: string;
      description?: string;
      isCustomerInformed?: boolean;
      issueIds?: string[];
    },
    formId: string
  ) => void;
  newStatus: InternalPublicHealthStatus | ProviderPublicHealthStatus | null;
  loading: boolean;
  title?: string;
  descriptionText?: string;
  confirmText?: string;
  providerView?: boolean;
  openIssues?: Issue[];
}

const ChangeStatusWithOptionalIssue = ({
  open,
  setOpen,
  updateStatus,
  newStatus,
  loading,
  title,
  descriptionText,
  confirmText,
  providerView = false,
  openIssues,
}: ChangeStatusWithOptionalIssueProps) => {
  const { t } = useTranslation();

  const issueTypeOptions: SelectMenuOption[] = [
    {
      id: 'missinginfo',
      label: t('admin.issue.type.missinginfo', 'Missing info'),
    },
    {
      id: 'canceled',
      label: t('admin.issue.type.canceled', 'Canceled'),
    },
    {
      id: 'noteligible',
      label: t('admin.issue.type.noteligible', 'Not eligible'),
    },
    {
      id: 'unicheck',
      label: t('admin.issue.type.unicheck', 'Uni check'),
    },
    {
      id: 'other',
      label: t('admin.issue.type.other.label', 'Other'),
    },
  ];

  const [description, setDescription] = useState('');
  const [issueType, setIssueType] = useState<SelectMenuOption | undefined>(
    undefined
  );
  const [createIssue, setCreateIssue] = useState(false);

  const [descriptionError, setDescriptionError] = useState(false);
  const [typeError, setTypeError] = useState(false);

  useEffect(() => {
    if (description !== '') {
      setDescriptionError(false);
    }
  }, [description]);

  useEffect(() => {
    if (issueType) {
      setTypeError(false);
    }
  }, [issueType]);

  const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const handleCreateIssueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCreateIssue(e.target.value === 'yes');
  };

  const onSubmit = () => {
    if (createIssue && !description) {
      setDescriptionError(true);
    }
    if (createIssue && !issueType) {
      setTypeError(true);
    }

    if ((createIssue && description && issueType) || !createIssue) {
      let issueIds;
      if (showIssuesSection) {
        issueIds = openIssues.map((issue) => issue.id);
      }
      updateStatus(
        {
          status: newStatus?.id ?? '',
          category: issueType?.id,
          description,
          issueIds,
        },
        'formWithOptionalIssue'
      );
    }
  };

  const newStatusColor = getInternalPublicHealthStatusColor(
    newStatus as InternalPublicHealthStatus
  );

  const showIssuesSection = openIssues && openIssues?.length !== 0;

  return (
    <Modal
      title={
        title ??
        t(
          'admin.provider.policy.public.modal.changestatus.title',
          'Change status'
        )
      }
      open={open}
      setOpen={setOpen}
      handleConfirm={onSubmit}
      loading={loading}
      textLoading={t(
        'admin.provider.policy.public.modal.changestatus.confirming.action',
        'Confirming...'
      )}
      confirmButtonLabel={confirmText}
    >
      <>
        {descriptionText && <p className={styles.text}>{descriptionText}</p>}
        <div className={styles.newStatusSection}>
          <h1 className={styles.sectionTitle}>
            {t(
              'admin.provider.policy.public.modal.changestatus.newstatus.title',
              'New status'
            )}
          </h1>
          <Badge color={newStatusColor} badgeType="full">
            {newStatus?.text}
          </Badge>
        </div>
        <div className={styles.divider} />
        <div className={styles.section}>
          <h1 className={styles.sectionTitle}>
            {t(
              'admin.provider.policy.public.modal.changestatus.newissue.title',
              'Do you want to create an issue?'
            )}
          </h1>
          <div className={styles.radioButtons}>
            <RadioButton
              id="isFeatherYes"
              name="uploadDocument"
              value="yes"
              checked={createIssue}
              onChange={handleCreateIssueChange}
            >
              {t(
                'admin.provider.policy.public.modal.changestatus.newissue.yes',
                'Yes'
              )}
            </RadioButton>
            <RadioButton
              id="isFeatherNo"
              name="uploadDocument"
              value="no"
              checked={!createIssue}
              onChange={handleCreateIssueChange}
            >
              {t(
                'admin.provider.policy.public.modal.changestatus.newissue.no',
                'No'
              )}
            </RadioButton>
          </div>
        </div>
        <div
          className={classNames(styles.createIssueSectionWrapper, {
            'max-h-[0px]': !createIssue,
            'max-h-[300px]': createIssue,
            'overflow-visible': createIssue,
            'overflow-hidden': !createIssue,
          })}
        >
          <div className={styles.section}>
            <SelectMenu
              selected={issueType}
              setSelected={setIssueType}
              placeholder={t(
                'admin.activity.newissue.modal.type.placeholder',
                'Select issue type'
              )}
              options={issueTypeOptions}
              label={t(
                'admin.activity.newissue.modal.type.header',
                'Issue type'
              )}
              color="gray"
              error={typeError}
              errorMessage={t(
                'admin.activity.newissue.modal.type.error',
                'An issue type is required.'
              )}
            />
          </div>
          <div className={styles.section}>
            <h1 className={styles.sectionTitle}>
              {t(
                'admin.activity.newissue.modal.description.header',
                'Issue description'
              )}
            </h1>
            <TextArea
              color="gray"
              placeholder={t(
                'admin.activity.newissue.modal.description.placeholder',
                'Start typing here...'
              )}
              required
              value={description}
              onChange={handleDescriptionChange}
              error={descriptionError}
              errorMessage={t(
                'admin.activity.newissue.modal.description.error',
                'A description is required.'
              )}
            />
          </div>
          <div className={styles.section}>
            <p className={styles.text}>
              {providerView
                ? t(
                    'admin.provider.policy.public.modal.changestatus.message',
                    'A new issue will be created and Feather will be notified.'
                  )
                : 'A new issue will be created and the provider will be notified.'}
            </p>
          </div>
        </div>

        {showIssuesSection && (
          <div className={styles.section}>
            <h1 className={styles.sectionTitle}>
              {t(
                'admin.provider.policy.public.modal.changestatus.issues.title',
                'These issues will be resolved automatically'
              )}
            </h1>
            <UnresolvedIssues issues={openIssues} setOpenModal={setOpen} />
          </div>
        )}
      </>
    </Modal>
  );
};

export default ChangeStatusWithOptionalIssue;
