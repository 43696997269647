import { GET_EXPAT_SPAIN_POLICIES } from 'pages/policies/expatSpain/graphql/policies';
import {
  ExpatSpainClaimStatus,
  ExpatSpainClaimType,
} from 'shared/claims/expatSpain/models';

import { ClaimsTemplateData } from '../template/models';
import {
  retrieveExpatSpainClaimInfoOldConfirmationValue,
  retrieveExpatSpainClaimInfoRows,
} from './modules/claimsDetails/claimInfo/data';
import ConfirmationModal from './modules/claimsDetails/components/ConfirmationModal';
import { UPDATE_EXPAT_SPAIN_CLAIM } from './modules/claimsDetails/graphql/mutations';
import { GET_EXPAT_SPAIN_HEALTH_CLAIM } from './modules/claimsDetails/graphql/queries';
import { retrieveExpatSpainPolicyInfoRows } from './modules/claimsDetails/policyInfo/data';
import { retrieveFormData } from './modules/createClaim/data';
import { CREATE_EXPAT_SPAIN_CLAIM } from './modules/createClaim/graphql/mutations';
import { ZSubmittableExpatSpainClaimSchema } from './modules/createClaim/models';
import { filters } from './modules/overview/data/filters';
import { rowConfig } from './modules/overview/data/tableData';
import { tableHeaders } from './modules/overview/data/tableHeaders';
import { GET_EXPAT_SPAIN_HEALTH_CLAIMS } from './modules/overview/graphql/queries';
import {
  dataByStatus,
  expatSpainClaimStatusOptions,
} from './modules/statusUpdate/data';
import { UPDATE_EXPAT_SPAIN_CLAIM_STATUS } from './modules/statusUpdate/graphql/mutations';

export const expatSpainHealthClaimsData: ClaimsTemplateData<
  ExpatSpainClaimStatus,
  ExpatSpainClaimType
> = {
  insuranceType: 'INCOMING_ES',

  // GraphQL ----------------------------------------
  queries: {
    fetchAllClaims: GET_EXPAT_SPAIN_HEALTH_CLAIMS,
    fetchSingleClaim: GET_EXPAT_SPAIN_HEALTH_CLAIM,
    fetchAllPolicies: GET_EXPAT_SPAIN_POLICIES,
  },
  mutations: {
    createClaim: CREATE_EXPAT_SPAIN_CLAIM,
    updateClaim: UPDATE_EXPAT_SPAIN_CLAIM,
    updateClaimStatus: UPDATE_EXPAT_SPAIN_CLAIM_STATUS,
  },

  // Features ----------------------------------------
  overview: {
    headers: tableHeaders,
    filterConfig: filters,
    rowConfig,
  },
  claimCreation: {
    createClaimSubmissionSchema: ZSubmittableExpatSpainClaimSchema,
    retrieveFormData,
  },
  policyInfo: { retrievePolicyInfoRows: retrieveExpatSpainPolicyInfoRows },
  claimInfo: {
    retrieveClaimInfoRows: retrieveExpatSpainClaimInfoRows,
    retrieveClaimInfoOldConfirmationValue:
      retrieveExpatSpainClaimInfoOldConfirmationValue,
    ClaimInfoConfirmationModal: ConfirmationModal,
  },
  statusUpdate: {
    dataByStatus,
    statusOptions: expatSpainClaimStatusOptions,
  },
};
