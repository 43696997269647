import { useTranslation } from '@getpopsure/i18n-react';
import Modal from 'components/Modal';
import TextArea from 'components/TextArea';
import { ChangeEvent, useState } from 'react';

import * as styles from './styles';

interface UnResolveIssueFormProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  updateStatus: (
    variables: {
      newStatus: string;
      comment: string;
      newFilename?: string;
      type?: string;
      file?: File;
    },
    formId: string
  ) => void;
  loading: boolean;
}

const UnResolveIssueForm = ({
  open,
  setOpen,
  updateStatus,
  loading,
}: UnResolveIssueFormProps) => {
  const { t } = useTranslation();

  const [comment, setComment] = useState('');

  const handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const onSubmit = () => {
    updateStatus(
      {
        newStatus: 'open',
        comment,
      },
      'unResolveForm'
    );
  };

  return (
    <Modal
      title={t('admin.activity.unresolveissue.modal.header', 'Unresolve issue')}
      open={open}
      setOpen={setOpen}
      handleConfirm={onSubmit}
      confirmButtonLabel={t(
        'admin.activity.unresolveissue.modal.unresolve.label',
        'Unresolve issue'
      )}
      loading={loading}
      textLoading={t(
        'admin.activity.unresolveissue.modal.loading.label',
        'Unresolving issue...'
      )}
    >
      <div className={styles.section}>
        <h1 className={styles.sectionTitle}>
          {t('admin.activity.unresolveissue.modal.comment.header', 'Comment')}
        </h1>
        <p className={styles.text}>
          {t(
            'admin.activity.unresolveissue.modal.comment.secondarytext',
            'Please explain why this issue is unresolved.'
          )}
        </p>
        <TextArea
          color="gray"
          placeholder={t(
            'admin.activity.unresolveissue.modal.comment.placeholder',
            'Start typing here...'
          )}
          required
          value={comment}
          onChange={handleCommentChange}
        />
      </div>
    </Modal>
  );
};

export default UnResolveIssueForm;
