import { useLazyQuery, useQuery } from '@apollo/client';
import {
  CodeIcon,
  EyeIcon,
  MailIcon,
  ShieldCheckIcon,
  UserIcon,
} from '@heroicons/react/solid';
import { ButtonType } from 'components/Button/Button';
import ChangeStatus from 'components/ChangeStatus';
import { DetailsPage } from 'components/DetailsPage';
import { InternalLink } from 'components/DetailsPage/models';
import LoadingState from 'components/LoadingState';
import NavBar from 'components/NavBar';
import { SelectButtonOption } from 'components/SelectButton';
import { TabInformation } from 'components/Tabs';
import { PrivateHealthPolicy } from 'models/privateHealthPolicy';
import Page403 from 'pages/errors/403';
import PageGenericError from 'pages/errors/genericError';
import {
  GET_CLAIMS_BY_SIMILAR_POLICY_NUMBER,
  GET_DENTAL_POLICY_BY_NAME,
  GET_PRIVATE_POLICY_BY_NAME,
  GET_SCANNED_DOCUMENT_BY_ID,
} from 'pages/scannedDocuments/scannedDocuments.queries';
import { useEffect } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import { paths } from 'routes/definedPaths';
import { checkUnauthenticatedErrors } from 'shared/errorHandling/unauthenticatedError';
import { checkUnauthorizedErrors } from 'shared/errorHandling/unauthorizedErrors';
import { insuranceNameMapper } from 'shared/insurances/insuranceNameMapper';

import { statusMapping } from '../scannedDocuments.mappings';
import { UPDATE_SCANNED_DOCUMENT } from '../scannedDocuments.mutations';
import {
  BasePolicy,
  RelatedClaimsData,
  SCANNED_DOCUMENTS_STATUSES,
  ScannedDocument,
  ScannedDocumentSource,
  ScannedDocumentStatus,
} from '../scannedDocuments.types';
import { getPolicyDetailsBaseURLFromInsuranceType } from '../scannedDocuments.utils';
import { ActivateDentalPolicy } from './OverviewTab/ActivateDentalPolicy/ScannedDocument.ActivateDentalPolicy';
import { ActivatePrivatePolicy } from './OverviewTab/ActivatePrivatePolicy/ScannedDocument.ActivatePrivatePolicy';
import { OverviewTab } from './OverviewTab/ScannedDocument.OverviewTab';
import { ShowInAccount } from './ShowInAccount/ScannedDocument.ShowInAccount';

type ScannedDocumentDetailsPageParams = {
  id?: string;
};

type GetScannedDocumentData = {
  scannedDocument: ScannedDocument;
};

type GetDentalPolicyByNameData = {
  dentalPolicyByName: BasePolicy;
};

type GetPrivatePolicyByNameData = {
  privatePolicyByName: PrivateHealthPolicy;
};

const sourceToIconMapping: Record<ScannedDocumentSource, JSX.Element> = {
  email: <MailIcon />,
  n8n: <CodeIcon />,
};

const dentalCoCDocumentNameRegex = /Name (?<name>[\w\W\s]*)\nPolicy/g;
const privateCoCDocumentNameRegex = /-333\s(?<name>[\w\s]*)\sservice/g;

const getDentalNameFromDocument = (textContent?: string) => {
  const nameMatch = dentalCoCDocumentNameRegex.exec(textContent ?? '');
  if (nameMatch && nameMatch.groups?.name) {
    return nameMatch.groups?.name.trim();
  }
  return null;
};

const getPrivateNameFromDocument = (textContent?: string) => {
  const nameMatch = privateCoCDocumentNameRegex.exec(textContent ?? '');
  if (nameMatch && nameMatch.groups?.name) {
    return nameMatch.groups?.name.trim();
  }
  return null;
};

export const ScannedDocumentDetailsPage = ({
  match,
}: RouteComponentProps<ScannedDocumentDetailsPageParams>) => {
  const { hash } = useLocation();
  const history = useHistory();

  const [getDentalPolicyByName, getDentalPolicyByNameResult] =
    useLazyQuery<GetDentalPolicyByNameData>(GET_DENTAL_POLICY_BY_NAME);

  const [getPrivatePolicyByName, getPrivatePolicyByNameResult] =
    useLazyQuery<GetPrivatePolicyByNameData>(GET_PRIVATE_POLICY_BY_NAME);

  const [getRelatedClaims, getRelatedClaimsResult] =
    useLazyQuery<RelatedClaimsData>(GET_CLAIMS_BY_SIMILAR_POLICY_NUMBER);

  const { data, error, loading } = useQuery<GetScannedDocumentData>(
    GET_SCANNED_DOCUMENT_BY_ID,
    {
      variables: {
        scannedDocumentId: match.params.id,
      },
    }
  );

  const isBusy =
    loading ||
    getDentalPolicyByNameResult.loading ||
    getPrivatePolicyByNameResult.loading ||
    getRelatedClaimsResult.loading;

  const scannedDocument = data?.scannedDocument;
  const newDentalPolicyMatchingByName =
    getDentalPolicyByNameResult.data?.dentalPolicyByName;
  const newPrivatePolicyMatchingByName =
    getPrivatePolicyByNameResult.data?.privatePolicyByName;

  // Policy matching via userPolicyId, policyNumber or just customer name
  const matchingPolicy =
    scannedDocument?.userPolicy ||
    scannedDocument?.relatedUserPolicy ||
    newDentalPolicyMatchingByName ||
    (newPrivatePolicyMatchingByName as BasePolicy);

  const documentCanActivateDentalPolicy = scannedDocument?.categories?.some(
    ({ attributes }) =>
      attributes.specialFlags?.some((flag) => flag === 'DENTAL_ACTIVATION')
  );

  const documentCanActivatePrivatePolicy = scannedDocument?.categories?.some(
    ({ attributes }) =>
      attributes.specialFlags?.some((flag) => flag === 'PRIVATE_ACTIVATION')
  );

  const shouldShowActivateDentalButton =
    documentCanActivateDentalPolicy && newDentalPolicyMatchingByName;

  const shouldShowActivatePrivateButton =
    documentCanActivatePrivatePolicy && newPrivatePolicyMatchingByName;

  const shouldShowRelatedPolicyLinks =
    scannedDocument?.relatedUserPolicy ||
    shouldShowActivateDentalButton ||
    shouldShowActivatePrivateButton;

  let nameToFind: string | null = null;

  if (documentCanActivateDentalPolicy) {
    nameToFind = getDentalNameFromDocument(scannedDocument?.textContent);
  } else if (documentCanActivatePrivatePolicy) {
    nameToFind = getPrivateNameFromDocument(scannedDocument?.textContent);
  }

  useEffect(() => {
    const fetchPoliciesByName = async () => {
      if (
        documentCanActivateDentalPolicy &&
        nameToFind &&
        !getDentalPolicyByNameResult.called
      ) {
        await getDentalPolicyByName({
          variables: {
            name: nameToFind,
          },
        });
      }
      if (
        documentCanActivatePrivatePolicy &&
        nameToFind &&
        !getPrivatePolicyByNameResult.called
      ) {
        await getPrivatePolicyByName({
          variables: {
            name: nameToFind,
          },
        });
      }
    };
    fetchPoliciesByName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    documentCanActivateDentalPolicy,
    documentCanActivatePrivatePolicy,
    nameToFind,
    getDentalPolicyByNameResult.called,
    getPrivatePolicyByNameResult.called,
  ]);

  useEffect(() => {
    const fetchRelatedClaims = async () => {
      await getRelatedClaims({
        variables: {
          policyNumber: scannedDocument?.policyNumber,
        },
      });
    };
    if (scannedDocument?.policyNumber && !getRelatedClaimsResult.called) {
      fetchRelatedClaims();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRelatedClaimsResult.called, scannedDocument?.policyNumber]);

  const relatedClaims =
    getRelatedClaimsResult.data?.userClaimsBySimilarPolicyNumber;

  if (error && checkUnauthenticatedErrors(error)) {
    history.push(paths.auth.path);
  }
  if (error && checkUnauthorizedErrors(error)) return <Page403 />;
  if (error) return <PageGenericError />;

  if (isBusy || !scannedDocument) {
    return (
      <div className="flex h-screen w-screen">
        <NavBar current="scannedDocuments" />
        <LoadingState message="Loading data..." />
      </div>
    );
  }

  const tabs: TabInformation[] = [
    {
      name: 'Overview',
      href: '#overview',
      withDot: false,
    },
  ];

  const tabComponents = [
    {
      id: 'overview',
      component: OverviewTab,
      props: {
        scannedDocument,
        relatedClaims,
        policyActivationPossible:
          shouldShowActivateDentalButton || shouldShowActivatePrivateButton,
      },
    },
  ];

  const internalLinkType: ButtonType = scannedDocument.userPolicyId
    ? 'white'
    : 'transparent';

  const internalLinks: InternalLink[] = [
    ...(shouldShowRelatedPolicyLinks
      ? [
          {
            title: `${
              matchingPolicy?.insuranceType &&
              insuranceNameMapper[matchingPolicy?.insuranceType]
            } policy`,
            icon: ShieldCheckIcon,
            href:
              getPolicyDetailsBaseURLFromInsuranceType(
                matchingPolicy?.insuranceType,
                matchingPolicy?.id
              ) ?? '',
            isTemporaryExternal: true,
            type: internalLinkType,
            region: matchingPolicy.regionOfPurchase,
          },
          {
            title: `Account: ${matchingPolicy?.user?.firstName}  ${matchingPolicy?.user?.lastName}`,
            icon: UserIcon,
            href: `/internal/customers/${matchingPolicy?.user?.id}`,
            isTemporaryExternal: true,
            type: internalLinkType,
          },
        ]
      : []),
  ];

  const mappedStatus = statusMapping(scannedDocument.status);

  const currentStatusOption: SelectButtonOption<ScannedDocumentStatus> = {
    label: mappedStatus.text.text,
    id: mappedStatus.text.id || 'NEW',
    color: mappedStatus.color,
  };

  const statusChangeOptions: Record<
    ScannedDocumentStatus,
    ScannedDocumentStatus[]
  > = {
    PENDING_UPLOAD: [],
    NEW: ['IN_PROGRESS', 'WAITING', 'DONE'],
    IN_PROGRESS: ['NEW', 'WAITING', 'DONE'],
    WAITING: ['NEW', 'IN_PROGRESS', 'DONE'],
    DONE: ['NEW', 'IN_PROGRESS', 'WAITING'],
  };

  const archived = !!scannedDocument.archivedAt;

  if (hash === '#pdflink' && scannedDocument.url) {
    window.document.location = scannedDocument.url;
  }

  return (
    <DetailsPage
      windowTitle="Scanned document details - Feather Admin Panel"
      pageTitle={archived ? 'Scanned document (archived)' : 'Scanned document'}
      backLink={{ text: 'Back' }}
      internalLinks={internalLinks}
      tabs={tabs}
      tabComponents={tabComponents}
      loading={isBusy}
      current="scannedDocuments"
      pageSubtitle={scannedDocument.recipientName ?? 'No name'}
      headerInfoTexts={[
        ...(scannedDocument.visibleInUserAccount && scannedDocument.userPolicyId
          ? [
              {
                title: 'Visible in user account',
                icon: <EyeIcon />,
              },
            ]
          : []),
        ...(scannedDocument.meta?.source
          ? [
              {
                title: `Source: ${scannedDocument.meta.source}`,
                icon: sourceToIconMapping[scannedDocument.meta.source],
              },
            ]
          : []),
      ]}
      headerActions={
        !archived && (
          <div className="flex items-center space-x-4">
            <div className="relative flex items-center">
              <ChangeStatus
                status={currentStatusOption}
                policyId={scannedDocument.id}
                statusOptions={SCANNED_DOCUMENTS_STATUSES.map((status) => ({
                  id: status,
                  label: statusMapping(status).text.text,
                  color: statusMapping(status).color,
                  title: statusMapping(status).text.text,
                }))}
                modals={{
                  PENDING_UPLOAD: { showOpenIssues: false, formFields: [] },
                  NEW: { showOpenIssues: false, formFields: [] },
                  IN_PROGRESS: { showOpenIssues: false, formFields: [] },
                  WAITING: { showOpenIssues: false, formFields: [] },
                  DONE: { showOpenIssues: false, formFields: [] },
                }}
                mutation={UPDATE_SCANNED_DOCUMENT}
                mutationOperationVariables={{
                  refetchQueries: [GET_SCANNED_DOCUMENT_BY_ID],
                  awaitRefetchQueries: true,
                  errorPolicy: 'none',
                }}
                statusChangeOptions={statusChangeOptions}
                mutationVariables={{}}
              />
            </div>
            <ShowInAccount scannedDocument={scannedDocument} />
            {shouldShowActivateDentalButton && (
              <ActivateDentalPolicy
                scannedDocument={scannedDocument}
                policyToActivate={newDentalPolicyMatchingByName}
              />
            )}
            {shouldShowActivatePrivateButton && (
              <ActivatePrivatePolicy
                scannedDocument={scannedDocument}
                policyToActivate={newPrivatePolicyMatchingByName}
              />
            )}
          </div>
        )
      }
      error={error}
    />
  );
};
