import { getFixedT } from '@getpopsure/i18n-react';

export const getOccupations = () => {
  const t = getFixedT();

  return [
    {
      title: t('admin.occupation.employed', 'Employed'),
      label: t('admin.occupation.employed', 'Employed'),
      id: 'employed',
    },
    {
      title: t('admin.occupation.selfemployed', 'Self-employed'),
      label: t('admin.occupation.selfemployed', 'Self-employed'),
      id: 'self',
    },
    {
      title: t('admin.occupation.student', 'Student'),
      label: t('admin.occupation.student', 'Student'),
      id: 'student',
    },
    {
      title: t('admin.occupation.otherOccupation', 'Other'),
      label: t('admin.occupation.otherOccupation', 'Other'),
      id: 'other',
    },
  ];
};
