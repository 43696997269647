import { ApolloError, ServerParseError } from '@apollo/client';

export const checkUnauthenticatedErrors = (error: ApolloError): boolean => {
  // For unauthenticated errors the server always returns a network error with code 401
  // This is the only case where 401 errors are returned
  if (error.networkError !== null) {
    const { statusCode } = error.networkError as ServerParseError;
    return statusCode === 401;
  }
  return false;
};
