import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import {
  getTemplateInsuranceTypeCountry,
  TemplateInsuranceType,
} from 'pages/policies/template/models/InsuranceTypes';
import { GenericClaimType } from 'shared/claims/generic/models';
import { Country, countryNameMapper } from 'shared/countries/models';
import { policyMappers } from 'shared/insurances';

import { genericClaimStatusOptions } from '../../statusUpdate/data';

const claimTypeOptions = (
  claimTypes: GenericClaimType[]
): SelectButtonOption<GenericClaimType>[] => {
  return claimTypes.map((type) => ({
    id: type,
    label: policyMappers.GENERIC?.claim?.typeNameMapper[type] ?? '',
    title: policyMappers.GENERIC?.claim?.typeNameMapper[type] ?? '',
  }));
};

const getRegionFilterOptions = (insuranceType: TemplateInsuranceType) =>
  getTemplateInsuranceTypeCountry()[insuranceType].reduce(
    (options, country) => {
      if (country === 'eu') return options;
      options.push({
        id: country,
        label: countryNameMapper[country],
        title: countryNameMapper[country],
      });
      return options;
    },
    <SelectButtonOption<string>[]>[]
  );

export const getFilterConfigByRegion = ({
  claimTypes,
  insuranceType,
  currentRegion,
}: {
  claimTypes: GenericClaimType[];
  insuranceType: TemplateInsuranceType;
  currentRegion?: Country;
}): FilterConfig[] => [
  {
    options: genericClaimStatusOptions(insuranceType),
    filterType: 'MULTIPLE',
    label: 'Status',
    id: 'filterStatus',
    placeholder: 'Status',
  },
  {
    options: claimTypeOptions(claimTypes),
    filterType: 'MULTIPLE',
    label: 'Claim type',
    id: 'filterClaimType',
    placeholder: 'Claim type',
  },
  ...(currentRegion === 'eu'
    ? [
        {
          options: getRegionFilterOptions(insuranceType),
          filterType: 'MULTIPLE' as const,
          label: 'Region',
          id: 'region',
          placeholder: 'Select region',
        },
      ]
    : []),
  {
    filterType: 'DATE_RANGE',
    label: 'Created on',
    id: 'createdAt',
  },
];
