import FileErrorCard from 'components/FileErrorCard';
import { TextPreviewInformation } from 'components/InformationCard/TextPreviewInformation/TextPreviewInformation';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';
import {
  ClaimData,
  ScannedDocument,
} from 'pages/scannedDocuments/scannedDocuments.types';

import { ConnectedClaims } from './ConnectedClaims/ScannedDocument.ConnectedClaims';
import { ScannedDocumentInfo } from './Info/ScannedDocument.Info';
import * as styles from './ScannedDocument.OverviewTab.styles';

export const OverviewTab = ({
  scannedDocument,
  relatedClaims,
  policyActivationPossible,
}: {
  scannedDocument: ScannedDocument;
  relatedClaims?: ClaimData[];
  policyActivationPossible?: boolean;
}) => {
  const archived = !!scannedDocument.archivedAt;
  // Policy connected via userPolicyId
  const hasConnectedPolicy = !!scannedDocument.userPolicyId;
  // Policy related via matching policy number
  const hasRelatedPolicy = !!scannedDocument.relatedUserPolicy;

  const showUnconfirmedRelationWarning =
    !archived && hasRelatedPolicy && !hasConnectedPolicy;

  const showMoMatchingPolicyByNumberError =
    scannedDocument.policyNumber &&
    !policyActivationPossible &&
    !archived &&
    !hasRelatedPolicy;

  const showNoPolicyNumberError =
    !archived && !scannedDocument.policyNumber && scannedDocument.processed;
  const showUnprocessedError = !archived && !scannedDocument.processed;

  const showClaimsSection =
    scannedDocument.policyNumber && (hasConnectedPolicy || hasRelatedPolicy);

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        {archived && (
          <FileErrorCard
            open={true}
            className="!mt-0 mb-4"
            title="Archived"
            hideCloseButton={true}
            handleClose={() => {}}
            errorType="WARNING"
            description={`This document was archived on ${dayjs(
              scannedDocument.archivedAt
            ).format(DATE_FORMAT)}.`}
          />
        )}
        {showUnconfirmedRelationWarning && (
          <FileErrorCard
            open={true}
            className="!mt-0 mb-4"
            title="Unconfirmed relation"
            hideCloseButton={true}
            handleClose={() => {}}
            errorType="WARNING"
            description="The relation between the policy and this document is only based on a matching policy number or name and hasn't been reviewed by a human being yet."
          />
        )}
        {policyActivationPossible && (
          <FileErrorCard
            open={true}
            className="!mt-0 mb-4"
            title="Policy activation possible"
            hideCloseButton={true}
            handleClose={() => {}}
            errorType="WARNING"
            description="Policy matches document by name, is not active and has no policy number yet."
          />
        )}
        {showMoMatchingPolicyByNumberError && (
          <FileErrorCard
            open={true}
            className="!mt-0 mb-4"
            title="No matching policy"
            hideCloseButton={true}
            handleClose={() => {}}
            errorType="ERROR"
            description="We were unable to find a policy that matches this document's policy number."
          />
        )}
        {showNoPolicyNumberError && (
          <FileErrorCard
            open={true}
            className="!mt-0 mb-4"
            title="No policy number"
            hideCloseButton={true}
            handleClose={() => {}}
            errorType="ERROR"
            description="We were unable to automatically retrieve the policy number from the document. Please check the PDF file and add it manually."
          />
        )}
        {showUnprocessedError && (
          <FileErrorCard
            open={true}
            className="!mt-0 mb-4"
            title="Unprocessed document"
            hideCloseButton={true}
            handleClose={() => {}}
            errorType="ERROR"
            description="This document hasn't been processed yet."
          />
        )}
        <ScannedDocumentInfo scannedDocument={scannedDocument} />
        <TextPreviewInformation textContent={scannedDocument.textContent} />
      </div>
      <div className={styles.rightContainer}>
        {showClaimsSection && (
          <ConnectedClaims
            scannedDocument={scannedDocument}
            relatedClaims={relatedClaims}
          />
        )}
      </div>
    </div>
  );
};
