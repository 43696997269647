import { useReactiveVar } from '@apollo/client';
import {
  CheckCircleIcon,
  ExclamationIcon,
  XIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import { AlertBannerStatus } from 'components/AlertBanners/models';
import { useEffect, useState } from 'react';
import { alertBanners } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';

import * as styles from './styles';

interface AlertBannerProps {
  state: AlertBannerState;
}

export const AlertBanner = ({ state }: AlertBannerProps) => {
  const { type, onClose, message, id: currentBannerId } = state;

  const alertBannersState = useReactiveVar(alertBanners);

  const [alertBannerStatus, setAlerBannerStatus] =
    useState<AlertBannerStatus>('IDLE');

  useEffect(() => {
    if (state) {
      setAlerBannerStatus('OPEN');
    }
  }, [state]);

  const handleOnClose = () => {
    onClose?.();
    setAlerBannerStatus('CLOSE');
  };

  useEffect(() => {
    if (alertBannerStatus === 'CLOSE') {
      const timeout = setTimeout(() => {
        setAlerBannerStatus('IDLE');

        const currentBannerRemovedState = alertBannersState.filter(
          ({ id }) => id !== currentBannerId
        );
        alertBanners(currentBannerRemovedState);
      }, 800);

      return () => clearTimeout(timeout);
    }

    if (alertBannerStatus === 'OPEN') {
      const timeout = setTimeout(() => {
        handleOnClose();
      }, 5_000);

      return () => clearTimeout(timeout);
    }
  }, [alertBannerStatus]);

  if (alertBannerStatus === 'IDLE') {
    return <></>;
  }

  return (
    <div
      className={classNames(styles.alertBannerWrapper, {
        [styles.appearOut]: alertBannerStatus === 'CLOSE',
      })}
    >
      <div
        className={classNames({
          [styles.alertCardSuccess]: type === 'SUCCESS',
          [styles.alertCardWarning]: type === 'WARNING',
        })}
      >
        <div className={styles.alertCardContent}>
          <div className={styles.iconWrapper}>
            {type === 'SUCCESS' && (
              <CheckCircleIcon
                className={styles.iconSuccess}
                aria-hidden="true"
              />
            )}
            {type === 'WARNING' && (
              <ExclamationIcon
                className={styles.iconWarning}
                aria-hidden="true"
              />
            )}
          </div>
          <div className={styles.textWrapper}>
            <p
              className={classNames({
                [styles.textSuccess]: type === 'SUCCESS',
                [styles.textWarning]: type === 'WARNING',
              })}
            >
              {message}
            </p>
          </div>
          <div className={styles.buttonWrapper}>
            <button
              type="button"
              className={classNames({
                [styles.buttonSuccess]: type === 'SUCCESS',
                [styles.buttonWarning]: type === 'WARNING',
              })}
              onClick={handleOnClose}
              data-testid="alert-banner-close-button"
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className={styles.buttonIcon} aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
