import { BadgeColor } from 'components/Badge/Badge';

import { PublicHealthStatus } from '../types';

export const publicHealthStatusMapper: Record<PublicHealthStatus, string> = {
  APPLICATION_RECEIVED: 'Received',
  UNI_CHECK: 'Uni check',
  APPLICATION_SENT: 'Sent',
  PROCESSING: 'Processing',
  COVERED: 'Covered',
  ERR_MISSING_INFO: 'Missing info',
  DROPPED_OUT: 'Canceled',
  CANCELED: 'Canceled',
  CANT_BE_COVERED: 'Not eligible',
};

export const publicHealthBadgeColorMapper: Record<
  PublicHealthStatus,
  BadgeColor
> = {
  APPLICATION_RECEIVED: 'purple',
  UNI_CHECK: 'pink',
  APPLICATION_SENT: 'blue',
  PROCESSING: 'blue',
  COVERED: 'green',
  ERR_MISSING_INFO: 'red',
  DROPPED_OUT: 'gray',
  CANCELED: 'gray',
  CANT_BE_COVERED: 'yellow',
};
