import CalendarDatePicker from 'components/CalendarDatePicker';
import { EditComponentDefaultProps } from 'components/InformationCardV2/models';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';

export interface InformationCardDateProps extends EditComponentDefaultProps {
  value?: Date;
  onChange?: (value: Date) => void;
}

export const InformationCardDate = ({
  shouldDisplayEditField = false,
  onChange,
  value,
  error,
}: InformationCardDateProps) => {
  const dateString = value ? dayjs(value).format(DATE_FORMAT) : '';
  const detailedDateString = value
    ? dayjs(value.toString()).format('DD/MM/YYYY, HH:mm')
    : '';

  const handleDatePickerChange = (date: Date | [Date, Date] | null) => {
    if (!date || Array.isArray(date)) return;

    onChange?.(
      new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    );
  };

  if (shouldDisplayEditField) {
    return (
      <div>
        <CalendarDatePicker
          selectedDate={value ? new Date(value) : undefined}
          onChange={handleDatePickerChange}
        />
        {error && <p className="mt-1 text-xs text-red-400">{error}</p>}
      </div>
    );
  }

  return (
    <div className="group relative">
      <span className="text-sm text-gray-900">{dateString}</span>
      {detailedDateString && (
        <span className="absolute bottom-5 left-3 hidden w-max rounded-md bg-white px-4 py-2 text-sm text-gray-600 shadow-3xl group-hover:inline">
          {detailedDateString}
        </span>
      )}
    </div>
  );
};
