import { useTranslation } from '@getpopsure/i18n-react';
import { PaperClipIcon } from '@heroicons/react/solid';

import { FileDataSchema, TaskEngineDataType } from '../TaskEngine.types';
import * as styles from './TaskPreviewModal.styles';
import { fileIcon } from './TaskPreviewModal.styles';

export const FilePreview = ({ data }: { data: TaskEngineDataType }) => {
  const { t } = useTranslation();
  const validFiles = FileDataSchema.safeParse(data);

  if (!validFiles.success) {
    return (
      <div>
        <p className={styles.invalidAnswer}>
          {t('taskEngine.taskPreviewModal.filePreview.error', 'Invalid file')}
        </p>
      </div>
    );
  }

  const { files } = validFiles.data;

  return (
    <div className="mb-4 last:mb-0">
      {files.map((file) => (
        <a
          className={styles.filePreview}
          key={file.blobName}
          href={file.url}
          target="_blank"
          rel="noreferrer"
        >
          <span className={fileIcon}>
            <PaperClipIcon className="w-5" />
          </span>
          <span className="truncate">{file.fileName}</span>
        </a>
      ))}
    </div>
  );
};
