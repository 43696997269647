import { ModalFormData } from 'components/ModalForm/models';
import dayjs from 'dayjs';
import { PrivateHealthPolicy } from 'models/privateHealthPolicy';
import { retrievePolicySuggestion } from 'pages/claims/template/components/CreateClaimModal/utils';
import { claimTypeNameMapper as privateHealthClaimTypeNameMapper } from 'shared/claims/privateHealth/claimTypeNameMapper';
import {
  claimTypes as privateHealthClaimTypes,
  PrivateHealthClaimStatus,
  privateHealthClaimStatuses,
  PrivateHealthClaimType,
} from 'shared/claims/privateHealth/models';
import { statusNameMapper as privateHealthStatusNameMapper } from 'shared/claims/privateHealth/statusNameMapper';

import { SubmittableClaim } from '../../../template/components/CreateClaimModal/models';

export const invoiceYearOptions: string[] = [
  dayjs().format('YYYY'),
  dayjs().subtract(1, 'year').format('YYYY'),
  dayjs().subtract(2, 'year').format('YYYY'),
];

export const retrievePrivateHealthCreateClaimFormData = (
  policies: PrivateHealthPolicy[],
  setPolicySearchQuery: (query: string) => void,
  policiesLoading: boolean
): ModalFormData<
  SubmittableClaim<PrivateHealthClaimStatus, PrivateHealthClaimType>,
  PrivateHealthClaimType | PrivateHealthClaimStatus | string
>[] => [
  {
    id: 'policyId',
    label: 'User policy',
    renderDetails: {
      type: 'autoSuggest',
      options: policies.map(({ id, user, policyNumber }) => ({
        id,
        label: retrievePolicySuggestion({
          firstName: user?.firstName,
          lastName: user?.lastName,
          policyNumber,
        }),
      })),
      placeholder: 'Enter name, email or policy number',
      setExternalQuery: (query: string) => {
        setPolicySearchQuery(query);
      },
      useUnfilteredOptions: true,
    },
    color: 'gray',
    required: true,
    loading: policiesLoading,
  },
  {
    id: 'status',
    label: 'Status',
    renderDetails: {
      type: 'select',
      options: privateHealthClaimStatuses.flatMap((status) => ({
        id: status,
        label: privateHealthStatusNameMapper[status],
      })),
      placeholder: 'Select a status',
    },
    color: 'gray',
    required: true,
  },
  {
    id: 'claimType',
    label: 'Claim type',
    renderDetails: {
      type: 'multiSelect',
      options: privateHealthClaimTypes.map((type) => ({
        id: type,
        label: privateHealthClaimTypeNameMapper[type],
      })),
      placeholder: 'Select type',
    },
    color: 'gray',
    required: true,
  },
  {
    id: 'amount',
    label: 'Claim amount',
    renderDetails: { type: 'currency', placeholder: '0.00' },
    color: 'gray',
  },
  {
    id: 'invoiceYear',
    label: 'Invoice year',
    renderDetails: {
      type: 'select',
      options: invoiceYearOptions.map((option) => ({
        id: option,
        label: option,
      })),
      placeholder: 'Select year',
    },
    color: 'gray',
  },
  {
    id: 'uploadDocument',
    label: 'Upload a document',
    renderDetails: { type: 'upload' },
  },
];
