import { useTranslation } from '@getpopsure/i18n-react';
import Header from 'components/Header';
import { Helmet } from 'react-helmet';

import * as styles from './styles';

const Page404 = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>Page Not Found - Feather Admin Panel</title>
      </Helmet>
      <div>
        <Header />
        <div className={styles.contentWrapper}>
          <img
            src="/static/error-states/feathers.svg"
            alt="feathers"
            className={styles.feathers}
          />
          <h1 className={styles.title}>
            {t('admin.error.pagenotfound.title', 'Page not found')}
          </h1>
          <span className={styles.text}>
            {t(
              'admin.error.pagenotfound.description',
              "This page doesn't exist or was removed."
            )}
          </span>
        </div>
      </div>
    </>
  );
};

export default Page404;
