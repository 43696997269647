import classNames from 'classnames';

export const nav = classNames(
  'flex',
  'justify-between',
  'border-b',
  'border-gray-300',
  'mx-[40px]'
);

export const tabsInnerContainer = classNames(
  'flex',
  'flex-wrap',
  'gap-8',
  'w-full'
);

export const currentTab = classNames('border-indigo-500', 'text-indigo-500');

export const disabledTab = classNames(
  'border-transparent',
  'text-gray-500',
  'hover:text-gray-600',
  'hover:border-gray-500'
);

export const tab = classNames(
  'group',
  'inline-flex',
  'items-center',
  'py-4',
  'px-1',
  'border-b-2',
  'font-bold',
  'text-sm',
  'items-center',
  'gap-2',
  'min-w-fit'
);

export const option = classNames('flex', 'items-center', 'gap-8');
export const withSubOptions = classNames('basis-96');
