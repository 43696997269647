import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import {
  LiabilityClaimStatus,
  liabilityClaimStatuses,
  LiabilityClaimType,
  liabilityClaimTypes,
} from 'shared/claims/liability/models';
import { policyMappers } from 'shared/insurances';

const statusOptions: SelectButtonOption<LiabilityClaimStatus>[] =
  liabilityClaimStatuses.map((status) => ({
    id: status,
    label: policyMappers.LIABILITY?.claim?.claimStatusMapper[status] ?? '',
    title: policyMappers.LIABILITY?.claim?.claimStatusMapper[status] ?? '',
  }));

const claimTypeOptions: SelectButtonOption<LiabilityClaimType>[] =
  liabilityClaimTypes.map((type) => ({
    id: type,
    label: policyMappers.LIABILITY?.claim?.typeNameMapper[type] ?? '',
    title: policyMappers.LIABILITY?.claim?.typeNameMapper[type] ?? '',
  }));

export const filters: FilterConfig[] = [
  {
    options: statusOptions,
    filterType: 'MULTIPLE',
    label: 'Status',
    id: 'filterStatus',
    placeholder: 'Status',
  },
  {
    options: claimTypeOptions,
    filterType: 'MULTIPLE',
    label: 'Claim type',
    id: 'filterClaimType',
    placeholder: 'Claim type',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Created on',
    id: 'createdAt',
  },
];
