import { gql } from '@apollo/client';

export const SUBMIT_PET_HEALTH_CLAIM = gql`
  mutation SubmitPetHealthClaim($claimId: String!, $comment: String) {
    triggerGenericClaimSubmission(
      workflowName: "SubmitPetHealthClaim"
      insuranceType: "PET_HEALTH"
      claimId: $claimId
      comment: $comment
    )
  }
`;
