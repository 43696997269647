import { BadgeColor } from 'components/Badge/Badge';

import { DisabilityStatus } from '../types';

export const disabilityStatusMapper: Record<DisabilityStatus, string> = {
  PENDING: 'Pending',
  ACTIVE: 'Covered',
  CANCELED: 'Canceled',
  DROPPED_OUT: 'Dropped out',
  NOT_ELIGIBLE: 'Not eligible',
};

export const disabilityBadgeColorMapper: Record<DisabilityStatus, BadgeColor> =
  {
    PENDING: 'purple',
    ACTIVE: 'green',
    CANCELED: 'gray',
    DROPPED_OUT: 'gray',
    NOT_ELIGIBLE: 'yellow',
  };
