import { gql } from '@apollo/client';

export const UPDATE_GENERIC_POLICY_STATUS = gql`
  mutation UpdateGenericPolicyStatus(
    $status: String!
    $id: String!
    $activeUntil: String
  ) {
    updateGenericPolicyStatus(
      status: $status
      id: $id
      activeUntil: $activeUntil
    ) {
      id
      publicStatus
    }
  }
`;
