import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';

interface TableCellDateProps {
  data: unknown;
  getDate: (data: any) => string;
}

export const TableCellDate = ({ data, getDate }: TableCellDateProps) => {
  const cellData = getDate(data);
  const date = cellData ? dayjs(cellData.toString()).format(DATE_FORMAT) : '';
  return <span>{date}</span>;
};
