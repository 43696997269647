import { SelectMenuOption } from 'components/SelectMenu';

export type CompanyType = 'HR' | 'PROVIDER';

export const mapCompanyType = (type: string) => {
  switch (type) {
    case 'HR':
      return 'HR';
    case 'PROVIDER':
      return 'Provider';
  }
};

export const companyTypeOptions: SelectMenuOption[] = [
  {
    id: 'hr',
    label: 'HR',
  },
  {
    id: 'provider',
    label: 'Provider',
  },
];
