import classNames from 'classnames';

export const pageWrapper = classNames(
  'mx-auto',
  'w-full',
  'flex',
  'flex-col',
  'justify-start',
  'items-center',
  'relative',
  'h-full',
  'max-w-[1200px]',
  'overflow-y-scroll'
);

export const tabWrapper = classNames(
  'flex',
  'flex-col',
  'lg:flex-row',
  'md:mx-[40px]',
  'mx-[20px]',
  'items-start',
  'relative'
);

export const headerWrapper = classNames(
  'sticky',
  'top-0',
  'w-full',
  'max-w-[1200px]',
  'pt-[44px]',
  'md:h-[180px]',
  'z-20',
  'bg-gray-200'
);

export const header = classNames(
  'flex',
  'flex-col',
  'md:mx-[40px]',
  'mx-[20px]',
  'pb-2',
  'sticky',
  'bg-gray-200'
);

export const pageTitle = classNames(
  'flex',
  'items-start',
  'md:items-center',
  'justify-center',
  'flex-col',
  'md:flex-row'
);

export const topSectionWrapper = classNames(
  'flex',
  'justify-between',
  'items-center'
);

export const middleSectionWrapper = classNames(
  'flex',
  'justify-between',
  'items-start',
  'md:items-center',
  'mt-[16px]',
  'flex-col',
  'md:flex-row',
  'space-y-3',
  'md:space-y-0'
);

export const title = classNames('text-[30px]', 'font-bold', 'leading-10');

const subTitleDefaultStyles = classNames(
  'text-[30px]',
  'font-bold',
  'leading-10',
  'text-gray-600'
);

export const subtitle = classNames(subTitleDefaultStyles, 'md:ml-[16px]');

export const longSubTitle = classNames(subTitleDefaultStyles, 'md:mt-[16px]');

export const detailsSectionWrapper = classNames(
  'flex',
  'items-center',
  'space-x-4',
  'min-h-[60px]'
);

export const internalLinksContainer = classNames('flex');

export const detailsSection = classNames('flex', 'items-center');

export const icon = classNames('text-gray-500', 'w-[20px]');

export const headerInfoContainer = classNames(
  'flex',
  'text-gray-600',
  'items-center',
  'space-x-2'
);

export const headerInfoText = classNames(
  'text-sm',
  'flex',
  'items-center',
  'space-x-1'
);

export const tabsContainer = classNames('relative', 'w-full');
