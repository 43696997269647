import { CombinedClaimStatus, CombinedClaimType } from 'shared/claims/models';
import { Country } from 'shared/countries/models';

import { bikeClaimsData } from '../bike';
import { dentalClaimsData } from '../dental';
import { dogLiabilityClaimsData } from '../dogLiability';
import { expatHealthClaimsData } from '../expatHealth';
import { expatSpainHealthClaimsData } from '../expatSpain';
import { genericClaimsData } from '../generic';
import { householdClaimsData } from '../household';
import { legalClaimsData } from '../legal';
import { liabilityClaimsData } from '../liability';
import { privateHealthClaimsData } from '../privateHealth';
import { ClaimsTemplateData, InsurancePathName } from './models';

export const claimsTemplateDataMapper = <
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Status extends CombinedClaimStatus,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Type extends CombinedClaimType
>(
  country?: Country
): Record<
  InsurancePathName,
  // TODO: [KONG] Update type
  ClaimsTemplateData<any, any>
> => ({
  'private-health': privateHealthClaimsData,
  'expat-health': expatHealthClaimsData,
  dental: dentalClaimsData,
  liability: liabilityClaimsData,
  household: householdClaimsData,
  travel: genericClaimsData('TRAVEL'),
  'pet-health': genericClaimsData('PET_HEALTH'),
  disability: genericClaimsData('DISABILITY'),
  'company-health': genericClaimsData('COMPANY_HEALTH'),
  'company-life': genericClaimsData('COMPANY_LIFE'),
  bike: bikeClaimsData('BIKE'),
  legal: legalClaimsData('LEGAL'),
  'dog-liability': dogLiabilityClaimsData('DOG_LIABILITY'),
  'expat-health-es': expatSpainHealthClaimsData,
  'expat-health-eu': genericClaimsData('INCOMING_EU', country),
  'expat-health-long-term': genericClaimsData('INCOMING_LT', country),
});
