export const claimTypes = [
  'COST_PLAN',
  'DOCTORS_VISIT',
  'MEDICATION',
  'NON_MEDICAL_PROFESSIONAL',
  'SPECIALIST_VISIT',
  'DENTAL',
  'AIDS',
  'PHYSICAL_OR_SPEECH_THERAPY',
  'HOSPITAL_TREATMENT',
  'OTHER',
] as const;
export type PrivateHealthClaimType = typeof claimTypes[number];

export const privateHealthClaimStatuses = [
  'SUBMITTED_TO_PROVIDER',
  'RECEIVED',
  'INCOMPLETE',
  'DUPLICATE',
  'DROPPED_OUT',
  'CLOSED',
  'MISSING_INFO_RECEIVED',
  'DENIED',
] as const;
export type PrivateHealthClaimStatus =
  typeof privateHealthClaimStatuses[number];
