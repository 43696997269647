import { useTranslation } from '@getpopsure/i18n-react';
import { UploadIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { useCallback } from 'react';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { fileSizeValidator } from 'shared/validators/fileSizeValidator';

import AcceptedFile from './AcceptedFile';
import RejectedFile from './RejectedFile';
import * as styles from './styles';

interface FileDropzoneProps {
  onFileSelect: (file?: File) => void;
  onFileDelete?: () => void;
  uploadedFile?: File;
  disabled?: boolean;
  color?: 'white' | 'gray';
}

const FileDropzone = ({
  onFileDelete,
  onFileSelect,
  uploadedFile,
  disabled = false,
  color = 'white',
}: FileDropzoneProps) => {
  const { t } = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onFileSelect(acceptedFiles[0]);
    },
    [onFileSelect]
  );

  const handleFileDelete = () => {
    onFileSelect(undefined);
  };

  const { fileRejections, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      accept: 'image/jpg,image/png,image/jpeg,application/pdf,image/gif',
      maxFiles: 1,
      validator: fileSizeValidator,
      onDrop,
      disabled,
      multiple: false,
    });

  return (
    <section className="w-full">
      {!uploadedFile ? (
        <>
          <div
            className={classNames(styles.dropzone, {
              [styles.dragActive]: isDragActive,
              [styles.dragInactive]: !isDragActive,
            })}
            {...getRootProps()}
          >
            <input data-testid="file-upload" {...getInputProps()} />
            <div className={styles.dropzoneContent}>
              <UploadIcon className={styles.uploadIcon} />
              <p
                className={classNames(styles.uploadText, {
                  hidden: isDragActive,
                })}
              >
                <b className={styles.textHighlight}>
                  {t(
                    'admin.documents.uploadcomponent.default.maintitle',
                    'Select a file'
                  )}
                </b>{' '}
                {t(
                  'admin.documents.uploadcomponent.default.secondarytitle',
                  'or drag and drop'
                )}
              </p>
              <p
                className={classNames(styles.uploadRequirementsText, {
                  hidden: isDragActive,
                })}
              >
                {t(
                  'admin.documents.uploadcomponent.default.helptext',
                  'PDF, PNG, JPG, GIF up to 20MB'
                )}
              </p>
            </div>
          </div>
          <div>
            {fileRejections.map((file) => {
              return (
                <RejectedFile
                  key={file.file.name}
                  errorCode={file.errors[0].code as ErrorCode}
                />
              );
            })}
          </div>
        </>
      ) : (
        <AcceptedFile
          file={uploadedFile}
          onFileDelete={onFileDelete || handleFileDelete}
          disabled={disabled}
          color={color}
        />
      )}
    </section>
  );
};

export default FileDropzone;
