import classNames from 'classnames';

export const section = classNames(
  'mt-4',
  'flex',
  'flex-col',
  'space-y-2',
  'first:mt-0'
);

export const label = classNames('text-sm', 'font-bold', 'text-gray-900');

export const errorMessage = classNames('text-sm', 'text-red-600');

export const input = classNames('mt-2');

export const actionsContainer = classNames(
  'mt-8',
  'border-t',
  'border-solid',
  'border-gray-300',
  'pb-6',
  'pt-8'
);
