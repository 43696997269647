import classNames from 'classnames';

export const alertCardSuccess = classNames(
  'rounded-[6px]',
  'bg-green-50',
  'p-4'
);
export const alertCardWarning = classNames(
  'rounded-[6px]',
  'bg-yellow-50',
  'p-4'
);

export const alertCardContent = classNames('flex');

export const iconWrapper = classNames('flex-shrink-0');

export const iconSuccess = classNames('h-5', 'w-5', 'text-green-400');
export const iconWarning = classNames('h-5', 'w-5', 'text-yellow-400');

export const textWrapper = classNames('ml-3');

export const textSuccess = classNames(
  'text-sm',
  'font-medium',
  'text-green-800'
);
export const textWarning = classNames(
  'text-sm',
  'font-medium',
  'text-yellow-800'
);

export const buttonWrapper = classNames(
  'ml-auto',
  'pl-3',
  '-mx-1.5',
  '-my-1.5'
);

export const buttonSuccess = classNames(
  'inline-flex',
  'bg-green-50',
  'rounded-[6px]',
  'p-1.5',
  'text-green-900',
  'hover:bg-green-100',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'focus:ring-offset-green-50',
  'focus:ring-green-600'
);

export const buttonWarning = classNames(
  'inline-flex',
  'bg-yellow-50',
  'rounded-[6px]',
  'p-1.5',
  'text-yellow-900',
  'hover:bg-yellow-100',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'focus:ring-offset-yellow-50',
  'focus:ring-yellow-600'
);

export const buttonIcon = classNames('h-5', 'w-5');

export const alertBannerWrapper = classNames(
  'fixed',
  'w-[448px]',
  'top-[32px]',
  'left-0',
  'right-0',
  'mx-auto',
  'z-30',
  'animate-appear-in-horizontal'
);

export const appearOut = classNames('animate-appear-out-horizontal');
