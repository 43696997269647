import { gql } from '@apollo/client';

export const GET_ADMIN_USERS = gql`
  query adminUsers(
    $offset: Float!
    $limit: Float!
    $searchString: String!
    $sortColumn: String!
    $sortOrder: String!
    $filterCompany: String
  ) {
    adminUsers(
      offset: $offset
      limit: $limit
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterCompany: $filterCompany
    ) {
      id
      firstName
      lastName
      company
      email
      authenticationType
      createdAt
      active
      isFeather
    }
    adminUsersCount(
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
    )
  }
`;

export const GET_ADMIN_USER = gql`
  query adminUser($id: String!) {
    adminUser(id: $id) {
      id
      firstName
      lastName
      company
      email
      authenticationType
      createdAt
      active
      roles
      isFeather
      language
      hasAccessToNewAdminPanel
      hasAccessToOldAdminPanel
    }
  }
`;
