import { UsersIcon } from '@heroicons/react/outline';
import { paths } from 'routes/definedPaths';

import { NavigationLink } from '../NavBar';

export const customersLinks: NavigationLink = {
  name: 'Customers',
  icon: UsersIcon,
  id: 'customers',
  href: paths.customers.path,
};
