import { CheckBadgeType } from 'components/CheckBadge/CheckBadge';
import { ComboBoxOption } from 'components/ComboBox/ComboBox';
import { CustomerDelinquencyBanner } from 'components/CustomerDelinquencyBanner';
import { CustomerFraudulentBanner } from 'components/CustomerFraudulentBanner';
import FileErrorCard from 'components/FileErrorCard';
import InformationCard, {
  InformationRow,
  InformationSection,
} from 'components/InformationCard';
import ComboBoxInformation from 'components/InformationCard/ComboBoxInformation';
import DateInformation from 'components/InformationCard/DateInformation';
import EditableInformationCard from 'components/InformationCard/EditableInformationCard';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import TextWithCheckInformation from 'components/InformationCard/TextWithCheckInformation';
import TextWithDetailsInformation from 'components/InformationCard/TextWithDetailsInformation';
import { ReferralCode } from 'components/ReferralCode/ReferralCode';
import { UPDATE_POLICY_INFORMATION } from 'graphql/publicHealth/internal/mutations';
import { GET_PUBLIC_POLICY } from 'graphql/publicHealth/internal/queries';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import HealthCardSection from 'pages/policies/publicHealth/components/HealthCardSection';
import ChangeAccount from 'pages/policies/template/ChangeAccount/ChangeAccount';
import { commissionEntitlementCutoffDate } from 'shared/commissionEntitlementCutoffDate';
import { getInternalPublicHealthStatus } from 'shared/mapInternalPublicHealthStatus';
import { mapProviderBranch } from 'shared/mapProviderBranches/mapProviderBranches';
import { mapUserOccupation } from 'shared/mapUserOccupation';

import DuplicateHealthPoliciesCard from '../../../../../../components/DuplicateHealthPoliciesCard';
import StartDateConfirmationModal from '../../../components/StartDateConfirmationModal';
import { CommissionResetCard } from '../ComisssionResetCard/CommissionResetCard';
import HRInformationCard from '../HRInformationCard/HRInformationCard';
import SideSection from '../SideSection';
import StudentChecklistCard from '../StudentChecklistCard';

interface OverviewTabProps {
  policy: PublicHealthPolicy;
  providerAgents: ComboBoxOption[];
  setProviderAgentsQuery: (newQuery: string) => void;
  companies: any[];
  setCompaniesQuery: (newQuery: string) => void;
}

const OverviewTab = ({
  policy,
  providerAgents,
  setProviderAgentsQuery,
  companies,
  setCompaniesQuery,
}: OverviewTabProps) => {
  const providerAgentMapping = (providerAgentId: string) => {
    return providerAgents.find(
      (providerAgent) => providerAgent.id === providerAgentId
    )?.label;
  };

  const customerInformation: InformationSection[] = [
    {
      title: 'Insured person',
      rows: [
        {
          id: 'firstName',
          title: 'First name',
          data: policy.insuredPerson.firstName ?? policy.user.firstName ?? '',
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'lastName',
          title: 'Last name',
          data: policy.insuredPerson.lastName ?? policy.user.lastName ?? '',
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'dateOfBirth',
          title: 'Date of birth',
          data:
            policy.insuredPerson.dateOfBirth ?? policy.user.dateOfBirth ?? '',
          type: 'DATE',
          component: DateInformation,
        },
        {
          id: 'occupation',
          title: 'Occupation',
          data: mapUserOccupation(policy.occupation),
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'email',
          title: 'Email',
          data: policy?.user?.email ?? '',
          component: TextWithDetailsInformation,
          type: 'TEXT_WITH_DETAILS',
          detailsTitle: 'Change account',
          detailsLabel: 'Change account',
          renderModalDetails: (setOpen, modalOpen) => (
            <ChangeAccount
              policyDetailsMutation={GET_PUBLIC_POLICY}
              setOpen={setOpen}
              modalOpen={modalOpen}
            />
          ),
        },
      ],
    },
  ];

  const statusSetToCoveredAt = policy.statusSetToCoveredAt
    ? new Date(policy.statusSetToCoveredAt)
    : '';

  const commissionEntitlementRowOptions: InformationRow[] = [
    ...(policy.commissionEntitlement === 'NONE' &&
    [
      'APPLICATION_RECEIVED',
      'APPLICATION_SENT',
      'PROCESSING',
      'ERR_MISSING_INFO',
      'UNI_CHECK',
    ].includes(policy.publicStatus)
      ? [
          {
            id: 'commission',
            title: 'Commission',
            data: 'Commission check not yet needed',
            type: 'TEXT' as const,
            component: TextInformation,
          },
        ]
      : []),
    ...(policy.commissionEntitlement === 'NONE' &&
    policy.publicStatus === 'COVERED' &&
    !policy.commissionId &&
    statusSetToCoveredAt >= commissionEntitlementCutoffDate
      ? [
          {
            id: 'commission',
            title: 'Commission',
            data: 'Awaiting commission check',
            type: 'TEXT' as const,
            component: TextInformation,
          },
        ]
      : []),
    ...(policy.commissionEntitlement === 'ACCEPTED'
      ? [
          {
            id: 'commission',
            title: 'Commission',
            data: 'Commission entitlement approved Policy activation confirmed',
            type: 'TEXT_WITH_CHECK' as const,
            check: 'check' as CheckBadgeType,
            component: TextWithCheckInformation,
          },
        ]
      : []),
    ...(policy.commissionEntitlement === 'DECLINED'
      ? [
          {
            id: 'commission',
            title: 'Commission',
            data: 'Commission entitlement declined',
            type: 'TEXT_WITH_CHECK' as const,
            check: 'cross' as CheckBadgeType,
            component: TextWithCheckInformation,
          },
        ]
      : []),
    ...((policy.commissionEntitlement === 'NONE' &&
      ['CANCELED', 'CANT_BE_COVERED', 'DROPPED_OUT'].includes(
        policy.publicStatus
      )) ||
    (policy.commissionEntitlement === 'NONE' &&
      policy.publicStatus === 'COVERED' &&
      (policy.commissionId ||
        statusSetToCoveredAt < commissionEntitlementCutoffDate))
      ? [
          {
            id: 'commission',
            title: 'Commission',
            data: 'No commission entitlement check needed',
            type: 'TEXT' as const,
            component: TextInformation,
          },
        ]
      : []),
  ];

  const policyInformation: InformationSection[] = [
    {
      title: 'Policy information',
      rows: [
        {
          id: 'provider',
          title: 'Provider',
          data: mapProviderBranch(policy.providerBranch) ?? policy.providerId,
          type: 'TEXT',
          component: TextInformation,
          editable: false,
        },
        {
          id: 'status',
          title: 'Status',
          data: policy.publicStatus,
          type: 'STATUS',
          component: StatusInformation,
          statusMapping: getInternalPublicHealthStatus,
          editable: false,
        },
        ...(policy.occupation === 'EMPLOYED'
          ? [
              {
                id: 'companyId',
                title: 'Company',
                data:
                  companies?.find(
                    (company) => company.id === policy.company?.id
                  )?.id ?? '',
                type: 'DROPDOWN' as const,
                component: ComboBoxInformation,
                dropdownOptions:
                  companies?.map(({ id, name }) => ({
                    id,
                    label: name,
                  })) ?? [],
                multipleOptions: false,
                placeholder: 'Select company',
                optionMapping: (companyId: string) =>
                  companies?.find((company) => company.id === companyId)
                    ?.name ?? policy.company?.name,
                editable: true,
                useExternalQuery: true,
                setExternalQuery: setCompaniesQuery,
              },
            ]
          : []),
        ...(policy.providerId === 'BARMER'
          ? [
              {
                id: 'agentId',
                title: 'Provider agent',
                data:
                  providerAgents.find(
                    (agent) => agent.label === policy.providerAgent?.email
                  )?.id ?? '',
                type: 'DROPDOWN' as const,
                component: ComboBoxInformation,
                dropdownOptions: providerAgents,
                multipleOptions: false,
                optionMapping: providerAgentMapping,
                editable: policy.providerId === 'BARMER',
                useExternalQuery: true,
                setExternalQuery: setProviderAgentsQuery,
                placeholder: 'Select provider agent',
              },
            ]
          : []),
        ...(policy.providerId === 'BARMER'
          ? commissionEntitlementRowOptions
          : []),
        ...(policy.providerId === 'TK'
          ? [
              {
                id: 'tkApiId',
                title: 'TK API ID',
                data: policy.tkApiId ?? '',
                type: 'TEXT' as const,
                component: TextInformation,
              },
            ]
          : []),
        {
          id: 'createdOn',
          title: 'Created on',
          data: policy.createdAt,
          type: 'DATE',
          component: DateInformation,
          editable: false,
        },
        {
          id: 'startDate',
          title: 'Start date',
          data: policy.startDate ?? '',
          type: 'DATE',
          component: DateInformation,
          editable: !(
            policy.publicStatus === 'CANCELED' ||
            policy.publicStatus === 'CANT_BE_COVERED' ||
            policy.publicStatus === 'DROPPED_OUT'
          ),
        },
        {
          id: 'statusSetToCoveredAt',
          title: 'Status set to covered at',
          data: policy.statusSetToCoveredAt ?? '',
          type: 'DATE',
          component: DateInformation,
          editable: false,
        },
        {
          id: 'svnr',
          title: 'SVNR',
          data: policy.user.svnr,
          type: 'TEXT',
          component: TextInformation,
          editable: true,
          placeholder: 'Enter SVNR number',
        },
        {
          id: 'kvnr',
          title: 'KVNR',
          data: policy.kvnr,
          type: 'TEXT',
          component: TextInformation,
          editable: !policy.kvnr,
          placeholder: 'Enter KVNR number',
        },
        {
          id: 'source',
          title: 'Source',
          data: policy.source,
          type: 'TEXT',
          component: TextInformation,
          editable: true,
          placeholder: 'Enter source',
        },
        {
          id: 'campaign',
          title: 'Campaign',
          data: policy?.campaign ?? '',
          component: TextInformation,
          type: 'TEXT',
          editable: true,
          placeholder: 'Enter campaign',
        },
        {
          id: 'medium',
          title: 'Medium',
          data: policy?.medium ?? '',
          component: TextInformation,
          type: 'TEXT',
          editable: true,
          placeholder: 'Enter medium',
        },
        {
          id: 'content',
          title: 'Content',
          type: 'TEXT',
          component: TextInformation,
          data: policy?.content ?? '',
          editable: true,
          placeholder: 'Enter content',
        },
        {
          id: 'term',
          title: 'Term',
          data: policy?.term ?? '',
          component: TextInformation,
          type: 'TEXT',
          editable: true,
          placeholder: 'Enter term',
        },
        {
          id: 'referralCode',
          title: 'Referral code',
          data: policy?.referral?.referralCode ?? '',
          type: policy?.referral?.status ? 'TEXT_WITH_DETAILS' : 'TEXT',
          detailsTitle: 'Add referral code',
          detailsLabel: 'Add referral code',
          component: TextWithDetailsInformation,
          renderModalDetails: (setOpen, modalOpen) => (
            <ReferralCode
              policyId={policy?.id ?? ''}
              userId={policy?.user?.id ?? ''}
              setOpen={setOpen}
              modalOpen={modalOpen}
            />
          ),
        },
      ],
    },
  ];

  const policyInformationEditableData = {
    startDate: policy.startDate ?? '',
    svnr: policy.user.svnr ?? '',
    kvnr: policy.kvnr ?? '',
    source: policy.source ?? '',
    campaign: policy?.campaign ?? '',
    medium: policy?.medium ?? '',
    content: policy?.content ?? '',
    term: policy?.term ?? '',
    agentId: policy.providerAgent?.id ?? '',
    companyId: policy.company?.id ?? '',
  };

  const shouldRenderBarmerCommissionEntitlementResetCard =
    policy.providerId === 'BARMER' &&
    ((policy.commissionEntitlement === 'ACCEPTED' &&
      policy.publicStatus !== 'COVERED') ||
      (policy.commissionEntitlement === 'DECLINED' &&
        policy.publicStatus === 'COVERED'));

  return (
    <>
      <div className="relative flex w-full flex-col">
        <CustomerDelinquencyBanner customer={policy.user} linkToCustomer />
        <CustomerFraudulentBanner customer={policy.user} />
        {policy?.archivedAt && (
          <FileErrorCard
            open
            hideCloseButton
            handleClose={() => {}}
            title="This policy is archived"
            errorType="WARNING"
            className="mb-[16px] mt-0"
          />
        )}
        <DuplicateHealthPoliciesCard
          policy={policy}
          insuranceType="PUBLIC_HEALTH"
        />
        {shouldRenderBarmerCommissionEntitlementResetCard && (
          <CommissionResetCard policy={policy} />
        )}
        <InformationCard sections={customerInformation} />
        <EditableInformationCard
          sections={policyInformation}
          editableData={policyInformationEditableData}
          resourceId={policy.id}
          mutation={UPDATE_POLICY_INFORMATION}
          ConfirmationModal={StartDateConfirmationModal}
          oldConfirmationValue={[
            {
              id: 'startDate',
              value: policy.startDate ?? '',
            },
          ]}
          successMessage="Your changes have been successfully saved"
        />
        {policy.occupation === 'UNIVERSITY_STUDENT' && (
          <StudentChecklistCard policy={policy} editable={true} />
        )}
        {policy.healthCard && <HealthCardSection policy={policy} />}
        {policy.occupation === 'EMPLOYED' && (
          <HRInformationCard policy={policy} />
        )}
      </div>
      <div className="w-full lg:ml-[20px] lg:w-auto">
        <SideSection policy={policy} />
      </div>
    </>
  );
};

export default OverviewTab;
