import { useMutation } from '@apollo/client';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import Button from 'components/Button/Button';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';
import { TariffInfo } from 'models/tariffInfo';
import { DELETE_TARIFF_INFO } from 'pages/policies/privateHealth/graphql/mutations';
import { GET_PRIVATE_POLICY } from 'pages/policies/privateHealth/graphql/queries';
import { getAddon } from 'pages/policies/privateHealth/utils/mapAddons';
import { getPrivateTariffName } from 'pages/policies/privateHealth/utils/mapTariffNames';

interface NewTariffInfoProps {
  tariffInfo: TariffInfo;
  policyId: string;
}

const NewTariffInfo = ({ tariffInfo, policyId }: NewTariffInfoProps) => {
  const [deleteTariff, { data, loading, error }] = useMutation(
    DELETE_TARIFF_INFO,
    { refetchQueries: [GET_PRIVATE_POLICY] }
  );

  const onClick = () => {
    deleteTariff({
      variables: { tariffInfoId: tariffInfo.id, policyId },
    }).catch(() => {});
  };

  const activeSickpayAddOn = tariffInfo.addOns?.some(
    (addOn) => addOn.add_on === 'KT'
  );

  return (
    <>
      <div className="flex flex-col space-y-4">
        <div className="flex">
          <span className="flex-1 font-bold">Start date</span>
          <span className="flex-1">
            {dayjs(tariffInfo.startDate).format(DATE_FORMAT)}
          </span>
        </div>
        <div className="flex">
          <span className="flex-1 font-bold">New tariff</span>
          <span className="flex-1">
            {getPrivateTariffName(tariffInfo.tariff)}
          </span>
        </div>
        <div className="flex">
          <span className="flex-1 font-bold">Deductible</span>
          <span className="flex-1">
            {englishFormattedEuroCurrency(Number(tariffInfo.deductible))}
          </span>
        </div>
        <div className="flex">
          <span className="flex-1 font-bold">Add-ons</span>
          <div className="flex-1">
            {tariffInfo.addOns?.map((addon) => {
              return <span>{getAddon(addon.add_on)}</span>;
            })}
          </div>
        </div>
        {activeSickpayAddOn && (
          <div className="flex">
            <span className="flex-1 font-bold">Sick day payout amount</span>
            <span className="flex-1">
              {englishFormattedEuroCurrency(Number(tariffInfo.sickDayPayout))}
            </span>
          </div>
        )}
      </div>
      <div className="mt-6 flex justify-end">
        <Button
          buttonType="primary"
          className="!h-10 w-36"
          onClick={onClick}
          loading={loading}
          disabled={!!data && !error}
          loadingText="Deleting..."
        >
          {!!data && !error ? 'Deleted!' : 'Delete request'}
        </Button>
      </div>
    </>
  );
};

export default NewTariffInfo;
