import { TFunction } from '@getpopsure/i18n-react';
import { policyMappers } from 'shared/insurances';

const companyHealthStatusNameMapper = (
  t: TFunction
): Record<string, string> => ({
  PENDING: t('admin.hr.companyHealth.status.pending.label', 'Pending'),
  ACTIVE: t('admin.hr.companyHealth.status.covered.label', 'Covered'),
  CANCELED: t('admin.hr.companyHealth.status.canceled.label', 'Canceled'),
  DROPPED_OUT: t(
    'admin.hr.companyHealth.status.droppedOut.label',
    'Dropped out'
  ),
});

export const companyHealthStatusMapper =
  (t: TFunction) => (status: string) => ({
    text: {
      id: status,
      text: companyHealthStatusNameMapper(t)[status],
    },
    color: policyMappers.COMPANY_PENSION?.badgeColorMapper[status] ?? 'gray',
  });
