import { useTranslation } from '@getpopsure/i18n-react';
import ActivityCards, {
  Activity,
} from 'components/ActivityCards/ActivityCards';
import Card from 'components/Card';
import CardSection from 'components/Card/CardSection';
import Link from 'components/Link';
import { Status } from 'models/statusColor';

interface ActivitySectionProps {
  activity: Activity[];
  policyId: string;
  statusMapping: (status: string) => Status;
}

const ActivitySection = ({
  activity,
  policyId,
  statusMapping,
}: ActivitySectionProps) => {
  const { t } = useTranslation();
  return (
    <Card
      title={t(
        'admin.provider.policy.public.recentactivity.title',
        'Recent Activity'
      )}
      boldTitle={false}
    >
      <CardSection className="w-[350px] !items-center !justify-center">
        <ActivityCards
          policyId={policyId}
          smallVersion
          activity={activity.slice(0, 3)}
          statusMapping={statusMapping}
          showCustomerInformed={false}
        />
        <Link
          text={t(
            'admin.provider.policy.public.recentactivity.viewall.action',
            'View all'
          )}
          color="secondary"
          href="#activity"
          className="font-bold"
        />
      </CardSection>
    </Card>
  );
};
export default ActivitySection;
