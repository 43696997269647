import { useEffect, useRef } from 'react';

type RefElement = HTMLInputElement & HTMLButtonElement;

export const useOutsideClick = (callback: () => void) => {
  const ref = useRef<RefElement>(null);

  useEffect(() => {
    const handleClick = (e: any) => {
      e.stopPropagation();

      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref]);

  return ref;
};
