import { useTranslation } from '@getpopsure/i18n-react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import Modal from 'components/Modal';
import dayjs from 'dayjs';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { DATE_FORMAT_TIME } from 'models/date';
import { useState } from 'react';

import * as styles from './styles';

interface Props {
  isDisabled: boolean;

  onEdit: () => void;
  onDelete: () => void;

  authorName: string;
  createdAt: string;

  deleteNoteLoading: boolean;
}

export const ActionsButton = ({
  isDisabled,
  onEdit,
  onDelete,
  authorName,
  createdAt,
  deleteNoteLoading,
}: Props) => {
  const { t } = useTranslation();

  const [isActionsPopupOpen, setIsActionsPopupOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const actionsButtonRef = useOutsideClick(() => setIsActionsPopupOpen(false));

  return (
    <>
      <Modal
        open={isModalOpen}
        title={t('notes.notesCard.modal.delete.title', 'Delete note?')}
        setOpen={setIsModalOpen}
        confirmButtonLabel={t('notes.notesCard.modal.delete.cta', 'Delete')}
        handleConfirm={() => {
          onDelete();
        }}
        loading={deleteNoteLoading}
      >
        <div className="text-sm text-gray-600">
          <p>
            Do you really want to delete this note added by {authorName} at{' '}
            {dayjs(createdAt).format(DATE_FORMAT_TIME)}?
          </p>
          <p>
            This process <span className="font-bold">cannot be undone</span>.
          </p>
        </div>
      </Modal>
      <div className={styles.dotIconContainer}>
        <button
          className={styles.dotIconButton}
          type="button"
          onClick={() => setIsActionsPopupOpen(!isActionsPopupOpen)}
          ref={actionsButtonRef}
          data-testid="dot-icon-button"
        >
          <DotsVerticalIcon className={styles.dotIcon} />
        </button>
        {isActionsPopupOpen && (
          <div className={styles.dropdown}>
            <button
              className={classNames(styles.dropdownButtonGray, {
                [styles.disabledDropdownButton]: isDisabled,
              })}
              type="button"
              disabled={isDisabled}
              onClick={onEdit}
            >
              {t('notes.notesCard.edit.cta', 'Edit')}
            </button>
            <button
              className={classNames(styles.dropdownButtonRed, {
                [styles.disabledDropdownButton]: isDisabled,
              })}
              type="button"
              disabled={isDisabled}
              onClick={() => setIsModalOpen(true)}
              data-testid="delete-button"
            >
              {t('notes.notesCard.delete.cta', 'Delete')}
            </button>
          </div>
        )}
      </div>
    </>
  );
};
