import { useQuery } from '@apollo/client';
import { User } from 'models/User';
import { generatePath, useParams } from 'react-router-dom';
import { intercomUrl as incompleteIntercomPath } from 'shared/intercomUrl';
import { stripeUrl as incompleteStripeUrl } from 'shared/stripeUrl';
import { setQueryToUrl } from 'shared/utils/setQueryToUrl';

import { CustomerDetailsView } from './CustomerDetails.view';
import { GET_CUSTOMER } from './graphql/queries';

export const CustomerDetails = () => {
  const { id: userId } = useParams<{ id?: string }>();

  const { loading, error, data } = useQuery<{
    customer: User;
  }>(GET_CUSTOMER, {
    variables: {
      userId,
    },
  });

  if (!userId) {
    throw new Error('[Customer] No user id found to find customer info');
  }

  const intercomId = process.env.REACT_APP_INTERCOM_EU_ID;
  const intercomUserId = data?.customer.intercomEUId;
  const token = data?.customer.stripeToken;
  const email = data?.customer.email;
  const pinnedNotes = data?.customer.pinnedNotes;

  const intercomUrl =
    intercomId && intercomUserId
      ? generatePath<string>(incompleteIntercomPath, {
          intercomId,
          userId: intercomUserId,
        })
      : undefined;

  const stripeUrl = token
    ? generatePath<string>(incompleteStripeUrl, {
        token,
      })
    : undefined;

  const stripeVisibilityUrl = userId
    ? setQueryToUrl(
        'https://data.feather-insurance.com/dashboard/216-stripe-scheduler-visibility',
        [{ key: 'user_id', value: userId }]
      )
    : undefined;

  const customerIoWorkspace =
    process.env.REACT_APP_DEPLOY_ENV === 'production' ? '114051' : '114300';

  const customerIoUrl = email
    ? setQueryToUrl(
        `https://fly.customer.io/workspaces/${customerIoWorkspace}/journeys/people`,
        [{ key: 'email', value: email }]
      )
    : undefined;

  const customerJourneyUrl = userId
    ? setQueryToUrl(
        'https://data.feather-insurance.com/dashboard/341-admin-panel-customer-journey-visibility',
        [{ key: 'user_id', value: userId }]
      )
    : undefined;

  return (
    <CustomerDetailsView
      intercomUrl={intercomUrl}
      stripeUrl={stripeUrl}
      userId={userId}
      customer={data?.customer}
      customerLoading={loading}
      policies={data?.customer.policies}
      paymentMethods={data?.customer.paymentMethods}
      pinnedNotes={pinnedNotes}
      error={error}
      stripeVisibilityUrl={stripeVisibilityUrl}
      customerIoUrl={customerIoUrl}
      customerJourneyUrl={customerJourneyUrl}
    />
  );
};
