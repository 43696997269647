import { TFunction } from '@getpopsure/i18n-react';
import { RowConfig } from 'components/TableRefactor/types';
import { retrieveHRPoliciesDetailsPath } from 'pages/policies/template/utils';

import { CompanyLifePolicy } from '../models/CompanyLifePolicy';
import { companyLifeStatusMapper } from '../utils';

export const getRowConfig = (t: TFunction, company: string): RowConfig => ({
  getResourceLink: (data: CompanyLifePolicy) =>
    retrieveHRPoliciesDetailsPath({
      insuranceType: 'company-life',
      id: data.id,
      company,
      country: 'de',
    }),
  cells: [
    {
      type: 'TEXT',
      textColor: 'dark',
      props: {
        getText: (data: CompanyLifePolicy) => data.user?.firstName ?? '',
      },
    },
    {
      type: 'TEXT',
      textColor: 'dark',
      props: {
        getText: (data: CompanyLifePolicy) => data.user?.lastName ?? '',
      },
    },
    {
      type: 'STATUS',
      textColor: 'light',
      props: {
        getStatus: (data: CompanyLifePolicy) =>
          companyLifeStatusMapper(t)(data.publicStatus),
      },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: {
        getText: (data: CompanyLifePolicy) =>
          data.lifeDetails?.employerContribution ?? '',
      },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: {
        getText: (data: CompanyLifePolicy) =>
          data.lifeDetails?.lumpSumCoverage ?? '',
      },
    },
    {
      type: 'DATE',
      textColor: 'light',
      props: {
        getDate: (data: CompanyLifePolicy) => data.startDate ?? '',
      },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: {
        getText: (data: CompanyLifePolicy) => data.policyNumber ?? '',
      },
    },
  ],
});
