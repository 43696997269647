import { FileError } from 'react-dropzone';

export const fileSizeValidator = (file: File): FileError | null => {
  if (file.size > 20000000) {
    return {
      code: 'file-too-large',
      message: 'This file is over 20MB.',
    };
  }
  return null;
};
