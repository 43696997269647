import { useMutation } from '@apollo/client';
import classnames from 'classnames';
import Button from 'components/Button';
import CalendarDatePicker from 'components/CalendarDatePicker';
import FileErrorCard from 'components/FileErrorCard';
import Modal from 'components/Modal';
import { PrivateHealthPolicy } from 'models/privateHealthPolicy';
import { ACTIVATE_PRIVATE_POLICY } from 'pages/scannedDocuments/scannedDocuments.mutations';
import {
  GET_PRIVATE_POLICY_BY_NAME,
  GET_SCANNED_DOCUMENT_BY_ID,
} from 'pages/scannedDocuments/scannedDocuments.queries';
import { ScannedDocument } from 'pages/scannedDocuments/scannedDocuments.types';
import { getPolicyDetailsBaseURLFromInsuranceType } from 'pages/scannedDocuments/scannedDocuments.utils';
import { useState } from 'react';
import { insuranceIconMapper } from 'shared/insurances/insuranceIconMapper';
import { insuranceNameMapper } from 'shared/insurances/insuranceNameMapper';

import * as styles from './ScannedDocument.ActivatePrivatePolicy.styles';

export const ActivatePrivatePolicy = ({
  scannedDocument,
  policyToActivate,
}: {
  scannedDocument: ScannedDocument;
  policyToActivate?: PrivateHealthPolicy;
}) => {
  const defaultStartDate = policyToActivate?.questionnaire?.answers
    ?.personalInfo?.coverageStartDate
    ? new Date(
        policyToActivate?.questionnaire?.answers?.personalInfo?.coverageStartDate
      )
    : undefined;

  const [startDate, setStartDate] = useState<Date | undefined>(
    defaultStartDate
  );
  const { policyNumber } = scannedDocument;

  const [showModal, setShowModal] = useState(false);

  const [activatePrivatePolicy, { loading }] = useMutation(
    ACTIVATE_PRIVATE_POLICY,
    {
      variables: {
        scannedDocumentId: scannedDocument.id,
        userPolicyId: policyToActivate?.id,
        policyNumber: scannedDocument.policyNumber,
        startDate: startDate?.toISOString(),
      },
      refetchQueries: [GET_SCANNED_DOCUMENT_BY_ID, GET_PRIVATE_POLICY_BY_NAME],
    }
  );

  const handleConfirm = async () => {
    await activatePrivatePolicy();
    setShowModal(false);
  };

  const confirmationRequired =
    policyToActivate && !scannedDocument.userPolicyId;

  const handleDateChange = (date: Date | [Date, Date] | null) => {
    if (!date || Array.isArray(date)) return;

    setStartDate(date);
  };

  return (
    <>
      {showModal && (
        <Modal
          title="Activate private policy?"
          open={showModal}
          setOpen={setShowModal}
          confirmButtonLabel="Activate"
          handleConfirm={handleConfirm}
        >
          <div className="flex flex-col">
            {confirmationRequired && (
              <FileErrorCard
                open={true}
                className="mb-5 mt-5"
                title="Unconfirmed relation"
                hideCloseButton={true}
                handleClose={() => {}}
                errorType="WARNING"
                description="Accepting this action will also confirm that below policy belongs to this document."
              />
            )}
            <p className={classnames(styles.label, 'mt-5')}>
              Policy to activate
            </p>
            <div className="mt-3 flex items-center">
              <a
                href={
                  getPolicyDetailsBaseURLFromInsuranceType(
                    policyToActivate?.insuranceType,
                    policyToActivate?.id
                  ) ?? ''
                }
                target="_blank"
                rel="noreferrer"
                className={styles.policyCard}
              >
                {policyToActivate?.insuranceType && (
                  <img
                    className={styles.policyIcon}
                    src={insuranceIconMapper[policyToActivate.insuranceType]}
                    alt=""
                  />
                )}
                <p className="flex flex-col">
                  <span className={styles.policyType}>
                    {policyToActivate?.insuranceType &&
                      insuranceNameMapper[policyToActivate?.insuranceType]}
                  </span>
                  <span className={styles.policyNumber}>
                    {`${policyToActivate?.questionnaire?.answers?.personalInfo?.name?.firstName} ${policyToActivate?.questionnaire?.answers?.personalInfo?.name?.lastName}`}
                  </span>
                </p>
              </a>
            </div>
            <p className={classnames(styles.label, 'mb-2 mt-7')}>Start date</p>
            <p className={styles.text}>
              Unless modified, this is the date picked by the customer in the
              questionnaire. You can also update this date later.
            </p>
            <CalendarDatePicker
              onChange={(date) => handleDateChange(date)}
              selectedDate={startDate}
              className={styles.calendarWrapper}
            />
            <p className={classnames(styles.label, 'mb-2 mt-7')}>
              Policy number
            </p>
            <p className={styles.policyNumber}>{policyNumber}</p>
            <p className={classnames(styles.text, 'mt-5 font-bold')}>
              Confirming this action will add above policy number, set the start
              date, activate the policy and generate the confirmation of
              coverage document.
            </p>
          </div>
        </Modal>
      )}
      <Button
        loading={loading}
        buttonType="primary"
        className="flex gap-2"
        onClick={() => setShowModal(true)}
      >
        <span className="flex gap-2">
          <span>Activate policy</span>
        </span>
      </Button>
    </>
  );
};
