import classNames from 'classnames';

export const cardListRow = classNames('pt-[8px]', 'flex', 'items-center');

export const rowLabel = classNames(
  'text-sm',
  'text-gray-600',
  'w-[180px]',
  'mr-[16px]'
);

export const rowsWrapper = classNames('mt-[8px]', 'w-full');

export const input = classNames('w-[264px]', 'px-[12px]', 'py-[9px]');
