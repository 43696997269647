import classNames from 'classnames';
import CalendarDatePicker from 'components/CalendarDatePicker';
import Input from 'components/Input';
import Modal from 'components/Modal';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import dayjs from 'dayjs';

import { customerFormData } from './data';
import {
  SubmittableCustomer,
  SubmittableCustomerValidationError,
} from './models';
import * as styles from './styles';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onChange: (input: Partial<SubmittableCustomer>) => void;
  answers: Partial<SubmittableCustomer>;
  onCreateCustomer: () => void;
  submissionDisabled: boolean;
  validationError: Partial<SubmittableCustomerValidationError> | null;
  loading: boolean;
}

export const CreateCustomerModalView = ({
  isOpen,
  setIsOpen,
  onChange,
  answers,
  onCreateCustomer,
  submissionDisabled,
  validationError,
  loading,
}: Props) => (
  <Modal
    title="Create new customer"
    open={isOpen}
    setOpen={setIsOpen}
    handleConfirm={onCreateCustomer}
    confirmButtonLabel="Create"
    disabled={submissionDisabled}
    loading={loading}
  >
    <>
      {customerFormData.map(
        ({ id, label, required, color, renderDetails, errorId }) => {
          const { type } = renderDetails;
          const answer = answers[id];

          return (
            <div className={styles.inputContainer} key={id}>
              <label className={styles.label} htmlFor={id}>
                <div className={styles.labelTitle}>{label}</div>
                {!required && (
                  <span className={styles.labelOptional}>(optional)</span>
                )}
              </label>

              {(type === 'text' || type === 'email') && (
                <Input
                  className={styles.input}
                  id={id}
                  type={type}
                  required={required}
                  placeholder={renderDetails.placeholder}
                  color={color}
                  onChange={(e) => {
                    onChange({ ...answers, [id]: e.target.value });
                  }}
                  value={answer ?? ''}
                  error={!!(errorId && validationError?.[errorId])}
                  errorMessage={errorId && validationError?.[errorId]}
                />
              )}

              {type === 'select' && (
                <SelectMenu
                  className={styles.input}
                  selected={renderDetails.options.find(
                    ({ id: optionId }) => optionId === answer
                  )}
                  setSelected={({ id: optionId }: SelectMenuOption) => {
                    onChange({ ...answers, [id]: optionId });
                  }}
                  placeholder={renderDetails.placeholder}
                  options={renderDetails.options}
                  color="gray"
                />
              )}

              {type === 'date' && (
                <CalendarDatePicker
                  className={classNames(styles.input, styles.dateInput)}
                  selectedDate={answer ? new Date(answer) : undefined}
                  onChange={(date) => {
                    onChange({
                      ...answers,
                      [id]: dayjs(date as Date).format('YYYY-MM-DD'),
                    });
                  }}
                  maxDate={new Date()}
                />
              )}
            </div>
          );
        }
      )}
    </>
  </Modal>
);
