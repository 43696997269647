import classNames from 'classnames';

export const container = classNames('flex');

export const title = classNames('text-lg');

export const count = classNames(
  'ml-[8px]',
  'bg-gray-300',
  'text-xs',
  'rounded-[10px]',
  'flex',
  'self-center',
  'justify-center',
  'items-center',
  'text-gray-700',
  'py-[2px]',
  'px-[10px]'
);
