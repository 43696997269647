import { useTranslation } from '@getpopsure/i18n-react';

import * as styles from '../styles';

export const DetailsButton = ({ detailsUrl }: { detailsUrl: string }) => {
  const { t } = useTranslation();
  return (
    <a
      className={styles.dropdownOption}
      target="_blank"
      href={detailsUrl}
      rel="noreferrer"
    >
      {t('admin.documents.action.details.label', 'See details')}
    </a>
  );
};
