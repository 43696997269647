import { gql } from '@apollo/client';

export const GET_LIFE_POLICIES = gql`
  query GetLifePolicies(
    $offset: Float!
    $limit: Float!
    $searchString: String!
    $sortColumn: String!
    $sortOrder: String!
    $filterStatus: [String]
    $filterPlan: [String]
    $filterCreatedAt: [DateTime]
    $filterStartDate: [DateTime]
    $filterArchivedAt: [DateTime]
  ) {
    lifePolicies(
      offset: $offset
      limit: $limit
      searchString: $searchString
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      filterStatus: $filterStatus
      filterPlan: $filterPlan
      filterCreatedAt: $filterCreatedAt
      filterStartDate: $filterStartDate
      filterArchivedAt: $filterArchivedAt
    ) {
      id
      policyNumber
      publicStatus
      startDate
      createdAt
      archivedAt
      source

      # Insured person
      insuredPerson {
        firstName
        lastName
      }

      # User
      user {
        id
        firstName
        lastName
      }
    }
    totalLifePolicies(
      searchString: $searchString
      filterStatus: $filterStatus
      filterPlan: $filterPlan
      filterCreatedAt: $filterCreatedAt
      filterStartDate: $filterStartDate
      filterArchivedAt: $filterArchivedAt
    )
  }
`;
