import { User } from 'models/User';

import { CustomerInfoView } from './CustomerInfo.view';

interface Props {
  loading: boolean;
  customer?: User;
}

export const CustomerInfo = ({ loading, customer }: Props) => {
  return <CustomerInfoView loading={loading} customer={customer} />;
};
