import { FetchResult, MutationFunctionOptions } from '@apollo/client';
import FileErrorCard from 'components/FileErrorCard';
import Link from 'components/Link';
import Modal from 'components/Modal';
import dayjs from 'dayjs';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';

import { DataRemovalRequest } from '../models';

interface DataRemovalRequestDetailsProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  request?: DataRemovalRequest;
  handleRequest: (options?: MutationFunctionOptions) => Promise<FetchResult>;
}

const DataRemovalRequestDetails = ({
  open,
  setOpen,
  request,
  handleRequest,
}: DataRemovalRequestDetailsProps) => {
  let status = '';
  if (request?.status === 'SUCCESS') {
    status = 'Success';
  }
  if (request?.status === 'PENDING') {
    status = 'Not completed';
  }
  if (request?.status === 'PENDING' && request.premoderationChecks.length > 0) {
    status = 'Failed';
  }
  return (
    <Modal
      title="Data removal request"
      open={open}
      setOpen={setOpen}
      hideActions
    >
      <div className="mt-2 flex-col space-y-4 text-sm text-gray-900">
        {status === 'Failed' && (
          <FileErrorCard
            open={true}
            title="Data deletion fails"
            errorType="ERROR"
            showActionButtons
            description={request?.premoderationChecks.map((check) => {
              return <li className="mt-2 list-disc space-y-2">{check}</li>;
            })}
            primaryButton={{
              text: 'Delete',
              onClick: () => {
                handleRequest({ variables: { requestId: request?.id } });
              },
            }}
          />
        )}
        {status === 'Not completed' && (
          <FileErrorCard
            open={true}
            title="Data deletion not completed"
            handleClose={() => {}}
            errorType="WARNING"
            hideCloseButton
            showActionButtons
            description={
              <>
                {!request?.dbRecordsDeletedAt && (
                  <li className="mt-2 list-disc space-y-2">
                    Database records haven&apos;t been deleted yet
                  </li>
                )}
                {!request?.documentsDeletedAt && (
                  <li className="mt-2 list-disc space-y-2">
                    Documents haven&apos;t been deleted yet
                  </li>
                )}
                {!request?.intercomRecordsDeletedAt && (
                  <li className="mt-2 list-disc space-y-2">
                    Intercom records haven&apos;t been deleted yet
                  </li>
                )}
                {!request?.stripeRecordsDeletedAt && (
                  <li className="mt-2 list-disc space-y-2">
                    Stripe records haven&apos;t been deleted yet
                  </li>
                )}
                {!request?.customerIoRecordsSuppressedAt && (
                  <li className="mt-2 list-disc space-y-2">
                    Customer.io records haven&apos;t been deleted yet
                  </li>
                )}
              </>
            }
            primaryButton={{
              text: 'Delete',
              onClick: () => {
                handleRequest({ variables: { requestId: request?.id } });
              },
            }}
          />
        )}
        <div className="flex items-start justify-between">
          <span className="font-bold">Customer</span>
          <span className="w-[244px]">
            {request?.user &&
            request.user.firstName &&
            request.user.lastName ? (
              <Link
                color="primary"
                href={generatePath(paths.customers.details.path, {
                  id: request.user.id,
                })}
                text={`${request?.user?.firstName} ${request.user.lastName}`}
              />
            ) : (
              ''
            )}
          </span>
        </div>
        <div className="flex items-start justify-between">
          <span className="font-bold">Customer id</span>
          <span className="w-[244px]">
            {request?.user ? request.user.id : ''}
          </span>
        </div>
        <div className="flex items-start justify-between">
          <span className="font-bold">Email</span>
          <span className="w-[244px]">{request?.email}</span>
        </div>
        <div className="my-[24px] w-full border-t border-gray-300" />
        <div className="flex items-start justify-between">
          <span className="font-bold">Status</span>
          <span className="w-[244px]">{status}</span>
        </div>
        <div className="flex items-start justify-between">
          <span className="font-bold">Database deleted on</span>
          <span className="w-[244px]">
            {request?.dbRecordsDeletedAt &&
              dayjs(request?.dbRecordsDeletedAt).format('DD MMM YYYY HH:mm:ss')}
          </span>
        </div>
        <div className="flex items-start justify-between">
          <span className="font-bold">Documents deleted on</span>
          <span className="w-[244px]">
            {request?.dbRecordsDeletedAt &&
              dayjs(request?.dbRecordsDeletedAt).format('DD MMM YYYY HH:mm:ss')}
          </span>
        </div>
        <div className="flex items-start justify-between">
          <span className="font-bold">Stripe subs. deleted on</span>
          <span className="w-[244px]">
            {request?.documentsDeletedAt &&
              dayjs(request?.documentsDeletedAt).format('DD MMM YYYY HH:mm:ss')}
          </span>
        </div>
        <div className="flex items-start justify-between">
          <span className="font-bold">Intercom deleted on</span>
          <span className="w-[244px]">
            {request?.intercomRecordsDeletedAt &&
              dayjs(request?.intercomRecordsDeletedAt).format(
                'DD MMM YYYY HH:mm:ss'
              )}
          </span>
        </div>
        <div className="flex items-start justify-between">
          <span className="font-bold">Customer.io deleted on</span>
          <span className="w-[244px]">
            {request?.customerIoRecordsSuppressedAt &&
              dayjs(request?.customerIoRecordsSuppressedAt).format(
                'DD MMM YYYY HH:mm:ss'
              )}
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default DataRemovalRequestDetails;
