export const getAddon = (addOn: string) => {
  switch (addOn) {
    case 'FLEX':
      return 'Joker flex';
    case 'URZ':
      return 'Holiday travel cover';
    case 'KT':
      return 'Sickpay';
    case 'HD':
      return 'Standard dental coverage';
    case 'HDL':
      return 'Premium dental coverage';
    case 'NKSFLEX':
      return 'Flex';
    default:
      return addOn;
  }
};

export const getAddonsForTariff = (tariff: string, tariffAddons: string[]) => {
  const additionalAddons = tariffAddons.filter(
    (addon) => !['FLEX', 'URZ', 'KT', 'HD', 'HDL', 'NKSFLEX'].includes(addon)
  );
  switch (tariff) {
    case 'PRIMO':
    case 'Primo':
    case 'PRIMO_PLUS':
    case 'PrimoPlus':
    case 'KS':
    case 'NK':
      return ['KT', 'URZ', 'FLEX', ...additionalAddons];
    case 'NK_SELECT_XL':
    case 'NKSelectXL':
    case 'NK_SELECT_L':
    case 'NKSelectL':
    case 'NK_SELECT_S':
    case 'NKSelectS':
      return ['KT', 'URZ', 'NKSFLEX', ...additionalAddons];
    case 'HI_MEDICAL':
    case 'HiMedical':
    case 'HI_MEDICAL_PLUS':
    case 'HiMedicalPlus':
      return ['KT', 'URZ', 'HD', 'HDL', ...additionalAddons];
    case 'NLTN.':
      return [];
    default:
      return additionalAddons;
  }
};
