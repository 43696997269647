import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { ConfirmationModalProps } from 'components/InformationCard/EditableInformationCard';
import Modal from 'components/Modal';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';
import { getAddon } from 'pages/policies/privateHealth/utils/mapAddons';

import * as styles from './styles';

const ConfirmationModal = ({
  open,
  setOpen,
  handleConfirm,
  loading,
  newFormData,
  additionalData,
  oldValues,
}: ConfirmationModalProps) => {
  const hasUpdatedStartDate =
    oldValues?.find((value) => value.id === 'startDate')?.value !==
    newFormData.startDate;

  const descriptionEmployed = hasUpdatedStartDate
    ? 'Changes in tariff will be effective after the new tariff start date. Confirmation of coverage and letter for employer will be regenerated upon confirmation.'
    : 'Changes in tariff will be effective after the new tariff start date. Letter for employer will be regenerated with new tariff information';

  const descriptionNotEmployed = hasUpdatedStartDate
    ? 'Changes will be effective after the new tariff start date. The confirmation of coverage will be regenerated upon confirmation.'
    : 'Changes will be effective after the new tariff start date.';

  const descriptionWhenActive =
    additionalData?.occupation === 'EMPLOYED'
      ? descriptionEmployed
      : descriptionNotEmployed;

  const description =
    additionalData?.status === 'ACTIVE'
      ? descriptionWhenActive
      : 'To renew the application document, please make sure to apply the same changes in the questionnaire.';

  const newAddons = Array.isArray(newFormData.addOns)
    ? newFormData.addOns.map((addon) => getAddon(addon)).join(', ')
    : '';

  const activeSickpayAddOn = Array.isArray(newFormData.addOns)
    ? newFormData.addOns?.some((addOn) => addOn === 'KT')
    : false;

  return (
    <Modal
      title="Save changes?"
      open={open}
      setOpen={setOpen}
      handleConfirm={handleConfirm}
      loading={loading}
    >
      <>
        <div className={styles.wrapper}>
          {hasUpdatedStartDate && (
            <div>
              <span className={styles.title}>New start date</span>
              <span className={styles.text}>
                {dayjs(newFormData.startDate?.toString()).format(DATE_FORMAT)}
              </span>
            </div>
          )}
          {oldValues?.find((value) => value.id === 'tariffStartDate')?.value !==
            newFormData.tariffStartDate && (
            <div>
              <span className={styles.title}>Tariff start date</span>
              <span className={styles.text}>
                {dayjs(newFormData.tariffStartDate?.toString()).format(
                  DATE_FORMAT
                )}
              </span>
            </div>
          )}
          {oldValues?.find((value) => value.id === 'tariff')?.value !==
            newFormData.tariff && (
            <div>
              <span className={styles.title}>New tariff</span>
              <span className={styles.text}>
                {newFormData.tariff?.toString()}
              </span>
            </div>
          )}
          {oldValues?.find((value) => value.id === 'deductible')?.value !==
            newFormData.deductible && (
            <div>
              <span className={styles.title}>Deductible</span>
              <span className={styles.text}>
                {englishFormattedEuroCurrency(Number(newFormData?.deductible))}
              </span>
            </div>
          )}
          {oldValues?.find((value) => value.id === 'addOns')?.value !==
            newAddons && (
            <div>
              <span className={styles.title}>Add-ons</span>
              <span className={styles.text}>
                {Array.isArray(newFormData.addOns)
                  ? newFormData.addOns
                      .map((addon) => getAddon(addon))
                      .join(', ')
                  : ''}
              </span>
            </div>
          )}
          {oldValues?.find((value) => value.id === 'sickDayPayout')?.value !==
            newFormData.sickDayPayout &&
            activeSickpayAddOn && (
              <div>
                <span className={styles.title}>Sick day payout amount</span>
                <span className={styles.text}>
                  {String(newFormData.sickDayPayout)}
                </span>
              </div>
            )}
        </div>
        <span className={styles.additionalText}>{description}</span>
      </>
    </Modal>
  );
};

export default ConfirmationModal;
