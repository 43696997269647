import { useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import Input from 'components/Input';
import LoadingState from 'components/LoadingState';
import Logo from 'components/Logo';
import LogoSmall from 'components/LogoSmall';
import useLogin, { AuthValues } from 'hooks/useLogin';
import PageGenericError from 'pages/errors/genericError';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import * as styles from './styles';

interface StateType {
  fromLogout: boolean;
  googleLoginFailure: boolean;
}

const Login = () => {
  const { t } = useTranslation();

  const [form, setForm] = useState<AuthValues>({
    email: '',
    password: '',
  });
  const { login, loginGoogle, loading, error } = useLogin();
  const { state } = useLocation<StateType>();
  const history = useHistory();

  const alertBannersState = useReactiveVar(alertBanners);

  useEffect(() => {
    if (state?.fromLogout) {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message: t(
          'admin.alert.success.loggedout.header',
          "You've been successfully logged out"
        ),
        onClose: () => history.replace({}),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    }

    if (state?.googleLoginFailure) {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: t(
          'admin.alert.warning.google..login.failure',
          "This user doesn't have access to this admin panel or Google Authentication enabled."
        ),
        onClose: () => history.replace({}),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    }
  }, [state]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    window.history.replaceState(null, '');
    await login(form);
  };

  const handleSubmitLoginGoogle = async () => {
    await loginGoogle();
  };

  if (error && error.errorStatus !== 401) return <PageGenericError />;

  if (loading) {
    return (
      <LoadingState
        message={t('admin.signin.signingin.title', 'Signing in...')}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Login - Feather Admin Panel</title>
      </Helmet>
      <div className={styles.login}>
        <div className={styles.header}>
          <Logo className={styles.logo} color="dark" />
          <h2 className={styles.title}>
            {t('admin.signin.header', 'Sign in to your account')}
          </h2>
        </div>

        <div className={styles.loginCard}>
          <form onSubmit={handleSubmit}>
            <div className={styles.emailInput}>
              <label htmlFor="email" className={styles.inputLabel}>
                {t('admin.signin.emailadress.title', 'Email address')}
              </label>
              <Input
                id="email"
                type="email"
                autoComplete="email"
                required={true}
                placeholder={t(
                  'admin.signin.emailadress.placeholder',
                  'you@example.com'
                )}
                onChange={handleChange}
                error={error !== null}
                color="gray"
              />
            </div>

            <div className={styles.passwordInput}>
              <label htmlFor="password" className={styles.inputLabel}>
                {t('admin.signin.password.title', 'Password')}
              </label>
              <Input
                id="password"
                type="password"
                autoComplete="current-password"
                required={true}
                placeholder={t(
                  'admin.signin.password.placeholder',
                  'Enter your password here'
                )}
                onChange={handleChange}
                error={error !== null}
                color="gray"
              />
            </div>

            <div>
              <Button
                type="submit"
                buttonType="primary"
                className={styles.submitButton}
              >
                {loading
                  ? t('admin.signin.loading.label', 'Loading...')
                  : t('admin.signin.signin.action', 'Sign in')}
              </Button>
              {error && <p className={styles.errorMessage}>{error.message}</p>}
            </div>
            <div className={styles.divider}>
              <div className={styles.dividerLineWrapper} aria-hidden="true">
                <div className={styles.dividerLine} />
              </div>
              <div className={styles.dividerTextWrapper}>
                <span className={styles.dividerText}>
                  {t('admin.signin.continue.label', 'Or continue with')}
                </span>
              </div>
            </div>
            <div>
              <Button
                type="button"
                buttonType="secondary"
                onClick={handleSubmitLoginGoogle}
                className="w-full"
                Icon={LogoSmall}
              >
                {t(
                  'admin.signin.login.feather.action',
                  'Login as a Feather employee'
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
