import { OperationVariables } from '@apollo/client';
import { RecentActivity } from 'components/ActivityLog/RecentActivity';
import {
  DocumentSection,
  DocumentsSection,
} from 'components/DocumentsSection/DocumentsSection';
import { PinnedNotes } from 'components/Notes/PinnedNotes';
import { SelectMenuOption } from 'components/SelectMenu';
import { DocumentNode } from 'graphql';
import { Note } from 'models/Note';
import { Policy } from 'models/policy';
import { PolicyActivity } from 'models/policyActivity';
import { EditButton } from 'pages/policies/template/EditButton/EditButton';
import { GET_POLICY_ACTIVITY } from 'shared/activityLog/queries';

import { PolicyTemplateInsurancePath } from '../models/InsuranceTypes';
import {
  ARCHIVE_POLICY_DOCUMENT,
  DELETE_POLICY_DOCUMENT,
  EDIT_POLICY_DOCUMENT,
  GENERATE_POLICY_DOCUMENTS_ARCHIVE,
  GET_POLICY_DOCUMENTS_ARCHIVE,
  RESTORE_POLICY_DOCUMENT,
  UPLOAD_POLICY_DOCUMENT,
} from '../mutations';
import { OtherPoliciesCard } from '../OtherPoliciesCard';
import { UploadButton } from '../UploadButton/UploadButton';

interface SideSectionProps {
  activity: PolicyActivity[];
  id: string;
  userId: string;
  insuranceType: PolicyTemplateInsurancePath;
  documentsData: {
    sections: DocumentSection[];
    options: SelectMenuOption[];
    uploadMutation?: DocumentNode;
    deleteFileMutation?: DocumentNode;
    getPolicyQuery: DocumentNode;
    additionalQueries?: OperationVariables;
  };
  policies?: Policy[];
  pinnedNotes?: Note[];
  refetchQueries?: DocumentNode[];
  hrPage?: boolean;
}

// TODO: [KONG] Refactor component for insurance type
const SideSection = ({
  activity,
  id,
  userId,
  insuranceType,
  documentsData,
  policies,
  refetchQueries,
  pinnedNotes,
  hrPage = false,
}: SideSectionProps) => {
  // Removes policy that user is currently on
  const filteredPolicies = policies?.filter(
    ({ id: otherPolicyId }) => otherPolicyId !== id
  );

  return (
    <div className="w-full lg:ml-[20px] lg:w-auto">
      {!hrPage && (
        <PinnedNotes
          notes={pinnedNotes ?? []}
          refetchQueries={refetchQueries}
        />
      )}
      {id && !hrPage && (
        <RecentActivity
          query={GET_POLICY_ACTIVITY}
          variables={{ policyId: id, userId, pageSize: 3 }}
        />
      )}
      {!hrPage && filteredPolicies && filteredPolicies.length > 0 && (
        <OtherPoliciesCard
          policies={filteredPolicies}
          title={`Other ${insuranceType} policies`}
          /**
           * Generic policies will return "generic" here from prop.
           * Type needs to be replaced
           */
          insuranceType={insuranceType}
        />
      )}
      {documentsData && (
        <DocumentsSection
          sections={documentsData.sections as DocumentSection[]}
          getResourceQuery={documentsData.getPolicyQuery}
          resourceId={id}
          deleteMutation={DELETE_POLICY_DOCUMENT}
          archiveMutation={ARCHIVE_POLICY_DOCUMENT}
          restoreMutation={RESTORE_POLICY_DOCUMENT}
          editMutation={EDIT_POLICY_DOCUMENT}
          documentCategories={documentsData.options}
          getDocumentArchiveQuery={GET_POLICY_DOCUMENTS_ARCHIVE}
          downloadAllMutation={GENERATE_POLICY_DOCUMENTS_ARCHIVE}
          uploadButton={
            <UploadButton
              mutation={UPLOAD_POLICY_DOCUMENT}
              documentCategories={documentsData.options}
              resourceId={id}
              documentVisibilityOptions={[
                { id: 'CUSTOMER', label: 'Customer' },
                { id: 'PROVIDER', label: 'Provider' },
                { id: 'HR', label: 'HR' },
              ]}
              getResourceQuery={documentsData.getPolicyQuery}
            />
          }
          editButton={EditButton}
          documentVisibilityOptions={[
            { id: 'CUSTOMER', label: 'Customer' },
            { id: 'PROVIDER', label: 'Provider' },
            { id: 'HR', label: 'HR' },
          ]}
        />
      )}
    </div>
  );
};

export default SideSection;
