import { CustomerDelinquencyBanner } from 'components/CustomerDelinquencyBanner';
import { CustomerFraudulentBanner } from 'components/CustomerFraudulentBanner';
import FileErrorCard from 'components/FileErrorCard';
import InformationCard from 'components/InformationCard';
import EditableInformationCard from 'components/InformationCard/EditableInformationCard';
import React from 'react';

import {
  InformationCardDetails,
  InformationCardEditableDetails,
} from '../models/PolicyTemplateData';

interface OverviewTabProps<PolicyData> {
  cards: (InformationCardDetails | InformationCardEditableDetails<any>)[];
  sideSection?: React.ReactNode;
  policyData?: PolicyData;
}

const OverviewTab = ({
  cards,
  sideSection,
  policyData,
}: OverviewTabProps<any>) => {
  return (
    <>
      <div className="relative flex w-full flex-col">
        <CustomerDelinquencyBanner customer={policyData.user} linkToCustomer />
        <CustomerFraudulentBanner customer={policyData.user} />
        {policyData?.archivedAt && (
          <FileErrorCard
            open
            hideCloseButton
            handleClose={() => {}}
            title="This policy is archived"
            errorType="WARNING"
            className="mb-[16px] mt-0"
          />
        )}
        {cards.map((card) => {
          if (card.editable) {
            return (
              <EditableInformationCard
                sections={card.cards}
                mutation={card.mutation}
                resourceId={card.resourceId}
                editableData={card.editabledData}
                successMessage="You have successfully saved the changes"
                ConfirmationModal={card.confirmationModal}
                oldConfirmationValue={
                  card.getOldConfirmationValues
                    ? card.getOldConfirmationValues(policyData)
                    : undefined
                }
                confirmationModalAdditionalData={
                  card.getConfirmationModalAdditionalData
                    ? card.getConfirmationModalAdditionalData(policyData)
                    : undefined
                }
                extraVariables={card.extraVariables}
                refetchQueries={card.refetchQueries}
              />
            );
          }
          return <InformationCard sections={card.cards ?? []} />;
        })}
      </div>
      {sideSection}
    </>
  );
};

export default OverviewTab;
