import { gql } from '@apollo/client';

export const GET_REFERRAL_CAMPAIGNS = gql`
  query GetReferralCampaigns(
    $searchString: String
    $offset: Int
    $limit: Int
    $sortColumn: String
    $sortOrder: String
  ) {
    referralCampaigns(
      searchString: $searchString
      offset: $offset
      limit: $limit
      sortColumn: $sortColumn
      sortOrder: $sortOrder
    ) {
      id
      title
      description
      sourceInsuranceType
      targetInsuranceType
      amount
      startDate
      endDate
    }
    referralCampaignsCount(searchString: $searchString)
  }
`;
