import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import { DocumentNode } from 'graphql';
import { alertBanners, setNewAlertBanner } from 'shared/reactiveVariables';
import { AlertBannerState } from 'shared/reactiveVariables/models';
import { v4 as uuidv4 } from 'uuid';

import * as styles from '../styles';

export const RegenerateButton = ({
  mutation,
  resourceId,
  documentCategory,
}: {
  mutation: DocumentNode;
  resourceId: string;
  documentCategory: string;
}) => {
  const { t } = useTranslation();
  const alertBannersState = useReactiveVar(alertBanners);
  const [regenerateDocument] = useMutation(mutation, {
    variables: {
      id: resourceId,
      category: documentCategory,
    },
    onCompleted: () => {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'SUCCESS',
        message: t(
          'admin.documents.regenerate.success.title',
          'Regenerating document, refresh the page in a few minutes.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
    onError: () => {
      const newAlertBanner: AlertBannerState = {
        id: uuidv4(),
        type: 'WARNING',
        message: t(
          'admin.documents.regenerate.error.title',
          'Something went wrong. Please try again.'
        ),
      };

      setNewAlertBanner({ state: alertBannersState, newAlertBanner });
    },
  });

  return (
    <button
      type="button"
      className={styles.dropdownOption}
      onClick={() => regenerateDocument()}
    >
      {t('admin.documents.action.regenerate.label', 'Regenerate')}
    </button>
  );
};
