import { useMutation } from '@apollo/client';
import { changeLanguage, Locale } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { UPDATE_ADMIN_USER_LANGUAGE } from 'graphql/admin/users/mutations';
import useStateWithLocalStorage from 'hooks/useStateWithLocalStorage';
import { useEffect, useState } from 'react';

import * as styles from './styles';

interface LanguageSelectorPropsProps {
  className?: string;
}

const LanguageSelector = ({ className }: LanguageSelectorPropsProps) => {
  const localeMapping: { [K in Locale]: string } = { en: 'EN', de: 'DE' };
  const { value: userInformation, setValue: setUserInformation } =
    useStateWithLocalStorage('userInformation');
  const [selected, setSelected] = useState<string>('');
  const [updateLanguage] = useMutation(UPDATE_ADMIN_USER_LANGUAGE);

  useEffect(() => {
    let userLanguage = '';
    if (userInformation) {
      userLanguage = JSON.parse(userInformation).language;
    }
    const language =
      userLanguage === 'undefined' || userLanguage === 'null'
        ? 'en'
        : userLanguage;
    setSelected(language);
    changeLanguage(language as Locale);
    dayjs.locale(language);
  }, [userInformation]);

  const handleChangeLanguage = (locale: Locale) => () => {
    setSelected(locale);
    changeLanguage(locale);
    dayjs.locale(locale);
    updateLanguage({
      variables: {
        language: locale,
      },
    }).then((result) => {
      const userInfo = JSON.parse(userInformation);
      setUserInformation(
        JSON.stringify({
          ...userInfo,
          language: result.data.updateAdminUserLanguage.language,
        })
      );
    });
  };

  return (
    <div className={styles.button}>
      {Object.entries(localeMapping).map(([locale, name], index) => (
        <div key={locale} className={styles.options}>
          <div
            role="button"
            tabIndex={0}
            onClick={handleChangeLanguage(locale as Locale)}
            onKeyDown={handleChangeLanguage(locale as Locale)}
            className={classNames(styles.option, {
              [styles.optionSelected]: selected === locale,
            })}
          >
            {name}
          </div>
          {index < Object.entries(localeMapping).length - 1 ? (
            <span className={styles.separator}>/</span>
          ) : (
            ''
          )}
        </div>
      ))}
    </div>
  );
};

export default LanguageSelector;
